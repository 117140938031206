import React, { useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Avatar, Typography, Box, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import VehiclesTypeService from "../../../../services/vehicleType.service";
import utils from "../../../../utils/index";

// Set locale for dayjs
require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  container: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#fff",
  },
  avatar: {
    width: "250px",
    height: "150px",
    border: "2px solid #00bb77", // Matches your theme color
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  highlightText: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "black",
    marginBottom: "5px",
  },
  secondaryText: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#6B6B6B",
    marginBottom: "10px",
  },
  highlightTextDetail: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#00bb77",
    marginBottom: "10px",
  },
  text: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#333",
    marginBottom: "10px",
  },
  divider: {
    margin: "10px 0",
  },
  infoContainer: {
    width: "50%",
  },
}));

const DetailCarComponent = (props) => {
  const classes = useStyles();
  const AllVehicles = useSelector((state) => state.vehicles);
  const [allVehicleTypes, setAllVehicleTypes] = useState([]);

  return (
    <>
      {props.idUserClicked !== 0 && (
        <div className={classes.container}>
          <Avatar
            className={classes.avatar}
            src={`${process.env.REACT_APP_URL}image/car/${
              AllVehicles.result[props.idUserClicked - 1].imagepath
            }`}
            alt={AllVehicles.result[props.idUserClicked - 1].Plate_No}
            variant="square"
          />
          <Typography className={classes.highlightText}>
            {AllVehicles.result[props.idUserClicked - 1].Plate_No}
          </Typography>
          <Typography className={classes.secondaryText}>
            {AllVehicles.result[props.idUserClicked - 1].model}
          </Typography>
          <Divider className={classes.divider} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "flex-start",
              width: "100%",
            }}
          >
            <Box className={classes.infoContainer}>
              <Typography className={classes.text}>
                {`ประเภทรถ: ${
                  AllVehicles.result[props.idUserClicked - 1].vehicleTypeNameTH
                }`}
              </Typography>

              <Typography className={classes.text}>
                {`บริษัท: TPE ${
                  AllVehicles.result[props.idUserClicked - 1].Vendor
                }`}
              </Typography>

              <Typography className={classes.text}>
                {`วันที่เช่า: ${dayjs(
                  AllVehicles.result[props.idUserClicked - 1].RentDate
                ).format("D MMM YYYY")}`}
              </Typography>

              <Typography className={classes.text}>{`CostCenter: `}</Typography>
              <Typography className={classes.text}>
                {AllVehicles.result[props.idUserClicked - 1].CostCenter}
              </Typography>
              <Typography className={classes.text}>
                ค่าเช่า:{" "}
                <span className={classes.highlightTextDetail}>
                  {utils.numberWithCommas(
                    AllVehicles.result[props.idUserClicked - 1].Cost
                  )}
                </span>{" "}
                บาท
              </Typography>
            </Box>
            <Box className={classes.infoContainer}>
              <Typography className={classes.text}>
                {`ใช้สำหรับ: ${
                  AllVehicles.result[props.idUserClicked - 1].UseFor
                }`}
              </Typography>

              <Typography className={classes.text}>
                {`ทำงานที่: site ${
                  AllVehicles.result[props.idUserClicked - 1].site
                }`}
              </Typography>

              <Typography className={classes.text}>
                {`วันสิ้นสุด: ${dayjs(
                  AllVehicles.result[props.idUserClicked - 1].ExpireDate
                ).format("D MMM YYYY")}`}
                {/* วันสิ้นสุด{" "}
              <span className={classes.highlightText}>
                {dayjs(
                  AllVehicles.result[props.idUserClicked - 1].ExpireDate
                ).format("D MMM YYYY")}
              </span> */}
              </Typography>
              <Typography className={classes.text}>
                {`CostElement: `}
              </Typography>
              <Typography className={classes.text}>
                {AllVehicles.result[props.idUserClicked - 1].CostElement}
              </Typography>
              <Typography className={classes.text}>
                ค่าจองรถ:{" "}
                <span className={classes.highlightTextDetail}>
                  {utils.numberWithCommas(
                    AllVehicles.result[props.idUserClicked - 1].CostBooking
                  )}
                </span>{" "}
                บาท
              </Typography>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailCarComponent;
