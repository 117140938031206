import React from "react";

import { styled } from '@mui/styles';
import Button from "@mui/material/Button";

const DivCoverReturn = styled("div")({
  position: "absolute",
  right: "50px",
  zIndex:"5",
});

const ButtonStyled = styled(Button)({
  width: "100%",
  height: "100%",
  padding: "10px",
  display: "block",
  borderRadius: "0 0 0 10px",
  "&:hover":{
    backgroundColor:"transparent",
  },
});

const IconStyled = styled("img")({
  width: "100px",
  height: "100px",
});

const ButtonStartStopDriving = (props) => {

  return (
    <DivCoverReturn>
      {props.statusCar && (
        <ButtonStyled
          onClick={props.OnClickButtonStartStop}
        >
          <IconStyled
            src={`./assets/Image/Icon/${
              props.statusCar.startTime 
                ? "StopButton.png"
                : "StartButton.png"
            }`}
            alt={
              props.statusCar.startTime 
                ? "StopButton.png"
                : "StartButton.png"
            }
          />
        </ButtonStyled>
      )}
    </DivCoverReturn>
  );
};

export default ButtonStartStopDriving;
