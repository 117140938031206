import React from 'react';

import { styled } from '@mui/styles';
import {
    IconButton,
} from '@mui/material';

const DivCoverReturn = styled("div")({
    width:"100%",
    position:"relative",
});

const EditButton = styled(IconButton)(({theme})=>({
    position:"absolute",
    right:"0px",
    top:"0px",
    fontSize:"16px",
    color:theme.palette.primary.main,
    "&:hover":{
        backgroundColor:"transparent",
    },
}));

const CardEdit = ({ children, openModal, edit }) => {

    return(
        <DivCoverReturn>
            {children}
            {edit && <EditButton onClick={openModal} disableRipple><i className="fas fa-pen"></i></EditButton>}
        </DivCoverReturn>
    );
};

export default CardEdit;