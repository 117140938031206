import React, { useState, useEffect } from "react";
import { styled, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Container from "@mui/material/Container";

import Progress from "./components/Progress";
import Activity from "./components/Activity";
import Delivery from "./components/Delivery";
import DetailTab from "./components/DetailTab";
import maintenanceService from "../../../../../services/maintenance.service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getUserProfile } from "../../../../../actions/user";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1600,
  ["& .chipFile"]: {
    marginRight: 8,
    marginBottom: 8,
    color: "#fff",
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.main,
    },
    ["& .MuiSvgIcon-root"]: {
      ["&:hover"]: {
        color: "#fff",
      },
    },
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const Detail = ({ match }) => {
  const dispatch = useDispatch();
  const [maintenance, setMaintenance] = useState(null);
  const [technician, setTechnicians] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isError, setIsError] = useState(null);
  const [page, setPage] = useState("detail");
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const onChangePageHandler = (event, newValue) => {
    setPage(newValue);
  };

  const onAddNewProgressReport = (newProgressReport) => {
    maintenance.delivery.unshift({
      ...newProgressReport.delivery,
    });

    if (newProgressReport.notification) {
      maintenance.notification.unshift({
        ...newProgressReport.notification,
      });
    }

    if (newProgressReport.status) {
      maintenance[0].status = newProgressReport.status;
    }

    maintenance[0].progress = newProgressReport.progress;
    setMaintenance({ ...maintenance });
  };

  useEffect(() => {
    maintenanceService
      .getMaintenanceDesc({
        maintenanceId: match.params.maintenanceId,
        idUser: currentUser.idUser,
        roles: currentUser.roles,
      })
      .then((res) => {
        setTechnicians(res.data.data.technician);
        setMaintenance(res.data.data);
        setLoading(false);
        setIsAdmin(res.data.isAdmin);
      })
      .catch((error) => {
        setLoading(false);
        setIsError("ไม่พบข้อมูล");
      });
  }, []);

  return (
    <Container maxWidth="lg">
      <div className="page">
        <Root>
          <Grid container spacing={3}>
            {loading && (
              <Grid item xs={12}>
                <Typography fontSize={20} textAlign="center">
                  กำลังโหลดข้อมูล...
                </Typography>
              </Grid>
            )}

            {!loading && isError && (
              <Grid item xs={12}>
                <Typography fontSize={20} textAlign="center">
                  {isError}
                </Typography>
              </Grid>
            )}

            {maintenance && (
              <>
                <Grid item xs={12}>
                  <BackButton
                    disableRipple
                    component={Link}
                    to={`/maintenances/history`}
                  >
                    <ArrowBackIcon
                      className="Icon"
                      style={{ color: "#99A8B2" }}
                    />
                  </BackButton>
                </Grid>
                <Grid item xs={12}>
                  <Progress value={maintenance[0].progress} />
                </Grid>
                <Grid item xs={12}>
                  <Tabs value={page} onChange={onChangePageHandler}>
                    <Tab value="activity" label="แจ้งเตือน" />
                    <Tab value="detail" label="รายละเอียด" />
                    <Tab value="delivery" label="รายงานความคืบหน้า" />
                  </Tabs>
                </Grid>
                <Grid item xs={12}>
                  {page === "activity" && (
                    <Activity data={maintenance?.notification} />
                  )}
                  {page === "detail" && (
                    <DetailTab data={maintenance} technician={technician} />
                  )}
                  {/* {page==="delivery" && <Delivery data={maintenance?.delivery} isAdmin={isAdmin} progress={maintenance?.progress} maintenanceId={maintenance?.id} onAddNewProgressReport={onAddNewProgressReport}/>} */}
                  {page === "delivery" && (
                    <Delivery
                      data={maintenance.delivery}
                      status={parseInt(maintenance[0].status)}
                      isAdmin={isAdmin}
                      progress={maintenance[0].progress}
                      maintenanceId={maintenance[0].idMaintenances}
                      technician={technician}
                      onAddNewProgressReport={onAddNewProgressReport}
                    />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Root>
      </div>
    </Container>
  );
};

export default Detail;
