import React, { useState, useEffect } from "react";
import clsx from "clsx";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import { Card, Grid, Avatar, Typography, useMediaQuery } from "@mui/material";

import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";
import MeetingRoomRoundedIcon from "@mui/icons-material/MeetingRoomRounded";
import AirlineSeatReclineNormalRoundedIcon from "@mui/icons-material/AirlineSeatReclineNormalRounded";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";

require("dayjs/locale/th");
dayjs.locale("th");

const CardStyled = styled(Card)(({ theme }) => ({
  marginBottom: "20px",
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  boxShadow: theme.shadows[3],
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
  paddingBottom: "10px",
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  width: "80%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "100px",
  height: "100px",
  margin: "20px",
  [theme.breakpoints.down("lg")]: {
    width: "80px",
    height: "80px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "140px",
    height: "140px",
    marginBottom: "0px",
  },
}));

const NameAndType = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const TextAndIcon = styled("div")({
  display: "flex",
  marginBottom: "5px",
  "& .Icon": {
    marginRight: "10px",
    color: "#293A80",
  },
  "& .CrossArea": {
    color: "#C400C6 !important",
  },
  "& .CrossAreaPool": {
    color: "#FF6600 !important",
  },
  "& .InArea": {
    color: "#0A81AB !important",
  },
  "& .Delivery": {
    color: "#8B4513 !important",
  },
});

const Text = styled(Typography)({
  color: "#293A80",
});

const DateAndTime = styled("div")({
  "@media (min-width: 700px) and (max-width: 780px)": {
    display: "flex",
  },
});

const DivStatus = styled("div")(({ theme }) => ({
  width: "150px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "5px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },
  "& .TextApproved": {
    color: "#6DCC6D",
  },
  "& .TextRejected": {
    color: "#EA4647",
  },
  "& .TextWaiting": {
    color: "#C3B816",
  },
}));

const DivAvatarAndName = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const TextFirstnameAndLastName = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "block",
    paddingTop: "10px",
  },
  "& .FirstName": {
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
    },
  },
}));

const Name = styled(Typography)({
  color: "#185ADB",
  fontSize: "20px",
  marginBottom: "10px",
});

const IconStatus = styled(Avatar)(({ theme }) => ({
  width: "90px",
  height: "90px",
  [theme.breakpoints.down("sm")]: {
    marginRight: "20px",
  },
}));

const TextStatus = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
});

const CardRequest = (props) => {
  const mobile = useMediaQuery("(max-width:600px)");
  const [booking, setBooking] = useState(null);
  const [showHistory, setHistory] = useState(0);

  useEffect(() => {
    if (props.booking) {
      setBooking(props.booking);
    }
  }, []);
  const verificationTypeOfBooking = (type) => {
    if (verifyTypeOfBookingCrossArea(type)) {
      return "การจองรถข้ามพื้นที่เฉพาะ";
    }
    if (verifyTypeOfBookingCrossAreaPool(type)) {
      return "การจองรถข้ามพื้นที่ Pool";
    }
    if (verifyTypeOfBookingInArea(type)) {
      return "การจองรถในพื้นที่";
    }
    if (verifyTypeOfBookingDeliveryCar(type)) {
      return "การขอรถรับส่งของ";
    }
    if (verifyTypeOfBookingMeetingRoom(type)) {
      return "การขอจองห้องประชุม";
    }
    if (verifyTypeOfBookingDriverBooking(type)) {
      return "การขอจองคนขับรถ";
    }
  };

  const verifyTypeOfStatus = (statusApproved, Approved, statusManageCar) => {
    return (
      <>
        <IconStatus
          src={`../assets/Image/Icon/${
            verifyTpyeOfStatusIsApproved(
              statusApproved,
              Approved,
              statusManageCar
            )
              ? "approved.png"
              : verifyTpyeOfStatusIsRejected(statusApproved, Approved)
              ? "rejected.png"
              : "waiting.png"
          }`}
          alt="IconStatus"
        />
        <TextStatus
          className={clsx({
            TextApproved: verifyTpyeOfStatusIsApproved(
              statusApproved,
              Approved,
              statusManageCar
            ),
            TextRejected: verifyTpyeOfStatusIsRejected(
              statusApproved,
              Approved
            ),
            TextWaiting:
              !verifyTpyeOfStatusIsApproved(
                statusApproved,
                Approved,
                statusManageCar
              ) && !verifyTpyeOfStatusIsRejected(statusApproved, Approved),
          })}
        >
          {verifyTpyeOfStatusIsApproved(
            statusApproved,
            Approved,
            statusManageCar
          )
            ? "Approved"
            : verifyTpyeOfStatusIsRejected(statusApproved, Approved)
            ? "Rejected"
            : "Waiting"}
        </TextStatus>
      </>
    );
  };

  const verifyTypeOfStatusForDriverBooking = (
    statusDelivery,
    statusManageCar
  ) => {
    // console.log(statusApproved, Approved, statusManageCar);
    return (
      <>
        <IconStatus
          src={`../assets/Image/Icon/${
            verifyTpyeOfStatusIsApprovedForDriverBooking(
              statusDelivery,

              statusManageCar
            )
              ? "carWaiting.png"
              : verifyTpyeOfStatusIsWaitingForDriverBooking(statusManageCar)
              ? "waiting.png"
              : "approved.png"
          }`}
          alt="IconStatus"
        />
        <TextStatus
          className={clsx({
            TextApprovedForDriverBooking:
              verifyTpyeOfStatusIsApprovedForDriverBooking(
                statusDelivery,

                statusManageCar
              ),

            TextWaiting:
              verifyTpyeOfStatusIsWaitingForDriverBooking(statusManageCar),

            TextApproved:
              !verifyTpyeOfStatusIsApprovedForDriverBooking(
                statusDelivery,

                statusManageCar
              ) &&
              !verifyTpyeOfStatusIsWaitingForDriverBooking(statusManageCar),
          })}
        >
          {verifyTpyeOfStatusIsApprovedForDriverBooking(
            statusDelivery,

            statusManageCar
          )
            ? "Approved"
            : verifyTpyeOfStatusIsWaitingForDriverBooking(statusManageCar)
            ? "Waiting"
            : "Success"}
        </TextStatus>
      </>
    );
  };

  const verifyTypeOfBookingCrossArea = (typeOfBooking) => {
    if (typeOfBooking === "CrossArea") {
      return true;
    } else {
      return false;
    }
  };

  const verifyTypeOfBookingCrossAreaPool = (typeOfBooking) => {
    if (typeOfBooking === "CrossAreaPool") {
      return true;
    } else {
      return false;
    }
  };

  const verifyTypeOfBookingInArea = (typeOfBooking) => {
    if (typeOfBooking === "InArea") {
      return true;
    } else {
      return false;
    }
  };

  const verifyTypeOfBookingDeliveryCar = (typeOfBooking) => {
    if (typeOfBooking === "Delivery") {
      return true;
    } else {
      return false;
    }
  };
  const verifyTypeOfBookingMeetingRoom = (typeOfBooking) => {
    if (typeOfBooking === "MeetingRoom") {
      return true;
    } else {
      return false;
    }
  };
  const verifyTypeOfBookingDriverBooking = (typeOfBooking) => {
    if (typeOfBooking === "DriverBooking") {
      return true;
    } else {
      return false;
    }
  };

  const verifyTpyeOfStatusIsApproved = (
    statusApproved,
    approved,
    statusManageCar
  ) => {
    console.log(statusApproved, approved, statusManageCar);

    if (
      statusApproved === "Success" &&
      approved === "Success" &&
      statusManageCar === "Success"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const verifyTpyeOfStatusIsRejected = (statusApproved, approved) => {
    if (statusApproved === "Success" && approved === false) {
      return true;
    } else {
      return false;
    }
  };

  const verifyTpyeOfStatusIsApprovedForDriverBooking = (
    statusDelivery,
    statusManageCar
  ) => {
    if (statusManageCar === "Success" && statusDelivery != "Success") {
      return true;
    } else {
      return false;
    }
  };
  const verifyTpyeOfStatusIsWaitingForDriverBooking = (statusManageCar) => {
    if (statusManageCar != "Success") {
      return true;
    } else {
      return false;
    }
  };

  const TextTypeBooking = () => {
    return (
      <>
        {verifyTypeOfBookingCrossArea(props.typeOfBooking) && (
          <DriveEtaIcon className="CrossArea Icon" />
        )}
        {verifyTypeOfBookingCrossAreaPool(props.typeOfBooking) && (
          <EmojiTransportationIcon className="CrossAreaPool Icon" />
        )}
        {verifyTypeOfBookingInArea(props.typeOfBooking) && (
          <EmojiTransportationIcon className="InArea Icon" />
        )}
        {verifyTypeOfBookingDeliveryCar(props.typeOfBooking) && (
          <EmojiTransportationIcon className="Delivery Icon" />
        )}
        {verifyTypeOfBookingMeetingRoom(props.typeOfBooking) && (
          <MeetingRoomRoundedIcon className="MeetingRoom Icon" />
        )}
        {verifyTypeOfBookingDriverBooking(props.typeOfBooking) && (
          <AirlineSeatReclineNormalRoundedIcon className="DriverBooking Icon" />
        )}
        <Typography
          className={clsx({
            CrossArea: verifyTypeOfBookingCrossArea(props.typeOfBooking),
            CrossAreaPool: verifyTypeOfBookingCrossAreaPool(
              props.typeOfBooking
            ),
            InArea: verifyTypeOfBookingInArea(props.typeOfBooking),
            Delivery: verifyTypeOfBookingDeliveryCar(props.typeOfBooking),
            MeetingRoom: verifyTypeOfBookingMeetingRoom(props.typeOfBooking),
          })}
        >
          {`ประเภท : ${verificationTypeOfBooking(props.typeOfBooking)}`}
        </Typography>
      </>
    );
  };

  return (
    <CardStyled
      onClick={() => {
        console.log(booking, props.typeOfBooking);
        if (props.typeOfBooking === "CrossArea") {
          props.onClickCardHandler(
            props.typeOfBooking,
            booking.idCrossAreaCarBooking
          );
        } else if (props.typeOfBooking === "CrossAreaPool") {
          props.onClickCardHandler(
            props.typeOfBooking,
            booking.idCrossAreaCarPoolBooking
          );
        } else if (props.typeOfBooking === "InArea") {
          props.onClickCardHandler(
            props.typeOfBooking,
            booking.idinAreaCarBooking
          );
        } else if (props.typeOfBooking === "Delivery") {
          props.onClickCardHandler(
            props.typeOfBooking,
            booking.idDeliveryCarBooking
          );
        } else if (props.typeOfBooking === "MeetingRoom") {
          props.onClickCardHandler(
            props.typeOfBooking,
            booking.idMeetingRoom,
            booking.idMeetingRoom
          );
        }

        // props.onClickCardHandler(props.typeOfBooking, booking.idCrossAreaCarBooking);
      }}
    >
      {booking && (
        <>
          {booking.statusApproved === "Success" &&
            booking.Approved === "Success" &&
            booking.statusManageCar === "Success" &&
            TextTypeBooking() != "MeetingRoom" && (
              <>
                <GridContainer container>
                  <Grid
                    item
                    xs={12}
                    md={7}
                    lg={6}
                    style={{ paddingRight: "0 !important" }}
                  >
                    <DivAvatarAndName>
                      <AvatarStyled
                        src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                        alt={booking.name}
                      />
                      <NameAndType>
                        <TextFirstnameAndLastName>
                          <Name>{booking.name}</Name>
                        </TextFirstnameAndLastName>
                        {!mobile && (
                          <TextAndIcon>{TextTypeBooking()}</TextAndIcon>
                        )}
                      </NameAndType>
                    </DivAvatarAndName>
                  </Grid>
                  {mobile && (
                    <TextAndIcon style={{ marginLeft: "15px" }}>
                      {TextTypeBooking()}
                    </TextAndIcon>
                  )}
                  <Grid
                    container
                    item
                    xs={12}
                    md={5}
                    lg={6}
                    spacing={2}
                    alignItems="center"
                    style={{ paddingLeft: "15px" }}
                  >
                    <Grid item xs={12}>
                      {!verifyTypeOfBookingCrossAreaPool(
                        props.typeOfBooking
                      ) && (
                        <TextAndIcon>
                          <GpsFixedRoundedIcon className="Icon" />
                          <Text>{`วัตถุประสงค์ : ${booking.purpose}`}</Text>
                        </TextAndIcon>
                      )}
                      <DateAndTime>
                        <TextAndIcon style={{ marginRight: "40px" }}>
                          <CalendarTodayIcon className="Icon" />
                          <Text>
                            {`วันที่ใช้ : ${dayjs(booking.departureDate).format(
                              "DD MMMM YYYY"
                            )}`}
                          </Text>
                        </TextAndIcon>
                        <TextAndIcon>
                          <AccessTimeRoundedIcon className="Icon" />
                          <Text>
                            {`ระยะเวลา : ${dayjs(booking.startTime).format(
                              "HH:mm"
                            )} - ${dayjs(booking.endTime).format("HH:mm")} น.`}
                            {/* {`ระยะเวลา : ${New booking.startTime} - ${dayjs(booking.endTime).format("HH:mm")} น.`} */}
                          </Text>
                        </TextAndIcon>
                      </DateAndTime>
                    </Grid>
                  </Grid>
                </GridContainer>
                <DivStatus>
                  {verifyTypeOfStatus(
                    booking.statusApproved,
                    booking.Approved,
                    booking.statusManageCar
                  )}
                </DivStatus>
              </>
            )}
          {booking.nameUser != undefined &&
            props.typeOfBooking === "DriverBooking" && (
              <>
                <GridContainer container>
                  <Grid
                    item
                    xs={12}
                    md={7}
                    lg={6}
                    style={{ paddingRight: "0 !important" }}
                  >
                    <DivAvatarAndName>
                      <AvatarStyled
                        src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                        alt={booking.nameUser}
                      />
                      <NameAndType>
                        <TextFirstnameAndLastName>
                          <Name>{booking.nameUser}</Name>
                        </TextFirstnameAndLastName>
                        {!mobile && (
                          <TextAndIcon>{TextTypeBooking()}</TextAndIcon>
                        )}
                      </NameAndType>
                    </DivAvatarAndName>
                  </Grid>
                  {mobile && (
                    <TextAndIcon style={{ marginLeft: "15px" }}>
                      {TextTypeBooking()}
                    </TextAndIcon>
                  )}
                  <Grid
                    container
                    item
                    xs={12}
                    md={5}
                    lg={6}
                    spacing={2}
                    alignItems="center"
                    style={{ paddingLeft: "15px" }}
                  >
                    <Grid item xs={12}>
                      {/* {!verifyTypeOfBookingCrossAreaPool(
                          props.typeOfBooking
                        ) && (
                          <TextAndIcon>
                            <GpsFixedRoundedIcon className="Icon" />
                            <Text>{`วัตถุประสงค์ : ${booking.purpose}`}</Text>
                          </TextAndIcon>
                        )} */}
                      <DateAndTime>
                        <TextAndIcon style={{ marginRight: "40px" }}>
                          <CalendarTodayIcon className="Icon" />
                          <Text>
                            {`วันที่ : ${dayjs(booking.startDate).format(
                              "DD MMMM YYYY"
                            )}`}
                          </Text>
                        </TextAndIcon>
                        <TextAndIcon>
                          <AccessTimeRoundedIcon className="Icon" />
                          <Text>
                            {`ระยะเวลา : ${booking.startTime} - ${booking.endTime} น.`}
                          </Text>
                        </TextAndIcon>
                        <TextAndIcon>
                          <GpsFixedRoundedIcon className="Icon" />
                          <Text>{`สถานที่รับ : ${booking.namePlaceFrom}`}</Text>
                        </TextAndIcon>
                        <TextAndIcon>
                          <GpsFixedRoundedIcon className="Icon" />
                          <Text>{`สถานที่ส่ง : ${booking.namePlaceTo}`}</Text>
                        </TextAndIcon>
                      </DateAndTime>
                    </Grid>
                  </Grid>
                </GridContainer>
                <DivStatus>
                  {verifyTypeOfStatusForDriverBooking(
                    booking.statusDelivery,
                    booking.statusManageCar
                  )}
                </DivStatus>
              </>
            )}
        </>
      )}
    </CardStyled>
  );
};

export default CardRequest;
