import React, { useState } from 'react';

import { styled } from '@mui/styles';
import {
    Typography, Grid,
} from '@mui/material';

import CardEdit from "../../../../../shared/cardEdit";
import ModalCondition from "./modalCondition";
import { listCondition } from "../../../choise";

const Topic = styled(Typography)({
    fontSize:"22px",
    fontWeight:"bold",
    margin:"10px 0px",
});

const GridStyled = styled(Grid)(({theme})=>({
    width:"500px",
    "& .Icon":{
        fontSize:"26px",
        marginRight:"10px",
        color:theme.palette.warning.main,
    },
    [theme.breakpoints.down('sm')]: {
        width:"100%",
    },
}));

const PartOfCondition = ({ listExConditions, setListExConditions, edit }) => {
    const [openModal, setOpenModal] = useState(false);

    const onChangeConditionCheckBox = (id) => {
        if(listExConditions.includes(id)){
            setListExConditions([...listExConditions.filter(option => option !== id)]);
        }
        else{
            setListExConditions([...listExConditions,id]);
        }
    };

    const OnClickOpenModal = () => setOpenModal(true);

    const OnClickCloseModal = () => setOpenModal(false);

    return(
        <CardEdit openModal={OnClickOpenModal} edit={edit}>
            <Topic>Car Rules</Topic>
            <GridStyled container spacing={1}>
                {listExConditions.map((id)=>(
                    <Grid key={"Option"+id} item xs={12} sm={6} style={{display:"flex"}}>
                        <i className="fas fa-exclamation-triangle Icon"></i>
                        <Typography>{listCondition.find(conditionlist => conditionlist.id === id).name}</Typography>
                    </Grid>
                ))}
            </GridStyled>
            {openModal && 
            <ModalCondition 
                openModal={openModal}
                listExConditions={listExConditions} 
                onChangeConditionCheckBox={onChangeConditionCheckBox}
                OnClickCloseModal={OnClickCloseModal}
            />}
        </CardEdit>
    );
};

export default PartOfCondition;