import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import { useSelector } from "react-redux";

import { styled } from "@mui/styles";
import {
  Typography,
  Button,
  Card,
  Avatar,
  TextField,
  FormControl,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import utils from "../../../../../../utils";
import HistoryLentCarServices from "../../../../../../services/historyLentCar.service";

require("dayjs/locale/th");
dayjs.locale("th");

const DivCoverReturn = styled("div")({
  width: "calc(100% - 60px)",
  height: "calc(100% - 70px)",
  padding: "70px 30px 0",
});

const Title = styled(Typography)({
  fontSize: "30px",
});

const HeadNavBar = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  margin: "10px 0",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const NavButton = styled("div")(({ theme }) => ({
  "& .Clicked": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const FilterButton = styled(Button)(({ theme }) => ({
  height: "100%",
  border: "2px solid",
  borderColor: theme.palette.primary.main,
  fontSize: "18px",
  padding: "7px 15px",
  marginRight: "10px",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const ClearButton = styled(Button)(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: "white",
    color: theme.palette.primary.dark,
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
    width: "fit-content",
  },
}));

const SortFormControl = styled(FormControl)(({ theme }) => ({
  width: "150px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
  },
}));

const AllCards = styled("div")(({ theme }) => ({
  width: "calc(100% - 30px)",
  height: "calc(100% - 172px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginTop: "10px",
  padding: "15px",
  overflowX: "auto",
  overflowY: "auto",
  [theme.breakpoints.up("xl")]: {
    alignItems: "center",
  },
}));

const CardStyled = styled(Card)(({ theme }) => ({
  width: "1274px",
  height: "100px",
  minHeight: "100px",
  display: "flex",
  padding: "10px",
  margin: "10px",
  boxShadow: theme.shadows[3],
}));

const DivAvatar = styled("div")({
  display: "flex",
  alignItems: "center",
  flexBasis: "8%",
});

const AvatarStyled = styled(Avatar)({
  width: "150px",
  height: "80px",
  objectFit: "contain",
});

const ContentWidthoutAvatarAndPoint = styled("div")(({ theme }) => ({
  height: "calc(100% - 10px)",
  display: "flex",
  padding: "5px 0",
  flexBasis: "92%",
}));

const NameText = styled(Typography)({
  fontSize: "24px",
});

const Text = styled(Typography)({
  fontSize: "20px",
});

const DivModelAndDelivery = styled("div")({
  flexBasis: "19%",
  padding: "0 10px",
});

const DivPlaces = styled("div")({
  flexBasis: "17%",
});

const DivDate = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flexBasis: "12%",
});

const DivDuration = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flexBasis: "7%",
});

const DivPrice = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flexBasis: "11%",
});

const DivViewButton = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexBasis: "10%",
});

const ViewButton = styled(Button)(({ theme }) => ({
  border: "1px solid black",
  color: "black",
}));

const DivStatus = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexBasis: "12%",
  "& .Approved": {
    color: theme.palette.success.main,
  },
  "& .Waiting": {
    color: theme.palette.warning.light,
  },
}));

const StatusText = styled(Typography)(({ theme }) => ({
  fontSize: "26px",
}));

const RequestPage = (props) => {
  const { user: AuthUser } = useSelector((state) => state.auth);
  const [listPending, setListPending] = useState([]);
  const [filterStatus, setFilterStatus] = useState("All");
  const [sort, setSort] = useState("Newest");

  const MobileResponsive = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (filterStatus === "Approved") {
      HistoryLentCarServices.getHistoryLentCarApprovedByBorrowerId(
        AuthUser.id,
        sort
      ).then((res) => {
        try {
          if (res.data) {
            setListPending(res.data);
          }
        } catch (error) {
          console.log(error);
        }
      });
    } else if (filterStatus === "Waiting") {
      HistoryLentCarServices.getHistoryLentCarWaitingByBorrowerId(
        AuthUser.id,
        sort
      ).then((res) => {
        try {
          if (res.data) {
            setListPending(res.data);
          }
        } catch (error) {
          console.log(error);
        }
      });
    } else {
      HistoryLentCarServices.getHistoryLentCarByBorrowerId(
        AuthUser.id,
        sort
      ).then((res) => {
        try {
          if (res.data) {
            setListPending(res.data);
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  }, [filterStatus, sort]);

  const onClickViewButton = (idCar) => {
    props.history.push("/friend2friend_Car/lender/car", {
      idCar: idCar,
      purpose: "lending",
      pending: true,
    });
  };

  return (
    <DivCoverReturn>
      <Title>รายการรออนุมัติ</Title>
      <HeadNavBar>
        <NavButton>
          <FilterButton
            className={clsx({ Clicked: filterStatus === "Approved" })}
            onClick={() => setFilterStatus("Approved")}
          >
            อนุมัติแล้ว
          </FilterButton>
          <FilterButton
            className={clsx({ Clicked: filterStatus === "Waiting" })}
            onClick={() => setFilterStatus("Waiting")}
          >
            รอการอนุมัติ
          </FilterButton>
          {(filterStatus !== "All" || sort !== "Newest") &&
            !MobileResponsive && (
              <ClearButton
                disableRipple
                onClick={() => {
                  setFilterStatus("All");
                  setSort("Newest");
                }}
              >
                ล้างตัวกรอง
              </ClearButton>
            )}
        </NavButton>
        <SortFormControl>
          <Select
            labelId="sort"
            id="sort"
            value={sort}
            onChange={(event) => setSort(event.target.value)}
          >
            <MenuItem value={"Newest"}>เรียงล่าสุด</MenuItem>
            <MenuItem value={"Oldest"}>เรียงเก่าสุด</MenuItem>
          </Select>
        </SortFormControl>
        {(filterStatus !== "All" || sort !== "Newest") && MobileResponsive && (
          <ClearButton
            disableRipple
            onClick={() => {
              setFilterStatus("All");
              setSort("Newest");
            }}
          >
            ล้างตัวกรอง
          </ClearButton>
        )}
      </HeadNavBar>
      {console.log(listPending)}
      <AllCards>
        {listPending.length > 0 &&
          listPending.map((pending) => (
            <CardStyled key={pending.id}>
              {console.log(pending)}
              <DivAvatar>
                <AvatarStyled
                  src={`${process.env.REACT_APP_URL}image/car/${pending.imageCar}`}
                  variant="square"
                />
              </DivAvatar>
              <ContentWidthoutAvatarAndPoint>
                <DivModelAndDelivery>
                  <NameText>{pending.model}</NameText>
                  <NameText>{pending.delivery ? "ส่งรถ" : "มารับ"}</NameText>
                </DivModelAndDelivery>
                <DivPlaces>
                  <NameText>{pending.province}</NameText>
                  <NameText>{pending.district}</NameText>
                </DivPlaces>
                <DivDate>
                  <Text>{dayjs(pending.datelent).format("D MMM YYYY")}</Text>
                  <Text>{`${pending.datelenttime} น.`}</Text>
                </DivDate>
                <DivDate>
                  <Text>{dayjs(pending.datereturn).format("D MMM YYYY")}</Text>
                  <Text>{`${pending.datereturntime} น.`}</Text>
                </DivDate>
                <DivDuration>
                  <NameText>Day</NameText>
                  <NameText>{pending.duration}</NameText>
                </DivDuration>
                <DivPrice>
                  <NameText>Price</NameText>
                  <NameText>{utils.numberWithCommas(pending.price)}</NameText>
                </DivPrice>
                <DivViewButton>
                  <ViewButton onClick={() => onClickViewButton(pending.id)}>
                    View
                  </ViewButton>
                </DivViewButton>
                <DivStatus>
                  <StatusText
                    className={clsx({
                      Approved: pending.status === "Approved",
                      Waiting: pending.status === "Waiting",
                    })}
                  >
                    {pending.status}
                  </StatusText>
                </DivStatus>
              </ContentWidthoutAvatarAndPoint>
            </CardStyled>
          ))}
      </AllCards>
    </DivCoverReturn>
  );
};

export default RequestPage;
