import { httpClient } from "./httpClient";

const getDriverProfile = (id) => {
  return httpClient.get("users/profile/" + id);
};

const getDriverById = (id) => {
  return httpClient.get("driver_by_id/" + id);
};

const getAllDriver = () => {
  return httpClient.get("drivers");
};

const postNewDriver = (driver) => {
  return httpClient.post("post_new_driver/", driver);
};

const postEditDriver = (EditDriver, id) => {
  return httpClient.post("post_edit_driver/", [EditDriver, id]);
};

const deleteDriver = (drivers) => {
  return httpClient.post("post_delete_drivers/", drivers);
};

export default {
  getDriverProfile,
  getDriverById,
  getAllDriver,
  deleteDriver,
  postNewDriver,
  postEditDriver,
};
