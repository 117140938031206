import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
/* import GoogleMap from "google-map-react"; */
import clsx from 'clsx';
// import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';

import { styled } from '@mui/styles';
import {
    Button, Paper, Typography,
} from '@mui/material';

// import { GoogleAPIkey, locationSite1 } from "../../../../utils/googleMapAssets";
import PaperRequest from "./paperRequest";
import InitMap from "../../shared/googleMap";

import BetweenSiteCarService from "../../../../services/betweenSiteCar.service";
import SiteService from "../../../../services/site.service"

const DivCoverReturn = styled("div")({
    width:"100%",
    height:"calc(100% - 64px)",
    display:"flex",
    position:"relative"
});

const GpsCarImg = styled("img")({
    width:"50px",
    height:"50px",
    transform:"translate(-50%,-100%)",
    position:"absolute",
    zIndex:"1",
});

const DivMarker = styled("div")(({theme})=>({
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    backgroundColor:"transparent",
    transform:"translate(-50%,-75%)",
    "& .Car":{
        backgroundColor:theme.palette.primary.main,
        width:"107px",
    },
    "& .Place":{
        width:"43px",
    },
    "& .Getting":{
        backgroundColor:theme.palette.success.main,
    },
    "& .Destination":{
        backgroundColor:theme.palette.error.main,
    },
    "& .GettingPin":{
        color:theme.palette.success.main,
    },
    "& .DestinationPin":{
        color:theme.palette.error.main,
    },
}));

const DivText = styled("div")(({theme})=>({
    padding:"5px 10px",
    border:"3px solid white",
    borderRadius:"30px",
    boxShadow:theme.shadows[3],
}));

const Text = styled(Typography)({
    color:"white",
    fontSize:"16px",
    fontWeight:"bold",
});

const MockGPSPlace = [
    {
        id:1,
        name:"Site 1",
        latlng:{lat:12.6999465,lng:101.1347889},
        latlngbounds:[
            {
                lat: 12.706941,
                lng: 101.135534
            },
            {
                lat: 12.703241,
                lng: 101.133505
            },
            {
                lat: 12.699737,
                lng: 101.133377
            },
            {
                lat: 12.6995844,
                lng: 101.1372722
            },
            {
                lat: 12.7069945,
                lng: 101.1373152
            },
            {
                lat: 12.706941,
                lng: 101.135534
            },
        ],
    },
    {
        id:2,
        name:"Site 3",
        latlng:{lat:12.72193,lng:101.152073},
        latlngbounds:[
            {
                lat: 12.723989955489369,
                lng: 101.14991571573864
            },
            {
                lat: 12.721888366307862,
                lng: 101.15346239281705
            },
            {
                lat: 12.720459234415664,
                lng: 101.15256378746356
            },
            {
                lat: 12.722543109802952,
                lng: 101.14901675957441
            },
            {
                lat: 12.723989955489369,
                lng: 101.14991571573864
            },
        ],
    },
    {
        id:3,
        name:"Site 7",
        latlng:{lat:12.759015,lng:101.16438599999999},
        latlngbounds:[
            {
                lat: 12.760134627420838,
                lng: 101.16424652257453
            },
            {
                lat: 12.75962189733676,
                lng: 101.16517993123678
            },
            {
                lat: 12.757466326841879,
                lng: 101.16526576191836
            },
            {
                lat: 12.757612822505617,
                lng: 101.16273375681158
            },
            {
                lat: 12.760134627420838,
                lng: 101.16424652257453
            },
        ],
    },
]

const MarkerCustom = ({ label, idPlace, placeStart }) => {
    return (
        <DivMarker style={{width:"fit-content"}}>
            <DivText className={clsx("Place", { "Getting" : idPlace === placeStart , "Destination" : idPlace !== placeStart })}>
                <Text>{label}</Text>
            </DivText>
            <i className={clsx("fas fa-map-marker-alt", { "GettingPin" : idPlace === placeStart , "DestinationPin" : idPlace !== placeStart })} style={{ fontSize:"35px", marginTop:"7px"}}></i>
        </DivMarker>
    );
};

const MarkerCar = () => {
    return(
        <DivMarker>
            <i className="fad fa-car" style={{fontSize:"40px", color:"blue", marginTop:"3px",}}></i>
        </DivMarker>
    );
};

const DivNoAuthen = styled("div")({
    width:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
});

const ImageHoliday = styled("img")({
    width:"400px",
    height:"400px"
});

const TextNoAuthen = styled(Typography)({
    fontSize:"28px"
});

const MapBetweenSiteDriver = props => {
    // lat:12.678920615135462,lng:101.13318017396224
    // 12.700801585951679, 101.13597159748619 In Site 1
    // 12.722090918838996, 101.15138593703504 In Site 3
    // 12.721577006194613, 101.13842333184412 Between 1 - 3
    const { result : DriverRouteDay } = useSelector(state => state.driverRouteDay);
    const [locationCar, setLocationCar] = useState({lat:12.700801585951679,lng:101.13597159748619});
    const [placeStart, setPlaceStart] = useState(1);
    const [placeDestination, setPlaceDestination] = useState(9);
    const [siteScg, setSiteScg] = useState([]);

;
    useEffect(() => {
        if(DriverRouteDay){
            setPlaceStart(1);
            BetweenSiteCarService.getSite().then((res)=>{
                if(res.data){
                    let areaPin = [];
                    console.log(res.data)
                    setSiteScg(res.data)
                }
            })
            // MockGPSPlace.map( (place,index) => {
            //     let areaPin = [];
            //     place.latlngbounds.map( latlng => {
            //         areaPin.push(new window.google.maps.LatLng(
            //             parseFloat(latlng.lat), parseFloat(latlng.lng)
            //         ))
            //     });
            //     let AreaPlace = new window.google.maps.Polygon({ paths: areaPin });
            //     const InArea = window.google.maps.geometry.poly.containsLocation(locationCar,AreaPlace)?true:false;
            //     if(InArea){
            //         setPlaceStart(place.id);
            //         setPlaceDestination(MockGPSPlace[index+1].id);
            //     }
            // });
            SiteService.getLocationDriverById(parseInt(DriverRouteDay.idDriver)).then((res)=>{
                if(res.data){
                    setLocationCar({lat:parseFloat(res.data[0].LatDriver),lng: parseFloat(res.data[0].LngDriver)})

                }
            })
        }else{
            props.history.push("/driver");
        }
    }, []);

    return(
        <DivCoverReturn className="page">
            {DriverRouteDay && parseInt(DriverRouteDay.typeDriver) === 3 ?
                <>
                    <InitMap>
                        <MarkerCar lat={locationCar.lat} lng={locationCar.lng} />
                         {siteScg && siteScg.map( place => {
                            if(parseInt(place.NoSite) === placeStart){
                                return(
                                    <MarkerCustom 
                                        lat={parseFloat(place.Lat)} 
                                        lng={parseFloat(place.Long)} 
                                        label={`Site ${place.NoSite}`} 
                                        idPlace={place.idScgSite}
                                        placeStart={placeStart} 
                                        key={place.idScgSite}
                                    />
                                );
                            }
                            else if(parseInt(place.NoSite) === placeDestination){
                                return(
                                    <MarkerCustom 
                                        lat={parseFloat(place.Lat)} 
                                        lng={parseFloat(place.Long)} 
                                        label={`Site ${place.NoSite}`} 
                                        idPlace={place.idScgSite}
                                        placeStart={placeStart} 
                                        key={place.idScgSite}
                                    />
                                );
                            }
                        })}
                        {/* {MockGPSPlace && MockGPSPlace.map( place => {
                            if(place.id === placeStart){
                                return(
                                    <MarkerCustom 
                                        lat={place.latlng.lat} 
                                        lng={place.latlng.lng} 
                                        label={place.name} 
                                        idPlace={place.id}
                                        placeStart={placeStart} 
                                        key={place.id}
                                    />
                                );
                            }
                            else if(place.id === placeDestination){
                                return(
                                    <MarkerCustom 
                                        lat={place.latlng.lat} 
                                        lng={place.latlng.lng} 
                                        label={place.name} 
                                        idPlace={place.id}
                                        placeStart={placeStart} 
                                        key={place.id}
                                    />
                                );
                            }
                        })} */}
                    </InitMap>
                    <PaperRequest 
                        MockGPSPlace={MockGPSPlace} 
                        placeStart={placeStart}
                    />
                </>
                :
                <DivNoAuthen>
                    <ImageHoliday src="./assets/Image/holiday.png" alt="holiday" />
                    <TextNoAuthen>ไม่ได้เป็นคนขับรถระหว่าง Site</TextNoAuthen>
                </DivNoAuthen>
            }
        </DivCoverReturn>
    );
};

export default MapBetweenSiteDriver;