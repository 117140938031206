import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import {
  Typography, Button
} from '@mui/material';

import ScheduleIcon from '@mui/icons-material/Schedule';
import AddIcon from '@mui/icons-material/Add';

import RegisterOfDriverService from "../../../../../../services/registerOfDriver.service";
import CardRegisterDate from './cardRegisterDate';

import { getUserProfile } from "../../../../../../actions/user";

require('dayjs/locale/th');
dayjs.locale('th');


const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "90%",
  },
  Title: {
    textAlign: "center",
    fontSize: "35px",
    marginTop: "30px",
  },
  partOfNavButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  CreateButton: {
    height:"50px",
    width:"200px",
    display:"flex",
    color:"white",
    fontSize:"20px",
    marginRight:"10px",
    backgroundColor: "#436ADC",
    ["&:hover"]:{
      backgroundColor: "#436ADC",
    },
    [theme.breakpoints.down('sm')]:{
      fontSize:"16px",
    },
  },
  RequestButton:{
    height:"50px",
    width:"220px",
    display:"flex",
    color:"white",
    fontSize:"20px",
    backgroundColor: "#F39C12",
    ["&:hover"]:{
      backgroundColor: "#F39C12",
    },
    [theme.breakpoints.down('sm')]:{
      fontSize:"14px",
    },
  },
  partOfList: {
    width: "100%",
    marginTop: "10px",
    height:"calc(100% - 140px)",
    overflowY:"auto",
    paddingBottom:"30px",
  },
  Icon:{
    marginRight:"5px",
    fontSize:"30px",
  },
}));

const ListRegisterPage = (props) => {
  const classes = useStyles();
  const { user : User } = useSelector(state => state.auth);
  const [listRegister, setListRegister] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    RegisterOfDriverService.getRegisterOfDriverByIdUser(User.id).then(
      (res) => {
        setListRegister(res.data.result);
    });
    dispatch(getUserProfile(User.id));
  }, []);

  const onClickCreateButton = () => {
    props.history.push("/friend2friend_Car/driver/form");
  };

  const onClickRequestButton = () => {
    props.history.push("/friend2friend_Car/driver/listApprove");
  };

  return (
    <div className={`page ${classes.DivCoverReturn}`}>
      <Typography className={`${classes.Title}`}>My Plan</Typography>
      <div className={`${classes.partOfNavButton}`}>
        <Button
          className={`${classes.CreateButton}`}
          onClick={onClickCreateButton}
        >
          <AddIcon className={classes.Icon}/>
          Create Plan
        </Button>
        <Button
          className={`${classes.RequestButton}`}
          onClick={onClickRequestButton}
        >
          <ScheduleIcon className={classes.Icon}/>
          รายการคำขอขึ้นรถ
        </Button>
      </div>
      <div className={`${classes.partOfList}`}>
        {listRegister.length > 0  &&
          listRegister.map((value) => {
            return (
              <CardRegisterDate register={value} key={value.id} />
            );
          })}
      </div>
    </div>
  );
};

export default ListRegisterPage;
