export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const USERS_FETCHING = "USERS_FETCHING";
export const USERS_FAILED = "USERS_FAILED";
export const USERS_SUCCESS = "USERS_SUCCESS";

export const USERPROFILE_FETCHING = "USERPROFILE_FETCHING";
export const USERPROFILE_FAILED = "USERPROFILE_FAILED";
export const USERPROFILE_SUCCESS = "USERPROFILE_SUCCESS";

export const DEPARTMENT_FETCHING = "DEPARTMENT_FETCHING";
export const DEPARTMENT_FAILED = "DEPARTMENT_FAILED";
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS";

export const DRIVERS_FETCHING = "DRIVERS_FETCHING";
export const DRIVERS_FAILED = "DRIVERS_FAILED";
export const DRIVERS_SUCCESS = "DRIVERS_SUCCESS";

export const DRIVERITEM_FETCHING = "DRIVERITEM_FETCHING";
export const DRIVERITEM_FAILED = "DRIVERITEM_FAILED";
export const DRIVERITEM_SUCCESS = "DRIVERITEM_SUCCESS";

export const WAITINGFRIENDCARS_FETCHING = "WAITINGFRIENDCARS_FETCHING";
export const WAITINGFRIENDCARS_FAILED = "WAITINGFRIENDCARS_FAILED";
export const WAITINGFRIENDCARS_SUCCESS = "WAITINGFRIENDCARS_SUCCESS";

export const WAITINGFRIENDCARBYID_FETCHING = "WAITINGFRIENDCARBYID_FETCHING";
export const WAITINGFRIENDCARBYID_FAILED = "WAITINGFRIENDCARBYID_FAILED";
export const WAITINGFRIENDCARBYID_SUCCESS = "WAITINGFRIENDCARBYID_SUCCESS";

export const VEHICLES_FETCHING = "VEHICLES_FETCHING";
export const VEHICLES_FAILED = "VEHICLES_FAILED";
export const VEHICLES_SUCCESS = "VEHICLES_SUCCESS";

export const EMPLOYEES_FETCHING = "EMPLOYEES_FETCHING";
export const EMPLOYEES_FAILED = "EMPLOYEES_FAILED";
export const EMPLOYEES_SUCCESS = "EMPLOYEES_SUCCESS";

export const LENTCARS_FETCHING = "LENTCARS_FETCHING";
export const LENTCARS_FAILED = "LENTCARS_FAILED";
export const LENTCARS_SUCCESS = "LENTCARS_SUCCESS";

export const LENTCAR_FETCHING = "LENTCAR_FETCHING";
export const LENTCAR_FAILED = "LENTCAR_FAILED";
export const LENTCAR_SUCCESS = "LENTCAR_SUCCESS";

export const VEHICLETYPES_FETCHING = "VEHICLETYPES_FETCHING";
export const VEHICLETYPES_FAILED = "VEHICLETYPES_FAILED";
export const VEHICLETYPES_SUCCESS = "VEHICLETYPES_SUCCESS";

export const VEHICLEBRANDSANDMODELS_FETCHING =
  "VEHICLEBRANDSANDMODELS_FETCHING";
export const VEHICLEBRANDSANDMODELS_FAILED = "VEHICLEBRANDSANDMODELS_FAILED";
export const VEHICLEBRANDSANDMODELS_SUCCESS = "VEHICLEBRANDSANDMODELS_SUCCESS";

export const DRIVERROUTEDAY_FETCHING = "DRIVERROUTEDAY_FETCHING";
export const DRIVERROUTEDAY_FAILED = "DRIVERROUTEDAY_FAILED";
export const DRIVERROUTEDAY_SUCCESS = "DRIVERROUTEDAY_SUCCESS";

export const HISTORYLENTCARS_FETCHING = "HISTORYLENTCARS_FETCHING";
export const HISTORYLENTCARS_FAILED = "HISTORYLENTCARS_FAILED";
export const HISTORYLENTCARS_SUCCESS = "HISTORYLENTCARS_SUCCESS";

export const ROUTES_FETCHING = "ROUTES_FETCHING";
export const ROUTES_FAILED = "ROUTES_FAILED";
export const ROUTES_SUCCESS = "ROUTES_SUCCESS";

export const ROUTELINE_FETCHING = "ROUTELINE_FETCHING";
export const ROUTELINE_FAILED = "ROUTELINE_FAILED";
export const ROUTELINE_SUCCESS = "ROUTELINE_SUCCESS";

export const ROUTEUSER_FETCHING = "ROUTEUSER_FETCHING";
export const ROUTEUSER_FAILED = "ROUTEUSER_FAILED";
export const ROUTEUSER_SUCCESS = "ROUTEUSER_SUCCESS";

//Testing

export const BOOKINGS_FETCHING = "BOOKINGS_FETCHING";
export const BOOKINGS_FAILED = "BOOKINGS_FAILED";
export const BOOKINGS_SUCCESS = "BOOKINGS_SUCCESS";

export const BETWEEN_SITE_CAR_BOOKINGS_FETCHING =
  "BETWEEN_SITE_CAR_BOOKINGS_FETCHING";
export const BETWEEN_SITE_CAR_BOOKINGS_FAILED =
  "BETWEEN_SITE_CAR_BOOKINGS_FAILED";
export const BETWEEN_SITE_CAR_BOOKINGS_SUCCESS =
  "BETWEEN_SITE_CAR_BOOKINGS_SUCCESS";

export const CROSS_AREA_CAR_BOOKINGS_FETCHING =
  "CROSS_AREA_CAR_BOOKINGS_FETCHING";
export const CROSS_AREA_CAR_BOOKINGS_FAILED = "CROSS_AREA_CAR_BOOKINGS_FAILED";
export const CROSS_AREA_CAR_BOOKINGS_SUCCESS =
  "CROSS_AREA_CAR_BOOKINGS_SUCCESS";

export const CROSS_AREA_CAR_POOL_BOOKINGS_FETCHING =
  "CROSS_AREA_CAR_POOL_BOOKINGS_FETCHING";
export const CROSS_AREA_CAR_POOL_BOOKINGS_FAILED =
  "CROSS_AREA_CAR_POOL_BOOKINGS_FAILED";
export const CROSS_AREA_CAR_POOL_BOOKINGS_SUCCESS =
  "CROSS_AREA_CAR_POOL_BOOKINGS_SUCCESS";

export const IN_AREA_CAR_BOOKINGS_FETCHING = "IN_AREA_CAR_BOOKINGS_FETCHING";
export const IN_AREA_CAR_BOOKINGS_FAILED = "IN_AREA_CAR_BOOKINGS_FAILED";
export const IN_AREA_CAR_BOOKINGS_SUCCESS = "IN_AREA_CAR_BOOKINGS_SUCCESS";

export const DELIVERY_CAR_BOOKINGS_FETCHING = "DELIVERY_CAR_BOOKINGS_FETCHING";
export const DELIVERY_CAR_BOOKINGS_FAILED = "DELIVERY_CAR_BOOKINGS_FAILED";
export const DELIVERY_CAR_BOOKINGS_SUCCESS = "DELIVERY_CAR_BOOKINGS_SUCCESS";

export const MEETING_ROOM_BOOKINGS_FETCHING = "MEETING_ROOM_BOOKINGS_FETCHING";
export const MEETING_ROOM_BOOKINGS_FAILED = "MEETING_ROOM_BOOKINGS_FAILED";
export const MEETING_ROOM_BOOKINGS_SUCCESS = "MEETING_ROOM_BOOKINGS_SUCCESS";

export const DRIVER_BOOKINGS_FETCHING = "DRIVER_BOOKINGS_FETCHING";
export const DRIVER_BOOKINGS_FAILED = "DRIVER_BOOKINGS_FAILED";
export const DRIVER_BOOKINGS_SUCCESS = "DRIVER_BOOKINGS_SUCCESS";

export const DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FETCHING =
  "DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FETCHING";
export const DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FAILED =
  "DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FAILED";
export const DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_SUCCESS =
  "DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_SUCCESS";

export const EDITINGS_FETCHING = "EDITING_FETCHING";
export const EDITINGS_FAILED = "EDITING_FAILED";
export const EDITINGS_SUCCESS = "EDITING_SUCCESS";

export const DELETE_FETCHING = "DELETE_FETCHING";
export const DELETE_FAILED = "DELETE_FAILED";
export const DELETE_SUCCESS = "DELETE_SUCCESS";

export const PASSENGERS_FETCHING = "PASSENGERS_FETCHING";
export const PASSENGERS_FAILED = "PASSENGERS_FAILED";
export const PASSENGERS_SUCCESS = "PASSENGERS_SUCCESS";

export const MANAGERS_FETCHING = "MANAGERS_FETCHING";
export const MANAGERS_FAILED = "MANAGERS_FAILED";
export const MANAGERS_SUCCESS = "MANAGERS_SUCCESS";

export const EMERGENCY_FETCHING = "EMERGENCY_FETCHING";
export const EMERGENCY_FAILED = "EMERGENCY_FAILED";
export const EMERGENCY_SUCCESS = "EMERGENCY_SUCCESS";
