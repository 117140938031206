import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import dayjs from 'dayjs';

import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FlagIcon from '@mui/icons-material/Flag';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';

require('dayjs/locale/th');
dayjs.locale('th');

const useStyles = makeStyles((theme)=>({
    Card:{
        width:"95%",
        height:"100px",
        display:"flex",
        marginTop:"10px",
        alignItems:"center",
        boxShadow:theme.shadows[3],
        [theme.breakpoints.down('md')]:{
            height:"120px",
        },
        [theme.breakpoints.down('sm')]:{
            height:"180px",
            alignItems:"flex-start",
        },
    },
    partOfHeadOfCard:{
        width:"100px",
        height:"100%",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        backgroundColor:"purple",
        flexBasis:"10%",
        [theme.breakpoints.down('md')]:{
            width:"90px",
            flexBasis:"initial",
            minWidth:"90px",
        },
    },
    partOfHeadOfCardComeBackHome:{
        backgroundColor:"#F067D9",
    },
    TextHeadCard:{
        color:"white",
        fontWeight:"bold",
        [theme.breakpoints.down('md')]:{
            fontSize:"15px",
        }
    },
    IconType:{
        fontSize:"50px",
        color:"white",
        [theme.breakpoints.down('md')]:{
            fontSize:"40px",
        },
    },
    TextAndIcon:{
        display:"flex",
    },
    partOfDate:{
        alignItems:"center",
        marginLeft:"15px",
        flexBasis:"30%",
    },
    partOfTime:{
        flexBasis:"25%",
        [theme.breakpoints.down('lg')]:{
            flexBasis:"20%",
        }
    },
    partOfPlace:{
        flexBasis:"50%",
        [theme.breakpoints.down('lg')]:{
            flexBasis:"39%",
        },
        [theme.breakpoints.down('lg')]:{
            flexBasis:"50%",
        },
    },
    Text:{ 
        fontSize:"20px",
        [theme.breakpoints.down('md')]:{
            fontSize:"16px",
        },
    },
    Icon:{
        marginRight:"10px",
        color:"black",
    },
    TextDate:{
        fontSize:"25px",
        color:"purple",
    },
    Button:{
        backgroundColor:"#C9CCCC",
        padding:"10px 20px",
        borderRadius:"50px",
        ["&:hover"]:{
            backgroundColor:"#C9CCCC",
        },
        [theme.breakpoints.down('sm')]:{
            position:"absolute",
            right:"10px",
            bottom:"10px",
        },
    },
    partOfButton:{
        alignItems:"center",
    },
    partOfDetailCard:{
        display:"flex",
        flexBasis:"70%",
        [theme.breakpoints.down('lg')]:{
            display:"block"
        },
        [theme.breakpoints.down('md')]:{
            flexBasis:"60%",
        },
        [theme.breakpoints.down('sm')]:{
            flexBasis:"90%",
            height:"100%",
            position:"relative"
        },
    },
    partOfDetailCardWithOutDate:{
        display:"flex",
        flexBasis:"70%",
        alignItems:"center",
        [theme.breakpoints.down('lg')]:{
            marginLeft:"15px",
        },
        [theme.breakpoints.down('md')]:{
            display:"block"
        },

    },
    partOfDetailPlace:{
        display:"flex",
        flexBasis:"74%",
        [theme.breakpoints.down('md')]:{
            flexBasis:"100%",
        },
        [theme.breakpoints.down('sm')]:{
            display:"block",
            marginBottom:"5px",
        },
    }
}));

const CardPlan = (props) => {
    const classes = useStyles();

    const MobileResponsive = useMediaQuery('(max-width:600px)');

    const onClickDetailButtonHandler = (idOrder) => {
        props.history.push("/friend2friend_Car/passenger/showDetail_driver", {
          orderId: idOrder,
          joined: true,
        });
    }

    const convertDate = (lawStartDate, lawEndDate) => {
        let startDateSetTime = new Date(lawStartDate).setHours(0,0,0,0);
        let endDateSetTime = new Date(lawEndDate).setHours(0,0,0,0);
        if(startDateSetTime === endDateSetTime){
          return dayjs(startDateSetTime).format("DD MMM YYYY");
        }
        else{
          let startDate = dayjs(startDateSetTime).format("DD");
          return `${startDate} - ${dayjs(endDateSetTime).format("DD MMM YYYY")}`
        }
    };

    return(
        <Card className={`${classes.Card}`}>
            <div className={clsx([classes.partOfHeadOfCard],{[classes.partOfHeadOfCardComeBackHome]:props.plan.register.type === "ComeBackHome"})}>
                {props.plan.register.type !== "ComeBackHome" ?
                    <EmojiTransportationIcon className={`${classes.IconType}`}/>
                    :
                    <HomeIcon className={`${classes.IconType}`}/>
                }
                <Typography className={`${classes.TextHeadCard}`}>
                    {props.plan.register.type !== "ComeBackHome" ? "GOWORK" : "BACKHOME"}
                </Typography>
            </div>
            <div className={classes.partOfDetailCard}>
                <div className={`${classes.partOfDate} ${classes.TextAndIcon}`}>
                    <CalendarTodayIcon className={`${classes.Icon}`}/>
                    <Typography className={`${classes.Text} ${classes.TextDate}`}>
                        {`${convertDate(props.plan.startDate, props.plan.endDate)}`}
                    </Typography>
                </div>
                <div className={classes.partOfDetailCardWithOutDate}>
                    <div className={`${classes.partOfTime} ${classes.TextAndIcon}`}>
                        <ScheduleIcon className={`${classes.Icon}`}/>
                        <Typography className={`${classes.Text}`}>
                            {dayjs(props.plan.register.time).format("HH.mm น. ")}
                        </Typography>
                    </div>
                    <div className={classes.partOfDetailPlace}>
                        <div className={`${classes.partOfPlace} ${classes.TextAndIcon}`}>
                            <LocationOnIcon className={`${classes.Icon}`}/>
                            <Typography className={`${classes.Text}`} noWrap>
                                {`${props.plan.register.fromPlace}`}
                            </Typography>
                        </div>
                        <div className={`${classes.partOfPlace} ${classes.TextAndIcon}`}>
                            <FlagIcon className={`${classes.Icon}`}/>
                            <Typography className={`${classes.Text}`} noWrap>
                                {`${props.plan.register.toPlace}`}
                            </Typography>
                        </div>
                    </div>
                </div>
                {MobileResponsive && 
                    <Button 
                    className={`${classes.Button}`} 
                    onClick={()=>onClickDetailButtonHandler(props.plan.id)}
                    >
                        <div className={`${classes.TextAndIcon} ${classes.partOfButton}`}>
                            <SearchIcon className={`${classes.Icon}`}/>
                            <Typography className={`${classes.Text}`}>
                                See Detail
                            </Typography>
                        </div>
                    </Button>
                }
            </div>
            {!MobileResponsive &&
                <Button 
                    className={`${classes.Button}`} 
                    onClick={()=>onClickDetailButtonHandler(props.plan.idRegister)}
                >
                    <div className={`${classes.TextAndIcon} ${classes.partOfButton}`}>
                        <SearchIcon className={`${classes.Icon}`}/>
                        <Typography className={`${classes.Text}`}>
                            See Detail
                        </Typography>
                    </div>
                </Button>
            }
        </Card>
    );
};

export default CardPlan;