import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { styled } from "@mui/styles";
import {
  Typography,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  Badge,
  ButtonGroup,
  Button,
  CircularProgress,
  Backdrop,
  DialogTitle,
  TextField,
  FormControlLabel,
  Switch,
  Tooltip,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Link,
  Fade,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */
import Alert from "@mui/material/Alert";
/* import {
    faMale,
    faBaby,
    faBlind,
    faInfo
} from "@fortawesome/free-solid-svg-icons"; */
import CloseIcon from "@mui/icons-material/Close";
import GoogleMap from "google-map-react";
/* import GoogleMap from "google-map-react";
import { googleApiKey, location } from "./../../../constants/index";
import * as routeActions from "./../../../actions/route.action";
import * as employeeActions from "./../../../actions/employee.action"; */
import clsx from "clsx";
import moment from "moment";
/* import ReactGA from 'react-ga';
import routeReducer from "../../../reducers/route.reducer"; */
import InfiniteScroll from "react-infinite-scroll-component";
import EmployeesServices from "../../../../services/employee.service";
import { GoogleAPIkey, locationSite1 } from "../../../../utils/googleMapAssets";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    background: "#eee",
    marginTop: 65,
  },
  mapProfile: {
    height: "100%",
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: "#3f51b5",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  houseIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/Image/house.svg)`,
    border: "1px solid",
    borderColor: "#00bdaa",
    backgroundSize: "15px 15px",
  },
  sirenIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/images/siren.svg)`,
    border: "1px solid",
    borderColor: "#f44336",
    backgroundSize: "20px 20px",
  },
  lightIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/images/lightning.svg)`,
    border: "1px solid",
    borderColor: "#ffc107",
    backgroundSize: "20px 20px",
  },
  statusEmergency: {
    color: "#f44336",
  },
  statusNormal: {
    color: "#ffc107",
  },
  statusEmergencyList: {
    backgroundColor: "#f44336",
    color: "#F44336",
  },
  statusNormalList: {
    backgroundColor: "#ffc107",
    color: "#FFC107",
  },
  imageProfile: {
    borderRadius: "50%",
  },
  infoTxtProfile: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  boxCount: {
    textAlign: "center",
    lineHeight: 1,
    border: "1px solid #EEE",
    borderRadius: 3,
    width: "100%",
    marginRight: 10,
  },
  statusDone: {
    color: "#4caf50",
  },
  buttonProgress: {
    color: "#AAA",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wideScreen: {
    flexDirection: "column",
  },
  listEmployeeSmallScreen: {
    position: "absolute",
    height: "300px !important",
    width: "100% !important",
    bottom: 0,
    left: 0,
    paddingLeft: 60,
  },
  filterListEmployee: {
    display: "flex",
    alignItems: "center",
  },
  listNameEmployeeSmallScreen: {
    overflow: "auto",
    height: 200,
  },
  ImgExampleMap: {
    width: "100%",
    height: "100%",
  },
  Loader: {
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // height: "100vh", // Full height of the viewport
    // marginInline: "150px",
    paddingInline: "150px",
  },
}));

const defaultProps = {
  color: "secondary",
};

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const EmployeePlace = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width: 768px)");
  /* const employeeReducer = useSelector(({ employeeReducer }) => employeeReducer); */
  const routeReducer = useSelector(({ routeReducer }) => routeReducer);
  const userReducer = useSelector(({ userReducer }) => userReducer);
  const [showMoreInfo, setShowMoreInfo] = React.useState(false);
  const [showAddSpecialRequest, setShowAddSpecialRequest] =
    React.useState(false);
  const [indexHover, setIndexHover] = React.useState(0);
  const [selectEmployee, setSelectEmployee] = React.useState({});
  const specialRequestReducer = useSelector(
    ({ specialRequestReducer }) => specialRequestReducer
  );
  const [viewProfile, setViewProfile] = React.useState("profile");
  const [value, setValue] = React.useState("");
  const [isEmergency, setIsEmergency] = React.useState(false);
  const [searchEmployee, setSearchEmployee] = React.useState("");
  const [site, setSite] = React.useState("all");
  const [shift, setShift] = React.useState("all");
  const [employeeReducer, setEmployeeReducer] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    /* ReactGA.initialize('UA-164134822-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        
        dispatch(employeeActions.getEmployeeCompany(props.history)); */
    // await EmployeesServices.getAllEmployees().then((res) => {
    //   setEmployeeReducer(res.data);
    // });
    fetchEmployees();
    console.log("refresh");
  }, [site, shift, searchEmployee]);

  const fetchEmployees = async () => {
    try {
      console.log("page", page);
      console.log("hasMore", hasMore);
      const res = await EmployeesServices.getEmployeesByPage(page).then(
        (res) => {
          console.log(res.data);
          if (res.data.length === 0) {
            setHasMore(false);
          } else {
            if (employeeReducer === null) {
              setEmployeeReducer(res.data);
            } else {
              setEmployeeReducer([...employeeReducer, ...res.data]);
            }

            // setEmployeeReducer([...employeeReducer, ...res.data]);
            setPage(page + 1);
          }
        }
      );
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const onChildClickCallback = (key) => {
    //console.log(key)
    setIndexHover(key);
  };

  const onChildMouseEnter = (key, childProps) => {
    setIndexHover(key);
  };

  const onChildMouseLeave = () => {
    //setIndexHover(0);
  };

  const handleClickMoreInformation = (place) => {
    setSelectEmployee(place);
    setShowMoreInfo(true);
  };

  const handleCloseMoreInformation = () => {
    setShowMoreInfo(false);
  };

  const handleCloseAddSpecialRequest = () => {
    setShowAddSpecialRequest(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeEmergency = (event) => {
    setIsEmergency(event.target.checked);
  };

  const handleChangeSite = (event) => {
    setSite(event.target.value);
    resetPagination();
  };

  const handleChangeShift = (event) => {
    setShift(event.target.value);
    resetPagination();
  };

  const handleSearch = (event) => {
    setSearchEmployee(event.target.value);
    resetPagination();
  };
  const resetPagination = () => {
    setEmployeeReducer([]);
    setPage(1);
    setHasMore(true);
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    return null;
  }

  const Marker = (props) => {
    const MarkerStyled = styled("div")({
      borderRadius: "50%",
      height: 30,
      width: 30,
      backgroundColor: "white",
      cursor: "pointer",
      zIndex: 0,
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "50%",
      backgroundPositionY: "45%",
    });

    return (
      <Fragment>
        <MarkerStyled className={classes.houseIcon} />
        {props.show && <InfoWindow place={props.place} />}
      </Fragment>
    );
  };

  const InfoWindow = (props) => {
    const { place } = props;

    const InfoWindowStyle = styled("div")({
      position: "relative",
      top: -355,
      left: 0,
      width: 350,
      backgroundColor: "white",
      boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
      padding: 10,
      fontSize: 14,
      zIndex: 100,
      borderRadius: 3,
      bottom: 390,
    });

    const CloseIconButton = styled(IconButton)({
      position: "absolute",
      right: -12,
      top: -10,
      backgroundColor: "#bdbdbd",
      "&:hover": {
        backgroundColor: "#bdbdbd",
      },
    });

    return (
      <InfoWindowStyle onClick={onChildMouseLeave}>
        <CloseIconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            setIndexHover(0);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </CloseIconButton>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              style={{ marginRight: 10 }}
              src={`${process.env.PUBLIC_URL}/assets/0100.jpg`}
            ></Avatar>
            <Typography variant="h5">{place.companyNameTH}</Typography>
          </div>
          <Divider style={{ margin: "10px 0" }}></Divider>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Badge
                overlap="circular"
                color="primary"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={place.shiftEnglish}
              >
                {/* <Avatar style={{ height: 100, width: 100 }} alt={place.fNameThai} src={`${process.env.PUBLIC_URL}/assets/profiles/emp/${place.scgEmployeeId}.jpg`} /> */}
                {/* Mock~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                <Avatar
                  style={{ height: 100, width: 100 }}
                  alt={place.fNameThai}
                  src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                />
                {/* Mock~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              </Badge>
            </div>
            <div className={classes.infoTxtProfile}>
              <Typography variant="h6">{place.fNameThai}</Typography>
              <Typography variant="body2" color="textSecondary">
                {place.scgEmployeeId}
              </Typography>
              {/* <Typography variant="body2" color="textSecondary">
                                {place.departmentNameTH}
                            </Typography> */}
              {/* Mock~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              <Typography variant="body2" color="textSecondary">
                ฝ่ายทรัพยากรบุคคล
              </Typography>
              {/* Mock~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              {/*  <Chip
                                label={place.shiftEnglish == "" ? "Day Time" : place.shiftEnglish}
                                style={{
                                    width: "50%",
                                    display: "flex",
                                    margin: "0 auto",
                                    marginTop: "10px"
                                }}
                            /> */}
              {/* Mock~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              <Chip
                label={"Day Time"}
                style={{
                  width: "50%",
                  display: "flex",
                  margin: "0 auto",
                  marginTop: "10px",
                }}
              />
              {/* Mock~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
            </div>
          </div>
          <Divider style={{ margin: "10px 0" }}></Divider>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <div style={{ display: "flex" }}>
              <Typography
                variant="body2"
                style={{ fontWeight: 500, marginRight: 5 }}
              >
                {"เบอร์โทรศัพท์ : "}
              </Typography>
              <Typography variant="body2">
                {formatPhoneNumber(place.mobileNumber)}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography
                variant="body2"
                style={{ fontWeight: 500, marginRight: 5 }}
              >
                {"Email : "}
              </Typography>
              <Typography variant="body2">{place.email}</Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography
                variant="body2"
                style={{ width: 55, fontWeight: 500, marginRight: 5 }}
              >
                {"ที่อยู่ : "}
              </Typography>
              <Typography variant="body2">
                {place.addressNumber} {place.villageSoi}{" "}
                {place.streetRoad && "ถนน"}
                {place.streetRoad} {place.subdistrictTambol && "ตำบล"}
                {place.subdistrictTambol} {place.district && "อำเภอ"}
                {place.district} {place.province && "จังหวัด"}
                {place.province} {place.postalCode}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Link
                href={`https://www.google.com/maps/search/?api=1&query=${place.lat},${place.long}`}
              >
                {" "}
                {place.lat} {","} {place.long}{" "}
              </Link>
            </div>
          </div>
        </div>
      </InfoWindowStyle>
    );
  };

  return (
    <div className={classes.root}>
      <div
        className={clsx({ [classes.wideScreen]: matches })}
        style={{ width: "100%", display: "flex" }}
      >
        <div className={classes.mapProfile}>
          {/* <img 
                        className={`${classes.ImgExampleMap}`}
                        src="../assets/background/exampleMap.png"
                        alt="ExampleMap"
                    /> */}
          <GoogleMap
            defaultZoom={12}
            defaultCenter={locationSite1}
            bootstrapURLKeys={{ key: GoogleAPIkey }}
            onChildClick={onChildClickCallback}
            /* onChildMouseEnter={onChildMouseEnter}
                        onChildMouseLeave={onChildMouseLeave} */
          >
            {employeeReducer &&
              employeeReducer
                .filter((item) => {
                  const fullName = item.firstname_TH + " " + item.lastname_TH;
                  if (shift == "all" && site == "all") {
                    if (searchEmployee == "") {
                      return item;
                    } else {
                      return fullName.includes(searchEmployee);
                    }
                  } else if (shift != "all" && site == "all") {
                    return (
                      item.shift == shift && fullName.includes(searchEmployee)
                    );
                  } else if (shift == "all" && site != "all") {
                    return (
                      item.site == site && fullName.includes(searchEmployee)
                    );
                  } else if (shift != "all" && site != "all") {
                    return (
                      item.site == site &&
                      item.shift == shift &&
                      fullName.includes(searchEmployee)
                    );
                  }
                })
                .map((place) => (
                  <Marker
                    key={place.idEmployee}
                    lat={place.lat}
                    lng={place.long}
                    show={place.idEmployee == indexHover}
                    place={place}
                  />
                ))}
          </GoogleMap>
        </div>
        <div
          className={clsx({ [classes.listEmployeeSmallScreen]: matches })}
          style={{
            width: "400px",
            height: "90vh",
            overflowX: "hidden",
            backgroundColor: "white",
            paddingTop: 20,
          }}
        >
          <div className={clsx({ [classes.filterListEmployee]: matches })}>
            <div style={{ padding: "0 10px", marginBottom: 10 }}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "100%" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  {" "}
                  Site{" "}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={site}
                  onChange={handleChangeSite}
                  label="site"
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={1}>Site 1</MenuItem>
                  <MenuItem value={3}>Site 3</MenuItem>
                  <MenuItem value={7}>Site 7</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "0 10px" }}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "100%" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  {" "}
                  Shift{" "}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={shift}
                  onChange={handleChangeShift}
                  label="shift"
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"A"}>Shift A</MenuItem>
                  <MenuItem value={"B"}>Shift B</MenuItem>
                  <MenuItem value={"C"}>Shift C</MenuItem>
                  <MenuItem value={"D"}>Shift D</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div style={{ padding: 10 }}>
              <TextField
                label="ค้นหาพนักงาน"
                id="outlined-size-small"
                variant="outlined"
                style={{ width: "100%" }}
                onChange={handleSearch}
                value={searchEmployee}
              />
            </div>
          </div>
          {employeeReducer && (
            <List
              className={clsx({
                [classes.listNameEmployeeSmallScreen]: matches,
              })}
            >
              <InfiniteScroll
                dataLength={employeeReducer.length - 10}
                next={fetchEmployees}
                hasMore={hasMore}
                loader={<CircularProgress className={classes.loader} />}
                endMessage={
                  <Typography variant="body2" color="textSecondary">
                    No more employees to show
                  </Typography>
                }
              >
                {employeeReducer &&
                  employeeReducer
                    .filter((item) => {
                      const fullName =
                        item.firstname_TH + " " + item.lastname_TH;
                      if (shift == "all" && site == "all") {
                        if (searchEmployee == "") {
                          return item;
                        } else return fullName.includes(searchEmployee);
                      } else if (shift != "all" && site == "all") {
                        return (
                          item.shift == shift &&
                          fullName.includes(searchEmployee)
                        );
                      } else if (shift == "all" && site != "all") {
                        return (
                          item.site == site && fullName.includes(searchEmployee)
                        );
                      } else if (shift != "all" && site != "all") {
                        return (
                          item.site == site &&
                          item.shift == shift &&
                          fullName.includes(searchEmployee)
                        );
                      }
                    })
                    .map((item) => (
                      <ListItem
                        selected={item.idEmployees == indexHover}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        key={item.idEmployees}
                        dense
                        button
                        onClick={() => {
                          setIndexHover(item.idEmployees);
                        }}
                      >
                        <ListItemAvatar>
                          {/* <Avatar alt={item.user} src={`${process.env.PUBLIC_URL}/assets/profiles/${item.idUser}.jpg`} /> */}
                          {/* Mock~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                          <Avatar
                            alt={item.username}
                            src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                          />
                          {/* Mock~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${item.firstname_TH} ${item.lastname_TH}`}
                          secondary={item.departmentName}
                        />
                      </ListItem>
                    ))}
              </InfiniteScroll>
            </List>
          )}
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={false}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default EmployeePlace;
