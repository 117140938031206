import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import {
  Modal,
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import ButtonBlue from "../../../shared/buttonBlue";
import DriveEtaIcon from "@mui/icons-material/DriveEta";

import VehicleService from "../../../../../services/vehicle.service";
import UserService from "../../../../../services/user.service";
import RouteCrossAreaCarPool from "../../../../../services/routeCrossAreaCarPool.service";
import { getAllDriver } from "../../../../../actions/user";
import { getAllVehicles } from "../../../../../actions/vehicle";

const useStyles = makeStyles((theme) => ({
  Modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Paper: {
    width: "600px",
    padding: "20px",
  },
  partOfTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    justifyContent: "space-between",
  },
  IconCar: {
    fontSize: "40px",
    marginRight: "10px",
    color: theme.palette.primary.main,
  },
  Title: {
    fontSize: "30px",
    color: theme.palette.primary.main,
  },
  partOfTitleName: {
    display: "flex",
    alignItems: "center",
  },
  partOfNavSwitch: {
    display: "flex",
    alignItems: "center",
  },
  partOfNavButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  Button: {
    width: "100px",
    marginTop: "20px",
    backgroundColor: "#00bb77",
    fontSize: "18px",
    color: "white",
    ["&:hover"]: {
      backgroundColor: "#009e67",
    },
  },
}));

const ModalManageCar = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const { result: listDrivers } = useSelector((state) => state.drivers);
  const { result: listVehicles } = useSelector((state) => state.vehicles);
  const [isDriverFromCompany, setIsDriverFromCompany] = useState(
    props.InforManageCarRoute.isDriverFromCompany
  );

  useEffect(() => {
    if (!listDrivers) {
      dispatch(getAllDriver());
    }

    if (!listVehicles) {
      dispatch(getAllVehicles());
    }
  }, []);

  const onClickSubmit = (AnswerFromInput) => {
    AnswerFromInput.isDriverFromCompany = isDriverFromCompany;
    if (!isDriverFromCompany) {
      AnswerFromInput.idDriver = null;
      AnswerFromInput.idVehicle = null;
    }
    console.log(AnswerFromInput);

    const routeLine = {
      AnswerFromInput: AnswerFromInput,
      routeLine: props.InforManageCarRoute.routeLine,
    };
    RouteCrossAreaCarPool.postEditIdDriverRoute(routeLine).then((res) => {
      if (res.data) {
        props.handleActionInPage();
        props.onCloseHandle();
        enqueueSnackbar("Data saved successfully", { variant: "success" });
      } else {
        enqueueSnackbar("Data saved failed", { variant: "error" });
      }
    });
    // props.handleActionInPage();
  };

  return (
    <Modal
      className={`${classes.Modal}`}
      open={props.open}
      onClose={props.onCloseHandle}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper className={`${classes.Paper}`}>
        <div className={`${classes.partOfTitle}`}>
          <div className={`${classes.partOfTitleName}`}>
            <DriveEtaIcon className={`${classes.IconCar}`} />
            <Typography className={`${classes.Title}`}>จัดรถ</Typography>
            {isDriverFromCompany ? (
              <Typography className={`${classes.Title}`}>ในบริษัท</Typography>
            ) : (
              <Typography className={`${classes.Title}`}>
                จากบริษัทอื่น
              </Typography>
            )}
          </div>
          <div className={`${classes.partOfNavSwitch}`}>
            <ButtonBlue
              variant="contained"
              onClick={() => {
                setValue("isDriverFromCompany", !isDriverFromCompany);
                setIsDriverFromCompany(!isDriverFromCompany);
              }}
            >
              {isDriverFromCompany
                ? "เลือกคนขับจากบริษัทอื่น"
                : "เลือกคนขับในบริษัท"}
            </ButtonBlue>
          </div>
        </div>

        <form onSubmit={handleSubmit(onClickSubmit)}>
          <Grid container spacing={3}>
            {isDriverFromCompany ? (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="label-vehicle-plate_no">
                      ทะเบียนรถ
                    </InputLabel>
                    <Controller
                      render={(propsInController) => (
                        <Select
                          label="ทะเบียนรถ"
                          labelId="label-vehicle-plate_no"
                          id="vehicle-plate_no-select"
                          error={errors.idVehicle ? true : false}
                          helperText={
                            errors.idVehicle ? "กรุณาเลือกทะเบียนรถ" : null
                          }
                          value={propsInController.field.value}
                          onChange={propsInController.field.onChange}
                        >
                          {listVehicles &&
                            listVehicles
                              .filter((value) => {
                                if (value.UseFor === "รถข้ามพื้นที่") {
                                  return value;
                                }
                              })
                              .map((vehicle) => {
                                return (
                                  <MenuItem
                                    key={vehicle.idVehicle}
                                    value={vehicle.idVehicle}
                                  >
                                    {`${vehicle.model} ${vehicle.Plate_No}`}
                                  </MenuItem>
                                );
                              })}
                        </Select>
                      )}
                      control={control}
                      name="idVehicle"
                      rules={{ required: true }}
                      defaultValue={props.InforManageCarRoute?.idVehicle}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="label-driver-name">ชื่อคนขับรถ</InputLabel>
                    <Controller
                      render={(propsInController) => (
                        <Select
                          label="ชื่อคนขับรถ"
                          labelId="label-driver-name"
                          id="driver-name-select"
                          error={errors.idDriver ? true : false}
                          helperText={
                            errors.idDriver ? "กรุณาเลือกชื่อคนขับรถ" : null
                          }
                          value={propsInController.field.value}
                          onChange={propsInController.field.onChange}
                        >
                          {listDrivers &&
                            listDrivers.map((driver) => {
                              return (
                                <MenuItem
                                  key={driver.idUser}
                                  value={driver.idUser + ""}
                                >
                                  {driver.FullName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      )}
                      control={control}
                      name="idDriver"
                      rules={{ required: true }}
                      defaultValue={props.InforManageCarRoute?.idDriver}
                    />
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        {...propsInController.field}
                        label="ทะเบียนรถ"
                        error={errors.plate_No ? true : false}
                        helperText={
                          errors && errors.plate_No && errors.plate_No.message
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    name="plate_No"
                    rules={{
                      required: { value: true, message: "กรุณากรอกทะเบียนรถ" },
                    }}
                    control={control}
                    defaultValue={props.InforManageCarRoute?.plate_No}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        {...propsInController.field}
                        label="ชื่อคนขับรถ"
                        error={errors.nameDriver ? true : false}
                        helperText={
                          errors &&
                          errors.nameDriver &&
                          errors.nameDriver.message
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    name="nameDriver"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "กรุณากรอกชื่อคนขับ",
                      },
                    }}
                    defaultValue={props.InforManageCarRoute?.nameDriver}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        {...propsInController.field}
                        label="เบอร์ติดต่อคนขับรถ"
                        error={errors.phoneDriver ? true : false}
                        helperText={
                          errors &&
                          errors.phoneDriver &&
                          errors.phoneDriver.message
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    name="phoneDriver"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "กรุณากรอกเบอร์ติดต่อคนขับ",
                      },
                    }}
                    defaultValue={props.InforManageCarRoute?.phoneDriver}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                id="DetailManageCar"
                label="หมายเหตุ"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                inputProps={{
                  ...register("DetailManageCar", {
                    value: props.InforManageCarRoute?.DetailManageCar,
                  }),
                }}
              />
            </Grid>
          </Grid>
          <div className={`${classes.partOfNavButton}`}>
            <ButtonBlue className={`${classes.Button}`} type="submit">
              จัดรถ
            </ButtonBlue>
          </div>
        </form>
      </Paper>
    </Modal>
  );
};

export default ModalManageCar;
