import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import { Typography, Grid, TextField, Paper } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import UnicornLoading from "../../../assets/loading.gif";

import InAreaCarBookingService from "../../../../../services/inAreaCarBooking.service";
import CardBooking from "./cardBooking";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    height: "calc(100% - 30px)",
    paddingTop: "20px",
  },
  partOfFilter: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  DatePicker: {
    width: "200px",
    marginRight: "15px",
  },
  partOfAllCard: {
    width: "100%",
    height: "calc(100% - 100px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15px",
    overflow: "hidden",
    overflowY: "auto",
    paddingTop: "20px",
    ["&::-webkit-scrollbar"]: {
      width: "10px",
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    ["&::-webkit-scrollbar-thumb"]: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "3px",
    },
  },
  TextNoBooking: {
    fontSize: "30px",
    textAlign: "center",
  },
  Grid: {
    width: "95%",
  },
}));

const InArea = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [AllBookingInArea, setAllBookingInArea] = useState([]);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [actionPage, setActionPage] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    setLoading(true);
    if (endDate) {
      let date = {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      };
      InAreaCarBookingService.getInAreaCarBookingByStartDateAndEndDate(date)
        .then((res) => {
          if (res.data) {
            setAllBookingInArea(res.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setAllBookingInArea([]);
          setLoading(false);
        });
    } else {
      let date = {
        startDate: new Date(startDate),
      };
      InAreaCarBookingService.getInAreaCarBookingByStartDate(date)
        .then((res) => {
          if (res.data) {
            setAllBookingInArea(res.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setAllBookingInArea([]);
          setLoading(false);
        });
    }
  }, [startDate, endDate, actionPage]);

  const onClickCard = (booking) => {
    props.history.push("/admin/car_management/in_area/detail", {
      booking: booking,
    });
  };

  const handleActionInPage = () => {
    setActionPage(!actionPage);
  };

  return (
    <div className={`${classes.DivCoverReturn}`}>
      <div className={`${classes.partOfAllCard}`}>
        <Grid container spacing={3} className={`${classes.Grid}`}>
          {!loading && AllBookingInArea.length > 0 ? (
            AllBookingInArea.map((booking) => {
              return (
                <CardBooking
                  key={booking.id}
                  booking={booking}
                  onClickCard={onClickCard}
                  handleActionInPage={handleActionInPage}
                />
              );
            })
          ) : (
            <>
              {!loading && (
                <Grid item xs={12}>
                  <Typography className={`${classes.TextNoBooking}`}>
                    ไม่มีรายการ
                  </Typography>
                </Grid>
              )}
            </>
          )}
          {loading && (
            <Grid item xs={12} container justifyContent="center">
              <Paper
                sx={{
                  overflow: "hidden",
                  border: "1px solid #EEEEEE",
                  backgroundColor: "#FFFFFF",
                  marginBottom: "20px",
                  padding: "25px 30px 25px 30px",
                  borderRadius: "15px",
                  textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                }}
              >
                <img
                  src={UnicornLoading}
                  alt="Loading..."
                  style={{ width: "300px" }}
                />
                {/* ปรับขนาดของภาพตามที่ต้องการ */}
              </Paper>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default InArea;
