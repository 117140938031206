export const getCateringStatus = (status) => {
    if(status === "pending"){
        return "รอดำเนินการ"
    }
    else if(status === "approved"){
        return "อนุมัติ"
    }
    else if(status === "disapproved"){
        return "ไม่อนุมัติ"
    }
    else{
        return ""
    }
}

export const getOptionHours = () => {
	let hours = [];
	for(let i = 0; i <= 23; i++){
		hours.push({
			value: i,
			label: String(i).padStart(2,'0')
		})
	}
	return hours;
}

export const getOptionMinutes = () => {
	let minutes = [];
	for(let i = 0; i <= 59; i++){
		minutes.push({
			value: i,
			label: String(i).padStart(2,'0')
		})
	}
	return minutes;
}

export const getOptionMaintenanceTypes = () => [
	{value: 1, label: "ซ่อมแซมทั่วไป"},
	{value: 2, label: "งานไฟฟ้า"},
	{value: 3, label: "งานประปา"},
]