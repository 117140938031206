import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { styled } from '@mui/styles';
import {
    Card, Avatar, Typography
} from '@mui/material';

import WorkIcon from '@mui/icons-material/Work';
import RoomIcon from '@mui/icons-material/Room';
import PersonIcon from '@mui/icons-material/Person';
import FlagIcon from "@mui/icons-material/Flag";
import EventSeatIcon from '@mui/icons-material/EventSeat';
import StarIcon from '@mui/icons-material/Star';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';

require("dayjs/locale/th");
dayjs.locale("th");

const CardStyled = styled(Card)(({theme})=>({
    width: "calc(100% - 16px)",
    height:"fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding:"20px 5px",
    boxShadow:theme.shadows[3],
    marginRight:"10px",
    marginBottom:"10px",
    "& .partOfNumberOfSeats":{
        width:"100%",
        display:"flex",
        justifyContent:"flex-end",
        paddingRight:"40px",
    },
}));

const AvatarStyled = styled(Avatar)({
    width:"130px",
    height:"130px",
});

const PartOfDetailOfDriver = styled("div")({
    width:"90%",
    margin:"10px 0px",
});

const PartOfTextAndIcon = styled("div")({
    display:"flex",
    alignItems:"center",
    "& .Icon":{
        fontSize:"20px",
        marginRight:"10px",
        color:"black",
    },
    "& .Text":{
        fontSize:"18px",
    },
    "& .TextNumSeats":{
        fontSize:"24px",
        color:"#28B463",
    },
});

const TextDateTime = styled(Typography)({
    color:"#D77015",
    fontSize:"20px",
});

const Textdepartment = styled(Typography)({
    color:"#909497",
});

const RateIcon = styled(StarIcon)({
    color:"#ffb400",
    fontSize:"30px",
    marginRight:"5px",
});

const TextRate = styled(Typography)({
    color:"#ffb400",
    fontSize:"24px",
});

const NumOfServices = styled(Typography)({
    color:"#797D7F",
    fontSize:"22px",
    marginLeft:"5px",
});

const SeatIcon = styled(EventSeatIcon)({
    fontSize:"32px", 
});

const CardDriver = props => {
    const { result: AllUsers } = useSelector((state) => state.users);

    const convertDate = (lawStartDate, lawEndDate) => {
        let startDateSetTime = new Date(lawStartDate).setHours(0,0,0,0);
        let endDateSetTime = new Date(lawEndDate).setHours(0,0,0,0);
        if(startDateSetTime === endDateSetTime){
          return dayjs(startDateSetTime).format("DD MMM YYYY");
        }
        else{
          let startDate = dayjs(startDateSetTime).format("DD");
          return `${startDate} - ${dayjs(endDateSetTime).format("DD MMM YYYY")}`
        }
    };

    const convertDateAndTime = (lawStartDate, lawEndDate, time) => {
        return(
            <>
                <PartOfTextAndIcon>
                    <CalendarTodayIcon className="Icon" />
                    <TextDateTime className="Text">{convertDate(lawStartDate, lawEndDate)}</TextDateTime>
                </PartOfTextAndIcon>
                <PartOfTextAndIcon>
                    <ScheduleIcon className="Icon" />
                    <TextDateTime className="Text">{dayjs(time).format(`เวลา HH.mm น.`)}</TextDateTime>
                </PartOfTextAndIcon>
            </>
        );
    };

    return(
        <CardStyled onClick={()=>props.onClickCard(props.driver.id)}>
            <AvatarStyled
                src={`${process.env.REACT_APP_URL}image/profile/${props.driver.image}`}
                alt={props.driver.image}
            />
            <PartOfDetailOfDriver>
                <PartOfTextAndIcon>
                    <PersonIcon className="Icon" />
                    <Typography className="Text" noWrap>
                        {`${props.driver.firstname} ${props.driver.lastname}`}
                    </Typography>
                </PartOfTextAndIcon>
                <PartOfTextAndIcon>
                    <WorkIcon className="Icon" />
                    <Textdepartment  className="Text" noWrap>
                        { props.driver.department }
                    </Textdepartment>
                </PartOfTextAndIcon>
                {convertDateAndTime(props.register.startDate, props.register.endDate, props.register.time)}
                <PartOfTextAndIcon>
                    <RoomIcon className="Icon" />
                    <Typography className="Text">{props.register.fromPlace}</Typography>
                </PartOfTextAndIcon>
                <PartOfTextAndIcon>
                    <FlagIcon className="Icon" />
                    <Typography className="Text">{props.register.toPlace}</Typography>
                </PartOfTextAndIcon>
                <PartOfTextAndIcon>
                    <RateIcon className="Icon"/>
                    <TextRate className="Text">{props.driver.pointFriendToFriend}</TextRate>
                    <NumOfServices>{`(30)`}</NumOfServices>
                </PartOfTextAndIcon>
            </PartOfDetailOfDriver>
            <PartOfTextAndIcon className={"partOfNumberOfSeats"}>
                <SeatIcon className={`Icon TextNumSeats`}/>
                <Typography className={`Text TextNumSeats`}>{`เหลือ ${props.register.availableSeats} ที่นั่ง`}</Typography>
            </PartOfTextAndIcon>
        </CardStyled>
    );
};

export default CardDriver;