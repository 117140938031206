import React from 'react';
import Chart from "react-apexcharts";

import utils from "../../../../../../utils";

const ChartOfReceipt = props => {
    var option = {
        series: [props.reciept,props.extra,props.delivery,props.others],
        options:{
            chart: {
              type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 270,
                        height:400,
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            dataLabels: {
                enabled: true,
            },
            plotOptions: {
                pie: {
                  expandOnClick: false,
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        show: true,
                        fontSize:"30px",
                        fontWeight:"bold",
                      },
                      value:{
                        show:true,
                        fontSize:"18px",
                        fontWeight:"bold",
                        formatter: (value) => {return (`${utils.numberWithCommas(value)} บาท`)},
                      },
                      total:{
                        show:true,
                        fontSize:"36px",
                        label:"Receipt",
                        color:"black",
                        formatter: () => {return ""},
                      },
                    },
                  },
                }
            },
            labels: ['ค่าเช่า', 'Extra', 'Delivery', 'อื่นๆ'],
        },
    };
    console.log(props.width)

    return(
        <Chart 
          series={option.series}
          options={option.options}
          type="donut"
          width="500px"
        />
    );
};

export default ChartOfReceipt;