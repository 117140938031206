import { httpClient } from "./httpClient";

const getAllRoutesDay = () => {
    return httpClient.get("routesDay");
};

const getUserRoute = (idUser) => {
    return httpClient.get("routesDay/userRoute/"+idUser);
};

const getRouteLine = (data) => {
    return httpClient.post("routesDay/routeLine",data);
};

const getRouteByIdDriver = (idDriver) => {
    return httpClient.get("routesDay/routeDriver/"+idDriver);
};

const addRoutesDay = (routeDayAdd) => {
    return httpClient.post("routeDay/add", routeDayAdd);
};

const removeUserRoutesDay = (idUser,idDriver) => {
    return httpClient.post("routeDay/removeUserRoute/"+idUser+"/"+idDriver);
};

const gettingUserComplete = (idUser,idDriver) => {
    return httpClient.post("routeDay/getting_user_complete/"+idUser+"/"+idDriver);
};

export default {
    getAllRoutesDay,
    addRoutesDay,
    getUserRoute,
    getRouteLine,
    getRouteByIdDriver,
    removeUserRoutesDay,
    gettingUserComplete
};