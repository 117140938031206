import {
    PASSENGERS_FETCHING,
    PASSENGERS_FAILED,
    PASSENGERS_SUCCESS
} from "../actions/types";

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case PASSENGERS_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case PASSENGERS_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case PASSENGERS_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
};
