import {
    DRIVERITEM_FETCHING,
    DRIVERITEM_FAILED,
    DRIVERITEM_SUCCESS,
    DRIVERS_FETCHING,
    DRIVERS_FAILED,
    DRIVERS_SUCCESS
} from "./types";

import Driver from '../services/driver.service';

export const getDriverProfile = (id) => async (dispatch) => {
    try {
        const res = await Driver.getDriverProfile(id);
        if(res){
            dispatch({
                type: DRIVERITEM_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: DRIVERITEM_FAILED,
        });
    }
};

export const getAllDriver = () => async (dispatch) => {
    try {
        const res = await Driver.getAllDriver();
        if(res){
            dispatch({
                type: DRIVERS_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: DRIVERS_FAILED,
        });
    }
};
