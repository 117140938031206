import {
    HISTORYLENTCARS_FETCHING,
    HISTORYLENTCARS_FAILED,
    HISTORYLENTCARS_SUCCESS,
} from "../actions/types";

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case HISTORYLENTCARS_FETCHING:
            return { ...state, result: null, isFetching: true, isError: false };    
        case HISTORYLENTCARS_FAILED:
            return { ...state, result: null, isFetching: false, isError: true };
        case HISTORYLENTCARS_SUCCESS:
            return { ...state, result: payload, isFetching: false, isError: false};
        default:
            return state;
    }
}