import React from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter, NavLink, Redirect, Route } from "react-router-dom";
// import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import TranslateIcon from "@mui/icons-material/Translate";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import { Box, Divider } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/IconButton";
import Logo from "../../pages/assets/unihr_logo.png";

import { logout } from "../../../actions/auth";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
    color: "#212b36",
    backgroundColor: "#ffffffcc",
    backdropFilter: "blur(6px)",

    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  media: {
    height: 10,
    // padding: 20,
    // paddingBottom: 0,
    // margin: 10,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 15,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  grow: {
    flexGrow: 1,
  },
  ButtonHome: {
    borderRadius: 0,
    "& .TextWhite": {
      color: "white",
    },
  },
  headerAction: {
    display: "flex",
    alignItems: "center",
  },
  Title: {
    color: "#212b36",
    textDecoration: "none",
  },
}));

const StyledIconButtonTranslate = styled(IconButton)(({}) => ({
  color: "#00bb77",
  backgroundColor: "transparent",
  border: "1px solid #00bb77",
  borderRadius: 8,
  padding: 8,
  marginRight: 8,
  fontSize: 28,
  // "& .MuiSvgIcon-root":{
  //   fontSize: 24,
  // }
  "&:hover": {
    transform: "scale(1.09) translateZ(0px)",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const isMenuOpen = Boolean(anchorEl);
  const [anchorEl_Trans, setAnchorEl_Trans] = React.useState(null);
  const open_Trans = Boolean(anchorEl_Trans);

  const handleClick_Trans = (event) => {
    setAnchorEl_Trans(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose_Trans = () => {
    setAnchorEl_Trans(null);
  };

  // const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    console.log(lng);
    // i18n.changeLanguage(lng);
    handleClose_Trans();
  };

  const logOut = () => {
    dispatch(logout());
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
      // keepMounted
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            backgroundColor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Box
        style={{
          width: 178,
          padding: "6px 20px",
          // margin: "12px 0",
          display: "flex",
          alignItems: "center",
          gap: 8,
        }}
      >
        <div
          style={{
            minWidth: 0,
          }}
        >
          {currentUser && (
            <Typography
              variant="h6"
              style={{
                fontSize: 16,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {currentUser && currentUser.fullname}
            </Typography>
          )}
          <Typography
            style={{
              color: "#9e9e9e",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "14px",
            }}
          >
            {currentUser && currentUser.email}
          </Typography>
        </div>
      </Box>
      <Divider />

      <MenuItem component={Link} to="/home">
        หน้าหลัก
      </MenuItem>
      <MenuItem component={Link} to="/login" onClick={logOut}>
        ออกจากระบบ
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: props.open,
        })}
      >
        <Toolbar>
          {!props.isDriver && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: props.open && classes.hide,
              })}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          )}
          {props.isDriver == true ? (
            <Button
              className={classes.ButtonHome}
              component={NavLink}
              to="/driver"
            >
              <img src={Logo} alt="Uni HR logo" width={60} />

              <Typography variant="h6">Driver</Typography>
            </Button>
          ) : (
            <Link to={"/home"}>
              <img src={Logo} alt="Uni HR logo" width={60} />
            </Link>
          )}

          <div className={classes.grow} />

          <div className={classes.headerAction}>
            <div>
              <StyledIconButtonTranslate
                aria-label="translate"
                size="small"
                aria-controls={open_Trans ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open_Trans ? "true" : undefined}
                onClick={handleClick_Trans}
              >
                <TranslateIcon fontSize="small" />
              </StyledIconButtonTranslate>
              <Menu
                anchorEl={anchorEl_Trans}
                open={open_Trans}
                onClose={handleClose_Trans}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => changeLanguage("th")}>
                  ภาษาไทย
                </MenuItem>
                <MenuItem onClick={() => changeLanguage("en")}>
                  English
                </MenuItem>
              </Menu>
            </div>
            <Divider
              style={{ margin: "0 16px", height: 24 }}
              orientation="vertical"
            />
            {/* <IconButton
              aria-label="show 17 new notifications"
              color="inherit"
              component={NavLink}
              to="/notification"
              size="large">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              size="large"
            >
              <Avatar
                alt={currentUser.username}
                src={`${process.env.REACT_APP_URL}image/profile/${currentUser.image}`}
              />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
};

export default withRouter(Header);
