import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import {
  Paper,
  Button,
  Typography,
  Grid,
  Avatar,
  useMediaQuery,
  Divider,
} from "@mui/material";

import { getCrossAreaCarBookingPassengerByIdBooking } from "../../../../../actions/crossAreaCarBookingPassenger";

import EmployeeService from "../../../../../services/employee.service.js";
require("dayjs/locale/th");
dayjs.locale("th");

const GridContainer = styled(Grid)({
  width: "100%",
});

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: "#DDDDDD",
}));

const DividerPassenger = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: theme.palette.primary.light,
  opacity: "0.5",
}));

const TextPassenger = styled(Typography)(({ theme }) => ({
  width: "100%",
  fontSize: "20px",
  color: theme.palette.primary.main,
  marginBottom: "10px",
}));

const DetailCrossAreaCar = (props) => {
  const dispatch = useDispatch();
  const { result: Passengers } = useSelector((state) => state.passengers);
  const [booking, setBooking] = useState(null);
  const [isEmployee, setEmployee] = useState(null);
  useEffect(() => {
    if (props.booking) {
      setBooking(props.booking);
      dispatch(
        getCrossAreaCarBookingPassengerByIdBooking(
          props.booking.idCrossAreaCarBooking
        )
      );
      EmployeeService.getEmployeesById(props.booking.idApproved)
        .then((res) => {
          if (res.status === 200) {
            setEmployee(res.data);
          } else {
            setEmployee([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <Fragment>
      {booking && (
        <Fragment>
          <GridContainer container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Text>{`ต้นทาง : ${booking.fromPlace}`}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>{`ปลายทาง : ${booking.toPlace}`}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>
                {`วันที่เริ่ม : ${dayjs(booking.departureDate).format(
                  "DD MMMM YYYY"
                )}`}
              </Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>
                {`เวลา : ${dayjs(booking.startTime).format(
                  " HH.mm น."
                )} - ${dayjs(booking.endTime).format(" HH.mm น.")}`}
              </Text>
            </Grid>
            {booking.returnDate && (
              <Fragment>
                <Grid item xs={12} sm={6}>
                  <Text>
                    {`วันที่สิ้นสุด : ${dayjs(booking.returnDate).format(
                      "DD MMMM YYYY"
                    )}`}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Text>
                    {`เวลา : ${dayjs(booking.returnStartTime).format(
                      " HH.mm น."
                    )} - ${dayjs(booking.returnEndTime).format(" HH.mm น.")}`}
                  </Text>
                </Grid>
              </Fragment>
            )}
          </GridContainer>
          <DividerStyled />
          {Passengers &&
            Passengers.map((passenger, index) => (
              <Fragment key={passenger.id}>
                <TextPassenger>ผู้โดยสารคนที่ {index + 1}</TextPassenger>
                <GridContainer container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Text>ชื่อ : {passenger.name}</Text>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Text>เบอร์โทรศัพท์ : {passenger.telephoneMobile}</Text>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Text>email : {passenger.email}</Text>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Text>บริษัท : {passenger.company}</Text>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Text>
                      Cost Center :{" "}
                      {passenger.costCenter && passenger.costCenter !== ""
                        ? passenger.costCenter
                        : " - "}
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Text>
                      Cost Element :{" "}
                      {passenger.costElement && passenger.costElement !== ""
                        ? passenger.costElement
                        : " - "}
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Text>จุดรับขาไป : {passenger.fromPlace}</Text>
                  </Grid>
                  {booking.flight === "twoWay" && (
                    <Grid item xs={12} sm={6}>
                      <Text>จุดรับขากลับ : {passenger.fromPlaceReturn}</Text>
                    </Grid>
                  )}
                </GridContainer>
                {Passengers.length !== index + 1 && <DividerPassenger />}
              </Fragment>
            ))}
          {!props.isManager && (
            <>
              <DividerStyled />
              {isEmployee && (
                <Fragment>
                  <GridContainer container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Text>{`ชื่อผู้อนุมัติ : ${isEmployee[0].firstname_TH} ${isEmployee[0].lastname_TH}`}</Text>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Text>{`แผนก : ${
                        isEmployee[0].departmentName
                          ? isEmployee[0].departmentName
                          : "ไม่มีแผนก"
                      }`}</Text>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Text>{`บริษัท : ${isEmployee[0].companyName}`}</Text>
                    </Grid>
                  </GridContainer>
                </Fragment>
              )}
            </>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default DetailCrossAreaCar;
