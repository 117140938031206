import React from "react";

import { styled } from '@mui/styles';
import Button from "@mui/material/Button";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const DivCoverReturn = styled("div")(({theme})=>({
  width: "100%",
  height: "70px",
  display: "flex",
  position: "relative",
  marginTop: "40px",
  marginBottom:"30px",
  "& .ButtonLeft": {
    left: "10%",
    backgroundColor: "#e1ad21",
    "&:hover":{
      backgroundColor: "#e1ad21",
    },
    [theme.breakpoints.down('sm')]:{
      left:"0",
    },
  },
  "& .ButtonRight": {
    right: "10%",
    backgroundColor: "darkgreen",
    "&:hover":{
      backgroundColor: "darkgreen",
    },
    [theme.breakpoints.down('sm')]:{
      right:"0",
    },
  },
  [theme.breakpoints.down('sm')]:{
    marginTop: "80px",
  },
}));

const ButtonStyled = styled(Button)(({theme})=>({
  width: "130px",
  height: "100%",
  fontSize: "22px",
  position: "absolute",
  color: "white",
}));

const NavButtonCardGivingPoints = (props) => {

  return (
    <DivCoverReturn>
      {props.currentOrder !== 0 && (
        <ButtonStyled
          className="ButtonLeft"
          onClick={props.onClickPreviousHandler}
        >
          <KeyboardArrowLeftIcon />
          กลับ
        </ButtonStyled>
      )}
      {props.currentOrder !== props.maxOrder - 1 && (
        <ButtonStyled
          className="ButtonRight"
          onClick={props.onClickNextHandler}
        >
          ถัดไป
          <KeyboardArrowRightIcon />
        </ButtonStyled>
      )}
      {props.countPeopleRated === props.maxOrder && props.currentOrder === props.maxOrder - 1 && (
        <ButtonStyled
          className="ButtonRight"
          onClick={props.OnClickSubmitRatingButton}
        >
          ยืนยัน
        </ButtonStyled>
      )}
    </DivCoverReturn>
  );
};

export default NavButtonCardGivingPoints;
