import React, { useState, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import { useSelector } from "react-redux";

import { styled } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { SnackbarProvider, useSnackbar } from "notistack";
import GridCard from "../../shared/GridCard";
import ButtonBlue from "../../shared/buttonBlue";

import DialogEmergency from "./dialogEmergency";
import DialogUpdateEmergency from "./dialogUpdateEmergency";
import driverEmergencyService from "../../../../services/driverEmergency.service";

const DivCoverReturn = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  position: "relative",
});

const DivCoverContent = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  position: "absolute",
  zIndex: "10",
});

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "70px",
  color: "white",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    fontSize: "50px",
  },
}));

const TextSmall = styled(Typography)(({ theme }) => ({
  fontSize: "25px",
  color: "white",
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  width: "200px",
  height: "200px",
  color: "white",
  borderRadius: "50%",
  fontSize: "45px",
  boxShadow: "0 0 10px 20px black",
  backgroundImage: "radial-gradient(circle,#ff0000,rgb(128,0,32))",
  marginTop: "20px",
  zIndex: "10",
  [theme.breakpoints.up("lg")]: {
    width: "500px",
    height: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "300px",
    height: "300px",
  },
}));

const AlertBackground = styled("div")({
  width: "100%",
  height: "calc(100% - 64px)",
  backgroundColor: "rgba(255,0,0,0.3)",
  position: "absolute",
  zIndex: "1",
  animationName: "alertLoop",
  animationDuration: "4s",
  animationIterationCount: "infinite",
});

const ImageBackground = styled("img")({
  width: "100%",
  height: "100%",
  zIndex: "0",
});

const EmergencyPage = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const [emergency, setEmergency] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const ListEmergency = [
    "เกิดอุบัติเหตุ",
    "รถเสีย/น้ำมันหมด/ยางแบน",
    "คนขับมีอาการเพลีย",
    "ผู้โดยสารมีปัญหา",
    "อื่นๆ",
  ];

  useEffect(() => {
    if (currentUser) {
      driverEmergencyService
        .getDriverEmergencyThatIsNotFinishByIdDriver(currentUser.idUser)
        .then((res) => {
          if (res.status === 200) {
            setEmergency(res.data);
          } else {
            setEmergency(null);
          }
        })
        .catch((err) => {
          console.log(err);
          setEmergency(null);
        });
    }
  }, [currentUser, openDialog, openDialogUpdate]);

  const onClickEmergencyHandler = (index) => {
    console.log(index);
    const cause = {
      idDriver: currentUser.idUser,
      cause: ListEmergency[index],
    };
    driverEmergencyService
      .postNewDriverEmergency(cause)
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("Report Success", { variant: "success" });
          setOpenDialog(false);
        } else {
          enqueueSnackbar("Report Fail", { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Report Fail", { variant: "error" });
        console.log(err);
      });
  };

  const onClickUpdateEmergencyHandler = () => {
    driverEmergencyService
      .getUpdateDriverEmergency(emergency.idDriverEmergency)
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("Update Success", { variant: "success" });
          setOpenDialogUpdate(false);
          props.history.push("/driver");
        } else {
          enqueueSnackbar("Update Fail", { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Update Fail", { variant: "error" });
        console.log(err);
      });
  };
  const onClickButtonHandler = () => {
    setOpenDialog(true);
  };

  const onClickButtonUpdateEmergencyHandler = () => {
    setOpenDialogUpdate(true);
  };

  return (
    <DivCoverReturn className="page">
      <DivCoverContent>
        <Text>แจ้งเหตุฉุกเฉิน</Text>
        {emergency === null ? (
          <ButtonStyled onClick={onClickButtonHandler}>Emergency</ButtonStyled>
        ) : (
          <Grid
            container
            spacing={2}
            style={{
              width: "90%",
              maxWidth: "600px",
              transition:
                "transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
              boxShadow:
                "0 2px 4px 0 rgb(35 31 32 / 20%), 0 0 1px 0 rgb(35 31 32 / 10%)",
              position: "relative",
              display: "flex",
              borderRadius: "15px",
              marginLeft: "5px",
              marginRight: "5px",
              border: "1px solid #EEEEEE",
              padding: "0px 16px 16px 0px",
              backgroundColor: "black",
              marginTop: "20px",
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextSmall> เหตุ: {emergency.causeEmergency}</TextSmall>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextSmall>
                สถานะ:{" "}
                {emergency.isActive
                  ? "กําลังดําเนินการ"
                  : "ดําเนินการเสร็จสิ้น"}
              </TextSmall>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <ButtonBlue
                variant="contained"
                color="warning"
                style={{ marginRight: "10px" }}
                onClick={onClickButtonHandler}
              >
                แก้ไขเหตุ
              </ButtonBlue>

              <ButtonBlue
                variant="contained"
                onClick={onClickButtonUpdateEmergencyHandler}
              >
                เหตุการณ์สิ้นสุด
              </ButtonBlue>
            </Grid>
          </Grid>
        )}
        {console.log(props)}
      </DivCoverContent>
      <ReactAudioPlayer
        src="./assets/music/Alarm-Slow.mp3"
        autoPlay
        volume={0.2}
      />
      <DialogEmergency
        openDialog={openDialog}
        OpenDialogHandler={setOpenDialog}
        onClickEmergencyHandler={onClickEmergencyHandler}
        ListDialog={ListEmergency}
      />
      <DialogUpdateEmergency
        openDialog={openDialogUpdate}
        OpenDialogHandler={setOpenDialogUpdate}
        onClickEmergencyHandler={onClickUpdateEmergencyHandler}
      />
      <AlertBackground></AlertBackground>
      <ImageBackground
        src="./assets/background/cautionBackground.jpg"
        alt="cautionBackground"
      />
    </DivCoverReturn>
  );
};

export default EmergencyPage;
