import React from 'react';

import { styled } from '@mui/styles';
import { 
    Avatar, Typography, Button,
} from '@mui/material';

const DivCoverReturn = styled("div")({
    height:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
});

const AvatarStyled = styled(Avatar)(({theme})=>({
    width: "300px",
    height: "300px",
    "& img": {
        objectPosition: "5px 6px",
    },
    boxShadow:theme.shadows[3],
    backgroundColor:"white",
}));

const ButtonStyled = styled(Button)({
    width:"100%",
    color:"white",
    fontSize:"30px",
    marginTop:"30px",
    backgroundColor:"green",
    "&:hover":{
        backgroundColor:"darkgreen",
    }
});

const ConFirmCarText = styled(Typography)({
    conFirmCarText:{
        marginTop:"30px",
        fontSize:"30px",
    },
});

const CarVerification = (props) => {
    return(
        <DivCoverReturn>
            <AvatarStyled
                src={`${process.env.REACT_APP_URL}image/car/car1.png`}
                alt="car1.png"
            />
            {/* <ConFirmCarText>ยืนยันให้รถมารับ</ConFirmCarText> */}
            <ButtonStyled onClick={props.onClickNextPage}>เรียกรถ</ButtonStyled>
        </DivCoverReturn>
    );
};

export default CarVerification;