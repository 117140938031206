import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/styles";
import { Grid, Typography, Divider, Chip } from "@mui/material";
import { Title } from "@material-ui/icons";
import {
  Paper,
  Button,
  Avatar,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stack from "@mui/material/Stack";
import GoogleMapReact from "google-map-react";
import {
  GoogleAPIkey,
  locationSite1,
} from "../../../../../../../src/utils/googleMapAssets";

import {
  postUpdateDeliveryStatus,
  getDeliverySampleshuttleByIdDriver,
  getDeliverySampleshuttleByIdUser,
} from "../../../../../../actions/deliverySampleshuttle";

import {
  getSitebyName,
  addLocationDriver,
} from "../../../../../../actions/site.js";
import { getLocationDriverById } from "../../../../../../actions/site.js";

import useGeoLocation from "../../../../driver/Sample/NewSample/CardDetailSample/useGeoLocation/index.js";
import UserServices from "../../../../../../services/user.service";
import ModalShowmap from "./showMap/index.js";
import GoogleApi from "./showMap/index.js";
import deliverySampleshuttleService from "../../../../../../services/deliverySampleshuttle.service.js";
require("dayjs/locale/th");
dayjs.locale("th");

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const DivCoverReturn = styled("div")({
  height: "fit-content",
  display: "Grid",
  justifyContent: "center",
  marginTop: "20px",
  width: "100%",
  borderRadius: "10px",
});

const PaperStyled = styled(Paper)(({ theme }) => ({
  // width: "100%",
  maxWidth: "100%",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: "20px",
  margin: "10px 50px 50px 50px",
  boxShadow: theme.shadows[3],
  position: "relative",
  padding: "0 20px",
  "& .BackButton": {
    width: "fit-content",
    color: "black",
    position: "absolute",
    top: "13px",
    left: "0",
    padding: "0 25px",
    ["&:hover"]: {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      top: "9px",
      padding: "0 10px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "0 15px",
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  position: "absolute",
  top: "13px",
  left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));
const ViewButton = styled(Button)({
  border: "1.75px solid #1a5289",
  borderRadius: "5px",
  color: "black",
  width: "100%",
  padding: "10px",
  fontWeight: "bolder",
  backgroundColor: "#1976d2",
  color: "white",
  ["&:hover"]: {
    backgroundColor: "#1a5289",
    color: "white",
  },
});

const ButtonStyled = styled(Button)(({ theme }) => ({
  width: "150px",
  height: "50px",
  color: "white",
  fontSize: "20px",
  [theme.breakpoints.down("sm")]: {
    width: "fit-Content",
  },
}));

const GridContainer = styled(Grid)({
  width: "100%",
  height: "100%",
});

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  height: "100%",
  width: "300px",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  color: "#637381",
  fontWeight: "600",
}));
const TextFrom = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  color: "#212b36",
  fontWeight: "600",
  marginLeft: "5px",
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  // backgroundColor: "#DDDDDD",
  borderStyle: "dashed",
  borderWidth: "0px 0px thin",
  borderColor: "rgba(145, 158, 171, 2)",
}));

const GridNavButton = styled(Grid)(({ theme }) => ({
  "& .DisapproveButton": {
    backgroundColor: "red",
    ["&:hover"]: {
      backgroundColor: "darkred",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .ApproveButton": {
    backgroundColor: "green",
    ["&:hover"]: {
      backgroundColor: "darkgreen",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "10px",
  },
}));

const GridButton = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

const Mumu = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  margin: "10px 0",
  fontWeight: "bolder",
}));
const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  margin: "10px 0",
  fontWeight: "bolder",
  color: "black",
}));
const DriverCardSampleDetail = (props) => {
  const [booking, setBooking] = useState(null);
  const { result: listBooking } = useSelector((state) => state.bookings);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isDriver, setDriver] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(async () => {
    deliverySampleshuttleService
      .getDeliverySampleshuttleByIdBooking(props.location.state.idBooking)
      .then((res) => {
        if (res.data) {
          setBooking(res.data);
        }
      });
  }, []);

  useEffect(async () => {
    if (booking && booking.idDriver) {
      const data = await UserServices.getDriverProfile(booking.idDriver)
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            setDriver([res.data]);
          }
        })
        .catch((error) => {
          console.log(error);
          setDriver([]);
        });
    } else {
      console.log("no driver found");
    }
  }, [booking]);

  console.log("//////////////////");

  console.log(booking);
  const onClickUpdate = async () => {
    const myData = {
      id: booking.idDeliverySampleShuttle,
      idDriver: currentUser.idUser,
    };

    deliverySampleshuttleService
      .postUpdateDeliveryStatus(myData)
      .then((res) => {
        if (res.status === 200) {
          props.history.push(`/sample`);
        }
      });
  };
  const onCloseModalHandle = () => {
    setOpenModal(false);
  };

  const onClickOpenModal = () => {
    setOpenModal(true);
  };

  const checkIsPicture = (index) => {
    const paths = JSON.parse(booking.path);
    const tailName = paths[index].fileName.split(".")[1];
    if (tailName === "jpg" || tailName === "png" || tailName === "jpeg") {
      return true;
    }
    return false;
  };

  const takeAtl = (index) => {
    const paths = JSON.parse(booking.path);
    return paths[index].fileName;
  };
  console.log(booking);
  console.log("111111");
  return (
    <DivCoverReturn className="page">
      {booking && (
        <>
          <PaperStyled>
            <BackButton disableRipple onClick={props.history.goBack}>
              <ArrowBackIcon className="Icon" />
            </BackButton>
            <Text
              style={{ fontSize: "25px", marginTop: "15px", color: "black" }}
            >
              รายละเอียด
            </Text>
            <GridContainer container>
              <Grid container item xs={12} md={6}></Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                alignContent="center"
                spacing={2}
              >
                <Grid item xs={12}></Grid>
              </Grid>
            </GridContainer>

            <DividerStyled />
            <GridContainer container spacing={1}>
              <Grid item xs={12}>
                <Mumu>รายละเอียดตัวอย่าง</Mumu>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                {/* <Text>{`ชื่อตัวอย่าง : ${booking.nameSample}`}</Text> */}
                <Text>{`ชื่อตัวอย่าง :`}</Text>
                <TextFrom>{`${booking.nameSample}`}</TextFrom>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                {/* <Text>{`Grade : ${booking.nameGrade}`}</Text> */}
                <Text>{`Grade :`}</Text>
                <TextFrom>{`${booking.nameGrade}`}</TextFrom>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                {/* <Text>{`LotNumber : ${booking.lotNumber}`}</Text> */}
                <Text>{`LotNumber :`}</Text>
                <TextFrom>{`${booking.lotNumber}`}</TextFrom>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                <LocationOnIcon></LocationOnIcon>
                {/* <Text
              style={{ marginLeft: "4px" }}
            >{`จุดรับของต้นทาง : ${booking.fromPlace}`}</Text> */}
                <Text>{`จุดรับของต้นทาง :`}</Text>
                <TextFrom>{`${booking.fromPlaceName}`}</TextFrom>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                <LocationOnIcon></LocationOnIcon>
                {/* <Text
              style={{ marginLeft: "4px" }}
            >{`จุดส่งของ : ${booking.toPlace}`}</Text> */}
                <Text>{`จุดส่งของปลายทาง : `}</Text>
                <TextFrom>{`${booking.toPlaceName}`}</TextFrom>
              </Grid>
              <Grid item xs={12}>
                {/* <Text>{`รายละเอียดเพิ่มเติม : ${booking.detail}`}</Text> */}
                <Text>{`รายละเอียดเพิ่มเติม : `}</Text>
                <TextFrom>{`${
                  booking.detail === null || booking.detail === ""
                    ? "-"
                    : booking.detail
                }`}</TextFrom>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "grid", alignItems: "center" }}
              >
                <Text style={{ marginRight: "5px" }}>{`ไฟล์เพิ่มเติม : `}</Text>
                {console.log(booking.urlFiles)}
                {booking.urlFiles != undefined && (
                  <div
                    key={`DeliverySampleShuttle${booking.idDeliverySampleShuttle}`}
                  >
                    <Stack direction="column" spacing={1}>
                      {booking.urlFiles != null && (
                        <>
                          <Stack direction="row" spacing={1}>
                            {booking.urlFiles.map((value, index) => (
                              <>
                                {checkIsPicture(index) === true && (
                                  <a
                                    href={value}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      height: "20%",
                                      width: "20%",
                                      margin: "5px",
                                    }}
                                  >
                                    <img
                                      src={`${value}`}
                                      alt={`${takeAtl(index)}`}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        margin: "5px",
                                      }}
                                    />
                                  </a>
                                )}
                              </>
                            ))}
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            {booking.urlFiles.map((value, index) => (
                              <>
                                {checkIsPicture(index) === false && (
                                  <Chip
                                    key={`file_${index}`}
                                    label={`${takeAtl(index)}`}
                                    color="primary"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    component="a"
                                    href={value}
                                    clickable
                                  />
                                )}
                              </>
                            ))}
                          </Stack>
                        </>
                      )}
                    </Stack>
                  </div>
                )}
                {booking.urlFiles === undefined ||
                  booking.urlFiles === null ||
                  (booking.urlFiles.length === 0 && (
                    <div
                      key={`DeliverySampleShuttle${booking.idDeliverySampleShuttle}`}
                    >
                      <TextFrom>ไม่มีไฟล์เพิ่มเติม</TextFrom>
                    </div>
                  ))}
                {booking.isExp === 1 && (
                  <div
                    key={`DeliverySampleShuttle${booking.idDeliverySampleShuttle}`}
                  >
                    <>
                      <TextFrom>ขออภัย: ไฟล์หมดอายุ</TextFrom>
                    </>
                  </div>
                )}
              </Grid>

              <DividerStyled />
              <Grid item xs={12}>
                <Mumu>รายละเอียดผู้ส่ง</Mumu>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                {/* <Text>{`ชื่อผู้ส่ง : ${booking.nameSender}`}</Text> */}
                <Text>{`ชื่อผู้ส่ง :`}</Text>
                <TextFrom>{`${booking.nameSender}`}</TextFrom>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                {/* <Text>{`ชื่อผู้ส่ง : ${booking.nameSender}`}</Text> */}
                <Text>{`เบอร์ผู้ส่ง :`}</Text>
                <TextFrom>{`${booking.phoneSender}`}</TextFrom>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                {/* <Text>{`วันที่ส่ง : ${new Date(booking.date).toLocaleDateString(
              "th-TH",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}`}</Text> */}
                <Text>{`วันที่ส่ง :`}</Text>
                <TextFrom>{`${new Date(booking.date).toLocaleDateString(
                  "th-TH",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }
                )}`}</TextFrom>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                            <Text>{`เบอร์โทรผู้ส่ง : ${booking.phoneSender}`}</Text>
                        </Grid>
                      
                        <Grid item xs={12} md={6}>
                            <Text>{`เวลารับของ : ${booking.startTime}`}</Text>
                        </Grid> */}

              <DividerStyled />
              <Grid item xs={12}>
                <Mumu>รายละเอียดผู้รับ</Mumu>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                {/* <Text>{`ชื่อผู้รับ : ${booking.nameRecipient}`}</Text> */}
                <Text>{`ชื่อผู้รับ : `}</Text>
                <TextFrom>{`${booking.nameRecipient}`}</TextFrom>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                {/* <Text>{`ชื่อผู้ส่ง : ${booking.nameSender}`}</Text> */}
                <Text>{`เบอร์ผู้รับ :`}</Text>
                <TextFrom>{`${booking.phoneRecipient}`}</TextFrom>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                            <Text>{`เบอร์โทรผู้รับ : ${booking.phoneRecipient}`}</Text>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Text>{`วันที่ส่ง : ${booking.startTime}`}</Text>
                        </Grid> */}

              <DividerStyled />

              <Grid item xs={12}>
                <Mumu>รายละเอียดคนขับรถ</Mumu>
              </Grid>
              {isDriver[0] != undefined && (
                <>
                  <Grid item xs={12} md={6} style={{ display: "flex" }}>
                    {/* <Text>{`ชื่อผู้รับ : ${booking.nameRecipient}`}</Text> */}
                    <Text>{`ชื่อคนขับ : `}</Text>
                    <TextFrom>{`${isDriver[0].firstname} ${isDriver[0].lastname}`}</TextFrom>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ display: "flex" }}>
                    {/* <Text>{`ชื่อผู้รับ : ${booking.nameRecipient}`}</Text> */}
                    <Text>{`เบอร์คนขับ : `}</Text>
                    <TextFrom>{`${isDriver[0].mobileNumber}`}</TextFrom>
                  </Grid>
                </>
              )}
              {isDriver[0] === undefined && (
                <>
                  <Grid item xs={12} md={6} style={{ display: "flex" }}>
                    {/* <Text>{`ชื่อผู้รับ : ${booking.nameRecipient}`}</Text> */}
                    <Text>{`ชื่อคนขับรถ : `}</Text>
                    <TextFrom
                      style={{ color: "#d42121" }}
                    >{`กำลังหาคนขับรถ`}</TextFrom>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ display: "flex" }}>
                    {/* <Text>{`ชื่อผู้รับ : ${booking.nameRecipient}`}</Text> */}
                    <Text>{`เบอร์คนขับรถ : `}</Text>
                    <TextFrom
                      style={{ color: "#d42121" }}
                    >{`กำลังหาคนขับรถ`}</TextFrom>
                  </Grid>
                </>
              )}
              <DividerStyled />

              <Grid item xs={12}>
                <Mumu>สถานะการส่งตัวอย่าง</Mumu>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                {/* <Text>{`สถานะ : ${booking.status}`}</Text> */}
                <Text>{`สถานะ : `}</Text>
                {booking.status === "ได้รับสินค้าเรียบร้อย" && (
                  <TextFrom
                    style={{ color: "#24944f" }}
                  >{`${booking.status}`}</TextFrom>
                )}
                {booking.status === "รอรับสินค้า" && (
                  <TextFrom
                    style={{ color: "#d42121" }}
                  >{`${booking.status}`}</TextFrom>
                )}
                {booking.status === "รับสินค้าเรียบร้อย" && (
                  <TextFrom
                    style={{ color: "#dfcf20" }}
                  >{`${booking.status}`}</TextFrom>
                )}
                {booking.status === "ส่งสินค้าเรียบร้อย" && (
                  <TextFrom
                    style={{ color: "#d47721" }}
                  >{`${booking.status}`}</TextFrom>
                )}
              </Grid>

              {booking.status === "ส่งสินค้าเรียบร้อย" && (
                <Grid item xs={12} md={6}>
                  <Text>{`ส่งของสำเร็จ : ${new Date(
                    booking.date
                  ).toLocaleDateString("th-TH", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}`}</Text>
                </Grid>
              )}
              {booking.status === "ได้รับสินค้าเรียบร้อย" && (
                <Grid item xs={12} md={6}>
                  <Text>{`ส่งของสำเร็จ : ${new Date(
                    booking.date
                  ).toLocaleDateString("th-TH", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}`}</Text>
                </Grid>
              )}
              {booking.status !== "ส่งสินค้าเรียบร้อย" && (
                <Grid item xs={12} md={6}>
                  <Text>{`ส่งของสำเร็จ : ---`}</Text>
                </Grid>
              )}
              {booking.status === "รอรับสินค้า" && (
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={onClickUpdate}
                  >
                    กดรับสินค้า
                  </Button>
                </Grid>
              )}
              {booking.status === "รับสินค้าเรียบร้อย" && (
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={onClickUpdate}
                  >
                    กดส่งสินค้า
                  </Button>
                </Grid>
              )}
              {booking.status === "ส่งสินค้าเรียบร้อย" && (
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    disabled={true}
                    onClick={onClickUpdate}
                  >
                    กดส่งสินค้า
                  </Button>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <ViewButton onClick={onClickOpenModal}>แสดงแผนที่</ViewButton>
              </Grid>
            </GridContainer>
          </PaperStyled>
          <GoogleApi
            open={openModal}
            onCloseHandle={onCloseModalHandle}
            inforInCar={booking}
          />
        </>
      )}
    </DivCoverReturn>

    //     )
    // })

    // <Fragment>
    //     {
    //         <Fragment>
    //             <GridContainer container spacing={1}>
    //                 <Grid item xs={12} sm={6}>
    //                     {/* <Text>{`ต้นทาง : ${booking.fromPlace}`}</Text> */}
    //                 </Grid>
    //                 <Grid item xs={12} sm={6}>
    //                     {/* <Text>{`ปลายทาง : ${booking.toPlace}`}</Text> */}
    //                 </Grid>
    //                 <Grid item xs={12} sm={6}>
    //                     <Text>
    //                         {/* {`วันที่เริ่ม : ${dayjs(booking.departureDate).format("DD MMMM YYYY")}`} */}
    //                     </Text>
    //                 </Grid>
    //                 <Grid item xs={12} sm={6}>
    //                     <Text>
    //                         {/* {`เวลา : ${dayjs(booking.startTime).format(" HH.mm น.")} - ${dayjs(booking.endTime).format(" HH.mm น.")}`} */}
    //                     </Text>
    //                 </Grid>

    //             </GridContainer>
    //             <DividerStyled />

    //         </Fragment>
    //     }
    // </Fragment>
  );
};

export default DriverCardSampleDetail;
