import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";

import RegisterOfDriverService from "../../../../../../services/registerOfDriver.service";
import { getAllUsers } from "../../../../../../actions/user";
import CardDriver from "./cardDriver";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    marginTop: "20px",
  },
  Content: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  Title: {
    fontSize: "35px",
  },
  NavButton: {
    width: "100%",
    display: "flex",
    marginTop: "10px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  NavButtonLeft: {
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  NavButtonRight: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "20px",
    marginTop: "-15px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
    },
    "& .MuiInputBase-root": {
      width: "170px",
      height: "50px",
    },
  },
  Button: {
    height: "50px",
    marginBottom: "-1px",
    padding: "10px 20px",
    border: "1px solid black",
    borderRadius: "4px 4px 0 0",
    borderBottom: "1px solid white",
    ["&:hover"]: {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  ButtonNotClicked: {
    backgroundColor: "#CACFD2",
    borderBottom: "1px solid black",
    ["&:hover"]: {
      backgroundColor: "#F0F3F4",
    },
  },
  ButtonWatchMyList: {
    width: "270px",
    minWidth: "270px",
    backgroundColor: "#F39C12",
    border: "0px",
    borderRadius: "4px",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#F39C12",
    },
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
    },
  },
  TextButton: {
    fontSize: "18px",
  },
  Icon: {
    fontSize: "20px",
    marginRight: "10px",
    color: "black",
  },
  IconButton: {
    fontSize: "30px",
  },
  ImgIcon: {
    width: "30px",
    height: "30px",
    marginRight: "10px",
  },
  partOfGrid: {
    width: "100%",
    height: "74vh",
    display: "flex",
    justifyContent: "flex-start",
    borderTop: "1px solid black",
    padding: "10px 0 0",
  },
  Grid: {
    width: "100%",
    height: "fit-content",
    paddingRight: "10px",
    paddingBottom: "10px",
    marginLeft: "0px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  Card: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 5px",
    boxShadow: theme.shadows[3],
  },
  Avatar: {
    width: "130px",
    height: "130px",
  },
  partOfDetailOfDriver: {
    width: "90%",
    margin: "10px 0px",
  },
  Text: {
    fontSize: "18px",
  },
  partOfTextAndIcon: {
    display: "flex",
    alignItems: "center",
  },
  TextDateTime: {
    color: "#D77015",
    fontSize: "20px",
  },
  Textdepartment: {
    color: "#909497",
  },
  RateIcon: {
    color: "#ffb400",
    fontSize: "30px",
    marginRight: "5px",
  },
  TextRate: {
    color: "#ffb400",
    fontSize: "24px",
  },
  NumOfServices: {
    color: "#797D7F",
    fontSize: "22px",
    marginLeft: "5px",
  },
  partOfNumberOfSeats: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "40px",
  },
  TextNumSeats: {
    fontSize: "24px",
    color: "#28B463",
  },
  SeatIcon: {
    fontSize: "32px",
  },
  TextListButton: {
    color: "white",
  },
}));

const SelectionDrivers = (props) => {
  const classes = useStyles();
  const [listOfDriver, setlistOfDriver] = useState([]);
  const [typeChosen, setTypeChosen] = useState("GoToWork");
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    let data = {
      type: typeChosen,
      startDate: date,
    };
    RegisterOfDriverService.getRegistersOfDriverByTypeAndDate(data).then(
      (res) => {
        if (res.data) {
          console.log(res);
          setlistOfDriver(res.data);
        }
      }
    );
  }, [typeChosen, date]);

  const onClickTypeButton = (type) => {
    if (type === "GoToWork") {
      setTypeChosen("GoToWork");
    } else {
      setTypeChosen("ComeBackHome");
    }
  };

  const onClickWaitingResponses = () => {
    props.history.push("/friend2friend_Car/passenger/waiting_responses");
  };

  const onClickCard = (orderid) => {
    props.history.push("/friend2friend_Car/passenger/showDetail_driver", {
      orderId: orderid,
      joined: false,
    });
  };

  return (
    <div className={`page ${classes.DivCoverReturn}`}>
      <div className={`${classes.Content}`}>
        <Typography className={`${classes.Title}`}>
          รายชื่อของคนขับรถ
        </Typography>
        <div className={`${classes.NavButton}`}>
          <div className={`${classes.NavButtonLeft}`}>
            <Button
              className={clsx(classes.Button, {
                [classes.ButtonNotClicked]: typeChosen !== "GoToWork",
              })}
              onClick={() => onClickTypeButton("GoToWork")}
            >
              <div className={`${classes.partOfTextAndIcon}`}>
                <EmojiTransportationIcon
                  className={`${classes.Icon} ${classes.IconButton}`}
                />
                <Typography className={`${classes.TextButton}`}>
                  ไปที่ทำงาน
                </Typography>
              </div>
            </Button>
            <Button
              className={clsx(classes.Button, {
                [classes.ButtonNotClicked]: typeChosen === "GoToWork",
              })}
              onClick={() => onClickTypeButton("ComeBackHome")}
            >
              <div className={`${classes.partOfTextAndIcon}`}>
                <HomeIcon className={`${classes.Icon} ${classes.IconButton}`} />
                <Typography className={`${classes.TextButton}`}>
                  กลับที่พัก
                </Typography>
              </div>
            </Button>
          </div>
          {console.log(date)}
          <div className={`${classes.NavButtonRight}`}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                inputFormat="dd/MM/yyyy"
                minDate={new Date()}
                value={date}
                onChange={(e) => setDate(e)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
            <Button
              className={`${classes.Button} ${classes.ButtonWatchMyList}`}
              onClick={onClickWaitingResponses}
            >
              <div className={`${classes.partOfTextAndIcon}`}>
                <img
                  className={`${classes.ImgIcon}`}
                  src={`../../assets/Image/Icon/waitingList.png`}
                  alt="waitingList.png"
                />
                <Typography
                  className={`${classes.TextButton} ${classes.TextListButton}`}
                >
                  รายการรออนุมัติของฉัน
                </Typography>
              </div>
            </Button>
          </div>
        </div>
        <div className={`${classes.partOfGrid}`}>
          <div style={{ width: "100%", paddingTop: "10px", overflowY: "auto" }}>
            <Grid container spacing={2} className={`${classes.Grid}`}>
              {listOfDriver.length > 0 &&
                listOfDriver.map((value) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={value.id}>
                      <CardDriver
                        driver={value.driver}
                        register={value}
                        onClickCard={onClickCard}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectionDrivers;
