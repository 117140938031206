import { httpClient } from "./httpClient";

const getAllVehicleBrandsAndModels = () => {
  return httpClient.get("vehicle_brandsAndmodels");
};

const getAllVehicleBrandsAndModelsByIdTypeCar = (idTypeCar) => {
  return httpClient.get("vehicle_brandsAndmodels_byIdTypeCar/"+idTypeCar);
};

const getVehicleBrandAndModelById = (Id) => {
  return httpClient.get("vehicle_BrandAndModel_byId/"+Id);
};

const getVehicleBrandAndModelByUseFor = (UseForEn) => {
  return httpClient.get("vehicle_BrandAndModel_byUseForEn/"+UseForEn);
};

const getVehicleBrandAndModelByTypeCarAndUseFor = (UseForEn, VehicleType) => {
  return httpClient.get("vehicle_BrandAndModel_byUseForEn_VehicleType/"+UseForEn+"/"+VehicleType);
};

export default {
  getAllVehicleBrandsAndModels,
  getAllVehicleBrandsAndModelsByIdTypeCar,
  getVehicleBrandAndModelById,
  getVehicleBrandAndModelByUseFor,
  getVehicleBrandAndModelByTypeCarAndUseFor
};
