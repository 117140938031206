import React from 'react';
import { useSelector } from "react-redux";

import makeStyles from '@mui/styles/makeStyles';
import { Avatar, Typography } from "@mui/material";
import Rating from '@mui/material/Rating';

const useStyles = makeStyles((theme)=>({
    partOfDetailDriver:{
        width:"30%",
        height:"calc(100% - 40px)",
        border:"1px solid #CCD1D1",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        padding:"20px 5px ",
    },
    Avatar:{
        width:"150px",
        height:"150px",
    },
    ContentText:{
        width:"80%",
        marginTop:"20px",
    },
    partOfAge:{
        display:"flex",
        marginBottom:"5px",
    },
    Text:{
        fontSize:"25px",
        marginBottom:"5px",
    },
    TextAge:{
        marginRight:"15px",
    },
    HighlightText:{
        fontSize:"30px",
        marginBottom:"5px",
        fontWeight:"bold",
    },
}));

const DetailEmployeeComponent = (props) => {
    const classes = useStyles();
    const AllEmployees = useSelector(state => state.employees);

    return(
        <div className={`${classes.partOfDetailDriver}`}>
            {props.idUserClicked !== 0 && (
                <>
                {console.log(AllEmployees.result[props.idUserClicked-1])}
                    <Avatar 
                        className={`${classes.Avatar}`}
                        src={`${process.env.REACT_APP_URL}image/profile/${AllEmployees.result[props.idUserClicked-1].image}`}
                        alt={AllEmployees.result[props.idUserClicked-1].image}
                    />
                    <div className={`${classes.ContentText}`}>
                        <Typography className={`${classes.HighlightText}`}>
                            {AllEmployees.result[props.idUserClicked-1].fNameThai}
                        </Typography>
                        <Typography className={`${classes.Text}`}>
                            {AllEmployees.result[props.idUserClicked-1].position}
                        </Typography>
                        <Typography className={`${classes.Text}`}>
                            {`${AllEmployees.result[props.idUserClicked-1].departmentShortName} , ${AllEmployees.result[props.idUserClicked-1].workingLocation}`}
                        </Typography>
                        <div className={`${classes.partOfAge}`}>
                            <Typography className={`${classes.Text} ${classes.TextAge}`}>
                                {`อายุ ${AllEmployees.result[props.idUserClicked-1].AgeYear}/${AllEmployees.result[props.idUserClicked-1].AgeMonth}`}
                                {/* {`อายุ 33/10`} */}
                            </Typography>
                            <Typography className={`${classes.Text}`}>
                                {`อายุงาน ${AllEmployees.result[props.idUserClicked-1].AgeWorkingYear}/${AllEmployees.result[props.idUserClicked-1].AgeWorkingMonth}`}
                                {/* {`อายุงาน 4/2`} */}
                            </Typography>
                        </div>
                        <Typography className={`${classes.Text}`}>
                            {`Email : ${AllEmployees.result[props.idUserClicked-1].email}`}
                        </Typography>
                        <Typography className={`${classes.Text}`}>
                            {`Tel. ${AllEmployees.result[props.idUserClicked-1].mobileNumber}`}
                        </Typography>
                    </div>
                </>
            )}
        </div>
    );
};

export default DetailEmployeeComponent;