import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import clsx from "clsx";

import { styled } from "@mui/material/styles";
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  OutlinedInput,
  Button,
  Stack,
} from "@mui/material";

import { SnackbarProvider, useSnackbar } from "notistack";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import VehicleService from "../../../../../services/vehicle.service";
import vehicleBrandAndModelService from "../../../../../services/vehicleBrandAndModel.service";
import vehicleTypeService from "../../../../../services/vehicleType.service";

const DivCoverReturn = styled("div")({
  margin: "20px 50px",
  height: "calc(100% - 20px)",
  position: "relative",
});

const BackButton = styled(Button)({
  position: "absolute",
  top: "15px",
  left: "125px",
  ["&:hover"]: {
    backgroundColor: "white",
  },
  ["&:active"]: {
    backgroundColor: "white",
  },
});

const Title = styled(Typography)({
  fontSize: "40px",
  textAlign: "center",
});

const FormStyled = styled("form")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginTop: "30px",
  flexDirection: "column",
});

const GridContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  boxShadow:
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
  border: "1px solid #EEEEEE",
  backgroundColor: "#FBFCFC",
  marginTop: "10px",
  marginBottom: "20px",
  marginLeft: "0px",
  padding: "0 16px 16px 0",

  borderRadius: "15px",
  width: "80%",
  "& .TextDarkBlue": {
    color: theme.palette.primary.drak,
  },
  "& .TextField .MuiInputBase-fullWidth": {
    color: theme.palette.primary.dark,
  },
  "& .OutlinedInput .MuiInputBase-input": {
    color: theme.palette.primary.dark,
  },
  "& .TextFieldDay": {
    marginTop: "0",
  },
  "& .TextFieldDayChanged .MuiOutlinedInput-root input": {
    color: theme.palette.primary.dark,
  },
}));

const GridItem = styled(Grid)({});

const NavButton = styled("div")({
  width: "80%",
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "40px",
});

const ConfirmButton = styled(Button)(({ theme }) => ({
  width: "fit-content",
  height: "50px",
  backgroundColor: "green",
  color: "white",
  fontSize: "20px",
  padding: "0 20px",
  ["&:hover"]: {
    backgroundColor: theme.palette.success.dark,
  },
}));

const FormAddCar = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      idVehicleType: props.location.state.vehicle?.idVehicleType,
      Plate_No: props.location.state.vehicle?.Plate_No,
      UseFor: props.location.state.vehicle?.UseFor,
      Vendor: props.location.state.vehicle?.Vendor,
      RentDate: props.location.state.vehicle
        ? props.location.state.vehicle.RentDate
        : new Date(),
      ExpireDate: props.location.state.vehicle
        ? props.location.state.vehicle.ExpireDate
        : new Date(),
      Cost: props.location.state.vehicle?.Cost,
      CostBooking: props.location.state.vehicle
        ? props.location.state.vehicle.CostBooking
          ? props.location.state.vehicle.CostBooking
          : 0
        : 0,
      site: props.location.state.vehicle?.site,
      Agency: props.location.state.vehicle?.Agency,
      CostCenter: props.location.state.vehicle?.CostCenter,
      CostElement: props.location.state.vehicle?.CostElement,
      idVehicleBrandAndModel:
        props.location.state.vehicle?.idVehicleBrandAndModel,
    },
  });
  const [vehicleType, setVehicleType] = useState(null);
  const [vehicleBrandAndModel, setVehicleBrandAndModel] = useState(null);
  const [selectVehicleType, setSelectVehicleType] = useState(null);

  useEffect(() => {
    if (props.location.state.isEdit) {
      setSelectVehicleType(props.location.state.vehicle.idType);
    }
    vehicleBrandAndModelService.getAllVehicleBrandsAndModels().then((res) => {
      setVehicleBrandAndModel(res.data);
    });
    vehicleTypeService.getAllVehicleTypes().then((res) => {
      setVehicleType(res.data);
    });
  }, []);

  const onClickBackButton = () => {
    props.history.goBack();
  };

  const onSubmitForm = (data) => {
    if (props.location.state.isEdit) {
      VehicleService.postEditVehicle(
        data,
        props.location.state.vehicle.idVehicle
      ).then((res) => {
        if (res.data) {
          enqueueSnackbar("Edit data Success", { variant: "success" });
          props.history.push(`/admin/vehicle_page`);
        } else {
          enqueueSnackbar("Edit data Failed", { variant: "error" });
        }
      });
    } else {
      VehicleService.postNewVehicle(data).then((res) => {
        if (res.data) {
          enqueueSnackbar("Add data Success", { variant: "success" });
          props.history.push(`/admin/vehicle_page`);
        } else {
          enqueueSnackbar("Add data Failed", { variant: "error" });
        }
      });
    }
    console.log(data);
  };
  console.log(props.location.state);
  return (
    <div className={`page`}>
      <DivCoverReturn>
        <BackButton onClick={onClickBackButton}>
          <ArrowBackIosIcon style={{ color: "black" }} />
          <Typography>Back</Typography>
        </BackButton>
        <Title>Add Vehicle</Title>
        <FormStyled onSubmit={handleSubmit(onSubmitForm)}>
          <GridContainer container spacing={2}>
            <GridItem item xs={12} md={4}>
              {vehicleType && (
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="idVehicleType">ประเภทรถ</InputLabel>
                  <Controller
                    render={(propsInController) => (
                      <Select
                        className={clsx({
                          TextDarkBlue:
                            props.location.state.vehicle?.idVehicleType !==
                            getValues("idVehicleType"),
                        })}
                        label="ประเภทรถ"
                        value={propsInController.field.value}
                        onChange={(e) => {
                          setValue("idVehicleBrandAndModel", "");
                          setSelectVehicleType(e.target.value);
                          propsInController.field.onChange(e);
                        }}
                        helperText={
                          errors &&
                          errors.idVehicleType &&
                          errors.idVehicleType.message
                        }
                        error={errors && errors.idVehicleType ? true : false}
                      >
                        {vehicleType.map((item) => (
                          <MenuItem
                            key={item.idVehicleTypes}
                            value={item.idVehicleTypes}
                          >
                            {item.vehicleTypeNameTH}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    name="idVehicleType"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "กรุณาเลือกประเภทรถ",
                      },
                    }}
                    defaultValue=""
                  />
                </FormControl>
              )}
            </GridItem>
            <GridItem item xs={12} md={4}>
              {vehicleBrandAndModel && (
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="idVehicleBrandAndModel">
                    ยี่ห้อและรุ่น
                  </InputLabel>
                  <Controller
                    render={(propsInController) => (
                      <Select
                        className={clsx({
                          TextDarkBlue:
                            props.location.state.vehicle?.BrandAndModel !==
                            getValues("idVehicleBrandAndModel"),
                        })}
                        label="ยี่ห้อและรุ่น"
                        value={propsInController.field.value}
                        onChange={propsInController.field.onChange}
                        helperText={
                          errors &&
                          errors.idVehicleBrandAndModel &&
                          errors.idVehicleBrandAndModel.message
                        }
                        error={
                          errors && errors.idVehicleBrandAndModel ? true : false
                        }
                      >
                        {vehicleBrandAndModel
                          .filter((x) => x.idType === selectVehicleType)
                          .map((item) => (
                            <MenuItem
                              key={item.idVehicleBrandsAndModels}
                              value={item.idVehicleBrandsAndModels}
                            >
                              {item.model}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                    name="idVehicleBrandAndModel"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "กรุณาเลือกยี่ห้อและรุ่น",
                      },
                    }}
                    defaultValue=""
                  />
                </FormControl>
              )}
            </GridItem>
            <GridItem item xs={12} md={4}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    className={clsx({
                      TextField:
                        props.location.state.vehicle?.Plate_No !==
                        getValues("Plate_No"),
                    })}
                    placeholder="ระบุทะเบียนรถ"
                    label="ทะเบียนรถ"
                    variant="outlined"
                    fullWidth
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                    helperText={
                      errors && errors.Plate_No && errors.Plate_No.message
                    }
                    error={errors && errors.Plate_No ? true : false}
                  />
                )}
                name="Plate_No"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "กรุณากรอกทะเบียนรถ",
                  },
                }}
                defaultValue=""
              />
            </GridItem>
            <GridItem item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="AgeYear">การใช้งาน</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      className={clsx({
                        TextDarkBlue:
                          props.location.state.vehicle?.UseFor !==
                          getValues("UseFor"),
                      })}
                      label="การใช้งาน"
                      value={propsInController.field.value}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === "รถข้ามพื้นที่") {
                          setValue("UseForEN", "CrossAreaCar");
                        } else if (value === "รถในพื้นที่") {
                          setValue("UseForEN", "InAreaCar");
                        } else if (value === "รถรับส่งของ") {
                          setValue("UseForEN", "DeliveryCar");
                        } else if (value === "รถรับส่ง") {
                          setValue("UseForEN", "ReceiveCar");
                        } else if (value === "รถระหว่างไซต์") {
                          setValue("UseForEN", "BetweenCar");
                        }

                        propsInController.field.onChange(e);
                      }}
                      helperText={
                        errors && errors.UseFor && errors.UseFor.message
                      }
                      error={errors && errors.UseFor ? true : false}
                    >
                      <MenuItem value={"รถรับส่ง"}>รถรับส่ง</MenuItem>
                      <MenuItem value={"รถข้ามพื้นที่"}>รถข้ามพื้นที่</MenuItem>
                      <MenuItem value={"รถในพื้นที่"}>รถในพื้นที่</MenuItem>
                      <MenuItem value={"รถรับส่งของ"}>รถรับส่งของ</MenuItem>
                      <MenuItem value={"รถระหว่างไซต์"}>รถระหว่างไซต์</MenuItem>
                    </Select>
                  )}
                  name="UseFor"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณาเลือกประเภทการใช้งาน",
                    },
                  }}
                  defaultValue=""
                />
              </FormControl>
            </GridItem>
            <GridItem item xs={12} md={4}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    className={clsx({
                      OutlinedInput:
                        props.location.state.vehicle?.Vendor !==
                        getValues("Vendor"),
                    })}
                    placeholder="ระบุบริษัทที่เช่า"
                    label="บริษัทที่เช่า"
                    variant="outlined"
                    fullWidth
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                    helperText={
                      errors && errors.Vendor && errors.Vendor.message
                    }
                    error={errors && errors.Vendor ? true : false}
                  />
                )}
                name="Vendor"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "กรุณากรอกบริษัทที่เช่า",
                  },
                }}
                defaultValue=""
              />
            </GridItem>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <GridItem item xs={12} md={2}>
                <Controller
                  render={(propsInController) => (
                    <MobileDatePicker
                      label="วันที่เช่า"
                      inputFormat="DD MMM YYYY"
                      value={propsInController.field.value}
                      onChange={(date) => {
                        propsInController.field.onChange(date);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className={clsx("TextFieldDay", {
                            TextFieldDayChanged:
                              props.location.state.vehicle?.RentDate !==
                              getValues("RentDate"),
                          })}
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  )}
                  name="RentDate"
                  defaultValue={new Date()}
                  control={control}
                />
              </GridItem>
              <GridItem item xs={12} md={2}>
                <Controller
                  render={(propsInController) => (
                    <MobileDatePicker
                      label="วันที่สิ้นสุด"
                      inputFormat="DD MMM YYYY"
                      minDate={getValues("RentDate")}
                      value={propsInController.field.value}
                      onChange={(date) => {
                        propsInController.field.onChange(date);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className={clsx("TextFieldDay", {
                            TextFieldDayChanged:
                              props.location.state.vehicle?.RentDate !==
                              getValues("RentDate"),
                          })}
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  )}
                  name="ExpireDate"
                  defaultValue={new Date()}
                  control={control}
                />
              </GridItem>
            </LocalizationProvider>
            <GridItem item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="AgeYear">ค่าเช่ารถ</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <OutlinedInput
                      id="Cost"
                      className={clsx({
                        OutlinedInput:
                          props.location.state.vehicle?.Cost !==
                          parseInt(getValues("Cost")),
                      })}
                      label="ค่าเช่ารถ"
                      value={propsInController.field.value}
                      onChange={(date) => {
                        propsInController.field.onChange(date);
                      }}
                      type="number"
                      endAdornment={
                        <InputAdornment position="end">บาท</InputAdornment>
                      }
                      inputProps={{
                        min: 0,
                      }}
                    />
                  )}
                  name="Cost"
                  defaultValue={0}
                  control={control}
                />
              </FormControl>
            </GridItem>
            <GridItem item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="AgeYear">ราคาจองรถ</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <OutlinedInput
                      id="Cost"
                      className={clsx({
                        OutlinedInput:
                          props.location.state.vehicle?.Cost !==
                          parseInt(getValues("Cost")),
                      })}
                      label="ราคาจองรถ"
                      value={propsInController.field.value}
                      onChange={(date) => {
                        propsInController.field.onChange(date);
                      }}
                      type="number"
                      endAdornment={
                        <InputAdornment position="end">บาท</InputAdornment>
                      }
                      inputProps={{
                        min: 0,
                      }}
                    />
                  )}
                  name="CostBooking"
                  defaultValue={0}
                  control={control}
                />
              </FormControl>
            </GridItem>
            <GridItem item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="site">ทำงานบริษัท</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      className={clsx({
                        TextDarkBlue:
                          props.location.state.vehicle?.site !==
                          getValues("site"),
                      })}
                      label="ทำงานบริษัท"
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                      helperText={errors && errors.site && errors.site.message}
                      error={errors && errors.site ? true : false}
                    >
                      <MenuItem value={1}>TPE Site 1</MenuItem>
                      <MenuItem value={2}>TPE Site 2</MenuItem>
                      <MenuItem value={3}>TPE Site 3</MenuItem>
                      <MenuItem value={7}>TPE Site 7</MenuItem>
                    </Select>
                  )}
                  name="site"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณาเลือกบริษัทที่ทำงาน",
                    },
                  }}
                  defaultValue=""
                />
              </FormControl>
            </GridItem>
            <GridItem item xs={12} md={4}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    className={clsx({
                      TextField:
                        props.location.state.vehicle?.Agency !==
                        getValues("Agency"),
                    })}
                    placeholder="ระบุหน่วยงาน"
                    label="หน่วยงาน"
                    variant="outlined"
                    fullWidth
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                    helperText={
                      errors && errors.Agency && errors.Agency.message
                    }
                    error={errors && errors.Agency ? true : false}
                  />
                )}
                name="Agency"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "กรุณากรอกหน่วยงาน",
                  },
                }}
                defaultValue=""
              />
            </GridItem>
            <GridItem item xs={12} md={4}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    className={clsx({
                      TextField:
                        props.location.state.vehicle?.CostCenter !==
                        getValues("CostCenter"),
                    })}
                    placeholder="ระบุ cost center"
                    label="Cost Center"
                    variant="outlined"
                    fullWidth
                    inputProps={{ pattern: "\\d{4}[-]\\d{5}$" }}
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                  />
                )}
                name="CostCenter"
                control={control}
                defaultValue=""
              />
            </GridItem>
            <GridItem item xs={12} md={4}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    className={clsx({
                      TextField:
                        props.location.state.vehicle?.CostElement !==
                        getValues("CostElement"),
                    })}
                    placeholder="ระบุ cost element"
                    label="Cost Element"
                    variant="outlined"
                    fullWidth
                    inputProps={{ pattern: "\\d{4}[-]\\d{5}$" }}
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                  />
                )}
                name="CostElement"
                control={control}
                defaultValue=""
              />
            </GridItem>
          </GridContainer>
          <NavButton>
            <ConfirmButton type="submit">Confirm</ConfirmButton>
          </NavButton>
        </FormStyled>
      </DivCoverReturn>
    </div>
  );
};

export default FormAddCar;
