import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styled } from '@mui/styles';
import{
  Typography, Button, Avatar
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getUserProfile } from "../../../../actions/user";
import { getRouteUserByIdUser } from "../../../../actions/routeCrossAreaCarPool";

const DivCoverReturn = styled("div")({
  width: "100%",
  height: "calc(100% - 64px)",
  display: "flex",
  justifyContent: "center",
  alignItems:"center",
  position: "relative",
});

const DivCoverContent = styled("div")(({theme})=>({
  width: "80%",
  height: "calc(90% - 60px)",
  display: "flex",
  justifyContent:"center",
  backgroundColor: "#03256C",
  paddingTop: "25px",
  paddingBottom:"10px",
  borderRadius: "20px",
  position: "absolute",
  border: "5px solid white",
  overflowY:"auto",
  [theme.breakpoints.down('sm')]:{
    width:"calc(100% - 10px)",
  }
}));

const DivButton = styled("div")({
  width: "100%",
  position: "absolute",
});

const ButtonStyled = styled(Button)(({theme})=>({
  width: "120px",
  height: "60px",
  fontSize: "25px",
  backgroundColor: "white",
  left: "50px",
  "&:hover":{
    backgroundColor: "white",
  },
  [theme.breakpoints.down("md")]:{
    width: "100px",
    left: "20px",
  },
  [theme.breakpoints.down("sm")]:{
    width: "85px",
    left: "10px",
    fontSize: "18px",
    height:"40px",
    marginTop:"-15px",
  },
}));

const Content = styled("div")(({theme})=>({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down('sm')]:{
    marginTop:"30px",
  },
}));

const Topic = styled(Typography)(({theme})=>({
  marginTop:"0",
  fontSize: "40px",
  color: "white",
  marginTop: "0px",
  [theme.breakpoints.down('sm')]:{
    fontSize: "35px",
  }
}));

const AvatarStyled = styled(Avatar)(({theme})=>({
  width: "200px",
  height: "200px",
  margin: "20px 0 10px",
  border: "5px solid white",
  [theme.breakpoints.down('sm')]:{
    width: "140px",
    height: "140px",
    margin: "10px 0 10px",
  },
}));

const Text = styled(Typography)(({theme})=>({
  fontSize: "40px",
  color: "white",
  marginTop: "10px",
  [theme.breakpoints.down('sm')]:{
    fontSize:"30px",
  }
}));

const SubText = styled(Typography)(({theme})=>({
  fontSize: "30px",
  color: "#dcdcdc",
  textAlign:"center",
  [theme.breakpoints.down('sm')]:{
    fontSize: "20px",
  },
}));

const ImgBackground = styled("img")({
  width: "100%",
  height: "100%",
});

const IconBack = styled(ArrowBackIcon)(({theme})=>({
  fontSize: "30px",
  [theme.breakpoints.down('sm')]:{
    fontSize: "20px",
  },
}));

const DetailEmployeePage = (props) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  // const { result: user } = useSelector((state) => state.userProfile);
  const { result: Driver } = useSelector(state => state.driverRouteDay);
  const { result: RouteOfDriver } = useSelector(state => state.routes);

  useEffect(() => {
    if(Driver){
      setUser(RouteOfDriver.find( route => route.idUser === parseInt(props.match.params.id)));
    }else{
      props.history.push("/driver");
    }
  }, [currentUser]);

  const Place = () => {
    if(Driver.typeDriver == 1){
      return `${user.addressNumberUser} ${user.villageSoiUser ? user.villageSoiUser : ""} ถ.${user.streetRoadUser} ต.${user.subdistrictTambolUser} อ.${user.districtUser} จ.${user.provinceUser} ${user.postalCodeUser}`;
    }else if(Driver.typeDriver == 2){
      return user.fromPlace;
    }
  };

  const OnClickBackButtonHandler = () => {
    props.history.goBack();
  };

  return (
    <DivCoverReturn className="page">
      {user && (
        <DivCoverContent>
          <DivButton>
            <ButtonStyled
              onClick={OnClickBackButtonHandler}
            >
              <IconBack />
              กลับ
            </ButtonStyled>
          </DivButton>
          <Content>
            <Topic>
              รายละเอียดผู้โดยสาร
            </Topic>
            <AvatarStyled
              src={`${process.env.REACT_APP_URL}image/profile/${user.imageUser}`}
              alt={user.fNameThai}
            />
            <Text>
              {user.fNameThaiUser}
            </Text>
            <SubText>
              {Place()}
            </SubText>
            <Text>
              {`Tel ${user.mobileNumberUser}`}
            </Text>
            <SubText>
              {`แผนก: ${user.departmentUser}`}
            </SubText>
            {Driver.typeDriver == 1 &&
              <SubText>
                {`สถานที่ทำงาน: ${user.workingLocationUser}`}
              </SubText>
            }
          </Content>
        </DivCoverContent>
      )}
      <ImgBackground
        src="../../assets/background/BackgroundDetailEmployee.jpg"
        alt="Background"
      />
    </DivCoverReturn>
  );
};

export default DetailEmployeePage;
