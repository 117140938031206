import { httpClient } from "./httpClient";

const getAllEmployees = () => {
  return httpClient.get("employees");
};

const getEmployeesByIdApproved = (id) => {
  return httpClient.get("employees_by_idapproved/" + id);
};

const getEmployeesById = (id) => {
  return httpClient.get("employees_by_id/" + id);
};

const getEmployeesByPage = (page) => {
  return httpClient.get("employees_by_page/" + page);
};

const getEmployeesByCompany = (id) => {
  return httpClient.get("employees_by_company/" + id);
};

const postEditProfileEmployees = (data) => {
  return httpClient.post("edit_employee", data);
};

export default {
  getAllEmployees,
  getEmployeesByIdApproved,
  getEmployeesById,
  getEmployeesByPage,
  getEmployeesByCompany,
  postEditProfileEmployees,
};
