import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { SnackbarProvider, useSnackbar } from "notistack";

import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import FormInformant from "../../shared/formBooking/formInformant";
import FormDetailFlight from "./formDetailFlight";
import NavButtonForm from "../../shared/NavButton/NavButtonForm";
import DialogShowDetailDialog from "./dialogShowDetailBooking";
import DeliveryCarBookings from "../../../../services/deliveryCarBooking.service";

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingInline: "5vw",
    [theme.breakpoints.down("sm")]: {
      paddingInline: "0",
      marginLeft: "0",
      width: "100%",
    },
  },
  Grid: {
    width: "80%",
    margin: "0 auto",
    marginTop: "20px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },
  Title: {
    textAlign: "center",
    fontSize: "25px",
    margin: "25px 0px",
  },
}));

const DeliveryitemCar = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const classes = useStyles();
  // const AllInput = watch();
  const { enqueueSnackbar } = useSnackbar();
  const { user: User } = useSelector((state) => state.auth);

  const [openDetailBooking, setOpenDetailBooking] = useState(false);

  const onSubmitHandler = (date) => {
    setOpenDetailBooking(true);
    console.log(date);
  };

  const onCloseDialog = () => {
    setOpenDetailBooking(false);
  };

  const onClickSubmit = (date) => {
    DeliveryCarBookings.postNewDeliveryCarBooking(date, User.idUser).then(
      (data) => {
        if (data.status === 200) {
          enqueueSnackbar("Create Booking Success", { variant: "success" });
          props.history.push("/carbooking");
        } else {
          enqueueSnackbar("Create Booking Failed", { variant: "error" });
        }
      }
    );
  };

  return (
    <div className={`page ${classes.DivCoverReturn}`}>
      <Typography className={classes.Title}>ขอรถรับส่งของ</Typography>
      <form style={{ width: "80%" }} onSubmit={handleSubmit(onSubmitHandler)}>
        <FormInformant
          register={register}
          setValue={setValue}
          errors={errors}
        />
        <FormDetailFlight
          register={register}
          control={control}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
        />
        <NavButtonForm type="submit" numberOfPage={1} />
      </form>
      {openDetailBooking && (
        <DialogShowDetailDialog
          open={openDetailBooking}
          handleClose={onCloseDialog}
          getValues={getValues}
          onClickSubmit={handleSubmit(onClickSubmit)}
        />
      )}
    </div>
  );
};

export default DeliveryitemCar;
