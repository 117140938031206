import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import DoneAllIcon from '@mui/icons-material/DoneAll';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

const ManagerMenu = ({ onClickList }) => {
  const dataListMenu = [
    {
      listItemIcon: <PlaylistAddCheckIcon />,
      listItemText: "Approved",
      listLink: "/manager/approved",
      listKey:"Approved",
    },
    // {
    //   listItemIcon: <DoneAllIcon />,
    //   listItemText: "Approved",
    //   listLink: "/manager/approvedTemporary",
    //   listKey:"ApprovedTemporary",
    // },
    // {
    //   listItemIcon: <DashboardIcon />,
    //   listItemText: "Dashboard",
    //   listLink: "/manager/dashboard",
    //   listKey:"dashboard",
    // },
  ];

  return (
    <ListMenu dataListMenu={dataListMenu} menuRole={"Manager"} onClickList={onClickList}/>
  );
};

export default ManagerMenu;
