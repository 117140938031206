import { httpClient } from "./httpClient";

const getHistoryLentCarById = (Id) => {
    return httpClient.get("getHistoryLentCarById/"+Id);
};

const getHistoryLentCarByCarId = (carId) => {
    return httpClient.get("getHistoryLentCarByCarId/"+carId);
};

const getHistoryLentCarByCarIdLender = (idLender) => {
    return httpClient.get("getHistoryLentCarByIdLender/"+idLender);
};

const getHistoryLentCarByCarIdAndMonthLent = (carId,dateLent) => {
    return httpClient.get("getHistoryLentCarByCarIdAndMonthLent/"+carId+"/"+dateLent);
};

const getHistoryLentCarByBorrowerId = (borrowerId,sort) => {
    return httpClient.get("getHistoryLentCarByBorrowerId/"+borrowerId+"/"+sort);
};

const getHistoryLentCarApprovedByBorrowerId = (borrowerId,sort) => {
    return httpClient.get("getHistoryLentCarApprovedByBorrowerId/"+borrowerId+"/"+sort);
};

const getHistoryLentCarWaitingByBorrowerId = (borrowerId,sort) => {
    return httpClient.get("getHistoryLentCarWaitingByBorrowerId/"+borrowerId+"/"+sort);
};

const getHistoryLentCarWaitingByLenderId = (lenderId) => {
    return httpClient.get("getHistoryLentCarWaitingByLenderId/"+lenderId);
};

export default {
    getHistoryLentCarById,
    getHistoryLentCarByCarId,
    getHistoryLentCarByCarIdAndMonthLent,
    getHistoryLentCarByBorrowerId,
    getHistoryLentCarApprovedByBorrowerId,
    getHistoryLentCarWaitingByBorrowerId,
    getHistoryLentCarByCarIdLender,
    getHistoryLentCarWaitingByLenderId
};