import React, { useState } from 'react';

import { styled } from '@mui/styles';
import { 
    Popover, IconButton, Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const MoreIconStyled = styled(MoreVertIcon)(({theme})=>({
    color:theme.palette.grey[500],
}));

const ContentPopover = styled("div")(({theme})=>({
    width:"130px",
    display:"flex",
    flexDirection:"column",
}));

const ButtonStyled = styled(Button)(({theme})=>({
    "& .Disable":{
        color:theme.palette.warning.main,
    },
    "& .Remove":{
        color:theme.palette.error.main,
    },
    "& .Icon":{
        marginRight:"7px",
    },
}));

const TextAndIcon = styled("div")(({theme})=>({
    display:"flex",
    alignItems:"center",
}));

const ButtonMore = props => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const onClickButton = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return(
        <>
            <IconButton onClick={onClickButton}><MoreIconStyled /></IconButton>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                onClose={()=>setAnchorEl(null)}
            >
                <ContentPopover>
                    <ButtonStyled onClick={props.onClickViewButton}><TextAndIcon><i className="fas fa-eye Icon"></i>View</TextAndIcon></ButtonStyled>
                    <ButtonStyled><TextAndIcon className="Disable"><i className="fas fa-ban Icon"></i>Disable</TextAndIcon></ButtonStyled>
                    <ButtonStyled><TextAndIcon className="Remove"><i className="fas fa-trash Icon"></i>Remove</TextAndIcon></ButtonStyled>
                </ContentPopover>
            </Popover>
        </>
    );
};

export default ButtonMore;