import React, { useState, Fragment } from 'react';
import { useForm, Controller } from "react-hook-form";

import { styled } from '@mui/styles';
import { 
    Modal, Paper, IconButton, Button, TextField, FormControl, InputLabel, Grid, Typography,
    Select, MenuItem, Divider
} from '@mui/material';

import { listExtras } from "../../../../choise";
import utils from "../../../../../../../../utils";

const ModalStyled = styled(Modal)({
    width:"100%",
    height:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
});

const PaperStyled = styled(Paper)(({theme})=>({
    width:"400px",
    height:"fit-content",
    padding:"30px",
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-start",
    [theme.breakpoints.down("sm")]:{
        width:"calc(95% - 60px)",
    },
}));

const DivListExtraSelected = styled("div")({
    width:"100%",
    maxHeight:"200px",
    overflowY:"auto",
    marginBottom:"20px",
});

const DivFlex = styled("div")({
    display:"flex",
    alignItems:"center",
});

const Text = styled(Typography)({
    fontSize:"16px",
    marginRight:"20px",
}); 

const DeleteIconButton = styled(IconButton)({
    "&:hover":{
        backgroundColor:"transparent",
    },
});

const DeleteIcon = styled("i")(({theme})=>({
    color:theme.palette.error.main,
    paddingTop:"3px",
}));

const AddExtraButton = styled(Button)(({theme})=>({
    color:theme.palette.primary.main,
}));

const DivderStyled =styled(Divider)(({theme})=>({
    width:"100%",
    height:"1px",
    backgroundColor:theme.palette.grey[200],
    margin:"20px 0",
}))

const NavAddExtra = styled("div")({
    width:"100%",
    display:"flex",
    justifyContent:"flex-end",
});

const AddExtraIconButton = styled(Button)(({theme})=>({
    color:theme.palette.success.main,
    fontSize:"16px",
    border:"1px solid",
    borderColor:theme.palette.success.main,
    "&:hover":{
        backgroundColor:"transparent",
    },
}));

const AddIconButton = styled("i")(({theme})=>({
    marginRight:"5px",
    paddingTop:"2px",
    fontSize:"22px",
}));

const ModalExtra = ({ openModal, listExExtras, onClickAddButton, onClickDeleteExtraButton, OnClickCloseModal }) => {
    const { register, setValue, control, getValues } = useForm({
        defaultValues:{
            extra:"",
            price:"",
            duration:"",
        }
    });
    const [openTextField, setOpenTextField] = useState(false);

    const OnCloseFunction = () => {
        setOpenTextField(false);
        OnClickCloseModal();
    };

    const OnClickConfirmButton = () => {
        OnClickCloseModal();
    };

    const OnClickAddButton = () => {
        let allDetail = getValues();
        allDetail.price = parseInt(allDetail.price);
        onClickAddButton(allDetail);
        setValue("extra","");
        setValue("price","");
        setValue("duration","");
        setOpenTextField(false);
    };

    return(
        <>
            <ModalStyled
                open={openModal}
                onClose={OnCloseFunction}
            >
                <PaperStyled>
                    <DivListExtraSelected>
                        {listExExtras.map( extra => (
                            <DivFlex key={extra.extra}>
                                <Text>
                                    {`${listExtras.find( extralist => extralist.id === extra.extra).name} ${utils.numberWithCommas(extra.price)} บาท /${extra.duration}`}
                                </Text>
                                <DeleteIconButton disableRipple onClick={()=>onClickDeleteExtraButton(extra.extra)}>
                                    <DeleteIcon className="far fa-times-circle"></DeleteIcon>
                                </DeleteIconButton>
                            </DivFlex>
                        ))}
                    </DivListExtraSelected>
                    <AddExtraButton variant="outlined" onClick={()=>setOpenTextField(true)} disabled={openTextField}><AddIconButton className="fas fa-plus-circle"></AddIconButton>Add Extra</AddExtraButton>
                    {openTextField && (
                        <Fragment>
                            <DivderStyled />
                            <FormControl variant="outlined" fullWidth style={{marginBottom:"10px"}}>
                                <InputLabel id="Extra-inputlabel">Extra</InputLabel>
                                <Controller
                                    control={control}
                                    name="extra"
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            labelId="Extra-label"
                                            id="Extra"
                                            label="Extra"
                                        >
                                            {listExtras.map( extralist => (
                                                <MenuItem value={extralist.id} key={extralist.id}>{extralist.name}</MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            <TextField 
                                id="price"
                                fullWidth
                                placeholder="ราคา(บาท)"
                                inputProps={{...register("price")}}
                            />
                            <FormControl variant="outlined" style={{margin:"10px 0"}} fullWidth>
                                <InputLabel id="Duration-inputlabel">ระยะเวลา</InputLabel>
                                <Controller
                                    control={control}
                                    name="duration"
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            labelId="Duration-label"
                                            id="Duration"
                                            label="ระยะเวลา"
                                        >
                                            <MenuItem value="วัน">วัน</MenuItem>
                                            <MenuItem value="การเดินทาง">การเดินทาง</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            <NavAddExtra>
                                <AddExtraIconButton disableRipple onClick={OnClickAddButton}>
                                    <AddIconButton className="far fa-check-circle"></AddIconButton>
                                    Add
                                </AddExtraIconButton>
                            </NavAddExtra>
                        </Fragment>
                    )}
                </PaperStyled>
            </ModalStyled>
        </>
    );
};

export default ModalExtra;