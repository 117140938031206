import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/styles";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import GoogleMap from "../../../../shared/googleWithAutoComplete/GoogleMap";

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props) => (props.colorText ? props.colorText : "green"),
  },
  map: {
    width: "100%",
    height: "300px",
  },
}));

const generateTimeOptions = (startTime = "-01:30") => {
  const times = [];
  const [startHour, startMinute] = startTime.split(":").map(Number);
  let startMinutes = startHour * 60 + startMinute + 30; // Adding 30 minutes

  for (let i = 0; i < 48; i++) {
    let hour = Math.floor(startMinutes / 60);
    let minute = startMinutes % 60;
    if (hour < 24) {
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      times.push(`${hour}:${minute}`);
      startMinutes += 30;
    }
  }
  return times;
};
const FormLocationTimePurpose = (props) => {
  const classes = useStyles({ colorText: "red" });
  const [dataFrom, setDataFrom] = useState("");
  const [dataTo, setDataTo] = useState("");
  const [dataFromReturn, setDataFromReturn] = useState("");
  const [dataToReturn, setDataToReturn] = useState("");
  const [endTimeOptions, setEndTimeOptions] = useState(generateTimeOptions());

  const onBlurFromPlace = (e) => {
    let valueOfToPlaceReturn = props.getValues(
      `listPassenger.person${props.index}.toPlaceReturn`
    );
    if (valueOfToPlaceReturn === "" || valueOfToPlaceReturn === undefined) {
      props.setValue(
        `listPassenger.person${props.index}.toPlaceReturn`,
        e.target.value
      );
    }
  };

  const onChangeToPlace = (e) => {
    let valueOfFromPlaceReturn = props.getValues(
      `listPassenger.person${props.index}.fromPlaceReturn`
    );
    if (valueOfFromPlaceReturn === "" || valueOfFromPlaceReturn === undefined) {
      props.setValue(
        `listPassenger.person${props.index}.fromPlaceReturn`,
        e.target.value
      );
    }
  };

  const checkedTheStatusUsingAllPassenger = () => {
    if (props.stateInputRef.statusUsingAllPassenger === true) return true;
    else return false;
  };

  const getDataFromGoogleMapFromPlace = (data) => {
    setDataFrom(data);
    props.setValue(`listPassenger.person${props.index}.fromPlace`, data);
    setDataToReturn(data);
    props.setValue(`listPassenger.person${props.index}.toPlaceReturn`, data);
  };
  const getDataFromGoogleMapToplace = (data) => {
    setDataTo(data);
    props.setValue(`listPassenger.person${props.index}.toPlace`, data);
    setDataFromReturn(data);
    props.setValue(`listPassenger.person${props.index}.fromPlaceReturn`, data);
  };

  const getDataFromGoogleMapFromReturnPlace = (data) => {
    setDataFromReturn(data);
    props.setValue(`listPassenger.person${props.index}.fromPlaceReturn`, data);
  };
  const getDataFromGoogleMapToReturnplace = (data) => {
    setDataToReturn(data);
    props.setValue(`listPassenger.person${props.index}.toPlaceReturn`, data);
  };

  useEffect(() => {
    const newEndTimeOptions = generateTimeOptions(
      props.getValues(
        `listPassenger.person${props.index}.roundTime${
          props.nameParam !== "Departure" ? props.nameParam : ""
        }`
      )
    );
    setEndTimeOptions(newEndTimeOptions);
  }, [
    props.watch(
      `listPassenger.person${props.index}.roundTime${
        props.nameParam !== "Departure" ? props.nameParam : ""
      }`
    ),
  ]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        {props.nameParam === "Departure" ? (
          <>
            <Grid item xs={12} sm={12}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    placeholder="ระบุจุดรับ"
                    label="จุดรับ"
                    fullWidth
                    disabled
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                    onBlur={(e) => {
                      onBlurFromPlace(e);
                    }}
                    error={!!propsInController.fieldState.error}
                    helperText={
                      propsInController.fieldState.error
                        ? propsInController.fieldState.error.message
                        : ""
                    }
                    InputProps={{
                      style: {
                        borderRadius: "15px",
                      },
                    }}
                  />
                )}
                name={`listPassenger.person${props.index}.fromPlace`}
                control={props.control}
                defaultValue=""
                rules={{
                  required: "กรุณาระบุจุดรับ",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{ height: "360px" }}>
              <GoogleMap
                name={`listPassenger.person${props.index}.fromPlace`}
                inputProps={{
                  ...props.setValue(
                    `listPassenger.person${props.index}.fromPlace`,
                    dataFrom
                  ),
                }}
                value={dataFrom}
                handleGetDataFromGoogleMap={getDataFromGoogleMapFromPlace}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    placeholder="ระบุจุดรับ"
                    label="จุดรับ"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                    error={!!propsInController.fieldState.error}
                    helperText={
                      propsInController.fieldState.error
                        ? propsInController.fieldState.error.message
                        : ""
                    }
                    InputProps={{
                      style: {
                        borderRadius: "15px",
                      },
                    }}
                  />
                )}
                name={`listPassenger.person${props.index}.fromPlaceReturn`}
                control={props.control}
                defaultValue=""
                rules={{
                  required: "กรุณาระบุจุดรับ",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{ height: "360px" }}>
              <GoogleMap
                name={`listPassenger.person${props.index}.fromPlaceReturnMap`}
                inputProps={{
                  ...props.setValue(
                    `listPassenger.person${props.index}.fromPlaceReturnMap`,
                    dataFromReturn
                  ),
                }}
                value={dataFromReturn}
                handleGetDataFromGoogleMap={getDataFromGoogleMapFromReturnPlace}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {props.nameParam === "Departure" ? (
          <>
            <Grid item xs={12} sm={12}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    placeholder="ระบุจุดส่ง"
                    label="จุดส่ง"
                    variant="outlined"
                    multiline
                    fullWidth
                    disabled
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                    onBlur={(e) => {
                      propsInController.field.onChange(e);
                      onChangeToPlace(e);
                      if (
                        props.getValues("statusUsingAllPassenger") &&
                        props.index === 1
                      ) {
                        [
                          ...new Array(
                            props.getValues("numberOfPassenger") - 1
                          ),
                        ].map((value, indexSet) => {
                          props.setValue(
                            `listPassenger.person${indexSet + 2}.toPlace`,
                            props.getValues(`listPassenger.person1.toPlace`)
                          );
                          props.setValue(
                            `listPassenger.person${
                              indexSet + 2
                            }.fromPlaceReturn`,
                            props.getValues(
                              `listPassenger.person1.fromPlaceReturn`
                            )
                          );
                        });
                      }
                    }}
                    error={!!propsInController.fieldState.error}
                    helperText={
                      propsInController.fieldState.error
                        ? propsInController.fieldState.error.message
                        : ""
                    }
                    InputProps={{
                      style: {
                        borderRadius: "15px",
                      },
                    }}
                  />
                )}
                name={`listPassenger.person${props.index}.toPlace`}
                control={props.control}
                defaultValue={""}
                rules={{
                  required: "กรุณาระบุจุดส่ง",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{ height: "360px" }}>
              <GoogleMap
                name={`listPassenger.person${props.index}.toPlace`}
                inputProps={{
                  ...props.setValue(
                    `listPassenger.person${props.index}.toPlace`,
                    dataTo
                  ),
                }}
                value={dataTo}
                handleGetDataFromGoogleMap={getDataFromGoogleMapToplace}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} style={{ marginBottom: "10px" }}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    placeholder="ระบุจุดส่ง"
                    label="จุดส่ง"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={propsInController.field.value}
                    error={!!propsInController.fieldState.error}
                    helperText={
                      propsInController.fieldState.error
                        ? propsInController.fieldState.error.message
                        : ""
                    }
                    InputProps={{
                      style: {
                        borderRadius: "15px",
                      },
                    }}
                    onChange={propsInController.field.onChange}
                  />
                )}
                name={`listPassenger.person${props.index}.toPlaceReturn`}
                control={props.control}
                defaultValue=""
                rules={{
                  required: "กรุณาระบุจุดส่ง",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{ height: "360px" }}>
              <GoogleMap
                name={`listPassenger.person${props.index}.toPlaceReturnMap`}
                inputProps={{
                  ...props.setValue(
                    `listPassenger.person${props.index}.toPlaceReturnMap`,
                    dataToReturn
                  ),
                }}
                value={dataToReturn}
                handleGetDataFromGoogleMap={getDataFromGoogleMapToReturnplace}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={props.nameParam === "Departure" ? 4 : 6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>{"เลือกรอบรถ"}</InputLabel>
          <Controller
            render={(propsInController) => (
              <Select
                label="เลือกรอบรถ"
                value={
                  propsInController.field.value
                  // props.stateInputRef.listAllPassengers[props.index - 1]
                  //   .TimeStart[props.nameParam] || ""
                }
                style={{ borderRadius: "15px" }}
                error={!!propsInController.fieldState.error}
                helperText={
                  propsInController.fieldState.error
                    ? propsInController.fieldState.error.message
                    : ""
                }
                onChange={(e) => {
                  // (e) => {
                  // props.setStateInputRef((prestate) => ({
                  //   ...prestate,
                  //   listAllPassengers: prestate.listAllPassengers.map(
                  //     (location) => {
                  //       if (props.nameParam === "Departure") {
                  //         if (
                  //           checkedTheStatusUsingAllPassenger() ||
                  //           props.index - 1 === location.order
                  //         ) {
                  //           return {
                  //             ...location,
                  //             TimeStart: {
                  //               ...location.TimeStart,
                  //               Departure: e.target.value,
                  //             },
                  //           };
                  //         } else {
                  //           return location;
                  //         }
                  //       } else {
                  //         if (
                  //           checkedTheStatusUsingAllPassenger() ||
                  //           props.index - 1 === location.order
                  //         ) {
                  //           return {
                  //             ...location,
                  //             TimeStart: {
                  //               ...location.TimeStart,
                  //               Return: e.target.value,
                  //             },
                  //           };
                  //         } else {
                  //           return location;
                  //         }
                  //       }
                  //     }
                  //   ),
                  // }));
                  propsInController.field.onChange(e);
                  if (
                    props.nameParam === "Departure" &&
                    props.getValues("statusUsingAllPassenger") &&
                    props.index === 1
                  ) {
                    [
                      ...new Array(props.getValues("numberOfPassenger") - 1),
                    ].map((value, indexSet) => {
                      props.setValue(
                        `listPassenger.person${indexSet + 2}.roundTime`,
                        props.getValues(`listPassenger.person1.roundTime`)
                      );
                    });
                  }
                }}
              >
                {generateTimeOptions().map((time, index) => (
                  <MenuItem key={index} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            )}
            name={`listPassenger.person${props.index}.roundTime${
              props.nameParam !== "Departure" ? props.nameParam : ""
            }`}
            control={props.control}
            defaultValue={
              ""
              // props.stateInputRef.listAllPassengers[props.index - 1].TimeStart[
              //   props.nameParam
              // ]
            }
            rules={{
              required: "กรุณาเลือกรอบรถ",
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={props.nameParam === "Departure" ? 4 : 6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>{"เวลาที่ต้องถึงจุดหมาย"}</InputLabel>
          <Controller
            render={(propsInController) => (
              <Select
                label="เวลาที่ต้องถึงจุดหมาย"
                value={
                  propsInController.field.value
                  // props.stateInputRef.listAllPassengers[props.index - 1]
                  //   .TimeStop[props.nameParam] || ""
                }
                style={{ borderRadius: "15px" }}
                error={!!propsInController.fieldState.error}
                helperText={
                  propsInController.fieldState.error
                    ? propsInController.fieldState.error.message
                    : ""
                }
                onChange={(e) => {
                  // (e) => {
                  // props.setStateInputRef((prestate) => ({
                  //   ...prestate,
                  //   listAllPassengers: prestate.listAllPassengers.map(
                  //     (location) => {
                  //       if (props.nameParam === "Departure") {
                  //         if (
                  //           checkedTheStatusUsingAllPassenger() ||
                  //           props.index - 1 === location.order
                  //         ) {
                  //           return {
                  //             ...location,
                  //             TimeStop: {
                  //               ...location.TimeStop,
                  //               Departure: e.target.value,
                  //             },
                  //           };
                  //         } else {
                  //           return location;
                  //         }
                  //       } else {
                  //         if (
                  //           checkedTheStatusUsingAllPassenger() ||
                  //           props.index - 1 === location.order
                  //         ) {
                  //           return {
                  //             ...location,
                  //             TimeStop: {
                  //               ...location.TimeStop,
                  //               Return: e.target.value,
                  //             },
                  //           };
                  //         } else {
                  //           return location;
                  //         }
                  //       }
                  //     }
                  //   ),
                  // }));
                  propsInController.field.onChange(e);
                  if (
                    props.nameParam === "Departure" &&
                    props.getValues("statusUsingAllPassenger") &&
                    props.index === 1
                  ) {
                    [
                      ...new Array(props.getValues("numberOfPassenger") - 1),
                    ].map((value, indexSet) => {
                      props.setValue(
                        `listPassenger.person${indexSet + 2}.endTime`,
                        props.getValues(`listPassenger.person1.endTime`)
                      );
                    });
                  }
                }}
              >
                {endTimeOptions.map((time, index) => (
                  <MenuItem key={index} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            )}
            name={`listPassenger.person${props.index}.endTime${
              props.nameParam !== "Departure" ? props.nameParam : ""
            }`}
            control={props.control}
            defaultValue={
              ""
              // props.stateInputRef.listAllPassengers[0].TimeStop[props.nameParam]
            }
            rules={{
              required: "กรุณาเลือกเวลาที่ต้องถึงจุดหมาย",
            }}
          />
        </FormControl>
      </Grid>
      {props.nameParam === "Departure" && (
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{"วัตถุประสงค์"}</InputLabel>
            <Controller
              render={(propsInController) => (
                <Select
                  label="วัตถุประสงค​์"
                  value={
                    propsInController.field.value
                    // props.stateInputRef.listAllPassengers[props.index-1].Purpose ||
                  }
                  style={{ borderRadius: "15px" }}
                  onChange={(e) => {
                    // (e) => {
                    // props.setStateInputRef((prestate) => ({
                    //   ...prestate,
                    //   listAllPassengers: prestate.listAllPassengers.map(
                    //     (location) => {
                    //       if (checkedTheStatusUsingAllPassenger() || props.index - 1 === location.order){
                    //         return {
                    //           ...location,
                    //           Purpose: e.target.value,
                    //         };
                    //       }
                    //       else{
                    //         return location
                    //       }
                    //     }
                    //   ),
                    // }));
                    propsInController.field.onChange(e);
                    if (
                      props.getValues("statusUsingAllPassenger") &&
                      props.index === 1
                    ) {
                      [
                        ...new Array(props.getValues("numberOfPassenger") - 1),
                      ].map((value, indexSet) => {
                        props.setValue(
                          `listPassenger.person${indexSet + 2}.purpose`,
                          props.getValues(`listPassenger.person1.purpose`)
                        );
                      });
                    }
                  }}
                >
                  <MenuItem value={"ไปประชุม"}>ไปประชุม</MenuItem>
                  <MenuItem value={"ไปร่วมกิจกรรม"}>ไปร่วมกิจกรรม</MenuItem>
                  <MenuItem value={"อื่นๆ"}>อื่นๆ</MenuItem>
                </Select>
              )}
              name={`listPassenger.person${props.index}.purpose`}
              control={props.control}
              defaultValue={
                ""
                // props.stateInputRef.listAllPassengers[props.index-1].Purpose
              }
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          label="Note"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          inputProps={{
            ...props.register(
              `listPassenger.person${props.index}.note${props.nameParam}`
            ),
          }}
          InputProps={{
            style: {
              borderRadius: "15px",
            },
          }}
        />
      </Grid>
    </>
  );
};

export default FormLocationTimePurpose;
