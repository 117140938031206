import { httpClient } from "./httpClient";

const getAllWaitingFriendCars = () => {
    return httpClient.get("/waitingFriendCars");
};

const getWaitingFriendCarsByIdDriver = (idDriver) => {
    return httpClient.get("/waitingFriendCar/"+idDriver);
};

const getWaitingFriendCarsByIdPassenger = (idPassenger) => {
    return httpClient.get("/waitingFriendCarByIdPassenger/"+idPassenger);
};

const changingstatusWaitingFriendCar = (idOrder,status) => {
    return httpClient.post("/waitingFriendCar/changingStatus/"+idOrder+"/"+status);
};

export default {
    getAllWaitingFriendCars,
    getWaitingFriendCarsByIdDriver,
    changingstatusWaitingFriendCar,
    getWaitingFriendCarsByIdPassenger,
  };