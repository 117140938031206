import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller } from "react-hook-form";

import Grid from "@mui/material/Grid";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Box,
} from "@mui/material";

import { getAllUsers } from "../../../../../actions/user";

import EmployeeService from "../../../../../services/employee.service";

const FormApprove = (props) => {
  const { user: AllUsers } = useSelector((state) => state.auth);
  const [isEmployee, setEmployee] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!AllUsers) {
      dispatch(getAllUsers());
    }
    EmployeeService.getEmployeesByIdApproved(AllUsers.idUser)
      .then((res) => {
        if (res.status === 200) {
          setEmployee(res.data);
        } else {
          setEmployee([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    console.log(AllUsers);
  });
  return (
    <>
      {AllUsers && console.log(AllUsers)}
      {isEmployee && (
        <Grid
          container
          item
          spacing={3}
          justifyContent="center"
          xs={12}
          sx={(theme) => ({
            display: "flex",
            // justifyContent: "space-between",
            border: "1px solid #EEEEEE",
            backgroundColor: "#FBFCFC",
            marginTop: "20px",
            marginBottom: "20px",
            padding: "0 25px 25px 0",
            boxShadow:
              "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
            borderRadius: "15px",
          })}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              render={(propsInController) => (
                <Autocomplete
                  options={isEmployee}
                  getOptionLabel={(option) =>
                    `${option.firstname_TH} ${option.lastname_TH}`
                  }
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {`${option.firstname_TH} ${option.lastname_TH}`}
                    </Box>
                  )}
                  value={propsInController.field.value}
                  onChange={(e, value) => {
                    if (value) {
                      props.setValue(
                        "nameApproved",
                        `${value.firstname_TH} ${value.lastname_TH}`
                      );
                      props.setValue(
                        "departmentApproved",
                        value.departmentName
                      );
                      props.setValue("companyApproved", value.companyName);
                      props.setValue("idApproved", value.idEmployees);
                      propsInController.field.onChange(value);
                      // console.log("propsInController.field.textContent : ",propsInController.field.value)
                    } else {
                      props.setValue("nameApproved", "");
                      props.setValue("departmentApproved", "");
                      props.setValue("companyApproved", "");
                      propsInController.field.onChange(null);
                    }
                  }}
                  style={{
                    borderRadius: "15px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={props.errors.Approved ? true : false}
                      helperText={
                        props.errors.Approved ? "กรุณาระบุชื่อ" : false
                      }
                      placeholder="ระบุชื่อ-นามสกุลของคนที่จะ Approve"
                      label="ชื่อคนที่อนุมัติ"
                      fullWidth
                      sx={{
                        ".MuiInputBase-root": { borderRadius: "15px" },
                      }}
                    />
                  )}
                />
              )}
              name="Approved"
              control={props.control}
              rules={{ required: true }}
              defaultValue={null}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              render={(propsInController) => (
                <TextField
                  disabled
                  label="แผนกของคนอนุมัติ"
                  fullWidth
                  value={propsInController.field.value}
                  InputLabelProps={{
                    shrink:
                      props.getValues("departmentApproved") === ""
                        ? false
                        : true,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              )}
              name="departmentApproved"
              control={props.control}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              render={(propsInController) => (
                <TextField
                  disabled
                  label="บริษัทของคนอนุมัติ"
                  fullWidth
                  value={propsInController.field.value}
                  InputLabelProps={{
                    shrink:
                      props.getValues("companyApproved") === "" ? false : true,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              )}
              name="companyApproved"
              control={props.control}
              defaultValue=""
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FormApprove;
