import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import dayjs from 'dayjs';

import { styled } from '@mui/styles';
import {
  Typography, Avatar, Button
} from '@mui/material';
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import UserService from "../../../../../services/user.service";

require('dayjs/locale/th');
dayjs.locale('th');

const DivCoverReturn = styled("div")(({theme})=>({
  width: "40%",
  height: "calc(100% - 64px)",
  minWidth: "400px",
  position: "absolute",
  display: "flex",
  zIndex:"10",
  "& .CloseSlide": {
    display: "none",
  },
  "& .NoPassenger":{
    justifyContent:"center"
  },
  [theme.breakpoints.down('sm')]:{
    minWidth: "375px",
  },
}));

const DivCoverInfo = styled("div")({
  width: "90%",
  height: "calc(100% - 15px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent:"space-between",
  paddingTop: "15px",
  backgroundColor: "#03256C",
  "& .Address": {
    marginTop: "10px",
    marginBottom: "10px",
  },
  "& .TimeText": {
    marginTop: "5px",
  },
  "& .Time": {
    fontSize: "50px",
  }
});

const Detail = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Text = styled(Typography)({
  color: "white",
  fontSize: "40px",
  textAlign:"center",
});

const AvatarStyled = styled(Avatar)({
  width: "200px",
  height: "200px",
  marginTop: "7px",
  marginBottom: "7px",
  border: "5px solid white",
});

const SubText = styled(Typography)({
  color: "rgb(220,220,220)",
  fontSize: "20px",
});

const AssignmentIndIconStyled = styled(AssignmentIndIcon)({
  fontSize: "50px",
});

const ButtonHiding = styled(Button)({
  width: "10%",
  height: "10%",
  backgroundColor: "#03256C",
  borderRadius: "0 0px 10px 0",
  "&:hover":{
    backgroundColor: "#03256C",
  },
});

const PersonIconStyled = styled(PersonIcon)({
  color: "white",
  fontSize: "40px",
});

const ButtonDetail = styled(Button)({
  width: "100%",
  height: "12%",
  // position: "fixed",
  // marginTop: "calc(60% - 15px)",
  backgroundColor: "rgb(233,215,0)",
  borderRadius:"0",
  fontSize: "25px",
  color:"#03256C",
  "&:hover":{
    backgroundColor: "rgb(210,190,0)",
  }
});

const DivCoverReturnClose = styled("div")({
  width: "6.5%",
  position: "absolute",
  display: "flex",
  height: "calc(100% - 64px)",
  zIndex:"5",
  "& .CloseSlide": {
    display: "none",
  },
});

const SlideNextEmployee = (props) => {
  const { result: Driver } = useSelector(state => state.driverRouteDay);
  const { result: RouteOfDriver } = useSelector(state => state.routes);
  const [open, setOpen] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [indexUser, setIndexUser ] = useState(null);

  useEffect(() => {
    if(RouteOfDriver){
      if(props.employee){
        setUserProfile(props.employee);
        setIndexUser(RouteOfDriver.findIndex(route => route.id === props.employee.id));
      }
    }
  }, [props.employee])

  const Place = () => {
    if(Driver.typeDriver == 1){
      return `${userProfile.addressNumberUser} ${userProfile.villageSoiUser ? userProfile.villageSoiUser : ""} ถ.${userProfile.streetRoadUser} ต.${userProfile.subdistrictTambolUser} อ.${userProfile.districtUser} จ.${userProfile.provinceUser} ${userProfile.postalCodeUser}`;
    }else if(Driver.typeDriver == 2){
      return userProfile.fromPlace;
    }
  };

  const onClickHandler = () => {
    setOpen(!open);
    props.onSetOpenSlide(!props.openSlide);
  }

  return (
    <Fragment>
      {open ? 
        <DivCoverReturn>
          {userProfile ? (
            <DivCoverInfo
              className={clsx({ "CloseSlide" : !open })}
            >
              <Detail>
                <Text>
                  ลำดับที่ {indexUser+1}
                </Text>
                <AvatarStyled
                  src={`${process.env.REACT_APP_URL}image/profile/${userProfile.imageUser}`}
                  alt={userProfile.fNameThaiUser}
                />
                <Text>{userProfile.fNameThaiUser}</Text>
                <SubText className="Address" align="center">
                  {Place()}
                </SubText>
                <Text>{`Tel ${userProfile.mobileNumberUser}`}</Text>
                <SubText className="TimeText">เวลาที่คาดการณ์</SubText>
                <Text className="Time">{dayjs(userProfile.routeTimeOptimized).format('HH.mm')}</Text>
              </Detail>
              <ButtonDetail
                onClick={()=>props.OnClickDetailButton(userProfile.idUser)}
              >
                <AssignmentIndIconStyled />
                ดูข้อมูลของพนักงาน
              </ButtonDetail>
            </DivCoverInfo>
          )
            :
          (
            <DivCoverInfo
              className={clsx("NoPassenger",{ "CloseSlide" : !open })}
            >
              <Text style={{width:"80%"}}>ไม่มีพนักงานคนถัดไป</Text>
            </DivCoverInfo>
          )
          }
          <ButtonHiding 
            onClick={onClickHandler}
          >
            <PersonIconStyled />
          </ButtonHiding>
        </DivCoverReturn>
        :
        <DivCoverReturnClose>
          <ButtonHiding 
            onClick={onClickHandler}
          >
            <PersonIconStyled />
          </ButtonHiding>
        </DivCoverReturnClose>
      }
    </Fragment>
  );
};

export default SlideNextEmployee;
