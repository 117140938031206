import React from "react";
import { styled } from "@mui/material";
import Card from "../Card";

const StyledProgressBar = styled("div")(({theme})=>({
	width: "100%",
	height: 34,
	borderRadius: 17,
	backgroundColor: "#e4e4e4",
	overflow: "hidden",
	["& .active"]: {
		height: "100%",
		backgroundColor: "#43a047",
	}
}))

const ProgressBar = ({value}) => {

	return(
		<StyledProgressBar>
			<div className="active" style={{width: `${value||0}%`}}/>
		</StyledProgressBar>
	)
}


const Progress = ({value}) => {

	return(
		<Card title={`ความคืบหน้าของงาน ${value||0}%`}>
			<ProgressBar value={value}/>
		</Card>
	)
}

export default Progress;