import React, { useState } from 'react';
import { Controller } from "react-hook-form";

import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/styles';
import {
     Button, Typography, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Modal,
     Paper, useMediaQuery
} from "@mui/material";

const useStyles = makeStyles((theme)=>({
    Content:{
        width:"95%",
        height:"calc(100% - 40px)",
        padding:"20px 0",
    },
    Text:{
        fontSize:"20px",
    },
    AddButton:{
        height:"50px", 
        padding:"0 20px", 
        fontSize:"20px", 
        marginLeft:"50px",
        boxShadow:theme.shadows[3],
        [theme.breakpoints.down('sm')]:{
            marginLeft:"0",
            marginTop:"20px"
        },
    },
    NextButton:{
        width:"fit-content",
        color:"green",
        fontSize:"18px",
        "&:hover":{
            backgroundColor:"white",
        }
    },
}));

const ModalStyles = styled(Modal)(({ theme })=>({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    "& .PaperInModal":{
        width:"400px",
        padding:"20px",
        "& .Title":{
            fontSize:"30px",
        },
        "& .TextField":{
            margin:"10px 0",
        },
        "& .NavButton":{
            display:"flex",
            justifyContent:"flex-end",
            "& .ConfirmButton":{
                backgroundColor:theme.palette.primary.main,
                color:theme.palette.primary.contrastText,
                fontSize:"18px",
            },
        },
    },

}));

const FormControlDelivery = styled(FormControl)(({theme})=>({
    width:"40%",
    [theme.breakpoints.down('lg')]:{
        width:"50%",
    },
    [theme.breakpoints.down('sm')]:{
        width:"100%"
    }
}));

const DivDelivery = styled("div")(({theme})=>({
    width:"100%", 
    display:"flex", 
    alignItems:"center", 
    margin:"30px 0",
    [theme.breakpoints.down('sm')]:{
        flexDirection:"column",
        alignItems:"flex-start",
    }
}));

const PriceForm = props => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);

    const MobileResponse = useMediaQuery('(max-width:600px)');

    return(
        <div className={`${classes.Content}`}>
            <Typography className={`${classes.Text}`}>Price</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        id="priceperday" 
                        label="Price/Day" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("priceperday")}} 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        id="deposit" 
                        label="มัดจำ/Trip" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("deposit")}} 
                    />
                </Grid>
                <Grid item xs={6} sm={4} style={{display:"flex",alignItems:"center"}}>
                    <Typography className={`${classes.Text}`}>3 Days Or More Discount</Typography>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <TextField 
                        id="threedaydiscount" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("threedaydiscount")}} 
                    />
                </Grid>
                {!MobileResponse && 
                    <Grid item xs={0} sm={4}></Grid>
                }
                <Grid item xs={6} sm={4} style={{display:"flex",alignItems:"center"}}>
                    <Typography className={`${classes.Text}`}>1 Months Or Move Discount</Typography>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <TextField 
                        id="monthdiscount" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("monthdiscount")}} 
                    />
                </Grid>
                {!MobileResponse && 
                    <Grid item xs={0} sm={4}></Grid>
                }
                <Grid item xs={6} sm={4} style={{display:"flex",alignItems:"center"}}>
                    <Typography className={`${classes.Text}`}>Early Bird Discount</Typography>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <TextField 
                        id="earlybirddiscount" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("earlybirddiscount")}} 
                    />
                </Grid>
                {MobileResponse && 
                    <Grid item xs={6}></Grid>
                }
                <Grid item xs={6} sm={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="Before">Before</InputLabel>
                        <Controller 
                            control={props.control}
                            name="before"
                            defaultValue=""
                            render={({field})=>(
                                <Select
                                    {...field}
                                    labelId="Before-label"
                                    id="Before"
                                    label="Before"
                                >
                                    <MenuItem value="1 Day">1 Day</MenuItem>
                                    <MenuItem value="2 Day">2 Day</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{display:"flex",alignItems:"center"}}>
                    <Typography className={`${classes.Text}`}>Cancel Free Before</Typography>
                </Grid>
                <Grid item xs={6} sm={4} >
                    <FormControl variant="outlined" fullWidth>
                        <Controller 
                            control={props.control}
                            name="cancelfreebefore"
                            defaultValue=""
                            render={({field})=>(
                                <Select
                                    {...field}
                                    labelId="CancelFreeBefore-label"
                                    id="CancelFreeBefore"
                                >
                                    <MenuItem value="1 Day">1 Day</MenuItem>
                                    <MenuItem value="2 Day">2 Day</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <DivDelivery>
                <FormControlDelivery variant="outlined">
                    <InputLabel id="Delivery">Delivery</InputLabel>
                    <Controller 
                        control={props.control}
                        name="delivery"
                        defaultValue=""
                        render={({field})=>(
                            <Select
                                {...field}
                                labelId="Delivery-label"
                                id="Delivery"
                                label="Delivery"
                            >
                                <MenuItem value="รับด้วยตัวเองเท่านั้น">รับด้วยตัวเองเท่านั้น</MenuItem>
                                <MenuItem value="รับด้วยตัวเองหรือบริการรับส่งรถ">รับด้วยตัวเองหรือบริการรับส่งรถ</MenuItem>
                            </Select>
                        )}
                    />
                </FormControlDelivery>
                <Button 
                    className={`${classes.AddButton}`}
                    onClick={()=>setOpenModal(true)}
                >Add Detail</Button>
            </DivDelivery>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        id="distanceinclude" 
                        variant="outlined" 
                        label="Distance Include"
                        fullWidth 
                        inputProps={{...props.register("distanceinclude")}} 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        id="pricecleaning" 
                        variant="outlined" 
                        label="ราคาทำความสะอาด"
                        fullWidth 
                        inputProps={{...props.register("pricecleaning")}} 
                    />
                </Grid>
            </Grid>
            <ModalStyles
                open={openModal}
                onClose={()=>setOpenModal(false)}
                aria-labelledby="Detail-modal-title"
                aria-describedby="Detail-modal-description"
            >
                <Paper className="PaperInModal">
                    <Typography className="Title">Detail</Typography>
                    <TextField 
                        className="TextField"
                        fullWidth
                        rows={10}
                        multiline
                        inputProps={{...props.register("detailDelivery")}} 
                    />
                    <div className="NavButton">
                        <Button className="ConfirmButton">Confirm</Button>
                    </div>
                </Paper>
            </ModalStyles>
            <div style={{width:"100%",display:"flex",justifyContent:"flex-end",padding:"10px 0"}}>
                <Button className={`${classes.NextButton}`} onClick={props.onClickBackProcess}>Back</Button>
                <Button className={`${classes.NextButton}`} onClick={props.onClickNextProcess}>Next</Button>
            </div>
        </div>
    );
};

export default PriceForm;