import { httpClient } from "./httpClient";

const getAllLentCars = () => {
    return httpClient.get("AllLentCars");
};

const getLentCarById = (idCar) => {
    return httpClient.get("getLentCarById/"+idCar);
};

const getLentCarsByProvince = (province) => {
    return httpClient.get("getLentCarsByProvince/"+province);
};

const getLentCarsByLenderId = (lenderId) => {
    return httpClient.get("getLentCarsByLenderId/"+lenderId);
};

export default {
    getAllLentCars,
    getLentCarById,
    getLentCarsByProvince,
    getLentCarsByLenderId,
};