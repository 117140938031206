const listOptions = [
    {
        id:1,
        name:"GPS"
    },
    {
        id:2,
        name:"ไฟสูง"
    },
    {
        id:3,
        name:"Bluetooth"
    },
    {
        id:4,
        name:"USB input"
    },
    {
        id:5,
        name:"AUX input"
    },
    {
        id:6,
        name:"Backup camera"
    },
    {
        id:7,
        name:"USB change"
    },
    {
        id:8,
        name:"Heated seats"
    },
    {
        id:9,
        name:"Keyless entry"
    },
    {
        id:10,
        name:"Automatic transmission"
    },
    {
        id:11,
        name:"EZ-Pass"
    },
    {
        id:12,
        name:"Emergency Kit with Jumper Cables"
    },
];

const listPackages = [
    {
        id:1,
        package:"Package A",
        Topic:["ประกันอุบัติเหตุสำหรับรถยนต์","ประกันอุบัติเหตุสำหรับคนขับ"],
        price:9000,
    },
    {
        id:2,
        package:"Package B",
        Topic:["ประกันอุบัติเหตุสำหรับรถยนต์","ประกันอุบัติเหตุสำหรับคนขับ"],
        price:8000,
    },
    {
        id:3,
        package:"Package C",
        Topic:["ประกันอุบัติเหตุสำหรับรถยนต์","ประกันอุบัติเหตุสำหรับคนขับ"],
        price:7000,
    },
    {
        id:4,
        package:"Package D",
        Topic:["ไม่มีประกันอุบัติเหตุ","(หากเกิดอุบัติเหตุคนขับต้องชำระเงิน 6,000 บาท)"],
        price:0,
    },
];

const listCondition = [
    {
        id:1,
        name:"No Smoking"
    },
    {
        id:2,
        name:"No Eating Food"
    },
    {
        id:3,
        name:"No Drinking"
    },
    {
        id:4,
        name:"No Pet"
    },
    {
        id:5,
        name:"Clean Interior and Exterior"
    },
];

const listExtras = [
    {
        id:1,
        name:"ไม่จำกัดไมล์"
    },
    {
        id:2,
        name:"เติมน้ำมันล่วงหน้า"
    },
    {
        id:3,
        name:"ทำความสะอาดหลังคืนรถ"
    },
    {
        id:4,
        name:"เก้าอี้ตั้งแคมป์"
    },
    {
        id:5,
        name:"ถุงนอน"
    },
    {
        id:6,
        name:"ถังน้ำแข็ง"
    },
    {
        id:7,
        name:"เต็นท์ตั้งแคมป์"
    },
    {
        id:8,
        name:"ที่นั่งเด็ก"
    },
];

export { listOptions, listPackages, listCondition, listExtras, };