import React from 'react';
import dayjs from 'dayjs';

import { styled } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PeopleIcon from '@mui/icons-material/People';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import RoomIcon from '@mui/icons-material/Room';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

import utils from "../../../../../utils";

require('dayjs/locale/th');
dayjs.locale('th');

const useStyles = makeStyles((theme)=>({
    Title:{
        fontSize: "30px",
    },
    typeAndModelDetail:{
        textAlign:"center",
    },
    GridPicture:{
        display:"flex",
        justifyContent:"center",
    },
    Avatar:{
        width:"350px",
        height:"200px",
        [theme.breakpoints.down('sm')]:{
            width:"250px",
            height:"150px",
        },
    },
    Dialog:{
        paddingBottom: "30px",
        "& .MuiPaper-root":{
            [theme.breakpoints.down('sm')]:{
                margin:"0",
            },
        },
    },
    DialogContent:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        "&::-webkit-scrollbar":{
            width:"5px"
        },
        "&::-webkit-scrollbar-thumb":{
            backgroundColor:"blue",
        },
        [theme.breakpoints.down('sm')]:{
            height:"550px",
            overflowY:"auto",
            padding:"0",
        },
    },
    Grid:{
        width:"calc(100% - 50px)",
        marginLeft:"50px",
        [theme.breakpoints.down('md')]:{
            width:"calc(100% - 20px)",
            marginLeft:"0", 
        },
    },
    Text:{
        fontSize:"20px",
        wordWrap:"break-word",
    },
    Icon:{
        marginTop:"3px",
        fontsize:"10px",
        marginRight:"3px",
    },
    Recipient:{
        color:"#6B011F",
    },
    IconYellow:{
        color:"#DCA700",
    },
    GridTextIcon:{
        display:"flex",
    },
    Fixed:{
        backgroundColor: "#DCA700",
        "&:hover":{
            backgroundColor: "#ba8e04",
        }
    },
    Confirm:{
        backgroundColor: "green",
        "&:hover":{
            backgroundColor: "darkgreen",
        }
    },
    GridTextAndIcon:{
        display:"flex",
    },
    DialogActions:{
        paddingRight:"30px",
    },
    Button:{
        width:"150px",
        height:"50px",
    },
    TextButton:{
        color:"white",
    },
    IconWeight:{
        weight:"26px",
        height:"26px",
        marginRight:"3px",
    },
}));

const GridContainText = styled(Grid)(({theme})=>({
    paddingRight:"24px",
}));

const DialogShowDetailBooking = (props) => {
    const classes = useStyles();

    const convertDate = (lawDate) => {
        let date = new Date(lawDate).toDateString();
        let splitDate = date.split(" ");
        return `${splitDate[2]} ${splitDate[1]} ${splitDate[3]}`;
    };

    return (
        <Dialog open={props.open} className={classes.Dialog} maxWidth="md">
            <DialogTitle>
                <Typography className={`${classes.Title} ${classes.typeAndModelDetail}`}>ขอรถรับส่งของ</Typography>
            </DialogTitle>
            <DialogContent className={classes.DialogContent}>
                <Avatar
                    className={`${classes.Avatar}`}
                    src={`${process.env.REACT_APP_URL}image/car/${props.getValues("imageVehicle")}`} 
                    alt={props.getValues("TypeOfCar")}
                    variant="square"
                />
                <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
                    <Grid container spacing={3} className={classes.Grid}>
                        <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                            <PersonIcon className={`${classes.Icon} ${classes.Recipient}`}/>
                            <Typography className={`${classes.Text} ${classes.Recipient}`}>ชื่อผู้รับ : &nbsp;&nbsp;{props.getValues("nameRecipient")}</Typography>
                        </GridContainText>
                        <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                            <PhoneIcon className={`${classes.Icon} ${classes.Recipient}`}/>
                            <Typography className={`${classes.Text} ${classes.Recipient}`}>Tel. ผู้รับ : &nbsp;&nbsp;{props.getValues("telephoneMobileRecipient")}</Typography>
                        </GridContainText>
                        <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                            <DriveEtaIcon className={classes.Icon}/>
                            <Typography className={`${classes.Text}`}>ประเภทของที่ส่ง : &nbsp;&nbsp;{props.getValues("typeProduct")}</Typography>
                        </GridContainText>
                        <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                            <img className={`${classes.IconWeight}`}src={`./assets/Image/Icon/weightOfProducts.png`} alt="weightOfProducts"/>
                            <Typography className={`${classes.Text}`}>น้ำหนักประมาณ : &nbsp;&nbsp;{props.getValues("weightProduct")}&nbsp;&nbsp; kg</Typography>
                        </GridContainText>
                        <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                            <CalendarTodayIcon className={`${classes.Icon}`}/>
                            <Typography className={`${classes.Text}`}>{`วันที่ : ${dayjs(props.getValues("date")).format('DD MMMM YYYY')}`}</Typography>
                        </GridContainText>
                        <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                            <QueryBuilderIcon className={`${classes.Icon}`}/>
                            <Typography className={`${classes.Text}`}>{`เวลา : ${props.getValues("startTime")} น.- ${props.getValues("endTime")} น.`}</Typography>
                        </GridContainText>
                        <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                            <RoomIcon className={`${classes.Icon}`}/>
                            <Typography className={`${classes.Text}`}>จุดรับ : &nbsp;&nbsp;{props.getValues("fromPlace")}</Typography>
                        </GridContainText>
                        <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                            <RoomIcon className={`${classes.Icon}`}/>
                            <Typography className={`${classes.Text}`}>จุดส่ง : &nbsp;&nbsp;{props.getValues("toPlace")}</Typography>
                        </GridContainText>
                        <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                            <PersonIcon className={classes.Icon}/>
                            <Typography className={`${classes.Text}`}>ผู้แจ้งขอ : &nbsp;&nbsp;{props.getValues("name")}</Typography>
                        </GridContainText>
                        <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                            <PhoneIcon className={classes.Icon}/>
                            <Typography className={`${classes.Text}`}>Tel. : &nbsp;&nbsp;{props.getValues("telephoneMobile")}</Typography>
                        </GridContainText>
                        <GridContainText item xs={12} sm={6} className={`${classes.GridTextAndIcon}`}>
                            <MonetizationOnIcon className={`${classes.Icon}  ${classes.IconYellow}`}/>
                            <Typography className={`${classes.Text}`}>{`ราคา : ${utils.numberWithCommas(props.getValues("costBooking"))} THB`}</Typography>
                        </GridContainText>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions className={classes.DialogActions}>
                <Button className={`${classes.Fixed} ${classes.Button}`} onClick={props.handleClose}>
                    <Typography className={`${classes.Text} ${classes.TextButton}`}>แก้ไข</Typography>
                </Button>
                <Button className={`${classes.Confirm} ${classes.Button}`} onClick={props.onClickSubmit}>
                    <Typography className={`${classes.Text} ${classes.TextButton}`}>จองรถ</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogShowDetailBooking;