import { httpClient } from "./httpClient";

const getAllCaterings = (filter) => {
  return httpClient.post("catering/getAllCaterings", filter);
};

const getCateringById = (cateringId) => {
  return httpClient.get(`catering/getCateringById/${cateringId}`);
};

const changeStatusById = (cateringId, status) => {
  return httpClient.put(`catering/changeStatusById/${cateringId}`, {
    status: status,
  });
};

const getAllRestaurants = () => {
  return httpClient.get(`catering/getAllRestaurants`);
};

const getRestaurantById = (restaurantId) => {
  return httpClient.get(`catering/getRestaurantById/${restaurantId}`);
};
const addNewRestaurant = (formData) => {
  return httpClient.post(`catering/addNewRestaurant`, formData, {
    "content-type": "multipart/form-data",
  });
};

const updateRestaurantMenu = (formData) => {
  return httpClient.post(`catering/updateRestaurantMenu`, formData, {
    "content-type": "multipart/form-data",
  });
};
const deleteRestaurant = (idCateringRestaurant) => {
  return httpClient.delete(`catering/deleteRestaurant/${idCateringRestaurant}`);
};
const getCateringType = () => {
  return httpClient.get(`catering/getCateringType`);
};
const addRequestCatering = (body, selected, menu) => {
  console.log("addRequestCatering", body);
  return httpClient.post(`catering/addRequestCatering`, [body, selected, menu]);
};

const updateCostRequestCatering = (data) => {
  console.log("updateCostRequestCatering", data);
  return httpClient.post(`catering/updateCostRequestCatering`, [data]);
};

const getCateringRestaurantType = () => {
  return httpClient.get(`catering/getCateringRestaurantType`);
};

const getCateringAdditionalOption = () => {
  return httpClient.get(`catering/getCateringAdditionalOption`);
};
export default {
  getAllCaterings,
  getCateringById,
  changeStatusById,
  getAllRestaurants,
  getRestaurantById,
  deleteRestaurant,
  addNewRestaurant,
  updateRestaurantMenu,
  getCateringType,
  addRequestCatering,
  getCateringRestaurantType,
  updateCostRequestCatering,
  getCateringAdditionalOption,
};
