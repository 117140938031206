import React from "react";
import { Typography, Avatar, styled, Stack, Divider } from "@mui/material";
import Card from "../Card";

import dayjs from "dayjs";
import "dayjs/locale/th";

import { getOptionMaintenanceTypes } from "../../../../../../utils/constant";

const StyledDetail = styled("div")(({theme})=>({
	["& .MuiAvatar-root"]: {
		marginLeft: "auto",
		marginRight: "auto",
		width: 200,
		height: 200,
	},
	["& .titleText"]: {
		width: 60,
		color: "#9e9e9e",
	},
	["& .fullWidth"]: {
		width: "100%",
	}
}))

const Detail = ({data}) => {
	console.log(data)
	const showCompletePercent = (value) => {
		let color = "#43a047";
		if(value < 30){
			color = "#e53935"
		}
		else if(value < 60){
			color = "#FFCC33"
		}
		return(
			<Typography fontSize={20} style={{color: color}}>{`${value}%`}</Typography>
		)
	}

	return(
		<Card>
			<StyledDetail>
				{data ? 
					<Stack spacing={2}>
						{
							data.fileUrl?
								// <Avatar src={`${process.env.REACT_APP_URL}image/maintenance/technician/${data.image}`}/>
								<Avatar src={`${data.fileUrl}`}/>

							:
								<Avatar />
						}
						<Typography textAlign="center" fontSize={24}>{data.fullName}</Typography>
						<Stack direction="row" spacing={2} justifyContent="center" divider={<Divider orientation="vertical" flexItem />}>
							<Stack>
								<Typography fontSize={14} color="#9e9e9e!important">งานที่รับ</Typography>
								<Typography fontSize={20}>{data.totalWork}</Typography>
							</Stack>
							<Stack>
								<Typography fontSize={14} color="#9e9e9e!important">สำเร็จ</Typography>
								{data.totalWork > 0 ? showCompletePercent(((data.completeWork/data.totalWork)*100).toFixed(0)) : showCompletePercent(0)}
							</Stack>
						</Stack>
						<Stack direction="row" spacing={2}>
							<Typography className="titleText" fontSize={14}>เบอร์โทร</Typography>
							<Typography>{data.phoneNumber}</Typography>
						</Stack>
						<Stack direction="row" spacing={2}>
							<Typography className="titleText" fontSize={14}>Email</Typography>
							<Typography>{data.email}</Typography>
						</Stack>
						{/* <Stack direction="row" spacing={2}>
							<Typography className="titleText" fontSize={14}>ประเภท</Typography>
							<Typography>{data.types.join(", ")}</Typography>
						</Stack> */}
						<Stack spacing={2}>
							<Typography className="titleText fullWidth" fontSize={14}>รายละเอียด</Typography>
							<Typography>{data.description}</Typography>
						</Stack>
						<Stack spacing={2}>
							<Typography className="titleText fullWidth" fontSize={14}>งานที่ได้รับมอบหมาย</Typography>
							<Stack spacing={2} divider={<Divider flexItem/>}>
								{data?.upComingWork.length === 0 && <Typography fontSize={14}>ยังไม่รับงาน</Typography>}
								{data?.upComingWork.map((w,i)=>(
									<Stack>
										<Typography fontSize={14}>{dayjs(new Date(w.startDate)).locale('th').format("DD MMMM YYYY hh.mm น.")}</Typography>
										<Typography>{getOptionMaintenanceTypes().find(mt => parseInt(mt.value) === parseInt(w.maintenanceType)).label}</Typography>
									</Stack>
								))}
								{/* // <Stack>
								// 	<Typography fontSize={14}>12 ธันวาคม 2564 12.00 น.</Typography>
								// 	<Typography>งานประปา</Typography>
								// </Stack>
								// <Stack>
								// 	<Typography fontSize={14}>12 ธันวาคม 2564 12.00 น.</Typography>
								// 	<Typography>งานประปา</Typography>
								// </Stack> */}
							</Stack>
						</Stack>
					</Stack>
				:
					<Typography fontSize={24} textAlign="center">เลือกรายชื่อเพื่อดูรายละเอียด</Typography>
				}
			</StyledDetail>
		</Card>
	)
}

export default Detail;