import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { styled } from '@mui/styles';
import { Typography } from '@mui/material';

// import { getAllUsers } from "../../../../actions/user";
import { getRouteDayByIdDriver, removeUserRoutesDay } from "../../../../actions/routeDay";
import { getRoutesByRouteLineAndRouteDate } from "../../../../actions/routeCrossAreaCarPool";
import wayOfDriverService from "../../../../services/wayOfDriver.service";
import RouteDayService from '../../../../services/routeToday.service';

import DivEmployee from "./divEmployee";
import ButtonGoToMapPage from "./ButtonGoToMapPage";

const DivCoverReturn = styled("div")({
  width: "100%",
  height: "calc(100% - 64px)",
  display: "flex",
  alignItems: "center",
  position: "relative",
});

const DivCoverContent = styled("div")({
  width: "100%",
  height: "calc(500px - 40px)",
  paddingTop: "30px",
  paddingBottom: "10px",
  backgroundColor: "#03256C",
  position:"absolute",
});

const Background = styled("img")({
  width: "100%",
  height: "100%",
  opacity: "1",
});

const Employee = styled("div")({
  width: "100%",
  height: "460px",
  display: "flex",
  marginBottom:"20px",
  scrollSnapType: "x mandatory",
  overflow: "auto",
  "&::-webkit-scrollbar":{
    display: "none",
  },
});

const NotPassenger = styled(Typography)({
  color:"white",
  fontSize:"48px",
  textAlign:"center",
});

const PlanPage = props => {
  const dispatch = useDispatch();
  const { result: RouteOfDriver } = useSelector(state => state.routes);
  const [clickDelete, setClickDelete] = useState(false);
  const { result: Driver } = useSelector((state) => state.driverRouteDay);
  const { user: currentUser } = useSelector((state) => state.auth);

 
  useEffect(() => {
    console.log(Driver)
    if(Driver){
      
      if(Driver.typeDriver === 1){
        
        dispatch(getRouteDayByIdDriver(Driver.idDriver));
      }
      else if(Driver.typeDriver === 2){
        console.log("hhh", Driver)
        dispatch(getRoutesByRouteLineAndRouteDate(Driver.routeLine,Driver.routeDate));
      }
    }else{
      props.history.push("/driver");
    }
  }, [clickDelete]);

  const onClickDeleteHandler = (idEmployee) => {
    dispatch(removeUserRoutesDay(idEmployee, Driver.idDriver));
    setClickDelete(!clickDelete);
  };

  const OnClickMapButtonHandler = () => {
    props.history.push("/map");
  };

  const onClickViewButton = (employssId) => {
    props.history.push("/plan/profileEmployee/"+employssId);
  };

  const onClickGettingComplete = (idUser) => {
    RouteDayService.gettingUserComplete(idUser,Driver.idDriver);
  };

  return (
    <DivCoverReturn className="page">
      <Background src={`./assets/background/backgroundPlan.jpg`} alt="backgroundPlan" />
      <ButtonGoToMapPage OnClickMapButtonHandler={OnClickMapButtonHandler}/>
      <DivCoverContent>
        <Employee>
          {RouteOfDriver ?
            RouteOfDriver.map((employee, index) => {
              return ( 
                <DivEmployee 
                  key={index} 
                  order={index} 
                  currentEmployee={employee} 
                  onClickDeleteHandler={onClickDeleteHandler}
                  onClickViewButton={onClickViewButton}
                  onClickGettingComplete={()=>onClickGettingComplete(employee.idUser)}
                />
              );
            })
            :
            <div style={{width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
              <NotPassenger>
                ไม่มีผู้โดยสารในเส้นทางของคุณ
              </NotPassenger>
            </div>
          }
        </Employee>
      </DivCoverContent>
    </DivCoverReturn>
  );
};

export default PlanPage;
