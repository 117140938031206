import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import { getCrossAreaCarBookingByIdApprovedUserForManager } from "../../../../actions/crossAreaCarBooking";
import { getInAreaCarBookingByIdApprovedUserForManager } from "../../../../actions/inAreaCarBooking";
import CrossAreaCarBookingService from "../../../../services/crossAreaCarBooking.service";
import InAreaCarBookingService from "../../../../services/inAreaCarBooking.service";

import MeetingRoomService from "../../../../services/meetingRoom.service";
import CrossAreaCarPoolBookingService from "../../../../services/crossAreaCarPoolBooking.service";
import DeliveryCarBookingService from "../../../../services/deliveryCarBooking.service";

import DialogOfConfirmationOfApprovalOfAllBooking from "./DialogOfConfirmationOfApprovalOfAllBooking";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    height: "90%",
    width: "80%",
    // display: "grid",
    // flexDirection: "row",
    marginTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",

    ["@media only screen and (max-width: 375px)"]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  Title: {
    textAlign: "center",
    fontSize: "30px",
    marginBottom: "5px",
  },
  Card: {
    marginBottom: "20px",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    boxShadow: theme.shadows[3],
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "space-between",
    },
    paddingBottom: "10px",
  },
  Avatar: {
    width: "100px",
    height: "100px",
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
    },
  },
  Name: {
    color: "#185ADB",
    fontSize: "20px",
    marginBottom: "10px",
  },
  TextAndIcon: {
    display: "flex",
    marginBottom: "5px",
  },
  Icon: {
    marginRight: "10px",
    color: "#293A80",
  },
  IconButton: {
    marginRight: "5px",
  },
  ButtonRight: {
    marginRight: "10px",
  },
  Button: {
    height: "50px",
    width: "130px",
    fontSize: "20px",
    borderRadius: "25px",
  },
  ApproveButton: {
    color: "white",
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
  DisapproveButton: {
    color: "white",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "darkred",
    },
  },
  DetailButton: {
    color: "white",
    backgroundColor: "#979797",
    "&:hover": {
      backgroundColor: "#757575",
    },
  },
  AvaterAndName: {
    display: "flex",
  },
  NameAndType: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  GirdAvatarAndName: {
    paddingRight: "0 !important",
  },
  NavButton: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ["@media only screen and (max-width: 1100px)"]: {
      marginTop: "5px",
      height: "50px",
    },
  },
  NavButtonApprove: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginRight: "100px",
    color: "green",
    ["@media only screen and (max-width: 1100px)"]: {
      marginTop: "5px",
      height: "50px",
    },
  },
  NavButtonApproveReject: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginRight: "100px",
    color: "red",
    ["@media only screen and (max-width: 1100px)"]: {
      marginTop: "5px",
      height: "50px",
    },
  },
  GridContainer: {
    width: "70%",
    ["@media only screen and (max-width: 1100px)"]: {
      width: "100%",
    },
  },
  GirdPurposeAndDateAndTime: {
    paddingLeft: "15px",
  },
  Text: {
    color: "#293A80",
  },
  CrossArea: {
    color: "#C400C6",
  },
  InArea: {
    color: "#0A81AB",
  },
  DateAndTime: {
    ["@media (min-width: 700px) and (max-width: 780px)"]: {
      display: "flex",
    },
  },
  Date: {
    marginRight: "40px",
  },
  NavButtonTop: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "20px",
  },
  ButtonAllBooking: {
    height: "56px",
    fontSize: "20px",
    color: "white",
    marginBottom: "10px",
    paddingRight: "15px",
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
  IconAllBooking: {
    marginRight: "10px",
    marginLeft: "10px",
  },
  AllContent: {
    width: "100%",
    height: "100%",
  },
  CardContent: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    border: "3px solid rgba(90, 114, 123, 0.11)",
    boxShadow: "rgba(90, 114, 123, 0.11) 0px 7px 30px 0px",
  },
  Tabbar: {
    width: "auto",
    display: "flex",
    justifyContent: "space-between",
    padding: "25px",
  },
  TableContent: {
    padding: "25px",
  },
}));

const ApprovePage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((state) => state.auth);
  const { result: Bookings } = useSelector((state) => state.bookings);
  const [isBooking, setBooking] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [typeOfBooking, setTypeOfBooking] = useState("CrossArea");
  const [lengthOfWaiting, setLengthOfWaiting] = useState(0);

  const handleChange = (event, newValue) => {
    setTypeOfBooking(newValue);
  };

  useEffect(() => {
    if (typeOfBooking === "CrossArea") {
      // dispatch(
      //   getCrossAreaCarBookingByIdApprovedUserForManager(authUser.idUser)
      // );
      CrossAreaCarBookingService.getAllCrossAreaCarBookings().then((res) => {
        if (res.data) {
          setBooking(res.data);
        } else {
          console.log("Error");
        }
      });
    } else if (typeOfBooking === "MeetingRoom") {
      MeetingRoomService.getAllMeetingRoomBooking().then((res) => {
        if (res.data) {
          setBooking(res.data);
        } else {
          console.log("Error");
        }
      });
    } else if (typeOfBooking === "InArea") {
      InAreaCarBookingService.getAllInAreaCarBookings().then((res) => {
        if (res.data) {
          setBooking(res.data);
        } else {
          console.log("Error");
        }
      });

      // dispatch(getInAreaCarBookingByIdApprovedUserForManager(authUser.idUser));
    } else if (typeOfBooking === "CrossAreaPool") {
      CrossAreaCarPoolBookingService.getAllCrossAreaCarPoolBookings().then(
        (res) => {
          if (res.data) {
            setBooking(res.data);
          } else {
            console.log("Error");
          }
        }
      );

      // dispatch(getInAreaCarBookingByIdApprovedUserForManager(authUser.idUser));
    } else if (typeOfBooking === "DeliveryCar") {
      DeliveryCarBookingService.getAllDeliveryCarBookings().then((res) => {
        if (res.data) {
          setBooking(res.data);
        } else {
          console.log("Error");
        }
      });

      // dispatch(getInAreaCarBookingByIdApprovedUserForManager(authUser.idUser));
    }
  }, [typeOfBooking]);

  // if(Bookings != undefined){
  //   console.log(Bookings);

  // }
  console.log(isBooking);
  useEffect(() => {
    if (Bookings != undefined) {
      setLengthOfWaiting(Bookings.length);
    } else {
      setLengthOfWaiting(0);
    }
  });

  const verificationTypeOfBooking = (type) => {
    console.log(type, "type");
    if (type === "CrossArea") {
      return "การจองรถข้ามพื้นที่พิเศษ";
    } else if (type === "InArea") {
      return "การจองรถในพื้นที่";
    } else if (type === "MeetingRoom") {
      return "จองสถานที่";
    } else if (type === "CrossAreaPool") {
      return "การจองรถข้ามพื้นที่Pool";
    } else if (type === "DeliveryCar") {
      return "การจองรถส่งของ";
    }
  };

  const verificationTypeOfBookingReturnBoolean = (type) => {
    if (type === "CrossArea") {
      return true;
    } else if (type === "InArea") {
      return false;
    }
  };

  const convertTime = (lawStartDate, lawEndDate) => {
    return `${dayjs(lawStartDate).format("HH:mm")} - ${dayjs(lawEndDate).format(
      "HH:mm"
    )}`;
  };

  const convertDate = (lawstartDate, lawendDate) => {
    if (lawendDate) {
      return `${dayjs(lawstartDate).format("DD")} - ${dayjs(lawendDate).format(
        "DD MMMM YYYY"
      )}`;
    } else {
      return dayjs(lawstartDate).format("DD MMMM YYYY");
    }
  };

  const watchDetail = (idBooking) => {
    props.history.push("/manager/detail_booking", {
      typeBooking: typeOfBooking,
      idBooking: idBooking,
    });
  };

  const onClickApprovalOfAllBooking = (typeOfBooking) => {
    console.log(typeOfBooking)
    setOpenDialog(true);
  };

  const onClickCancellationOfApprovalOfAllBooking = () => {
    setOpenDialog(false);
  };

  const onClickApprove = (typeOfBooking, idBooking, status) => {
    if (typeOfBooking === "CrossArea") {
      CrossAreaCarBookingService.postApprovedCrossAreaCarBooking({
        typeOfBooking: typeOfBooking,
        idBooking: idBooking,
        status: status,
      }).then((res) => {
        if (res.data) {
          setBooking(res.data);
        }
      });
      // return "การจองรถข้ามพื้นที่พิเศษ";
    } else if (typeOfBooking === "InArea") {
      InAreaCarBookingService.postApprovedInAreaCarBooking({
        typeOfBooking: typeOfBooking,
        idBooking: idBooking,
        status: status,
      }).then((res) => {
        if (res.data) {
          setBooking(res.data);
        }
      });
      // return "การจองรถในพื้นที่";
    } else if (typeOfBooking === "MeetingRoom") {
      MeetingRoomService.postApprovedlMeetingRoomBooking({
        typeOfBooking: typeOfBooking,
        idBooking: idBooking,
        status: status,
      }).then((res) => {
        if (res.data) {
          setBooking(res.data);
        }
      });
      // return "จองสถานที่";
    } else if (typeOfBooking === "CrossAreaPool") {
      CrossAreaCarPoolBookingService.postApprovedlCrossAreaCarPoolBooking({
        typeOfBooking: typeOfBooking,
        idBooking: idBooking,
        status: status,
      }).then((res) => {
        if (res.data) {
          setBooking(res.data);
        }
      });
      // return "จองสถานที่";
    } else if (typeOfBooking === "DeliveryCar") {
      DeliveryCarBookingService.postApprovedlDeliveryCarBooking({
        typeOfBooking: typeOfBooking,
        idBooking: idBooking,
        status: status,
      }).then((res) => {
        if (res.data) {
          setBooking(res.data);
        }
      });
      // return "จองสถานที่";
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "RGB(25, 118, 209)",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <div className={`page ${classes.DivCoverReturn}`}>
      <Typography className={classes.Title}>รายการรออนุมัติ</Typography>
      <div className={`${classes.NavButtonTop}`}>
        <FormControl style={{ width: "200px", marginBottom: "10px" }}>
          <InputLabel>ประเภทการจอง</InputLabel>
          <Select
            label="ประเภทการจอง"
            value={typeOfBooking}
            onChange={(e) => setTypeOfBooking(e.target.value)}
          >
            <MenuItem value={"CrossArea"}>จองรถข้ามพื้นที่เฉพาะ</MenuItem>
            <MenuItem value={"InArea"}>จองรถในพื้นที่</MenuItem>
            <MenuItem value={"MeetingRoom"}>จองสถานที่</MenuItem>
            <MenuItem value={"CrossAreaPool"}>จองรถข้ามพื้นที่Pool</MenuItem>
            <MenuItem value={"DeliveryCar"}>จองรถส่งของ</MenuItem>
          </Select>
        </FormControl>
        <Button
          className={`${classes.ButtonAllBooking}`}
          onClick={() => onClickApprovalOfAllBooking(typeOfBooking)}
        >
          <DoneAllIcon className={`${classes.IconAllBooking}`} />
          อนุมัติทั้งหมด
        </Button>
      </div>

      {isBooking.length > 0 &&
        typeOfBooking === "InArea" &&
        isBooking.map((booking) => (
          <Card className={`${classes.Card}`} key={booking.idinAreaCarBooking}>
            <Grid container className={classes.GridContainer}>
              <Grid item xs={12} md={6} className={classes.GirdAvatarAndName}>
                <div className={classes.AvaterAndName}>
                  <Avatar
                    className={classes.Avatar}
                    // src={`${process.env.REACT_APP_URL}image/profile/${booking.user.image}`}
                    // alt={booking.user.image}
                  />
                  <div className={classes.NameAndType}>
                    <Typography className={classes.Name}>
                      {booking.name}
                    </Typography>
                    <div className={`${classes.TextAndIcon}`}>
                      {verificationTypeOfBookingReturnBoolean(typeOfBooking) ? (
                        <DriveEtaIcon
                          className={`${classes.Icon} ${classes.CrossArea}`}
                        />
                      ) : (
                        <EmojiTransportationIcon
                          className={`${classes.Icon} ${classes.InArea}`}
                        />
                      )}
                      <Typography
                        className={
                          verificationTypeOfBookingReturnBoolean(typeOfBooking)
                            ? classes.CrossArea
                            : classes.InArea
                        }
                      >{`ประเภท : ${verificationTypeOfBooking(
                        typeOfBooking
                      )}`}</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                spacing={2}
                alignItems="center"
                className={classes.GirdPurposeAndDateAndTime}
              >
                <Grid item xs={12}>
                  <div className={`${classes.TextAndIcon}`}>
                    <GpsFixedRoundedIcon className={`${classes.Icon}`} />
                    <Typography
                      className={`${classes.Text}`}
                    >{`วัตถุประสงค์ : ${booking.purpose}`}</Typography>
                  </div>
                  <div className={classes.DateAndTime}>
                    <div className={`${classes.TextAndIcon} ${classes.Date}`}>
                      <CalendarTodayIcon className={`${classes.Icon}`} />
                      <Typography
                        className={`${classes.Text}`}
                      >{`วันที่ใช้ : ${convertDate(
                        booking.departureDate,
                        booking.returnDate
                      )}`}</Typography>
                    </div>
                    <div className={`${classes.TextAndIcon}`}>
                      <AccessTimeRoundedIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`}>
                        {`ระยะเวลา : ${convertTime(
                          booking.startTime,
                          booking.endTime
                        )}`}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {booking.statusApproved === "Success" && (
              <div className={classes.NavButtonApprove}>
                <p>อนุมัติแล้ว</p>
              </div>
            )}
            {booking.statusApproved === "Reject" && (
              <div className={classes.NavButtonApprove}>
                <p>ไม่อนุมัติ</p>
              </div>
            )}
            {booking.statusApproved != "Success" && booking.statusApproved != "Reject" && (
              <div className={classes.NavButton}>
                <IconButton
                  aria-label="SearchIcon"
                  className={`${classes.DetailButton} ${classes.ButtonRight}`}
                  onClick={() => {
                    watchDetail(booking.idNewMeetingRoomBooking);
                  }}
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
                <Button
                  className={`${classes.ApproveButton} ${classes.ButtonRight} ${classes.Button}`}
                  onClick={() => {
                    onClickApprove(
                      typeOfBooking,
                      booking.idinAreaCarBooking,
                      "Success"
                    );
                  }}
                >
                  <CheckIcon className={classes.IconButton} />
                  {` อนุมัติ`}
                </Button>
                <Button
                  className={`${classes.DisapproveButton} ${classes.Button} ${classes.ButtonRight}`}
                  onClick={() => {
                    onClickApprove(
                      typeOfBooking,
                      booking.idinAreaCarBooking,
                      "Reject"
                    );
                  }}
                >
                  <ClearIcon className={classes.IconButton} />
                  {` ไม่อนุมัติ`}
                </Button>
              </div>
            )}
          </Card>
        ))}
      {isBooking.length > 0 &&
        typeOfBooking === "MeetingRoom" &&
        isBooking.map((booking) => (
          <Card
            className={`${classes.Card}`}
            key={booking.idNewMeetingRoomBooking}
          >
            <Grid container className={classes.GridContainer}>
              <Grid item xs={12} md={6} className={classes.GirdAvatarAndName}>
                <div className={classes.AvaterAndName}>
                  <Avatar
                    className={classes.Avatar}
                    // src={`${process.env.REACT_APP_URL}image/profile/${booking.user.image}`}
                    // alt={booking.user.image}
                  />
                  <div className={classes.NameAndType}>
                    <Typography className={classes.Name}>
                      {booking.nameUser}
                    </Typography>
                    <div className={`${classes.TextAndIcon}`}>
                      {verificationTypeOfBookingReturnBoolean(typeOfBooking) ? (
                        <DriveEtaIcon
                          className={`${classes.Icon} ${classes.CrossArea}`}
                        />
                      ) : (
                        <EmojiTransportationIcon
                          className={`${classes.Icon} ${classes.InArea}`}
                        />
                      )}
                      <Typography
                        className={
                          verificationTypeOfBookingReturnBoolean(typeOfBooking)
                            ? classes.CrossArea
                            : classes.InArea
                        }
                      >{`ประเภท : ${verificationTypeOfBooking(
                        typeOfBooking
                      )}`}</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                spacing={2}
                alignItems="center"
                className={classes.GirdPurposeAndDateAndTime}
              >
                <Grid item xs={12}>
                  {/* <div className={`${classes.TextAndIcon}`}>
                    <GpsFixedRoundedIcon className={`${classes.Icon}`} />
                    <Typography
                      className={`${classes.Text}`}
                    >{`วัตถุประสงค์ : ${booking.purpose}`}</Typography>
                  </div> */}
                  <div className={classes.DateAndTime}>
                    <div className={`${classes.TextAndIcon} ${classes.Date}`}>
                      <CalendarTodayIcon className={`${classes.Icon}`} />
                      <Typography
                        className={`${classes.Text}`}
                      >{`วันที่ใช้ : ${convertDate(
                        booking.startDate,
                        booking.endDate
                      )}`}</Typography>
                    </div>
                    <div className={`${classes.TextAndIcon}`}>
                      <AccessTimeRoundedIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`}>
                        {`ระยะเวลา : ${booking.timeStart} - ${booking.timeEnd}`}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {booking.statusApprove === "Success" && (
              <div className={classes.NavButtonApprove}>
                <p>อนุมัติแล้ว</p>
              </div>
            )}
            {booking.statusApprove === "Reject" && (
              <div className={classes.NavButtonApproveReject}>
                <p>ไม่อนุมัติ</p>
              </div>
            )}
            {booking.statusApprove != "Success" && booking.statusApprove != "Reject" && (
              <div className={classes.NavButton}>
                <IconButton
                  aria-label="SearchIcon"
                  className={`${classes.DetailButton} ${classes.ButtonRight}`}
                  onClick={() => {
                    watchDetail(booking.idNewMeetingRoomBooking);
                  }}
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
                <Button
                  className={`${classes.ApproveButton} ${classes.ButtonRight} ${classes.Button}`}
                  onClick={() => {
                    onClickApprove(
                      typeOfBooking,
                      booking.idNewMeetingRoomBooking,
                      "Success"
                    );
                  }}
                >
                  <CheckIcon className={classes.IconButton} />
                  {` อนุมัติ`}
                </Button>
                <Button
                  className={`${classes.DisapproveButton} ${classes.Button} ${classes.ButtonRight}`}
                  onClick={() => {
                    onClickApprove(
                      typeOfBooking,
                      booking.idNewMeetingRoomBooking,
                      "Reject"
                    );
                  }}
                >
                  <ClearIcon className={classes.IconButton} />
                  {` ไม่อนุมัติ`}
                </Button>
              </div>
            )}
          </Card>
        ))}
      {isBooking.length > 0 &&
        typeOfBooking === "CrossArea" &&
        isBooking.map((booking) => (
          <Card
            className={`${classes.Card}`}
            key={booking.idCrossAreaCarBooking}
          >
            <Grid container className={classes.GridContainer}>
              <Grid item xs={12} md={6} className={classes.GirdAvatarAndName}>
                <div className={classes.AvaterAndName}>
                  <Avatar
                    className={classes.Avatar}
                    // src={`${process.env.REACT_APP_URL}image/profile/${booking.user.image}`}
                    // alt={booking.user.image}
                  />
                  <div className={classes.NameAndType}>
                    <Typography className={classes.Name}>
                      {booking.name}
                    </Typography>
                    <div className={`${classes.TextAndIcon}`}>
                      {verificationTypeOfBookingReturnBoolean(typeOfBooking) ? (
                        <DriveEtaIcon
                          className={`${classes.Icon} ${classes.CrossArea}`}
                        />
                      ) : (
                        <EmojiTransportationIcon
                          className={`${classes.Icon} ${classes.InArea}`}
                        />
                      )}
                      <Typography
                        className={
                          verificationTypeOfBookingReturnBoolean(typeOfBooking)
                            ? classes.CrossArea
                            : classes.InArea
                        }
                      >{`ประเภท : ${verificationTypeOfBooking(
                        typeOfBooking
                      )}`}</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                spacing={2}
                alignItems="center"
                className={classes.GirdPurposeAndDateAndTime}
              >
                <Grid item xs={12}>
                  <div className={`${classes.TextAndIcon}`}>
                    <GpsFixedRoundedIcon className={`${classes.Icon}`} />
                    <Typography
                      className={`${classes.Text}`}
                    >{`วัตถุประสงค์ : ${booking.purpose === "" ? "-" : booking.purpose}`}</Typography>
                  </div>
                  <div className={classes.DateAndTime}>
                    <div className={`${classes.TextAndIcon} ${classes.Date}`}>
                      <CalendarTodayIcon className={`${classes.Icon}`} />
                      <Typography
                        className={`${classes.Text}`}
                      >{`วันที่ใช้ : ${convertDate(
                        booking.departureDate,
                        booking.returnDate
                      )}`}</Typography>
                    </div>
                    <div className={`${classes.TextAndIcon}`}>
                      <AccessTimeRoundedIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`}>
                        {`ระยะเวลา : ${convertTime(
                          booking.startTime,
                          booking.endTime
                        )}`}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {booking.statusApproved === "Success" && (
              <div className={classes.NavButtonApprove}>
                <p>อนุมัติแล้ว</p>
              </div>
            )}
            {booking.statusApproved === "Reject" && (
              <div className={classes.NavButtonApproveReject}>
                <p>ไม่อนุมัติ</p>
              </div>
            )}
            {booking.statusApproved != "Success" && booking.statusApproved != "Reject" &&(
              <div className={classes.NavButton}>
                <IconButton
                  aria-label="SearchIcon"
                  className={`${classes.DetailButton} ${classes.ButtonRight}`}
                  onClick={() => {
                    watchDetail(booking.idCrossAreaCarBooking);
                  }}
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
                <Button
                  className={`${classes.ApproveButton} ${classes.ButtonRight} ${classes.Button}`}
                  onClick={() => {
                    onClickApprove(
                      typeOfBooking,
                      booking.idCrossAreaCarBooking,
                      "Success"
                    );
                  }}
                >
                  <CheckIcon className={classes.IconButton} />
                  {` อนุมัติ`}
                </Button>
                <Button
                  className={`${classes.DisapproveButton} ${classes.Button} ${classes.ButtonRight}`}
                  onClick={() => {
                    onClickApprove(
                      typeOfBooking,
                      booking.idCrossAreaCarBooking,
                      "Reject"
                    );
                  }}
                >
                  <ClearIcon className={classes.IconButton} />
                  {` ไม่อนุมัติ`}
                </Button>
              </div>
            )}
          </Card>
        ))}
      {isBooking.length > 0 &&
        typeOfBooking === "CrossAreaPool" &&
        isBooking.map((booking) => (
          <Card
            className={`${classes.Card}`}
            key={booking.idCrossAreaCarPoolBooking}
          >
            <Grid container className={classes.GridContainer}>
              <Grid item xs={12} md={6} className={classes.GirdAvatarAndName}>
                <div className={classes.AvaterAndName}>
                  <Avatar
                    className={classes.Avatar}
                    // src={`${process.env.REACT_APP_URL}image/profile/${booking.user.image}`}
                    // alt={booking.user.image}
                  />
                  <div className={classes.NameAndType}>
                    <Typography className={classes.Name}>
                      {booking.name}
                    </Typography>
                    <div className={`${classes.TextAndIcon}`}>
                      {verificationTypeOfBookingReturnBoolean(typeOfBooking) ? (
                        <DriveEtaIcon
                          className={`${classes.Icon} ${classes.CrossArea}`}
                        />
                      ) : (
                        <EmojiTransportationIcon
                          className={`${classes.Icon} ${classes.InArea}`}
                        />
                      )}
                      <Typography
                        className={
                          verificationTypeOfBookingReturnBoolean(typeOfBooking)
                            ? classes.CrossArea
                            : classes.InArea
                        }
                      >{`ประเภท : ${verificationTypeOfBooking(
                        typeOfBooking
                      )}`}</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                spacing={2}
                alignItems="center"
                className={classes.GirdPurposeAndDateAndTime}
              >
                <Grid item xs={12}>
                  {/* <div className={`${classes.TextAndIcon}`}>
                    <GpsFixedRoundedIcon className={`${classes.Icon}`} />
                    <Typography
                      className={`${classes.Text}`}
                    >{`วัตถุประสงค์ : จองสถานที่`}</Typography>
                  </div> */}
                  <div className={classes.DateAndTime}>
                    <div className={`${classes.TextAndIcon} ${classes.Date}`}>
                      <CalendarTodayIcon className={`${classes.Icon}`} />
                      <Typography
                        className={`${classes.Text}`}
                      >{`วันที่ใช้ : ${convertDate(
                        booking.departureDate,
                        booking.returnDate
                      )}`}</Typography>
                    </div>
                    <div className={`${classes.TextAndIcon}`}>
                      <AccessTimeRoundedIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`}>
                        {`ระยะเวลา : ${convertTime(
                          booking.startTime,
                          booking.endTime
                        )}`}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {booking.statusApproved === "Success" && (
              <div className={classes.NavButtonApprove}>
                <p>อนุมัติแล้ว</p>
              </div>
            )}
            {booking.statusApproved === "Reject" && (
              <div className={classes.NavButtonApproveReject}>
                <p>ไม่อนุมัติ</p>
              </div>
            )}
            {booking.statusApproved != "Success" && booking.statusApproved != "Reject" && (
              <div className={classes.NavButton}>
                <IconButton
                  aria-label="SearchIcon"
                  className={`${classes.DetailButton} ${classes.ButtonRight}`}
                  onClick={() => {
                    watchDetail(booking.idCrossAreaCarPoolBooking);
                  }}
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
                <Button
                  className={`${classes.ApproveButton} ${classes.ButtonRight} ${classes.Button}`}
                  onClick={() => {
                    onClickApprove(
                      typeOfBooking,
                      booking.idCrossAreaCarPoolBooking,
                      "Success"
                    );
                  }}
                >
                  <CheckIcon className={classes.IconButton} />
                  {` อนุมัติ`}
                </Button>
                <Button
                  className={`${classes.DisapproveButton} ${classes.Button} ${classes.ButtonRight}`}
                  onClick={() => {
                    onClickApprove(
                      typeOfBooking,
                      booking.idCrossAreaCarPoolBooking,
                      "Reject"
                    );
                  }}
                >
                  <ClearIcon className={classes.IconButton} />
                  {` ไม่อนุมัติ`}
                </Button>
              </div>
            )}
          </Card>
        ))}
      {isBooking.length > 0 &&
        typeOfBooking === "DeliveryCar" &&
        isBooking.map((booking) => (
          <Card
            className={`${classes.Card}`}
            key={booking.idCrossAreaCarPoolBooking}
          >
            <Grid container className={classes.GridContainer}>
              <Grid item xs={12} md={6} className={classes.GirdAvatarAndName}>
                <div className={classes.AvaterAndName}>
                  <Avatar
                    className={classes.Avatar}
                    // src={`${process.env.REACT_APP_URL}image/profile/${booking.user.image}`}
                    // alt={booking.user.image}
                  />
                  <div className={classes.NameAndType}>
                    <Typography className={classes.Name}>
                      {booking.name}
                    </Typography>
                    <div className={`${classes.TextAndIcon}`}>
                      {verificationTypeOfBookingReturnBoolean(typeOfBooking) ? (
                        <DriveEtaIcon
                          className={`${classes.Icon} ${classes.CrossArea}`}
                        />
                      ) : (
                        <EmojiTransportationIcon
                          className={`${classes.Icon} ${classes.InArea}`}
                        />
                      )}
                      <Typography
                        className={
                          verificationTypeOfBookingReturnBoolean(typeOfBooking)
                            ? classes.CrossArea
                            : classes.InArea
                        }
                      >{`ประเภท : ${verificationTypeOfBooking(
                        typeOfBooking
                      )}`}</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                spacing={2}
                alignItems="center"
                className={classes.GirdPurposeAndDateAndTime}
              >
                <Grid item xs={12}>
                  <div className={`${classes.TextAndIcon}`}>
                    <GpsFixedRoundedIcon className={`${classes.Icon}`} />
                    <Typography
                      className={`${classes.Text}`}
                    >{`วัตถุประสงค์ : ${booking.purpose === "" ? "-" : booking.purpose}`}</Typography>
                  </div>
                  <div className={classes.DateAndTime}>
                    <div className={`${classes.TextAndIcon} ${classes.Date}`}>
                      <CalendarTodayIcon className={`${classes.Icon}`} />
                      <Typography
                        className={`${classes.Text}`}
                      >{`วันที่ใช้ : ${convertDate(
                        booking.departureDate,
                        booking.returnDate
                      )}`}</Typography>
                    </div>
                    <div className={`${classes.TextAndIcon}`}>
                      <AccessTimeRoundedIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`}>
                        {`ระยะเวลา : ${convertTime(
                          booking.startTime,
                          booking.endTime
                        )}`}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {booking.statusApproved === "Success" && (
              <div className={classes.NavButtonApprove}>
                <p>อนุมัติแล้ว</p>
              </div>
            )}
            {booking.statusApproved === "Reject" && (
              <div className={classes.NavButtonApproveReject}>
                <p>ไม่อนุมัติ</p>
              </div>
            )}
            {booking.statusApproved != "Success" && booking.statusApproved != "Reject" && (
              <div className={classes.NavButton}>
                <IconButton
                  aria-label="SearchIcon"
                  className={`${classes.DetailButton} ${classes.ButtonRight}`}
                  onClick={() => {
                    watchDetail(booking.idDeliveryCarBooking);
                  }}
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
                <Button
                  className={`${classes.ApproveButton} ${classes.ButtonRight} ${classes.Button}`}
                  onClick={() => {
                    onClickApprove(
                      typeOfBooking,
                      booking.idDeliveryCarBooking,
                      "Success"
                    );
                  }}
                >
                  <CheckIcon className={classes.IconButton} />
                  {` อนุมัติ`}
                </Button>
                <Button
                  className={`${classes.DisapproveButton} ${classes.Button} ${classes.ButtonRight}`}
                  onClick={() => {
                    onClickApprove(
                      typeOfBooking,
                      booking.idDeliveryCarBooking,
                      "Reject"
                    );
                  }}
                >
                  <ClearIcon className={classes.IconButton} />
                  {` ไม่อนุมัติ`}
                </Button>
              </div>
            )}
          </Card>
        ))}
      <DialogOfConfirmationOfApprovalOfAllBooking
        openDialog={openDialog}
        onCancellation={onClickCancellationOfApprovalOfAllBooking}
      />
    </div>
  );
};

export default ApprovePage;
