import { httpClient } from "./httpClient";

const getAllRoutes = () => {
    return httpClient.get("routes");
};

const getRoutesBySiteShift = (SiteShift) => {
    return httpClient.post("route_site_shift",SiteShift);
};

export default {
    getAllRoutes,
    getRoutesBySiteShift,
};