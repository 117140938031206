import { httpClient } from "./httpClient";

const getDriverEmergencyByIdUser = (id) => {
  return httpClient.get("driver_emergency_by_id/" + id);
};

const allDriverEmergency = () => {
  return httpClient.get("driver_emergency_all");
};

const postNewDriverEmergency = (data) => {
  return httpClient.post("post_new_driver_emergency/", data);
};

const getUpdateDriverEmergency = (id) => {
  return httpClient.get("get_update_driver_emergency/" + id);
};

const getDriverEmergencyThatIsNotFinish = () => {
  return httpClient.get("driver_emergency_that_is_not_finish/");
};

const getDriverEmergencyThatIsNotFinishByIdDriver = (id) => {
  return httpClient.get(
    "driver_emergency_that_is_not_finish_by_id_driver/" + id
  );
};

export default {
  getDriverEmergencyByIdUser,
  allDriverEmergency,
  postNewDriverEmergency,
  getUpdateDriverEmergency,
  getDriverEmergencyThatIsNotFinish,
  getDriverEmergencyThatIsNotFinishByIdDriver,
};
