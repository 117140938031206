import { httpClient } from "./httpClient";

const getAllBetweenSiteCars = () => {
  return httpClient.get("between_site_cars");
};

const getBetweenSiteCarById = (Id) => {
  return httpClient.get("between_site_car/" + Id);
};

const getBetweenSiteCarByIdDriver = (idDriver) => {
  return httpClient.get(`between_site_car_by_driver/${idDriver}`);
};
const getBetweenSiteCarByIdDriverIsNotFinish = (idDriver) => {
  return httpClient.get("between_site_car_by_driver_is_notFinish/" + idDriver);
};

const getBetweenSiteCarByFilterByIdDriver = (filter) => {
  return httpClient.post(`between_site_car_filter_by_id_driver`, filter);
};
const getBetweenSiteCarByIdUserIsNotFinish = (IdUser) => {
  return httpClient.get("between_site_car_is_notFinish/" + IdUser);
};

const getUpdateTargetStatusById = (id) => {
  return httpClient.get("update_target_status_call_car/" + id);
};

const callCar = (data) => {
  return httpClient.post("callCar", data);
};
const setFinishCallCar = (data) => {
  return httpClient.post("set_finish_call_car", data);
};
const CancelCallCar = (idUser) => {
  return httpClient.post("cancel_between_site_car/" + idUser);
};
const getSite = () => {
  return httpClient.get("getsite");
};
const postNewBetweenSiteCar = (newdata) => {
  return httpClient.post("post_new_between_site_car", newdata);
};
const updateDriverRoute = (idDriverRouteDay) => {
  return httpClient.post("update_driver_route", idDriverRouteDay);
};
const postOptimizedRouteBetweenSiteCar = () => {
  return httpClient.post("otimized_route_between_site_car");
};

const getBetweenSiteWithRouteDate = (data) => {
  return httpClient.post("get_between_site_with_route_date", data);
};

const getBetweenSiteWithRouteDateAndEndDate = (data) => {
  return httpClient.post("get_between_site_with_route_date_and_end_date", data);
};

export default {
  getAllBetweenSiteCars,
  getBetweenSiteCarByIdUserIsNotFinish,
  getBetweenSiteCarById,
  getBetweenSiteCarByIdDriver,
  getBetweenSiteCarByFilterByIdDriver,
  callCar,
  setFinishCallCar,
  getUpdateTargetStatusById,
  CancelCallCar,
  getSite,
  postNewBetweenSiteCar,
  updateDriverRoute,
  postOptimizedRouteBetweenSiteCar,
  getBetweenSiteWithRouteDate,
  getBetweenSiteWithRouteDateAndEndDate,
  getBetweenSiteCarByIdDriverIsNotFinish,
};
