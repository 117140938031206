import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector } from "react-redux";

import { styled } from '@mui/styles';
import {
    Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Avatar, Button,
} from '@mui/material';

import ChartOfReceipt from "./chartOfReceipt";
import LentCarServices from "../../../../../../services/lentCar.service";
import utils from "../../../../../../utils";


require('dayjs/locale/th');
dayjs.locale('th');

const DivCoverReturn = styled("div")(({theme})=>({
    width:"calc(100% - 40px)",
    padding:"70px 20px 20px",
    [theme.breakpoints.down('sm')]:{
        width:"100%",
        padding:"70px 0 20px"
    }
}));

const TableContainerStyled = styled(TableContainer)(({theme})=>({
    height:"calc(100% - 310px)",
}))

const Title = styled(Typography)(({theme})=>({
    fontSize:"36px",
    [theme.breakpoints.down('sm')]:{
        paddingLeft:"20px"
    }
}));

const ImageCar = styled(Avatar)(({theme})=>({
    width:"100px",
    heihgt:"50px",
    "& .MuiAvatar-img ":{
        objectFit:"contain",
    }
}));

const TextColumn = styled(Typography)(({theme})=>({
    fontSize:"18px",
    fontWeight:"bold",
}));

const Text = styled(Typography)(({theme})=>({
    fontSize:"18px",
}));

const TextPoint = styled(Typography)(({theme})=>({
    color:theme.palette.warning.main,
    fontWeight:"bold",
}));

const ViewButton = styled(Button)(({theme})=>({
    color:"black",
    border:"1px solid black",
}));

const ReceiptPage = props => {

    const [ListCar, setlistCar] = useState([]);
    const { user: AuthUser } = useSelector(state => state.auth);

    useEffect(() => {
        LentCarServices.getLentCarsByLenderId(AuthUser.id).then((res)=>{
            setlistCar(res.data);
        })
    }, [])

    const onClickViewButton = (idCar) => {
        props.history.push("/friend2friend_Car/lender/car",{ idCar:idCar, purpose: "view", edit: true });
    };

    return(
        <DivCoverReturn className="page">
            <Title>รายได้</Title>
            <ChartOfReceipt />
            <TableContainerStyled>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{minWidth:"20px"}}></TableCell>
                            <TableCell padding="none" style={{minWidth:"113px"}}></TableCell>
                            <TableCell style={{minWidth:"125px"}}></TableCell>
                            <TableCell align="center" style={{minWidth:"110px"}}><TextColumn>วันที่</TextColumn></TableCell>
                            <TableCell align="center" style={{minWidth:"50px"}}><TextColumn>รวม</TextColumn></TableCell>
                            <TableCell align="center" style={{minWidth:"60px"}}><TextColumn>เดือนนี้</TextColumn></TableCell>
                            <TableCell align="center" style={{minWidth:"100px"}}><TextColumn>รายได้เดือนนี้</TextColumn></TableCell>
                            <TableCell align="center" style={{minWidth:"120px"}}><TextColumn>รายได้รวม</TextColumn></TableCell>
                            <TableCell align="center" style={{minWidth:"50px"}}><TextColumn>Rating</TextColumn></TableCell>
                            <TableCell style={{minWidth:"50px"}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ListCar.length > 0 && ListCar.map((car,index)=>(
                            <TableRow key={car.id}>
                                <TableCell><Text>{index+1}</Text></TableCell>
                                <TableCell padding="none"><ImageCar variant="square" src="../../assets/Image/example/example2.jpeg" /></TableCell>
                                <TableCell><Text>{car.model}</Text></TableCell>
                                <TableCell align="center"><Text>{dayjs(car.registerDate).format('D MMM YYYY')}</Text></TableCell>
                                <TableCell align="center"><Text>{car.numberBorrowed}</Text></TableCell>
                                <TableCell align="center"><Text>{car.numberBorrowedOfCurrentMonth}</Text></TableCell>
                                <TableCell align="center"><Text>{utils.numberWithCommas(car.receiptsOfCurrentMonth)}</Text></TableCell>
                                <TableCell align="center"><Text>{utils.numberWithCommas(car.receipts)}</Text></TableCell>
                                <TableCell align="center"><TextPoint>{(car.point*10)%1 === 0?car.point.toFixed(1):car.point.toFixed(2)}</TextPoint></TableCell>
                                <TableCell align="center" onClick={()=>onClickViewButton(car.id)}><ViewButton>View</ViewButton></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainerStyled>
        </DivCoverReturn>
    );
};

export default ReceiptPage;