import {
    ROUTES_FETCHING,
    ROUTES_FAILED,
    ROUTES_SUCCESS,
    ROUTELINE_FETCHING,
    ROUTELINE_FAILED,
    ROUTELINE_SUCCESS,
    ROUTEUSER_FETCHING,
    ROUTEUSER_FAILED,
    ROUTEUSER_SUCCESS
} from "./types";

import RouteTodayService from "../services/routeToday.service";

export const getRouteDayByIdDriver = (idDriver) => async (dispatch) => {
  try {
    dispatch({
      type: ROUTES_FETCHING,
    });
    const res = await RouteTodayService.getRouteByIdDriver(idDriver);
    if (res) {
      dispatch({
        type: ROUTES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ROUTES_FAILED,
    });
  }
};

export const getRouteLine = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ROUTELINE_FETCHING,
    });
    const res = await RouteTodayService.getRouteLine(data);
    if (res) {
      dispatch({
        type: ROUTELINE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ROUTELINE_FAILED,
    });
  }
};

export const getUserRoute = (idUser) => async (dispatch) => {
  try {
    dispatch({
      type: ROUTEUSER_FETCHING,
    });
    const res = await RouteTodayService.getUserRoute(idUser);
    console.log("res : ",res)
    if (res) {
      dispatch({
        type: ROUTEUSER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ROUTEUSER_FAILED,
    });
  }
};

export const removeUserRoutesDay = (idUser,idDriver) => async (dispatch) => {
  try {
    dispatch({
      type: ROUTES_FETCHING,
    });
    const res = await RouteTodayService.removeUserRoutesDay(idUser,idDriver);
    console.log("res: ",res);
    if (res) {
      dispatch({
        type: ROUTES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ROUTES_FAILED,
    });
  }
};