import { httpClient } from "./httpClient";

const getAllReviews = () => {
    return httpClient.get("reviews");
};

const getReviewsByCarId = (carId) => {
    return httpClient.get("review/"+carId);
};
const postNewReview = (newreview) => {
    return httpClient.post("add_new_review",newreview);
};
const getAllReviewsBySearch = (query) => {
    return httpClient.get("all_reviews_by_search", { params: query });
};

export default {
    getAllReviews,
    getReviewsByCarId,
    postNewReview,
    getAllReviewsBySearch
};