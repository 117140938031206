import React from 'react';

import { styled } from '@mui/styles';
import { 
    Modal, Paper, IconButton, Button, TextField, FormControlLabel, Checkbox, Grid,
} from '@mui/material';

import { listOptions } from "../../../../choise";

const ModalStyled = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
});

const PaperStyled = styled(Paper)(({theme})=>({
    width:"600px",
    height:"fit-content",
    padding:"30px 30px 20px",
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-end",
    [theme.breakpoints.down("sm")]:{
        width:"calc(95% - 60px)",
    },
}));

const GridContainer = styled(Grid)({
    height:"300px",
    overflowY:"auto",
});

const ConfirmButton = styled(Button)(({theme})=>({
    marginTop:"20px",
    padding:"7px 10px",
    backgroundColor:theme.palette.success.main,
    color:theme.palette.success.contrastText,
    fontSize:"16px",
    "&:hover":{
        backgroundColor:theme.palette.success.dark,
    },
}));

const ModalFeature = ({ openModal, listExOptions, OnClickCloseModal, onChangeOptionsCheckBox }) => {

    const OnCloseFunction = () => {
        OnClickCloseModal()
    };

    const OnClickConfirmButton = () => {
        OnClickCloseModal()
    };

    return(
        <>
            <ModalStyled
                open={openModal}
                onClose={OnCloseFunction}
            >
                <PaperStyled>
                    <GridContainer container spacing={1}>
                        {listOptions.map( option => (
                            <Grid item xs={12} sm={6} key={option.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={listExOptions.includes(option.id)}
                                            onClick={()=>onChangeOptionsCheckBox(option.id)}
                                            name={option.name}
                                        />
                                    }
                                    label={option.name}
                                />
                            </Grid>
                        ))}
                    </GridContainer>
                    <ConfirmButton onClick={OnClickConfirmButton}>Confirm</ConfirmButton>
                </PaperStyled>
            </ModalStyled>
        </>
    );
};

export default ModalFeature;