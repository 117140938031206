import {
    PASSENGERS_FETCHING,
    PASSENGERS_FAILED,
    PASSENGERS_SUCCESS
} from "./types";

import CrossAreaCarPoolPassengerService from "../services/crossAreaCarPoolBookingPassenger.service";

export const getCrossAreaCarPoolBookingPassengerByIdBooking = (idBooking) => async (dispatch) => {
    try {
        dispatch({
            type: PASSENGERS_FETCHING,
        });
        const res = await CrossAreaCarPoolPassengerService.getCrossAreaCarPoolBookingPassengerByIdBooking(idBooking);
        if(res){
            dispatch({
                type: PASSENGERS_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: PASSENGERS_FAILED,
        });
    }
}