import {
    HISTORYLENTCARS_FETCHING,
    HISTORYLENTCARS_FAILED,
    HISTORYLENTCARS_SUCCESS,
} from "./types";

import HistoryLentCarService from "../services/historyLentCar.service";

export const getHistoryLentCarsByIdLender = (idLender) => async (dispatch) => {
    try {
        dispatch({
            type: HISTORYLENTCARS_FETCHING,
        });
        const res = await HistoryLentCarService.getHistoryLentCarByCarIdLender(idLender);
        if(res){
            dispatch({
                type: HISTORYLENTCARS_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: HISTORYLENTCARS_FAILED,
        });
    }
};

export const getHistoryLentCarWaitingByLenderId = (idLender) => async (dispatch) => {
    try {
        dispatch({
            type: HISTORYLENTCARS_FETCHING,
        });
        const res = await HistoryLentCarService.getHistoryLentCarWaitingByLenderId(idLender);
        if(res){
            dispatch({
                type: HISTORYLENTCARS_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: HISTORYLENTCARS_FAILED,
        });
    }
};

