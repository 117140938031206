import React, { useState } from "react";
import { styled, Typography, Stack, Button, IconButton } from "@mui/material";
import Card from "./Card";
import FoodMenuModal from "./FoodMenuModal";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteModal from "../../../shared/deleteModal";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import cateringService from "../../../../../services/catering.service";

const Root = styled("div")(({ theme }) => ({
  "& .icon": {
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
  },
  "& .location": {
    fontSize: 14,
    color: "#d32f2f",
    "& .MuiTypography-root": {
      color: "#d32f2f",
    },
  },
  "& .foodMenuBtn": {
    backgroundColor: "#e0e0e0",
    "&:hover": {
      backgroundColor: "#bdbdbd",
    },
    color: "#212b36",
    marginRight: 10,
    zIndex: 1,
  },
  "& .deleteBtn": {
    backgroundColor: "#d32f2f",
    "&:hover": {
      backgroundColor: "#b71c1c",
    },
    color: "#ffffff",
    zIndex: 1,
  },
}));

const CateringRestaurantCard = ({
  data,
  onClickEditRestaurant,
  onClickDelete,
}) => {
  const [isOpenFoodMenu, setIsOpenFoodMenu] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const onClickOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const onCloseDeleteModalHandle = () => {
    setOpenDeleteModal(false);
  };

  return (
    <Card
      backgroundImage={data.fileUrl}
      style={{
        cursor: "pointer",
        opacity: 0.8,
        "&:hover": {
          opacity: 1,
        },
      }}
    >
      <Root>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            height: "120px",
          }}
          onClick={() => {
            setIsOpenFoodMenu(true);
          }}
        >
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <div>
                <Typography fontSize={24}>{data.nameRestaurant}</Typography>
                <Stack className="location" direction="row">
                  <i className="fas fa-map-marker-alt icon"></i>
                  <Typography fontSize={18}>{data.location}</Typography>
                </Stack>
              </div>
            </Stack>

            {/* <Stack direction="row" justifyContent="flex-end">
            <Button
              className="foodMenuBtn"
              variant="contained"
              size="small"
              startIcon={<EditRoundedIcon />}
              onClick={() => {
                onClickEditRestaurant(data.idCateringRestaurant);
              }}
            >
              แก้ไข
            </Button>
            <Button
              className="deleteBtn"
              variant="contained"
              size="small"
              startIcon={<DeleteRoundedIcon />}
              onClick={onClickOpenDeleteModal}
            >
              ลบ
            </Button>
          </Stack> */}
          </Stack>
        </div>
        <div style={{ position: "absolute", bottom: 0, right: 0, zIndex: 1 }}>
          <Button
            className="foodMenuBtn"
            variant="contained"
            size="small"
            startIcon={<EditRoundedIcon />}
            onClick={() => {
              onClickEditRestaurant(data.idCateringRestaurant);
            }}
          >
            แก้ไข
          </Button>
          <Button
            className="deleteBtn"
            variant="contained"
            size="small"
            startIcon={<DeleteRoundedIcon />}
            onClick={onClickOpenDeleteModal}
          >
            ลบ
          </Button>
        </div>

        {isOpenFoodMenu && (
          <FoodMenuModal
            open={isOpenFoodMenu}
            onClose={() => {
              setIsOpenFoodMenu(false);
            }}
            data={data}
          />
        )}
        <DeleteModal
          open={openDeleteModal}
          onCloseDeleteModalHandle={onCloseDeleteModalHandle}
          onClickDelete={() => onClickDelete(data.idCateringRestaurant)}
          label={"ลบร้านอาหาร"}
        />
      </Root>
    </Card>
  );
};

export default CateringRestaurantCard;
