import React, { useEffect, Fragment } from "react";

import { useSelector, useDispatch } from "react-redux";
// import makeStyles from '@mui/styles/makeStyles';
import { styled } from "@mui/styles";

import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";

import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import EditIcon from "@mui/icons-material/Edit";

import { getUserProfile } from "../../../../actions/user";
import Utils from "../../../../utils";

// const useStyles = makeStyles((theme) => ({
//   divider: {
//     margin: "10px 0",
//     backgroundColor: "#00000021",
//   },
//   wrapFollow: {
//     padding: 8,
//     width: 350,
//     borderRadius: 16,
//     backgroundImage: "linear-gradient(87deg,#172b4d,#1a174d)",
//   },
//   innerFollow: {
//     display: "flex",
//     justifyContent: "center",
//   },
//   follow: {
//     padding: 16,
//   },
//   followCount: {
//     fontSize: 24,
//     fontWeight: 600,
//     color: "#FFFFFF",
//   },
//   followLabel: {
//     fontSize: 14,
//     color: "#FFFFFF",
//   },
//   dividerVertical: {
//     margin: "16px 32px",
//     backgroundColor: "#FFFFFF",
//   },
//   sectionFour: {
//     marginTop: 16,
//     marginBottom: 16,
//     width: "100%",
//     display: "inline-flex",
//     justifyContent: "center",
//   },
// }));

const Root = styled("div")({
  marginTop: 8,
});

const SectionOne = styled("div")({
  display: "inline-flex",
  width: "100%",
  justifyContent: "center",
});

const WrapAvatar = styled(Paper)({
  padding: 16,
  width: "fit-content",
  borderRadius: "50%",
});

const AvatarStyled = styled(Avatar)({
  width: 200,
  height: 200,
});

const SectionTwo = styled("div")({
  marginTop: 16,
});

const TextProfile = styled(Typography)({
  fontWeight: 300,
});

const SectionThree = styled("div")({
  maxWidth: 650,
  margin: "auto",
  marginTop: 24,
  display: "flex",
  justifyContent: "space-around",
});

const AboutTop = styled("div")({
  marginBottom: 32,
  display: "flex",
  alignItems: "center",
});

const InnerAbout = styled("div")({
  marginLeft: 8,
});

const IconAbout = styled(Typography)({
  opacity: 0.8,
});

const AboutBottom = styled("div")({
  display: "flex",
  alignItems: "center",
});

const PartOfButtonEdit = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: "30px",
  marginBottom: "30px",
});

const EditButton = styled(Button)(({ theme }) => ({
  fontSize: "20px",
  padding: "10px 20px",
  backgroundColor: theme.palette.primary.dark,
  color: "white",
  ["&:hover"]: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const IconEditButton = styled(EditIcon)({
  fontSize: "30px",
  marginRight: "10px",
});

const SectionTwoSkeleton = styled("div")({
  maxWidth: 500,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const ProfilePage = (props) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const all = useSelector((state) => state);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.idUser));
    }
  }, [currentUser]);

  const onClickEditButton = () => {
    props.history.push("/identifyprofile");
  };

  return (
    <Root className="page">
      {userProfile ? (
        <div>
          <SectionOne>
            <WrapAvatar>
              <AvatarStyled
                alt="Remy Sharp"
                src={`${process.env.REACT_APP_URL}image/profile/${userProfile.imageName}`}
              />
            </WrapAvatar>
          </SectionOne>
          <SectionTwo>
            <Typography align="center" variant="h2">
              {`${userProfile.firstname_TH} ${userProfile.lastname_TH}`}
            </Typography>
            <TextProfile align="center" variant="h5" color="textSecondary">
              {userProfile.positionName}
            </TextProfile>
            <TextProfile align="center" variant="h6" color="textSecondary">
              {userProfile.departmentName}
            </TextProfile>
          </SectionTwo>
          <SectionThree>
            <div>
              <AboutTop>
                <PhoneIphoneOutlinedIcon fontSize="large" color="primary" />
                <InnerAbout>
                  <IconAbout variant="body2" color="textSecondary">
                    Mobile Number:{" "}
                  </IconAbout>
                  <Typography>{userProfile.telephoneMobile}</Typography>
                </InnerAbout>
              </AboutTop>
              <AboutBottom>
                <PersonPinCircleOutlinedIcon fontSize="large" color="primary" />
                <InnerAbout>
                  <IconAbout variant="body2" color="textSecondary">
                    Work Location:{" "}
                  </IconAbout>
                  <Typography>{userProfile.workingLocation}</Typography>
                </InnerAbout>
              </AboutBottom>
            </div>
            <div>
              <AboutTop>
                <AlternateEmailOutlinedIcon fontSize="large" color="primary" />
                <InnerAbout>
                  <IconAbout variant="body2" color="textSecondary">
                    Email:{" "}
                  </IconAbout>
                  <Typography>{userProfile.email}</Typography>
                </InnerAbout>
              </AboutTop>
              <AboutBottom>
                <BusinessCenterOutlinedIcon fontSize="large" color="primary" />
                <InnerAbout>
                  <IconAbout variant="body2" color="textSecondary">
                    Company:{" "}
                  </IconAbout>
                  <Typography>{userProfile.companyName}</Typography>
                </InnerAbout>
              </AboutBottom>
            </div>
          </SectionThree>
          <PartOfButtonEdit>
            <EditButton onClick={onClickEditButton}>
              <IconEditButton />
              Edit
            </EditButton>
          </PartOfButtonEdit>
          {/* <Divider className={classes.divider} /> */}
          {/* <div className={classes.sectionFour}>
            <Paper className={classes.wrapFollow}>
              <div className={classes.innerFollow}>
                <div className={classes.follow}>
                  <Typography align="center" className={classes.followCount}>
                    {Utils.numberWithCommas(userProfile.follower)}
                  </Typography>
                  <Typography
                    align="center"
                    color="textSecondary"
                    className={classes.followLabel}
                  >
                    Follower
                  </Typography>
                </div>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.dividerVertical}
                />
                <div className={classes.follow}>
                  <Typography align="center" className={classes.followCount}>
                    {Utils.numberWithCommas(userProfile.following)}
                  </Typography>
                  <Typography
                    align="center"
                    color="textSecondary"
                    className={classes.followLabel}
                  >
                    Following
                  </Typography>
                </div>
              </div>
            </Paper>
          </div> */}
        </div>
      ) : (
        <Fragment>
          <SectionOne>
            <Skeleton variant="circular" width={200} height={200} />
          </SectionOne>
          <SectionTwoSkeleton>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <Skeleton width="60%" />
          </SectionTwoSkeleton>
        </Fragment>
      )}
    </Root>
  );
};

export default ProfilePage;
