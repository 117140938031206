import React from "react";
import Typography from "@mui/material/Typography";

const SettingPage = () => {
  return (
    <div className="page">
      <Typography>Setting</Typography>
    </div>
  );
};

export default SettingPage;
