import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/styles";
// import { GoogleApiWrapper, Map, InfoWindow, Marker } from "google-maps-react";
import {
  GoogleAPIkey,
  locationSite1,
} from "../../../../../../../utils/googleMapAssets";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";
import { Modal, Typography, Paper } from "@mui/material";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import SiteService from "../../../../../../../services/site.service";
import MarkerDriver from "./MarkerDriver";
import GPSLocation from "./Icon/GPSLocation.png";
import TruckLocation from "./Icon/truck.png";

// import { GoogleMap, Marker } from "react-google-maps";
const placesLibrary = ["places"];

const useStyles = makeStyles((theme) => ({
  Modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Paper: {
    width: "90%",
    height: "80%",
    padding: "20px",
  },
  partOfTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  IconCar: {
    fontSize: "40px",
    marginRight: "10px",
    color: theme.palette.primary.main,
  },
  Title: {
    fontSize: "30px",
    color: theme.palette.primary.main,
  },
}));
const AlertMessage = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  fontSize: "16px",
  color: "red",
});

const GoogleApi = (props) => {
  const classes = useStyles();
  const [searchResult, setSearchResult] = useState("Result: none");
  const [isLocation, setLocation] = useState([{ Lat: 0, Lng: 0 }]);
  const [map, setMap] = React.useState(null);
  const [isLocationDriver, setLocationDriver] = useState([{ Lat: 0, Long: 0 }]);
  const [isLocationFrom, setLocationFrom] = useState([
    { LatFrom: 0, LongFrom: 0 },
  ]);
  const [isLocationTo, setLocationTo] = useState([{ LatTo: 0, LongTo: 0 }]);
  const [refresh, setRefresh] = useState(0);

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GoogleAPIkey,
    libraries: placesLibrary,
    language: "th",
    region: "TH",
  });

  const customCenter = {
    url: GPSLocation,
    scaledSize: new window.google.maps.Size(25, 50), // Size of the icon
    origin: new window.google.maps.Point(0, 0), // Origin point
    anchor: new window.google.maps.Point(25, 50), // Anchor point
  };

  useEffect(() => {
    if (props.inforInCar != null) {
      SiteService.getLocationDriverById(props.inforInCar.idDriver)
        .then((res) => {
          if (res.data) {
            setLocationDriver([
              { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
            ]);
          }
        })
        .catch((error) => {
          setLocationDriver([{ Lat: 0, Long: 0 }]);
        });
      SiteService.getSitebyName(props.inforInCar.fromPlace)
        .then((res) => {
          if (res.data) {
            setLocationFrom([
              { LatFrom: res.data[0].Lat, LongFrom: res.data[0].Long },
            ]);
          }
        })
        .catch((error) => {
          setLocationFrom([{ LatFrom: 0, LongFrom: 0 }]);
        });
      SiteService.getSitebyName(props.inforInCar.toPlace)
        .then((res) => {
          if (res.data) {
            setLocationTo([
              { LatTo: res.data[0].Lat, LongTo: res.data[0].Long },
            ]);
          }
        })
        .catch((error) => {
          setLocationTo([{ LatTo: 0, LongTo: 0 }]);
        });
    }
  }, [props.inforInCar]);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: parseFloat(
      isLocationDriver[0].Lat === 0
        ? isLocationFrom[0].LatFrom
        : isLocationDriver[0].Lat
    ),
    lng: parseFloat(
      isLocationDriver[0].Long === 0
        ? isLocationFrom[0].LongFrom
        : isLocationDriver[0].Long
    ),
  };

  const svgIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#90ee90" viewBox="0 0 24 24">
    <path fill-rule="evenodd" d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z" clip-rule="evenodd"/>
  </svg>`;
  const svgIconUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
    svgIcon
  )}`;

  return (
    <>
      {isLoaded && (
        <Modal
          className={`${classes.Modal}`}
          open={props.open}
          onClose={props.onCloseHandle}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Paper className={`${classes.Paper}`}>
            <div className={`${classes.partOfTitle}`}>
              <DriveEtaIcon className={`${classes.IconCar}`} />
              <Typography className={`${classes.Title}`}>แสดงแผนที่</Typography>
            </div>
            <div style={{ height: "90%", width: "100%" }}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={13}
              >
                <Marker
                  position={{
                    lat: parseFloat(isLocationFrom[0].LatFrom),
                    lng: parseFloat(isLocationFrom[0].LongFrom),
                  }}
                  icon={{
                    url:
                      "data:image/svg+xml;charset=UTF-8," +
                      encodeURIComponent(`
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" width="56px" height="56px">
                <path d="M12 2C8.69 2 6 4.69 6 8c0 3.53 3.87 7.61 5.47 9.19.3.3.77.3 1.06 0C14.13 15.61 18 11.53 18 8c0-3.31-2.69-6-6-6zm0 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
              </svg>
            `),
                  }}
                />
                <Marker
                  position={{
                    lat: parseFloat(isLocationTo[0].LatTo),
                    lng: parseFloat(isLocationTo[0].LongTo),
                  }}
                  icon={{
                    url:
                      "data:image/svg+xml;charset=UTF-8," +
                      encodeURIComponent(`
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="56px" height="56px">
                <path d="M12 2C8.69 2 6 4.69 6 8c0 3.53 3.87 7.61 5.47 9.19.3.3.77.3 1.06 0C14.13 15.61 18 11.53 18 8c0-3.31-2.69-6-6-6zm0 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
              </svg>
            `),
                  }}
                />
                {isLocationDriver[0].Lat !== 0 &&
                isLocationDriver[0].Long !== 0 ? (
                  <MarkerDriver inforInCar={props.inforInCar} />
                ) : (
                  <AlertMessage>กำลังหาคนขับรถ</AlertMessage>
                )}
              </GoogleMap>
            </div>
          </Paper>
        </Modal>
      )}
    </>
  );
};

export default GoogleApi;
