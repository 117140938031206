import React, { useState, useEffect, Fragment } from 'react';
import dayjs from 'dayjs';

import { styled } from '@mui/styles';

import {
    Typography, Button, Grid
} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// import VehicleTypeService from "../../../../../services/vehicleType.service";

require('dayjs/locale/th');
dayjs.locale('th');

const DivCoverReturn = styled('div')(({theme})=>({
    width:"100%",
    marginTop:"20px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    paddingTop:"64px",
}));

const DivTitle = styled("div")(({theme})=>({
    width:"90%", 
    maxWidth:"1100px", 
    display:"flex", 
    justifyContent:"center", 
    position:"relative",
    [theme.breakpoints.down('sm')]:{
        marginTop:"50px"
    }
}));

const BackButton = styled(Button)(({theme})=>({
    position:"absolute",
    color:"black",
    fontSize:"20px",
    left:"-8px",
    [theme.breakpoints.down('sm')]:{
        top:"-50px",
    }
}));

const Title = styled(Typography)(({theme})=>({
    textAlign:"center",
    fontSize:"30px"
}));

const DivContent = styled("div")(({theme})=>({
    width:"90%",
    maxWidth:"1100px",
    marginBottom:"50px"
}));

const DivTopic = styled("div")(({theme})=>({
    display:"flex",
    borderBottom:"1px solid #1565c0",
    marginTop:"24px"
}));

const TextMainTopic = styled(Typography)(({theme})=>({
    fontSize:"20px",
    fontWeight:"bold",
    color:theme.palette.primary.dark
}));

const TextTopic = styled(Typography)(({theme})=>({
    fontSize:"18px",
    fontWeight:"bold",
}));

const TextData = styled(Typography)(({theme})=>({
    fontSize:"18px",
    marginLeft:"10px",
    color:theme.palette.primary.main
}));

const DivTopicAndData = styled("div")(({theme})=>({
    display:"flex"
}));

const GridContainer = styled(Grid)(({theme})=>({
    width:"100%",
    marginTop:"0px"
}));

// const DivTypeOfFlight = styled("div")(({theme})=>({
//     width:"calc(100% - 68px)",
//     marginTop:"24px"
// }));

// const DivTextFlight = styled("div")(({theme})=>({
//     width:"100%",
//     borderBottom:"1px solid #DCDCDC",
//     marginTop:"24px"
// }));

// const DivPurpose = styled("div")(({theme})=>({
//     width:"calc(100% - 68px)",
//     marginTop:"24px"
// }));

// const DivGettingPlace = styled("div")(({theme})=>({
//     width:"calc(100% - 68px)",
//     marginTop:"24px"
// }));

const DetailDeliveryCar = props => {
    const [booking, setBooking] = useState(null);

    useEffect(() => {
        if(props.location.state === undefined){
            props.history.push("/myListOfRequest");
        }else{
            setBooking(props.location.state.booking);
        }
    }, [props.location.state])

    return(
        <DivCoverReturn>
            <DivTitle>
                <BackButton onClick={()=>props.history.goBack()}>
                    <ArrowBackIosNewIcon /> Back
                </BackButton>
                <Title>ขอรถรับส่งของ</Title>
            </DivTitle>
            {booking &&
                <DivContent>
                    <DivTopic>
                        <TextMainTopic>ผู้ขอรถ</TextMainTopic>
                    </DivTopic>
                    <GridContainer container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <DivTopicAndData>
                                <TextTopic>ชื่อ :</TextTopic>
                                <TextData>{booking.name}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DivTopicAndData>
                                <TextTopic>เบอร์โทรศัพท์ :</TextTopic>
                                <TextData>{booking.telephoneMobile}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DivTopicAndData>
                                <TextTopic>Email :</TextTopic>
                                <TextData>{booking.email}</TextData>
                            </DivTopicAndData>
                        </Grid>
                    </GridContainer>
                    <DivTopic>
                        <TextMainTopic>รายละเอียดสิ่งของ</TextMainTopic>
                    </DivTopic>
                    <GridContainer container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <DivTopicAndData>
                                <TextTopic>ประเภทของที่ส่ง :</TextTopic>
                                <TextData>{booking.typeProduct}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DivTopicAndData>
                                <TextTopic>น้ำหนักโดยประมาณ :</TextTopic>
                                <TextData>{`${booking.weightProduct} kg`}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DivTopicAndData>
                                <TextTopic>วัตถุประสงค์ :</TextTopic>
                                <TextData>{booking.purpose}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12}>
                            <DivTopicAndData>
                                <TextTopic>รายละเอียด :</TextTopic>
                                <TextData>{booking.detail? booking.detail : " - "}</TextData>
                            </DivTopicAndData>
                        </Grid>
                    </GridContainer>
                    <DivTopic>
                        <TextMainTopic>รายละเอียดการขอรถ</TextMainTopic>
                    </DivTopic>
                    <GridContainer container spacing={3}>
                        <Grid item xs={12} sm={6} >
                            <DivTopicAndData>
                                <TextTopic>จุดรับ :</TextTopic>
                                <TextData>{booking.fromPlace}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <DivTopicAndData>
                                <TextTopic>จุดส่ง :</TextTopic>
                                <TextData>{booking.toPlace}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DivTopicAndData>
                                <TextTopic>วันที่เดินทาง :</TextTopic>
                                <TextData>{dayjs(booking.departureDate).format('DD MMMM YYYY')}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DivTopicAndData>
                                <TextTopic>เวลาเริ่มออกรถ :</TextTopic>
                                <TextData>{dayjs(booking.startTime).format('HH.mm น.')}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DivTopicAndData>
                                <TextTopic>เวลาคืนรถ :</TextTopic>
                                <TextData>{dayjs(booking.endTime).format('HH.mm น.')}</TextData>
                            </DivTopicAndData>
                        </Grid>
                    </GridContainer>
                    <DivTopic>
                        <TextMainTopic>รายละเอียดผู้รับ</TextMainTopic>
                    </DivTopic>
                    <GridContainer container spacing={3} style={{marginBottom:"30px"}}>
                        <Grid item xs={12} sm={6}>
                            <DivTopicAndData>
                                <TextTopic>ชื่อผู้รับ :</TextTopic>
                                <TextData>{booking.nameRecipient}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DivTopicAndData>
                                <TextTopic>เบอร์โทรศัพท์ผู้รับ :</TextTopic>
                                <TextData>{booking.telephoneMobileRecipient}</TextData>
                            </DivTopicAndData>
                        </Grid>
                    </GridContainer>
                </DivContent>
            }  
        </DivCoverReturn>
    );
};

export default DetailDeliveryCar;