import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import PersonIcon from "@mui/icons-material/Person";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";

const UserMenu = ({ onClickList }) => {
  const dataListMenu = [
    {
      listItemIcon: <DriveEtaIcon />,
      listItemText: "จองรถทั้งหมด",
      listLink: "/carbooking",
      listKey: "carbooking",
    },
    {
      listItemIcon: (
        <i
          className="fas fa-tools"
          style={{ width: 24, textAlign: "center", fontSize: "1.25rem" }}
        ></i>
      ),
      listItemText: "จองคนขับรถ",
      // listLink: "//meetingroom",
      // listKey:"maintenanceGroup",
      collapse: [
        {
          listItemIcon: <DescriptionRoundedIcon />,
          listItemText: "ฟอร์มจองคนขับรถ",
          listLink: "/driverbooking",
          listKey: "driverbooking",
        },
      ],
    },

    {
      listItemIcon: (
        <i className="fas fa-hat-chef" style={{ fontSize: "20px" }}></i>
      ),
      listItemText: "จัดเลี้ยง",
      listKey: "catering",
      collapse: [
        {
          listItemIcon: <FormatListBulletedIcon />,
          listItemText: "ค้นหาร้านอาหาร",
          listLink: "/catering/restaurant",
          listKey: "restaurant",
        },
        {
          listItemIcon: <FormatListBulletedIcon />,
          listItemText: "ร้องขอการจัดเลี้ยง",
          listLink: "/catering/request_catering",
          listKey: "restaurantRequest",
        },
      ],
    },
    {
      listItemIcon: (
        <i
          className="fas fa-tools"
          style={{ width: 24, textAlign: "center", fontSize: "1.25rem" }}
        ></i>
      ),
      listItemText: "ซ่อมแซม",
      listLink: "/maintenances",
      listKey: "maintenanceGroup",
      collapse: [
        {
          listItemIcon: <DescriptionRoundedIcon />,
          listItemText: "ฟอร์มแจ้งซ่อม",
          listLink: "/maintenances/request",
          listKey: "request",
        },
        {
          listItemIcon: <DescriptionRoundedIcon />,
          listItemText: "ประวัติการแจ้งซ่อม",
          listLink: "/maintenances/history",
          listKey: "history",
        },
      ],
    },
    {
      listItemIcon: (
        <i
          className="fas fa-tools"
          style={{ width: 24, textAlign: "center", fontSize: "1.25rem" }}
        ></i>
      ),
      listItemText: "จองสถานที่",
      // listLink: "//meetingroom",
      // listKey:"maintenanceGroup",
      collapse: [
        {
          listItemIcon: <DescriptionRoundedIcon />,
          listItemText: "ฟอร์มจองสถานที่",
          listLink: "/meetingroom",
          listKey: "meetingroom",
        },
      ],
    },
    {
      listItemIcon: <FormatListBulletedIcon />,
      listItemText: "รายการของฉัน",
      listLink: "/myListOfRequest",
      listKey: "myListOfRequest",
    },
    {
      listItemIcon: <FormatListBulletedIcon />,
      listItemText: "ประเมินความพึงพอใจ",
      listLink: "/evaluate_satisfaction",
      listKey: "evaluateSatisfaction",
    },
    // {
    //   listItemIcon: <PersonIcon />,
    //   listItemText: "โปรไฟล์ของฉัน",
    //   listLink: "/profile",
    //   listKey:"profile",
    // },
  ];

  return (
    <ListMenu
      key="user"
      dataListMenu={dataListMenu}
      onClickList={onClickList}
    />
  );
};

export default UserMenu;
