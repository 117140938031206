import React, { useState } from "react";
import { styled, Grid, Typography, Button, IconButton } from "@mui/material";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "dayjs/locale/th";
import { Link } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const Root = styled("div")(({ theme }) => ({
  marginTop: 32,
  ["& .header"]: {
    fontSize: 14,
    color: "#9e9e9e",
  },
  ["& .desc"]: {
    fontSize: 20,
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));
const maintenanceType = ["", "งานซ่อมแซมทั่วไป", "งานไฟฟ้า", "งานประปา"];

const Result = ({ data, onClickGoBack, edit }) => {
  console.log(data, "Result");
  return (
    <Root>
      <div style={{ display: "flex", alignItems: "center" }}>
        <BackButton disableRipple onClick={onClickGoBack}>
          <ArrowBackIcon className="Icon" style={{ color: "#99A8B2" }} />
        </BackButton>
        <Typography
          fontSize={32}
          marginBottom={2}
          marginTop={1}
          textAlign="center"
        >
          {edit ? "แก้ไขร้านอาหารเรียบร้อย" : "เพิ่มร้านอาหารเรียบร้อย"}
        </Typography>
        <CheckCircleRoundedIcon
          style={{ color: "#4BB543", fontSize: "40px", marginLeft: "5px" }}
        />
      </div>
      {/* <Typography textAlign="center" marginBottom={2}>งานของคุณอยู่ระหว่างรอการยืนยัน</Typography> */}
      {data && (
        <Grid
          container
          spacing={3}
          sx={(theme) => ({
            display: "flex",
            boxShadow:
              "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
            border: "1px solid #EEEEEE",
            backgroundColor: "#FBFCFC",
            marginTop: "10px",
            marginBottom: "20px",
            marginLeft: "0px",
            padding: "0 25px 25px 0",

            borderRadius: "15px",
            [theme.breakpoints.down("sm")]: {},
            [theme.breakpoints.down("md")]: {},
          })}
        >
          {/* <Grid item xs={12} container justifyContent="flex-end">
					<Button variant="contained" size="large" component={Link} to={`/maintenances/detail/${data?.idMaintenances}`}>รายละเอียด</Button>
				</Grid> */}

          <Grid item xs={12} container justifyContent="center">
            <Grid container maxWidth={960} spacing={3}>
              {/* <Grid item xs={12} sm={6}>
							<Typography className="header">ประเภทงานซ่อมแซม</Typography>
							<Typography className="desc">{maintenanceType[data?.maintenanceType||0]}</Typography>
						</Grid> */}
              <Grid item xs={12} sm={12}>
                <Typography className="header">ชื่อร้าน</Typography>
                <Typography className="desc">
                  {data?.Restaurant.data.nameRestaurant}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="header">ชื่อผู้ติดต่อ</Typography>
                <Typography className="desc">
                  {data?.Restaurant.data.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="header">เบอร์ติดต่อ</Typography>
                <Typography className="desc">
                  {data?.Restaurant.data.phone !== null &&
                  data?.Restaurant.data.phone !== ""
                    ? data?.Restaurant.data.phone
                    : " - "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="header">Email</Typography>
                <Typography className="desc">
                  {data?.Restaurant.data.email !== null &&
                  data?.Restaurant.data.email !== ""
                    ? data?.Restaurant.data.email
                    : " - "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="header">สถานที่</Typography>
                <Typography className="desc">
                  {data?.Restaurant.data.location}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="header">รายละเอียดสถานที่</Typography>
                <Typography className="desc">
                  {data?.Restaurant.dataMap[0].locationfrommap}
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
							<Typography className="header">วันที่และเวลา</Typography>
							<Typography className="desc">{dayjs(data?.startDate).locale('th').format("D MMMM YYYY (hh.mmน.)")}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography className="header">รายละเอียด</Typography>
							<Typography className="desc">{data?.description}</Typography>
						</Grid> */}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Root>
  );
};

export default Result;
