import React, { useEffect, useState } from "react";
import { styled, Typography, Stack } from "@mui/material";
import FoodCard from "./foodCard";
import { Grid, IconButton } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import cateringService from "../../../../../services/catering.service";

const Root = styled("div")(({ theme }) => ({
  "& .restaurant-banner": {
    width: "100%",
    height: 250,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  "& .restaurant-name": {
    fontSize: 24,
    marginBottom: 8,
  },
  "& .restaurant-location": {
    color: "#9e9e9e",
    marginBottom: 8,
  },
  "& .container": {
    // display:"flex",
    // justifyContent: "space-beetween",
    maxWidth: 1640,
  },
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const RootContent = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  // maxWidth: 1280,

  display: "grid",
  gridTemplateColumns: "1fr",
  gap: 16,
  "@media (min-width: 680px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "24px 16px",
  },
  "@media (min-width: 1000px)": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const RestaurantDescription = (props) => {
  const [restaurant, setRestaurant] = useState(null);

  const onClickGoBack = () => {
    props.history.push("/catering/restaurant");
  };

  useEffect(() => {
    cateringService
      .getRestaurantById(props.match.params.restaurantId)
      .then((res) => {
        setRestaurant(res.data.data);
      });
  }, []);

  return (
    <div className="page">
      {restaurant && (
        <Root>
          <div
            className="restaurant-banner"
            style={{
              backgroundImage: `url(${restaurant.fileUrl})`,
              borderBottomLeftRadius: "16px",
              borderBottomRightRadius: "16px",
            }}
          />
          <Grid container spacing={0} className="container">
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: "10px",
              }}
            >
              <BackButton disableRipple onClick={onClickGoBack}>
                <ArrowBackIcon className="Icon" style={{ color: "#99A8B2" }} />
              </BackButton>
              <Grid item xs={12}>
                <Typography className="restaurant-name">
                  {restaurant.nameRestaurant}
                </Typography>
                <Typography className="restaurant-location">
                  <i
                    className="fas fa-map-marker-alt"
                    style={{ color: "#d50000" }}
                  ></i>{" "}
                  {restaurant.location == ""
                    ? restaurant.locationfrommap
                    : restaurant.location}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <RootContent>
                {restaurant.foods.map((food) => (
                  <FoodCard key={`food_${food.idCateringFood}`} data={food} />
                ))}
              </RootContent>
            </Grid>
          </Grid>
        </Root>
      )}
    </div>
  );
};

export default RestaurantDescription;
