import React, { Component, useState } from "react";

// import { GoogleApiWrapper, Map, InfoWindow, Marker } from "google-maps-react";

import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";
import {
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import { GoogleAPIkey } from "../../../../../../../utils/googleMapAssets";
// import { GoogleMap, Marker } from "react-google-maps";
const placesLibrary = ["places"];

const GoogleApi = ({ handleGetDataFromGoogleMap }) => {
  const [searchResult, setSearchResult] = useState("Result: none");
  const [isLocation, setLocation] = useState([{ Lat: 13.7563, Lng: 100.5018 }]);
  const [map, setMap] = React.useState(null);

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GoogleAPIkey,
    libraries: placesLibrary,
    language: "th",
    region: "TH",
  });
  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      setLocation([
        {
          Lat: place.geometry.location.lat(),
          Lng: place.geometry.location.lng(),
        },
      ]);
      setData([
        {
          locationfrommap: place.name,
          Lat: place.geometry.location.lat(),
          Lng: place.geometry.location.lng(),
        },
      ]);
    } else {
      alert("Please enter text");
    }
  };
  console.log(isLocation);
  const containerStyle = {
    width: "100%",
    height: "250px",
  };

  const center = {
    lat: parseFloat(isLocation[0].Lat),
    lng: parseFloat(isLocation[0].Lng),
  };

  const setData = (data) => {
    handleGetDataFromGoogleMap(data);
  };
  // if (!isLoaded) {
  //   return <div>Loading...</div>;
  // }

  return (
    <>
      {isLoaded && (
        <div style={{ height: "80%", width: "100%" }}>
          <div id="searchColumn">
            <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
              {/* <input
            type="text"
            placeholder="Search for Tide Information"
       
            style={{
              boxSizing: `border-box`,
              border: `1px solid #eee`,
              width: `100%`,
              height: `50px`,
              padding: `10px`,
              borderRadius: `15px`,
              boxShadow: `0 0 15px 4px rgba(0,0,0,0.06)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              transition: `.3s border-color`,
            }}
          /> */}
              <TextField
                type="text"
                placeholder="ค้นหาสถานที่"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    width: "100%",
                    marginTop: "8px",
                    marginBottom: "5px",
                  },
                }}
              />
            </Autocomplete>
          </div>

          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
          >
            <Marker
              position={{
                lat: parseFloat(isLocation[0].Lat),
                lng: parseFloat(isLocation[0].Lng),
              }}
            />
          </GoogleMap>
        </div>
      )}
    </>
  );
};

export default GoogleApi;
