import React from "react";
import { styled, Paper } from "@mui/material";
import { margin } from "@mui/system";

const Root = styled(Paper)(({ theme }) => ({
  borderRadius: 16,
  margin: "0 20px 0 20px",
  padding: 16,
  transition:
    "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  boxShadow:
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
  position: "relative",
  overflow: "hidden",
  cursor: "pointer",
  userSelect: "none",
  zIndex: 1,
  [theme.breakpoints.down("lg")]: {
    margin: "0 5px 0 5px",
  },
  [theme.breakpoints.down("md")]: {
    margin: "0 5px 0 5px",
  },
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow:
      "rgb(145 158 171 / 60%) 0px 0px 2px 0px, rgb(145 158 171 / 60%) 0px 20px 40px -4px",
  },
}));

const Card = ({ children }) => {
  return <Root>{children}</Root>;
};

export default Card;
