import React, { useState } from 'react';

import { styled } from '@mui/styles';
import {
    Typography,
} from '@mui/material';

import CardEdit from "../../../../../shared/cardEdit";
import ModalDescription from "./modalDescription";

const Topic = styled(Typography)({
    fontSize:"22px",
    fontWeight:"bold",
    margin:"10px 0px",
});

const PartOfDescription = ({ LentCar, edit }) => {
    const [openModal, setOpenModal] = useState(false);

    const OnClickOpenModal = () => setOpenModal(true);

    const OnClickCloseModal = () => setOpenModal(false);

    return(
        <CardEdit openModal={OnClickOpenModal} edit={edit} >
            <Topic>Description</Topic>
            <Typography>{LentCar.description} </Typography>
            {openModal && <ModalDescription LentCar={LentCar} openModal={openModal} OnClickCloseModal={OnClickCloseModal}/>}
        </CardEdit>
    );
};

export default PartOfDescription;