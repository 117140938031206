import React, { useState } from "react";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import {
  Grid,
  Card,
  Avatar,
  Typography,
  Button,
  IconButton,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import StatusRequest from "../../../shared/StatusRequest";

import GridCard from "../../../shared/GridCard";
import ModalManageCar from "../modal";
import { SnackbarProvider, useSnackbar } from "notistack";

import DeleteModal from "../../../shared/deleteModal";
import DriverService from "../../../../../services/driverBooking.service";

require("dayjs/locale/th");
dayjs.locale("th");

const GridItem = styled(Grid)({
  display: "flex",
  marginTop: "20px",
  justifyContent: "center",
  position: "relative",
  // marginRight:"15px"
  padding: "0",
});

const CardStyled = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "120px",
  // boxShadow:theme.shadows[1],
  display: "flex",
  // padding: "0 10px",
  borderRadius: "10px",
  cursor: "pointer",
  transition:
    "transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 0 10px 0 rgba(73, 73, 73, 0.8)",
  },
  [theme.breakpoints.down("lg")]: {
    height: "fit-content",
    flexDirection: "column",
    padding: "15px 15px 15px 15px",
  },
}));

const PartOfContent = styled("div")(({ theme }) => ({
  width: "100%",
  height: "120px",
  display: "flex",
  // padding: "0 10px",
  [theme.breakpoints.down("lg")]: {
    height: "fit-content",
    flexDirection: "column",
    padding: "15px 0",
  },
}));

const PartOfAvatar = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexBasis: "8%",
  [theme.breakpoints.down("lg")]: {
    height: "fit-content",
  },
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "80px",
  height: "80px",
  [theme.breakpoints.down("lg")]: {
    width: "120px",
    height: "120px",
  },
}));
const GirdContent = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
    margin: "2.5px 0",
  },
}));
const Text = styled(Typography)({
  fontSize: "15px",
  fontWeight: 500,
});

const HighlightText = styled(Typography)({
  fontSize: "15px",
});
const HighlightTextName = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
});

const TextWithIcon = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  justifyContent: "center",
  "& .Icon": {
    marginRight: "5px",
    color: "#293A80",
  },
  "& .LocationIcon": {
    marginRight: "5px",
    color: theme.palette.error.main,
  },
  "& .LocationFromIcon": {
    marginRight: "5px",
    color: "green",
  },
  "& .CalendarIcon": {
    marginRight: "5px",
    color: "#305cde",
  },
  "& .ClockIcon": {
    marginRight: "5px",
    color: "#59b5f7",
  },
}));

const PartOfName = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  flexBasis: "12%",
  marginLeft: "10px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0",
    display: "block",
  },
  "& .TextContentPartName": {
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
  },
}));
const PartOfPlace = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexBasis: "12%",
  marginLeft: "10px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0",
    display: "block",
  },
  "& .TextContentPartName": {
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
  },
}));

const PartOfDate = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexBasis: "10%",
  "& .TextDateAndTime": {
    fontSize: "15px",
    [theme.breakpoints.down("lg")]: {
      margin: "-2px 0",
    },
  },
  "& .Date": {
    fontSize: "15px",
  },
  "& .Time": {
    fontSize: "15px",
  },
}));

const PartOfStatus = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexBasis: "10%",
  [theme.breakpoints.down("lg")]: {
    margin: "2px 0",
  },
  "& .TextSuccess": {
    color: "green",
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
    },
  },
  "& .TextWaiting": {
    color: "darkorange",
    fontSize: "15px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
    },
  },
}));
const PartOfOption = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexBasis: "10%",
  [theme.breakpoints.down("lg")]: {
    margin: "2px 0",
  },
  "& .TextSuccess": {
    color: "green",
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
    },
  },
  "& .TextWaiting": {
    color: "darkorange",
    fontSize: "15px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
    },
  },
}));

const PartOfNavButton = styled("div")(({ theme }) => ({
  width: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  position: "absolute",
  right: "5px",
  top: "calc(50% + 5px)",
  transform: "translateY(-50%)",
  zIndex: "2",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "60px",
    flexBasis: "auto",
    // top: "calc(100% + 0px)",
    position: "relative",
    marginTop: "25px",
  },
  "& .Icon": {
    fontSize: "25px",
    color: "white",
  },
}));

const ManageButton = styled(Button)(({ theme }) => ({
  width: "85px",
  fontSize: "15px",
  marginTop: "15px",
  borderRadius: "15px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  ["&:hover"]: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const EditButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  color: "white",
  marginRight: "5px",
  ["&:hover"]: {
    backgroundColor: theme.palette.grey[700],
  },
}));

const DeleteButton = styled(IconButton)({
  backgroundColor: "red",
  color: "white",

  ["&:hover"]: {
    backgroundColor: "darkred",
  },
});

const CardBooking = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const reload = () => window.location.reload();

  const onCloseModalHandle = () => {
    setOpenModal(false);
  };

  const onClickOpenModal = () => {
    setOpenModal(true);
  };

  const onClickDelete = () => {
    DriverService.deleteCarDriverBooking(props.booking).then((res) => {
      if (res.status === 200) {
        props.handleActionInPage();
        enqueueSnackbar("Delete Success", { variant: "success" });
      } else {
        enqueueSnackbar("Delete Failed", { variant: "error" });
      }
    });
  };
  const timeToSeconds = (timestartString, timeendString) => {
    if (!timestartString || !timeendString) return 0;
    const [hours, minutes] = timestartString.split(":").map(Number);
    const [hoursEnd, minutesEnd] = timeendString.split(":").map(Number);
    const data = Math.abs(
      hours * 3600 + minutes * 60 - (hoursEnd * 3600 + minutesEnd * 60)
    );
    const timeminutes = Math.floor(data / 60);
    return timeminutes;
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={12}>
      <GridCard>
        <Grid
          container
          item
          xs={12}
          md={12}
          lg={10.6}
          onClick={() => {
            props.onClickCard(props.booking);
          }}
        >
          <GirdContent item xs={12} md={12} lg={1.2}>
            <AvatarStyled
              src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
              alt="1"
            />
          </GirdContent>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={2.3}
            sx={(theme) => ({
              textAlign: "left",
            })}
          >
            <Grid item xs={12} md={12} lg={12}>
              <HighlightTextName>{props.booking.nameUser}</HighlightTextName>
              <Text>{`Tel. ${props.booking.telephoneMobile}`}</Text>
            </Grid>
          </GirdContent>
          <GirdContent item xs={12} md={12} lg={2} sx={{ textAlign: "center" }}>
            <Grid item xs={12} md={12} lg={12}>
              <TextWithIcon>
                <LocationOnRoundedIcon className="LocationFromIcon" />
                <Text>สถานที่รับ</Text>
              </TextWithIcon>
              <HighlightText>{props.booking.namePlaceFrom}</HighlightText>
            </Grid>
          </GirdContent>
          <GirdContent item xs={12} md={12} lg={2} sx={{ textAlign: "center" }}>
            <Grid item xs={12} md={12} lg={12}>
              <TextWithIcon>
                <LocationOnRoundedIcon className="LocationIcon" />
                <Text>สถานที่ส่ง</Text>
              </TextWithIcon>
              <HighlightText>{props.booking.namePlaceTo}</HighlightText>
            </Grid>
          </GirdContent>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={1.7}
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextWithIcon>
                <CalendarMonthRoundedIcon className="CalendarIcon" />
                <Text>วันที่จอง</Text>
              </TextWithIcon>
              <HighlightText>
                {dayjs(props.booking.startDate).format("D MMM YYYY")}
              </HighlightText>
            </Grid>
          </GirdContent>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={1.7}
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextWithIcon>
                <AccessTimeRoundedIcon className="ClockIcon" />
                <Text>เวลาจอง</Text>
              </TextWithIcon>
              <HighlightText>
                {props.booking.startTime} น.- {props.booking.endTime} น.
              </HighlightText>
            </Grid>
          </GirdContent>
          {/* <GirdContent
            item
            xs={12}
            md={12}
            lg={2.2}
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextWithIcon>
                <PeopleAltRoundedIcon className="Icon" />
                <Text>จำนวนคน</Text>
              </TextWithIcon>
              <HighlightText>{data.numberOfPeople}</HighlightText>
            </Grid>
          </GirdContent> */}

          <GirdContent item xs={12} md={12} lg={1} sx={{ textAlign: "center" }}>
            <Grid item xs={12} md={12} lg={12}>
              <Text>สถานะ</Text>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {props.booking.nameDriver != null ||
                props.booking.idVehicle != null ? (
                  <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={1}
                    active={1}
                  />
                ) : (
                  <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={null}
                    step={null}
                    active={1}
                  />
                )}
              </Grid>
            </Grid>
          </GirdContent>
        </Grid>

        <Grid container item xs={12} md={12} lg={1.4}>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={12}
            sx={{ textAlign: "center" }}
          >
            {props.booking.nameDriver == null &&
            props.booking.idVehicle == null ? (
              <Grid item xs={12} md={12} lg={12}>
                <ManageButton onClick={onClickOpenModal}>จัดคนขับ</ManageButton>
              </Grid>
            ) : (
              <Grid item xs={12} md={12} lg={12}>
                <EditButton onClick={onClickOpenModal} size="small">
                  <EditIcon className="Icon" />
                </EditButton>
                <DeleteButton onClick={() => setOpenDelete(true)} size="small">
                  <DeleteForeverIcon className="Icon" />
                </DeleteButton>
              </Grid>
            )}
          </GirdContent>
        </Grid>
      </GridCard>

      {/* <CardStyled>
          <PartOfContent onClick={() => props.onClickCard(props.booking)}>
            <PartOfAvatar>
              <AvatarStyled
                src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                alt="1"
              />
            </PartOfAvatar>
            <PartOfName>
              <HighlightTextName className="TextContentPartName">
                {props.booking.nameUser}
              </HighlightTextName>

              <Text className="TextContentPartName">
                {`Tel. ${props.booking.telephoneMobile}`}
              </Text>
            </PartOfName>
            <PartOfPlace>
              <Text className="TextContentPartName">สถานที่รับ</Text>
              <HighlightText className="TextContentPartName">
                {props.booking.namePlaceFrom}
              </HighlightText>
            </PartOfPlace>
            <PartOfPlace>
              <Text className="TextContentPartName">สถานที่ส่ง</Text>
              <HighlightText className="TextContentPartName">
                {props.booking.namePlaceTo}
              </HighlightText>
            </PartOfPlace>
            <PartOfDate>
              <Text>วันที่</Text>

              <HighlightText className="Time">
                {dayjs(props.booking.startDate).format("D MMM YYYY")}
              </HighlightText>
            </PartOfDate>
            <PartOfDate>
              <Text>เวลารับ-ส่ง</Text>

              <HighlightText className="Time">
                {props.booking.startTime} น.- {props.booking.endTime} น.
              </HighlightText>
            </PartOfDate>
            <PartOfDate>
              <Text>การเดินทาง</Text>

              <HighlightText className="Time">
                {props.booking.twoWay ? "ไป-กลับ" : "เที่ยวเดียว"}
              </HighlightText>
            </PartOfDate>
            
            <PartOfStatus>
              <Text>สถานะ</Text>
              {props.booking.nameDriver != null ||
              props.booking.idVehicle != null ? (
                <HighlightText className="TextSuccess">สำเร็จ</HighlightText>
              ) : (
                <HighlightText className="TextWaiting">
                  รอจัดคนขับรถ
                </HighlightText>
              )}
            </PartOfStatus>
          </PartOfContent>
          <PartOfNavButton>
            {props.booking.nameDriver == null &&
            props.booking.idVehicle == null ? (
              <ManageButton onClick={onClickOpenModal}>จัดคนขับ</ManageButton>
            ) : (
              <>
                <EditButton onClick={onClickOpenModal} size="small">
                  <EditIcon className="Icon" />
                </EditButton>
                <DeleteButton onClick={() => setOpenDelete(true)} size="small">
                  <DeleteForeverIcon className="Icon" />
                </DeleteButton>
              </>
            )}
          </PartOfNavButton>
        </CardStyled> */}

      <ModalManageCar
        open={openModal}
        onCloseHandle={onCloseModalHandle}
        inforManageCar={props.booking}
        handleActionInPage={props.handleActionInPage}
      />
      <DeleteModal
        open={openDelete}
        onCloseDeleteModalHandle={() => setOpenDelete(false)}
        onClickDelete={onClickDelete}
        label={"ลบรายการจองคนขับ"}
      />
    </Grid>
  );
};

export default CardBooking;
