import React, { useState, useEffect } from "react";
import {
  styled,
  Box,
  Typography,
  Chip,
  Dialog,
  DialogContent,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const FileInputWrapper = styled(Box)(({ theme, isEdit }) => ({
  border: "2px dashed #ccc",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  width: "100%",
  height: "200px",
  position: "relative",
  cursor: "pointer",
  overflow: "hidden",
  backgroundColor: "#f9f9f9",
  "&:hover": {
    backgroundColor: "#e9e9e9",
  },
}));

const HiddenInput = styled("input")({
  display: "none",
});

const CenterContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
});

const ImagePreview = styled("img")({
  maxWidth: "100%",
  maxHeight: "100%",
});

const FileInput = ({ isEdit, fileUrl, onFileChange }) => {
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    if (isEdit && fileUrl) {
      const fileNameFromUrl = fileUrl.split("/").pop().split("?")[0];
      setFileName(fileNameFromUrl);
      setImageSrc(fileUrl);
    }
  }, [isEdit, fileUrl]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setImageSrc(URL.createObjectURL(file));
      onFileChange(event); // Forward file change to parent component
    }
  };

  const handleChipClick = (event) => {
    event.stopPropagation(); // Prevent the click from propagating to the parent element
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FileInputWrapper
        isEdit={isEdit}
        onClick={() => document.getElementById("file-input").click()}
      >
        {fileName ? (
          <Chip
            label={fileName}
            onClick={handleChipClick}
            style={{ justifyContent: "flex-start" }}
          />
        ) : (
          <CenterContent>
            <CloudUploadIcon style={{ fontSize: 48, color: "#ccc" }} />
            <Typography variant="body1" color="textSecondary">
              คลิก หรือ ลาก ไฟล์ที่ต้องการ
            </Typography>
          </CenterContent>
        )}
        <HiddenInput id="file-input" type="file" onChange={handleFileChange} />
      </FileInputWrapper>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <ImagePreview src={imageSrc} alt="Preview" />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FileInput;
