import { httpClient } from "./httpClient";

const allDriverRouteDays = () => {
    return httpClient.get("driver_route_days");
};

const getDriverRouteDayById = (id) => {
    return httpClient.get("driver_route_day/"+id);
};

const getDriverRouteDayByIdDriver = (idDriver,routeDate) => {
    let data = {
        idDriver:idDriver,
        routeDate:routeDate
    };
    console.log("hhh", data)
    return httpClient.post("driver_route_day_by_idDriver",data);
};

const postIdVehicle = (data) => {
    return httpClient.post("driver_route_day_update_idVehicle",data);
}

const postBedTime = (data) => {
    return httpClient.post("driver_route_day_update_bedtime",data);
}

const postStartTime = (idDriver,routeDate) => {
    let data = {
        idDriver: idDriver,
        routeDate: routeDate,
        startTime: new Date()
    };
    return httpClient.post("driver_route_day_post_start_time",data);
}

const postEndTime = (idDriver,routeDate,endTime,duration) => {
    let data = {
        idDriver: idDriver,
        routeDate: routeDate,
        endTime: endTime,
        duration: duration
      };
    return httpClient.post("driver_route_day_post_end_time",data);
}

export default {
    allDriverRouteDays,
    getDriverRouteDayById,
    getDriverRouteDayByIdDriver,
    postIdVehicle,
    postBedTime,
    postStartTime,
    postEndTime
};