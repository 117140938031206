import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { SnackbarProvider, useSnackbar } from "notistack";

import clsx from "clsx";

import { styled } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import {
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Container,
  Grid,
} from "@mui/material";

import FormInformant from "../../shared/formBooking/formInformant";
import FormFlight from "./formFlight";
import FormCar from "./formCar";
import FormPurpose from "./formPurpose";
import FormDetailOfFight from "./formDetailOfFight";
import FormApprove from "./formApprove";
import DialogWarningSeats from "./dialogWarningSeats";
import NavButton from "../../shared/NavButton/NavButtonForm";
import DialogShowDetailBooking from "./dialogShowDetailBooking";
import { getAllUsers } from "../../../../actions/user";
import EmployeeService from "../../../../services/employee.service";
import SiteService from "../../../../services/site.service";

import CrossAreaCarBookingServices from "../../../../services/crossAreaCarBooking.service";
import { getEmployeesByCompany } from "../../../../actions/employee";

const DivCoverReturn = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",

  "& .Mobile": {
    marginTop: "150px",
  },
});

const DivStepperStyled = styled("div")(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  width: "100%",
  color: "#212b36",
  backgroundColor: "#ffffffcc",
  backdropFilter: "blur(6px)",
  position: "fixed",
  top: "64px",
  borderBottom: "1px solid black",
  marginBottom: "20px",
  padding: "15px 0",
  zIndex: "100",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const DivCoverForm = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  marginTop: "120px",
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const FormStyled = styled("form")({
  width: "80%",
});

const DivSpace = styled("div")({
  height: "150px",
});

const CrossAreaCarBooking = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const MobileResponsive = useMediaQuery("(max-width:600px)");
  const { enqueueSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = useState(1);
  const [openAlert, setOpenAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    warning: false,
    detailBooking: false,
  });
  const { user: AllUsers } = useSelector((state) => state.auth);
  const { result: allEmployees } = useSelector((state) => state.employees);

  useEffect(() => {
    if (!AllUsers) {
      dispatch(getAllUsers());
    }
    if (!allEmployees) {
      dispatch(getEmployeesByCompany(AllUsers.idUser));
    }
  }, []);
  const listStepper = [
    "รายละเอียดการจองรถ",
    "ระบุรถที่ใช้",
    "ระบุสถานที่ของแต่ละบุคคล",
    "ระบุบริษัทและคนที่อนุมัติ",
  ];

  const checkSeats = () => {
    if (
      parseInt(getValues("numberOfPassenger")) > parseInt(getValues("capacity"))
    ) {
      setOpenDialog({ ...openDialog, warning: true });
      return false;
    } else {
      return true;
    }
  };

  const onSubmitNextPage = (data) => {
    onClickNext();
    console.log(data);
  };

  const onClickBack = () => {
    setActiveStep((preActiveStep) => preActiveStep - 1);
    window.scroll(0, 0);
  };

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const checkListPassenger = () => {
    let isNotSpecified = [];

    if (getValues("gettingPoint") === "onePoint") {
      if (
        getValues(`listPassenger.person${1}.option`) === undefined ||
        getValues(`listPassenger.person${1}.option`) === null
      ) {
        isNotSpecified.push(1);
      }
    } else {
      for (let index = 1; index <= getValues("numberOfPassenger"); index++) {
        if (
          getValues(`listPassenger.person${index}.option`) === undefined ||
          getValues(`listPassenger.person${index}.option`) === null
        ) {
          isNotSpecified.push(index);
        }
      }
    }
    if (isNotSpecified.length > 0) {
      handleClick();
      return false;
    } else {
      return true;
    }
  };

  const onClickNext = () => {
    let Pass = true;
    let checkPassenger = true;
    if (activeStep === 2) {
      Pass = checkSeats();
    }
    if (activeStep === 3) {
      checkPassenger = checkListPassenger();
    }
    if (Pass && checkPassenger && activeStep !== 4) {
      setActiveStep((preActiveStep) => preActiveStep + 1);
    }
    if (activeStep === 4) {
      setOpenDialog({ ...openDialog, detailBooking: true });
    }
    if (checkPassenger) {
      window.scroll(0, 0);
    }
  };

  const SplitTime = (time) => {
    let splitTime = time.split(":");
    let TimeSet = new Date().setHours(splitTime[0], splitTime[1]);
    let result = new Date(TimeSet);
    return result;
  };

  const OnClickSubmitBooking = (data) => {
    delete data.Approved;
    delete data.indexCar;
    data.numberOfPassenger = parseInt(data.numberOfPassenger);
    data.startTime = SplitTime(data.startTime);
    data.endTime = SplitTime(data.endTime);
    data.totalPrice = getValues("totalPrice");
    if (getValues("flight") !== "twoWay") {
      data.fromPlaceReturn = null;
      data.toPlaceReturn = null;
      data.numberOfPassengerReturn = 0;
      data.returnDate = null;
      data.returnStartTime = null;
      data.returnEndTime = null;
    } else {
      data.numberOfPassengerReturn = parseInt(data.numberOfPassengerReturn);
      data.returnStartTime = SplitTime(data.returnStartTime);
      data.returnEndTime = SplitTime(data.returnEndTime);
    }
    console.log(data);
    CrossAreaCarBookingServices.postNewCrossAreaCarBooking(
      data,
      AllUsers.idUser
    ).then((res) => {
      if (res.status === 200) {
        enqueueSnackbar("Create Booking Success", { variant: "success" });
        props.history.push("/carbooking");
      } else {
        enqueueSnackbar("Create Booking Failed", { variant: "error" });
      }
    });
  };

  return (
    <DivCoverReturn className="page">
      <DivStepperStyled>
        <Stepper activeStep={activeStep - 1} alternativeLabel>
          {listStepper.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </DivStepperStyled>
      <DivCoverForm className={clsx({ Mobile: MobileResponsive })}>
        {activeStep === 1 && (
          <FormStyled onSubmit={handleSubmit(onSubmitNextPage)}>
            <FormInformant
              register={register}
              setValue={setValue}
              errors={errors}
            />

            <FormFlight
              control={control}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              errors={errors}
            />

            <FormPurpose register={register} errors={errors} />
            <NavButton
              type="button"
              numberOfPage={activeStep}
              onClickBackPageHandler={onClickBack}
            />
          </FormStyled>
        )}
        {activeStep === 2 && (
          <FormStyled onSubmit={handleSubmit(onSubmitNextPage)}>
            <FormCar
              getValues={getValues}
              setValue={setValue}
              control={control}
              watch={watch}
            />
            <NavButton
              type="button"
              numberOfPage={activeStep}
              onClickBackPageHandler={onClickBack}
            />
          </FormStyled>
        )}
        {activeStep === 3 && (
          <FormStyled onSubmit={handleSubmit(onSubmitNextPage)}>
            <FormDetailOfFight
              register={register}
              control={control}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
            />
            <NavButton
              type="button"
              numberOfPage={activeStep}
              onClickBackPageHandler={onClickBack}
            />
            <DivSpace />
          </FormStyled>
        )}
        {activeStep === 4 && (
          <FormStyled onSubmit={handleSubmit(onSubmitNextPage)}>
            <FormApprove
              register={register}
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
            />
            <NavButton
              type="submit"
              numberOfPage={activeStep + 1}
              onClickBackPageHandler={onClickBack}
            />
          </FormStyled>
        )}
      </DivCoverForm>
      <DialogWarningSeats
        open={openDialog}
        handleClose={() => setOpenDialog({ ...openDialog, warning: false })}
      />
      {activeStep === 4 && (
        <DialogShowDetailBooking
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          open={openDialog}
          handleClose={() =>
            setOpenDialog({ ...openDialog, detailBooking: false })
          }
          OnClickSubmitBooking={handleSubmit(OnClickSubmitBooking)}
        />
      )}
      <Snackbar
        open={openAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} variant="filled" severity="error">
          กรุณากรอกข้อมูลให้ครบ
        </Alert>
      </Snackbar>
    </DivCoverReturn>
  );
};

export default CrossAreaCarBooking;
