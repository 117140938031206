import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, styled } from "@mui/material";
import TruckLocation from "../../../user/sampleshuttle/NewSample/CardDetailSample/showMap/Icon/truck.png";
import FmdBadRoundedIcon from "@mui/icons-material/FmdBadRounded";
import registerOfDriverServices from "../../../../../services/registerOfDriver.service";

import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";
import {
  GoogleAPIkey,
  locationSite1,
} from "../../../../../../src/utils/googleMapAssets";
import SiteService from "../../../../../services/site.service";
import UserService from "../../../../../services/user.service";
import DriverMarker from "./driverMarker";
require("dayjs/locale/th");
dayjs.locale("th");
const placesLibrary = ["places"];

const StyledRoot = styled("div")(({ theme }) => ({}));
const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    height: "calc(100% - 30px)",
    paddingTop: "20px",
  },
  partOfFilter: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  DatePicker: {
    width: "200px",
    marginRight: "15px",
    [theme.breakpoints.down("md")]: {
      width: "170px",
      marginRight: "0px",
    },
  },
  Content: {
    height: "calc(100% - 80px)",
  },
  TableContainer: {
    borderTop: "1px solid rgb(224, 224, 224)",
    height: "100%",
  },
}));

const ShowMapAllDriver = () => {
  const classes = useStyles();
  const { result: Emergency, isFetching: isFetchingEmergency } = useSelector(
    (state) => state.emergency
  );

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [AllRegisterDriver, setAllRegisterDriver] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [InforRegister, setInforRegister] = useState(null);
  const [isAllDriver, setAllDriver] = useState([]);
  const [isLocationDriver, setLocationDriver] = useState([]);
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GoogleAPIkey,
    libraries: placesLibrary,
    language: "th",
    region: "TH",
  });
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const truckIcon = {
    url: TruckLocation,
    scaledSize: new window.google.maps.Size(25, 20), // Size of the icon
    origin: new window.google.maps.Point(0, 0), // Origin point
    anchor: new window.google.maps.Point(12.5, 20), // Anchor point
  };

  useEffect(() => {
    setLocationDriver([]);

    registerOfDriverServices
      .getRegistersOfDriverByDuration(startDate, endDate)
      .then((res) => {
        // console.log(res.data.result);
        setAllRegisterDriver(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
    UserService.getAllDriver().then((res) => {
      setAllDriver(res.data);
    });
  }, [startDate, endDate]);

  useEffect(() => {
    setLocationDriver([]);
    const dataDriver = [];
    if (isAllDriver != []) {
      isAllDriver.map((driver) => {
        SiteService.getLocationDriverById(driver.idUser).then((res) => {
          dataDriver.push(res.data[0]);
          setLocationDriver([...isLocationDriver, dataDriver]);
        });
      });
    }
  }, [isAllDriver]);

  const convertDate = (lawDateStart, lawDateEnd) => {
    let checkOneDay = () => {
      if (lawDateEnd !== "") {
        return false;
      } else {
        return true;
      }
    };

    return (
      <Typography
        className={`${classes.TextDetailOfFlight}`}
      >{`วันที่ : ${dayjs(lawDateStart).format("D MMM YYYY")}${
        !checkOneDay() && ` - ${dayjs(lawDateEnd).format("D MMM YYYY")}`
      }`}</Typography>
    );
  };
  const defaultProps = {
    center: {
      lat: parseFloat(13.45),
      lng: parseFloat(100.28),
      // lat: parseFloat(12.5),
      // lng: parseFloat(1.5),
    },
    zoom: 8,
  };

  console.log(isLocationDriver);
  return (
    <div className={`${classes.DivCoverReturn}`}>
      <div className={`${classes.partOfFilter}`}></div>
      <div className={`${classes.Content}`}>
        <div style={{ height: "100%", width: "100%" }}>
          {isLoaded && (
            <GoogleMap
              // bootstrapURLKeys={{ key: GoogleAPIkey }}
              mapContainerStyle={containerStyle}
              center={defaultProps.center}
              zoom={defaultProps.zoom}
              // yesIWantToUseGoogleMapApiInternals
            >
              {/* {isLocationDriver[0] != undefined &&
                isLocationDriver[0].map((data) => {
                  return (
                    <Marker
                      position={{
                        lat: parseFloat(data.LatDriver),
                        lng: parseFloat(data.LngDriver),
                      }}
                      key={data.idLocationDriver}
                      icon={truckIcon}

                      // lat={parseFloat(data.LatDriver)}
                      // lng={parseFloat(data.LngDriver)}
                    />
                  );
                })} */}

              <DriverMarker />
            </GoogleMap>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowMapAllDriver;
