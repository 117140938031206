import React, { Fragment, useState, useEffect } from "react";
import { styled, Divider } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Box,
} from "@mui/material";

import MeetingRoomService from "../../../../../../services/meetingRoom.service";


const StyledRoot = styled("div")(({ theme }) => ({
  "& .Title": {
    fontSize: "26px",
    textAlign: "start",
    // fontWeight: "bold"
  },
  "& .Place": {
    fontSize: "20px",
    textAlign: "start",
    // fontWeight: "bold"
  },
  "& .numOfPeople": {
    fontSize: "20px",
    textAlign: "start",
  },
  "& .Price": {
    fontSize: "20px",
    textAlign: "end",
  },
  "& .Button": {
    fontSize: "15px",
  },
}));

const MediaCard = (props) => {

  const [meeting, setMeeting] = useState({})
  const [meetingImage, setMeetingImage] = useState({})
  useEffect(() => {
    if (props.meeting) {
      setMeeting(props.meeting);
      if (props.meeting.fileUrl.length > 0) {
        setMeetingImage(props.meeting.fileUrl);
      } else {
        MeetingRoomService.getIAllImageNewMeetingRoomById(
          props.meeting.idNewMeetingRoom
        ).then((res) => {
          if (res.data) {
            // console.log(res.data[0].imageBase64)
            setMeetingImage(res.data[0].imageBase64);
          } else {
            console.log("error");
          }
        });
      }}
  }, []);
    return (
      <StyledRoot>
      <Card>
        <CardMedia
          sx={{ height: 200 }}
          image={meetingImage}
          title="green iguana"
        />
        <CardContent>
          <Typography className="Title">{meeting.nameMeetingRoom}</Typography>
          <Grid style={{ display: "flex", marginTop:"8px"  }}>
            <img
              style={{ weight: "26px", height: "26px", marginRight: "6px" }}
              src={"/assets/Image/MeetingRoom/business.png"}
              alt="Logo"
            />
            <Typography className="Place">สถานที่ {meeting.place}</Typography>
          </Grid>
          <Grid style={{ display: "flex", marginTop:"8px" }}>
          <img
              style={{ weight: "26px", height: "26px", marginRight: "6px" }}
              src={"/assets/Image/MeetingRoom/people.png"}
              alt="Logo"
            />
               <Typography className="numOfPeople">
            จำนวนที่จองได้ {meeting.numberOfPeople} คน
          </Typography>
          </Grid>
       
        </CardContent>
        <CardContent style={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            style={{ weight: "30px", height: "30px", marginRight: "6px" }}
            src={"/assets/Image/MeetingRoom/dollar.png"}
            alt="Logo"
          />
          <Typography className="Price">{meeting.price} บาท/ชม</Typography>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            // className="Button"
            onClick={() => {
              props.onClickCardHandler(meeting.idNewMeetingRoom);
            }}
          >
            รายละเอียดเพิ่มเติม
          </Button>
        </CardActions>
      </Card>
    </StyledRoot>
      );
}
export default MediaCard;
