import { DRIVERROUTEDAY_FETCHING, DRIVERROUTEDAY_FAILED, DRIVERROUTEDAY_SUCCESS } from "../actions/types";

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case DRIVERROUTEDAY_FETCHING:
            return {...state, result: null, isFetching: true, isError: false};
        case DRIVERROUTEDAY_FAILED:
            return {...state, result: null, isFetching: false, isError: true};
        case DRIVERROUTEDAY_SUCCESS:
            return {...state, result: payload, isFetching: false, isError: false};
        default:
            return state;
    }
}