import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styled } from '@mui/styles';
import { 
  Typography, Button
} from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import WaitingFrinedCar from "../../../../../../services/waitingFriendCar.service";
import PassengerFriendToFriendService from "../../../../../../services/passengerFriendToFriend.service";
import { getAllUsers } from "../../../../../../actions/user";
import CardRequest from "./cardRequest";

const DivCoverReturn = styled("div")(({theme})=>({
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  marginTop: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  position:"relative",
  [theme.breakpoints.down('sm')]: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

const NavButtonBack = styled("div")({
  position:"absolute",
  width:"fit-content",
  top:"65px",
  left:"10px",
  zIndex:"10",
});

const BackButton = styled(Button)({
  backgroundColor:"white",
  color:"black",
  "&:hover":{
    backgroundColor:"white",
  },
});

const BackIcon = styled(ArrowBackIosIcon)(({theme})=>({
  fontSize:"20px",
  color:"black",
  [theme.breakpoints.down('sm')]:{
    fontSize:"18px",
  },
}));

const TextBackButton = styled(Typography)(({theme})=>({
  fontSize:"20px",
  [theme.breakpoints.down('sm')]:{
    fontSize:"18px",
  },
}));

const Title = styled(Typography)(({theme})=>({
  textAlign: "center",
  fontSize: "30px",
  marginBottom: "10px",
  [theme.breakpoints.down('sm')]:{
    marginTop: "40px",
  },
}));

const TextNotFound = styled(Typography)({
  fontSize: "30px",
  textAlign: "center",
  color: "#A6ACAF",
});

const WaitingResponses = (props) => {
  const dispatch = useDispatch();
  const { user: CurrentUser } = useSelector((state) => state.auth);
  const { result: AllUser } = useSelector((state) => state.users);
  const [listOfOrder, setListOfOrder] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [CurrentUser]);

  useEffect(() => {
    PassengerFriendToFriendService.getWaitingResponsePassengerFriendToFriendByIdPassenger(CurrentUser.id).then(
      (res) => {
        if(res.data){
          setListOfOrder(res.data);
        }
      }
    ).catch(error => {
      setListOfOrder([]);
    });
    // WaitingFrinedCar.getWaitingFriendCarsByIdPassenger(1).then(
    //   (res) => {
    //     if (res.data.stateFind === "Found") {
    //       setListOfOrder(res.data.result);
    //     } else {
    //       setListOfOrder([]);
    //     }
    //   }
    // );
  }, [refresh])

  const onClickDetailButtonHandler = (idOrder) => {
    props.history.push("/friend2friend_Car/passenger/showDetail_driver", {
      orderId: idOrder,
      joined: true
    });
  }

  const onClickBackButton = () => {
    props.history.push("/friend2friend_Car/passenger/my_plan");
  };

  return (
    <DivCoverReturn className="page">
      <NavButtonBack>
        <BackButton
          onClick={onClickBackButton}
        >
          <BackIcon/>
          <TextBackButton>Back</TextBackButton>
        </BackButton>
      </NavButtonBack>
      <Title>รายการคำขอไปด้วยกัน</Title>
      {listOfOrder.length > 0 && AllUser ?
        listOfOrder.map((value) => {
          return (
            <CardRequest key={value.id} request={value} driver={value.driver} onClickDetailButtonHandler={onClickDetailButtonHandler}/> 
          );
        })
        :
        (<TextNotFound>ไม่มีรายการคำขอ</TextNotFound>)
        }
    </DivCoverReturn>
  );
};

export default WaitingResponses;
