import React, { useState } from "react";
import { styled, Typography, Grid, Chip } from "@mui/material";

const StyledDropZone = styled("label")(({theme})=>({
	flex: 1,
	position: "relative",
	["& .MuiChip-root"]: {
		marginRight: 8,
		marginBottom: 8,
		color: "#fff",
		cursor: "default",
		backgroundColor: theme.palette.primary.main,
		["&:hover"]: {
			backgroundColor: theme.palette.primary.main,
		},
		["& .MuiSvgIcon-root"]: {
			["&:hover"]: {
				color: "#fff",
			}
		}
	}
}))

const DropZone = ({name, setValue, maxFile, maxSize, onlyImage, defaultValue}) => {
	const [uploadFiles, setUploadFiles] = useState(defaultValue || []);

	const onFileHandle = (files) => {
		const validateFiles = [];
		for(let i = 0;i<files.length;i++){
			if(uploadFiles.length + i + 1 > (maxFile || 4)){
				continue;
			}
			if(files[i].size > (1048576*maxSize || 10485760)){ // check if larger than maxSize
				continue;
			}
			if(onlyImage && !["image/jpg","image/jpeg","image/png"].includes(files[i].type)){
				console.log(files[i])
				console.log("onlyImage", onlyImage)
				continue;
			}
			validateFiles.push(files[i]);
		}
		setUploadFiles([
			...uploadFiles,
			...validateFiles
		])

		setValue(name, [...uploadFiles, ...validateFiles]);
		// console.log("validateFiles", validateFiles);
		
	}

	const onDragHandler = (event) => {
		event.preventDefault();
	}

	const onDropFileHandler = (event) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		onFileHandle(files);
	}

	const onChangeFileHandler = (event) => {
		event.preventDefault();
		const files = event.target.files;
		// console.log(files);
		onFileHandle(files);
		event.target.value = null;
	}

	const onDeleteFile = (deleteIndex) => {
		setUploadFiles([...uploadFiles].filter((file, index)=> deleteIndex !== index))
		setValue(name, [...uploadFiles].filter((file, index)=> deleteIndex !== index))
	}

	return(
		<StyledDropZone
			htmlFor="dropfile"
			onDragOver={onDragHandler}
    	onDragEnter={onDragHandler}
    	onDragLeave={onDragHandler}
			onDrop={onDropFileHandler}
		>
			<input type="file" id="dropfile" hidden onChange={onChangeFileHandler} accept={onlyImage ?".jpg, .jpeg, .png": "*"}/>
			{(uploadFiles.length === 0) && <DropIcon />}
			<Grid container>
				{uploadFiles.map((file, index)=>(
					// <Grid key={`file_${index}`} item xs={6} sm={4} md={3} lg={2} container>
						<Chip key={`file_${index}`} label={file.name} onClick={(event)=>{event.preventDefault()}} onDelete={(event)=>{event.preventDefault(); onDeleteFile(index)}}/>
					//  </Grid> 
				))}
			</Grid>
		</StyledDropZone>
	)
}

const StyledIcon = styled("div")(({theme})=>({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	top: "50%",
	left: "50%",
	position: "absolute",
	transform: "translate(-50%, -50%)",
	userSelect: "none",
	pointerEvents: "none",
	["& .dropIcon"]: {
		fontSize: 100,
		color: "rgba(0,0,0,.32)",
	}
}))

const DropIcon = () => {
	return(
		<StyledIcon>
			<i className="fas fa-cloud dropIcon"></i>
			<Typography color="rgba(0,0,0,.32)!important" gutterBottom>คลิก หรือ ลาก ไฟล์ที่ต้องการ</Typography>
		</StyledIcon>
	)
}

const StyledDropFile = styled("div")(({theme})=>({
	padding: "16.5px 14px",
	// width: "100%",
	minHeight: 200,
	display: "flex",
	flexDirection: "column",
	borderRadius: "10px",
	border: "1px solid #c4c4c4",
}))

const DropFile = ({title, name, setValue, maxFile, maxSize, onlyImage, defaultValue}) => {
	return(
		<StyledDropFile>
			<Typography color="rgba(0,0,0,.6)!important" gutterBottom>{title}</Typography>
			<DropZone name={name} setValue={setValue} maxFile={maxFile} maxSize={maxSize} onlyImage={onlyImage} defaultValue={defaultValue}/>
		</StyledDropFile>
	)
}

export default DropFile;