import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/styles";
import Container from "@mui/material/Container";

import makeStyles from "@mui/styles/makeStyles";
import {
  Tabs,
  Tab,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Typography from "@mui/material/Typography";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

import CrossArea from "./crossArea";
import DetailCrossAreaCar from "./crossArea/detailCrossAreaCar";

import InArea from "./inArea";
import DetailInAreaCar from "./inArea/detailInAreaCar";

import CrossAreaPool from "./crossAreaPool";

import DeliveryItems from "./deliveryItems";
import DetailDeliveryCar from "./deliveryItems/detailDeliveryCar";

import DetailDriverPeer2Peer from "./DetailDriverPeer2peer";

import ShowMapAllDriver from "./showMapAllDriver";

import BetweenSiteCarManage from "./betweenSiteCar";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import { getUserProfile } from "../../../../actions/user";
import { getAllDriver } from "../../../../actions/user";
import { getAllVehicles } from "../../../../actions/vehicle";
import { getAllVehicleBrandsAndModels } from "../../../../actions/vehicleBrandAndModel";
import dayjs from "dayjs";
import driverEmergencyService from "../../../../services/driverEmergency.service";
require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  DatePicker: {
    // width: "200px",
    marginRight: "15px",
    [theme.breakpoints.down("md")]: {
      // width: "170px",
      marginRight: "0px",
    },
  },
  IconCar: {
    fontSize: "40px",
    marginRight: "10px",
    color: theme.palette.primary.main,
  },
}));

const DivCoverReturn = styled("div")({
  height: "calc(100% - 64px)",
});

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const HeaderOfPage = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: "40px",
  // alignItems: "center",
});

const TabsStyled = styled(Tabs)({
  // "& .MuiButtonBase-root": {
  //   border: "1px solid black",
  // },
  // display: "flex",
  // justifyContent: "center",
  "& .MuiTabs-scroller .PrivateTabIndicator-colorSecondary-34": {
    height: "3px",
  },

  // marginInline: "auto",
  // "& .TabLeft": {
  //   borderLeft: "1px solid black",
  // },
});

const TabStyled = styled(Tab)({
  // width: "150px",
  // border: "1px solid black",
  // borderLeft: "0",
  fontSize: "20px",
  height: "60px",
});
const Image = styled("img")({
  width: "100%",
  height: "80%",
});

const PartOfContent = styled("div")({
  width: "100%",
  height: "calc(100% - 80px)",
  // display: "flex",

  // alignItems: "center",
});
const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const Title = styled(Typography)({
  textAlign: "center",
  fontSize: "30px",
  marginBottom: "20px",
});

const Text = styled(Typography)({
  fontSize: "15px",
});

const CarManagement = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: allDriver } = useSelector((state) => state.drivers);
  const { result: allVehicles } = useSelector((state) => state.vehicles);
  const { result: listVehicleBrandAndModel } = useSelector(
    (state) => state.vehicleBrandsAndModels
  );
  const [typeOfManagement, setTypeOfManagement] = useState("cross_area");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [company, setCompany] = useState(userProfile?.companyName);

  const onChangeTab = (event, page) => {
    props.history.push("/admin/car_management/" + page);
    setTypeOfManagement(page);
  };

  const onClickGoBack = () => {
    props.history.push("/home");
  };

  useEffect(() => {
    if (userProfile === null) {
      dispatch(getUserProfile(currentUser.idUser));
    }
    if (!allDriver) {
      dispatch(getAllDriver());
    }
    if (!allVehicles) {
      dispatch(getAllVehicles());
    }
    if (!listVehicleBrandAndModel) {
      dispatch(getAllVehicleBrandsAndModels());
    }

    let path = props.history.location.pathname.split("/");
    if (path[path.length - 1] === "cross_area") {
      setTypeOfManagement("cross_area");
    }
    if (path[path.length - 1] === "cross_area_pool") {
      setTypeOfManagement("cross_area_pool");
    }
    if (path[path.length - 1] === "in_area") {
      setTypeOfManagement("in_area");
    }
    if (path[path.length - 1] === "delivery") {
      setTypeOfManagement("delivery");
    }
    if (path[path.length - 1] === "peer_to_peer") {
      setTypeOfManagement("peer_to_peer");
    }
    if (path[path.length - 1] === "beetween_site_car") {
      setTypeOfManagement("beetween_site_car");
    }
    if (path[path.length - 1] === "showMapAllDriver") {
      setTypeOfManagement("showMapAllDriver");
    }
    driverEmergencyService
      .getDriverEmergencyThatIsNotFinish()
      .then((res) => {});
  }, []);
  return (
    <Container maxWidth="xl">
      <Root>
        <DivCoverReturn className="page">
          <HeaderOfPage>
            <Grid
              sx={(theme) => ({
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "15px",
                [theme.breakpoints.down("sm")]: {
                  display: "grid",
                },
                [theme.breakpoints.down("md")]: {
                  display: "grid",
                },
              })}
            >
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <BackButton disableRipple onClick={onClickGoBack}>
                    <ArrowBackIcon
                      className="Icon"
                      style={{ color: "#99A8B2" }}
                    />
                  </BackButton>
                </Grid>
                <DriveEtaIcon className={`${classes.IconCar}`} />

                <Title>Car Management</Title>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={(theme) => ({
                display: "flex",
                // justifyContent: "space-between",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                boxShadow:
                  "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

                marginBottom: "20px",
                padding: "25px 30px 25px 30px",
                // padding: "25px 30px 25px 30px",

                borderRadius: "15px",
                [theme.breakpoints.down("sm")]: {
                  // padding: "25px 30px 25px 15px",
                },
                [theme.breakpoints.down("md")]: {
                  // padding: "25px 30px 25px 15px",
                },
              })}
            >
              <Grid item xs={12} md={6}>
                <Stack>
                  <Text>ชื่อบริษัท</Text>

                  {userProfile && (
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        placeholder="เลือกบริษัท"
                        id="company"
                        defaultValue={company}
                        onChange={(e) => setCompany(e.target.value)}
                        value={company}
                      >
                        <MenuItem
                          key={userProfile.idCompany}
                          value={userProfile.companyName}
                        >
                          {userProfile.companyName}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={3}>
                <Stack>
                  <Text>วันที่เดินทาง</Text>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={startDate}
                      fullWidth
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => (
                        <TextField
                          className={`${classes.DatePicker}`}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={12} md={3}>
                <Stack>
                  <Text>วันที่สิ้นสุด</Text>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      minDate={startDate}
                      value={endDate}
                      fullWidth
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => (
                        <TextField
                          className={`${classes.DatePicker}`}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </Grid>
            <TabsStyled
              value={typeOfManagement}
              onChange={onChangeTab}
              // centered
              aria-label="Tab-typeOfManagement"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <TabStyled
                className="classes.TabLeft"
                id="Tab-crossArea"
                value="cross_area"
                label="รถข้ามพื้นที่เฉพาะ"
              />
              <TabStyled
                id="Tab-crossAreaPool"
                value="cross_area_pool"
                label="รถข้ามพื้นที่Pool"
              />
              <TabStyled id="Tab-inArea" value="in_area" label="รถในพื้นที่" />
              <TabStyled
                id="Tab-deliveryItem"
                value="delivery"
                label="รถรับส่งของ"
              />
              <TabStyled
                id="Tab-peer2peer"
                value="peer_to_peer"
                label="รถ Peer to Peer"
              />
              <TabStyled
                id="Tab-beetweenSiteCar"
                value="beetween_site_car"
                label="รถรับส่งระหว่าง Site"
              />
              <TabStyled
                id="Tab-showMapAllDriver"
                value="showMapAllDriver"
                label="แสดงแผนที่รถทั้งหมด"
              />
            </TabsStyled>
          </HeaderOfPage>
          <PartOfContent>
            <Switch>
              <Route
                exact
                path="/admin/car_management"
                component={() => (
                  <Redirect to="/admin/car_management/cross_area" />
                )}
              />

              <Route
                exact
                path="/admin/car_management/cross_area"
                component={(props) => (
                  <CrossArea
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
              <Route
                exact
                path="/admin/car_management/cross_area/detail"
                component={(props) => (
                  <DetailCrossAreaCar
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />

              <Route
                exact
                path="/admin/car_management/cross_area_pool"
                component={(props) => (
                  <CrossAreaPool
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />

              <Route
                exact
                path="/admin/car_management/in_area"
                component={(props) => (
                  <InArea {...props} startDate={startDate} endDate={endDate} />
                )}
              />
              <Route
                exact
                path="/admin/car_management/in_area/detail"
                component={(props) => (
                  <DetailInAreaCar
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />

              <Route
                exact
                path="/admin/car_management/delivery"
                component={(props) => (
                  <DeliveryItems
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
              <Route
                exact
                path="/admin/car_management/delivery/detail"
                component={(props) => (
                  <DetailDeliveryCar
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />

              <Route
                exact
                path="/admin/car_management/peer_to_peer"
                component={(props) => (
                  <DetailDriverPeer2Peer
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />

              <Route
                exact
                path="/admin/car_management/showMapAllDriver"
                component={ShowMapAllDriver}
              />

              <Route
                exact
                path="/admin/car_management/beetween_site_car"
                component={(props) => (
                  <BetweenSiteCarManage
                    {...props}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
            </Switch>
          </PartOfContent>
        </DivCoverReturn>
      </Root>
    </Container>
  );
};

export default CarManagement;
