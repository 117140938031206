import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { SnackbarProvider, useSnackbar } from "notistack";

import FormInformant from "../../shared/formBooking/formInformant";
import FormFlight from "./formFlight/index";
import NavButtonForm from "../../shared/NavButton/NavButtonForm";
import DialogShowDetailBooking from "./dialogShowDetailBooking";
import { getAllUsers } from "../../../../actions/user";
import { postNewInAreaCarBooking } from "../../../../actions/inAreaCarBooking.js";

import { useSelector, useDispatch } from "react-redux";

import InAreaCarBookings from "../../../../services/inAreaCarBooking.service";
import inAreaCarBookingService from "../../../../services/inAreaCarBooking.service";
const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingInline: "5vw",
    [theme.breakpoints.down("sm")]: {
      paddingInline: "0",
      marginLeft: "0",
      width: "100%",
    },
  },
  Grid: {
    width: "80%",
    margin: "0 auto",
    marginTop: "20px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },
  Title: {
    textAlign: "center",
    fontSize: "25px",
    margin: "25px 0px",
  },
}));

const InAreaCarBooking = (props) => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // const AllInput = watch();
  const [openDetailBooking, setOpenDetailBooking] = useState(false);
  const [stateInputRef, setStateInputRef] = useState({
    fromPlaceReturn: "",
    toPlaceReturn: "",
    listAllPassengers: [{ order: "" }],
    statusUsingAllPassenger: false,
  });
  const { result: AllUsers } = useSelector((state) => state.users);
  const { user: User } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!AllUsers) {
      dispatch(getAllUsers());
    }
  }, []);

  const showRegister = (date) => {
    setOpenDetailBooking(true);
    console.log(date);
  };

  const onCloseDialog = () => {
    setOpenDetailBooking(false);
  };

  const onClickSubmit = async (data) => {
    let myData = data;
    myData.idUser = User.idUser;
    console.log(myData);
    inAreaCarBookingService.postNewInAreaCarBooking(myData).then((data) => {
      if (data.type === "success") {
        enqueueSnackbar("Create Booking Success", { variant: "success" });
        props.history.push("/carbooking");
      } else {
        enqueueSnackbar("Create Booking Failed", { variant: "error" });
      }
    });
  };

  return (
    <div className={`page ${classes.DivCoverReturn}`}>
      <Typography className={classes.Title}>จองรถในพื้นที่</Typography>
      <form style={{ width: "80%" }} onSubmit={handleSubmit(showRegister)}>
        <FormInformant
          register={register}
          setValue={setValue}
          errors={errors}
        />
        <FormFlight
          register={register}
          control={control}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
        />
        <NavButtonForm type="submit" numberOfPage={1} />
      </form>
      {openDetailBooking && (
        <DialogShowDetailBooking
          open={openDetailBooking}
          handleClose={onCloseDialog}
          getValues={getValues}
          watch={watch}
          onClickSubmit={handleSubmit(onClickSubmit)}
        />
      )}
    </div>
  );
};

export default InAreaCarBooking;
