import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  styled,
  Grid,
  Typography,
  TextField,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ButtonBlue from "../../../../shared/buttonBlue";
import DateAdapter from "@mui/lab/AdapterDayjs";
import "dayjs/locale/th";

import CateringService from "../../../../../../services/catering.service";

// import GoogleMap from "./Googlemap/googlemap";
import GoogleMap from "./Googlemap/googlemap";

import Dropfile from "../../../../shared/Form/DropFile";
import FileInput from "./dorpFile/index";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1600,
}));
const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const Form = ({ isEdit, onSuccessHandler, onClickGoBack, editRestaurant }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm();
  const [mapdata, setData] = useState("");
  const [isCateringRestaurantType, setCateringRestaurantType] = useState();
  const [file, setFile] = useState();

  const getDataFromGoogleMap = (data) => {
    setData(data);
    setValue("location", data[0].locationfrommap);
  };
  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const onSubmit = (data) => {
    if (isEdit) {
      data.idCateringRestaurant = editRestaurant.idCateringRestaurant;
    }
    const resultData = {
      data: data,
      dataMap: mapdata,
    };
    console.log(resultData);
    console.log(file);
    onSuccessHandler(resultData, file);
  };

  useEffect(() => {
    CateringService.getCateringRestaurantType().then((res) => {
      if (res.data.data) {
        setCateringRestaurantType(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    console.log("form", editRestaurant);

    if (isEdit && editRestaurant) {
      const map = [
        {
          locationfrommap: editRestaurant.locationfrommap,
          Lat: editRestaurant.Lat,
          Lng: editRestaurant.Lng,
        },
      ];
      setValue("nameRestaurant", editRestaurant.nameRestaurant);
      setValue("categories", editRestaurant.categories);
      setValue("name", editRestaurant.name);
      setValue("phone", editRestaurant.phone);
      setValue("email", editRestaurant.email);
      setValue("location", editRestaurant.location);
      setData(map);
    }
  }, [editRestaurant]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={0}>
        <Grid item xs={12} marginBottom={2} marginTop={1}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <BackButton disableRipple onClick={onClickGoBack}>
              <ArrowBackIcon className="Icon" style={{ color: "#99A8B2" }} />
            </BackButton>
            <Typography fontSize={32}>
              {isEdit ? "แก้ไขร้านอาหาร" : "เพิ่มร้านอาหาร"}
            </Typography>
          </div>
        </Grid>
        <Grid
          container
          item
          spacing={3}
          xs={12}
          sx={(theme) => ({
            display: "flex",
            boxShadow:
              "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
            border: "1px solid #EEEEEE",
            backgroundColor: "#FBFCFC",
            marginTop: "10px",
            marginBottom: "20px",
            marginLeft: "0px",
            padding: "0 25px 25px 0",

            borderRadius: "15px",
            [theme.breakpoints.down("sm")]: {},
            [theme.breakpoints.down("md")]: {},
          })}
        >
          <Grid item xs={12} lg={4}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อร้านอาหาร"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                  fullWidth
                  helperText={
                    errors.nameRestaurant ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                  }
                  error={errors.nameRestaurant ? true : false}
                />
              )}
              control={control}
              name="nameRestaurant"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ประเภทร้านอาหาร"
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                  helperText={
                    errors.categories ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                  }
                  error={errors.categories ? true : false}
                  select
                  fullWidth
                >
                  {isCateringRestaurantType != undefined &&
                    isCateringRestaurantType.map((mt) => (
                      <MenuItem
                        key={mt.idCateringRestaurantType}
                        value={mt.idCateringRestaurantType}
                      >
                        {mt.type}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              control={control}
              name="categories"
              defaultValue={editRestaurant?.categories || ""} // Set the default value here
              rules={{ required: true }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              label="ชื่อผู้ติดต่อ"
              InputLabelProps={{ shrink: true }}
              inputProps={{ ...register("name", { required: true }) }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
              fullWidth
              helperText={errors.name ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false}
              error={errors.name ? true : false}
            />
          </Grid>
          <Grid item xs={6} lg={4}>
            <TextField
              label="เบอร์โทรศัพท์"
              InputLabelProps={{ shrink: true }}
              inputProps={{ ...register("phone") }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
              fullWidth
              // helperText={errors.phone ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false}
              // error={errors.phone ? true : false}
            />
          </Grid>
          <Grid item xs={6} lg={4}>
            <TextField
              label="Email"
              InputLabelProps={{ shrink: true }}
              inputProps={{ ...register("email") }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
              fullWidth
              // helperText={errors.email ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false}
              // error={errors.email ? true : false}
            />
          </Grid>

          <Grid item xs={12} marginRight="-10px">
            {/* <InputLabel>{"สถานที่"}</InputLabel> */}
            <Grid item xs={12} sm={12} lg={12}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    error={errors.location ? true : false}
                    helperText={
                      errors.location ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    label="ชื่อสถานที่"
                    variant="outlined"
                    disabled
                    multiline
                    fullWidth
                    // inputProps={{ ...register("location") }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                )}
                control={control}
                name="location"
                defaultValue=""
                rules={{ required: true }}
              />
            </Grid>
            <GoogleMap
              // name="fromPlace"
              inputProps={{ ...register("place"), mapdata }}
              value={mapdata}
              handleGetDataFromGoogleMap={getDataFromGoogleMap}
            />
          </Grid>
          <Grid item container xs={12}>
            <Typography>แนบไฟล์รูปภาพ (สูงสุด 1 ไฟล์)</Typography>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            {/* <TextField
              id="outlined-basic"
              variant="outlined"
              type="file"
              fullWidth
              onChange={onFileChange}
              inputProps={{
                multiple: true,
              }}
              InputProps={{
                style: {
                  borderRadius: "8px",
                },
              }}
            /> */}
            <FileInput
              isEdit={isEdit}
              fileUrl={isEdit && editRestaurant?.fileUrl}
              onFileChange={onFileChange}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container justifyContent="flex-end" marginTop="10px">
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            variant="contained"
            style={{
              height: "50px",
              color: "white",
              borderRadius: "15px",
            }}
          >
            {isEdit ? "แก้ไขเมนูอาหาร" : "เพิ่มเมนูอาหาร"}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
