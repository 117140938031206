import { httpClient } from "./httpClient";

const getSitebyName = (IdScgSite) => {
  return httpClient.get("site_by_name/" + IdScgSite);
};
const addLocationDriver = (data) => {
  return httpClient.post("add_location_driver/add", data);
};
const getLocationDriverById = (idDriver) => {
  return httpClient.get("get_location_Driver_by_id/" + idDriver);
};

const getAllLocationDriver = () => {
  return httpClient.get("get_all_location_Driver");
};
const getAllSite = () => {
  return httpClient.get("get_all_site");
};
export default {
  getSitebyName,
  addLocationDriver,
  getLocationDriverById,
  getAllLocationDriver,
  getAllSite,
};
