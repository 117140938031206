import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styled } from '@mui/styles';
import CardGivingPoints from "./cardGivingPoints";

import { getUserProfile } from "../../../../actions/user";

const DivCoverReturn = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
});

const Background = styled("img")({
  width: "100%",
  height: "calc(100% - 64px)",
  zIndex: "-1",
  position: "absolute",
  opacity: "0.7",
});

const GivingPointsPage = (props) => {
  const dispatch = useDispatch();
  const { result: RouteOfDriver } = useSelector(state => state.routes);

  const [orderId, setOrderId] = useState(0);
  const [arrayObjectPassenger, setArrayObjectPassenger] = useState([]);

  useEffect(() => {
    //ไม่ติด
    // if(RouteOfDriver){
    //   dispatch(getUserProfile(RouteOfDriver[orderId].idUser));
    //   setArrayObjectPassenger(RouteOfDriver);
    // }
  }, [orderId]);


  const OnClickSubmitRatingButton = () => {
    props.history.push("/driver");
  }

  return (
    <DivCoverReturn className="page">
      {console.log(RouteOfDriver)}
      <Background
        src={`./assets/background/backgroundReviews.jpg`}
        alt="backgroundReviews"
      />
      {arrayObjectPassenger.length > 0 &&
        <CardGivingPoints
          maxOrder={arrayObjectPassenger.length}
          currentOrder={orderId}
          onClickHandler={setOrderId}
          arrayObjectPassenger={arrayObjectPassenger}
          setArrayObjectPassenger={setArrayObjectPassenger}
          OnClickSubmitRatingButton={OnClickSubmitRatingButton}
        />
      }
    </DivCoverReturn>
  );
};

export default GivingPointsPage;
