import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import dayjs from "dayjs";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  Typography,
  Avatar,
  IconButton,
  Fade,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../shared/deleteModal";
import { SnackbarProvider, useSnackbar } from "notistack";

import { getAllVehicles } from "../../../../actions/vehicle";
import VehiclesService from "../../../../services/vehicle.service";
import VehiclesTypeService from "../../../../services/vehicleType.service";

import DetailCarComponent from "./detailCarComponent";
import vehicleBrandAndModelService from "../../../../services/vehicleBrandAndModel.service";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  partOfTopNavButton: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  partOfTitle: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
  },
  ImageTopic: {
    width: "60px",
    height: "60px",
    marginRight: "20px",
  },
  Title: {
    fontSize: "50px",
  },
  TopNavButton: {
    height: "50px",
    display: "flex",
    justifyContent: "flex-end",
    padding: " 15px 50px",
  },
  Button: {
    borderRadius: "50px",
  },
  AddDriverButton: {
    width: "190px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: "20px",
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  DeleteDriverButton: {
    width: "150px",
    fontSize: "20px",
    backgroundColor: "red",
    color: "white",
    marginRight: "20px",
    ["&:hover"]: {
      backgroundColor: "darkred",
    },
  },
  Icon: {
    marginRight: "10px",
  },
  partOfDriver: {
    backgroundColor: "white",
    width: "100%",
    display: "flex",
    height: "calc(100% - 80px)",
  },
  partOfTableDriver: {
    width: "70%",
    height: "100%",
    border: "1px solid #CCD1D1",
  },
  TableContainer: {
    height: "100%",
  },
  TableHeadCell: {
    fontSize: "20px",
  },
  TableCell: {
    borderBottom: "1px solid #CCD1D1",
  },
  TableCellPlateNo: {
    width: "10%",
  },
  TableCellCompany: {
    width: "25%",
  },
  TableRow: {
    backgroundColor: "white",
    ["&:hover"]: {
      cursor: "pointer",
    },
  },
  Avatar: {
    width: "50px",
    height: "50px",
    "& img": {
      objectPosition: "0px 0px",
    },
  },
  Text: {
    fontSize: "18px",
  },
  editIcon: {
    color: theme.palette.primary.dark,
  },
}));

const columnHead = [
  { id: "picture", label: "", padding: "checkbox", align: "left" },
  { id: "plate_No", label: "ทะเบียน", padding: "normal", align: "left" },
  {
    id: "vehicleTypeNameTH",
    label: "ประเภท",
    padding: "normal",
    align: "center",
  },
  { id: "vendor", label: "บริษัท", padding: "normal", align: "left" },
  {
    id: "expire_Date",
    label: "วันหมดอายุ",
    padding: "normal",
    align: "center",
  },
  { id: "edit", label: "", padding: "checkbox", align: "left" },
];

const VehiclePage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const AllVehicles = useSelector((state) => state.vehicles);
  const [idUserClicked, setIdUserClicked] = useState(0);
  const [selectDriver, setSelectDriver] = useState([]);
  const [actionInPage, setActionInPage] = useState(false);
  const [allVehicleTypes, setAllVehicleTypes] = useState([]);
  const [allVehicleBrands, setAllVehicleBrands] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    console.log(actionInPage);
    dispatch(getAllVehicles());
    console.log(props.history);
  }, [actionInPage, props.history]);

  const handleClick = (event, id) => {
    const selectedIndex = selectDriver.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectDriver, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectDriver.slice(1));
    } else if (selectedIndex === selectDriver.length - 1) {
      newSelected = newSelected.concat(selectDriver.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectDriver.slice(0, selectedIndex),
        selectDriver.slice(selectedIndex + 1)
      );
    }

    setSelectDriver(newSelected);
  };

  const handleClickDeleteButton = () => {
    setIdUserClicked(0);
    let objSelectDrivers = { idVehicle: selectDriver };
    console.log(objSelectDrivers);
    VehiclesService.postDeleteVehicle(objSelectDrivers).then((res) => {
      if (res.data) {
        setSelectDriver([]);
        setOpenDeleteModal(false);
        setActionInPage(!actionInPage);
        enqueueSnackbar("Delete Success", { variant: "success" });
      } else {
        enqueueSnackbar("Delete Failed", { variant: "error" });
      }
    });
  };

  const handleActionInPage = () => {
    setActionInPage(!actionInPage);
    console(actionInPage);
  };

  const handleClickAddButton = () => {
    props.history.push("/admin/vehicle_page/add_car", {
      isEdit: false,
    });
  };

  const handleClickEditButton = (vehicle) => {
    props.history.push("/admin/vehicle_page/add_car", {
      vehicle: vehicle,
      isEdit: true,
    });
  };

  const IsSelected = (id) => selectDriver.indexOf(id) !== -1;

  return (
    <div className={`page`}>
      <div className={`${classes.partOfTopNavButton}`}>
        <div className={`${classes.partOfTitle}`}>
          <img
            className={`${classes.ImageTopic}`}
            src="../../assets/Image/Icon/carTopic.png"
            alt="car"
          />
          <Typography className={`${classes.Title}`}>รถยนต์</Typography>
        </div>
        <div className={`${classes.TopNavButton}`}>
          <Fade in={selectDriver.length > 0}>
            <Button
              className={`${classes.DeleteDriverButton} ${classes.Button}`}
              onClick={() => setOpenDeleteModal(true)}
            >
              <DeleteIcon className={`${classes.Icon}`} />
              Delete
            </Button>
          </Fade>
          <Button
            className={`${classes.AddDriverButton} ${classes.Button}`}
            onClick={handleClickAddButton}
          >
            <AddIcon className={`${classes.Icon}`} />
            Add Vehicle
          </Button>
        </div>
      </div>
      <div className={`${classes.partOfDriver}`}>
        <div className={`${classes.partOfTableDriver}`}>
          <TableContainer className={`${classes.TableContainer}`}>
            <Table stickyHeader aria-label="List Driver">
              <TableHead>
                <TableRow>
                  <TableCell
                    key={"checkbox"}
                    align="center"
                    padding="checkbox"
                    className={`${classes.TableCell}`}
                  ></TableCell>
                  {columnHead.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.padding}
                      className={clsx(
                        classes.TableHeadCell,
                        classes.TableCell,
                        {
                          [classes.TableCellPlateNo]:
                            headCell.id === "plate_No",
                          [classes.TableCellCompany]: headCell.id === "company",
                        }
                      )}
                    >
                      <Typography className={`${classes.TableHeadCell}`}>
                        {headCell.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {AllVehicles.result &&
                  AllVehicles.result.map((vehicle, index) => (
                    <TableRow
                      hover
                      key={vehicle.idVehicle}
                      className={`${classes.TableRow}`}
                      onClick={() => {
                        setIdUserClicked(index + 1);
                      }}
                    >
                      <TableCell
                        padding="checkbox"
                        className={`${classes.TableCell}`}
                      >
                        <Checkbox
                          checked={IsSelected(vehicle.idVehicle)}
                          onClick={(event) => {
                            handleClick(event, vehicle.idVehicle);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        padding="normal"
                        className={`${classes.TableCell}`}
                      >
                        <Avatar
                          className={`${classes.Avatar}`}
                          src={`${process.env.REACT_APP_URL}image/car/${vehicle.imagepath}`}
                          alt="1"
                        />
                      </TableCell>
                      <TableCell className={`${classes.TableCell}`}>
                        <Typography className={`${classes.Text}`}>
                          {vehicle.Plate_No}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.TableCell}`}
                      >
                        <Typography className={`${classes.Text}`}>
                          {vehicle.vehicleTypeNameTH}
                        </Typography>
                      </TableCell>
                      <TableCell className={`${classes.TableCell}`}>
                        <Typography className={`${classes.Text}`}>
                          {vehicle.Vendor}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.TableCell}`}
                      >
                        <Typography className={`${classes.Text}`}>
                          {dayjs(vehicle.ExpireDate).format("D MMM YYYY")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.TableCell}`}
                      >
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleClickEditButton(vehicle)}
                          size="large"
                        >
                          <EditIcon className={`${classes.editIcon}`} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <DeleteModal
          open={openDeleteModal}
          onCloseDeleteModalHandle={() => setOpenDeleteModal(false)}
          onClickDelete={() => handleClickDeleteButton()}
          label={"ลบรายการรถยนต์"}
        />
        <DetailCarComponent idUserClicked={idUserClicked} />
      </div>
    </div>
  );
};

export default VehiclePage;
