import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import makeStyles from "@mui/styles/makeStyles";
import {
  Modal,
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  Rating,
} from "@mui/material";

import DriveEtaIcon from "@mui/icons-material/DriveEta";

import ReviewService from "../../../../../../services/review.service";

const useStyles = makeStyles((theme) => ({
  Modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Paper: {
    width: "600px",
    padding: "20px",
  },
  partOfTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  IconCar: {
    fontSize: "40px",
    marginRight: "10px",
    color: theme.palette.primary.main,
  },
  Title: {
    fontSize: "30px",
    color: theme.palette.primary.main,
  },
  partOfNavButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  Button: {
    width: "100px",
    marginTop: "20px",
    backgroundColor: "green",
    fontSize: "18px",
    color: "white",
    ["&:hover"]: {
      backgroundColor: "darkgreen",
    },
  },
}));

const ModalRating = (props) => {
  console.log(props);
  const classes = useStyles();
  const { register, handleSubmit, control } = useForm();
  const [value, setValue] = useState(0);

  const onClickSubmit = (AnswerFromInput) => {
    // console.log(AnswerFromInput);
    AnswerFromInput.infor = props.infor
    AnswerFromInput.rating = value
    ReviewService.postNewReview(AnswerFromInput).then((res)=>{
      console.log(res)
      if(res.status === 200){
        window.location.reload(true);
      }
    })
    // AnswerFromInput.id = props.inforManageCar.idDriverBooking;
    // console.log("prop", props.inforManageCar, AnswerFromInput);
    // DriverService.postManageCarDriverBooking(AnswerFromInput);

    props.onCloseHandle();
  };

  //   useEffect(() => {
  //     userService
  //       .getAllDriver()
  //       .then((res) => {
  //         if (res.data) {
  //           setAllDriver(res.data);
  //         }
  //       })
  //       .catch((error) => {
  //         setAllDriver([]);
  //       });
  //     DriverService.getAllVehicle().then((res)=>{
  //       if(res.data){
  //         setallVehicle(res.data)
  //       }
  //     }).catch((error) => {
  //         setallVehicle([]);
  //       });
  //   }, [props.inforManageCar]);

  return (
    <Modal
      className={`${classes.Modal}`}
      open={props.open}
      onClose={props.onCloseHandle}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper className={`${classes.Paper}`}>
        <div className={`${classes.partOfTitle}`}>
          <DriveEtaIcon className={`${classes.IconCar}`} />
          <Typography className={`${classes.Title}`}>ประเมินความพึงพอใจ</Typography>
        </div>
        <form onSubmit={handleSubmit(onClickSubmit)}>
          <Grid container spacing={3}>

            <Grid item xs={12}>
            <Typography component="legend">ความพึงพอใจ</Typography>
              <Rating
                name="simple-controlled"
                value={value}
                sx={{
                  fontSize: "3rem"
              }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="comment"
                label="เขียนรีวิวเพิ่มเติม"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                inputProps={{
                  ...register("comment"),
                }}
              />
            </Grid>
          </Grid>
          <div className={`${classes.partOfNavButton}`}>
            <Button className={`${classes.Button}`} type="submit">
              ตกลง
            </Button>
          </div>
        </form>
      </Paper>
    </Modal>
  );
};

export default ModalRating;
