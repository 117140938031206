import { combineReducers } from "redux";
import auth from "./auth";
import userProfile from "./user-profile";
import users from "./user";
import department from "./department";
import drivers from "./drivers";
import driverItem from "./driver-item";
import waitingFriendCars from "./waitingFriendCars";
import vehicles from "./vehicles";
import employees from "./employees";
import lentCars from "./lentCars";
import lentCar from "./lentCar";
import vehicletypes from "./vehicletypes";
import vehicleBrandsAndModels from "./vehicleBrandsAndModels";
import driverRouteDay from "./driverRouteDay";
import historyLentCars from "./historyLentCars";
import routes from "./routesDay";
import bookings from "./bookings";
import passengers from "./passengers";
import routeLine from "./routeLine";
import routeUser from "./routeUser";

import betweenSiteCar from "./bookings/betweenSiteCar";
import crossAreaCar from "./bookings/crossAreaCar";
import crossAreaCarPool from "./bookings/crossAreaCarPool";
import deliveryCar from "./bookings/deliveryCar";
import driverBooking from "./bookings/driverBooking";
import inAreaCar from "./bookings/inAreaCar";
import meetingRoom from "./bookings/meetingRoom";
import deliverySampleShuttleCar from "./bookings/deliverySampleShuttleCar";

import emergency from "./emergency";

const appReducer = combineReducers({
  auth,
  users,
  userProfile,
  department,
  drivers,
  driverItem,
  vehicles,
  employees,
  lentCars,
  lentCar,
  vehicletypes,
  vehicleBrandsAndModels,
  driverRouteDay,
  historyLentCars,
  routes,
  bookings,
  passengers,
  routeLine,
  routeUser,
  betweenSiteCar,
  crossAreaCar,
  crossAreaCarPool,
  deliveryCar,
  driverBooking,
  inAreaCar,
  meetingRoom,
  deliverySampleShuttleCar,
  emergency,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
