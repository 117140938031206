import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { styled } from '@mui/styles';
import {
  Typography, Card, Avatar, IconButton, 
} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { getDriverProfile } from "../../../../actions/driver";
import { getDriverRouteDayByIdDriver } from "../../../../actions/driverRouteDay";

const DivCoverReturn = styled("div")({
  width: "100%",
  height: "calc(100% - 60px)",
  paddingTop: "60px",
  paddingLeft: "0",
  paddingRight: "0",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems:"center",
});

const BackgroundImage = styled("img")({
  position: "absolute",
  width: "100%",
  height: "calc(100% - 60px)",
  opacity: "0.3",
  objectFit:"cover"
});

const CardStyled = styled(Card)(({theme})=>({
  width: "80%",
  height: "80%",
  backgroundColor: "#03256C",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent:"center",
  position: "absolute",
  border: "5px solid white",
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  },
}));

const ImageCar = styled(Avatar)(({theme})=>({
  width: "300px",
  height: "300px",
  backgroundColor: "white",
  marginBottom: "20px",
  border: "5px solid white",
  "& img": {
    objectPosition: "-10px 6px",
  },
  [theme.breakpoints.down('sm')]: {
    width: "250px",
    height: "250px",
  },
}));

const Text = styled(Typography)(({theme})=>({
  textAlign: "center",
  fontSize: "35px",
  color: "white",
  [theme.breakpoints.down('sm')]: {
    fontSize: "25px",
  },
}));

const NavButton = styled("div")(({theme})=>({
  width: "100%",
  marginTop: "30px",
  display: "flex",
  justifyContent: "space-between",
  "& .iconButtonCorrect":{
    backgroundColor: "green",
    marginLeft: "30%",
    '&:hover': {
      backgroundColor:"green",
    },
    [theme.breakpoints.down("sm")]:{
      marginLeft: "0",
    },
  },
  "& .iconButtonWrong": {
    backgroundColor: "red",
    marginRight: "30%",
    '&:hover': {
      backgroundColor:"red",
    },
    [theme.breakpoints.down("sm")]:{
      marginRight: "0",
    },
  },
  [theme.breakpoints.down("sm")]:{
    justifyContent: "space-around",
  },
}));

const IconButtonStyled = styled(IconButton)({
  width: "80px",
  height: "80px",
  color: "white",
  border: "5px solid white",
  borderRadius: "50%",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  "& .Icon":{
    fontSize: "40px",
  },
});

const AuthenticationCar = () => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: Driver } = useSelector((state) => state.driverRouteDay);

  useEffect(() => {
    if(!Driver){
      dispatch(getDriverRouteDayByIdDriver(currentUser.idUser,"2022-08-27"));
      // dispatch(getDriverRouteDayByIdDriver(currentUser.id,"2022-05-30"));
    }
    dispatch(getDriverProfile(currentUser.idUser));
  }, []);

  return (
    <DivCoverReturn className="page">
      <BackgroundImage src={'./assets/background/carpark_authencar.jpg'} alt="backgroundImage" />
      {Driver && (
        <CardStyled>
          <ImageCar
            alt={Driver.vehiclePlateNo}
            src={`${process.env.REACT_APP_URL}image/car/${Driver.vehicleImagePath}`}
          />
          <div>
            <Text>
              {`ป้ายทะเบียนรถ : ${Driver.vehiclePlateNo}`}
            </Text>
            <Text>
              {`บริษัท : ${Driver.vehicleVendor}`}
            </Text>
          </div>
          <NavButton>
            <IconButtonStyled
              className="iconButtonCorrect"
              component={NavLink}
              to="/bedtimelastnight"
              size="large">
                <DoneIcon className="Icon" />
            </IconButtonStyled>
            <IconButtonStyled
              className="iconButtonWrong"
              component={NavLink}
              to="/chosecar"
              size="large">
                <CloseIcon className="Icon" />
            </IconButtonStyled>
          </NavButton>
        </CardStyled>
      )}
    </DivCoverReturn>
  );
};

export default AuthenticationCar;

