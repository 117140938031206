import { httpClient } from "./httpClient";

const getAllVehicles = () => {
  return httpClient.get("vehicles");
};

const getVehiclesByUseFor = (UseFor) => {
  return httpClient.get("vehicle_by_usefor/" + UseFor);
};

const getVehicleById = (id) => {
  return httpClient.get("vehicle_by_id/" + id);
};
const postNewVehicle = (NewVehicle) => {
  return httpClient.post("post_new_vehicle/", [NewVehicle]);
};

const postEditVehicle = (EditVehicle, id) => {
  return httpClient.post("post_edit_vehicle/", [EditVehicle, id]);
};

const postDeleteVehicle = (id) => {
  return httpClient.post("post_delete_vehicle/", [id]);
};

export default {
  getAllVehicles,
  getVehiclesByUseFor,
  getVehicleById,
  postNewVehicle,
  postEditVehicle,
  postDeleteVehicle,
};
