import React from "react";
import { styled, Typography } from "@mui/material";
import Form from "./components/Form";

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const NewTechnicianPage = (props) => {
  const onSuccessHandler = () => {
    props.history.push("/admin/technicians");
  };

  return (
    <div className="page">
      <Root>
        <Typography
          fontSize={24}
          marginTop={2}
          marginBottom={2}
          fontWeight={500}
          align="center"
        >
          ลงทะเบียนช่างใหม่
        </Typography>
        <Form onSuccessHandler={onSuccessHandler} />
      </Root>
    </div>
  );
};

export default NewTechnicianPage;
