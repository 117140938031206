import React from "react";
import { Grid, Typography, Chip, Avatar, Stack } from "@mui/material";
import Card from "../Card";
import dayjs from "dayjs";
import "dayjs/locale/th";

const DetailTab = ({ data, technician }) => {
  console.log(data);
  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12} sm={4}>
        <Card
          title="วันที่ดำเนินการ"
          style={{ height: "100%", boxSizing: "border-box" }}
        >
          <Typography fontSize={24}>
            {dayjs(data[0].startDate)
              .locale("th")
              .format("DD MMM YYYY (HH.mmน.)")}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card
          title="สถานที่"
          style={{ height: "100%", boxSizing: "border-box" }}
        >
          <Typography>{data[0].location}</Typography>
          <Typography fontSize={14}>({data[0].locationDetail})</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card title="สถานะ" style={{ height: "100%", boxSizing: "border-box" }}>
          {parseInt(data[0].status) === 1 && (
            <Typography fontSize={24}>
              <i className="far fa-clock"></i> กำลังรออนุมัติ...
            </Typography>
          )}
          {parseInt(data[0].status) === 2 && (
            <Typography fontSize={24}>
              <i className="fad fa-spinner"></i> กำลังดำเนินการ
            </Typography>
          )}
          {parseInt(data[0].status) === 3 && (
            <Typography fontSize={24} color="#43a047 !important">
              <i className="fas fa-check-circle"></i> ดำเนินการเสร็จสิ้น
            </Typography>
          )}
          {parseInt(data[0].status) === 4 && (
            <Typography fontSize={24} color="#e53935 !important">
              <i className="fas fa-times-circle"></i> ไม่อนุมัติ
            </Typography>
          )}
          {parseInt(data[0].status) === 5 && (
            <Typography fontSize={24} color="#e53935 !important">
              <i className="fas fa-trash"></i> ยกเลิก
            </Typography>
          )}
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title="รายละเอียดของงาน">
          <div>
            <Typography
              display="inline-block"
              style={{ marginRight: 4 }}
            >{`ประเภท:`}</Typography>
            {parseInt(data[0].maintenanceType) === 1 && (
              <Typography fontSize={20} display="inline-block">
                <i className="fas fa-wrench"></i> ซ่อมแซมทั่วไป
              </Typography>
            )}
            {parseInt(data[0].maintenanceType) === 2 && (
              <Typography
                fontSize={20}
                color="#f8c243 !important"
                display="inline-block"
              >
                <i className="fas fa-bolt"></i> งานไฟฟ้า
              </Typography>
            )}
            {parseInt(data[0].maintenanceType) === 3 && (
              <Typography
                fontSize={20}
                color="cornflowerblue !important"
                display="inline-block"
              >
                <i className="fas fa-tint"></i> งานประปา
              </Typography>
            )}
          </div>
          <Typography marginTop={2}>{data[0].description}</Typography>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title="ไฟล์ที่แนบ">
          <div>
            {data[0].fileUrl === undefined && (
              <Typography>ไม่พบไฟล์แนบ</Typography>
            )}
            {data[0].isExp === 1 && <Typography>ไฟล์แนบหมดอายุ</Typography>}
            {data[0].fileUrl !== undefined &&
              data[0].isExp !== 1 &&
              data[0].fileUrl.map((file, index) => (
                <Chip
                  className="chipFile"
                  key={`file_${index}`}
                  label={`image_${index + 1}`}
                  component="a"
                  href={`${file.file}`}
                  target="_blank"
                />

                // <Chip className="chipFile" key={`file_${index}`} label={file.fileName} component="a" href={`${process.env.REACT_APP_URL}file/maintenance/${file.path}`} target="_blank"/>
                // <Typography fontSize={24}>{file.filename}</Typography>
              ))}
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          title="ผู้แจ้งซ่อมแซม"
          style={{ height: "100%", boxSizing: "border-box" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography fontSize={14} color="#9e9e9e!important">
                ชื่อ-นามสกุล
              </Typography>
              <Typography fontSize={18}>{data[0].requestorName}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontSize={14} color="#9e9e9e!important">
                เบอร์โทร
              </Typography>
              <Typography fontSize={18}>{data[0].phoneNumber}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontSize={14} color="#9e9e9e!important">
                Email
              </Typography>
              <Typography fontSize={18}>{data[0].email}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontSize={14} color="#9e9e9e!important">
                บริษัท
              </Typography>
              <Typography fontSize={18}>{data[0].company}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontSize={14} color="#9e9e9e!important">
                หน่วยงาน
              </Typography>
              <Typography fontSize={18}>{data[0].agency}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontSize={14} color="#9e9e9e!important">
                Cost center
              </Typography>
              <Typography fontSize={18}>{data[0].costCenter}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontSize={14} color="#9e9e9e!important">
                Cost element
              </Typography>
              <Typography fontSize={18}>{data[0].costElement}</Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {data.technician === undefined && (
        <Grid item xs={12} md={6}>
          <Card title="ช่างผู้ดูแลงาน">
            <Stack spacing={2}>
              {data.technician.map((t, i) => (
                <Stack direction="row" spacing={2}>
                  {t.fileUrl ? (
                    // <Avatar src={`${process.env.REACT_APP_URL}image/maintenance/technician/${t.image}`} style={{width: 100, height: 100}}/>
                    <Avatar
                      src={t.fileUrl}
                      style={{ width: 100, height: 100 }}
                    />
                  ) : (
                    // <Typography fontSize={18}>{t.fileUrl}</Typography>

                    <Avatar style={{ width: 100, height: 100 }} />
                  )}
                  <Stack direction="column" spacing={1}>
                    <div>
                      <Typography fontSize={14} color="#9e9e9e!important">
                        ชื่อ-นามสกุล
                      </Typography>
                      <Typography fontSize={18}>{t.fullName}</Typography>
                    </div>
                    <div>
                      <Typography fontSize={14} color="#9e9e9e!important">
                        เบอร์โทร
                      </Typography>
                      <Typography fontSize={18}>{t.phoneNumber}</Typography>
                    </div>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Card>
        </Grid>
      )}

      {data?.technician.length > 0 && (
        <Grid item xs={12} md={6}>
          <Card title="ช่างผู้ดูแลงาน">
            <Stack spacing={2}>
              {data?.technician.map((t, i) => (
                <Stack direction="row" spacing={2}>
                  {t.fileUrl ? (
                    // <Avatar src={`${process.env.REACT_APP_URL}image/maintenance/technician/${t.image}`} style={{width: 100, height: 100}}/>
                    <Avatar
                      src={t.fileUrl}
                      style={{ width: 100, height: 100 }}
                    />
                  ) : (
                    <Avatar style={{ width: 100, height: 100 }} />
                  )}
                  <Stack direction="column" spacing={1}>
                    <div>
                      <Typography fontSize={14} color="#9e9e9e!important">
                        ชื่อ-นามสกุล
                      </Typography>
                      <Typography fontSize={18}>{t.fullName}</Typography>
                    </div>
                    <div>
                      <Typography fontSize={14} color="#9e9e9e!important">
                        เบอร์โทร
                      </Typography>
                      <Typography fontSize={18}>{t.phoneNumber}</Typography>
                    </div>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default DetailTab;
