import React, { Fragment, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { NavLink } from "react-router-dom";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  isActive: {
    color: "#00bb77",
    fontWeight: "700 !important",
    backgroundColor: "rgba(0,187,119,0.1)",
    borderRight: "5px solid #00bb77",
    "& *": {
      color: "inherit",
      fontWeight: "inherit",
    },
  },
  listItem: {
    maxHeight: 48,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  nestedOpen: {
    "& .MuiListItemIcon-root": {
      display: "flex",
      justifyContent: "center",
    },
  },
  nestedClose: {
    "& .MuiListItemIcon-root": {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
}));

const ListMenu = (props) => {
  const classes = useStyles();
  const [dataListMenu] = useState(props.dataListMenu);
  const [openCollapse, setOpenCollapse] = React.useState(-1);

  const handleClickCollapse = (index) => {
    if (openCollapse === index) {
      setOpenCollapse(-1);
    } else {
      setOpenCollapse(index);
    }
  };

  return (
    <List
      subheader={
        props.menuRole && (
          <ListSubheader style={{ backgroundColor: "#FFFFFF" }}>
            {props.menuRole}
          </ListSubheader>
        )
      }
    >
      {dataListMenu.map((value, index) => {
        return (
          <Fragment key={value.listKey}>
            {value.collapse ? (
              <Fragment>
                <ListItem
                  className={classes.listItem}
                  onClick={() => handleClickCollapse(index)}
                  key={value.listKey}
                >
                  <ListItemIcon>{value.listItemIcon}</ListItemIcon>
                  <ListItemText primary={value.listItemText} />
                  {openCollapse === index ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={openCollapse === index}
                  timeout="auto"
                  unmountOnExit
                >
                  <List>
                    {value.collapse.map((collapse) => {
                      return (
                        <ListItem
                          component={NavLink}
                          activeClassName={classes.isActive}
                          to={collapse.listLink}
                          button
                          key={collapse.listKey}
                          className={` ${classes.listItem} ${
                            props.open
                              ? classes.nestedOpen
                              : classes.nestedClose
                          }
                          `}
                          onClick={props.onClickList}
                        >
                          <ListItemIcon>{collapse.listItemIcon}</ListItemIcon>
                          <ListItemText primary={collapse.listItemText} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </Fragment>
            ) : (
              <ListItem
                className={classes.listItem}
                component={NavLink}
                activeClassName={classes.isActive}
                to={value.listLink}
                button
                key={value.listKey}
                onClick={props.onClickList}
              >
                <ListItemIcon>{value.listItemIcon}</ListItemIcon>
                <ListItemText primary={value.listItemText} />
              </ListItem>
            )}
          </Fragment>
        );
      })}
    </List>
  );
};

export default ListMenu;
