import React from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const FormPurpose = (props) => {
  return (
    <Grid
      container
      item
      spacing={3}
      xs={12}
      justifyContent="center"
      sx={(theme) => ({
        display: "flex",
        boxShadow:
          "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        border: "1px solid #EEEEEE",
        backgroundColor: "#FBFCFC",
        marginTop: "10px",
        marginBottom: "20px",
        padding: "0 25px 25px 0",

        borderRadius: "15px",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.down("md")]: {},
      })}
    >
      <Grid item xs={12}>
        <TextField
          placeholder="ระบุวัตถุประสงค์"
          label="วัตถุประสงค์"
          multiline
          rows={4}
          fullWidth
          inputProps={{ ...props.register("purpose") }}
          InputProps={{
            style: {
              borderRadius: "15px",
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FormPurpose;
