import {
    ROUTELINE_FETCHING,
    ROUTELINE_FAILED,
    ROUTELINE_SUCCESS,
    ROUTEUSER_FETCHING,
    ROUTEUSER_FAILED,
    ROUTEUSER_SUCCESS,
    ROUTES_FETCHING,
    ROUTES_FAILED,
    ROUTES_SUCCESS
} from "./types";

import RouteCrossAreaCarPoolService from "../services/routeCrossAreaCarPool.service";

export const getRouteLineByIdUserRouteDate = (data) => async (dispatch) => {
    try {
      dispatch({
        type: ROUTELINE_FETCHING,
      });
      const res = await RouteCrossAreaCarPoolService.getRouteLineByIdUserRouteDate(data);
      if (res) {
        dispatch({
          type: ROUTELINE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ROUTELINE_FAILED,
      });
    }
};

export const getRouteUserByIdUser = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ROUTEUSER_FETCHING,
    });
    const res = await RouteCrossAreaCarPoolService.getRouteUserByIdUser(data);
    // console.log("res : ",res)
    if (res) {
      dispatch({
        type: ROUTEUSER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ROUTEUSER_FAILED,
    });
  }
};

export const getRoutesByRouteLineAndRouteDate = (routeLine,routeDate) => async (dispatch) => {
  try {
    dispatch({
      type: ROUTES_FETCHING,
    });
    const res = await RouteCrossAreaCarPoolService.getRoutesByRouteLineAndRouteDate(routeLine,routeDate);
    if (res) {
      dispatch({
        type: ROUTES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ROUTES_FAILED,
    });
  }
};
export const postEditIdDriverRoute = (routeLine) => async (dispatch) => {
  try {
    dispatch({
      type: ROUTES_FETCHING,
    });
    const res = await RouteCrossAreaCarPoolService.postEditIdDriverRoute(routeLine);
    if (res) {
      dispatch({
        type: ROUTES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ROUTES_FAILED,
    });
  }
};

export const deleteRouteCrossPools = (routeLine) => async (dispatch) => {
  try {
    dispatch({
      type: ROUTES_FETCHING,
    });
    const res = await RouteCrossAreaCarPoolService.deleteRouteCrossPools(routeLine);
    if (res) {
      dispatch({
        type: ROUTES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ROUTES_FAILED,
    });
  }
};