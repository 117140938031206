import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {
  Typography,
  Paper,
  Tabs,
  Tab,
  Stepper,
  Divider,
  Step,
  StepButton,
  StepLabel,
  StepContent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useMediaQuery from "@mui/material/useMediaQuery";

import routeServices from "../../../../services/route.service";
import employeeService from "../../../../services/employee.service";
import utils from "../../../../utils";

/* import * as utils from '../../../../src/utils/util';
import * as routeActions from "./../../../actions/route.action";
import * as employeeActions from "./../../../actions/employee.action";
import ReactGA from 'react-ga';
import routeReducer from "../../../reducers/route.reducer";
import * as dayjs from 'dayjs' */

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "fit-content",
    background: "#eee",
    paddingBottom: "30px",
    marginTop: "20px",
  },
  tabPanel: {
    backgroundColor: "#FFF",
  },
  cardRoute: {
    padding: 20,
  },
  lineStep: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
}));

const RoutesPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width: 768px)");
  const [tab, setTab] = React.useState(0);
  const [openDetail, setOpenDetail] = React.useState(false);
  const [routeReducer, setRouteReducer] = React.useState(null);
  const [listEmployee, setListEmployee] = React.useState(null);
  /* const userReducer = useSelector(({ userReducer }) => userReducer); */
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  useEffect(() => {
    /* ReactGA.initialize('UA-164134822-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        dispatch(routeActions.getAllRoute(1)) */
    //dispatch(employeeActions.getAllEmployee(props.history));

    routeServices.getAllRoutes().then((res) => {
      setRouteReducer(res.data);
    });

    employeeService.getAllEmployees().then((res) => {
      setListEmployee(res.data);
    });
  }, []);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleClickCalNewRoute = async (shift) => {
    /* const formData = {
            "site": tab == 0 ? 1 : 3,
            "shift": shift,
            "idCompany": userReducer.result.idCompany
        }  */
    /* let result = await dispatch(routeActions.calNewRoute(formData));
        if (result) {
            console.log(result);
        } */
  };

  const handleEmployeeProfile = async (username) => {
    setOpenDetail(true);
    /* let result = await employeeActions.doGetEmployeeProfile(username); */
    //console.log(result);
  };

  const printRoute = (tab, shift) => {
    var site = "";
    switch (tab) {
      case 0:
        site = 1;
        break;
      case 1:
        site = 3;
        break;
      case 2:
        site = 7;
        break;
      default:
      // code block
    }

    if (tab == 0) {
      const siteGroup = utils.groupBy(routeReducer, (route) => route.routeSite);
      const routeGroup = utils.groupBy(
        siteGroup.get(site),
        (route) => route.routeShift
      );
      const routeShift = utils.groupBy(
        routeGroup.get(shift),
        (route) => route.routeLine
      );
      if (routeShift) {
        const routes = [...routeShift.values()];
        return (
          <Fragment>
            {routes.map((route, index) => (
              <Fragment key={index}>
                <div className={classes.lineStep}>
                  <Typography
                    variant="subtitle1"
                    style={{ textAlign: "center", padding: 5 }}
                  >
                    สายที่ {index + 1}
                  </Typography>
                  <div style={{ width: "100%" }}>
                    <Stepper nonLinear alternativeLabel style={{ padding: 0 }}>
                      {route.map((item, index) => (
                        <Step key={index + 1} active={true}>
                          <StepButton
                            onClick={() => {
                              handleEmployeeProfile(item.user);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {item.routeTimeOptimized.substring(0, 5)}
                              </Typography>
                              <Typography variant="body2">
                                {
                                  listEmployee.find(
                                    (employee) =>
                                      employee.idUser === item.idUser
                                  )?.firstname_TH
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                style={{ color: "#9e9e9e" }}
                              >
                                {
                                  listEmployee.find(
                                    (employee) =>
                                      employee.idUser === item.idUser
                                  )?.departmentName
                                }
                              </Typography>
                            </div>
                          </StepButton>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </div>
              </Fragment>
            ))}
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              ยังไม่มีข้อมูล
            </Typography>
          </Fragment>
        );
      }
    } else {
      return (
        <Fragment>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            ยังไม่มีข้อมูล
          </Typography>
        </Fragment>
      );
    }
  };

  return (
    <div className={`page ${classes.root}`}>
      <div>
        <Typography variant="h2">Route optimization</Typography>
      </div>
      <div style={{ width: "95%", marginTop: 20 }}>
        <Paper style={{ flexGrow: 1, marginTop: 10, marginBottom: 10 }}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab style={{ fontSize: 16 }} label="SITE #1" />
            <Tab style={{ fontSize: 16 }} label="SITE #3" />
            <Tab style={{ fontSize: 16 }} label="SITE #7" />
          </Tabs>
        </Paper>
        <div className={classes.tabPanel}>
          <div className={classes.cardRoute}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div style={{ marginLeft: 10 }}>
                <div>
                  <Typography
                    variant="h2"
                    style={{ fontWeight: 400, lineHeight: 0.9 }}
                  >
                    A
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "#757575", letterSpacing: 5 }}
                  >
                    SHIFT
                  </Typography>
                </div>
                {/* {userReducer && <Button variant="outlined" onClick={() => handleClickCalNewRoute("A")} >คำนวนเส้นทางใหม่</Button>} */}
              </div>
              {routeReducer && (
                <Button
                  variant="outlined"
                  onClick={() => handleClickCalNewRoute("A")}
                >
                  คำนวนเส้นทางใหม่
                </Button>
              )}
            </div>
            <div style={{ width: "100%" }}>
              {routeReducer && listEmployee && printRoute(tab, "A")}
            </div>
          </div>
          <Divider />
          <div className={classes.cardRoute}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div style={{ marginLeft: 10 }}>
                <div>
                  <Typography
                    variant="h2"
                    style={{ fontWeight: 400, lineHeight: 0.9 }}
                  >
                    B
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "#757575", letterSpacing: 5 }}
                  >
                    SHIFT
                  </Typography>
                </div>
                {/*
                                    userReducer.result && <Button variant="outlined" onClick={() => handleClickCalNewRoute("B")} >คำนวนเส้นทางใหม่</Button>
                                */}
              </div>
              {routeReducer && (
                <Button
                  variant="outlined"
                  onClick={() => handleClickCalNewRoute("B")}
                >
                  คำนวนเส้นทางใหม่
                </Button>
              )}
            </div>
            <div style={{ width: "100%" }}>
              {routeReducer && listEmployee && printRoute(tab, "B")}
            </div>
          </div>
          <Divider />
          <div className={classes.cardRoute}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div style={{ marginLeft: 10 }}>
                <div>
                  <Typography
                    variant="h2"
                    style={{ fontWeight: 400, lineHeight: 0.9 }}
                  >
                    C
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "#757575", letterSpacing: 5 }}
                  >
                    SHIFT
                  </Typography>
                </div>
                {/*
                                    userReducer.result && <Button variant="outlined" onClick={() => handleClickCalNewRoute("C")} >คำนวนเส้นทางใหม่</Button>
                                */}
              </div>
              {routeReducer && (
                <Button
                  variant="outlined"
                  onClick={() => handleClickCalNewRoute("C")}
                >
                  คำนวนเส้นทางใหม่
                </Button>
              )}
            </div>
            <div style={{ width: "100%" }}>
              {routeReducer && listEmployee && printRoute(tab, "C")}
            </div>
          </div>
          <Divider />
          <div className={classes.cardRoute}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div style={{ marginLeft: 10 }}>
                <div>
                  <Typography
                    variant="h2"
                    style={{ fontWeight: 400, lineHeight: 0.9 }}
                  >
                    D
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "#757575", letterSpacing: 5 }}
                  >
                    SHIFT
                  </Typography>
                </div>
                {/*
                                    userReducer.result && <Button variant="outlined" onClick={() => handleClickCalNewRoute("D")} >คำนวนเส้นทางใหม่</Button>
                                */}
              </div>
              {routeReducer && (
                <Button
                  variant="outlined"
                  onClick={() => handleClickCalNewRoute("D")}
                >
                  คำนวนเส้นทางใหม่
                </Button>
              )}
            </div>
            <div style={{ width: "100%" }}>
              {routeReducer && listEmployee && printRoute(tab, "D")}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openDetail}
        onClose={handleCloseDetail}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"ข้อมูลส่วนตัวพนักงาน"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetail} color="primary">
            {" "}
            Disagree{" "}
          </Button>
          <Button onClick={handleCloseDetail} color="primary">
            {" "}
            Agree{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RoutesPage;
