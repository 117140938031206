import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  styled,
  Grid,
  Stack,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/th";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { getOptionMaintenanceTypes } from "../../../../../../utils/constant";
import DeleteModal from "../../../../shared/deleteModal";

const StyledItem = styled("div")(({ theme }) => ({
  padding: "0 24px",
  height: 70,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  minWidth: "680px",

  ["&:hover"]: {
    backgroundColor: "#e4e4e4",
  },
  ["& .MuiAvatar-root"]: {
    marginRight: 8,
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0 48px",
    height: 100,
    display: "flex",
    alignItems: "center",
    marginTop: "20px",

    ["&:hover"]: {
      backgroundColor: "#e4e4e4",
    },
    ["& .MuiAvatar-root"]: {
      marginRight: 8,
    },
  },
}));

const Item = ({ data, onClick, onClickDelete }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  return (
    <StyledItem onClick={onClick}>
      <Grid container>
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          // spacing={1}
          container
          alignItems="center"
          // justifyContent="center"
          // direction="column"
        >
          {data.fileUrl ? (
            // <Avatar src={`${process.env.REACT_APP_URL}image/maintenance/technician/${data.image}`}/>
            <Avatar src={`${data.fileUrl}`} />
          ) : (
            <Avatar />
          )}
          <Typography>{data.fullName}</Typography>
        </Grid>

        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          //   spacing={0}
          container
          alignItems="center"
        >
          {/* <Typography>{data.types.join(", ")}</Typography> */}
          <Typography>{data.types}</Typography>
        </Grid>

        <Grid
          item
          xs={3}
          md={3}
          lg={3}
          //   spacing={0}
          container
          direction="column"
        >
          {data?.upComingWork.length > 0 ? (
            <>
              <Typography fontSize={14}>
                {dayjs(new Date(data.upComingWork[0].startDate))
                  .locale("th")
                  .format("DD MMMM YYYY hh.mm น.")}
              </Typography>
              <Typography fontSize={14}>
                {
                  getOptionMaintenanceTypes().find(
                    (mt) =>
                      mt.value ===
                      parseInt(data.upComingWork[0].maintenanceType)
                  ).label
                }
              </Typography>
            </>
          ) : (
            <Typography fontSize={14}>ยังไม่รับงาน</Typography>
          )}
        </Grid>
        <Grid
          item
          xs={1}
          md={1}
          lg={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            component={Link}
            to={"/admin/edittechnician/" + data.idMaintenanceTechnicians}
          >
            <EditRoundedIcon />
          </IconButton>
          <IconButton color="error" onClick={() => setOpenDeleteDialog(true)}>
            <DeleteRoundedIcon />
          </IconButton>
        </Grid>
      </Grid>
      {openDeleteDialog && (
        <DeleteModal
          open={openDeleteDialog}
          onCloseDeleteModalHandle={() => setOpenDeleteDialog(false)}
          onClickDelete={() => {
            onClickDelete();
            setOpenDeleteDialog(false);
          }}
          label={"ลบช่างซ่อม"}
        />
      )}
    </StyledItem>
  );
};

export default Item;
