import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import makeStyles from '@mui/styles/makeStyles';
import {
    Accordion, AccordionSummary, AccordionDetails, Typography,  useMediaQuery
} from '@mui/material';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import HomeIcon from '@mui/icons-material/Home';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FlagIcon from '@mui/icons-material/Flag';
import EventSeatIcon from '@mui/icons-material/EventSeat';

import RegisterDate from "./registerDate";

require('dayjs/locale/th');
dayjs.locale('th');

const useStyles = makeStyles((theme) => ({
    AccordionSummary: {
      height: "120px",
      paddingLeft:"0",
      borderRadius:"5px",
      position:"relative",
      ["& .MuiAccordionSummary-content"]: {
        height: "100%",
      },
      [theme.breakpoints.down('md')]:{
        height: "150px",
      },
      [theme.breakpoints.down('sm')]:{
        height: "190px",
      },
    },
    partOfAccordionSummary: {
      width: "100%",
      display: "flex",
      justifyContent:"center",
      flexDirection:"column",
      [theme.breakpoints.down('md')]:{
        justifyContent:"flex-start",
        height:"100%",
      },
    },
    partOfTypePlan:{
      display:"flex",
      flexBasis:"110px",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
      borderRadius:"5px 0 0 5px",
      [theme.breakpoints.down('sm')]:{
        width:"90px",
        flexBasis:"initial",
        minWidth:"90px",
      },
    },
    IconType:{
      fontSize:"55px",
      color:"white",
      [theme.breakpoints.down('sm')]:{
        fontSize:"30px"
      },
    },
    TypeGoWork:{
      backgroundColor:"purple",
    },
    TypeBackHome:{
      backgroundColor:"#F067D9",
    },
    TextType:{
      color:"white",
      fontWeight:"bold",
      [theme.breakpoints.down('sm')]:{
        fontSize:"16px",
      },
    },
    partOfMainAccordionSummary:{
      width: "100%",
      height:"40px",
      display: "flex",
      alignItems: "flex-end",
      paddingLeft:"15px",
      marginBottom:"30px",
      [theme.breakpoints.down('lg')]:{
        display:"block"
      },
      [theme.breakpoints.down('md')]:{
        height:"fit-content",
        marginBottom:"0",
        marginTop:"5px",
      },
      [theme.breakpoints.down('sm')]:{
        marginBottom:"12px",
      },
    },
    TextAccordion: {
      fontSize: "18px",
    },
    TextPlace: {
      display:"flex",
      flexBasis: "30%",
    },
    TextDate: {
      display:"flex",
      alignItems:"center",
      flexBasis: "25%",
    },
    TextMarked:{
      fontSize:"22px",
    },
    TextGoWork:{
      color:"purple",
    },
    TextBackHome:{
      color:"#F067D9",
    },
    TextTime: {
      display:"flex",
      flexBasis: "20%",
    },
    TextNumber:{
      display:"flex",
      alignItems:"flex-end",
      position:"absolute",
      right:"60px",
      top:"13px",
      [theme.breakpoints.down('lg')]:{
        top:"30px",
      },
      [theme.breakpoints.down('md')]:{
        top:"70px",
      },
      [theme.breakpoints.down('sm')]:{
        position:'initial',
        marginTop:"-10px",
      },
    },
    TextHaveNumberSeats:{
      fontSize:"30px",
      color:"#2ECC71",
      marginLeft:"6px",
      marginRight:"6px",
      marginBottom:"-7px",
    },
    Icon:{
      marginRight:"5px",
    },
    createdDate:{
      color:"#A6ACAF",
    },
    partOfSubAccordionSummary:{
      display:"flex",
      justifyContent:"flex-end",
    },
    AccordionDetails:{
      display:"flex",
      flexDirection:"column",
      [theme.breakpoints.down('lg')]:{
        padding:"0",
      },
    },
    partOfDetailAccordionSummary:{
      display:"flex",
      flexBasis:"70%",
      [theme.breakpoints.down('md')]:{
        display:"block",
      },
    },
    BorderPurple:{
      border:"1px solid",
      borderColor:"rgb(128,0,128,0.5)",
      borderRadius:"5px"
    },
    BorderPink:{
      border:"1px solid",
      borderColor:"rgb(240,103,217,0.5)",
      borderRadius:"5px"
    },
}));

const CardRegisterDate = props => {
    const classes = useStyles();

    const MobileResponse = useMediaQuery('(max-width:600px)');

    const convertDate = (lawStartDate, lawEndDate) => {
      let startDateSetTime = new Date(lawStartDate).setHours(0,0,0,0);
      let endDateSetTime = new Date(lawEndDate).setHours(0,0,0,0);
      if(startDateSetTime === endDateSetTime){
        return dayjs(startDateSetTime).format("DD MMM YYYY");
      }
      else{
        let startDate = dayjs(startDateSetTime).format("DD");
        return `${startDate}-${dayjs(endDateSetTime).format("DD MMM YYYY")}`
      }
    };

    const getDuration = (startDate, endDate) => {
      let duration = dayjs(endDate).diff(dayjs(startDate), "day");
      return duration+1;
    };

    return(
        <Accordion 
          className={
            clsx({ 
              [classes.BorderPurple]:props.register.type === "GoToWork", 
              [classes.BorderPink]:props.register.type !== "GoToWork" 
            })}>
            {console.log(props.register)}
            <AccordionSummary
                className={`${classes.AccordionSummary}`}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div className={clsx(classes.partOfTypePlan,
                { [classes.TypeGoWork] : props.register.type === "GoToWork",
                    [classes.TypeBackHome] : props.register.type !== "GoToWork"
                })}>
                {
                    props.register.type === "GoToWork" ? 
                    <EmojiTransportationIcon className={`${classes.IconType}`}/> :
                    <HomeIcon className={`${classes.IconType}`}/>
                }
                <Typography className={`${classes.TextType}`}>
                    {props.register.type === "GoToWork" ? "GOWORK" : "BACKHOME"}
                </Typography>
                </div>
                <div className={`${classes.partOfAccordionSummary}`}>
                <div className={`${classes.partOfMainAccordionSummary}`}>
                    <div className={`${classes.TextDate}`}>
                        <CalendarTodayIcon className={`${classes.Icon}`}/>
                        <Typography
                            className={
                            clsx(classes.TextAccordion,classes.TextMarked,
                                { [classes.TextGoWork] : props.register.type === "GoToWork",
                                [classes.TextBackHome] : props.register.type !== "GoToWork"
                                })}
                        >
                        {convertDate(props.register.startDate, props.register.endDate)}
                        </Typography>
                    </div>
                    <div className={`${classes.partOfDetailAccordionSummary}`}>
                        <div className={`${classes.TextTime}`}>
                            <ScheduleIcon className={`${classes.Icon}`}/>
                            <Typography
                                className={`${classes.TextAccordion}`}
                                noWrap
                            >
                            {dayjs(props.register.time).format("HH.mm น.")}
                            </Typography>
                        </div>
                        <div className={`${classes.TextPlace}`}>
                            <LocationOnIcon className={`${classes.Icon}`}/>
                            <Typography
                                className={`${classes.TextAccordion}`}
                                noWrap
                            >
                            {props.register.fromPlace}
                            </Typography>
                        </div>
                        <div className={`${classes.TextPlace}`}>
                            <FlagIcon className={`${classes.Icon}`}/>
                            <Typography
                                className={`${classes.TextAccordion}`}
                                noWrap
                            >
                            {props.register.toPlace}
                            </Typography>
                        </div>
                        {MobileResponse &&
                        <div className={`${classes.TextNumber}`}>
                            <EventSeatIcon className={`${classes.Icon}`}/>
                            <Typography className={`${classes.TextAccordion}`}>มีอยู่</Typography>
                            <Typography className={`${classes.TextHaveNumberSeats}`}>{props.register.numberOfPassenger}</Typography>
                            <Typography className={`${classes.TextAccordion}`}>คน</Typography>
                        </div>
                        }
                    </div>
                </div>
                <div className={`${classes.partOfSubAccordionSummary}`}>
                    <Typography className={`${classes.createdDate}`}>
                        {`สร้างวันที่ ${dayjs(props.register.createDate).format("DD MMMM YYYY")}`}
                    </Typography>
                </div>
                </div>
                {!MobileResponse &&
                <div className={`${classes.TextNumber}`}>
                    <EventSeatIcon className={`${classes.Icon}`}/>
                    <Typography className={`${classes.TextAccordion}`}>มีอยู่</Typography>
                    <Typography className={`${classes.TextHaveNumberSeats}`}>{props.register.numberOfPassenger}</Typography>
                    <Typography className={`${classes.TextAccordion}`}>คน</Typography>
                </div>
                }
            </AccordionSummary>
            <AccordionDetails className={`${classes.AccordionDetails}`}>
                {[...Array(getDuration(props.register.startDate,props.register.endDate))].map( 
                    (v,index) => {
                        return (
                            <RegisterDate 
                                key={props.register.id+""+index}
                                idRegister={props.register.id} 
                                date={props.register.startDate} 
                                type={props.register.type}
                                addDay={index} 
                            />
                        );
                    }
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default CardRegisterDate;