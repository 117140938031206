import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme)=>({
    DivCoverReturn:{
        width:"90%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        padding:"10px",
        borderRadius: "15px",
        marginTop: '20px',
    },
    Text: {
        fontSize: "35px",
        textAlign: "center",
    },
    Time: {
        marginLeft: "20px",
        fontWeight: "bold",
    },
}));

const CardShowStartStopTime = (props) => {
    const classes = useStyles();
    const { result: Driver } = useSelector(state => state.driverRouteDay);

    return (
        <div className={`${classes.DivCoverReturn}`}>
            <Typography className={`${classes.Text}`}>
                เวลาออกรถ : <span className={classes.Time}>{props.startTime}</span>
            </Typography>
            {Driver.endTime && Driver.duration &&
                <div>
                    <Typography className={`${classes.Text}`}>
                        เวลาหยุดรถ : <span className={classes.Time}>{props.endTime}</span>
                    </Typography>
                    <Typography className={`${classes.Text}`}>
                        เวลาที่ใช้ : <span className={classes.Time}>{props.duration}</span>
                    </Typography>
                </div>
            }
        </div>
    );
};

export default CardShowStartStopTime;