import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';

import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import FlagIcon from "@mui/icons-material/Flag";
import RoomIcon from "@mui/icons-material/Room";
import WorkIcon from "@mui/icons-material/Work";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import passengerFriendToFriendService from "../../../../../../services/passengerFriendToFriend.service";
// import WaitingFrinedCar from "../../../../../../services/waitingFriendCar.service";
import { getAllUsers } from "../../../../../../actions/user";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    position:"relative",
    ["@media only screen and (max-width: 375px)"]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  Title: {
    textAlign: "center",
    fontSize: "30px",
    marginBottom: "10px",
    [theme.breakpoints.down('sm')]:{
      marginTop: "40px",
    },
  },
  Card: {
    marginBottom: "20px",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent:"space-between",
    boxShadow: theme.shadows[3],
    ["@media only screen and (max-width: 1100px)"]: {
      display: "block",
    },
  },
  Avatar: {
    width: "100px",
    height: "100px",
    margin: "20px",
  },
  Name: {
    color: "#185ADB",
    fontSize: "25px",
  },
  TextAndIcon: {
    display: "flex",
    marginBottom: "5px",
    alignItems: "center",
  },
  Icon: {
    fontSize: "30px",
    marginRight: "5px",
  },
  IconButton: {
    marginRight: "5px",
  },
  ButtonRight: {
    marginRight: "10px",
  },
  Button: {
    height: "50px",
    width: "130px",
    fontSize: "20px",
    borderRadius: "25px",
  },
  ApproveButton: {
    color: "white",
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
  DisapproveButton: {
    color: "white",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "darkred",
    },
  },
  AvaterAndName: {
    display: "flex",
    [theme.breakpoints.down('sm')]:{
      flexDirection:"column",
      alignItems:"center",
    },
  },
  NameAndType: {
    width: "calc(100% - 140px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down('sm')]:{
      width: "calc(100% - 30px)",
      padding: "0 15px",
    },
  },
  GirdAvatarAndName: {
    paddingRight: "0 !important",
  },
  NavButton: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight:"20px",
    ["@media only screen and (max-width: 1100px)"]: {
      marginTop: "5px",
      height: "50px",
    },
    [theme.breakpoints.down('lg')]:{
      marginBottom:"10px",
    },
    [theme.breakpoints.down('sm')]:{
      justifyContent: "center",
      marginRight:"0",
    },
  },
  GridContainer: {
    width: "70%",
    ["@media only screen and (max-width: 1100px)"]: {
      width: "100%",
    },
  },
  GridPlace: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "15px",
    [theme.breakpoints.down('md')]:{
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down('sm')]:{
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  Text: {
    color: "#293A80",
  },
  DateAndTime: {
    ["@media (min-width: 700px) and (max-width: 780px)"]: {
      display: "flex",
    },
  },
  Position: {
    fontSize: "16px",
  },
  TextDate: {
    fontSize: "20px",
  },
  FromPlaceAndToPlace: {
    fontSize: "20px",
  },
  TextNotFound: {
    fontSize: "30px",
    textAlign: "center",
    color: "#A6ACAF",
  },
  NavButtonBack:{
    position:"absolute",
    width:"fit-content",
    top:"65px",
    left:"10px",
    zIndex:"10",
  },
  BackButton:{
    backgroundColor:"white",
    color:"black",
    ["&:hover"]:{
      backgroundColor:"white",
    },
  },
  BackIcon:{
    fontSize:"20px",
    color:"black",
    [theme.breakpoints.down('sm')]:{
      fontSize:"18px",
    },
  },
  TextBackButton:{
    fontSize:"20px",
    [theme.breakpoints.down('sm')]:{
      fontSize:"18px",
    },
  },
  DivFromPlaceAndToPlace:{
    flexBasis:"30%",
    [theme.breakpoints.down('md')]:{
      flexBasis:"50%",
    },
  },
}));

const ListApprove = (props) => {
  const classes = useStyles();
  const { user: CurrentUser } = useSelector((state) => state.auth);
  const [listOfOrder, setListOfOrder] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    passengerFriendToFriendService.getWaitingResponsePassengerFriendToFriendByIdDriver(CurrentUser.id).then(
      (res) => {
        if(res.data){
          setListOfOrder(res.data);
        }
      }
    ).catch(error => {
      setListOfOrder([]);
    })
  }, [refresh]);

  const onClickButton = (idOrder, result) => {
    if (result === "Approved") {
      setRefresh(!refresh);
    } else {
      setRefresh(!refresh);
    }
  };

  const onClickBackButton = () => {
    props.history.push("/friend2friend_Car/driver/listRegisterPage");
  };

  const convertDate = (lawStartDate, lawEndDate, lawtime) => {
    let startDateSetTime = new Date(lawStartDate).setHours(0,0,0,0);
    let endDateSetTime = new Date(lawEndDate).setHours(0,0,0,0);
    let time = dayjs(lawtime).format("HH.mm น.");
    if(startDateSetTime === endDateSetTime){
      return `${dayjs(startDateSetTime).format("DD MMM YYYY")}, ${time}`;
    }
    else{
      let startDate = dayjs(startDateSetTime).format("DD");
      return `${startDate}-${dayjs(endDateSetTime).format("DD MMM YYYY")}, ${time}`
    }
  };

  return (
    <div className={`page ${classes.DivCoverReturn}`}>
      <div className={`${classes.NavButtonBack}`}>
        <Button 
          className={`${classes.BackButton}`}
          onClick={onClickBackButton}
        >
          <ArrowBackIosIcon className={`${classes.BackIcon}`}/>
          <Typography className={`${classes.TextBackButton}`}>Back</Typography>
        </Button>
      </div>
      <Typography className={classes.Title}>รายการรออนุมัติ</Typography>
      {listOfOrder.length > 0 ? (
        listOfOrder.map((value) => {
          return (
            <Card className={`${classes.Card}`} key={value.id}>
              <Grid container className={classes.GridContainer}>
                <Grid item xs={12} md={6} className={classes.GirdAvatarAndName}>
                  <div className={classes.AvaterAndName}>
                    <Avatar
                      className={classes.Avatar}
                      src={`${process.env.REACT_APP_URL}image/profile/${value.user.image}`}
                      alt={value.user.image}
                    />
                    <div className={classes.NameAndType}>
                      <Typography className={classes.Name} noWrap>
                        {`${value.user.firstname} ${value.user.lastname}`}
                      </Typography>
                      <div className={`${classes.TextAndIcon}`}>
                        <WorkIcon className={`${classes.Icon}`} />
                        <Typography
                          className={`${classes.Position} ${classes.Text}`}
                          noWrap
                        >
                          {value.user.position}
                        </Typography>
                      </div>
                      <div className={`${classes.TextAndIcon}`}>
                        <CalendarTodayIcon className={`${classes.Icon}`} />
                        <Typography
                          className={`${classes.TextDate} ${classes.Text}`}
                        >
                          {convertDate(value.startDate,value.endDate)}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className={classes.GridPlace}>
                  <div className={`${classes.TextAndIcon} ${classes.DivFromPlaceAndToPlace}`}>
                    <RoomIcon className={`${classes.Icon}`} />
                    <Typography
                      className={`${classes.FromPlaceAndToPlace} ${classes.Text}`}
                      noWrap
                    >
                      {value.fromPlace}
                    </Typography>
                  </div>
                  <div className={`${classes.TextAndIcon}  ${classes.DivFromPlaceAndToPlace}`}>
                    <FlagIcon className={`${classes.Icon}`} />
                    <Typography
                      className={`${classes.FromPlaceAndToPlace} ${classes.Text}`}
                      noWrap
                    >
                      {value.toPlace}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <div className={classes.NavButton}>
                <Button
                  className={`${classes.ApproveButton} ${classes.ButtonRight} ${classes.Button}`}
                  onClick={() => onClickButton(value.id, "Approved")}
                >
                  <CheckIcon className={classes.IconButton} />
                  {` อนุมัติ`}
                </Button>
                <Button
                  className={`${classes.DisapproveButton} ${classes.Button} ${classes.ButtonRight}`}
                  onClick={() => onClickButton(value.id, "Rejected")}
                >
                  <ClearIcon className={classes.IconButton} />
                  {` ไม่อนุมัติ`}
                </Button>
              </div>
            </Card>
          );
        })
      ) : (
        <Typography className={`${classes.TextNotFound}`}>ไม่มีรายการรออนุมัติ</Typography>
      )}
    </div>
  );
};

export default ListApprove;
