import { httpClient } from "./httpClient";

const getAllCrossAreaCarBookingPassengers = () => {
    return httpClient.get("cross_area_car_booking_passengers");
};

const getCrossAreaCarBookingPassengerById = (id) => {
    return httpClient.get("cross_area_car_booking_passenger/"+id);
};

const getCrossAreaCarBookingPassengerByIdBooking = (id) => {
    return httpClient.get("cross_area_car_booking_passengerbyIdbooking/"+id);
};

const deleteCarCrossAreaCarBookingPassenger = (delCar) => {
    return httpClient.post("delete_car_cross_area_car_booking_passenger", delCar);
};

export default {
    getAllCrossAreaCarBookingPassengers,
    getCrossAreaCarBookingPassengerById,
    getCrossAreaCarBookingPassengerByIdBooking,
    deleteCarCrossAreaCarBookingPassenger
};