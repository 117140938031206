import React from "react";
import { Button, Stack, styled, Typography, Avatar } from "@mui/material";

import utils from "../../../../../utils";
import Modal from "../../../shared/Modal";

const Root = styled("div")(({theme})=>({
    padding: 16,
    width: 480,
    maxWidth: "100%",
    boxSizing: "border-box",
    "& .MuiAvatar-root": {
        marginRight: 4,
        width: 50,
        height: 50,
    },
    "& .foodContainer": {
        display: "grid",
        gridTemplateColumns: "calc(100% - 80px) auto",
    },
    "& .price": {
        color: "#2e7d32",
    }
}))

const FoodMenuModal = ({open, onClose, data}) => {
    return(
        <Modal open={open} onClose={onClose} disableFullscreen>
            <Root>
                <Stack spacing={2}>
                    <Typography>เมนูอาหาร</Typography>
                    {data.foods.map(food => (
                        <div key={`food_${food.idCateringFood}`} className="foodContainer">
                            <Stack direction="row">
                                <Avatar variant="square" src={`${food.fileUrl}`}/>
                                <Typography>{food.menuName}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="flex-end">
                                <Typography className="price">{`฿${utils.numberWithCommas((parseFloat(food.price)).toFixed(2))}`}</Typography>
                            </Stack>
                        </div>
                    ))}
                </Stack>
            </Root>
        </Modal>
    )
}

export default FoodMenuModal;