import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";

import { Button, Typography, Grid, Stack, Avatar } from "@mui/material";
import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";

import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ConfirmApproveModal from "./ConfirmApproveModal";
import ConfirmDisApproveModal from "./ConfirmDisApproveModal";
import StatusRequest from "../../../../../shared/StatusRequest";
import GridCard from "../../../../../shared/GridCard";

require("dayjs/locale/th");
dayjs.locale("th");

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "80px",
  height: "80px",
  [theme.breakpoints.down("lg")]: {
    width: "120px",
    height: "120px",
  },
}));

const GirdContent = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
    margin: "2.5px 0",
  },
}));
const Text = styled(Typography)({
  fontSize: "15px",
  fontWeight: 500,
});

const HighlightText = styled(Typography)({
  fontSize: "15px",
});
const HighlightTextName = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
});

const TextWithIcon = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  justifyContent: "center",
  "& .Icon": {
    marginRight: "5px",
    color: "#293A80",
  },
  "& .LocationIcon": {
    marginRight: "5px",
    color: theme.palette.error.main,
  },
  "& .CalendarIcon": {
    marginRight: "5px",
    color: theme.palette.primary.main,
  },
}));

const CardSampleRequest = ({ booking, onChangeStatusHandler, onClickCard }) => {
  const [openConfirmApproveModal, setOpenConfirmApproveModal] = useState(false);
  const [openConfirmDisApproveModal, setOpenConfirmDisApproveModal] =
    useState(false);

  return (
    <Grid item xs={12} sm={6} md={4} lg={12}>
      <GridCard>
        <Grid
          container
          item
          xs={12}
          md={12}
          lg={10.2}
          onClick={() => {
            onClickCard(booking.idNewMeetingRoomBooking);
          }}
        >
          <GirdContent item xs={12} md={12} lg={1.5}>
            <AvatarStyled
              src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
              alt="1"
            />
          </GirdContent>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={2.4}
            sx={(theme) => ({
              textAlign: "left",
            })}
          >
            <Grid item xs={12} md={12} lg={12}>
              <HighlightTextName>{booking.nameUser}</HighlightTextName>
              <Text>{`Tel. ${booking.phoneUser}`}</Text>
              <Text>{`Email. ${booking.emailUser}`}</Text>
            </Grid>
          </GirdContent>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={2.5}
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextWithIcon>
                <LocationOnRoundedIcon className="LocationIcon" />
                <Text>สถานที่</Text>
              </TextWithIcon>
              <HighlightText>{booking.nameMeetingRoom}</HighlightText>
            </Grid>
          </GirdContent>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={2.2}
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextWithIcon>
                <CalendarMonthRoundedIcon className="CalendarIcon" />
                <Text>วันที่จอง</Text>
              </TextWithIcon>
              <HighlightText>
                {new Date(booking.startDate).toLocaleDateString("th-TH", {
                  year: "2-digit", // แสดงปีเป็นสองหลัก
                  month: "short", // แสดงเดือนเป็นชื่อย่อ
                  day: "numeric",
                })}{" "}
                -{" "}
                {new Date(booking.endDate).toLocaleDateString("th-TH", {
                  year: "2-digit", // แสดงปีเป็นสองหลัก
                  month: "short", // แสดงเดือนเป็นชื่อย่อ
                  day: "numeric",
                })}
              </HighlightText>
              <HighlightText>{`${booking.timeStart} น.- ${booking.timeEnd} น.`}</HighlightText>
            </Grid>
          </GirdContent>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={2.2}
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextWithIcon>
                <PeopleAltRoundedIcon className="Icon" />
                <Text>จำนวนคน</Text>
              </TextWithIcon>
              <HighlightText>{booking.numOfPeople}</HighlightText>
            </Grid>
          </GirdContent>

          <GirdContent
            item
            xs={12}
            md={12}
            lg={1.2}
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <Text>สถานะ</Text>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {booking.statusApprove === "Success" && (
                  <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={1}
                    active={1}
                  />
                )}
                {booking.statusApprove === null && (
                  <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={null}
                    step={null}
                    active={1}
                  />
                )}
                {booking.statusApprove === "Reject" && (
                  <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={0}
                    active={1}
                  />
                )}
              </Grid>
            </Grid>
          </GirdContent>
        </Grid>

        <Grid container item xs={12} md={12} lg={1.8}>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={12}
            sx={{ textAlign: "center" }}
          >
            {booking.statusApprove === null && (
              <Grid item xs={12} md={12} lg={12}>
                <Button
                  className="cardButton"
                  variant="contained"
                  color="success"
                  size="small"
                  style={{
                    minWidth: "40px", // Adjust the size as needed
                    height: "40px", // Make it a square to ensure it's circular
                    borderRadius: "50%", // Make the button circular
                    padding: "0",
                    margin: "0 5px",
                  }}
                  sx={{
                    color: "white",
                    backgroundColor: "#4BB543", // สีเขียว
                    "&:hover": {
                      backgroundColor: "#43A047", // สีเขียวเข้มขึ้นเมื่อ hover
                    },
                  }}
                  onClick={() => {
                    setOpenConfirmApproveModal(true);
                  }}
                >
                  <i className="far fa-check" style={{ fontSize: "20px" }}></i>
                </Button>
                <Button
                  className="cardButton"
                  variant="contained"
                  color="error"
                  size="small"
                  style={{
                    minWidth: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    padding: "0",
                    margin: "0 5px",
                  }}
                  onClick={() => {
                    setOpenConfirmDisApproveModal(true);
                  }}
                >
                  <i className="far fa-times" style={{ fontSize: "20px" }}></i>
                </Button>
              </Grid>
            )}
          </GirdContent>
        </Grid>

        {openConfirmApproveModal && (
          <ConfirmApproveModal
            open={openConfirmApproveModal}
            onClose={() => {
              setOpenConfirmApproveModal(false);
            }}
            onChangeStatusHandler={onChangeStatusHandler}
            index={booking.idNewMeetingRoomBooking}
          />
        )}
        {openConfirmDisApproveModal && (
          <ConfirmDisApproveModal
            open={openConfirmDisApproveModal}
            onClose={() => {
              setOpenConfirmDisApproveModal(false);
            }}
            onChangeStatusHandler={onChangeStatusHandler}
            index={booking.idNewMeetingRoomBooking}
          />
        )}
      </GridCard>

      {/* {booking  && (
        <CardStyled>
         
          <>
            <GridContainer container>
              <Grid item xs={12} md={7} lg={2.5}>
                <DivAvatarAndName>
                  <AvatarStyled
                    src={`${process.env.REACT_APP_URL}image/profile/${booking.imageUser}`}
                    alt={booking.name}
                  />
                  <NameAndType>
                    <Text>{`ชื่อผู้จอง`}</Text>
                    <TextFirstnameAndLastName>
                      <Name>{booking.nameUser}</Name>
                    </TextFirstnameAndLastName>
                  </NameAndType>
                </DivAvatarAndName>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5}
                lg={1.5}
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: "15px" }}
              >
                <Grid item xs={12}>
                  <TextAndIcon style={{ marginRight: "40px" }}>
                    <Text>{`สถานที่`}</Text>
                  </TextAndIcon>
                  <TextFirstnameAndLastName>
                    <Name>{booking.nameMeetingRoom}</Name>
                  </TextFirstnameAndLastName>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5}
                lg={2.5}
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: "15px" }}
              >
                <Grid item xs={12}>
                  <TextAndIcon style={{ marginRight: "40px" }}>
                    <Text>{`วันที่`}</Text>
                  </TextAndIcon>
                  <TextFirstnameAndLastName>
                    <Name>
                      {new Date(booking.startDate).toLocaleDateString("th-TH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}  - {new Date(booking.endDate).toLocaleDateString("th-TH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })} 
                    </Name>
                  </TextFirstnameAndLastName>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5}
                lg={1.5}
                spacing={1}
                alignItems="center"
                style={{ paddingLeft: "10px" }}
              >
                <Grid item xs={12}>
                  <TextAndIcon style={{ marginRight: "20px" }}>
                    <Text>{`วันที่สิ้นสุด`}</Text>
                  </TextAndIcon>
                  <TextFirstnameAndLastName>
                    <Name>
                      {new Date(booking.endDate).toLocaleDateString("th-TH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })} 
                    </Name>
                  </TextFirstnameAndLastName>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5}
                lg={1.5}
                spacing={1}
                alignItems="center"
                style={{ paddingLeft: "15px" }}
              >
                <Grid item xs={12}>
                  <TextAndIcon style={{ marginRight: "20px" }}>
                    <Text>{`เวลาเริ่ม-สิ้นสุด`}</Text>
                  </TextAndIcon>
                  <TextFirstnameAndLastName>
                    <Name>
                    
                      {`${(booking.timeStart)} น.- ${(booking.timeEnd)} น.`} 
                    </Name>
                  </TextFirstnameAndLastName>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5}
                lg={0.8}
                spacing={1}
                alignItems="center"
                style={{ paddingLeft: "15px" }}
              >
                <Grid item xs={12}>
                  <TextAndIcon style={{ marginRight: "20px" }}>
                    <Text>{`จำนวนคน`}</Text>
                  </TextAndIcon>
                  <TextFirstnameAndLastName>
                    <Name>
                    
                      {(booking.numOfPeople)} 
                    </Name>
                  </TextFirstnameAndLastName>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5}
                lg={1}
                spacing={1}
                alignItems="center"
                style={{ paddingLeft: "15px" }}
              >
                <Grid item xs={12}>
                  <TextAndIcon style={{ marginRight: "20px" }}>
                    <Text>{`จำนวนเงิน`}</Text>
                  </TextAndIcon>
                  <TextFirstnameAndLastName>
                    <Name>
                    
                      {(booking.totalPrice)} 
                    </Name>
                  </TextFirstnameAndLastName>
                </Grid>
              </Grid>


              <Grid
                container
                item
                xs={10}
                md={5}
                lg={1.5}
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: "15px", marginRight: "50px" }}
              >
                <Grid item xs={10}>
                  <TextAndIcon style={{ marginRight: "30px" }}>
                    <Text>{`สถานะ`}</Text>
                  </TextAndIcon>
                  <TextFirstnameAndLastName>
                    <Name>{booking.status}</Name>
                    {(booking.statusApprove === null && booking.statusPayment === null) && (
                      <Name
                        style={{ color: "#d42121" }}
                      >รอการอนุมัติ</Name>
                    )}
                    {(booking.statusApprove != null && booking.statusPayment === null) && (
                      <Name
                        style={{ color: "#d42121" }}
                      >รอการชำระเงิน</Name>
                    )}
                     {(booking.statusApprove != null && booking.statusPayment != null) && (
                      <Name
                        style={{ color: "#24944f" }}
                      >อนุมัติเรียบร้อย</Name>
                    )}
                  
                  </TextFirstnameAndLastName>
                </Grid>
              </Grid>
              <ViewButton onClick={()=>onClickViewButton(props.currentaction.action)}>View</ViewButton>
              <Grid
                container
                item
                xs={10}
                md={5}
                lg={1}
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: "15px"}}
              >
                <Grid item xs={12}>
                  <ViewButton 
                    onClick={() => {
                      props.onClickCardHandler(booking.idDeliverySampleShuttle);
                    }}
                  >
                   แสดงรายละเอียด
                  </ViewButton>
                </Grid>
              </Grid>
            </GridContainer>
          </>
        </CardStyled>
      )} */}
    </Grid>
  );
};

export default CardSampleRequest;
