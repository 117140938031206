import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import { styled } from "@mui/styles";
import { Button, Typography } from "@mui/material";

import { Marker } from "@react-google-maps/api";
import {
  GoogleAPIkey,
  locationSite1,
} from "../../../../../utils/googleMapAssets";
import InitMap from "../../../shared/googleMap";
import MarkerDriver from "./MarkerDriver";

const DivCoverReturn = styled("div")({
  width: "100%",
  height: "calc(100% - 64px)",
  position: "relative",
  display: "flex",
});

const DetailCarButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.info.dark,
  padding: "5px 20px",
  position: "absolute",
  zIndex: "5",
  top: "75px",
  left: "11px",
  boxShadow: theme.shadows[3],
  fontSize: "20px",
  fontWeight: "bold",
  color: "white",
  "&:hover": {
    backgroundColor: theme.palette.info.dark,
  },
}));

const MapBetweenSite = (props) => {
  const location = useLocation();

  console.log("location : ", location);
  return (
    <DivCoverReturn className="page">
      <InitMap>
        <Marker
          position={location.state.data.data.gettingPlace}
          icon={{
            url:
              "data:image/svg+xml;charset=UTF-8," +
              encodeURIComponent(`
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="56px" height="56px">
                <path d="M12 2C8.69 2 6 4.69 6 8c0 3.53 3.87 7.61 5.47 9.19.3.3.77.3 1.06 0C14.13 15.61 18 11.53 18 8c0-3.31-2.69-6-6-6zm0 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
              </svg>
            `),
          }}
        />
        <Marker
          position={location.state.data.data.targetPlace}
          icon={{
            url:
              "data:image/svg+xml;charset=UTF-8," +
              encodeURIComponent(`
              <svg height="48" viewBox="0 0 1792 1792" width="48" xmlns="http://www.w3.org/2000/svg" fill="green">
  <path d="M1536 1536q0 63-61.5 113.5t-164 81-225 46-253.5 15.5-253.5-15.5-225-46-164-81-61.5-113.5q0-49 33-88.5t91-66.5 118-44.5 131-29.5q26-5 48 10.5t26 41.5q5 26-10.5 48t-41.5 26q-58 10-106 23.5t-76.5 25.5-48.5 23.5-27.5 19.5-8.5 12q3 11 27 26.5t73 33 114 32.5 160.5 25 201.5 10 201.5-10 160.5-25 114-33 73-33.5 27-27.5q-1-4-8.5-11t-27.5-19-48.5-23.5-76.5-25-106-23.5q-26-4-41.5-26t-10.5-48q4-26 26-41.5t48-10.5q71 12 131 29.5t118 44.5 91 66.5 33 88.5zm-384-896v384q0 26-19 45t-45 19h-64v384q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-384h-64q-26 0-45-19t-19-45v-384q0-53 37.5-90.5t90.5-37.5h384q53 0 90.5 37.5t37.5 90.5zm-96-384q0 93-65.5 158.5t-158.5 65.5-158.5-65.5-65.5-158.5 65.5-158.5 158.5-65.5 158.5 65.5 65.5 158.5z"/>
</svg>

            `),
          }}
        />

        <MarkerDriver inforInCar={location.state.data.data.driver} />
        {/* <MarkerGetting lat={locationSite1.lat} lng={locationSite1.lng} /> */}
        {/* <MarkerCar lat={12.692112500841047} lng={101.1339876326265} />
        <MarkerTarget lat={12.677341909437025} lng={101.13526948020574} /> */}
      </InitMap>
      <DetailCarButton onClick={() => props.history.push("/betweenSite_Car")}>
        รายละเอียดรถ
      </DetailCarButton>
    </DivCoverReturn>
  );
};

export default MapBetweenSite;
