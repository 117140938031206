import React, { useEffect, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarProvider, useSnackbar } from "notistack";
// import { useForm, Controller } from "react-hook-form";
// import makeStyles from '@mui/styles/makeStyles';
import { styled } from "@mui/styles";
import {
  Typography,
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Divider,
  Chip,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import SaveIcon from "@mui/icons-material/Save";
import RoomIcon from "@mui/icons-material/Room";

// import GoogleMap from "google-map-react";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";
import { GoogleAPIkey, locationSite1 } from "../../../../utils/googleMapAssets";
/* import GoogleMap from "google-map-react";
import { googleApiKey, location } from "./../../../constants/index"; */
import * as Yup from "yup";
import { Formik } from "formik";
/* import * as userActions from "./../../../actions/user.action";
import * as loginActions from "./../../../actions/login.action";
import ReactGA from 'react-ga'; */
import DialogEditLocation from "../../shared/dialog/DialogEditLocation";
// import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';

import { getUserProfile, updateLocationUser } from "../../../../actions/user";
import employeeService from "../../../../services/employee.service";

const placesLibrary = ["places"];

// const useStyles = makeStyles(theme => ({
//     addressProfile: {
//         marginTop: 10,
//         textalign: "left",
//         padding: 0
//     },
//     infoTxtProfile: {
//         textAlign: "center",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//     },
//     tagShift: {
//         marginTop: 5
//     },
//     rowField: {
//         display: "flex",
//         marginBottom: 10
//     },
//     ImgExampleMap:{
//         height:"100%",
//         width:"100%",
//     },
// }));

const Root = styled("div")({
  marginTop: 50,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  height: "fit-content",
  background: "#eee",
  minWidth: 400,
});

const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: "#fff",
}));

const TrackView = styled("div")({
  marginTop: 10,
  textalign: "center",
  width: "100%",
});

const InfoProfile = styled("div")({
  marginBottom: 25,
});

const ImageProfile = styled("img")({
  borderRadius: "50%",
});

const Title = styled(Typography)({
  fontSize: "bold",
  fontSize: "20px",
});

const RowField = styled(Grid)({
  display: "flex",
  marginBottom: 10,
});

const MapProfile = styled("div")({
  height: "40vh",
  width: "100%",
  marginTop: 10,
  "& .GPSIcon": {
    color: "red",
    fontSize: "30px",
    transform: "translate(-40%,-75%)",
  },
});

const AlertMessage = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  fontSize: "16px",
  color: "red",
});

const IdentifyProfilePage = (props) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { user: AuthUser } = useSelector((state) => state.auth);
  const userReducer = useSelector((state) => state.userProfile);
  const [locationLatLong, setLocationLatLong] = React.useState({
    Lat: locationSite1.lat,
    Long: locationSite1.lng,
  });
  /* const [locationLatLong, setLocationLatLong] = React.useState({ Lat: location.site1_lat, Long: location.site1_lng }); */
  const [openPinMap, setOpenPinMap] = React.useState(false);
  const [showInfoWindow, setShowInfoWindow] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GoogleAPIkey,
    libraries: placesLibrary,
    language: "th",
    region: "TH",
  });

  const center = {
    lat: parseFloat(
      locationLatLong.Lat === null ? locationSite1.lat : locationLatLong.Lat
    ),
    lng: parseFloat(
      locationLatLong.Long === null ? locationSite1.lng : locationLatLong.Long
    ),
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    dispatch(getUserProfile(AuthUser.idUser));
    if (userReducer.result) {
      formRef.current.values.latAddress = userReducer.result.latAddress;
      formRef.current.values.lngAddress = userReducer.result.lngAddress;
      setLocationLatLong({
        Lat: userReducer.result.latAddress,
        Long: userReducer.result.lngAddress,
      });
    }
    /* ReactGA.initialize('UA-164134822-1');
        ReactGA.pageview(window.location.pathname + window.location.search); */

    //dispatch(userActions.getUserProfile())
    /* if (!localStorage.getItem("username")) {
            dispatch(loginActions.logout(props.history));
            props.history.push("/");
        } */
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.values.latAddress = locationLatLong.Lat;
      formRef.current.values.lngAddress = locationLatLong.Long;
      formRef.current.handleSubmit();
    }
  };
  /* 
    const handleLocation = location => {
        setLocationLatLong({ Lat: location.lat, Long: location.lng });
        setOpenPinMap(false);
    }; */

  const handleOpenPinMap = () => {
    setOpenPinMap(true);
  };

  const handleClosePinMap = () => {
    setOpenPinMap(false);
  };

  const handleResponseLocation = (locationLatLong) => {
    if (formRef.current) {
      formRef.current.values.latAddress = locationLatLong.lat;
      formRef.current.values.lngAddress = locationLatLong.lng;
      const data = {
        idUser: AuthUser.idUser,
        Lat: locationLatLong.lat,
        Lng: locationLatLong.lng,
      };
      dispatch(updateLocationUser(data));
    }

    setLocationLatLong({ Lat: locationLatLong.lat, Long: locationLatLong.lng });

    setOpenPinMap(false);
  };

  const SignupSchema = Yup.object().shape({
    houseNo: Yup.string().required("Required"),
    villageNo: Yup.string().required("Required"),
    subDistrict: Yup.string().required("Required"),
    district: Yup.string().required("Required"),
    provience: Yup.string().required("Required"),
    areaCode: Yup.string().required("Required"),
  });

  const MarkerCustom = () => {
    return (
      <Fragment>
        <i className="fas fa-map-marker-alt GPSIcon"></i>
      </Fragment>
    );
  };

  const showForm = ({
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    handleBlur,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <RowField container spacing={1}>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              required
              id="houseNo"
              name="houseNo"
              label="บ้านเลขที่"
              onChange={handleChange}
              value={values.houseNo}
              onBlur={handleBlur}
              helperText={touched.houseNo ? errors.houseNo : ""}
              error={touched.houseNo && Boolean(errors.houseNo)}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={10}>
            <TextField
              fullWidth
              required
              id="villageNo"
              name="villageNo"
              label="หมู่ที่/หมู่บ้าน/ซอย"
              onChange={handleChange}
              value={values.villageNo}
              onBlur={handleBlur}
              helperText={touched.villageNo ? errors.villageNo : ""}
              error={touched.villageNo && Boolean(errors.villageNo)}
            ></TextField>
          </Grid>
        </RowField>
        <RowField container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              style={{ marginRight: 5 }}
              id="road"
              name="road"
              label="ถนน"
              onChange={handleChange}
              value={values.road}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              id="subDistrict"
              name="subDistrict"
              label="ตำบล"
              onChange={handleChange}
              value={values.subDistrict}
              onBlur={handleBlur}
              helperText={touched.subDistrict ? errors.subDistrict : ""}
              error={touched.subDistrict && Boolean(errors.subDistrict)}
            ></TextField>
          </Grid>
        </RowField>
        <RowField container spacing={1}>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="district"
              name="district"
              label="อำเภอ"
              onChange={handleChange}
              value={values.district}
              onBlur={handleBlur}
              helperText={touched.district ? errors.district : ""}
              error={touched.district && Boolean(errors.district)}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="provience"
              name="provience"
              label="จังหวัด"
              onChange={handleChange}
              value={values.provience}
              onBlur={handleBlur}
              helperText={touched.provience ? errors.provience : ""}
              error={touched.provience && Boolean(errors.provience)}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="areaCode"
              name="areaCode"
              label="รหัสไปรษณีย์"
              onChange={handleChange}
              value={values.areaCode}
              onBlur={handleBlur}
              helperText={touched.areaCode ? errors.areaCode : ""}
              error={touched.areaCode && Boolean(errors.areaCode)}
              fullWidth
            ></TextField>
          </Grid>
        </RowField>
        <div style={{ marginTop: "30px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Title>ปักหมุดที่อยู่</Title>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenPinMap}
              endIcon={<RoomIcon>pin</RoomIcon>}
            >
              ปักหมุด
            </Button>
          </div>
          <Divider style={{ margin: "5px 0 15px" }}></Divider>
          <RowField container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                style={{
                  marginRight: 5,
                  ["&:hover"]: {
                    backgroundColor: "red",
                  },
                }}
                id="lat"
                name="lat"
                label="ตำแหน่ง Lat"
                value={values.latAddress}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                style={{ marginRight: 5 }}
                id="long"
                name="long"
                label="ตำแหน่ง Long"
                value={values.lngAddress}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              ></TextField>
            </Grid>
          </RowField>
          {isLoaded && (
            <MapProfile>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
              >
                {locationLatLong.Lat !== null &&
                locationLatLong.Long !== null ? (
                  <Marker
                    position={{
                      lat: parseFloat(locationLatLong.Lat),
                      lng: parseFloat(locationLatLong.Long),
                    }}
                  />
                ) : (
                  <AlertMessage>ยังไม่ได้ระบุตำแหน่งที่อยู่อาศัย</AlertMessage>
                )}
              </GoogleMap>
            </MapProfile>
          )}

          {/* Dialog Edit Pin Map */}
          {openPinMap && (
            <DialogEditLocation
              handleClose={handleClosePinMap}
              selectemployee={{
                lat: formRef.current.values.latAddress,
                long: formRef.current.values.lngAddress,
              }}
              handleResponseLocation={handleResponseLocation}
              openLocation={openPinMap}
            />
          )}
        </div>
      </form>
    );
  };

  return (
    <Root>
      <Snackbar
        open={showSuccess}
        autoHideDuration={3000}
        onClose={() => {
          setShowSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSuccess(false);
          }}
          severity="success"
        >
          บันทึกข้อมูลเรียบร้อย
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={3000}
        onClose={() => {
          setShowError(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
        >
          เกิดข้อผิดพลาด ลองอีกครั้ง
        </Alert>
      </Snackbar>
      <BackdropStyled open={showLoading}>
        <CircularProgress color="inherit" />
      </BackdropStyled>
      {!userReducer.result ? (
        <BackdropStyled open={true}>
          <CircularProgress color="inherit" />
        </BackdropStyled>
      ) : (
        <div style={{ margin: "20px" }}>
          <TrackView>
            <InfoProfile>
              <Card>
                <CardContent
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <ImageProfile
                      height="150"
                      width="150"
                      src={`${process.env.REACT_APP_URL}image/profile/${userReducer.result.imageName}`}
                      alt={userReducer.result.firstname_TH}
                    />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Typography variant="h4" style={{ fontSize: 30 }}>
                      {`${userReducer.result.firstname_TH} ${userReducer.result.lastname_TH}`}
                    </Typography>
                    <Typography color="textSecondary" variant="h6">
                      {userReducer.result.departmentName}
                    </Typography>
                    {userReducer.result.shift && (
                      <Chip
                        color="primary"
                        label={`SHIFT ${userReducer.result.shift}`}
                        style={{
                          fontSize: 16,
                          width: "50%",
                          display: "flex",
                          margin: "0 auto",
                          marginTop: "10px",
                        }}
                      />
                    )}
                  </div>
                </CardContent>
              </Card>
            </InfoProfile>
            <InfoProfile>
              <Card style={{ width: "100%" }}>
                <CardContent style={{ padding: "25px" }}>
                  <Title>ที่อยู่</Title>
                  <Divider style={{ margin: "10px 0 15px" }}></Divider>
                  <Formik
                    innerRef={formRef}
                    onSubmit={async (values, { setSubmitting }) => {
                      console.log("formRef: ", formRef);
                      console.log("values: ", values);
                      employeeService
                        .postEditProfileEmployees(values)
                        .then((res) => {
                          if (res) {
                            enqueueSnackbar("Save Profile Success", {
                              variant: "success",
                            });
                            props.history.push("/profile");
                          } else {
                            enqueueSnackbar("Save Profile Error", {
                              variant: "error",
                            });
                          }
                        });

                      /* setShowLoading(true);
                                            let status = await dispatch(userActions.updateProfile(values))
                                            if (status) { */
                      // setShowSuccess(true);
                      /*     setShowLoading(false);
                                            } else {
                                                setShowError(true)
                                                setShowLoading(false);
                                            } */
                    }}
                    validationSchema={SignupSchema}
                    initialValues={userReducer.result}
                  >
                    {(props) => showForm(props)}
                  </Formik>
                </CardContent>
              </Card>
            </InfoProfile>
            <div>
              <Card style={{ width: "100%" }}>
                <CardContent style={{ padding: 16 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "auto" }}
                      // className={classes.button}
                      startIcon={<SaveIcon />}
                      onClick={handleSubmit}
                    >
                      บันทึกข้อมูล
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          </TrackView>
        </div>
      )}
    </Root>
  );
};

export default IdentifyProfilePage;
