import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { styled } from '@mui/styles';
import { 
    Modal, Paper, Button, TableRow, TableCell, Typography, Avatar,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

require('dayjs/locale/th');
dayjs.locale('th');

const AvatarStyled = styled(Avatar)(({theme})=>({
    width:"60px",
    height:"60px",
}));

const Text = styled(Typography)(({theme})=>({
    fontSize:"18px",
}));

const TextAndIcon = styled("div")(({theme})=>({
    display:"flex",
    justifyContent:"center",
}));

const StarIconStyled = styled(StarIcon)(({theme})=>({
    color:theme.palette.warning.light,
    marginRight:"5px",
}));

const ViewButton = styled(Button)({
    border:"1px solid black",
    color:"black",
});

const ModalStyled = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
});

const PaperStyled = styled(Paper)({
    display:"flex",
    flexDirection:"column",
    padding:"30px 40px",
    alignItems:"center",
});

const ContentProfile = styled("div")(({theme})=>({
    width:"calc(100% - 30px)",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginLeft:"30px",
}));

const AvatarModalStyled = styled(Avatar)(({theme})=>({
    width:"100px",
    height:"100px",
    marginBottom:"15px",
}));

const Name = styled(Typography)(({theme})=>({
    fontSize:"24px",
    fontWeight:"bold",
}));

const SubText = styled(Typography)(({theme})=>({
    fontSize:"20px",
    marginRight:"30px",
}));

const IconAndText = styled("div")(({theme})=>({
    display:"flex",
    alignItems:"center",
    "& .Icon":{
        marginRight:"10px"
    },
}));

const TableRowAndModal = props => {
    const [openModal, setOpenModal] = useState(false);

    const onClickViewButton = action => {
        if(action === "Register"){
            setOpenModal(true);
        }else{
            props.OnClickViewButtonAddCar();
        }
    };

    return(
        <>
            <TableRow>
                <TableCell><AvatarStyled src={`${process.env.REACT_APP_URL}image/profile/${props.currentaction.imagepath}`} /></TableCell>
                <TableCell><Text>{`${props.currentaction.firstname} ${props.currentaction.lastname}`}</Text></TableCell>
                <TableCell align="center"><Text>{dayjs(props.currentaction.date).format("D MMM YYYY")}</Text></TableCell>
                <TableCell align="center"><Text>{props.currentaction.location}</Text></TableCell>
                <TableCell align="center"><Text>{props.currentaction.mobileNumber}</Text></TableCell>
                <TableCell align="center"><TextAndIcon><StarIconStyled /><Text>{props.currentaction.pointLentCar}</Text></TextAndIcon></TableCell>
                <TableCell align="center"><Text>{props.currentaction.action}</Text></TableCell>
                <TableCell align="center"><ViewButton onClick={()=>onClickViewButton(props.currentaction.action)}>View</ViewButton></TableCell>
            </TableRow>
            <ModalStyled
                open={openModal}
                onClose={()=>setOpenModal(false)}
            >
                <PaperStyled>
                    <AvatarModalStyled src={`${process.env.REACT_APP_URL}image/profile/${props.currentaction.imagepath}`}/>
                    <ContentProfile>
                        <Name noWrap>{`${props.currentaction.firstname} ${props.currentaction.lastname}`}</Name>
                        <IconAndText>
                            <IconAndText><PlaceIcon className="Icon"/><SubText>{`${props.currentaction.province},${props.currentaction.district}`}</SubText></IconAndText>
                        </IconAndText>
                        <IconAndText>
                            <PhoneIcon className="Icon"/><SubText>{`${props.currentaction.mobileNumber}`}</SubText>
                        </IconAndText>
                        <IconAndText>
                            <EmailIcon className="Icon"/><SubText>{`${props.currentaction.email}`}</SubText>
                        </IconAndText>
                    </ContentProfile>
                </PaperStyled>
            </ModalStyled>
        </>
    );
};

export default TableRowAndModal;