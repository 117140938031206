import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import {
  getDeliverySampleshuttleByIdDriver,
  getDeliverySampleshuttleAll,
  getDeliverySampleshuttleByIdUser,
} from "../../../../actions/deliverySampleshuttle";

import CardSampleRequest from "./NewSample/CardSampleRequest";

import { styled } from "@mui/styles";

import { Button } from "@mui/material";
import { useEffect } from "react";
import deliverySampleshuttleService from "../../../../services/deliverySampleshuttle.service";
// import CardSampleDetail from './NewSample/';

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  Grid: {
    width: "80%",
    margin: "0 auto",
    marginTop: "20px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },
  Title: {
    textAlign: "center",
    fontSize: "25px",
    margin: "25px 0px",
  },
  CreateButton: {
    height: "50px",
    width: "200px",
    display: "flex",
    color: "white",
    fontSize: "18px",
    marginRight: "10px",
    backgroundColor: "#436ADC",
    ["&:hover"]: {
      backgroundColor: "#436ADC",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  IconImage: {
    weight: "26px",
    height: "26px",
    marginRight: "3px",
  },
}));

const NavTopButton = styled("div")(({ theme }) => ({
  height: "60px",
  // marginTop: "20px",
  "& .CurrentTable": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
}));

const DivCoverReturn = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  "& .ButtonTypeNotClicked": {
    backgroundColor: "#B2BABB ",
    color: "white",
    fontWeight: "bold",
    ["&:hover"]: {
      borderBottom: "0px",
      backgroundColor: "#D5DBDB",
    },
  },
}));

const Title = styled(Typography)({
  textAlign: "center",
  fontSize: "30px",
  marginBottom: "20px",
});

const ButtonType = styled(Button)({
  width: "180px",
  minWidth: "180px",
  height: "50px",
  fontSize: "16px",
  borderRadius: "4px 4px 0 0",
  zIndex: "10",
  backgroundColor: "#FBFCFC",
  border: "1px solid #EEEEEE",
  borderBottom: "0",
  marginBottom: "-2px",
  fontWeight: "bold",
  "&:hover": {
    borderBottom: "0px",
    backgroundColor: "#FBFCFC",
  },
});

const NavDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const AllCards = styled("div")({
  height: "calc(100% - 153px)",
  padding: "20px",
  marginBottom: "10px",
  paddingBottom: "0",
  paddingTop: "20px",
  border: "1px solid #EEEEEE",
  zIndex: "1",
  backgroundColor: "#FBFCFC",
});

const SpaceOfAllCards = styled("div")({
  height: "95%",
  padding: "5px 4px 0px 4px",
  overflowY: "auto",
});

const DriverCard = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const classes = useStyles();
  // const AllInput = watch();
  const dispatch = useDispatch();

  const [openCardBooking, setopenCardBooking] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listBooking, setListBooking] = useState(null);
  // const { result: listBooking } = useSelector((state) => state.bookings);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: Bookings } = useSelector((state) => state.bookings);
  const [showHistory, setHistory] = useState(0);

  const onClickCardHandler = (idBooking) => {
    props.history.push(`/sample/detail_booking`, { idBooking: idBooking });
  };

  const onClickNewList = () => {
    props.history.push(`/sampleshuttle/NewSample/formDetailFlight`);
  };

  const onClickHistory = () => {
    setHistory(1);
  };

  const onClickStatus = () => {
    setHistory(0);
  };

  const [CardDetail, setCardDetail] = useState([]);
  const [ListCardDetail, setListCardDetail] = useState([]);
  const [booking, setBooking] = useState(null);

  useEffect(async () => {
    deliverySampleshuttleService
      .getDeliverySampleshuttleByIdDriver(currentUser.idUser)
      .then((res) => {
        if (res.data) {
          setListBooking(res.data);
          setLoading(false);
        } else {
          setListBooking([]);
          setLoading(false);
        }
      });
  }, [showHistory, props.history]);
  console.log("listBooking");

  console.log(listBooking);
  return (
    <DivCoverReturn className="page">
      <Title>รายการจองรถรับส่งตัวอย่าง</Title>
      <NavTopButton>
        {showHistory === 0 && (
          <Button
            className={`${classes.CreateButton}`}
            onClick={onClickHistory}
          >
            <img
              className={`${classes.IconImage}`}
              src={"/assets/Image/Icon/clock_white.png"}
              alt="Logo"
            />
            ประวัติการทำรายการ
          </Button>
        )}
        {showHistory === 1 && (
          <Button className={`${classes.CreateButton}`} onClick={onClickStatus}>
            <img
              className={`${classes.IconImage}`}
              src={"/assets/Image/Icon/list.png"}
              alt="Logo"
            />
            สถานะรายการ
          </Button>
        )}
      </NavTopButton>
      <AllCards>
        <SpaceOfAllCards>
          {listBooking &&
            listBooking.length > 0 &&
            !loading &&
            listBooking.toReversed().map((booking) => {
              return (
                <CardSampleRequest
                  key={booking.id}
                  booking={booking}
                  showHistory={showHistory}
                  onClickCardHandler={onClickCardHandler}
                ></CardSampleRequest>
              );
            })}
          {listBooking && listBooking.length === 0 && !loading && (
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
              marginTop={20}
              fontSize={30}
            >
              ไม่พบรายการจอง
            </Typography>
          )}
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
              marginTop={20}
            >
              <CircularProgress disableShrink />
            </div>
          )}
        </SpaceOfAllCards>
      </AllCards>
    </DivCoverReturn>
  );
};

export default DriverCard;
