import React from "react";

import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";

import { GoogleAPIkey, locationSite1 } from "../../../../utils/googleMapAssets";

const placesLibrary = ["places"];

const InitMap = ({ children }, props) => {
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GoogleAPIkey,
    libraries: placesLibrary,
    language: "th",
    region: "TH",
  });
  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const defaultProps = {
    center: {
      lat: locationSite1.lat,
      lng: locationSite1.lng,
    },
    zoom: 10,
  };

  const Print = () => {
    var bounds = new window.google.maps.LatLngBounds();
    console.log(" bounds : ", bounds);
  };
  const mapOptions = {
    mapTypeControl: false, // Hide "Satellite" and "Roadmap" buttons
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={defaultProps.center}
          zoom={defaultProps.zoom}
          options={mapOptions}
        >
          {children}
        </GoogleMap>
      )}
    </div>
  );
};

export default InitMap;
