import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Avatar, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UnicornLoading from "../../assets/loading.gif";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
  DirectionsService,
  DirectionsRenderer,
  InfoWindow,
} from "@react-google-maps/api";
import SlideNextEmployee from "./slideNextEmployee";
import ButtonStartStopDriving from "./ButtonStartStopDriving";
import ButtonGoToPlanPage from "./ButtonGoToPlanPage";
import ButtonBlue from "../../shared/buttonBlue";
import { getRouteDayByIdDriver } from "../../../../actions/routeDay";
import { getRoutesByRouteLineAndRouteDate } from "../../../../actions/routeCrossAreaCarPool";
import { GoogleAPIkey, locationSite1 } from "../../../../utils/googleMapAssets";
import MarkerDriver from "./MarkerDriver";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import siteService from "../../../../services/site.service";
import betweenSiteCarService from "../../../../services/betweenSiteCar.service";
import { useSnackbar } from "notistack";
import SwipeableEdgeDrawer from "./SwipeableDrawer";
require("dayjs/locale/th");
dayjs.locale("th");
const placesLibrary = ["places"];
const DivCoverReturn = styled("div")(({ theme }) => ({
  height: "calc(100% - 64px)",
  width: "100%",
  position: "relative",
  "& .CloseSlide": {
    left: "34%",
    [theme.breakpoints.down("md")]: {
      right: "0",
      left: "initial",
    },
    [theme.breakpoints.down("sm")]: {
      left: "0",
    },
  },
  "& .OpenSlide": {
    left: "49%",
    [theme.breakpoints.down("md")]: {
      right: "0",
      left: "initial",
    },
    [theme.breakpoints.down("sm")]: {
      left: "0",
    },
  },
  "& .DivTextTimeNone": {
    display: "none",
  },
}));

const TextTime = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  color: "white",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

const PartOfMap = styled("div")({
  width: "100%",
  // height: "100%",
  height: "calc(100% - 32px)",
  position: "absolute",
  zIndex: "1",
});

const CloseButton = styled("div")({
  position: "absolute",
  right: "5px",
  borderRadius: "50%",
  backgroundColor: "#03256C",
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "3.5px solid rgb(233,215,0)",
});

const CloseIconStyled = styled(CloseIcon)({
  color: "white",
});

const DivTextTime = styled("div")(({ theme }) => ({
  position: "absolute",
  backgroundColor: "#03256C",
  padding: "10px 20px",
  border: "3.5px solid rgb(233,215,0)",
  borderTop: "0",
  borderRadius: "0 0 20px 20px",
  zIndex: "15",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 47px)",
  },
}));

const DivMarker = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "transparent",
  transform: "translate(-50%,-75%)",
  "& .MyLocation": {
    backgroundColor: "green",
    width: "135px",
  },
  "& .Car": {
    backgroundColor: theme.palette.primary.main,
    width: "30px",
  },
}));

const DivText = styled("div")(({ theme }) => ({
  padding: "5px 10px",
  border: "3px solid white",
  borderRadius: "30px",
  boxShadow: theme.shadows[3],
}));

const Text = styled(Typography)({
  color: "white",
  fontSize: "18px",
  fontWeight: "bold",
});

const GettingSpot = styled(PersonPinCircleIcon)({
  fontSize: "55px",
  color: "green",
});

const DivTextNoPassenger = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: "10",
  backgroundColor: theme.palette.primary.dark,
  padding: "20px",
  borderRadius: "0 0 20px 0",
}));

const TextNoPassenger = styled(Typography)({
  color: "white",
  fontSize: "32px",
});

const MarkerCar = () => {
  return (
    <DivMarker>
      <i
        className="fad fa-car"
        style={{ fontSize: "40px", color: "blue", marginTop: "3px" }}
      ></i>
    </DivMarker>
  );
};

const MarkerGetting = () => {
  return (
    <DivMarker>
      <DivText className="MyLocation">
        <Text>ผู้โดยสารคนถัดไป</Text>
      </DivText>
      <GettingSpot />
    </DivMarker>
  );
};

const MapPage = (props) => {
  const dispatch = useDispatch();
  const { result: Driver } = useSelector((state) => state.driverRouteDay);
  const { result: RouteOfDriver } = useSelector((state) => state.routes);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [openSlide, setOpenSlide] = useState(true);
  const [openStartTimeBar, setOpenStartTimeBar] = useState(true);
  const [isLocationDriver, setLocationDriver] = useState(null);
  const [siteFrom, setSiteFrom] = useState(null);
  const [siteTo, setSiteTo] = useState(null);
  const [Booking, setBooking] = useState(null);
  const [load, setLoad] = useState(false);
  const [openInfoSiteFrom, setOpenInfoSiteFrom] = useState(false);
  const [openInfoSiteTo, setOpenInfoSiteTo] = useState(false);
  const [updateBook, setUpdateBook] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const placesLibrary = ["places"];
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GoogleAPIkey,
    libraries: placesLibrary,
    language: "th",
    region: "TH",
  });
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    if (currentUser) {
      setLoad(true);
      siteService
        .getLocationDriverById(currentUser.idUser)
        .then((res) => {
          if (res.data) {
            setLocationDriver([
              { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
            ]);
          }
        })
        .catch((error) => {
          setLocationDriver([{ Lat: 0, Long: 0 }]);
        });
      betweenSiteCarService
        .getBetweenSiteCarByIdDriverIsNotFinish(currentUser.idUser)
        .then((res) => {
          if (res.data) {
            setSiteFrom(res.data[0].fromPlace);
            setSiteTo(res.data[0].toPlace);
            setBooking(res.data[0]);
            setLoad(false);
          }
        })
        .catch((error) => {
          setSiteFrom(null);
          setSiteTo(null);
          setBooking(null);
          setLoad(false);
        });
    }
  }, []);

  useEffect(() => {
    betweenSiteCarService
      .getBetweenSiteCarByIdDriverIsNotFinish(currentUser.idUser)
      .then((res) => {
        if (res.data) {
          setSiteFrom(res.data[0].fromPlace);
          setSiteTo(res.data[0].toPlace);
          setBooking(res.data[0]);
          setLoad(false);
        }
      })
      .catch((error) => {
        setSiteFrom(null);
        setSiteTo(null);
        setBooking(null);
        setLoad(false);
      });
  }, [updateBook]);

  const OnClickButtonUpdateStatus = () => {
    betweenSiteCarService
      .getUpdateTargetStatusById(Booking.idBetweenSiteCar)
      .then((res) => {
        if (res.data) {
          setBooking(res.data);
          enqueueSnackbar("Update Status Success", { variant: "success" });
        } else {
          setBooking(null);
          enqueueSnackbar("Update Status Fail", { variant: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("Update Status Fail", { variant: "error" });
        setBooking(null);
      });
  };

  const OnClickButtonSetFinish = () => {
    betweenSiteCarService
      .setFinishCallCar({ id: Booking.idBetweenSiteCar })
      .then(
        (res) => {
          if (res.data) {
            setUpdateBook(!updateBook);
            enqueueSnackbar("Update Status Success", { variant: "success" });
          } else {
            enqueueSnackbar("Update Status Fail", { variant: "error" });
          }
        },
        (error) => {
          enqueueSnackbar("Update Status Fail", { variant: "error" });
          console.log(error);
        }
      );
  };

  // useEffect(() => {
  //   if (Driver) {
  //     if (!RouteOfDriver) {
  //       if (Driver.typeDriver === 1) {
  //         dispatch(getRouteDayByIdDriver(Driver.idDriver));
  //       } else if (Driver.typeDriver === 2) {
  //         dispatch(
  //           getRoutesByRouteLineAndRouteDate(Driver.routeLine, Driver.routeDate)
  //         );
  //       }
  //     } else {
  //       if (Driver.startTime && Driver.endTime) {
  //         props.history.push("/reviews");
  //       }
  //     }
  //   }
  // }, [Driver]);

  // useEffect(() => {}, [RouteOfDriver]);

  const OnClickDetailButton = (idUser) => {
    props.history.push(`/map/profileEmployee/${idUser}`);
  };

  const OnClickButtonStartStop = () => {
    props.history.push("/startstop");
  };

  const OnClickPlanButton = () => {
    props.history.push("/plan");
  };

  const EmployeeData = () => {
    let route = RouteOfDriver.find((route) => route.statusGetting === false);
    if (route) {
      return route;
    } else {
      return null;
    }
  };

  return (
    <DivCoverReturn className={`page`}>
      {/* {!load && Booking === null && (
        <DivTextNoPassenger>
          <TextNoPassenger>ไม่มีผู้โดยสาร</TextNoPassenger>
        </DivTextNoPassenger>
      )} */}
      {/* {RouteOfDriver ? (
        <SlideNextEmployee
          openSlide={openSlide}
          employee={EmployeeData() ? EmployeeData() : null}
          onSetOpenSlide={setOpenSlide}
          OnClickDetailButton={OnClickDetailButton}
        />
      ) : (
        <DivTextNoPassenger>
          <TextNoPassenger>ไม่มีผู้โดยสารในเส้นทางของคุณ</TextNoPassenger>
        </DivTextNoPassenger>
      )}
      {RouteOfDriver && Driver && Driver.startTime && (
        <DivTextTime
          className={clsx({
            OpenSlide: openSlide,
            CloseSlide: !openSlide,
            DivTextTimeNone: !openStartTimeBar,
          })}
        >
          <TextTime>
            {`เวลาออกรถ : ${dayjs(Driver.startTime).format("HH : mm")}`}
          </TextTime>
          <CloseButton onClick={() => setOpenStartTimeBar(false)}>
            <CloseIconStyled />
          </CloseButton>
        </DivTextTime>
      )}
      {RouteOfDriver && Driver && (
        <ButtonStartStopDriving
          statusCar={Driver ? Driver : ""}
          OnClickButtonStartStop={OnClickButtonStartStop}
        />
      )} */}
      {/* <ButtonGoToPlanPage OnClickPlanButton={OnClickPlanButton} /> */}

      <PartOfMap>
        {load ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Paper
              sx={{
                overflow: "hidden",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FFFFFF",
                marginBottom: "20px",
                padding: "25px 30px 25px 30px",
                borderRadius: "15px",
                textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
              }}
            >
              <img
                src={UnicornLoading}
                alt="Loading..."
                style={{ width: "300px" }}
              />
              {/* ปรับขนาดของภาพตามที่ต้องการ */}
            </Paper>
          </div>
        ) : (
          <>
            {isLoaded && isLocationDriver && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={
                  Booking === null
                    ? {
                        lat: parseFloat(isLocationDriver[0].Lat),
                        lng: parseFloat(isLocationDriver[0].Long),
                        // lat: parseFloat(12.692112500841047),
                        // lng: parseFloat(101.1339876326265),
                      }
                    : null
                }
                zoom={13}
                options={{
                  disableDefaultUI: true, // ปิดปุ่ม UI ทั้งหมด
                  zoomControl: false, // ปิดปุ่มย่อ/ขยาย
                  fullscreenControl: false, // ปิดปุ่มเต็มจอ
                  streetViewControl: false, // ปิดปุ่ม Street View
                  mapTypeControl: false, // ปิดปุ่มแผนที่/ดาวเทียม
                }}
              >
                <MarkerDriver
                  inforInCar={Booking}
                  isBetweenSiteCar={true}
                  siteFrom={siteFrom}
                  siteTo={siteTo}
                  isLocationDriver={isLocationDriver}
                />
                {siteTo && (
                  <Marker
                    position={{
                      lat: parseFloat(siteTo.Lat),
                      lng: parseFloat(siteTo.Long),
                    }}
                    icon={{
                      url:
                        "data:image/svg+xml;charset=UTF-8," +
                        encodeURIComponent(`
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#d9534f" width="56px" height="56px">
                          <path d="M12 2C8.69 2 6 4.69 6 8c0 3.53 3.87 7.61 5.47 9.19.3.3.77.3 1.06 0C14.13 15.61 18 11.53 18 8c0-3.31-2.69-6-6-6zm0 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
                        </svg>
                      `),
                    }}
                    onClick={() => setOpenInfoSiteTo(true)}
                  >
                    {openInfoSiteTo && (
                      <InfoWindow
                        position={{
                          lat: parseFloat(siteTo.Lat),
                          lng: parseFloat(siteTo.Long),
                        }}
                        onCloseClick={() => setOpenInfoSiteTo(false)} // Close the InfoWindow
                        options={{
                          pixelOffset: new window.google.maps.Size(0, -20),
                        }} // Move InfoWindow above the marker
                      >
                        {Booking && Booking.user && (
                          <Box>
                            <div
                              style={{
                                width: "100%",
                                height: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                              }}
                            >
                              <Avatar
                                sx={{ width: 100, height: 100 }}
                                src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                                alt={"image"}
                              />
                            </div>
                            <h2>{`${Booking.user.firstname_TH} ${Booking.user.lastname_TH}`}</h2>
                            <p>Tel: {Booking.user.telephoneMobile}</p>
                            <p>จุดส่ง: {Booking.toPlaceName}</p>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "10px 0",
                              }}
                            >
                              {Booking.targetStatus === "GettingPlace" ? (
                                <ButtonBlue
                                  variant="contained"
                                  color="warning"
                                  onClick={OnClickButtonUpdateStatus}
                                >
                                  รับผู้โดยสาร
                                </ButtonBlue>
                              ) : (
                                <ButtonBlue
                                  variant="contained"
                                  onClick={OnClickButtonSetFinish}
                                >
                                  ส่งผู้โดยสาร
                                </ButtonBlue>
                              )}
                            </div>
                          </Box>
                        )}
                      </InfoWindow>
                    )}
                  </Marker>
                )}
                {siteFrom && (
                  <Marker
                    position={{
                      lat: parseFloat(siteFrom.Lat),
                      lng: parseFloat(siteFrom.Long),
                    }}
                    icon={{
                      url:
                        "data:image/svg+xml;charset=UTF-8," +
                        encodeURIComponent(`
                        <svg height="48" viewBox="0 0 1792 1792" width="48" xmlns="http://www.w3.org/2000/svg" fill="#005f40">
            <path d="M1536 1536q0 63-61.5 113.5t-164 81-225 46-253.5 15.5-253.5-15.5-225-46-164-81-61.5-113.5q0-49 33-88.5t91-66.5 118-44.5 131-29.5q26-5 48 10.5t26 41.5q5 26-10.5 48t-41.5 26q-58 10-106 23.5t-76.5 25.5-48.5 23.5-27.5 19.5-8.5 12q3 11 27 26.5t73 33 114 32.5 160.5 25 201.5 10 201.5-10 160.5-25 114-33 73-33.5 27-27.5q-1-4-8.5-11t-27.5-19-48.5-23.5-76.5-25-106-23.5q-26-4-41.5-26t-10.5-48q4-26 26-41.5t48-10.5q71 12 131 29.5t118 44.5 91 66.5 33 88.5zm-384-896v384q0 26-19 45t-45 19h-64v384q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-384h-64q-26 0-45-19t-19-45v-384q0-53 37.5-90.5t90.5-37.5h384q53 0 90.5 37.5t37.5 90.5zm-96-384q0 93-65.5 158.5t-158.5 65.5-158.5-65.5-65.5-158.5 65.5-158.5 158.5-65.5 158.5 65.5 65.5 158.5z"/>
          </svg>
          
                      `),
                    }}
                    onClick={() => {
                      setOpenInfoSiteFrom(true);
                      console.log("openInfoSiteFrom", openInfoSiteFrom);
                    }}
                  >
                    {openInfoSiteFrom && (
                      <InfoWindow
                        position={{
                          lat: parseFloat(siteFrom.Lat),
                          lng: parseFloat(siteFrom.Long),
                        }}
                        onCloseClick={() => setOpenInfoSiteFrom(false)} // Close the InfoWindow
                        options={{
                          pixelOffset: new window.google.maps.Size(0, -20),
                          zIndex: 100,
                        }} // Move InfoWindow above the marker
                      >
                        {Booking && Booking.user && (
                          <Box>
                            <div
                              style={{
                                width: "100%",
                                height: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                              }}
                            >
                              <Avatar
                                sx={{ width: 100, height: 100 }}
                                src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                                alt={"image"}
                              />
                            </div>
                            <h2>{`${Booking.user.firstname_TH} ${Booking.user.lastname_TH}`}</h2>
                            <p>Tel: {Booking.user.telephoneMobile}</p>
                            <p>จุดรับ: {Booking.fromPlaceName}</p>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "10px 0",
                              }}
                            >
                              {Booking.targetStatus === "GettingPlace" ? (
                                <ButtonBlue
                                  variant="contained"
                                  color="warning"
                                  onClick={OnClickButtonUpdateStatus}
                                >
                                  รับผู้โดยสาร
                                </ButtonBlue>
                              ) : (
                                <ButtonBlue
                                  variant="contained"
                                  onClick={OnClickButtonSetFinish}
                                >
                                  ส่งผู้โดยสาร
                                </ButtonBlue>
                              )}
                            </div>
                          </Box>
                        )}
                      </InfoWindow>
                    )}
                  </Marker>
                )}
                {/* <MarkerCar lat={12.692112500841047} lng={101.1339876326265} />
          {RouteOfDriver && (
            <MarkerGetting
              lat={EmployeeData() ? EmployeeData().latUser : 0}
              lng={EmployeeData() ? EmployeeData().lngUser : 0}
            />
          )} */}
              </GoogleMap>
            )}
          </>
        )}
      </PartOfMap>
      <SwipeableEdgeDrawer
        Booking={Booking}
        OnClickButtonUpdateStatus={OnClickButtonUpdateStatus}
        OnClickButtonSetFinish={OnClickButtonSetFinish}
      />
    </DivCoverReturn>
  );
};

export default MapPage;
