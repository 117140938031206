import {
  DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FETCHING,
  DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FAILED,
  DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_SUCCESS,
} from "./types";

import {
  PASSENGERS_FETCHING,
  PASSENGERS_FAILED,
  PASSENGERS_SUCCESS,
} from "./types";

import DeliverySampleshuttleService from "../services/deliverySampleshuttle.service";

export const postDeliverySampleshuttleByStartDate = (newData) => async () => {
  try {
    console.log(newData);
    const res =
      await DeliverySampleshuttleService.postDeliverySampleshuttleByStartDate(
        newData
      );

    if (res) {
      return res;
    }
  } catch (error) {
    return "error";
  }
};
export const postUpdateDeliveryStatus = (newData) => async () => {
  try {
    const res = await DeliverySampleshuttleService.postUpdateDeliveryStatus(
      newData
    );
    if (res) {
      return res;
    }
  } catch (error) {
    return "error";
  }
};

export const getDeliverySampleShuttleByNo = () => async () => {
  try {
    const res =
      await DeliverySampleshuttleService.getDeliverySampleShuttleByNo();

    if (res) {
      return res;
    }
  } catch (error) {
    return "error";
  }
};
export const getDeliverySampleShuttleByFilter =
  (dataFilter) => async (dispatch) => {
    console.log(dataFilter);
    try {
      dispatch({
        type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await DeliverySampleshuttleService.getDeliverySampleShuttleByFilter(
          dataFilter
        );
      if (res) {
        dispatch({
          type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_SUCCESS,
          payload: res.data.data.final_result,
        });
        console.log(res.data.data.final_result);
        return res.data.data.final_result;
      }
    } catch (error) {
      dispatch({
        type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FAILED,
      });
    }
    // try {
    //   const res = await DeliverySampleshuttleService.getDeliverySampleShuttleByFilter(dataFilter);

    //   if (res) {
    //     return res;
    //   }
    // } catch (error) {
    //   return "error";
    // }
  };

export const getDeliverySampleshuttleByIdUser =
  (idUser) => async (dispatch) => {
    try {
      dispatch({
        type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await DeliverySampleshuttleService.getDeliverySampleshuttleByIdUser(
          idUser
        );
      if (res) {
        dispatch({
          type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
        return res.data;
      }
    } catch (error) {
      dispatch({
        type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FAILED,
      });
    }
  };

export const getDeliverySampleshuttleByIdBooking =
  (idBooking) => async (dispatch) => {
    try {
      dispatch({
        type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await DeliverySampleshuttleService.getDeliverySampleshuttleByIdBooking(
          idBooking
        );
      if (res) {
        dispatch({
          type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FAILED,
      });
    }
  };

export const getDeliverySampleshuttleByIdDriver =
  (idDriver) => async (dispatch) => {
    try {
      dispatch({
        type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await DeliverySampleshuttleService.getDeliverySampleshuttleByIdDriver(
          idDriver
        );
      if (res) {
        dispatch({
          type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (error) {
      dispatch({
        type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FAILED,
      });
    }
  };
export const getDeliverySampleShuttleByFilterByIdDriver =
  (dataFilter) => async (dispatch) => {
    try {
      dispatch({
        type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await DeliverySampleshuttleService.getDeliverySampleShuttleByFilterByIdDriver(
          dataFilter
        );
      if (res) {
        dispatch({
          type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_SUCCESS,
          payload: res.data.data,
        });
        return res;
      }
    } catch (error) {
      dispatch({
        type: DELIVERY_SAMPLE_SHUTTLE_CAR_BOOKINGS_FAILED,
      });
    }
  };

export const postEditDeliverySampleshuttle = (newData, id) => async () => {
  try {
    const res =
      await DeliverySampleshuttleService.postEditDeliverySampleshuttle(
        newData,
        id
      );
    if (res) {
      return res;
    }
  } catch (error) {
    return "error";
  }
};

export const postDeleteDeliverySampleshuttle = (newData) => async () => {
  try {
    const res = await DeliverySampleshuttleService.deleteDeliverySampleshuttle(
      newData
    );
    if (res) {
      return res;
    }
  } catch (error) {
    return "error";
  }
};

// export const getFileDeliverySampleshuttleById = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: PASSENGERS_FETCHING,
//     });
//     const res =
//       await DeliverySampleshuttleService.getFileDeliverySampleshuttleById(id);
//     if (res) {
//       dispatch({
//         type: PASSENGERS_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (error) {
//     dispatch({
//       type: PASSENGERS_FAILED,
//     });
//   }
// };
