import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import clsx from "clsx";

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/styles";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import VehiclesService from "../../../../../services/vehicle.service";
import VehicleBrandAndModelService from "../../../../../services/vehicleBrandAndModel.service";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const useStyles = makeStyles((theme) => ({
  ButtonTypeCar: {
    display: "flex",
    height: "65px",
    backgroundColor: "#FBFCFC",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    border: "1.5px solid lightgray",
    positon: "relative",
  },
  ButtonTypeCarOnClick: {
    borderColor: "#57837B",
    backgroundColor: "#B6C867",
  },
  Icon: {
    width: "50px",
    height: "50px",
  },
  TextInButton: {
    marginLeft: "20px",
    fontSize: "18px",
  },
  ButtonNavigate: {
    fontSize: "55px",
  },
  ChoosingCar: {
    borderRadius: "10px",
    border: "1.5px solid lightgray",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
  },
  ChoosingCarNoCar: {
    height: "500px",
    display: "flex",
    width: "100%",
  },
  DivCoverImageCar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ImageCar: {
    width: "500px",
    height: "300px",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  GridDetailCar: {
    width: "700px",
    margin: "0 auto",
    marginBottom: "20px",
    [theme.breakpoints.up("lg")]: {
      width: "800px",
    },
    [theme.breakpoints.down("md")]: {
      width: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const TextCapacity = styled(Typography)(({ theme }) => ({
  fontSize: "42px",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "28px",
  },
}));

const Text = styled(Typography)({
  fontSize: "18px",
  wordBreak: "break-word",
});

const ChoosingCar = styled("div")({
  borderRadius: "10px",
  border: "1.5px solid lightgray",
  justifyContent: "center",
  backgroundColor: "#FBFCFC",
  boxShadow:
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
  alignItems: "center",
  fontSize: "30px",
  display: "flex",
  width: "100%",
  position: "relative",
  "& .PreviousButton": {
    left: "0",
  },
  "& .NextButton": {
    right: "0",
  },
});

const NavigateButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  indexZ: "2",
  backgroundColor: "rgba(255,255,255,0.8)",
  color: "black",
  "&:hover": {
    backgroundColor: "rgba(255,255,255,0.8)",
  },
  [theme.breakpoints.down("md")]: {
    top: "25%",
  },
}));

const GridContainText = styled(Grid)(({ theme }) => ({
  wordWrap: "break-word",
  [theme.breakpoints.down("sm")]: {
    paddingRight: "24px",
  },
}));

const CarCard = styled("div")(({ theme }) => ({
  width: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const FormCar = (props) => {
  const classes = useStyles();
  const [vehicleList, setVehicleList] = useState([]);

  useEffect(() => {
    if (props.getValues("idTypeCar")) {
      VehicleBrandAndModelService.getVehicleBrandAndModelByTypeCarAndUseFor(
        "CrossAreaCar",
        props.getValues("idTypeCar")
      )
        .then((res) => {
          if (res.data) {
            setVehicleList(res.data);
            props.setValue("indexCar", 0);
            props.setValue(
              "idVehicleBrandAndModel",
              res.data[props.getValues("indexCar")].idVehicleBrandsAndModels
            );
            props.setValue(
              "capacity",
              res.data[props.getValues("indexCar")].capacity
            );
            props.setValue(
              "costBooking",
              res.data[props.getValues("indexCar")].costBooking
            );
            props.setValue(
              "extraCostBooking",
              res.data[props.getValues("indexCar")].extraCostBooking
            );
          }
        })
        .catch(() => {
          setVehicleList([]);
        });
    }
  }, [props.watch("idTypeCar")]);

  const onClickNavigateBack = () => {
    if (props.getValues("indexCar") - 1 < 0) {
      props.setValue("indexCar", vehicleList.length - 1);
    } else {
      props.setValue("indexCar", props.getValues("indexCar") - 1);
    }

    props.setValue(
      "idVehicleBrandAndModel",
      vehicleList[props.getValues("indexCar")].idVehicleBrandsAndModels
    );
    props.setValue(
      "capacity",
      vehicleList[props.getValues("indexCar")].capacity
    );
    props.setValue(
      "costBooking",
      vehicleList[props.getValues("indexCar")].costBooking
    );
  };

  const onClickNavigateNext = () => {
    if (props.getValues("indexCar") + 1 > vehicleList.length - 1) {
      props.setValue("indexCar", 0);
    } else {
      props.setValue("indexCar", props.getValues("indexCar") + 1);
    }
    props.setValue(
      "idVehicleBrandAndModel",
      vehicleList[props.getValues("indexCar")].idVehicleBrandsAndModels
    );
    props.setValue(
      "capacity",
      vehicleList[props.getValues("indexCar")].capacity
    );
    props.setValue(
      "costBooking",
      vehicleList[props.getValues("indexCar")].costBooking
    );
  };

  return (
    <Grid container item spacing={3} xs={12}>
      <Controller
        render={(propsInContoller) => (
          <Grid container item spacing={3} xs={12}>
            <Grid item xs={12} sm={6} md={3}>
              <div
                className={clsx(classes.ButtonTypeCar, {
                  [classes.ButtonTypeCarOnClick]:
                    props.getValues("idTypeCar") === 1,
                })}
                onClick={() => propsInContoller.field.onChange(1)}
              >
                <img
                  className={classes.Icon}
                  src="./assets/Image/Icon/van.png"
                  alt="car_icon"
                />
                <Typography className={classes.TextInButton}>รถตู้</Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div
                className={clsx(classes.ButtonTypeCar, {
                  [classes.ButtonTypeCarOnClick]:
                    props.getValues("idTypeCar") === 2,
                })}
                onClick={() => {
                  propsInContoller.field.onChange(2);
                }}
              >
                <img
                  className={classes.Icon}
                  src="./assets/Image/Icon/car.png"
                  alt="car_icon"
                />
                <Typography className={classes.TextInButton}>รถเก๋ง</Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div
                className={clsx(classes.ButtonTypeCar, {
                  [classes.ButtonTypeCarOnClick]:
                    props.getValues("idTypeCar") === 3,
                })}
                onClick={() => propsInContoller.field.onChange(3)}
              >
                <img
                  className={classes.Icon}
                  src="./assets/Image/Icon/pickup-truck.png"
                  alt="car_icon"
                />
                <Typography className={classes.TextInButton}>
                  รถกระบะ
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div
                className={clsx(classes.ButtonTypeCar, {
                  [classes.ButtonTypeCarOnClick]:
                    props.getValues("idTypeCar") === 4,
                })}
                onClick={() => propsInContoller.field.onChange(4)}
              >
                <img
                  className={classes.Icon}
                  src="./assets/Image/Icon/bus.png"
                  alt="car_icon"
                />
                <Typography className={classes.TextInButton}>รถบัส</Typography>
              </div>
            </Grid>
          </Grid>
        )}
        name="idTypeCar"
        control={props.control}
        defaultValue=""
      />
      <Grid item xs={12}>
        {props.watch("idVehicleBrandAndModel") && vehicleList.length > 0 ? (
          <ChoosingCar>
            <NavigateButton
              className="PreviousButton"
              disableRipple
              onClick={() => onClickNavigateBack()}
            >
              <NavigateBeforeIcon className={classes.ButtonNavigate} />
            </NavigateButton>
            <CarCard>
              {vehicleList[props.getValues("indexCar")] && (
                <>
                  <img
                    className={classes.ImageCar}
                    src={`${process.env.REACT_APP_URL}image/car/${
                      vehicleList[props.getValues("indexCar")].imagepath
                    }`}
                    alt={`${
                      vehicleList[props.getValues("indexCar")].imagepath
                    }`}
                  />
                  <TextCapacity>{`จำนวนที่นั่ง : ${
                    vehicleList[props.getValues("indexCar")].capacity
                  }`}</TextCapacity>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    className={classes.GridDetailCar}
                  >
                    <GridContainText item xs={12} md={6}>
                      <Text>{`รุ่น : ${
                        vehicleList[props.getValues("indexCar")].model
                      }`}</Text>
                    </GridContainText>
                    <GridContainText item xs={12} md={6}>
                      <Text>{`แบรนด์ : ${
                        vehicleList[props.getValues("indexCar")].brand
                      }`}</Text>
                    </GridContainText>
                    <GridContainText item xs={12} md={6}>
                      <Text>{`เครื่องยนต์ : ${
                        vehicleList[props.getValues("indexCar")].moter
                      }`}</Text>
                    </GridContainText>
                    <GridContainText item xs={12} md={6}>
                      <Text>{`เชื้อเพลิง : ${
                        vehicleList[props.getValues("indexCar")].gas
                      }`}</Text>
                    </GridContainText>
                    <GridContainText item xs={12} md={6}>
                      <Text>{`ระบบเกียร์ : ${
                        vehicleList[props.getValues("indexCar")].gear
                      }`}</Text>
                    </GridContainText>
                    <GridContainText item xs={12} md={6}>
                      <Text>{`เบรกABS : ${
                        vehicleList[props.getValues("indexCar")].breakABS
                      }`}</Text>
                    </GridContainText>
                  </Grid>
                </>
              )}
            </CarCard>
            <NavigateButton
              className="NextButton"
              disableRipple
              onClick={() => onClickNavigateNext()}
            >
              <NavigateNextIcon className={classes.ButtonNavigate} />
            </NavigateButton>
          </ChoosingCar>
        ) : (
          <div className={`${classes.ChoosingCar} ${classes.ChoosingCarNoCar}`}>
            ไม่มีรถประเภทนี้
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default FormCar;
