import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/styles';
import {
     Button, Typography, Card, FormControlLabel, Checkbox, Grid,
} from "@mui/material";
import CardPackage from "./CardPackage";

import { listPackages, listCondition } from "../../../choise";

const useStyles = makeStyles((theme)=>({
    Content:{
        width:"95%",
        height:"calc(100% - 40px)",
        padding:"20px 0",
    },
    partOfCardPackage:{
        height:"fit-content",
        display:"flex",
        padding:"10px 0",
        overflowX:"auto",
        overflow:"hidden",
    },
    partOfCondition:{
        height:"fit-content",
        paddingTop:"10px",
    },
    NextButton:{
        width:"fit-content",
        color:"green",
        fontSize:"18px",
        "&:hover":{
            backgroundColor:"white",
        }
    },
}));

const TitleStyle = styled(Typography)(({theme})=>({
    fontSize:"20px",
}));

const ConditionForm = props => {
    const classes = useStyles();

    const onChangeConditionsCheckBox = (id) => {
        if(props.conditions.includes(id)){
            props.setConditions([...props.conditions.filter(condition => condition !== id)]);
        }
        else{
            props.setConditions([...props.conditions,id]);
        }
    };

    return(
        <div className={`${classes.Content}`}>
            <TitleStyle>Insurance Need From Customer</TitleStyle>
            <div className={`${classes.partOfCardPackage}`}>
                { listPackages.map((Package,index)=>(
                    <CardPackage 
                        key={Package.package}
                        Package={Package} 
                        index={index+1} 
                        packageSelected={props.packageSelected}
                        onClickCard={(indexpackage)=>props.setPackageSelected(indexpackage)}
                    />
                ))}
            </div>
            <div className={`${classes.partOfCondition}`}>
                <TitleStyle> Condition </TitleStyle>
                <Grid container spacing={1}>
                    {listCondition.map((label,index)=>(
                        <Grid item xs={12} sm={6} lg={4} key={label+""+index}>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={props.conditions.includes(label.id)}
                                        onChange={()=>onChangeConditionsCheckBox(label.id)} 
                                        name={label.name}
                                    />
                                }
                                label={label.name}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
            <div style={{width:"100%",display:"flex",justifyContent:"flex-end",padding:"10px 0"}}>
                <Button className={`${classes.NextButton}`} onClick={props.onClickBackProcess}>Back</Button>
                <Button className={`${classes.NextButton}`} onClick={props.onClickNextProcess}>Next</Button>
            </div>
        </div>
    );
};

export default ConditionForm;