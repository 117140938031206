import { httpClient } from "./httpClient";

const postNewDriverBooking = (newDriverBooking) => {
  return httpClient.post("add_new_driver_booking", [newDriverBooking]);
};
const getAllNewDriverBooking = () => {
  return httpClient.get("all_new_driver_booking");
};

const getDriverBookingByIdDriver = (idDriver) => {
  return httpClient.get("driver_booking_byIdDriver/" + idDriver);
};
const postManageCarDriverBooking = (manageCar) => {
  return httpClient.post("manage_car_driver_booking", manageCar);
};
const deleteCarDriverBooking = (del) => {
  return httpClient.post("delete_car_driver_booking", del);
};
const getAllVehicle = () => {
  return httpClient.get("all_vehicle");
};
const getAllDriverBookingByIdUser = (idUser) => {
  return httpClient.get("driver_booking_byIdUser/" + idUser);
};
const getDriverBookingByIdUserForRating = (idUser) => {
  return httpClient.get("driver_booking_byIdUser_ForRating/" + idUser);
};
const getDriverBookingByFilter = (filter) => {
  return httpClient.post("driver_booking_byFilter", filter);
};
const getDriverBookingByFilterByIdUser = (filter) => {
  return httpClient.post("driver_booking_byFilter_byIdUser", filter);
};
const getDriverBookingByFilterByIdDriver = (filter) => {
  return httpClient.post("driver_booking_byFilter_byIdDriver", filter);
};

export default {
  postNewDriverBooking,
  getAllNewDriverBooking,
  postManageCarDriverBooking,
  deleteCarDriverBooking,
  getAllVehicle,
  getAllDriverBookingByIdUser,
  getDriverBookingByIdDriver,
  getDriverBookingByIdUserForRating,
  getDriverBookingByFilter,
  getDriverBookingByFilterByIdUser,
  getDriverBookingByFilterByIdDriver,
};
