import React from "react";

import { styled } from '@mui/styles';
import {
  Button,
} from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const DivCoverReturn = styled("div")(({theme})=>({
  width: "30%",
  height: "90%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down('sm')]:{
    width: "40%",
  },
}));

const ButtonArrow = styled(Button)({
  width: "94%",
  height: "90px",
  boxShadow: "0 0 20px",
  borderRadius: "10px",
  backgroundColor: "#002980",
  color: "white",
  "& .Arrow": {
    fontSize: "100px",
  },
});

const InputStyled = styled("input")(({theme})=>({
  width: "90%",
  height: "90%",
  textAlign: "center",
  fontSize: "150px",
  border: "3px solid #03256C",
  borderRadius: "10px",
  [theme.breakpoints.down('sm')]:{
    fontSize: "100px",
  },
}));

const DivInputBedTime = (props) => {

  return (
    <DivCoverReturn>
      <ButtonArrow
        onClick={() => props.onClickFunction(props.value + 1)}
      >
        <ExpandLessIcon className="Arrow" />
      </ButtonArrow>
      <InputStyled
        type="number"
        name={props.name}
        value={props.value}
        onChange={(event) => {
            props.onClickFunction(event.target.value);
        }}
        onClick={() => {
           props.onSetFuntion("");
        }}
        onBlur={(event) => {
          props.onBlurHandler(event.target.value, event.target.name);
        }}
      />
      <ButtonArrow
        onClick={() => props.onClickFunction(props.value - 1)}
      >
        <ExpandMoreIcon className="Arrow" />
      </ButtonArrow>
    </DivCoverReturn>
  );
};

export default DivInputBedTime;
