import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { SnackbarProvider, useSnackbar } from "notistack";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  Typography,
  Avatar,
  IconButton,
  Fade,
  useMediaQuery,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../shared/deleteModal";
import { getAllDriver } from "../../../../actions/driver";
import { getAllVehicles } from "../../../../actions/vehicle";
import { getAllVehicleBrandsAndModels } from "../../../../actions/vehicleBrandAndModel";
import DriversService from "../../../../services/driver.service";

import DetailDriverComponent from "./detailDriverComponent";

const useStyles = makeStyles((theme) => ({
  partOfTopNavButton: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  partOfTitle: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
  },
  ImageTopic: {
    width: "60px",
    height: "60px",
    marginRight: "20px",
  },
  Title: {
    fontSize: "50px",
  },
  TopNavButton: {
    height: "50px",
    display: "flex",
    justifyContent: "flex-end",
    padding: " 15px 50px",
    [theme.breakpoints.down("md")]: {
      paddingRight: "20px",
    },
  },
  Button: {
    borderRadius: "50px",
  },
  AddDriverButton: {
    width: "190px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: "20px",
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  AddDriverIconButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: "20px",
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  DeleteDriverButton: {
    width: "150px",
    fontSize: "20px",
    backgroundColor: "red",
    color: "white",
    marginRight: "20px",
    ["&:hover"]: {
      backgroundColor: "darkred",
    },
  },
  DeleteDriverIconButton: {
    fontSize: "20px",
    backgroundColor: "red",
    color: "white",
    marginRight: "20px",
    ["&:hover"]: {
      backgroundColor: "darkred",
    },
  },
  IconButton: {
    width: "50px",
    height: "50px",
  },
  Icon: {
    marginRight: "10px",
    color: "white",
    [theme.breakpoints.down("md")]: {
      marginRight: "0",
    },
  },
  partOfDriver: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    height: "calc(100% - 80px)",
  },
  partOfTableDriver: {
    width: "70%",
    height: "100%",
    border: "1px solid #CCD1D1",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  TableContainer: {
    height: "100%",
  },
  TableHeadCell: {
    fontSize: "20px",
  },
  TableCell: {
    borderBottom: "1px solid #CCD1D1",
  },
  TableCellName: {
    width: "25%",
  },
  TableCellCompany: {
    width: "25%",
  },
  TableRow: {
    ["&:hover"]: {
      cursor: "pointer",
    },
  },
  Avatar: {
    width: "50px",
    height: "50px",
  },
  Text: {
    fontSize: "18px",
  },
  editIcon: {
    color: theme.palette.primary.dark,
  },
}));

const columnHead = [
  {
    id: "picture",
    label: "",
    padding: "checkbox",
    align: "left",
    minWidth: 82,
  },
  {
    id: "name",
    label: "ชื่อ-สกุล",
    padding: "normal",
    align: "left",
    minWidth: 210,
  },
  {
    id: "company",
    label: "สังกัด",
    padding: "normal",
    align: "left",
    minWidth: 210,
  },
  {
    id: "ageWorking",
    label: "อายุงาน",
    padding: "normal",
    align: "center",
    minWidth: 100,
  },
  {
    id: "plateNum",
    label: "รถหลัก",
    padding: "normal",
    align: "center",
    minWidth: 105,
  },
  { id: "edit", label: "", padding: "checkbox", align: "left", minWidth: 80 },
];

const DriverPage = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const AllDrivers = useSelector((state) => state.drivers);
  const [idUserClicked, setIdUserClicked] = useState(0);
  const [selectDriver, setSelectDriver] = useState([]);
  const [actionInPage, setActionInPage] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const BrowserResponsive = useMediaQuery("(min-width:1025px)");
  const IpadVeticalResponsive = useMediaQuery("(max-width:800px)");

  useEffect(() => {
    dispatch(getAllDriver());
    dispatch(getAllVehicles());
  }, [actionInPage, props.history]);

  const handleClick = (event, id) => {
    const selectedIndex = selectDriver.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectDriver, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectDriver.slice(1));
    } else if (selectedIndex === selectDriver.length - 1) {
      newSelected = newSelected.concat(selectDriver.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectDriver.slice(0, selectedIndex),
        selectDriver.slice(selectedIndex + 1)
      );
    }

    setSelectDriver(newSelected);
  };

  const handleClickDeleteButton = () => {
    setIdUserClicked(0);
    let objSelectDrivers = { idDrivers: selectDriver };
    console.log(objSelectDrivers);
    DriversService.deleteDriver(objSelectDrivers).then(() => {
      setSelectDriver([]);
      setOpenDeleteModal(false);
      setActionInPage(!actionInPage);
      enqueueSnackbar("Delete driver successfully", { variant: "success" });
    });
  };

  const handleClickAddButton = () => {
    props.history.push("/admin/driver/add_driver", { isEdit: false });
  };

  const handleClickEditButton = (driver) => {
    props.history.push("/admin/driver/add_driver", {
      driver: driver,
      isEdit: true,
    });
  };

  const IsSelected = (id) => selectDriver.indexOf(id) !== -1;

  return (
    <div className={`page`}>
      <div className={`${classes.partOfTopNavButton}`}>
        <div className={`${classes.partOfTitle}`}>
          <img
            className={`${classes.ImageTopic}`}
            src="../../assets/Image/Icon/driverTopic.png"
            alt="driver"
          />
          <Typography className={`${classes.Title}`}>คนขับรถ</Typography>
        </div>
        <div className={`${classes.TopNavButton}`}>
          {BrowserResponsive ? (
            <Fade in={selectDriver.length > 0}>
              <Button
                className={`${classes.DeleteDriverButton} ${classes.Button}`}
                onClick={() => setOpenDeleteModal(true)}
              >
                <DeleteIcon className={`${classes.Icon}`} />
                Delete
              </Button>
            </Fade>
          ) : (
            <Fade in={selectDriver.length > 0}>
              <IconButton
                className={`${classes.DeleteDriverIconButton} ${classes.IconButton}`}
                aria-label="delete"
                size="large"
                onClick={() => setOpenDeleteModal(true)}
              >
                <DeleteIcon />
              </IconButton>
            </Fade>
          )}
          {BrowserResponsive ? (
            <Button
              className={`${classes.AddDriverButton} ${classes.Button}`}
              onClick={handleClickAddButton}
            >
              <AddIcon className={`${classes.Icon}`} />
              Add Driver
            </Button>
          ) : (
            <IconButton
              className={`${classes.AddDriverIconButton} ${classes.IconButton}`}
              aria-label="add"
              size="large"
              onClick={handleClickAddButton}
            >
              <AddIcon />
            </IconButton>
          )}
        </div>
      </div>
      <div className={`${classes.partOfDriver}`}>
        <div className={`${classes.partOfTableDriver}`}>
          <TableContainer className={`${classes.TableContainer}`}>
            <Table stickyHeader aria-label="List Driver">
              <TableHead>
                <TableRow>
                  <TableCell
                    key={"checkbox"}
                    align="center"
                    padding="checkbox"
                    className={`${classes.TableCell}`}
                  ></TableCell>
                  {columnHead.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.padding}
                      className={clsx(
                        classes.TableHeadCell,
                        classes.TableCell,
                        {
                          [classes.TableCellName]: headCell.id === "name",
                          [classes.TableCellCompany]: headCell.id === "company",
                        }
                      )}
                      style={{ minWidth: headCell.minWidth }}
                    >
                      <Typography className={`${classes.TableHeadCell}`}>
                        {headCell.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {AllDrivers.result &&
                  AllDrivers.result.map((driver, index) => (
                    <TableRow
                      hover
                      key={driver.idDriver}
                      className={`${classes.TableRow}`}
                      onClick={() => {
                        setIdUserClicked(index + 1);
                      }}
                    >
                      <TableCell
                        padding="checkbox"
                        className={`${classes.TableCell}`}
                      >
                        <Checkbox
                          checked={IsSelected(driver.idDriver)}
                          onClick={(event) => {
                            handleClick(event, driver.idDriver);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        padding="normal"
                        className={`${classes.TableCell}`}
                      >
                        <Avatar
                          className={`${classes.Avatar}`}
                          src={`${process.env.REACT_APP_URL}image/profile/${driver.image}`}
                          alt={driver.image}
                        />
                      </TableCell>
                      <TableCell className={`${classes.TableCell}`}>
                        <Typography className={`${classes.Text}`}>
                          {driver.FullName}
                        </Typography>
                      </TableCell>
                      <TableCell className={`${classes.TableCell}`}>
                        <Typography className={`${classes.Text}`}>
                          {driver.Company}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.TableCell}`}
                      >
                        <Typography className={`${classes.Text}`}>
                          {`${driver.AgeWorkingYear}/${driver.AgeWorkingMonth}`}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.TableCell}`}
                      >
                        <Typography className={`${classes.Text}`}>
                          {driver.PlateNumCar}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.TableCell}`}
                      >
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            handleClickEditButton(driver);
                          }}
                          size="large"
                        >
                          <EditIcon className={`${classes.editIcon}`} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <DeleteModal
          open={openDeleteModal}
          onCloseDeleteModalHandle={() => setOpenDeleteModal(false)}
          onClickDelete={() => handleClickDeleteButton()}
          label={"ลบคนขับรถ"}
        />
        {!IpadVeticalResponsive && (
          <DetailDriverComponent idUserClicked={idUserClicked} />
        )}
      </div>
    </div>
  );
};

export default DriverPage;
