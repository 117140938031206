import React, { useEffect, useState } from "react";
import clsx from "clsx";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PeopleIcon from "@mui/icons-material/People";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import RoomIcon from "@mui/icons-material/Room";
import PersonIcon from "@mui/icons-material/Person";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import BusinessIcon from "@mui/icons-material/Business";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";

import VehicleTypeService from "../../../../../services/vehicleType.service";
import utils from "../../../../../utils";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  Title: {
    fontSize: "30px",
  },
  GridPicture: {
    display: "flex",
    justifyContent: "center",
  },
  Avatar: {
    width: "400px",
    height: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "230px",
      height: "130px",
    },
  },
  typeAndModelDetail: {
    textAlign: "center",
  },
  Dialog: {
    paddingBottom: "30px",
    marginTop: "0",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("sm")]: {
        margin: "0",
      },
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px",
    },
  },
  DialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "550px",
      overflowY: "auto",
      padding: "0",
    },
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "blue",
    },
  },
  Grid: {
    width: "calc(100% - 50px)",
    marginLeft: "50px",
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 20px)",
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  Text: {
    fontSize: "20px",
    color: "darkblue",
  },
  Icon: {
    marginTop: "3px",
    color: "darkblue",
    fontsize: "10px",
    marginRight: "3px",
  },
  IconYellow: {
    color: "#DCA700",
  },
  GridTextIcon: {
    display: "flex",
  },
  Fixed: {
    backgroundColor: "#DCA700",
    "&:hover": {
      backgroundColor: "#ba8e04",
    },
  },
  Confirm: {
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
  GridTextAndIcon: {
    display: "flex",
  },
  DialogActions: {
    paddingRight: "30px",
  },
  Button: {
    width: "150px",
    height: "50px",
  },
  TextButton: {
    color: "white",
  },
}));

const GridContainText = styled(Grid)(({ theme }) => ({
  wordWrap: "break-word",
  [theme.breakpoints.down("sm")]: {
    paddingRight: "24px",
  },
}));

const DialogShowDetailBooking = (props) => {
  const classes = useStyles();
  const [typeCar, setTypeCar] = useState(null);
  console.log(typeCar);
  useEffect(() => {
    if (props.getValues("idTypeCar")) {
      VehicleTypeService.getVehicleTypeById(props.getValues("idTypeCar"))
        .then((res) => {
          if (res.data) {
            console.log(res.data);
            setTypeCar(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.watch("idTypeCar")]);

  const verificationFlightIsOneWay = (typeOfFlight) => {
    if (typeOfFlight === "oneWay") {
      return true;
    } else {
      return false;
    }
  };

  const pathnameOfPicture = (idTypeCar) => {
    if (idTypeCar === 2) {
      return "car1.png";
    } else if (idTypeCar === 3) {
      return "car2.png";
    } else {
      return "car4.jpg";
    }
  };

  return (
    <Dialog open={props.open} className={classes.Dialog} maxWidth="md">
      {typeCar && (
        <>
          <DialogTitle>
            <Typography
              className={`${classes.Title} ${classes.typeAndModelDetail}`}
            >
              จองรถในพื้นที่
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.DialogContent}>
            <Avatar
              className={`${classes.Avatar}`}
              src={`${process.env.REACT_APP_URL}image/car/${pathnameOfPicture(
                props.getValues("idTypeCar")
              )}`}
              alt={props.getValues("name")}
              variant="square"
            />
            <Grid container spacing={2} className={classes.Grid}>
              <GridContainText
                item
                xs={12}
                sm={6}
                className={classes.GridTextAndIcon}
              >
                <CalendarTodayIcon className={`${classes.Icon}`} />
                <Typography
                  className={`${classes.Text}`}
                >{`วันที่เริ่มต้น : ${dayjs(
                  props.getValues("departureDate")
                ).format("DD MMMM YYYY")}`}</Typography>
              </GridContainText>
              <GridContainText
                item
                xs={12}
                sm={6}
                className={classes.GridTextAndIcon}
              >
                <QueryBuilderIcon className={`${classes.Icon}`} />
                <Typography
                  className={`${classes.Text}`}
                >{`เวลา : ${props.getValues(
                  "startTime"
                )} น. - ${props.getValues("endTime")} น.`}</Typography>
              </GridContainText>
              <GridContainText
                item
                xs={12}
                sm={6}
                className={classes.GridTextAndIcon}
              >
                <DriveEtaIcon className={classes.Icon} />
                <Typography className={`${classes.Text}`}>
                  ประเภท : &nbsp;&nbsp;
                  {verificationFlightIsOneWay(props.getValues("flight"))
                    ? "เที่ยวเดียว"
                    : "รอรับกลับ"}
                </Typography>
              </GridContainText>
              <GridContainText
                item
                xs={12}
                sm={6}
                className={classes.GridTextAndIcon}
              >
                <PeopleIcon className={classes.Icon} />
                <Typography className={`${classes.Text}`}>
                  จำนวนผู้โดยสาร : &nbsp;&nbsp;
                  {props.getValues("numberOfPassenger")}&nbsp;&nbsp; คน
                </Typography>
              </GridContainText>
              <GridContainText
                item
                xs={12}
                sm={6}
                className={classes.GridTextAndIcon}
              >
                <RoomIcon className={`${classes.Icon}`} />
                <Typography className={`${classes.Text}`}>
                  จุดรับ : &nbsp;&nbsp;{props.getValues("fromPlace")}
                </Typography>
              </GridContainText>
              <GridContainText
                item
                xs={12}
                sm={6}
                className={classes.GridTextAndIcon}
              >
                <RoomIcon className={`${classes.Icon}`} />
                <Typography className={`${classes.Text}`}>
                  จุดส่ง : &nbsp;&nbsp;{props.getValues("toPlace")}
                </Typography>
              </GridContainText>
              <GridContainText
                item
                xs={12}
                sm={6}
                className={classes.GridTextAndIcon}
              >
                <EmojiTransportationIcon className={`${classes.Icon}`} />
                <Typography className={`${classes.Text}`}>
                  ประเภทรถ : &nbsp;&nbsp;{typeCar.vehicleTypeNameTH}
                </Typography>
              </GridContainText>
              <GridContainText
                item
                xs={12}
                sm={6}
                className={classes.GridTextAndIcon}
              >
                <BusinessIcon className={`${classes.Icon}`} />
                <Typography className={`${classes.Text}`}>
                  GA Site : &nbsp;&nbsp;{props.getValues("gaSite")}
                </Typography>
              </GridContainText>
              <GridContainText
                item
                xs={12}
                sm={6}
                className={classes.GridTextAndIcon}
              >
                <PersonIcon className={classes.Icon} />
                <Typography className={`${classes.Text}`}>
                  ผู้แจ้งขอ : &nbsp;&nbsp;{props.getValues("name")}
                </Typography>
              </GridContainText>
              <GridContainText
                item
                xs={12}
                sm={6}
                className={classes.GridTextAndIcon}
              >
                <PhoneIcon className={classes.Icon} />
                <Typography className={`${classes.Text}`}>
                  Tel. : &nbsp;&nbsp;{props.getValues("telephoneMobile")}
                </Typography>
              </GridContainText>
              <GridContainText
                item
                xs={12}
                sm={6}
                className={`${classes.GridTextAndIcon}`}
              >
                <MonetizationOnIcon
                  className={`${classes.Icon}  ${classes.IconYellow}`}
                />
                <Typography
                  className={`${classes.Text}`}
                >{`ราคา : ${utils.numberWithCommas(
                  props.getValues("costBooking")
                )} THB`}</Typography>
              </GridContainText>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            <Button
              className={`${classes.Fixed} ${classes.Button}`}
              onClick={props.handleClose}
            >
              <Typography className={`${classes.Text} ${classes.TextButton}`}>
                แก้ไข
              </Typography>
            </Button>
            <Button
              className={`${classes.Confirm} ${classes.Button}`}
              onClick={props.onClickSubmit}
            >
              <Typography className={`${classes.Text} ${classes.TextButton}`}>
                จองรถ
              </Typography>
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DialogShowDetailBooking;
