import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { styled } from '@mui/styles';
import {
    Typography, Avatar, Button, Rating, TextField, Divider
} from '@mui/material';
import utils from "../../../../utils/index";

const DivCoverReturn = styled("div")({
    margin: "auto",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "350px"
});

const Title = styled(Typography)({
    fontSize: "1.25rem"
});

const AvatarStyled = styled(Avatar)({
    borderRadius: "50%",
    margin: "0 10px",
    width: 100, 
    height: 100, 
    marginTop: 15,
    marginBottom: 15
});

const ConfirmButton = styled(Button)(({theme})=>({
    width:"200px",
    color:theme.palette.success.contrastText,
    backgroundColor:theme.palette.success.main,
    marginTop:"10px",
    "&:hover":{
        backgroundColor:theme.palette.success.main,
    }
}));

const RatingStyled = styled(Rating)({
    "& .MuiRating-decimal":{
        fontSize:"45px"
    }
});

const DividerStyled = styled(Divider)({
    height:"1px",
    width:"100%",
    backgroundColor:"rgb(0,0,0)",
    opacity:"0.1",
    marginTop:15,
});

const TextFieldStyled = styled(TextField)({
    width:"100%",
    "& .MuiFormHelperText-root":{
        fontSize:"16px",
    }
});

const RatingDriver = props => {
    const { result : userRouteReducer } = useSelector(state => state.routeUser);
    const { register, handleSubmit, control, watch } = useForm();

    const OnClickHandleSubmit = (data) => {
        let point = parseFloat(data.point);
        if(point > 0){
            if(point > 2){
                console.log(data);
            }
            else if(point <= 2 && data.comment.length > 0){
                console.log(data);
            }
        }
    };

    return (
        <DivCoverReturn className="page">
            <Title>ให้คะแนนคนขับรถ</Title>
            <AvatarStyled />
            <Typography variant="h6">
                {userRouteReducer ? userRouteReducer.fullnameDriver : "ยังไม่ระบุ"}
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ textAlign: "left", marginRight: 10 }}>
                    <Typography variant="body2">เบอร์โทรศัพท์:</Typography>
                </div>
                <div style={{ textAlign: "left" }}>
                    <Typography variant="body2"> 
                        {userRouteReducer ? utils.formatPhoneNumber(userRouteReducer.mobileNumberDriver) : "ยังไม่ระบุ"} 
                    </Typography>
                </div>
            </div>
            <div>
                <Button 
                    variant="outlined" 
                    style={{marginTop: 20}}
                    disableRipple
                    // className={classes.tagShift}
                >
                    {userRouteReducer ? userRouteReducer.plateNo : "ยังไม่ระบุ"}
                </Button>
            </div>
            <DividerStyled />
            <div style={{ marginTop: 10, marginBottom: 5 }}>
                <Controller
                    render={(propsInController)=>(
                        <RatingStyled 
                            precision={0.5}
                            value={parseFloat(propsInController.field.value)}
                            onChange={propsInController.field.onChange}
                        />
                    )}
                    name="point"
                    defaultValue={0}
                    control={control}
                />
            </div>
            <div style={{ width:"100%", marginTop: 5, marginBottom: 10 }}>
                <TextFieldStyled
                    multiline
                    rows={4}
                    helperText={parseFloat(watch("point")) <= 2 && "ให้คะแนนน้อยกว่า 2.5 ต้องแสดงความคิดเห็น"}
                    {...register("comment")}
                />
            </div>
            <ConfirmButton onClick={handleSubmit(OnClickHandleSubmit)}>
                ยืนยัน
            </ConfirmButton>
        </DivCoverReturn>
    )
};

export default RatingDriver;