import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Paper,
} from "@mui/material";

const DivAddButton = (props) => {
  return (
    <div>
      <Paper
        variant="outlined"
        style={{
          padding: 30,
          margin: "30px 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ textAlign: "center", color: "#757575" }}
        >
          ไม่มีข้อมูล
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: 345,
          }}
        >
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                props.setSelectShift(true);
              }}
            >
              {" "}
              เพิ่มตารางประจำวัน{" "}
            </Button>
          </div>
          <Dialog
            open={props.selectShift}
            onClose={props.handleCloseSelectShift}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Select Shift"}</DialogTitle>
            <DialogContent>
              <RadioGroup
                aria-label="shift"
                name="shift1"
                value={props.shift}
                onChange={props.handleChangeShift}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label="Shift A"
                />
                <FormControlLabel
                  value="B"
                  control={<Radio />}
                  label="Shift B"
                />
                <FormControlLabel
                  value="C"
                  control={<Radio />}
                  label="Shift C"
                />
                <FormControlLabel
                  value="D"
                  control={<Radio />}
                  label="Shift D"
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleCloseSelectShift} color="primary">
                {" "}
                ยกเลิก{" "}
              </Button>
              <Button
                onClick={props.handleAddRouteDay}
                color="primary"
                autoFocus
                disabled={props.shift == ""}
              >
                {" "}
                เพิ่มตาราง{" "}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </div>
  );
};

export default DivAddButton;
