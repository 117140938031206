import React, { Fragment } from 'react';

import { styled } from '@mui/styles';
import {
    Typography, Avatar, Rating, Divider
} from '@mui/material';

import StarIcon from '@mui/icons-material/Star';

const Topic = styled(Typography)(({theme})=>({
    fontSize:"22px",
    fontWeight:"bold",
    margin:"10px 0px",
}));

const IconAndText = styled("div")(({theme})=>({
    display:"flex",
    alignItems:"center",
    "& .Text":{
        fontSize:"18px",
        marginLeft:"10px",
    },
    "& .IconAndPoint":{
        color:theme.palette.warning.light,
        fontWeight:"bold",
        fontSize:"24px",
    },
    "& .Icon":{
        fontSize:"30px",
    },
}));

const ReviewDiv = styled("div")(({theme})=>({
    width:"100%",
    display:"flex",
    margin:"20px 0",
    "& .Avatar":{
        width:"50px",
        height:"50px",
    },
}));

const RatingAndComment = styled("div")(({theme})=>({
    width:"calc(100% - 80px)",
    marginLeft:"20px",
    "& .Name":{
        fontSize:"18px",
        fontWeight:"bold",
    },
}));

const DividerStyled = styled(Divider)(({theme})=>({
    width:"100%",
    height:"1px",
    backgroundColor:theme.palette.grey[200],
    margin:"10px 0",
}));

const partOfRatingAndComment = ({ LentCar, reviews}) => {
    return (
        <div>
            <Topic>Ratings And Reviews</Topic>
            <IconAndText>
                <StarIcon className="IconAndPoint Icon" />
                <Typography className="IconAndPoint Text">{LentCar.point}</Typography>
            </IconAndText>
            {reviews.length > 0 && reviews.map((review)=>(
                <Fragment key={review.id}>
                    <ReviewDiv>
                        <Avatar src={`${process.env.REACT_APP_URL}image/profile/1.jpg`} className="Avatar"/>
                        <RatingAndComment>
                            <Typography className="Name">Oliver</Typography>
                            <Rating name="read-only" value={review.rating} readOnly precision={0.5} />
                            <Typography>{review.comment}</Typography>
                        </RatingAndComment>
                    </ReviewDiv>
                    <DividerStyled />
                </Fragment>
            ))}
        </div>
    );
};

export default partOfRatingAndComment;