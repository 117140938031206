import React, { useState, useEffect, Fragment } from "react";
import dayjs from 'dayjs';

import { styled } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import CrossAreaCarBookingService from "../../../../../services/crossAreaCarBooking.service";
import InAreaCarBookingService from "../../../../../services/inAreaCarBooking.service";

require('dayjs/locale/th');
dayjs.locale('th');

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    height:"fit-content",
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  Title: {
    textAlign: "center",
    fontSize: "30px",
    marginTop: "10px",
  },
  TextUnderPicture:{
    textAlign: "center",
    fontSize:"16px",
  },
  Paper: {
    width: "80%",
    height:"fit-content",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    paddingBottom:"20px",
    marginBottom:"20px",
    boxShadow: theme.shadows[3],
    position:"relative",
    padding:"0 20px",
    [theme.breakpoints.down("sm")]:{
      width:"100%",
      padding:"0 10px",
    },
  },
  ContentInPaper:{
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    paddingBottom:"20px",
  },
  Divider: {
    height: "1px",
    width: "100%",
    marginTop:"20px",
    marginBottom:"20px",
    backgroundColor: "#DDDDDD",
  },
  Grid:{
    width:"100%",
  },
  Avatar:{
    height:"100%",
    width:"85%",
    ["@media only screen and (min-width: 1030px)"]:{
      width:"300px",
    },
    ["@media (min-width: 700px) and (max-width: 820px)"]: {
      width:"300px",
    },
  },
  GridPicture:{
    height:"150px",
    display:"flex",
    justifyContent:"center",
  },
  GridTextUnderPicture:{
    display:"flex",
    flexDirection:"column",
    marginTop:"20px",
    justifyContent:"center",
  },
  Typography:{
    whiteSpace:"pre-wrap",
    ["@media only screen and (max-width: 380px)"]:{
      whiteSpace:"normal",
    },
  },
  Text:{
    fontSize:"20px",
  },
  TextPassenger:{
    width:"100%",
    color:theme.palette.primary.main,
    marginBottom:"10px"
  },
  DividerPassenger: {
    height: "1px",
    width: "100%",
    marginTop:"20px",
    marginBottom:"20px",
    backgroundColor: theme.palette.primary.light,
    opacity:"0.5",
  },
  DetailCar:{
    width:"calc(100% - 30px)",
    paddingLeft:"25px",
    ["@media only screen and (min-width: 800px)"]:{
      display:"flex",
      justifyContent:"center",
    },
  },
  TextPrice:{
    marginTop:"0",
    fontSize:"24px",
    wordWrap:"break-word",
  },
  NavButton:{
    width:"100%",
    height:"50px",
    display:"flex",
    justifyContent:"space-around",
    ["@media only screen and (max-width: 380px)"]:{
      display:"block",
    },
  },
  Button:{
    width:"150px",
    height:"50px",
    color:"white",
    fontSize:"20px",
    [theme.breakpoints.down("sm")]:{
      width:"fit-Content"
    },
  },
  BackButton:{
    width:"fit-content",
    color:"black",
    position:"absolute",
    top:"13px",
    left:"0",
    padding:"0 25px",
    ["&:hover"]:{
      backgroundColor:"white",
    },
    [theme.breakpoints.down('sm')]:{
      top:"5px"
    },
  },
  DisapproveButton:{
    backgroundColor:"red",
    ["&:hover"]:{
      backgroundColor:"darkred",
    },
    [theme.breakpoints.down('sm')]:{
      width:"100%",
    },
  },
  ApproveButton:{
    backgroundColor:"green",
    ["&:hover"]:{
      backgroundColor:"darkgreen",
    },
    [theme.breakpoints.down('sm')]:{
      width:"100%",
    },
  },
  TelOfDriver:{
    ["@media only screen and (min-width: 760px)"]:{
      display:"flex",
    },
  },
  GridButton:{
    display:"flex",
    justifyContent:"center",
  },
}));

const GridNavButton = styled(Grid)(({theme})=>({
  [theme.breakpoints.down("sm")]:{
    paddingBottom:"10px",
  },
}));

const DetailBookingOrder = (props) => {
  const classes = useStyles();
  const [booking, setBooking] = useState(null);

  useEffect(() => {
    let stateByHistoryPush = props.location.state;
    console.log(props.location.state.typeOfBooking);
    if(stateByHistoryPush.typeOfBooking){
      if(stateByHistoryPush.typeOfBooking === "CrossArea"){
        CrossAreaCarBookingService.getCrossAreaCarBookingById(stateByHistoryPush.orderId).then(
          (res) => {
            if(res.data){
              console.log("CrossArea : ",res.data);
              setBooking(res.data);
            }
          }
        );
      }else{
        InAreaCarBookingService.getInAreaCarBookingById(stateByHistoryPush.orderId).then(
          (res) => {
            if(res.data){
              console.log("InArea : ",res.data);
              setBooking(res.data);
            }
          }
        );
      }
    }
  }, [])

  const verificationTypeOfBooking = (type) => {
    if (type === "CrossArea") {
      return "รถข้ามพื้นที่พิเศษ";
    } else if (type === "InArea") {
      return "รถในพื้นที่";
    }
  };

  const convertCarTypeAndModel = () => {
    if(booking.vehicleBrandsAndModels){
      return `${booking.vehicleTypes.vehicleTypeNameTH} ${booking.vehicleBrandsAndModels.model}`;
    }
    else if(booking){
      return `${booking.vehicleTypes.vehicleTypeNameTH} ${booking.gaSite}`;
    }
  };

  const convertTypeOfFlight = () => {
    if(booking){
      if(booking.flight === "oneWay"){
        return "เที่ยวเดียว";
      }
      else{
        if(props.location.state.typeOfBooking === "CrossArea"){
          return "ไป-กลับ";
        }
        else{
          return "รอรับกลับ";
        }
      }
    }
  };

  const onClickBackButton = () => {
    props.history.push("/manager/approved");
  };

  return (
    <div className={`page ${classes.DivCoverReturn}`}>
      <Paper className={classes.Paper}>
        {booking && (
          <>
            {console.log("booking : ",booking)}
            <Button 
              className={`${classes.Button} ${classes.BackButton}`} 
              startIcon={<ArrowBackIcon />} 
              onClick={onClickBackButton} 
            >
              กลับ
            </Button>
            <Typography className={classes.Title}>
              การจองรถ
            </Typography>
            <Grid container spacing={1} className={classes.Grid}>
              <Grid container item xs={12} md={6}>
                <Grid item xs={12} className={classes.GridPicture}>
                  <Avatar
                    className={classes.Avatar}
                    src={`${process.env.REACT_APP_URL}image/car/${props.location.state.typeOfBooking === "CrossArea" ? booking.vehicleBrandsAndModels.imagepath : booking.vehicleTypes.imagepath}`}
                    alt={props.location.state.typeOfBooking === "CrossArea" ? booking.vehicleBrandsAndModels.imagepath : booking.vehicleTypes.imagepath}
                    variant="square"
                  />
                </Grid>
                <Grid item xs={12} className={`${classes.GridTextUnderPicture}`}>
                  <Typography className={`${classes.Text} ${classes.TextUnderPicture}`}>{`${convertCarTypeAndModel()}`}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} alignContent="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.Text}>{verificationTypeOfBooking(props.location.state.typeOfBooking)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={`${classes.Typography} ${classes.Text}`}>จำนวนผู้โดยสาร : {booking.numberOfPassenger} คน</Typography>
                </Grid>
                <Grid item xs={12}> 
                  <Typography className={`${classes.Typography} ${classes.Text}`}>{"ประเภท : "+convertTypeOfFlight()}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.Text}>{`วัตถุประสงค์ : ${booking.purpose}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.Divider} />
            <Grid container spacing={1} className={classes.Grid}>
              <Grid item xs={12} md={6}>
                <Typography className={`${classes.Typography} ${classes.Text}`}>ผู้จอง : {booking.name}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={`${classes.Typography} ${classes.Text}`}>เบอร์โทรศัพท์ : {booking.telephoneMobile}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={`${classes.Typography} ${classes.Text}`}>email : {booking.email}</Typography>
              </Grid>
            </Grid>
            <Divider className={classes.Divider} />
            <Grid container spacing={1} className={classes.Grid}>
              <Grid item xs={12} sm={6}> 
                <Typography className={`${classes.Typography} ${classes.Text}`}>ต้นทาง : {booking.fromPlace}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}> 
                <Typography className={`${classes.Typography} ${classes.Text}`}>ปลายทาง : {booking.toPlace}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={`${classes.Typography} ${classes.Text}`}>
                  {`วันที่เริ่ม : ${dayjs(booking.departureDate).format("DD MMMM YYYY")}`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={`${classes.Typography} ${classes.Text}`}>
                  {`เวลา : ${dayjs(booking.startTime).format(" HH.mm น.")} - ${dayjs(booking.endTime).format(" HH.mm น.")}`}
                </Typography>
              </Grid>
              {booking.returnDate &&
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography className={`${classes.Typography} ${classes.Text}`}>
                      {`วันที่สิ้นสุด : ${dayjs(booking.returnDate).format("DD MMMM YYYY")}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={`${classes.Typography} ${classes.Text}`}>
                      {`เวลา : ${dayjs(booking.returnStartTime).format(" HH.mm ")} - ${dayjs(booking.returnEndTime).format(" HH.mm ")}`}
                    </Typography>
                  </Grid>
                </>
              }
            </Grid>
            <Divider className={classes.Divider} />
            {props.location.state.typeOfBooking === "CrossArea" &&
            <>
              {booking.passengers.map( (passenger,index) => (
                <Fragment key={passenger.id}>
                  <Typography className={`${classes.Text} ${classes.TextPassenger}`}>ผู้โดยสารคนที่ {index+1}</Typography>
                  <Grid container spacing={1} className={classes.Grid}>
                    <Grid item xs={12} sm={6}>
                      <Typography className={`${classes.Typography} ${classes.Text}`}>ชื่อ : {passenger.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={`${classes.Typography} ${classes.Text}`}>เบอร์โทรศัพท์ : {passenger.telephoneMobile}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={`${classes.Typography} ${classes.Text}`}>email : {passenger.email}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={`${classes.Typography} ${classes.Text}`}>บริษัท : {passenger.company}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={`${classes.Typography} ${classes.Text}`}>Cost Center : {passenger.costCenter}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={`${classes.Typography} ${classes.Text}`}>Cost Element : {passenger.costElement}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={`${classes.Typography} ${classes.Text}`}>จุดรับขาไป : {passenger.fromPlace}</Typography>
                    </Grid>
                    {booking.flight === "twoWay" &&
                      <Grid item xs={12} sm={6}>
                        <Typography className={`${classes.Typography} ${classes.Text}`}>จุดรับขากลับ : {passenger.fromPlaceReturn}</Typography>
                      </Grid>
                    }
                  </Grid>
                  {booking.passengers.length !== (index+1) &&
                    <Divider className={`${classes.DividerPassenger}`}/>
                  }
                </Fragment>
              ))}
              <Divider className={classes.Divider} />
            </>
            }
            {props.location.state.typeOfBooking === "InArea" &&
            <>
              <Grid container spacing={1} className={classes.Grid}>
                <Grid item xs={12} sm={6}>
                  <Typography className={`${classes.Typography} ${classes.Text}`}>บริษัท : {booking.company}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={`${classes.Typography} ${classes.Text}`}>Cost Center : {booking.costCenter}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={`${classes.Typography} ${classes.Text}`}>Cost Element : {booking.costElement}</Typography>
                </Grid>
              </Grid>
              <Divider className={classes.Divider} />
            </>
            }
            <Typography className={` ${classes.TextPrice} ${classes.Title} ${classes.Typography}`}>
              {`ค่าใช้จ่าย  :   6,400  THB   \(ไม่รวมค่าล่วงเวลา\)`}
            </Typography>
            <Divider className={classes.Divider} />
            <GridNavButton container justifyContent="center" spacing={2}>
              <Grid item className={classes.GridButton} xs={12} sm={4}>
                <Button className={`${classes.Button} ${classes.ApproveButton}`} startIcon={<CheckIcon />}>อนุมัติ</Button>
              </Grid>
              <Grid item className={classes.GridButton} xs={12} sm={4}>
                <Button className={`${classes.Button} ${classes.DisapproveButton}`} startIcon={<CloseIcon />}>ไม่อนุมัติ</Button>
              </Grid>
            </GridNavButton>
          </>
        )}
      </Paper>
    </div>
  );
};

export default DetailBookingOrder;
