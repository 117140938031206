import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  styled,
  Stack,
  TextField,
  MenuItem,
  Typography,
  Grid,
  FormControl,
  Button,
  Select,
  Paper,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CateringRequestedCard from "./CateringRequestedCard";
import Container from "@mui/material/Container";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import cateringService from "../../../../../services/catering.service";
import ButtonBlue from "../../../shared/buttonBlue";
import UnicornLoading from "../../../assets/loading.gif";
import { SnackbarProvider, useSnackbar } from "notistack";

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
  "& .TextNoBooking": {
    textAlign: "center",
    fontSize: "25px",
  },
}));

const Text = styled(Typography)({
  fontSize: "15px",
});

const RequestedList = (props) => {
  const [caterings, setCaterings] = useState([]);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { register, getValues, control } = useForm();
  const [company, setCompany] = useState(userProfile?.companyName);
  const [isStartDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const changeStatus = (index, status) => {
    setLoading(true);
    cateringService
      .changeStatusById(index, status)
      .then((res) => {
        if (res.data.data.status) {
          cateringService
            .getAllCaterings({ status: getValues("status") || null })
            .then((res) => {
              setCaterings(res.data.data);
              setLoading(false);
            });
          enqueueSnackbar("Update Status Success", { variant: "success" });
        } else {
          enqueueSnackbar("Update Status Failed", { variant: "error" });
        }
        // caterings[index].status = res.data.data.status;
        // setCaterings([...caterings]);
      })
      .catch((err) => {
        enqueueSnackbar("Update Status Failed", { variant: "error" });
        console.log(err);
      });
  };

  const onClickSearch = () => {
    setLoading(true);
    cateringService
      .getAllCaterings({
        status: getValues("status") || null,
        date: isStartDate,
      })
      .then((res) => {
        setCaterings(res.data.data);
        setLoading(false);
      });
  };

  const onClickCard = (index) => {
    props.history.push(`/catering/requested/${index}`);
  };

  useEffect(() => {
    setLoading(true);
    cateringService.getAllCaterings().then((res) => {
      setCaterings(res.data.data);
      setLoading(false);
    });
  }, []);

  return (
    <Container maxWidth="xl">
      <Root>
        <div className="page">
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <Typography fontSize={32} marginTop={1}>
                รายการร้องขอจัดเลี้ยง
              </Typography>
            </Grid>

            <Grid
              item
              container
              spacing={3}
              xs={12}
              sx={(theme) => ({
                display: "flex",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                marginTop: "0",
                marginLeft: "0",
                marginBottom: "20px",
                padding: "0 25px 25px 0",
                borderRadius: "15px",
              })}
            >
              <Grid item xs={12} md={6} lg={6}>
                <Stack>
                  <Text>ชื่อบริษัท</Text>

                  {userProfile && (
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        placeholder="เลือกบริษัท"
                        id="company"
                        defaultValue={company}
                        onChange={(e) => setCompany(e.target.value)}
                        value={company}
                      >
                        <MenuItem
                          key={userProfile.idCompany}
                          value={userProfile.companyName}
                        >
                          {userProfile.companyName}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={3} lg={3}>
                <Stack>
                  <Text>วันที่จัดเลี้ยง</Text>
                  <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                    <Controller
                      name="date"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <MobileDatePicker
                          {...field}
                          // clearable
                          inputFormat="DD MMMM YYYY"
                          value={isStartDate}
                          componentsProps={{
                            actionBar: {
                              actions: ["clear", "cancel", "accept"],
                            },
                          }}
                          onChange={(date) => setStartDate(date)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              placeholder="วัน เดือน ปี"
                              InputProps={{
                                style: {
                                  borderRadius: "5px",
                                },
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Stack>
                  <Text>สถานะ</Text>
                  <TextField
                    inputProps={{ ...register("status") }}
                    select
                    defaultValue={false}
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "5px",
                      },
                    }}
                  >
                    <MenuItem value={false}>ทั้งหมด</MenuItem>
                    <MenuItem value="pending">รออนุมัติ</MenuItem>
                    <MenuItem value="approved">อนุมัติแล้ว</MenuItem>
                    <MenuItem value="disapproved">ไม่อนุมัติ</MenuItem>
                  </TextField>
                </Stack>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  style={{ marginTop: "10px" }}
                >
                  <ButtonBlue
                    variant="contained"
                    onClick={onClickSearch}
                    style={{
                      maxWidth: "200px",
                      maxHeight: "35px",
                      borderRadius: "15px",
                    }}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </ButtonBlue>
                </Stack>
              </Grid>
            </Grid>
            <Grid item container spacing={3} xs={12}>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Paper
                    sx={{
                      overflow: "hidden",
                      border: "1px solid #EEEEEE",
                      backgroundColor: "#FFFFFF",
                      marginBottom: "20px",
                      padding: "25px 30px 25px 30px",
                      borderRadius: "15px",
                      textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                    }}
                  >
                    <img
                      src={UnicornLoading}
                      alt="Loading..."
                      style={{ width: "300px" }}
                    />
                    {/* ปรับขนาดของภาพตามที่ต้องการ */}
                  </Paper>
                </div>
              ) : (
                <>
                  {caterings.length > 0 ? (
                    caterings
                      .toReversed()
                      .map((catering, index) => (
                        <CateringRequestedCard
                          key={`catering_${catering.idCateringRequest}`}
                          data={catering}
                          onChangeStatusHandler={changeStatus}
                          index={index}
                          onClickCard={onClickCard}
                        />
                      ))
                  ) : (
                    <Grid item xs={12} lg={12} style={{ marginTop: "30px" }}>
                      <Typography className="TextNoBooking">
                        ไม่มีรายการ
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </Root>
    </Container>
  );
};

export default RequestedList;
