import React from "react";
import { 
	useMediaQuery, 
	useTheme, 
	Dialog, 
} from '@mui/material/';

const Modal = ({open, onClose, children, paper, disableFullscreen}) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return(
		<Dialog
			fullScreen={fullScreen && !disableFullscreen}
			open={open}
			onClose={onClose}
			scroll={(fullScreen || paper) ? "paper" : "body"}
		>
			{children}
		</Dialog>
	)
}

export default Modal;