import { DRIVERITEM_FETCHING, DRIVERITEM_FAILED, DRIVERITEM_SUCCESS } from "../actions/types";

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case DRIVERITEM_FETCHING:
            return {...state, result: null, isFetching: true, isError: false};
        case DRIVERITEM_FAILED:
            return {...state, result: null, isFetching: false, isError: true};
        case DRIVERITEM_SUCCESS:
            return {...state, result: payload, isFetching: false, isError: false};
        default:
            return state;
    }
}