import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  styled,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  Box,
  Chip,
  OutlinedInput,
  Divider,
} from "@mui/material";
import Container from "@mui/material/Container";
import { SnackbarProvider, useSnackbar } from "notistack";

import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DateAdapter from "@mui/lab/AdapterDayjs";
import "dayjs/locale/th";
import {
  getOptionHours,
  getOptionMaintenanceTypes,
  getOptionMinutes,
} from "../../../../../../utils/constant";
import UserService from "../../../../../../services/user.service";
import CateringService from "../../../../../../services/catering.service";

import Dropfile from "../../../../shared/Form/DropFile";
import employeeService from "../../../../../../services/employee.service";
import cateringService from "../../../../../../services/catering.service";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1600,
}));

const Form = ({ foodData, onSuccessHandler }) => {
  const location = useLocation();
  const fromRestaurant = location.state;
  console.log(fromRestaurant);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();
  const [isEmployee, setEmployee] = useState(null);
  const [isCateringType, setCateringType] = useState(null);
  const [selected, setSelected] = useState([]);
  const [additionalOption, setAdditionalOption] = useState(null);
  const [menurestaurant, setmenurestaurant] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    console.log(data);
    CateringService.addRequestCatering(data, selected, fromRestaurant).then(
      (res) => {
        if (res.status === 200) {
          data.extraCatering = selected;
          onSuccessHandler(data);
          enqueueSnackbar("Add Request Success", {
            variant: "success",
          });
          window.scroll(0, 0);
        } else {
          enqueueSnackbar("Add Request Fail", {
            variant: "error",
          });
        }
      }
    );
  };
  const selectionChangeHandler = (event) => {
    console.log("event.target.value", event.target.value);
    console.log("selected", selected);
    let temp = [];
    let find = false;
    if (selected.length > 0) {
      for (let i = 0; i < selected.length; i++) {
        if (
          selected[i].idAdditionalOption !==
          event.target.value[event.target.value.length - 1].idAdditionalOption
        ) {
          temp.push(event.target.value[i]);
        } else {
          find = true;
        }
      }
    } else {
      temp.push(event.target.value[event.target.value.length - 1]);
      find = true;
    }
    if (!find) {
      temp.push(event.target.value[event.target.value.length - 1]);
    }

    console.log("temp", temp);
    // setSelected(temp);

    setSelected(temp);
  };

  console.log(selected);

  useEffect(() => {
    employeeService.getEmployeesByIdApproved(currentUser.idUser).then((res) => {
      if (res.data) {
        setEmployee(res.data);
      }
    });
    CateringService.getCateringAdditionalOption()
      .then((res) => {
        if (res.data) {
          setAdditionalOption(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // UserService.getAllManager()
    //   .then((res) => {
    //     if (res.data) {
    //       setEmployee(res.data);
    //       // setEmployee(res.data);
    //       // console.log(res.data)
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    CateringService.getCateringType()
      .then((res) => {
        if (res.data) {
          setCateringType(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (currentUser) {
      UserService.getUserProfile(currentUser.idUser).then((res) => {
        if (res.data) {
          setValue("name", `${res.data.firstname_TH} ${res.data.lastname_TH}`);
          setValue("phoneNumber", res.data.telephoneMobile.replace(/-/g, ""));
          setValue("email", res.data.email);
          setValue("company", res.data.companyName);
          setValue("section", res.data.sectionName);
          setValue("department", res.data.departmentName);
          setValue("costCenter", res.data.costCenterCharge);
          setValue("costElement", res.data.costElementCharge);
        }
      });
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography fontSize={24} fontWeight={500}>
              ขออนุมัติจัดเลี้ยง
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "10px" }}>
            <Typography fontSize={18} fontWeight={300}>
              รายละเอียดผู้แจ้ง
            </Typography>
            <Divider />
          </Grid>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={(theme) => ({
              display: "flex",
              boxShadow:
                "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FBFCFC",
              marginTop: "10px",
              marginBottom: "20px",
              marginLeft: "0px",
              padding: "0 25px 25px 0",

              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {},
              [theme.breakpoints.down("md")]: {},
            })}
          >
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="ชื่อผู้แจ้ง"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("name", { required: true }) }}
                helperText={errors.name ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                error={errors.name ? true : false}
                // InputProps={{
                //   style: {
                //     borderRadius: "10px",
                //   },
                // }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="เบอร์"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("phoneNumber", { required: true }) }}
                helperText={
                  errors.phoneNumber ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                error={errors.phoneNumber ? true : false}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="Email"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("email", { required: true }) }}
                helperText={errors.email ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                error={errors.email ? true : false}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="แผนก"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("section") }}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="ส่วน"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("department") }}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="บริษัท"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("company", { required: true }) }}
                helperText={
                  errors.company ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                error={errors.company ? true : false}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="Cost Center"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("costCenter") }}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="Cost Element"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("costElement") }}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={0} md={4} lg={4}></Grid> */}
          </Grid>

          <Grid item xs={12} style={{ marginTop: "10px" }}>
            <Typography fontSize={18} fontWeight={300}>
              รายละเอียดการจัดเลี้ยง
            </Typography>
            <Divider />
          </Grid>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={(theme) => ({
              display: "flex",
              boxShadow:
                "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FBFCFC",
              marginTop: "10px",
              marginBottom: "20px",
              marginLeft: "0px",
              padding: "0 25px 25px 0",

              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {},
              [theme.breakpoints.down("md")]: {},
            })}
          >
            <Grid item xs={12} md={4} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                <Controller
                  name="date"
                  control={control}
                  defaultValue={null}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MobileDatePicker
                      {...field}
                      label="วันที่"
                      minDate={new Date()}
                      inputFormat="DD MMMM YYYY"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            errors.date ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                          }
                          error={errors.date ? true : false}
                          sx={{
                            ".MuiInputBase-root": {
                              borderRadius: "10px",
                            },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Controller
                name="startTimeHour"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="เวลาจัด (ชั่วโมง)"
                    fullWidth
                    helperText={
                      errors.startTimeHour ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.startTimeHour ? true : false}
                    select
                  >
                    {getOptionHours().map((mt) => (
                      <MenuItem key={`startHour_${mt.value}`} value={mt.value}>
                        {mt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="เวลาจัด (นาที)"
                inputProps={{
                  ...register("startTimeMinute", { required: true }),
                }}
                fullWidth
                helperText={
                  errors.startTimeMinute ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                error={errors.startTimeMinute ? true : false}
                select
                defaultValue={""}
              >
                {getOptionMinutes().map((mt) => (
                  <MenuItem key={`startMinute_${mt.value}`} value={mt.value}>
                    {mt.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="จำนวนคน"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  ...register("numberOfPeople", { required: true }),
                }}
                helperText={
                  errors.numberOfPeople ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                error={errors.numberOfPeople ? true : false}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="จุดส่ง"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("sendTo", { required: true }) }}
                helperText={errors.sendTo ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                error={errors.sendTo ? true : false}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                fullWidth
                label="ประเภทการจัด"
                inputProps={{
                  ...register("cateringType", { required: true }),
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                helperText={
                  errors.cateringType ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.cateringType ? true : false}
                select
                // style={{ minWidth: 520 }}
              >
                {isCateringType != undefined &&
                  isCateringType.map((mt) => (
                    <MenuItem value={mt.idCateringType}>{mt.Type}</MenuItem>
                  ))}
              </TextField>
            </Grid>
            {fromRestaurant == null && (
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  label="งบประมาณการจัดเลี้ยง"
                  inputProps={{ ...register("budget", { required: true }) }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                  fullWidth
                  helperText={
                    errors.description ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                  }
                  error={errors.description ? true : false}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12} lg={12}>
              <InputLabel>รายการจัดเลี้ยงเพิ่มเติม</InputLabel>
              <Select
                multiple
                fullWidth
                value={selected}
                onChange={selectionChangeHandler}
                // sx={{
                //   ".MuiInputBase-input": {
                //     minWidth: "1550px",
                //     minHeight: "45px",

                //   },

                // }}
                style={{ borderRadius: "10px" }}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip
                        key={value.idAdditionalOption}
                        label={value.nameOption}
                        sx={{ marginLeft: "5px" }}
                      />
                    ))}
                  </div>
                )}
              >
                {additionalOption &&
                  additionalOption.length > 0 &&
                  additionalOption.map((item) => (
                    <MenuItem
                      value={{
                        idAdditionalOption: item.idCateringAdditionalOption,
                        nameOption: item.nameOption,
                        costOption: item.costOption,
                      }}
                    >
                      {item.nameOption}
                    </MenuItem>
                  ))}
                {/* <MenuItem value={"พนักงานเสิร์ฟ"}>พนักงานเสิร์ฟ</MenuItem>
                <MenuItem value={"พนักงานตักอาหาร"}>พนักงานตักอาหาร</MenuItem>
                <MenuItem value={"พนักงานบริการ"}>พนักงานบริการ</MenuItem>
                <MenuItem value={"พิธีกร"}>พิธีกร</MenuItem>
                <MenuItem value={"เจ้าหน้าที่รักษาความปลอดภัย"}>
                  เจ้าหน้าที่รักษาความปลอดภัย
                </MenuItem>
                <MenuItem value={"ดนตรี"}>ดนตรี</MenuItem>
                <MenuItem value={"เวที"}>เวที</MenuItem> */}
              </Select>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                label="วัตถุประสงค์"
                // InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("objective", { required: true }) }}
                helperText={
                  errors.objective ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                error={errors.objective ? true : false}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextField
                label="รายละเอียดเพิ่มเติม"
                inputProps={{ ...register("description") }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
          </Grid>

          {isEmployee && (
            <>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Typography fontSize={18} fontWeight={300}>
                  รายละเอียดผู้อนุมัติ
                </Typography>
                <Divider />
              </Grid>
              <Grid
                container
                item
                spacing={3}
                xs={12}
                sx={(theme) => ({
                  display: "flex",
                  boxShadow:
                    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                  border: "1px solid #EEEEEE",
                  backgroundColor: "#FBFCFC",
                  marginTop: "10px",
                  marginBottom: "20px",
                  marginLeft: "0px",
                  padding: "0 25px 25px 0",

                  borderRadius: "15px",
                  [theme.breakpoints.down("sm")]: {},
                  [theme.breakpoints.down("md")]: {},
                })}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    render={(propsInController) => (
                      <Autocomplete
                        options={isEmployee}
                        getOptionLabel={(option) =>
                          `${option.firstname_TH} ${option.lastname_TH}`
                        }
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {`${option.firstname_TH} ${option.lastname_TH}`}
                          </Box>
                        )}
                        value={propsInController.field.value}
                        onChange={(e, value) => {
                          if (value) {
                            setValue(
                              "nameApproved",
                              `${value.firstname_TH} ${value.lastname_TH}`
                            );
                            setValue(
                              "departmentApproved",
                              value.departmentName
                            );
                            setValue("companyApproved", value.companyName);
                            setValue("idApproved", value.idEmployees);
                            propsInController.field.onChange(value);
                            // console.log("propsInController.field.textContent : ",propsInController.field.value)
                          } else {
                            setValue("nameApproved", "");
                            setValue("departmentApproved", "");
                            setValue("companyApproved", "");
                            propsInController.field.onChange(null);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={propsInController.fieldState.error}
                            helperText={
                              propsInController.fieldState.error
                                ? "กรุณาระบุชื่อ"
                                : false
                            }
                            sx={{
                              ".MuiInputBase-root": { borderRadius: "10px" },
                            }}
                            placeholder="ระบุชื่อ-นามสกุลของคนที่จะ Approve"
                            label="ชื่อคนที่อนุมัติ"
                            fullWidth
                          />
                        )}
                      />
                    )}
                    name="Approved"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={null}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        disabled
                        label="แผนกของคนอนุมัติ"
                        fullWidth
                        value={propsInController.field.value}
                        InputLabelProps={{
                          shrink:
                            getValues("departmentApproved") === ""
                              ? false
                              : true,
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    )}
                    name="departmentApproved"
                    control={control}
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        disabled
                        label="บริษัทของผู้อนุมัติ"
                        fullWidth
                        value={propsInController.field.value}
                        InputLabelProps={{
                          shrink:
                            getValues("companyApproved") === "" ? false : true,
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    )}
                    name="companyApproved"
                    control={control}
                    defaultValue=""
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12} container justifyContent="flex-end">
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              style={{
                maxWidth: "150px",
                maxHeight: "60px",
                marginTop: "5px",
                borderRadius: "15px",
                fontSize: "18px",
                backgroundColor: "green",
              }}
              variant="contained"
            >
              ส่งคำขออนุมัติ
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Form;
