import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { styled } from '@mui/styles';
import {
    Card, Grid, Avatar, Typography, IconButton, Button, useMediaQuery
} from '@mui/material';

import RoomIcon from '@mui/icons-material/Room';
import FlagIcon from "@mui/icons-material/Flag";
import WorkIcon from '@mui/icons-material/Work';
import SearchIcon from "@mui/icons-material/Search";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';

require("dayjs/locale/th");
dayjs.locale('th');

const CardStyled = styled(Card)(({theme})=>({
  marginBottom: "20px",
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  justifyContent:"space-between",
  boxShadow: theme.shadows[3],
  [theme.breakpoints.down('lg')]: {
    display: "block",
  },
}));

const GridContainer = styled(Grid)(({theme})=>({
  width: "70%",
  [theme.breakpoints.down('lg')]: {
    width: "100%",
  },
}));

const AvatarAndName = styled("div")(({theme})=>({
  display: "flex",
  [theme.breakpoints.down('sm')]:{
    flexDirection:"column",
    alignItems:"center",
  },
}));

const NameAndType = styled("div")(({theme})=>({
  width: "calc(100% - 140px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.down('sm')]:{
    width: "calc(100% - 30px)",
    padding: "0 15px",
  },
}));

const Name = styled(Typography)({
  color: "#185ADB",
  fontSize: "25px",
});

const TextAndIcon = styled("div")({
  display: "flex",
  marginBottom: "5px",
  alignItems: "center",
  "& .Icon": {
    fontSize: "30px",
    marginRight: "5px",
  },
  "& .Text": {
    color: "#293A80",
  },
  "& .FromPlaceAndToPlace": {
    fontSize: "20px",
  },
});

const Position = styled(Typography)({
  fontSize: "16px",
});

const TextDate = styled(Typography)({
  fontSize: "20px",
});

const GridPlace = styled(Grid)(({theme})=>({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingLeft: "15px",
  "& .DivFromPlaceAndToPlace":{
    flexBasis:"30%",
    [theme.breakpoints.down('md')]:{
      flexBasis:"50%",
    },
  },
  [theme.breakpoints.down('md')]:{
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  [theme.breakpoints.down('sm')]:{
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

const NavButtonOfCard = styled("div")(({theme})=>({
    display:"flex",
    alignItems:"center",
    marginRight:"3vw",
    [theme.breakpoints.down("lg")]:{
        justifyContent:"flex-end",
        marginTop:"10px",
        marginBottom:"10px",
    },
    [theme.breakpoints.down("sm")]:{
        justifyContent:"space-around",
    },
}));

const DetailButton = styled(IconButton)({
    width:"50px",
    height:"50px",
    color: "white",
    backgroundColor: "#979797",
    "&:hover": {
      backgroundColor: "#757575",
    },
});

const CancelIconButton = styled(IconButton)({
    width:"50px",
    height:"50px",
    color: "white",
    backgroundColor: "#cc0000",
    "&:hover": {
      backgroundColor: "#990000",
    },
});

const IconStatus = styled(Avatar)({
    width:"90px",
    height:"90px",
    marginLeft:"20px",
    marginRight:"20px",
});

const TextStatus = styled(Typography)({
    textAlign:"center",
    marginTop:"5px",
    color:"#C3B816",
});

const CancelButton = styled(Button)({
    height:"60px",
    width:"100px",
    borderRadius:"10px",
    backgroundColor:"red",
    "&:hover":{
      backgroundColor:"darkred",
    },
});

const TextCancelButton = styled(Typography)({
    color:"white",
    fontSize:"22px",
});

const AvatarStyled = styled(Avatar)({
  width: "100px",
  height: "100px",
  margin: "20px",
});

const CardRequest = props => {
    const { result: AllUser } = useSelector((state) => state.users);

    const MobileResponse = useMediaQuery('(max-width:600px)');

    const convertDateAndTime = (lawStartDate, lawEndDate, lawtime) => {
        let startDateSetTime = new Date(lawStartDate).setHours(0,0,0,0);
        let endDateSetTime = new Date(lawEndDate).setHours(0,0,0,0);
        let time = dayjs(lawtime).format("HH.mm น.");
        if(startDateSetTime === endDateSetTime){
          return `${dayjs(startDateSetTime).format("DD MMM YYYY")}, ${time}`;
        }
        else{
          let startDate = dayjs(startDateSetTime).format("DD");
          return `${startDate}-${dayjs(endDateSetTime).format("DD MMM YYYY")}, ${time}`
        }
    };
    
    return (
        <CardStyled>
            <GridContainer container>
                <Grid item xs={12} md={6} style={{paddingRight: "0 !important"}}>
                    <AvatarAndName>
                      <AvatarStyled
                          src={`${process.env.REACT_APP_URL}image/profile/${props.driver.image}`}
                          alt={props.driver.image}
                      />
                      <NameAndType>
                          <Name noWrap>
                            {`${props.driver.firstname} ${props.driver.lastname}`}
                          </Name>
                          <TextAndIcon>
                            <WorkIcon className="Icon" />
                            <Position className="Text" noWrap>
                                {props.driver.position}
                            </Position>
                          </TextAndIcon>
                          <TextAndIcon>
                            <CalendarTodayIcon className="Icon" />
                            <TextDate className="Text" >
                                {convertDateAndTime(props.request.startDate, props.request.endDate, props.request.register.time)}
                            </TextDate>
                          </TextAndIcon>
                      </NameAndType>
                    </AvatarAndName>
                </Grid>
                <GridPlace item xs={12} md={6}>
                    <TextAndIcon className="DivFromPlaceAndToPlace">
                      <RoomIcon className="Icon" />
                      <Typography className="FromPlaceAndToPlace Text" noWrap >
                          {props.request.register.fromPlace}
                      </Typography>
                    </TextAndIcon>
                    <TextAndIcon className="DivFromPlaceAndToPlace">
                      <FlagIcon className="Icon" />
                      <Typography className="FromPlaceAndToPlace Text" noWrap >
                          {props.request.register.toPlace}
                      </Typography>
                    </TextAndIcon>
                </GridPlace>
            </GridContainer>
            <NavButtonOfCard>
                 <DetailButton
                    aria-label="SearchIcon"
                    onClick = {()=>props.onClickDetailButtonHandler(props.request.idRegister)}
                    size="large">
                    <SearchIcon />
                </DetailButton>
                <div>
                    <IconStatus
                        src={`../../assets/Image/Icon/waiting.png`} 
                        alt="IconStatus"
                    />
                    <TextStatus>
                        Waiting
                    </TextStatus>
                </div>
                {MobileResponse &&
                    <CancelIconButton>
                        <CloseIcon style={{color:"white"}} />
                    </CancelIconButton>
                }
                {!MobileResponse &&
                    <CancelButton>
                        <TextCancelButton >
                            ยกเลิก
                        </TextCancelButton>
                    </CancelButton>
                }
            </NavButtonOfCard>
        </CardStyled>
    );
};

export default CardRequest;