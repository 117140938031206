import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";
import { keyframes } from "@emotion/react";

import { styled } from "@mui/styles";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

import { Card } from "@mui/material";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  Grid: {
    width: "80%",
    margin: "0 auto",
    marginTop: "20px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },
  Title: {
    textAlign: "center",
    fontSize: "25px",
    margin: "25px 0px",
  },
}));

const CardStyled = styled(Card)(({ theme }) => ({
  marginBottom: "20px",
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  boxShadow: theme.shadows[3],
  transition:
    "transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 0 20px 0.2px",
  },
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
  paddingBottom: "10px",
}));

const alertLoop = keyframes`
  0% {
    background-color: white;
  }
  50% {
    background-color: red;
  }
  100% {
    background-color: white;
  }
`;

const CardStyledV2 = styled(Card)(({ theme }) => ({
  marginBottom: "20px",
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  boxShadow: theme.shadows[3],
  transition:
    "transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 0 20px 0.2px",
  },
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
  paddingBottom: "10px",
  backgroundColor: "red",
  animationDuration: "4s",
  animationIterationCount: "infinite",
  animationName: "alertLoop",
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "100px",
  height: "100px",
  margin: "20px",
  [theme.breakpoints.down("lg")]: {
    width: "80px",
    height: "80px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "140px",
    height: "140px",
    marginBottom: "0px",
  },
}));

const NameAndType = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const TextAndIcon = styled("div")({
  display: "flex",
  marginBottom: "5px",
  "& .Icon": {
    marginRight: "10px",
    color: "#293A80",
  },
  "& .CrossArea": {
    color: "#C400C6 !important",
  },
  "& .CrossAreaPool": {
    color: "#FF6600 !important",
  },
  "& .InArea": {
    color: "#0A81AB !important",
  },
  "& .Delivery": {
    color: "#8B4513 !important",
  },
});

const Text = styled(Typography)({
  color: "#293A80",
});

const DateAndTime = styled("div")({
  "@media (min-width: 700px) and (max-width: 780px)": {
    display: "flex",
  },
});

const DivAvatarAndName = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const TextFirstnameAndLastName = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "block",
    paddingTop: "10px",
  },
  "& .FirstName": {
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
    },
  },
}));

const Name = styled(Typography)({
  color: "#185ADB",
  fontSize: "15px",
  marginBottom: "10px",
});

const IconStatus = styled(Avatar)(({ theme }) => ({
  width: "90px",
  height: "90px",
  [theme.breakpoints.down("sm")]: {
    marginRight: "20px",
  },
}));

const TextStatus = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
});

const ViewButton = styled(Button)({
  border: "1px solid black",
  color: "black",
});

const CardSampleRequest = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const [booking, setBooking] = useState({});

  useEffect(() => {
    if (props.booking) {
      setBooking(props.booking);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/data");
      const data = await response.json();
      setData(data);
    };
    fetchData();
  }, []);

  return (
    <div>
      {props.showHistory === 0 &&
        booking.status != "ได้รับสินค้าเรียบร้อย" &&
        booking.status != "รอรับสินค้า" && (
          <CardStyled
            onClick={() => {
              props.onClickCardHandler(booking.idDeliverySampleShuttle);
            }}
          >
            <GridContainer container>
              <Grid item xs={12} md={7} lg={3}>
                <DivAvatarAndName>
                  <AvatarStyled
                    src={`${process.env.REACT_APP_URL}image/profile/${booking.imageUser}`}
                    // alt={booking.name}
                  />
                  <NameAndType>
                    <Text>{`ชื่อผู้ส่ง`}</Text>
                    <TextFirstnameAndLastName>
                      <Name>{booking.nameSender}</Name>
                    </TextFirstnameAndLastName>
                  </NameAndType>
                </DivAvatarAndName>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5}
                lg={2}
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: "15px" }}
              >
                <Grid item xs={12}>
                  <TextAndIcon style={{ marginRight: "40px" }}>
                    <Text>{`ชื่อผลทดลง`}</Text>
                  </TextAndIcon>
                  <TextFirstnameAndLastName>
                    <Name>{booking.nameSample}</Name>
                  </TextFirstnameAndLastName>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5}
                lg={1.5}
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: "15px" }}
              >
                <Grid item xs={12}>
                  <TextAndIcon style={{ marginRight: "40px" }}>
                    <Text>{`วันที่`}</Text>
                  </TextAndIcon>
                  <TextFirstnameAndLastName>
                    <Name>
                      {new Date(booking.date).toLocaleDateString("th-TH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </Name>
                  </TextFirstnameAndLastName>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5}
                lg={2}
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: "15px" }}
              >
                <Grid item xs={12}>
                  <TextAndIcon style={{ marginRight: "40px" }}>
                    <Text>{`จุดรับ`}</Text>
                  </TextAndIcon>
                  <TextFirstnameAndLastName>
                    <Name>{booking.fromPlaceName}</Name>
                  </TextFirstnameAndLastName>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5}
                lg={2}
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: "15px" }}
              >
                <Grid item xs={12}>
                  <TextAndIcon style={{ marginRight: "40px" }}>
                    <Text>{`จุดส่ง`}</Text>
                  </TextAndIcon>
                  <TextFirstnameAndLastName>
                    <Name>{booking.toPlaceName}</Name>
                  </TextFirstnameAndLastName>
                </Grid>
              </Grid>
              {/* <Grid container item xs={12} md={5} lg={1} spacing={2} alignItems="center" style={{ paddingLeft: "15px" }}>
                                <Grid item xs={12}>

                                    <TextFirstnameAndLastName>
                                        <ArticleIcon className="Icon" />
                                    </TextFirstnameAndLastName>
                                </Grid>
                            </Grid> */}

              <Grid
                container
                item
                xs={12}
                md={5}
                lg={1.5}
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: "15px" }}
              >
                <Grid item xs={12}>
                  <TextAndIcon style={{ marginRight: "40px" }}>
                    <Text>{`สถานะ`}</Text>
                  </TextAndIcon>
                  <TextFirstnameAndLastName>
                    {/* <Name>{booking.status}</Name> */}
                    {booking.status === "ได้รับสินค้าเรียบร้อย" && (
                      <Name
                        style={{ color: "#24944f" }}
                      >{`${booking.status}`}</Name>
                    )}
                    {booking.status === "รอรับสินค้า" && (
                      <Name
                        style={{ color: "#d42121" }}
                      >{`${booking.status}`}</Name>
                    )}
                    {booking.status === "รับสินค้าเรียบร้อย" && (
                      <Name
                        style={{ color: "#dfcf20" }}
                      >{`${booking.status}`}</Name>
                    )}
                    {booking.status === "ส่งสินค้าเรียบร้อย" && (
                      <Name
                        style={{ color: "#d47721" }}
                      >{`${booking.status}`}</Name>
                    )}
                  </TextFirstnameAndLastName>
                </Grid>
              </Grid>
              {/* <Grid
                container
                item
                xs={12}
                md={5}
                lg={1}
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: "15px" }}
              >
                <Grid item xs={12}>
                  <ViewButton
                    onClick={() => {
                      props.onClickCardHandler(booking.idDeliverySampleShuttle);
                    }}
                  >
                    แสดงรายละเอียด
                  </ViewButton>
                </Grid>
              </Grid> */}
            </GridContainer>
          </CardStyled>
        )}
      {props.showHistory === 0 && booking.status == "รอรับสินค้า" && (
        <CardStyledV2
          onClick={() => {
            props.onClickCardHandler(booking.idDeliverySampleShuttle);
          }}
        >
          <GridContainer container>
            <Grid item xs={12} md={7} lg={3}>
              <DivAvatarAndName>
                <AvatarStyled
                  src={`${process.env.REACT_APP_URL}image/profile/${booking.imageUser}`}
                  // alt={booking.name}
                />
                <NameAndType>
                  <Text>{`ชื่อผู้ส่ง`}</Text>
                  <TextFirstnameAndLastName>
                    <Name>{booking.nameSender}</Name>
                  </TextFirstnameAndLastName>
                </NameAndType>
              </DivAvatarAndName>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={5}
              lg={2}
              spacing={2}
              alignItems="center"
              style={{ paddingLeft: "15px" }}
            >
              <Grid item xs={12}>
                <TextAndIcon style={{ marginRight: "40px" }}>
                  <Text>{`ชื่อผลทดลง`}</Text>
                </TextAndIcon>
                <TextFirstnameAndLastName>
                  <Name>{booking.nameSample}</Name>
                </TextFirstnameAndLastName>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={5}
              lg={1.5}
              spacing={2}
              alignItems="center"
              style={{ paddingLeft: "15px" }}
            >
              <Grid item xs={12}>
                <TextAndIcon style={{ marginRight: "40px" }}>
                  <Text>{`วันที่`}</Text>
                </TextAndIcon>
                <TextFirstnameAndLastName>
                  <Name>
                    {new Date(booking.date).toLocaleDateString("th-TH", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </Name>
                </TextFirstnameAndLastName>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={5}
              lg={2}
              spacing={2}
              alignItems="center"
              style={{ paddingLeft: "15px" }}
            >
              <Grid item xs={12}>
                <TextAndIcon style={{ marginRight: "40px" }}>
                  <Text>{`จุดรับ`}</Text>
                </TextAndIcon>
                <TextFirstnameAndLastName>
                  <Name>{booking.fromPlaceName}</Name>
                </TextFirstnameAndLastName>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={5}
              lg={2}
              spacing={2}
              alignItems="center"
              style={{ paddingLeft: "15px" }}
            >
              <Grid item xs={12}>
                <TextAndIcon style={{ marginRight: "40px" }}>
                  <Text>{`จุดส่ง`}</Text>
                </TextAndIcon>
                <TextFirstnameAndLastName>
                  <Name>{booking.toPlaceName}</Name>
                </TextFirstnameAndLastName>
              </Grid>
            </Grid>
            {/* <Grid container item xs={12} md={5} lg={1} spacing={2} alignItems="center" style={{ paddingLeft: "15px" }}>
                                <Grid item xs={12}>

                                    <TextFirstnameAndLastName>
                                        <ArticleIcon className="Icon" />
                                    </TextFirstnameAndLastName>
                                </Grid>
                            </Grid> */}

            <Grid
              container
              item
              xs={12}
              md={5}
              lg={1.5}
              spacing={2}
              alignItems="center"
              style={{ paddingLeft: "15px" }}
            >
              <Grid item xs={12}>
                <TextAndIcon style={{ marginRight: "40px" }}>
                  <Text>{`สถานะ`}</Text>
                </TextAndIcon>
                <TextFirstnameAndLastName>
                  {/* <Name>{booking.status}</Name> */}
                  {booking.status === "ได้รับสินค้าเรียบร้อย" && (
                    <Name
                      style={{ color: "#24944f" }}
                    >{`${booking.status}`}</Name>
                  )}
                  {booking.status === "รอรับสินค้า" && (
                    <Name
                      style={{ color: "#d42121" }}
                    >{`${booking.status}`}</Name>
                  )}
                  {booking.status === "รับสินค้าเรียบร้อย" && (
                    <Name
                      style={{ color: "#dfcf20" }}
                    >{`${booking.status}`}</Name>
                  )}
                  {booking.status === "ส่งสินค้าเรียบร้อย" && (
                    <Name
                      style={{ color: "#d47721" }}
                    >{`${booking.status}`}</Name>
                  )}
                </TextFirstnameAndLastName>
              </Grid>
            </Grid>
            {/* <Grid
              container
              item
              xs={12}
              md={5}
              lg={2}
              spacing={2}
              alignItems="center"
              style={{ paddingLeft: "15px" }}
            >
              <Grid item xs={12}>
                <ViewButton
                  onClick={() => {
                    props.onClickCardHandler(booking.idDeliverySampleShuttle);
                  }}
                >
                  แสดงรายละเอียด
                </ViewButton>
              </Grid>
            </Grid> */}
          </GridContainer>
        </CardStyledV2>
      )}
      {props.showHistory === 1 && booking.status == "ได้รับสินค้าเรียบร้อย" && (
        <CardStyled
          onClick={() => {
            props.onClickCardHandler(booking.idDeliverySampleShuttle);
          }}
        >
          {
            <>
              <GridContainer container>
                <Grid item xs={12} md={7} lg={3}>
                  <DivAvatarAndName>
                    <AvatarStyled
                      src={`${process.env.REACT_APP_URL}image/profile/${booking.imageUser}`}
                      // alt={booking.name}
                    />
                    <NameAndType>
                      <Text>{`ชื่อผู้ส่ง`}</Text>
                      <TextFirstnameAndLastName>
                        <Name>{booking.nameSender}</Name>
                      </TextFirstnameAndLastName>
                    </NameAndType>
                  </DivAvatarAndName>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={5}
                  lg={2}
                  spacing={2}
                  alignItems="center"
                  style={{ paddingLeft: "15px" }}
                >
                  <Grid item xs={12}>
                    <TextAndIcon style={{ marginRight: "40px" }}>
                      <Text>{`ชื่อผลทดลง`}</Text>
                    </TextAndIcon>
                    <TextFirstnameAndLastName>
                      <Name>{booking.nameSample}</Name>
                    </TextFirstnameAndLastName>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={5}
                  lg={2}
                  spacing={2}
                  alignItems="center"
                  style={{ paddingLeft: "15px" }}
                >
                  <Grid item xs={12}>
                    <TextAndIcon style={{ marginRight: "40px" }}>
                      <Text>{`วันที่`}</Text>
                    </TextAndIcon>
                    <TextFirstnameAndLastName>
                      <Name>
                        {new Date(booking.date).toLocaleDateString("th-TH", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </Name>
                    </TextFirstnameAndLastName>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={5}
                  lg={2}
                  spacing={2}
                  alignItems="center"
                  style={{ paddingLeft: "15px" }}
                >
                  <Grid item xs={12}>
                    <TextAndIcon style={{ marginRight: "40px" }}>
                      <Text>{`จุดรับ`}</Text>
                    </TextAndIcon>
                    <TextFirstnameAndLastName>
                      <Name>{booking.fromPlaceName}</Name>
                    </TextFirstnameAndLastName>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={5}
                  lg={2}
                  spacing={2}
                  alignItems="center"
                  style={{ paddingLeft: "15px" }}
                >
                  <Grid item xs={12}>
                    <TextAndIcon style={{ marginRight: "40px" }}>
                      <Text>{`จุดส่ง`}</Text>
                    </TextAndIcon>
                    <TextFirstnameAndLastName>
                      <Name>{booking.toPlaceName}</Name>
                    </TextFirstnameAndLastName>
                  </Grid>
                </Grid>
                {/* <Grid container item xs={12} md={5} lg={1} spacing={2} alignItems="center" style={{ paddingLeft: "15px" }}>
                                <Grid item xs={12}>

                                    <TextFirstnameAndLastName>
                                        <ArticleIcon className="Icon" />
                                    </TextFirstnameAndLastName>
                                </Grid>
                            </Grid> */}

                <Grid
                  container
                  item
                  xs={12}
                  md={5}
                  lg={1}
                  spacing={2}
                  alignItems="center"
                  style={{ paddingLeft: "15px" }}
                >
                  <Grid item xs={12}>
                    <TextAndIcon style={{ marginRight: "40px" }}>
                      <Text>{`สถานะ`}</Text>
                    </TextAndIcon>
                    <TextFirstnameAndLastName>
                      {/* <Name>{booking.status}</Name> */}
                      {booking.status === "ได้รับสินค้าเรียบร้อย" && (
                        <Name
                          style={{ color: "#24944f" }}
                        >{`${booking.status}`}</Name>
                      )}
                      {booking.status === "รอรับสินค้า" && (
                        <Name
                          style={{ color: "#d42121" }}
                        >{`${booking.status}`}</Name>
                      )}
                      {booking.status === "รับสินค้าเรียบร้อย" && (
                        <Name
                          style={{ color: "#dfcf20" }}
                        >{`${booking.status}`}</Name>
                      )}
                      {booking.status === "ส่งสินค้าเรียบร้อย" && (
                        <Name
                          style={{ color: "#d47721" }}
                        >{`${booking.status}`}</Name>
                      )}
                    </TextFirstnameAndLastName>
                  </Grid>
                </Grid>
                {/* <Grid
                  container
                  item
                  xs={12}
                  md={5}
                  lg={2}
                  spacing={2}
                  alignItems="center"
                  style={{ paddingLeft: "15px" }}
                >
                  <Grid item xs={12}>
                    <ViewButton
                      onClick={() => {
                        props.onClickCardHandler(
                          booking.idDeliverySampleShuttle
                        );
                      }}
                    >
                      แสดงรายละเอียด
                    </ViewButton>
                  </Grid>
                </Grid> */}
              </GridContainer>
            </>
          }
        </CardStyled>
      )}
    </div>
  );
};

export default CardSampleRequest;
