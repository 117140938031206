import React, { useState } from 'react';
import { Controller } from "react-hook-form";

import { styled } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
    Typography, Grid, Button, Divider, FormControl, InputLabel, Select, MenuItem, FormControlLabel,
    Checkbox, Modal, Paper, TextField, useMediaQuery
} from "@mui/material";

import { listOptions } from "../../../choise";
import GoogleMap from '../../../../../shared/googleMap';

const useStyles = makeStyles((theme)=>({
    Content:{
        width:"95%",
        height:"100%",
    },
    partOfLocation:{
        height:"49%",
        display:"flex",
        flexDirection:"column",
        maxHeight:"320px",
        [theme.breakpoints.down('lg')]:{
            height:"100%",
            maxHeight:"500px",
        }
    },
    Title:{
        fontSize:"30px",
    },
    GridItems:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },
    ButtonProvince:{
        height:"70px",
        fontSize:"20px",
        border:"2px solid",
        borderColor:theme.palette.grey[500],
        "&:hover":{
            borderColor:theme.palette.primary.main,
        }
    },
    GridContainer:{
        marginBottom:"20px",
    },
    AddressButton:{
        width:"100%",
        height:"45px",
        padding:"0 10px",
        justifyContent:"left",
        border:"2px solid",
        borderColor:theme.palette.grey[500],
        borderRadius:"5px",
        "&:hover":{
            borderColor:theme.palette.primary.main,
        }
    },
    TextButton:{
        fontSize:"20px",
    },
    Divider:{
        height:"2px",
        backgroundColor:theme.palette.grey[500],
    },
    SubTitle:{
        fontSize:"24px",
        marginTop:"10px"
    },
    partOfYourCar:{
        height:"fit-content",
    },
    Modal:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        [theme.breakpoints.down('sm')]:{
            justifyContent:"flex-start"
        }
    },
    Paper:{
        width:"30%",
        minWidth:"430px",
        padding:"20px",
        [theme.breakpoints.down('sm')]:{
            width:"100%",
            minWidth:"initial"
        }
    },
    NextButton:{
        width:"fit-content",
        color:"green",
        fontSize:"18px",
        "&:hover":{
            backgroundColor:"white",
        }
    },
    Map:{
        width:"100%",
        height:"400px",
        marginTop:"15px",
    },
    ConfirmButton:{
        width:"fit-content",
        backgroundColor:"green",
        color:theme.palette.primary.contrastText,
        fontSize:"16px",
        marginLeft:"15px",
        "&:hover":{
            backgroundColor:"darkgreen",
        }
    },
    BackButton:{
        width:"fit-content",
        color:"green",
        fontSize:"16px",
        "&:hover":{
            backgroundColor:"white",
        }
    },
}));

const DivGoogleMap = styled('div')(({theme})=>({
    width:"100%",
    height:"400px",
    marginTop:"15px",
}));

const LocationForm = (props) => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [province, setProvince] = useState(null);
    const [pageNum, setPageNum] = useState(1);

    const MobileResponse = useMediaQuery('(max-width:600px)');

    const onChangeOptionsCheckBox = (id) => {
        if(props.options.includes(id)){
            props.setOptions([...props.options.filter(option => option !== id)]);
        }
        else{
            props.setOptions([...props.options,id]);
        }
    };

    const onClickProvinceButton = (province) => {
        setProvince(province);
        setOpenModal(true);
    };

    return(
        <div className={`${classes.Content}`}>
            <div className={`${classes.partOfLocation}`}>
                <Typography className={`${classes.Title}`}>Your Location</Typography>
                <Grid container spacing={3} className={`${classes.GridContainer}`}>
                    <Grid item xs={6} lg={3} className={`${classes.GridItems}`}>
                        <Button className={`${classes.ButtonProvince}`} fullWidth onClick={()=>onClickProvinceButton("กรุงเทพฯ")}>
                            กรุงเทพฯ
                        </Button>
                    </Grid>
                    <Grid item xs={6} lg={3} className={`${classes.GridItems}`}>
                        <Button className={`${classes.ButtonProvince}`} fullWidth onClick={()=>onClickProvinceButton("ระยอง")}>
                            ระยอง
                        </Button>
                    </Grid>
                    <Grid item xs={6} lg={3} className={`${classes.GridItems}`}>
                        <Button className={`${classes.ButtonProvince}`} fullWidth onClick={()=>onClickProvinceButton("สระบุรี")}>
                            สระบุรี
                        </Button>
                    </Grid>
                    <Grid item xs={6} lg={3} className={`${classes.GridItems}`}>
                        <Button className={`${classes.ButtonProvince}`} fullWidth onClick={()=>onClickProvinceButton("ตรัง")}>
                            ตรัง
                        </Button>
                    </Grid>
                    <Grid item xs={6} lg={3} className={`${classes.GridItems}`}>
                        <Button className={`${classes.ButtonProvince}`} fullWidth onClick={()=>onClickProvinceButton("ขอนแก่น")}>
                            ขอนแก่น
                        </Button>
                    </Grid>
                    <Grid item xs={6} lg={3} className={`${classes.GridItems}`}>
                        <Button className={`${classes.ButtonProvince}`} fullWidth onClick={()=>onClickProvinceButton("กระบี่")}>
                            กระบี่
                        </Button>
                    </Grid>
                    <Grid item xs={6} lg={3} className={`${classes.GridItems}`}>
                        <Button className={`${classes.ButtonProvince}`} fullWidth onClick={()=>onClickProvinceButton("นครนายก")}>
                            นครนายก
                        </Button>
                    </Grid>
                    <Grid item xs={6} lg={3} className={`${classes.GridItems}`}>
                        <Button className={`${classes.ButtonProvince}`} fullWidth onClick={()=>onClickProvinceButton("นครปฐม")}>
                            นครปฐม
                        </Button>
                    </Grid>
                </Grid>
                <Button className={`${classes.AddressButton}`} onClick={()=>setOpenModal(true)}>
                    <Typography className={`${classes.TextButton}`} noWrap>
                        {props.getValues("location") === "" ? "Your Address" : props.getValues("location")}
                    </Typography>
                </Button>
            </div>    
            <Divider className={`${classes.Divider}`}/>
            <div className={`${classes.partOfYourCar}`}>
                <Typography className={`${classes.Title}`}>Your Car</Typography>
                <Grid container spacing={MobileResponse? 1 : 3} style={{marginBottom:"0"}}>
                    <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth style={{marginTop:"10px"}}>
                            <InputLabel id="Brand">ยี่ห้อ</InputLabel>
                            <Controller 
                                control={props.control}
                                name="brand"
                                defaultValue=""
                                render={({field})=>(
                                    <Select
                                        {...field}
                                        labelId="Brand-label"
                                        id="Brand"
                                        label="ยี่ห้อ"
                                    >
                                        <MenuItem value="Toyota">Toyota</MenuItem>
                                        <MenuItem value="Honda">Honda</MenuItem>
                                        <MenuItem value="Tesla">Tesla</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth style={{marginTop:"10px"}}>
                            <InputLabel id="Model">Model</InputLabel>
                            <Controller 
                                control={props.control}
                                name="model"
                                defaultValue=""
                                render={({field})=>(
                                    <Select
                                        {...field}
                                        labelId="Model-label"
                                        id="Model"
                                        label="Model"
                                    >
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth style={{marginTop:"10px"}}>
                            <InputLabel id="Year">Year</InputLabel>
                            <Controller 
                                control={props.control}
                                name="year"
                                defaultValue=""
                                render={({field})=>(
                                    <Select
                                        {...field}
                                        labelId="Year-label"
                                        id="Year"
                                        label="Year"
                                    >
                                        {[...new Array(10)].map((value,index)=>(
                                            <MenuItem key={value+""+index} value={index+1}>{index+1}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Typography className={`${classes.SubTitle}`}>Option</Typography>
                <div>
                    <Grid container spacing={1} style={{width:"100%"}}>
                        {listOptions.map((option,index)=>(
                            <Grid item xs={12} sm={6} lg={4} key={option}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props.options.includes(option.id)}
                                            onChange={()=>onChangeOptionsCheckBox(option.id)} 
                                            name={option.name}
                                        />
                                    }
                                    label={option.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
            <div style={{width:"100%",display:"flex",justifyContent:"flex-end",padding:"10px 0"}}>
                <Button className={`${classes.NextButton}`} onClick={props.onClickNextProcess}>Next</Button>
            </div>
            <Modal 
               open={openModal}
               onClose={()=>{setOpenModal(false);setProvince(null);setPageNum(1);}} 
               className={`${classes.Modal}`}
            >
                <Paper className={`${classes.Paper}`}>
                    { pageNum === 1 &&
                    <>
                        <FormControl variant="outlined" fullWidth style={{marginTop:"10px"}}>
                            <InputLabel id="Province">จังหวัด</InputLabel>
                            <Controller 
                                control={props.control}
                                name="province"
                                defaultValue={province? province : ""}
                                render={({field})=>(
                                    <Select
                                        {...field}
                                        labelId="Province-label"
                                        id="Province"
                                        label="จังหวัด"
                                    >
                                        <MenuItem value="กรุงเทพฯ">กรุงเทพฯ</MenuItem>
                                        <MenuItem value="ระยอง">ระยอง</MenuItem>
                                        <MenuItem value="สระบุรี">สระบุรี</MenuItem>
                                        <MenuItem value="ตรัง">ตรัง</MenuItem>
                                        <MenuItem value="ขอนแก่น">ขอนแก่น</MenuItem>
                                        <MenuItem value="กระบี่">กระบี่</MenuItem>
                                        <MenuItem value="นครนายก">นครนายก</MenuItem>
                                        <MenuItem value="นครปฐม">นครปฐม</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                        <FormControl variant="outlined" fullWidth style={{marginTop:"10px"}}>
                            <InputLabel id="District">เขต/อำเภอ</InputLabel>
                            <Controller 
                                control={props.control}
                                name="district"
                                defaultValue=""
                                render={({field})=>(
                                    <Select
                                        {...field}
                                        labelId="District-label"
                                        id="District"
                                        label="อำเภอ"
                                    >
                                        <MenuItem value="กรุงเทพฯ">กรุงเทพฯ</MenuItem>
                                        <MenuItem value="ระยอง">ระยอง</MenuItem>
                                        <MenuItem value="สระบุรี">สระบุรี</MenuItem>
                                        <MenuItem value="ตรัง">ตรัง</MenuItem>
                                        <MenuItem value="ขอนแก่น">ขอนแก่น</MenuItem>
                                        <MenuItem value="กระบี่">กระบี่</MenuItem>
                                        <MenuItem value="นครนายก">นครนายก</MenuItem>
                                        <MenuItem value="นครปฐม">นครปฐม</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                        <FormControl variant="outlined" fullWidth style={{marginTop:"10px"}}>
                            <InputLabel id="Subdistrict">แขวง/ตำบล</InputLabel>
                            <Controller 
                                control={props.control}
                                name="subdistrict"
                                defaultValue=""
                                render={({field})=>(
                                    <Select
                                        {...field}
                                        labelId="Subdistrict-label"
                                        id="Subdistrict"
                                        label="เขต/ตำบล"
                                    >
                                        <MenuItem value="กรุงเทพฯ">กรุงเทพฯ</MenuItem>
                                        <MenuItem value="ระยอง">ระยอง</MenuItem>
                                        <MenuItem value="สระบุรี">สระบุรี</MenuItem>
                                        <MenuItem value="ตรัง">ตรัง</MenuItem>
                                        <MenuItem value="ขอนแก่น">ขอนแก่น</MenuItem>
                                        <MenuItem value="กระบี่">กระบี่</MenuItem>
                                        <MenuItem value="นครนายก">นครนายก</MenuItem>
                                        <MenuItem value="นครปฐม">นครปฐม</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                        <FormControl variant="outlined" fullWidth style={{marginTop:"10px"}}>
                            <InputLabel id="zipcode">ZIP code</InputLabel>
                            <Controller 
                                control={props.control}
                                name="zipcode"
                                defaultValue=""
                                render={({field})=>(
                                    <Select
                                        {...field}
                                        labelId="zipcode-label"
                                        id="zipcode"
                                        label="ZIP code"
                                    >
                                        <MenuItem value="10150">10150</MenuItem>
                                        <MenuItem value="10180">10180</MenuItem>
                                        <MenuItem value="21120">21120</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                        <div style={{width:"100%",display:"flex", justifyContent:"flex-end", marginTop:"10px"}}>
                            <Button className={`${classes.NextButton}`} onClick={()=>setPageNum(2)}>
                                Next
                            </Button>
                        </div>
                    </>
                    }
                    { pageNum === 2 && 
                    <>
                        <TextField id="address" label="ที่อยู่" variant="outlined" fullWidth inputProps={{...props.register("address")}} />
                        <DivGoogleMap>
                            <GoogleMap></GoogleMap>
                        </DivGoogleMap>
                        <div style={{display:"flex", justifyContent:"flex-end", marginTop:"5px",}}>
                            <Button className={`${classes.BackButton}`} onClick={()=>setPageNum(1)}>
                                Back
                            </Button>
                            <Button className={`${classes.ConfirmButton}`} onClick={()=>setPageNum(2)}>
                                Confirm
                            </Button>
                        </div>
                    </>
                    }
                </Paper>
            </Modal>
        </div>
    );
};

export default LocationForm;