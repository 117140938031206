import React, { Fragment, useState, useEffect } from "react";
import { styled, Divider, InputAdornment } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import Container from "@mui/material/Container";

import {
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Box,
  IconButton,
  Stack,
  Paper,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CircularProgress from "@mui/material/CircularProgress";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import MeetingRoomService from "../../../../../../services/meetingRoom.service";
import { SnackbarProvider, useSnackbar } from "notistack";
import UnicornLoading from "../../../../assets/loading.gif";
import CardSampleRequest from "./Card/index";

const StyledRoot = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",

    width: "100%",
  },
  "& .divCoverReturn": {
    width: "100%",
    display: "flex",
    paddingTop: "100px",
    height: "calc(100% - 30px)",
  },
  "& .Grid": {
    width: "100%",

    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",

      // flexDirection:"column",
    },
  },
  "& .tabBar": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .Header": {
    display: "flex",
    alignItems: "center",
  },
  "& .title": {
    textAlign: "center",
    width: "100%",
  },
  "& .CreateButton": {
    height: "50px",
    width: "200px",
    display: "flex",
    color: "white",
    fontSize: "15px",
    borderRadius: "10px",
    margin: "3px",
    backgroundColor: "#436ADC",
    ["&:hover"]: {
      backgroundColor: "#436ADC",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  "& .IconImage": {
    weight: "24px",
    height: "24px",
    marginRight: "5px",
  },
  "& .content": {
    marginTop: "15px",
  },
  "& .DataPicker": {
    width: "auto",
    marginRight: "0px",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      marginRight: "0px",
    },
  },
  "& .datePicker": {
    width: "35%",
  },
  "& .TextNoBooking": {
    textAlign: "center",
    fontSize: "25px",
  },
}));

const Text = styled(Typography)({
  fontSize: "15px",
});

const DetailMeetingRoomBooking = (props) => {
  const { register, getValues, control, setValue } = useForm();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [company, setCompany] = useState(userProfile?.companyName);
  const [startDate, setStartDate] = useState(null);
  const [isShowAllBooking, setShowAllBooking] = useState(true);
  const [isBooikingList, setBookinglist] = useState([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const showAllBooking = () => {
    if (isShowAllBooking === true) {
      setShowAllBooking(false);
    } else {
      setShowAllBooking(true);
    }
  };
  const onClickCardHandler = (idMeeting) => {
    props.history.push(`/admin/Newmeetingroom/requested/${idMeeting}`);
    // console.log("onClickCardHandler");
  };

  const changeStatus = (index, status) => {
    setLoading(true);
    MeetingRoomService.postApprovedlMeetingRoomBooking({
      idBooking: index,
      status: status,
    })
      .then((res) => {
        if (res.status === 200) {
          setBookinglist(res.data);
          setLoading(false);
          enqueueSnackbar("Update Status Success", { variant: "success" });
          resetFilter();
        } else {
          setLoading(false);
          enqueueSnackbar("Update Status Failed", { variant: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("Update Status Failed", { variant: "error" });
        setLoading(false);
      });
  };

  const resetFilter = () => {
    setValue("nameUser", "");
    setStartDate(null);
    setValue("status", false);
  };

  const onClickSearch = () => {
    const filter = {
      nameUser: getValues("nameUser"),
      startDate: startDate,
      status: getValues("status"),
    };
    setLoading(true);
    MeetingRoomService.getAllMeetingRoomBookingAndAllMeetingRoom(filter)
      .then((res) => {
        console.log(res.status);

        if (res.status === 200) {
          setBookinglist(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          setBookinglist([]);

          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
        setBookinglist([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    resetFilter();
    setLoading(true);
    const filter = {
      nameUser: "",
      startDate: null,
      status: false,
    };
    MeetingRoomService.getAllMeetingRoomBookingAndAllMeetingRoom(filter)
      .then((res) => {
        if (res.status == 200) {
          setBookinglist(res.data);
          setLoading(false);
        } else {
          setBookinglist([]);
          setLoading(false);
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
        setBookinglist([]);
        setLoading(false);
      });
  }, []);

  return (
    <Container maxWidth="xl">
      <StyledRoot>
        <div className="page">
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <Typography fontSize={32} fontWeight={500} className="Title">
                คำขออนุมัติการจองห้องสถานที่
              </Typography>
            </Grid>
            <Grid
              item
              container
              spacing={3}
              xs={12}
              sx={(theme) => ({
                display: "flex",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                marginTop: "0",
                marginLeft: "0",
                marginBottom: "20px",
                padding: "0 25px 25px 0",
                borderRadius: "15px",
              })}
            >
              <Grid item xs={12} md={12} lg={12}>
                <Stack>
                  <Text>ชื่อบริษัท</Text>

                  {userProfile && (
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        placeholder="เลือกบริษัท"
                        id="company"
                        defaultValue={company}
                        onChange={(e) => setCompany(e.target.value)}
                        value={company}
                      >
                        <MenuItem
                          key={userProfile.idCompany}
                          value={userProfile.companyName}
                        >
                          {userProfile.companyName}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Stack>
                  <Text>ชื่อผู้จอง</Text>

                  <TextField
                    placeholder="ชื่อ - นามสกุล"
                    fullWidth
                    inputProps={{ ...register("nameUser") }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear text"
                            onClick={() => setValue("nameUser", "")}
                          >
                            <ClearRoundedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={3} lg={3}>
                <Stack>
                  <Text>วันที่จองสถานที่</Text>
                  <Controller
                    name="date"
                    defaultValue={null}
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        locale="th"
                      >
                        <MobileDatePicker
                          {...field}
                          // clearable
                          inputFormat="DD MMMM YYYY"
                          value={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                          }}
                          componentsProps={{
                            actionBar: {
                              actions: ["clear", "cancel", "accept"],
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              placeholder="วัน เดือน ปี"
                              InputProps={{
                                style: {
                                  borderRadius: "5px",
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={3} lg={3}>
                <Stack>
                  <Text>สถานะ</Text>

                  <TextField
                    select
                    inputProps={{ ...register("status") }}
                    defaultValue={false}
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "5px",
                      },
                    }}
                  >
                    <MenuItem value={false}>ทั้งหมด</MenuItem>
                    <MenuItem value="Pending">รออนุมัติ</MenuItem>
                    <MenuItem value="Success">อนุมัติแล้ว</MenuItem>
                    <MenuItem value="Reject">ไม่อนุมัติ</MenuItem>
                  </TextField>
                </Stack>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  style={{ marginTop: "10px" }}
                >
                  <Button
                    variant="contained"
                    onClick={onClickSearch}
                    style={{
                      maxWidth: "200px",
                      maxHeight: "35px",
                      borderRadius: "15px",
                    }}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </Button>
                </Stack>
              </Grid>
            </Grid>

            <Grid item container spacing={3} xs={12}>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Paper
                    sx={{
                      overflow: "hidden",
                      border: "1px solid #EEEEEE",
                      backgroundColor: "#FFFFFF",
                      marginBottom: "20px",
                      padding: "25px 30px 25px 30px",
                      borderRadius: "15px",
                      textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                    }}
                  >
                    <img
                      src={UnicornLoading}
                      alt="Loading..."
                      style={{ width: "300px" }}
                    />
                    {/* ปรับขนาดของภาพตามที่ต้องการ */}
                  </Paper>
                </div>
              ) : (
                <>
                  {isBooikingList.length > 0 ? (
                    isBooikingList
                      .toReversed()
                      .map((book, index) => (
                        <CardSampleRequest
                          key={`meeting_room_booking_${book.idNewMeetingRoomBooking}`}
                          booking={book}
                          onChangeStatusHandler={changeStatus}
                          index={index}
                          onClickCard={onClickCardHandler}
                        />
                      ))
                  ) : (
                    <Grid item xs={12} lg={12} style={{ marginTop: "30px" }}>
                      <Typography className="TextNoBooking">
                        ไม่มีรายการ
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </StyledRoot>
    </Container>
  );
};
export default DetailMeetingRoomBooking;
