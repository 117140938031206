import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import clsx from "clsx";

import {
    Avatar, Typography, TextField, Grid, Divider, FormControl, InputLabel, Select, MenuItem, Button,
} from "@mui/material";
import { styled } from "@mui/styles";

const DivCoverReturn = styled("div")(({theme})=>({
    height:"fit-content",
    display:"flex",
    justifyContent:"center",
}));

const Content = styled("div")(({theme})=>({
    width:"80%",
    height:"fit-content",
    paddingBottom:"20px",
}));

const Profile = styled("div")(({theme})=>({
    width:"100%",
    display:"flex",
    marginTop:"20px",
    alignItems:"center",
    [theme.breakpoints.down('md')]:{
        flexDirection:"column",
    }
}));

const AvatarStyled = styled(Avatar)(({theme})=>({
    width:"160px",
    height:"160px",
    marginRight:"30px",
    [theme.breakpoints.down('md')]:{
        width:"130px",
        height:"130px",
        marginBottom:"20px",
    }
}));

const DividerStyled = styled(Divider)(({theme})=>({
    height:"1px",
    backgroundColor:theme.palette.grey[300],
    margin:"20px 0",
}));

const AddImage = styled("div")(({theme})=>({
    width:"100%",
    height:"300px",
    border:"1px solid",
    borderColor:theme.palette.grey[400],
    borderRadius:"5px",
    "& .DragAndDrop":{
        width:"100%",
        height:"100%",
        overflowY:"auto",
        "& span":{
            flexDirection:"column",
        }
    },
    "& .DragAndDropHavingPicture":{
        justifyContent:"flex-start",
    },
    "& .partOfDragAndDropArea":{
        width:"100%",
        height:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        [theme.breakpoints.down('sm')]:{
            flexDirection:"column"
        }
    },
    "& .partOfDragAndDropAreaHavingPicture":{
        flexDirection:"row",
    },
    "& .IconDragAndDrop":{
        fontSize:"80px",
        color:theme.palette.grey[500],
    },
    "& .TextDragAndDrop":{
        fontSize:"26px",
        fontWeight:"bold",
        color:theme.palette.grey[500],
        [theme.breakpoints.down('sm')]:{
            textAlign:"center"
        }
    },
}));

const NavButton = styled("div")(({theme})=>({
    display:"flex",
    justifyContent:"flex-end",
}));

const ConfirmButton = styled(Button)(({theme})=>({
    backgroundColor:theme.palette.success.main,
    color:theme.palette.success.contrastText,
    padding:"10px 20px",
    fontSize:"18px",
    "&:hover":{
        backgroundColor:theme.palette.success.dark,
    },
}));

const TopicTypography = styled(Typography)(({theme})=>({
    fontSize:"26px",
    marginBottom:"10px",
}));

const ContentImage = styled("div")(({theme})=>({
    height:"100%",
    width:"100%",
    
}));

const Image = styled("img")(({theme})=>({
    height:"100%",
    width:"100%",
    objectFit:"contain",
}));

const DivTextDropBox = styled("div")(({theme})=>({
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginLeft:"20px",
    [theme.breakpoints.down('sm')]:{
        marginLeft:"0"
    }
}));

const DependabilityBorrower = props => {
    const { register, control, getValues, setValue } = useForm();
    const [imageDriverCard, setImageDriverCard] = useState([]);

    const validationFile = (file) => {
        const types = ["image/jpg","image/jpeg","image/png"];
        if(types.includes(file.type)){
            return true;
        }
        return false;
    }

    const onDragHandler = event => {
        event.preventDefault();
    };

    const onDropHandler = event => {
        event.preventDefault();
        var Images = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        let TempFiles = [...imageDriverCard];
        [...new Array(Images.length)].map((value,index)=>{
            if(validationFile(Images[index])){
                TempFiles.push(Images[index]);
            }
        })
        if(TempFiles.length > 0){
            setImageDriverCard(TempFiles);
        }
    };

    return(
        <DivCoverReturn className="page">
            <Content>
                <Profile>
                    <AvatarStyled />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="ชื่อ"
                                fullWidth
                                inputProps={{...register("firstname")}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="นามสกุล"
                                fullWidth
                                inputProps={{...register("lastname")}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="เบอร์โทรศัพท์"
                                fullWidth
                                inputProps={{...register("phonenumber")}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Email"
                                fullWidth
                                inputProps={{...register("email")}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="LineId"
                                fullWidth
                                inputProps={{...register("lineid")}}
                            />
                        </Grid>
                    </Grid>
                </Profile>
                <DividerStyled />
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="Occupation">อาชีพ</InputLabel>
                                <Controller 
                                    control={control}
                                    name="occupation"
                                    defaultValue=""
                                    render={({field})=>(
                                        <Select
                                            {...field}
                                            labelId="Occupation-label"
                                            id="Occupation"
                                            label="อาชีพ"
                                        >
                                            <MenuItem value="เจ้าของกิจการ">เจ้าของกิจการ</MenuItem>
                                            <MenuItem value="พนักงานบริษัทเอกชน">พนักงานบริษัทเอกชน</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <TextField 
                                id="salary" 
                                label="รายได้" 
                                variant="outlined" 
                                fullWidth 
                                inputProps={{...register("salary")}} 
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="Education">วุฒิการศึกษา</InputLabel>
                                <Controller 
                                    control={control}
                                    name="education"
                                    defaultValue=""
                                    render={({field})=>(
                                        <Select
                                            {...field}
                                            labelId="Education-label"
                                            id="Education"
                                            label="วุฒิการศึกษา"
                                        >
                                            <MenuItem value="มัธยมศึกษาตอนต้น(ม.1-3)">มัธยมศึกษาตอนต้น(ม.1-3)</MenuItem>
                                            <MenuItem value="มัธยมศึกษาตอนปลาย(ม.4-6)">มัธยมศึกษาตอนปลาย(ม.4-6)</MenuItem>
                                            <MenuItem value="ประกาศนียบัตรวิชาชีพ(ปวช.)">ประกาศนียบัตรวิชาชีพ(ปวช.)</MenuItem>
                                            <MenuItem value="อนุปริญญา">อนุปริญญา</MenuItem>
                                            <MenuItem value="ปริญญาตรี">ปริญญาตรี</MenuItem>
                                            <MenuItem value="สูงกว่าปริญญาตรี">สูงกว่าปริญญาตรี</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <DividerStyled />
                <div>
                    <TopicTypography>รูปใบขับขี่</TopicTypography>
                    <AddImage 
                        onDragOver={onDragHandler}
                        onDragEnter={onDragHandler}
                        onDragLeave={onDragHandler}
                        onDrop={(event)=>onDropHandler(event)}
                    >
                        <Button className="DragAndDrop" component="label" disableRipple disableFocusRipple>
                            <input type="file" onChange={(event)=>onDropHandler(event)} hidden/>
                            <div className="partOfDragAndDropArea">
                                {imageDriverCard.length > 0 ?
                                    imageDriverCard.map((image)=>(
                                        <ContentImage key={image.name}>
                                            <Image  src={URL.createObjectURL(image)} />
                                        </ContentImage>
                                    )) 
                                    :
                                    <>
                                        <span className="IconDragAndDrop"><i className="fas fa-cloud-upload-alt"></i></span>
                                        <DivTextDropBox>
                                            <Typography className="TextDragAndDrop">{"Drag & Drop to Upload File"}</Typography>
                                            <Typography className="TextDragAndDrop" style={{fontSize:"16px",fontWeight:"normal"}}>Or</Typography>
                                            <Typography className="TextDragAndDrop" style={{fontSize:"20px",}}>Click this Area</Typography>
                                        </DivTextDropBox>
                                    </>
                                }
                            </div>
                        </Button>
                    </AddImage>
                </div>
                <DividerStyled />
                <NavButton>
                    <ConfirmButton>Confirm</ConfirmButton>
                </NavButton>
            </Content>
        </DivCoverReturn>
    );
};

export default DependabilityBorrower;