import React from 'react';
import Chart from "react-apexcharts";

const ChartOfScore = props => {
    const option = {
          
        series: [(props.point/5)*100],
        options: {
          chart: {
            offsetY: -25,
            sparkline: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%"
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: '97%',
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY:-10,
                  fontSize: '48px',
                  fontWeight:"bold",
                  formatter: (val) => ((val/100)*5) ,
                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              shadeIntensity: 0.4,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 53, 91]
            },
          },
          labels: ['Average Results'],
        }
    };

    return(
        <Chart 
            options={option.options} 
            series={option.series} 
            type="radialBar"
            width={props.width}
            height={props.width}
        />
    );
};

export default ChartOfScore;