import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";

import FormEachEmployee from "../../../shared/formBooking/formEachEmployee";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import GoogleMap from "./GoogleMap";

const useStyles = makeStyles((theme) => ({
  Text: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  radioGroup: {
    flexDirection: "row",
  },
  map: {
    width: "100%",
    height: "300px",
  },
}));

const FormDetailOfFight = (props) => {
  const classes = useStyles();
  const [dataFrom, setDataFrom] = useState([
    ...new Array(parseInt(props.getValues("numberOfPassenger"))),
  ]);

  const [dataFromReturn, setDataFromReturn] = useState([
    ...new Array(parseInt(props.getValues("numberOfPassenger"))),
  ]);

  const getDataFromGoogleMapFromPlace = (data, index) => {
    console.log("fromPlace : " + data, index);
    const listTemp = [...dataFrom];
    listTemp[index - 1] = data;
    setDataFrom(listTemp);
    props.setValue(`listPassenger.person${index}.fromPlace`, data);
  };

  const getDataFromGoogleMapFromReturnPlace = (data, index) => {
    console.log("fromPlaceReturn : " + data, index);
    const listTemp = [...dataFromReturn];
    listTemp[index - 1] = data;
    setDataFromReturn(listTemp);
    props.setValue(`listPassenger.person${index}.fromPlaceReturn`, data);
  };

  return (
    <Grid container item xs={12} spacing={0}>
      <Grid container item xs={12} spacing={3} sx={{ marginBottom: "30px" }}>
        <Grid item xs={12}>
          <FormControl>
            <Controller
              rules={{ required: true }}
              control={props.control}
              defaultValue="onePoint"
              name="gettingPoint"
              render={(props) => (
                <RadioGroup
                  className={classes.radioGroup}
                  value={props.field.value}
                  onChange={props.field.onChange}
                >
                  <Grid item>
                    <FormControlLabel
                      value="onePoint"
                      control={
                        <Radio checked={props.field.value === "onePoint"} />
                      }
                      label="จุดเดียว"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value="manyPoint"
                      control={
                        <Radio checked={props.field.value === "manyPoint"} />
                      }
                      label="ระบุจุดรายคน"
                    />
                  </Grid>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>

      {props.watch("gettingPoint") === "onePoint" && (
        <Grid
          container
          item
          xs={12}
          spacing={3}
          marginBottom={12}
          sx={(theme) => ({
            display: "flex",
            // justifyContent: "space-between",
            border: "1px solid #EEEEEE",
            backgroundColor: "#FBFCFC",
            boxShadow:
              "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
            marginBottom: "20px",
            marginLeft: "0px",
            padding: "0 25px 25px 0",

            borderRadius: "15px",
            [theme.breakpoints.down("sm")]: {
              // padding: "25px 30px 25px 15px",
            },
            [theme.breakpoints.down("md")]: {
              // padding: "25px 30px 25px 15px",
            },
          })}
        >
          <FormEachEmployee
            key={1}
            register={props.register}
            index={1}
            control={props.control}
            watch={props.watch}
            setValue={props.setValue}
          />

          <Grid item xs={12} sm={props.watch("flight") === "twoWay" ? 6 : 12}>
            <Grid item xs={12} sm={12}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    placeholder="ระบุจุดรับขาไป"
                    label="จุดรับขาไป"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                    error={!!propsInController.fieldState.error}
                    helperText={
                      propsInController.fieldState.error
                        ? propsInController.fieldState.error.message
                        : ""
                    }
                    InputProps={{
                      style: {
                        borderRadius: "15px",
                      },
                    }}
                  />
                )}
                name={`listPassenger.person1.fromPlace`}
                control={props.control}
                defaultValue=""
                rules={{
                  required: "กรุณาระบุจุดรับ",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{ height: "400px" }}>
              <GoogleMap
                name={`listPassenger.person1.fromPlace`}
                inputProps={{
                  ...props.setValue(
                    `listPassenger.person1.fromPlace`,
                    dataFrom[0]
                  ),
                }}
                value={dataFrom[0]}
                index={1}
                handleGetDataFromGoogleMap={getDataFromGoogleMapFromPlace}
              />
            </Grid>
          </Grid>
          {props.watch("flight") === "twoWay" && (
            <Grid item xs={12} sm={6}>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={(propsInController) => (
                    <TextField
                      placeholder="ระบุจุดรับขากลับ"
                      label="จุดรับขากลับ"
                      variant="outlined"
                      fullWidth
                      disabled
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                      error={!!propsInController.fieldState.error}
                      helperText={
                        propsInController.fieldState.error
                          ? propsInController.fieldState.error.message
                          : ""
                      }
                      InputProps={{
                        style: {
                          borderRadius: "15px",
                        },
                      }}
                    />
                  )}
                  name={`listPassenger.person1.fromPlaceReturn`}
                  control={props.control}
                  defaultValue=""
                  rules={{
                    required: "กรุณาระบุจุดรับขากลับ",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} style={{ height: "400px" }}>
                <GoogleMap
                  name={`listPassenger.person1.fromPlaceReturn`}
                  inputProps={{
                    ...props.setValue(
                      `listPassenger.person1.fromPlaceReturn`,
                      dataFromReturn[0]
                    ),
                  }}
                  value={dataFromReturn[0]}
                  index={1}
                  handleGetDataFromGoogleMap={
                    getDataFromGoogleMapFromReturnPlace
                  }
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {parseInt(props.getValues("numberOfPassenger")) >= 1 &&
        props.getValues("gettingPoint") === "manyPoint" &&
        [...new Array(parseInt(props.getValues("numberOfPassenger")))].map(
          (value, index) => {
            return (
              <Grid
                container
                item
                xs={12}
                spacing={3}
                key={`${value}_${index}`}
                marginBottom={12}
                sx={(theme) => ({
                  display: "flex",
                  // justifyContent: "space-between",
                  border: "1px solid #EEEEEE",
                  backgroundColor: "#FBFCFC",
                  boxShadow:
                    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                  marginBottom: "40px",
                  marginLeft: "0px",

                  padding: "0 25px 25px 0",
                  // padding: "25px 30px 25px 30px",

                  borderRadius: "15px",
                  [theme.breakpoints.down("sm")]: {
                    // padding: "25px 30px 25px 15px",
                  },
                  [theme.breakpoints.down("md")]: {
                    // padding: "25px 30px 25px 15px",
                  },
                })}
              >
                {props.getValues("gettingPoint") === "manyPoint" && (
                  <Grid
                    item
                    xs={12}
                    className={classes.Text}
                    style={{ marginTop: "10px" }}
                  >
                    คนที่ {index + 1}
                    <Divider />
                  </Grid>
                )}
                <FormEachEmployee
                  key={value + index}
                  register={props.register}
                  index={index + 1}
                  control={props.control}
                  watch={props.watch}
                  setValue={props.setValue}
                />

                <Grid
                  item
                  xs={12}
                  sm={props.watch("flight") === "twoWay" ? 6 : 12}
                >
                  <Grid item xs={12} sm={12}>
                    <Controller
                      render={(propsInController) => (
                        <TextField
                          placeholder="ระบุจุดรับขาไป"
                          label="จุดรับขาไป"
                          variant="outlined"
                          fullWidth
                          disabled
                          value={propsInController.field.value}
                          onChange={propsInController.field.onChange}
                          error={!!propsInController.fieldState.error}
                          helperText={
                            propsInController.fieldState.error
                              ? propsInController.fieldState.error.message
                              : ""
                          }
                          InputProps={{
                            style: {
                              borderRadius: "15px",
                            },
                          }}
                        />
                      )}
                      name={`listPassenger.person${index + 1}.fromPlace`}
                      control={props.control}
                      defaultValue=""
                      rules={{
                        required: "กรุณาระบุจุดรับ",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ height: "400px" }}>
                    <GoogleMap
                      name={`listPassenger.person${index + 1}.fromPlace`}
                      inputProps={{
                        ...props.setValue(
                          `listPassenger.person${index + 1}.fromPlace`,
                          dataFrom[index]
                        ),
                      }}
                      value={dataFrom[index]}
                      index={index + 1}
                      handleGetDataFromGoogleMap={getDataFromGoogleMapFromPlace}
                    />
                  </Grid>
                </Grid>
                {props.watch("flight") === "twoWay" && (
                  <Grid item xs={12} sm={6}>
                    <Grid item xs={12} sm={12}>
                      <Controller
                        render={(propsInController) => (
                          <TextField
                            placeholder="ระบุจุดรับขากลับ"
                            label="จุดรับขากลับ"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={propsInController.field.value}
                            onChange={propsInController.field.onChange}
                            error={!!propsInController.fieldState.error}
                            helperText={
                              propsInController.fieldState.error
                                ? propsInController.fieldState.error.message
                                : ""
                            }
                            InputProps={{
                              style: {
                                borderRadius: "15px",
                              },
                            }}
                          />
                        )}
                        name={`listPassenger.person${
                          index + 1
                        }.fromPlaceReturn`}
                        control={props.control}
                        defaultValue=""
                        rules={{
                          required: "กรุณาระบุจุดรับขากลับ",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ height: "400px" }}>
                      <GoogleMap
                        name={`listPassenger.person${
                          index + 1
                        }.fromPlaceReturn`}
                        inputProps={{
                          ...props.setValue(
                            `listPassenger.person${index + 1}.fromPlaceReturn`,
                            dataFromReturn[index]
                          ),
                        }}
                        value={dataFromReturn[index]}
                        index={index + 1}
                        handleGetDataFromGoogleMap={
                          getDataFromGoogleMapFromReturnPlace
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            );
          }
        )}
    </Grid>
  );
};

export default FormDetailOfFight;
