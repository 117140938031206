import React from "react";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledBox = styled(Box)(({ theme, statusRequest, size }) => ({
  width: size,
  height: size,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 12,
  ...(statusRequest === 1 && {
    backgroundColor: "#ebfaf2",
  }),
  ...(statusRequest === 2 && {
    backgroundColor: "#fdf3f5",
  }),
  ...(statusRequest === 0 && {
    backgroundColor: "#fffcd6",
  }),
  ...(statusRequest === 3 && {
    backgroundColor: "#e7f2fc",
  }),
}));

const StatusRequest = ({ status, sizefont, sizebox, onClickButton }) => {
  const renderIcon = () => {
    if (status === 1) {
      return (
        <i
          className={`fas fa-check-circle`}
          style={{ fontSize: sizefont, color: "#00c292" }}
        ></i>
      );
    } else if (status === 2) {
      return (
        <i
          className={`fas fa-times-circle`}
          style={{ fontSize: sizefont, color: "#e46a76" }}
        ></i>
      );
    } else if (status === 3) {
      return (
        <i
          className={`fas fa-eye`}
          style={{ fontSize: sizefont, color: "#1976d2" }}
        ></i>
      );
    }else {
      return (
        <i
          className={`fas fa-question-circle`}
          style={{ fontSize: sizefont, color: "#fec90f" }}
        ></i>
      );
    }
  };

  return (
    <StyledBox statusRequest={status} size={sizebox} onClick={onClickButton}>
      {renderIcon()}
    </StyledBox>
  );
};

export default StatusRequest;
