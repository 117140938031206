import React from "react";

import { styled } from '@mui/styles';
import { 
  TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Avatar 
} from '@mui/material';

const TableContainerStyled = styled(TableContainer)(({theme})=>({
  width: "70%",
  [theme.breakpoints.down("sm")]:{
    width:"100%",
    height:"50%",
  },
}));

const TableRowStyled = styled(TableRow)({
  height: "100px",
});

const TableCellStyled = styled(TableCell)({
  fontSize: "20px",
});

const TableCellHead = styled(TableCell)({
  borderBottom: "3px solid #3366CC",
  fontSize: "20px",
});

const AvatarStyled = styled(Avatar)({
  "& img": {
    objectPosition: "0px 0px",
  },
});

const ListAllBuses = (props) => {

  const ShowConsole = (id) => {
    props.onClickVehicleRow(id);
  };

  return (
    <TableContainerStyled>
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCellHead align="center" size="small" >
              {` รูป `}
            </TableCellHead>
            <TableCellHead align="center">
              {` ประเภท `}
            </TableCellHead>
            <TableCellHead style={{minWidth:"300px"}}>
              {` บริษัท `}
            </TableCellHead>
            <TableCellHead align="center">
              {` เลขทะเบียนรถ `}
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.AllVehiclesShiftCar.length > 0 &&
            props.AllVehiclesShiftCar.map((vehicle) => (
              <TableRowStyled
                key={vehicle.idVehicle}
                onClick={() => ShowConsole(vehicle)}
              >
                {/* {console.log(vehicle)} */}
                <TableCellStyled padding="checkbox" size="small" align="center">
                  <AvatarStyled
                    src={`${process.env.REACT_APP_URL}image/car/${vehicle.imagepath}`}
                    alt={`รถ ${vehicle.model}`}
                  />
                </TableCellStyled>
                <TableCellStyled align="center">
                  {vehicle.vehicleTypeNameTH}
                </TableCellStyled>
                <TableCellStyled>
                  {vehicle.Vendor}
                </TableCellStyled>
                <TableCellStyled align="center">
                  {vehicle.Plate_No}
                </TableCellStyled>
              </TableRowStyled>
            ))}
        </TableBody>
      </Table>
    </TableContainerStyled>
  );
};

export default ListAllBuses;
