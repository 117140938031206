import React, { useState, useEffect, Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import Container from "@mui/material/Container";
import { SnackbarProvider, useSnackbar } from "notistack";

import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  postDeliverySampleshuttleByStartDate,
  postEditDeliverySampleshuttle,
} from "../../../../../../actions/deliverySampleshuttle";
import { getEmployeesByCompany } from "../../../../../../actions/employee";
import employeeService from "../../../../../../services/employee.service";
import { getDeliverySampleShuttleByNo } from "../../../../../../actions/deliverySampleshuttle";
import DialogShowDetailDialog from "../dialogShowDetailBooking";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/styles";
import { IconButton } from "@mui/material";
import Dropfile from "./Dropfile/index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SiteService from "../../../../../../services/site.service";
import { getUserProfile } from "../../../../../../actions/user";
import userService from "../../../../../../services/user.service";
import { set } from "date-fns";
import deliverySampleshuttleService from "../../../../../../services/deliverySampleshuttle.service";
// import VehicleBrandAndModelService from "../../../../../services/vehicleBrandAndModel.service";

const useStyles = makeStyles((theme) => ({
  PaddingBottom: {
    paddingBottom: "0 !important",
  },
  Title: {
    textAlign: "center",
    fontSize: "30px",

    marginTop: "90px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "90px",
    },
  },

  HeadersText: {
    textAlign: "left",
    fontSize: "20px",
    marginTop: "15px",
    // marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
    },
  },
  HeadersTextNo: {
    textAlign: "right",
    fontSize: "20px",
    marginTop: "15px",
    // marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
    },
  },

  DivCoverReturn: {
    // width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  DivCoverForm: {
    // width: "90%",
    position: "absolute",
    zIndex: "1",
    paddingBottom: "30px",
  },
  Grid: {
    marginTop: "10px",
  },
  Button: {
    width: "100px",
    height: "50px",
    fontSize: "18px",
    color: "white",
    backgroundColor: "green",
    ["&:hover"]: {
      backgroundColor: "darkgreen",
    },
  },
}));

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  marginTop: "90px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#E0E3E7",
    borderWidth: 1,
  },
  "& input:invalid + fieldset": {
    borderColor: "red",
    borderWidth: 2,
  },
  "& input:valid:focus + fieldset": {
    // borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const FormDetailFlight = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const [openDetailBooking, setOpenDetailBooking] = useState(false);
  const dispatch = useDispatch();
  const [nameFile, setNameFile] = useState("Upload File");
  const [data, setData] = useState({
    attachment: null,
  });
  const [lastNo, setLastNo] = useState(0);
  const [newDate, setDate] = useState(
    new Date().toJSON().slice(0, 10).split("-").reverse().join("")
  );

  const [allSite, setAllSite] = useState([]);

  const location = useLocation();
  const isEdit = location.state.isEditList && !location.state.isNewList;
  const booking = location.state.bookings;
  const { result: allEmployees } = useSelector((state) => state.employees);
  // const allDataOnState = useSelector((state) => state);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [sender, setSender] = useState(null);
  const [recipient, setRecipient] = useState(null);

  const { result: currentUserProfile } = useSelector(
    (state) => state.userProfile
  );

  const onSubmitHandler = (date) => {
    setOpenDetailBooking(true);
  };

  const onCloseDialog = () => {
    setOpenDetailBooking(false);
  };

  const onClickSubmit = async () => {
    //setopenCardBooking(true);
    const myData = {
      IdUser: currentUser.idUser,
      //มาแก้Iddriver
      IdDriver: null,
      NameSample: getValues("nameSample"),
      NameGrade: getValues("nameGrade"),
      LotNumber: getValues("lotNumber"),
      idSender: currentUser.idUser,
      NameSender: getValues("nameSender"),
      PhoneSender: getValues("phoneSender"),
      date: getValues("date"),
      startTime: getValues("startTime"),
      fromPlace: getValues("fromPlace"),
      toPlace: getValues("toPlace"),
      idRecipient: getValues("idRecipient"),
      NameRecipient: getValues("nameRecipient"),
      PhoneRecipient: getValues("phoneRecipient"),
      detail: getValues("detail"),
      status: isEdit ? getValues("status") : "รอรับสินค้า",
      attachment: getValues("attachment"),
      no: `Carlab${newDate}${lastNo}`,
      deleteOldFile: isEdit ? getValues("deleteOldFile") : [],
    };

    const formData = new FormData(); //เป็นฟอร์มที่สามารถเก็บข้อความและไฟล์ได้
    formData.append("mydata", JSON.stringify(myData));
    myData.attachment?.map((file, index) => {
      formData.append(`attachment`, file);
    });

    if (isEdit) {
      const id = booking.idDeliverySampleShuttle;
      deliverySampleshuttleService
        .postEditDeliverySampleshuttle(formData, id)
        .then((res) => {
          console.log(res);

          if (res.type === "success") {
            enqueueSnackbar("Edit data Success", { variant: "success" });
            props.history.push(`/sampleshuttle`);
          } else {
            enqueueSnackbar("Edit data Failed", { variant: "error" });
          }
        });
    } else {
      deliverySampleshuttleService
        .postDeliverySampleshuttleByStartDate(formData)
        .then((res) => {
          console.log(res);
          if (res.type === "success") {
            enqueueSnackbar("Add data Success", { variant: "success" });
            props.history.push(`/sampleshuttle`);
          } else {
            enqueueSnackbar("Add data Failed", { variant: "error" });
          }
        });
    }
  };
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    setNameFile(selectedFile.name);

    // You can now use the selectedFile for further processing
  };

  useEffect(async () => {
    const dataRe = dispatch(getDeliverySampleShuttleByNo());
    const data = dataRe.then((res) => {
      if (res.data) {
        if (res.data[0].no === null) {
          setLastNo("0001");
        } else {
          const date = res.data[0].no.slice(6, 14);

          var year = date.substring(0, 2);
          var month = date.substring(2, 4);
          var day = date.substring(4, 8);
          var data = new Date(day, month - 1, year);
          var data_date = [
            data.getFullYear(),
            data.getMonth() + 1,
            data.getDate(),
          ].join("-");
          if (data_date === new Date().toJSON().slice(0, 10)) {
            const no = parseInt(res.data[0].no.slice(14, 18)) + 1;
            setLastNo(String(no).padStart(4, "0"));
          } else {
            setLastNo("0001");
          }
        }
      }
    });
    SiteService.getAllSite().then((res) => {
      if (res.data) {
        setAllSite(res.data);
      }
    });
    if (!allEmployees) {
      await employeeService.getEmployeesById(currentUser.idUser).then((res) => {
        if (res.data) {
          dispatch(getEmployeesByCompany(res.data[0].idCompany));
        }
      });
    }
    if (!currentUserProfile) {
      const curData = await dispatch(getUserProfile(currentUser.idUser));
    }
    if (isEdit) {
      await userService.getUserProfile(booking.idSender).then((res) => {
        if (res.data) {
          setSender(res.data);
          console.log(sender);
          setValue("idSender", res.data.idEmployees);
          setValue("nameSender", booking.nameSender);
        }
      });
      await userService.getUserProfile(booking.idRecipient).then((res) => {
        if (res.data) {
          setRecipient(res.data);
          setValue("idRecipient", res.data.idEmployees);
          setValue("nameRecipient", booking.nameRecipient);
        }
      });
      setValue("fromPlaceName", booking.fromPlaceName);
      setValue("toPlaceName", booking.toPlaceName);
    }
  }, []);
  console.log("recipient", isEdit && recipient !== null);
  console.log("sender", isEdit && sender !== null);
  return (
    <Container maxWidth="xl">
      <Root>
        {/* <div className={`page ${classes.DivCoverReturn}`}>
        <div className={`${classes.DivCoverForm}`}> */}

        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {" "}
              <Grid>
                <BackButton disableRipple onClick={props.history.goBack}>
                  <ArrowBackIcon
                    className="Icon"
                    style={{ color: "#99A8B2" }}
                  />
                </BackButton>
              </Grid>
              {isEdit && (
                <Typography className={`${classes.Title}`}>
                  แก้ไขรายการรถรับส่งตัวอย่างการทดลอง
                </Typography>
              )}
              {!isEdit && (
                <Typography className={`${classes.Title}`}>
                  เพิ่มรายการรถรับส่งตัวอย่างการทดลอง
                </Typography>
              )}
            </Grid>
            <Grid
              container
              item
              spacing={3}
              xs={12}
              sx={(theme) => ({
                display: "flex",
                boxShadow:
                  "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                marginTop: "10px",
                marginBottom: "20px",
                marginLeft: "0px",
                padding: "0 25px 25px 0",

                borderRadius: "15px",
                [theme.breakpoints.down("sm")]: {},
                [theme.breakpoints.down("md")]: {},
              })}
            >
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography className={`${classes.HeadersText}`}>
                  รายละเอียดตัวอย่างการทดลอง
                </Typography>
                <Typography className={`${classes.HeadersTextNo}`}>
                  งานที่ Carlab{newDate}
                  {lastNo}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="nameSample"
                  error={errors.nameSample ? true : false}
                  helperText={
                    errors.nameSample ? "กรุณากรอกชื่อตัวอย่าง" : false
                  }
                  label="ชื่อตัวอย่าง"
                  placeholder="ระบุชื่อตัวอย่าง"
                  fullWidth
                  inputProps={{ ...register("nameSample", { required: true }) }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                  defaultValue={isEdit ? booking.nameSample : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Grade"
                  placeholder="ระบุGrade"
                  fullWidth
                  inputProps={{ ...register("nameGrade") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                  defaultValue={isEdit ? booking.nameGrade : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Lot Number"
                  placeholder="ระบุLot Number"
                  fullWidth
                  inputProps={{ ...register("lotNumber") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                  defaultValue={isEdit ? booking.lotNumber : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="รายละเอียดเพิ่มเติม"
                  placeholder="ระบุรายละเอียด"
                  variant="outlined"
                  multiline
                  rows={3}
                  getValues
                  fullWidth
                  inputProps={{ ...register("detail") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                  defaultValue={isEdit ? booking.detail : ""}
                />
              </Grid>
              <Grid item xs={12} lg={12} className="gridContainer">
                <Grid item xs={4} md={4} lg={7}>
                  <Typography>เพิ่มไฟล์</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Dropfile
                    title="แนบไฟล์ (ไม่เกิน 10 MB ต่อไฟล์ สูงสุด 10 ไฟล์)"
                    name="attachment"
                    setValue={setValue}
                    isEdit={isEdit}
                    oldFile={isEdit ? booking.path : []}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs={12}>
              <InputLabel shrink>{"รูปที่ 1"}</InputLabel>

              <Button variant="contained" component="label">
                {nameFile}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  hidden
                />
              </Button>
            </Grid> */}
            <Grid
              container
              item
              spacing={3}
              xs={12}
              sx={(theme) => ({
                display: "flex",
                boxShadow:
                  "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                marginTop: "10px",
                marginBottom: "20px",
                marginLeft: "0px",
                padding: "0 25px 25px 0",

                borderRadius: "15px",
                [theme.breakpoints.down("sm")]: {},
                [theme.breakpoints.down("md")]: {},
              })}
            >
              <Grid item xs={12}>
                <Typography className={`${classes.HeadersText}`}>
                  รายละเอียดผู้ส่ง
                </Typography>
              </Grid>

              {allEmployees &&
                currentUserProfile &&
                (!isEdit || sender !== null) && (
                  <>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Controller
                        render={(propsInController) => (
                          <TextField
                            // {...params}
                            name="nameSender"
                            error={errors.Sender ? true : false}
                            InputLabelProps={{
                              shrink:
                                getValues("nameSender") === "" ? false : true,
                            }}
                            helperText={
                              errors.Sender ? "กรุณากรอกชื่อผู้ส่ง" : false
                            }
                            inputProps={{
                              ...register("nameSender", { required: true }),
                            }}
                            placeholder="ระบุชื่อ-นามสกุล"
                            value={propsInController.field.value}
                            label="ชื่อผู้ส่ง"
                            fullWidth
                            sx={{
                              ".MuiInputBase-root": { borderRadius: "15px" },
                            }}
                          />
                        )}
                        name="nameSender"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={
                          isEdit
                            ? booking.nameSender
                            : `${currentUserProfile.firstname_TH} ${currentUserProfile.lastname_TH}`
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Controller
                        render={(propsInController) => (
                          <TextField
                            name="phoneSender"
                            error={errors.phoneSender ? true : false}
                            // onChange={handleChange}
                            // onClick={handleClick}
                            InputLabelProps={{
                              shrink:
                                getValues("phoneSender") === "" ||
                                getValues("phoneSender") === null ||
                                getValues("phoneSender") === undefined
                                  ? false
                                  : true,
                            }}
                            helperText={
                              errors.phoneSender
                                ? "กรุณากรอกเบอร์โทรผู้ส่ง"
                                : false
                            }
                            placeholder="ระบุเบอร์โทรผู้ส่ง"
                            label="เบอร์โทรผู้ส่ง"
                            fullWidth
                            inputProps={{
                              ...register("phoneSender", { required: true }),
                            }}
                            value={propsInController.field.value}
                            InputProps={{
                              style: {
                                borderRadius: "15px",
                              },
                            }}
                          />
                        )}
                        name="phoneSender"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={
                          isEdit
                            ? booking.phoneSender
                            : currentUserProfile.telephoneMobile
                        }
                      />
                    </Grid>
                  </>
                )}
              <Grid item xs={12} sm={6} lg={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>{"จุดรับของต้นทาง"}</InputLabel>
                  <Controller
                    render={(propsInController) => (
                      <Select
                        name="fromPlace"
                        // onClick={(e) => {
                        //   handleClickSelect(propsInController);
                        // }}
                        error={errors.fromPlace ? true : false}
                        helperText={
                          errors.fromPlace ? "กรุณาเลือกจุดรับของต้นทาง" : false
                        }
                        label="จุดรับของต้นทาง"
                        value={propsInController.field.value}
                        onChange={(e) => {
                          // props.setValue("idVehicleBrandAndModel", "");
                          // props.setValue("imageVehicle", "");
                          // props.setValue("costBooking", "");
                          setValue(
                            "fromPlaceName",
                            allSite.map((value) => {
                              if (value.idScgSite === e.target.value) {
                                if (value.noSite !== null) {
                                  return `Site${value.noSite}: ${value.nameSite}`;
                                } else {
                                  return `${value.nameSite}`;
                                }
                              }
                            })
                          );

                          propsInController.field.onChange(e);
                          // handleChange(e);
                        }}
                        style={{ borderRadius: "15px" }}
                      >
                        {allSite.map((value, index) => (
                          <MenuItem key={index} value={value.idScgSite}>
                            {value.noSite !== null &&
                              `Site${value.noSite}:${value.nameSite}`}
                            {value.noSite === null && `${value.nameSite}`}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    name="fromPlace"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={isEdit ? booking.fromPlace : ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    render={(propsInController) => (
                      <DatePicker
                        label="วันที่ส่ง"
                        minDate={new Date()}
                        value={propsInController.field.value}
                        onChange={(date) => {
                          propsInController.field.onChange(date);
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              ".MuiInputBase-root": { borderRadius: "15px" },
                            }}
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    )}
                    name={"date"}
                    defaultValue={isEdit ? booking.date : new Date()}
                    control={control}
                    style={{
                      borderRadius: "40px",
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  render={(propsInController) => (
                    <FormControl fullWidth>
                      <InputLabel shrink>{"เวลารับของต้นทาง"}</InputLabel>
                      <Select
                        native
                        label="เวลารับของต้นทาง"
                        value={propsInController.field.value}
                        onChange={(e) => {
                          propsInController.field.onChange(e.target.value);
                        }}
                        style={{ borderRadius: "15px" }}
                      >
                        {[...new Array(48)].map((value, index) => {
                          let hour = Math.floor((0 + index * 30) / 60);
                          let minute = Math.floor((0 + index * 30) % 60);
                          if (hour < 10) {
                            hour = "0" + hour;
                          }
                          if (minute < 10) {
                            minute = "0" + minute;
                          }
                          return (
                            <option
                              value={hour + ":" + minute}
                              key={hour + ":" + minute}
                            >
                              {hour + ":" + minute}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  name="startTime"
                  control={control}
                  defaultValue={isEdit ? booking.startTime : "00:00"}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={3}
              xs={12}
              sx={(theme) => ({
                display: "flex",
                boxShadow:
                  "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                marginTop: "10px",
                marginBottom: "20px",
                marginLeft: "0px",
                padding: "0 25px 25px 0",

                borderRadius: "15px",
                [theme.breakpoints.down("sm")]: {},
                [theme.breakpoints.down("md")]: {},
              })}
            >
              <Grid item xs={12}>
                <Typography className={`${classes.HeadersText}`}>
                  รายละเอียดผู้รับ
                </Typography>
              </Grid>
              {allEmployees &&
                currentUserProfile &&
                (!isEdit || recipient !== null) && (
                  <>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Controller
                        render={(propsInController) => (
                          <Autocomplete
                            options={allEmployees}
                            getOptionLabel={(option) =>
                              `${option.firstname_TH} ${option.lastname_TH}`
                            }
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {`${option.firstname_TH} ${option.lastname_TH}`}
                              </Box>
                            )}
                            value={propsInController.field.value}
                            onChange={(e, value) => {
                              if (value) {
                                setValue("idRecipient", value.idEmployees);
                                setValue(
                                  "nameRecipient",
                                  `${value.firstname_TH} ${value.lastname_TH}`
                                );
                                setValue(
                                  "phoneRecipient",
                                  value.telephoneMobile
                                );
                                propsInController.field.onChange(value);
                              } else {
                                setValue("idRecipient", null);
                                setValue("nameRecipient", "");
                                setValue("phoneRecipient", "");

                                propsInController.field.onChange(null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="nameRecipient"
                                error={errors.Recipient ? true : false}
                                helperText={
                                  errors.Recipient
                                    ? "กรุณากรอกชื่อผู้รับ"
                                    : false
                                }
                                placeholder="ระบุชื่อ-นามสกุล"
                                label="ชื่อผู้รับ"
                                fullWidth
                                defaultValue={
                                  isEdit ? booking.nameRecipient : ""
                                }
                                sx={{
                                  ".MuiInputBase-root": {
                                    borderRadius: "15px",
                                  },
                                }}
                              />
                            )}
                          />
                        )}
                        name="Recipient"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={isEdit ? recipient : null}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Controller
                        render={(propsInController) => (
                          <TextField
                            name="phoneRecipient"
                            error={errors.phoneRecipient ? true : false}
                            InputLabelProps={{
                              shrink:
                                getValues("phoneRecipient") === "" ||
                                getValues("phoneRecipient") === null ||
                                getValues("phoneRecipient") === undefined
                                  ? false
                                  : true,
                            }}
                            helperText={
                              errors.phoneRecipient
                                ? "กรุณากรอกเบอร์โทรผู้รับ"
                                : false
                            }
                            placeholder="ระบุเบอร์โทรผู้รับ"
                            label="เบอร์โทรผู้รับ"
                            fullWidth
                            inputProps={{
                              ...register("phoneRecipient", { required: true }),
                            }}
                            value={propsInController.field.value}
                            InputProps={{
                              style: {
                                borderRadius: "15px",
                              },
                            }}
                          />
                        )}
                        name="phoneRecipient"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={isEdit ? booking.phoneRecipient : ""}
                      />
                    </Grid>
                  </>
                )}

              <Grid item xs={12} sm={6} lg={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>{"จุดส่งของ"}</InputLabel>
                  <Controller
                    render={(propsInController) => (
                      <Select
                        name="toPlace"
                        // onClick={() => {
                        //   handleClickSelect(propsInController);
                        // }}
                        label="จุดส่งของ"
                        value={propsInController.field.value}
                        error={errors.toPlace ? true : false}
                        helperText={
                          errors.toPlace ? "กรุณาเลือกจุดส่งของ" : false
                        }
                        onChange={(e) => {
                          // props.setValue("idVehicleBrandAndModel", "");
                          // props.setValue("imageVehicle", "");
                          // props.setValue("costBooking", "");
                          setValue(
                            "toPlaceName",
                            allSite.map((value) => {
                              if (value.idScgSite === e.target.value) {
                                if (value.noSite !== null) {
                                  return `Site${value.noSite}: ${value.nameSite}`;
                                } else {
                                  return `${value.nameSite}`;
                                }
                              }
                            })
                          );
                          propsInController.field.onChange(e);
                          // handleChange(e);
                        }}
                        style={{ borderRadius: "15px" }}
                      >
                        {allSite.map((value, index) => (
                          <MenuItem key={index} value={value.idScgSite}>
                            {value.noSite !== null &&
                              `Site${value.noSite}:${value.nameSite}`}
                            {value.noSite === null && `${value.nameSite}`}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    name="toPlace"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={isEdit ? booking.toPlace : ""}
                  />
                </FormControl>
              </Grid>
              {isEdit && (
                <Fragment>
                  <Grid item xs={12}>
                    <Typography className={`${classes.HeadersText}`}>
                      สถานะการส่งของ
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>{"สถานะ"}</InputLabel>
                      <Controller
                        render={(propsInController) => (
                          <Select
                            label="สถานะ"
                            value={propsInController.field.value}
                            onChange={(e) => {
                              // props.setValue("idVehicleBrandAndModel", "");
                              // props.setValue("imageVehicle", "");
                              // props.setValue("costBooking", "");
                              propsInController.field.onChange(e);
                            }}
                            style={{ borderRadius: "15px" }}
                          >
                            <MenuItem value={"รอรับสินค้า"}>
                              รอรับสินค้า
                            </MenuItem>
                            <MenuItem value={"รับสินค้าเรียบร้อย"}>
                              รับสินค้าเรียบร้อย
                            </MenuItem>
                            <MenuItem value={"ส่งสินค้าเรียบร้อย"}>
                              ส่งสินค้าเรียบร้อย
                            </MenuItem>
                            <MenuItem value={"ได้รับสินค้าเรียบร้อย"}>
                              ได้รับสินค้าเรียบร้อย
                            </MenuItem>
                          </Select>
                        )}
                        name="status"
                        control={control}
                        defaultValue={isEdit ? booking.status : "รอรับสินค้า"}
                      />
                    </FormControl>
                  </Grid>
                </Fragment>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px",
              }}
            >
              <div className={`${classes.NavButton}`}>
                <Button className={`${classes.Button}`} type="submit">
                  ยืนยัน
                </Button>
              </div>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </form>

        {openDetailBooking && (
          <DialogShowDetailDialog
            open={openDetailBooking}
            handleClose={onCloseDialog}
            getValues={getValues}
            onClickSubmit={onClickSubmit}
            isEdit={isEdit}
          />
        )}
        {/* </div>
      </div> */}
      </Root>
    </Container>
  );
};
export default FormDetailFlight;
