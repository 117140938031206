import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import { Grid, Typography, Divider } from "@mui/material";

require("dayjs/locale/th");
dayjs.locale("th");

const GridContainer = styled(Grid)({
  width: "100%",
});

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: "#DDDDDD",
}));

const DetailMeetingRoomBooking = (props) => {
  const [booking, setBooking] = useState(null);
  const [meetingRoom, setMeetingRoom] = useState(null);
  const [imageMeetingRoom, setImageMeetingRoom] = useState(null);

  useEffect(() => {
    if (props.booking) {
      setBooking(props.booking);
    }
  }, []);

  return (
    <Fragment>
      {booking && (
        <Fragment>
          <GridContainer container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Text>
                {`วันที่เริ่ม : ${dayjs(booking.startDate).format(
                  "DD MMMM YYYY"
                )}`}
              </Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>
                {`วันที่สิ้นสุด : ${dayjs(booking.endDate).format(
                  "DD MMMM YYYY"
                )}`}
              </Text>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Text>
                {`เวลา : ${booking.timeStart.split(" ")[0]} - ${
                  booking.timeEnd.split(" ")[0]
                }`}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text>
                {`รายละเอียด : ${booking.detail ? booking.detail : "-"}`}
              </Text>
            </Grid>
          </GridContainer>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DetailMeetingRoomBooking;
