import React from "react";

import { styled } from "@mui/styles";
import TextField from "@mui/material/TextField";

const TextFieldStyled = styled(TextField)(({theme})=>({
  height: "130px",
  marginBottom: "10px",
  width: "90%",
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInputLabel-outlined": {
    color: "white",
    fontSize: "30px",
  },
  "& .MuiInputBase-root": {
    fontSize: "30px",
  },
  "& .MuiInputLabel-outlined": {
    color: "white",
    fontSize: "30px",
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -12px) scale(0.75)",
    },
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "30px",
    color: "white",
    height: "100px !important",
    lineHeight: "1.60",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiFormHelperText-root": {
    color: "white",
    fontSize: "16px",
  },
  [theme.breakpoints.down('sm')]:{
    width:"100%",
  },
}));

const TextFieldCustom = (props) => {
  return (
    <TextFieldStyled
      label="ความคิดเห็น"
      variant="outlined"
      rows={2}
      multiline
      helperText={
        props.warningComment
          ? "ให้ดาวน้อยกว่าหรือเท่ากับ 2 ดาวต้องระบุความคิดเห็น"
          : ""
      }
      onChange={(event) => {
        props.onSetComment(event.target.value);
      }}
      value={props.comment}
    />
  );
};

export default TextFieldCustom;
