import React, { useState, Fragment } from 'react';

import { styled } from '@mui/styles';

import {
    Typography, IconButton,
} from '@mui/material';

import StarIcon from '@mui/icons-material/Star';

import CardEdit from "../../../../../shared/cardEdit";
import ModalModelBrandYear from "./modalModelBrandYear";
import utils from "../../../../../../../utils";

const ModelText = styled(Typography)({
    fontSize:"34px",
    fontWeight:"bold",
});

const IconAndText = styled("div")(({theme})=>({
    display:"flex",
    alignItems:"center",
    "& .Text":{
        fontSize:"18px",
        marginLeft:"10px",
    },
    "& .IconAndPoint":{
        color:theme.palette.warning.light,
        fontWeight:"bold",
        fontSize:"24px",
    },
    "& .Icon":{
        fontSize:"30px",
    },
}));

const PartOfModelTextAndPoint = ({ LentCar, edit }) => {
    const [openModal, setOpenModal] = useState(false);

    const OnClickOpenModal = () => setOpenModal(true);

    const OnClickCloseModal = () => setOpenModal(false);

    return(
        <CardEdit openModal={OnClickOpenModal} edit={edit}>
            <ModelText>{`${LentCar.brand} ${LentCar.model} ${LentCar.year}`}</ModelText>
            <IconAndText>
                <StarIcon className="IconAndPoint Icon" />
                <Typography className="IconAndPoint Text">{LentCar.point}</Typography>
                <Typography className="Text">({utils.numberWithCommas(LentCar.numberBorrowed)} trips)</Typography>
            </IconAndText>
            {openModal && <ModalModelBrandYear openModal={openModal} LentCar={LentCar} OnClickCloseModal={OnClickCloseModal}/>}
        </CardEdit>
    );
};

export default PartOfModelTextAndPoint;