import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";
import SiteService from "../../../../../services/site.service";

const MarkerDriver = (props) => {
  const [isLocationDriver, setLocationDriver] = useState([{ Lat: 0, Long: 0 }]);

  useEffect(() => {
    if (props.inforInCar != null) {
      const updateLocationDriver = setInterval(() => {
        SiteService.getLocationDriverById(props.inforInCar.idUser)
          .then((res) => {
            if (res.data) {
              setLocationDriver([
                { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
              ]);
            }
          })
          .catch((error) => {
            setLocationDriver([{ Lat: 0, Long: 0 }]);
          });
      }, 1000);
      return () => clearInterval(updateLocationDriver);
    }
  }, [props.inforInCar]);

  return (
    <Marker
      position={{
        lat: parseFloat(isLocationDriver[0].Lat),
        lng: parseFloat(isLocationDriver[0].Long),
      }}
      icon={{
        url:
          "data:image/svg+xml;charset=UTF-8," +
          encodeURIComponent(`
      <svg height="48" viewBox="0 0 24 24" width="48" xmlns="http://www.w3.org/2000/svg"><path d="m20.772 10.155-1.368-4.104A2.995 2.995 0 0 0 16.559 4H7.441a2.995 2.995 0 0 0-2.845 2.051l-1.368 4.104A2 2 0 0 0 2 12v5c0 .738.404 1.376 1 1.723V21a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-2h12v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-2.277A1.99 1.99 0 0 0 22 17v-5a2 2 0 0 0-1.228-1.845zM7.441 6h9.117c.431 0 .813.274.949.684L18.613 10H5.387l1.105-3.316A1 1 0 0 1 7.441 6zM5.5 16a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 5.5 16zm13 0a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 18.5 16z"/></svg>

        `),
      }}
    />
  );
};

export default MarkerDriver;
