import { httpClient } from "./httpClient";

const getAllVehicleTypes = () => {
  return httpClient.get("vehicle_types");
};

const getVehicleTypeById = (Id) => {
  return httpClient.get("vehicle_typeById/"+Id);
};

export default {
    getAllVehicleTypes,
    getVehicleTypeById
};
