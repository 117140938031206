import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/styles";
// import { GoogleApiWrapper, Map, InfoWindow, Marker } from "google-maps-react";
import {
  GoogleAPIkey,
  locationSite1,
} from "../../../../../../../utils/googleMapAssets";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";
import { Modal, Typography, Paper } from "@mui/material";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import SiteService from "../../../../../../../services/site.service";
import MarkerDriver from "./MarkerDriver";
import GPSLocation from "./Icon/GPSLocation.png";
import userService from "../../../../../../../services/user.service";
import driverService from "../../../../../../../services/driver.service";

// import { GoogleMap, Marker } from "react-google-maps";
const placesLibrary = ["places"];

const useStyles = makeStyles((theme) => ({
  Modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Paper: {
    width: "90%",
    height: "80%",
    padding: "20px",
  },
  partOfTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  IconCar: {
    fontSize: "40px",
    marginRight: "10px",
    color: theme.palette.primary.main,
  },
  Title: {
    fontSize: "30px",
    color: theme.palette.primary.main,
  },
}));
const AlertMessage = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  fontSize: "16px",
  color: "red",
});

const GoogleApi = (props) => {
  const classes = useStyles();
  const [searchResult, setSearchResult] = useState("Result: none");
  const [isLocation, setLocation] = useState([{ Lat: 0, Lng: 0 }]);
  const [map, setMap] = React.useState(null);
  const [isLocationDriver, setLocationDriver] = useState([{ Lat: 0, Long: 0 }]);
  const [isLocationFrom, setLocationFrom] = useState([
    { LatFrom: 0, LongFrom: 0 },
  ]);
  const [isLocationTo, setLocationTo] = useState([{ LatTo: 0, LongTo: 0 }]);
  const [refresh, setRefresh] = useState(0);

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GoogleAPIkey,
    libraries: placesLibrary,
    language: "th",
    region: "TH",
  });

  const customCenter = {
    url: GPSLocation,
    scaledSize: new window.google.maps.Size(25, 50), // Size of the icon
    origin: new window.google.maps.Point(0, 0), // Origin point
    anchor: new window.google.maps.Point(25, 50), // Anchor point
  };

  useEffect(() => {
    if (
      props.inforInCar != null &&
      props.inforInCar.fromPlace &&
      props.inforInCar.toPlace
    ) {
      console.log(props.inforInCar);
      SiteService.getLocationDriverById(props.inforInCar.idDriver)
        .then((res) => {
          if (res.data) {
            setLocationDriver([
              { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
            ]);
          }
        })
        .catch((error) => {
          setLocationDriver([{ Lat: 0, Long: 0 }]);
        });
      SiteService.getSitebyName(props.inforInCar.fromPlace)
        .then((res) => {
          if (res.data) {
            console.log(res.data);
            setLocationFrom([
              { LatFrom: res.data[0].Lat, LongFrom: res.data[0].Long },
            ]);
          }
        })
        .catch((error) => {
          setLocationFrom([{ LatFrom: 0, LongFrom: 0 }]);
        });
      SiteService.getSitebyName(props.inforInCar.toPlace)
        .then((res) => {
          if (res.data) {
            console.log(res.data);

            setLocationTo([
              { LatTo: res.data[0].Lat, LongTo: res.data[0].Long },
            ]);
          }
        })
        .catch((error) => {
          setLocationTo([{ LatTo: 0, LongTo: 0 }]);
        });
    }
  }, [props.inforInCar]);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: parseFloat(
      isLocationDriver[0].Lat ? isLocationDriver[0].Lat : locationSite1.lat
    ),
    lng: parseFloat(
      isLocationDriver[0].Long ? isLocationDriver[0].Long : locationSite1.lng
    ),
  };

  return (
    <>
      {isLoaded && (
        <Modal
          className={`${classes.Modal}`}
          open={props.open}
          onClose={props.onCloseHandle}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Paper className={`${classes.Paper}`}>
            <div className={`${classes.partOfTitle}`}>
              <DriveEtaIcon className={`${classes.IconCar}`} />
              <Typography className={`${classes.Title}`}>แสดงแผนที่</Typography>
            </div>
            <div style={{ height: "90%", width: "100%" }}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={13}
              >
                <Marker
                  position={{
                    lat: parseFloat(isLocationFrom[0].LatFrom),
                    lng: parseFloat(isLocationFrom[0].LongFrom),
                  }}
                  icon={customCenter}
                />
                <Marker
                  position={{
                    lat: parseFloat(isLocationTo[0].LatTo),
                    lng: parseFloat(isLocationTo[0].LongTo),
                  }}
                  icon={customCenter}
                />
                {props.inforInCar.idDriver != null ? (
                  <MarkerDriver inforInCar={props.inforInCar} />
                ) : (
                  <AlertMessage>กำลังจัดหาคนขับ...</AlertMessage>
                )}
              </GoogleMap>
            </div>
          </Paper>
        </Modal>
      )}
    </>
  );
};

export default GoogleApi;
