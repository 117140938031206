import React, { useState } from 'react';

import { styled } from '@mui/styles';
import {
    Typography, Grid, 
} from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import CardEdit from "../../../../../shared/cardEdit";
import ModalFeature from "./modalFeature";
import { listOptions } from "../../../choise";

const Topic = styled(Typography)({
    fontSize:"22px",
    fontWeight:"bold",
    margin:"10px 0px",
});

const GridStyled = styled(Grid)(({theme})=>({
    width:"500px",
    "& .Icon":{
        fontSize:"26px",
        marginRight:"10px",
        color:theme.palette.success.main,
    },
    [theme.breakpoints.down('sm')]: {
        width:"100%",
    },
}));

const PartOfFeature = ({ listExOptions, setListExOptions, edit }) => {
    const [openModal, setOpenModal] = useState(false);

    const onChangeOptionsCheckBox = (id) => {
        if(listExOptions.includes(id)){
            setListExOptions([...listExOptions.filter(option => option !== id)]);
        }
        else{
            setListExOptions([...listExOptions,id]);
        }
    };

    const OnClickOpenModal = () => setOpenModal(true);

    const OnClickCloseModal = () => setOpenModal(false);

    return(
        <CardEdit openModal={OnClickOpenModal} edit={edit}>
            <Topic>Features</Topic>
            <GridStyled container spacing={1}>
                {listExOptions.map((id)=>(
                    <Grid key={"Option"+id} item xs={12} sm={6} style={{display:"flex"}}>
                        <AddCircleIcon className="Icon"/>
                        <Typography>{listOptions.find( option => option.id === id).name}</Typography>
                    </Grid>
                ))}
            </GridStyled>
            {openModal && 
            <ModalFeature 
                openModal={openModal}
                onChangeOptionsCheckBox={onChangeOptionsCheckBox} 
                listExOptions={listExOptions}
                OnClickCloseModal={OnClickCloseModal}
            />}
        </CardEdit>
    );
};

export default PartOfFeature;