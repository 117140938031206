import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { styled } from '@mui/styles';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@mui/material';

import TableRowAndModal from "./tableRowAndModal";
import HistoryActionsOfLentCar from "../../../../../services/historyActionsOfLentCar.service";

require('dayjs/locale/th');
dayjs.locale('th');

const TableContainerStyled = styled(TableContainer)(({theme})=>({
    overflowX:"auto",
    boxShadow:theme.shadows[3],
    height:"100%",
}));

const TableCellStyled = styled(TableCell)(({theme})=>({
    backgroundColor:theme.palette.primary.main,
    color:theme.palette.primary.contrastText,
    fontSize:"18px",
}));

const TableActions = props => {
    const [historyActions, setHistoryActions] = useState([]);

    useEffect(() => {
        HistoryActionsOfLentCar.getAllHistoryActions().then((res)=>{
            try {
                if(res.data){
                    setHistoryActions(res.data);
                }
            } catch (error) {
                console.log(error)
            }
        })
    }, []);

    const OnClickViewButtonAddCar = () => {
        props.history.push("/admin/detail_lentcar",{ idCar:1, purpose: "view" });
    };

    return(
        <>
            <TableContainerStyled component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCellStyled style={{ minWidth: "40px" }}></TableCellStyled>
                            <TableCellStyled style={{ minWidth: "130px" }}></TableCellStyled>
                            <TableCellStyled align="center">Time</TableCellStyled>
                            <TableCellStyled align="center">Location</TableCellStyled>
                            <TableCellStyled align="center">Tel.</TableCellStyled>
                            <TableCellStyled align="center">Rating</TableCellStyled>
                            <TableCellStyled align="center">Item</TableCellStyled>
                            <TableCellStyled></TableCellStyled>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {historyActions.length > 0 && historyActions.map((currentaction)=>(
                            <TableRowAndModal 
                                key={currentaction.id} 
                                currentaction={currentaction} 
                                OnClickViewButtonAddCar={OnClickViewButtonAddCar}    
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainerStyled>
        </>
    );
};

export default TableActions;