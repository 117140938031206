import React from "react";
import { styled, Grid, Typography, Divider } from "@mui/material";
import Card from "../Card";
import { minWidth } from "@mui/system";

const StyledTable = styled("div")(({ theme }) => ({
  ["& .header"]: {
    padding: "0 24px",
    minWidth: "680px",
    // [theme.breakpoints.down("lg")]: {
    // 	minWidth: "auto",
    // }
  },
  ["& .headerDivider"]: {
    marginTop: 16,
  },
}));

const Table = ({ children }) => {
  return (
    <Card
      style={{
        flexGrow: 1,
        paddingLeft: 0,
        paddingRight: 0,
        overflow: "hidden",
        overflowX: "auto",
      }}
    >
      <StyledTable>
        <Grid className="header" container>
          <Grid item xs={4} md={4} lg={4}>
            <Typography>ชื่อ-สกุล</Typography>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Typography>ประเภทช่าง</Typography>
          </Grid>

          <Grid item xs={3} md={3} lg={3}>
            <Typography>งานที่มอบหมาย</Typography>
          </Grid>
          <Grid item xs={1} md={1} lg={1}></Grid>
        </Grid>
        <Divider className="headerDivider" flexItem />
        {children}
      </StyledTable>
    </Card>
  );
};

export default Table;
