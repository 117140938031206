import { httpClient } from "./httpClient";

const getRouteLineByIdUserRouteDate = (data) => {
  return httpClient.post("route_cross_area_car_pool_route_line", data);
};

const getRouteUserByIdUser = (data) => {
  return httpClient.post("route_cross_area_car_pool_route_user", data);
};

const getRoutesByRouteDate = (date) => {
  return httpClient.post("route_cross_area_car_pool_routes_by_date", date);
};

const getRoutesByRouteLineAndRouteDate = (routeDateEnd, routeDateStart) => {
  let data = {
    routeDateEnd: routeDateEnd,
    routeDateStart: routeDateStart,
  };
  return httpClient.post(
    "route_cross_area_car_pool_routes_by_route_line_date",
    data
  );
};

const postEditIdDriverRoute = (routeLine) => {
  return httpClient.post("post_Edit_IdDriver_Route", routeLine);
};

const deleteRouteCrossPools = (routeLine) => {
  return httpClient.post("delete_Route_Cross_Pools", routeLine);
};
export default {
  getRouteLineByIdUserRouteDate,
  getRouteUserByIdUser,
  getRoutesByRouteDate,
  getRoutesByRouteLineAndRouteDate,
  postEditIdDriverRoute,
  deleteRouteCrossPools,
};
