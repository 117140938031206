import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from "react-redux";

import { styled } from '@mui/styles';
import {
    Typography, Button, Card, Avatar, IconButton,
} from '@mui/material';

import StarIcon from '@mui/icons-material/Star';

import HistoryLentCarsServices from "../../../../../../services/historyLentCar.service";
import utils from "../../../../../../utils";
import StatusRequest from "../../../../shared/buttonStyled/stausRequest";
import { getHistoryLentCarWaitingByLenderId } from "../../../../../../actions/historyLentCar";

require('dayjs/locale/th');
dayjs.locale('th');

const DivCoverReturn = styled("div")(({
    width:"calc(100% - 60px)",
    height:"fit-content",
    padding:"70px 30px 0",
}));

const Title = styled(Typography)(({
    fontSize:"30px",
}));

const AllCards = styled("div")(({theme})=>({
    width:"calc(100% - 30px)",
    height:"calc(100% - 85px)",
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-start",
    marginTop:"10px",
    padding:"15px",
    overflowX:"auto",
    overflowY:"auto",
    [theme.breakpoints.up('xl')]:{
        alignItems:"center",
    },
}));

const CardStyled = styled(Card)(({theme})=>({
    width:"1274px",
    height:"100px",
    minHeight:"100px",
    display:"flex",
    padding:"20px 10px",
    margin:"10px",
    alignItems:"center",
    boxShadow:theme.shadows[3],
}));

const DivAvatar = styled("div")(({
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    flexBasis:"8%",
}));

const AvatarStyled = styled(Avatar)(({
    width:"80px",
    height:"80px",
    objectFit:"contain",
}));

const DivPoint = styled("div")(({
    display:"flex",
}));

const RatingIcon = styled(StarIcon)(({theme})=>({
    fontSize:"20px",
    color:theme.palette.warning.light,
}));

const PointText = styled(Typography)(({theme})=>({
    color:theme.palette.warning.light,
    fontSize:"16px",
    fontWeight:"bold",
}));

const NumberOfUsingServices = styled(Typography)(({theme})=>({
    color:theme.palette.grey[500],
    fontSize:"16px",
    fontWeight:"bold",
    marginLeft:"3px",
}));

const ContentWidthoutAvatarAndPoint = styled("div")(({theme})=>({
    height:"calc(100% - 10px)",
    display:"flex",
    padding:"5px 0",
    flexBasis:"92%",
}));

const DivNameAndPlace = styled("div")(({
    flexBasis:"22%",
    padding:"0 10px",
}));

const NameText = styled(Typography)(({
    fontSize:"24px",
}));

const Text = styled(Typography)(({
    fontSize:"20px",
}));

const TextGrey = styled(Typography)(({theme})=>({
    fontSize:"20px",
    color:theme.palette.grey[500],
}));

const DivModelAndDelivery = styled("div")(({
    flexBasis:"17%",
}));

const DivDate = styled("div")(({
    height:"100%",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    flexBasis:"12%",
}));

const DivDuration = styled("div")(({
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    flexBasis:"8%",
}));

const DivPrice = styled("div")(({
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    flexBasis:"12%",
}));

const DivApproveAndDeny = styled("div")(({theme})=>({
    flexBasis:"17%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-around",
}));

const PendingPage = props => {
    const dispatch = useDispatch();
    const { user : User } = useSelector(state => state.auth)
    const { result : Pending } = useSelector(state => state.historyLentCars);
    const [listPending, setListPending] = useState([]);
    useEffect(() => {
        if(!Pending){
            dispatch(getHistoryLentCarWaitingByLenderId(User.id));
        }
        // HistoryLentCarsServices.getHistoryLentCarByCarId(1).then((res)=>{
        //     try {
        //         if(res.data){
        //             setListPending(res.data);
        //         }
        //     } catch (error) {
        //         console.log(error)
        //     }
        // })
    }, [])

    const onClickViewButton = (id) => {
        props.history.push("/friend2friend_Car/lender/car",{ lentId: id, idCar:1, purpose: "lending", pending: true });
    };


    return(
        <DivCoverReturn>
            <Title>รายการรออนุมัติ</Title>
            {console.log(Pending)}
            <AllCards>
                {Pending ? Pending.map((pending)=>(
                    <CardStyled key={pending.id}>
                        <DivAvatar>
                            <AvatarStyled 
                                src={`${process.env.REACT_APP_URL}image/profile/${pending.imageUserborrower}`}
                            />
                            <DivPoint>
                                <RatingIcon />
                                <PointText>{pending.pointBorrower}</PointText>
                                <NumberOfUsingServices>(5)</NumberOfUsingServices>
                            </DivPoint>
                        </DivAvatar>
                        <ContentWidthoutAvatarAndPoint>
                            <DivNameAndPlace>
                                <NameText>{`${pending.firstnameborrower} ${pending.lastnameborrower}`}</NameText>
                                <TextGrey>{`${pending.provinceborrower},${pending.districtborrower}`}</TextGrey>
                            </DivNameAndPlace>
                            <DivModelAndDelivery>
                                <NameText>{pending.model}</NameText>
                                <Text>{pending.delivery?"ส่งรถ":"มารับ"}</Text>
                            </DivModelAndDelivery>
                            <DivDate>
                                <Text>{dayjs(pending.datelent).format("D MMM YYYY")}</Text>
                                <Text style={{margin:"5px 0"}}>{`${pending.datelenttime} น.`}</Text>
                                {pending.delivery && 
                                    <TextGrey>{pending.district}</TextGrey>
                                }
                            </DivDate>
                            <DivDate>
                                <Text>{dayjs(pending.datereturn).format("D MMM YYYY")}</Text>
                                <Text style={{margin:"5px 0"}}>{`${pending.datereturntime} น.`}</Text>
                                {pending.delivery && 
                                    <TextGrey>{pending.district}</TextGrey>
                                }
                            </DivDate>
                            <DivDuration>
                                <TextGrey>Day</TextGrey>
                                <NameText style={{fontWeight:"bold"}}>{pending.duration}</NameText>
                            </DivDuration>
                            <DivPrice>
                                <TextGrey>Price</TextGrey>
                                <NameText style={{fontWeight:"bold"}}>{utils.numberWithCommas(pending.price)}</NameText>
                            </DivPrice>
                            <DivApproveAndDeny>
                                <StatusRequest 
                                    status={3} 
                                    sizefont={"28px"} 
                                    sizebox={50} 
                                    onClickButton={()=>onClickViewButton(pending.id)}
                                />
                                <StatusRequest status={1} sizefont={"28px"} sizebox={50}/>
                                <StatusRequest status={2} sizefont={"28px"} sizebox={50}/>
                            </DivApproveAndDeny>
                        </ContentWidthoutAvatarAndPoint>
                    </CardStyled>
                ))
                :
                <Title>ไม่มีรายการรออนุมัติ</Title>
            }
            </AllCards>
        </DivCoverReturn>
    );
};

export default PendingPage;