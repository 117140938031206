import {
  EMPLOYEES_FETCHING,
  EMPLOYEES_FAILED,
  EMPLOYEES_SUCCESS,
} from "./types";

import EmployeeService from "../services/employee.service";

export const getAllEmployees = () => async (dispatch) => {
  try {
    const res = await EmployeeService.getAllEmployees();
    if (res) {
      dispatch({
        type: EMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEES_FAILED,
    });
  }
};

export const getEmployeesByCompany = (idCompany) => async (dispatch) => {
  try {
    const res = await EmployeeService.getEmployeesByCompany(idCompany);
    if (res) {
      dispatch({
        type: EMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEES_FAILED,
    });
  }
};
