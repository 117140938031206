import React, { useState } from "react";
import { Avatar as MuiAvatar, styled, Typography } from "@mui/material";

const Root = styled("div")(({theme})=>({
	marginRight: 40,
	width: 165, 
	height: 165,
	borderRadius: "50%",
	overflow: "hidden",
	position: "relative",
	["&:hover"]: {
		["& .button"]: {
			display: "flex",
		}
	},
	["& .button"]: {
		width: "100%",
		height: 60,
		backgroundColor: "rgba(0,0,0,.65)",
		display: "none",
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		bottom: 0,
		color: "#fff",
		cursor: "pointer",
		userSelect: "none",
	}
}))

const StyledAvatar = styled(MuiAvatar)(({theme})=>({
	width: 165, 
	height: 165,
	[theme.breakpoints.down("md")]: {
		marginLeft: 20,
		marginRight: 0, 
		width: 75, 
		height: 75,
	}
}))

const Avatar = ({setValue, getValues}) => {

	const onUploadFile = (event) => {
		const { files } = event.target;
		if(files.length !== 0 && ["image/jpeg", "image/png", "image/jpg"].includes(files[0].type)){
			setValue("image", files[0]);
		}
		// console.log(files);
	}

	return(
		<Root>
			<StyledAvatar src={getValues("image")? URL.createObjectURL(getValues("image")) : null}/>
			<input type="file" id="uploadAvatar-btn" onChange={onUploadFile} hidden accept="image/jpeg, image/png, image/jpg"/>
			<label htmlFor="uploadAvatar-btn" className="button">
				<i className="fas fa-image" style={{marginRight: 4}}></i>
				<Typography textAlign="center" color="#fff!important" fontWeight={500}>Upload +</Typography>
			</label>
		</Root>
	)
}

export default Avatar;