import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import { Autocomplete, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import userService from "../../../../../services/user.service";
import employeeService from "../../../../../services/employee.service";

const useStyles = makeStyles((theme) => ({
  customTextField: {
    "& .MuiInputBase-root": {
      borderRadius: "15px",
    },
  },
}));

const FormEachEmployee = (props) => {
  const classes = useStyles();
  const { result: AllUsers } = useSelector((state) => state.users);
  const { result: currentUser } = useSelector((state) => state.auth);
  const { result: allEmployees } = useSelector((state) => state.employees);
  const defaultProps = {
    options: AllUsers,
    getOptionLabel: (option) => `${option.firstname_TH} ${option.lastname_TH}`,
  };

  const onChangeAutocomplete = (propsInController, value) => {
    console.log(propsInController);
    console.log(value);
    if (value) {
      props.setValue(
        `listPassenger.person${props.index}.idUser`,
        value.idEmployees
      );
      props.setValue(
        `listPassenger.person${props.index}.name`,
        `${value.firstname_TH} ${value.lastname_TH}`
      );
      props.setValue(
        `listPassenger.person${props.index}.telephoneMobile`,
        value.telephoneMobile
      );
      props.setValue(`listPassenger.person${props.index}.email`, value.email);
      props.setValue(
        `listPassenger.person${props.index}.company`,
        value.companyName
      );
      let address = "";
      if (value.houseNo) {
        address += ` ${value.houseNo}`;
      }
      if (value.village) {
        address += ` หมู่บ้าน ${value.village}`;
      }
      if (value.villageNo) {
        address += ` หมู่ที่ ${value.villageNo}`;
      }
      if (value.alley) {
        address += ` ซ.${value.alley}`;
      }
      if (value.road) {
        address += ` ถ.${value.road}`;
      }
      if (value.subDistrict) {
        address += ` ต.${value.subDistrict}`;
      }
      if (value.district) {
        address += ` อ.${value.district}`;
      }
      if (value.provience) {
        address += ` จ.${value.provience}`;
      }
      if (value.areaCode) {
        address += ` ${value.areaCode}`;
      }

      props.setValue(`listPassenger.person${props.index}.fromPlace`, address);
      props.setValue(
        `listPassenger.person${props.index}.toPlaceReturn`,
        address
      );
      if (value.costCenterCharge) {
        props.setValue(
          `listPassenger.person${props.index}.costCenter`,
          value.costCenterCharge
        );
      }
      if (value.costElementCharge) {
        props.setValue(
          `listPassenger.person${props.index}.costElement`,
          value.costElementCharge
        );
      }
      propsInController.field.onChange(value);
    } else {
      props.setValue(`listPassenger.person${props.index}.idUser`, 0);
      props.setValue(`listPassenger.person${props.index}.name`, "");
      props.setValue(`listPassenger.person${props.index}.telephoneMobile`, "");
      props.setValue(`listPassenger.person${props.index}.email`, "");
      props.setValue(`listPassenger.person${props.index}.company`, "");
      props.setValue(`listPassenger.person${props.index}.fromPlace`, "");
      props.setValue(`listPassenger.person${props.index}.toPlaceReturn`, "");
      props.setValue(`listPassenger.person${props.index}.costCenter`, "");
      props.setValue(`listPassenger.person${props.index}.costElement`, "");
      propsInController.field.onChange(null);
    }
  };

  return (
    <>
      {allEmployees && allEmployees.length > 0 && (
        <>
          <Grid item xs={12} lg={6}>
            <Controller
              render={(propsInController) => {
                return (
                  <Autocomplete
                    fullWidth
                    options={allEmployees}
                    getOptionLabel={(option) =>
                      `${option.firstname_TH} ${option.lastname_TH}`
                    }
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {`${option.firstname_TH} ${option.lastname_TH}`}
                      </Box>
                    )}
                    value={propsInController.field.value}
                    onChange={(e, value) => {
                      console.log(value);
                      onChangeAutocomplete(propsInController, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="ระบุชื่อผู้โดยสาร"
                        label="ชื่อผู้โดยสาร"
                        className={classes.customTextField}
                      />
                    )}
                  />
                );
              }}
              name={`listPassenger.person${props.index}.option`}
              control={props.control}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              placeholder="ระบุเบอร์โทรศัพท์"
              label="เบอร์โทรศัพท์"
              variant="outlined"
              fullWidth
              inputProps={{
                ...props.register(
                  `listPassenger.person${props.index}.telephoneMobile`
                ),
                // pattern: `[0]\\d{2}(?:[\\-]?\\d{3}){2}\\d$`,
              }}
              InputLabelProps={{
                shrink: props.watch(
                  `listPassenger.person${props.index}.telephoneMobile`
                )
                  ? true
                  : false,
              }}
              InputProps={{
                style: {
                  borderRadius: "15px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              placeholder="ระบุemail"
              label="Email"
              variant="outlined"
              fullWidth
              type="email"
              inputProps={{
                ...props.register(`listPassenger.person${props.index}.email`),
              }}
              InputLabelProps={{
                shrink: props.watch(`listPassenger.person${props.index}.email`)
                  ? true
                  : false,
              }}
              InputProps={{
                style: {
                  borderRadius: "15px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              placeholder="ระบุบริษัท"
              label="บริษัท"
              variant="outlined"
              fullWidth
              inputProps={{
                ...props.register(`listPassenger.person${props.index}.company`),
              }}
              InputLabelProps={{
                shrink: props.watch(
                  `listPassenger.person${props.index}.company`
                )
                  ? true
                  : false,
              }}
              InputProps={{
                style: {
                  borderRadius: "15px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              placeholder="ระบุ cost center"
              label="Cost Center"
              variant="outlined"
              fullWidth
              inputProps={{
                ...props.register(
                  `listPassenger.person${props.index}.costCenter`
                ),
                pattern: "\\d{4}[-]\\d{5}$",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              placeholder="ระบุ cost Element"
              label="Cost Element"
              variant="outlined"
              fullWidth
              inputProps={{
                ...props.register(
                  `listPassenger.person${props.index}.costElement`
                ),
                pattern: "\\d{4}[-]\\d{5}$",
              }}
              InputProps={{
                style: {
                  borderRadius: "15px",
                },
              }}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default FormEachEmployee;
