import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import CardPlan from "./CardPlan";
import WaitingResponseService from "../../../../../../services/waitingFriendCar.service";
import PassengerFriendToFriendService from "../../../../../../services/passengerFriendToFriend.service";

const useStyles = makeStyles((theme)=>({
    DivCoverReturn:{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        marginTop:"20px",
    },
    Title:{
        fontSize:"40px",
    },
    NavButton:{
        width:"90%",
        display:"flex",
        justifyContent:"flex-end",
        [theme.breakpoints.down('sm')]:{
            width:"calc(100% - 15px)",
        },
    },
    Button:{
        width:"200px",
        backgroundColor:"#1F4DD4",
        marginLeft:"15px",
        ["&:hover"]:{
            backgroundColor:"#1F4DD4",
        },
        [theme.breakpoints.down("sm")]:{
            marginLeft:"0px"
        },
    },
    TextAndIcon:{
        display:"flex",
        alignItems:"center",
    },
    ImageIcon:{
        width:"40px",
        height:"40px",
        marginRight:"5px",
    },
    ListButton:{
        backgroundColor:"#F39C12",
        ["&:hover"]:{
            backgroundColor:"#F39C12",
        },
        [theme.breakpoints.down('sm')]:{
            marginLeft:"9px"
        },
    },
    ListIcon:{
        width:"30px",
        height:"30px",
    },
    TextButton:{
        fontSize:"20px",
        color:"white",
    },
}));

const MyPlan = (props) => {
    const classes = useStyles();
    const { user: User } = useSelector(state => state.auth);
    const [listMyPlans, setListMyPlans] = useState([]);

    useEffect(() => {
        PassengerFriendToFriendService.getPassengerFriendToFriendByIdPassenger(User.id).then(
            (res) => {
                if(res.data){
                    setListMyPlans(res.data);
                }
            }
        ).catch(error => {
            setListMyPlans([]);
        })
    }, [])

    const onClickSelectDriver = () => {
        props.history.push("/friend2friend_Car/passenger/selectionDrivers");
    };

    const onClickRequestList = () => {
        props.history.push("/friend2friend_Car/passenger/waiting_responses");
    }

    return(
        <div className={`page ${classes.DivCoverReturn}`}>
            <Typography className={`${classes.Title}`}>My Plan</Typography>
            <div className={`${classes.NavButton}`}>
                <Button 
                    className={`${classes.Button}`}
                    onClick={onClickSelectDriver}
                >
                    <div className={`${classes.TextAndIcon}`}>
                        <img 
                            className={`${classes.ImageIcon}`}
                            src={`../../assets/Image/Icon/driver.png`}
                            alt="driver"
                        />
                        <Typography className={`${classes.TextButton}`}>
                            เลือกคนขับ
                        </Typography>
                    </div>
                </Button>
                <Button 
                    className={`${classes.Button} ${classes.ListButton}`}
                    onClick={onClickRequestList}
                >
                    <div className={`${classes.TextAndIcon}`}>
                        <img 
                            className={`${classes.ImageIcon} ${classes.ListIcon}`}
                            src={`../../assets/Image/Icon/waitingList.png`}
                            alt="RequestList"
                        />
                        <Typography className={`${classes.TextButton}`}>
                            รายการรออนุมัติ
                        </Typography>
                    </div>
                </Button>
            </div>
            {listMyPlans.length > 0 && listMyPlans.map((plan)=>{
                return(
                    <CardPlan 
                        key={plan.id}
                        plan={plan}
                        history={props.history}
                    />
                );
            })}
        </div>
    );
};

export default MyPlan;