import React from 'react';
import { useForm } from "react-hook-form";

import { styled } from '@mui/styles';
import { 
    Modal, Paper, Button, TextField
} from '@mui/material';

const ModalStyled = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
});

const PaperStyled = styled(Paper)(({theme})=>({
    width:"600px",
    height:"fit-content",
    padding:"30px 30px 20px",
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-end",
    [theme.breakpoints.down("sm")]:{
        width:"calc(95% - 60px)",
    },
}));

const ConfirmButton = styled(Button)(({theme})=>({
    marginTop:"20px",
    padding:"7px 10px",
    backgroundColor:theme.palette.success.main,
    color:theme.palette.success.contrastText,
    fontSize:"16px",
    "&:hover":{
        backgroundColor:theme.palette.success.dark,
    },
}));

const ModalDescription = ({ openModal, LentCar, OnClickCloseModal }) => {
    const { register, setValue } = useForm({
        defaultValues:{
            description:LentCar?LentCar.description:"",
        }
    });

    const OnClickConfirmButton = () => {
        OnClickCloseModal();
    };

    return(
        <>
            <ModalStyled
                open={openModal}
                onClose={OnClickCloseModal}
            >
                <PaperStyled>
                    <TextField 
                        id="description" 
                        label="รายละเอียด" 
                        variant="outlined" 
                        fullWidth 
                        multiline
                        rows={7}
                        inputProps={{...register("description")}} 
                    />
                    <ConfirmButton onClick={OnClickConfirmButton}>Confirm</ConfirmButton>
                </PaperStyled>
            </ModalStyled>
        </>
    );
};

export default ModalDescription;