import React from 'react';
import { styled } from '@mui/styles';
import Chart from "react-apexcharts";

const ChartStyled = styled(Chart)(({theme})=>({
    boxShadow:theme.shadows[3],
}));

const NumOfLendChart = props => {
    console.log(props)
    const optionNumOfLend = {
        series: [
            {
              name: "numOfLend",
              data: props.data
            }
        ],
        options: {
            chart: {
              id: "Lend-bar"
            },
            xaxis: {
              categories: props.labelX
            },
            stroke: {
                curve: 'smooth',
            },
            chart: {
                sparkline: {
                  enabled: true
                },
            },
            colors: [ props.color ],
            yaxis: {
                min: 0,
                show: false,
            },
            title: {
                text: props.Title,
                offsetX: 0,
                offsetY: 10,
                style: {
                    fontSize: '14px',
                    color:"grey",
                },
            },
            subtitle: {
                text: props.SubTitle,
                offsetX: 0,
                offsetY: 25,
                style: {
                    fontSize: '56px',
                    color: props.color,
                },
            }
        },
    }
    
    return(
        <ChartStyled
            options={optionNumOfLend.options}
            series={optionNumOfLend.series}
            type="area"
            width="100%"
            height="130px"
        />
    );
};

export default NumOfLendChart;