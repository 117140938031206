import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarProvider, useSnackbar } from "notistack";

import { styled } from "@mui/styles";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

import CarVerification from "./carVarification";
import AssignPlace from "./assignPlace";
import DetailOfCalling from "./detailOfCalling";

import BetweenSiteCarService from "../../../../services/betweenSiteCar.service";

const DivCoverReturn = styled("div")({
  width: "100%",
  heihgt: "calc(100% - 64px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
});

const PaperStyled = styled(Paper)(({ theme }) => ({
  width: "95%",
  maxWidth: "500px",
  height: "490px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: theme.shadows[5],
  backgroundColor: "#E1E5EA",
  position: "absolute",
  zIndex: "5",
}));

const ImageBackground = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  opacity: "0.2",
  objectFit: "cover",
});

const BetweenSiteCar = (props) => {
  const { user: User } = useSelector((state) => state.auth);
  const [numberOfPage, setNumberOfPage] = useState(1);
  const [Calling, setCalling] = useState(null);
  const [loading, setLoading] = useState(true);
  const [actionPage, setActionPage] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    BetweenSiteCarService.getBetweenSiteCarByIdUserIsNotFinish(User.idUser)
      .then((res) => {
        if (res.status === 200) {
          setCalling(res.data);
          setNumberOfPage(3);
          setLoading(false);
        }
        if (res.status === 204) {
          setNumberOfPage(1);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [actionPage]);

  const onClickNextPage = (data) => {
    console.log(data);
    if (numberOfPage === 2) {
      let calling = data;
      calling.idUser = User.idUser;
      console.log("Calling", calling);

      BetweenSiteCarService.postNewBetweenSiteCar(calling)
        .then((res) => {
          if (res.status === 200) {
            setCalling(res.data);
            setNumberOfPage(numberOfPage + 1);
            enqueueSnackbar("Calling Between Site Car Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Calling Between Site Car Failed", {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setNumberOfPage(numberOfPage + 1);
    }
  };

  const onClickCancelCalling = () => {
    BetweenSiteCarService.CancelCallCar(User.idUser)
      .then((res) => {
        if (res) {
          console.log(res);
          setNumberOfPage(1);
          setCalling(null);
          setLoading(true);
          setActionPage(!actionPage);
          enqueueSnackbar("Cancel Success", { variant: "success" });
        } else {
          enqueueSnackbar("Cancel Failed", { variant: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const OnClickMapButton = () => {
    props.history.push({
      pathname: "/betweenSite_Car/map",
      state: { data: Calling },
    });
  };

  return (
    <DivCoverReturn className="page">
      <PaperStyled>
        {loading && (
          <Grid item xs={12} container justifyContent="center" paddingTop="40%">
            <CircularProgress disableShrink />
          </Grid>
        )}

        {numberOfPage === 1 && !loading && (
          <CarVerification onClickNextPage={onClickNextPage} />
        )}
        {numberOfPage === 2 && !loading && (
          <AssignPlace onClickNextPage={onClickNextPage} />
        )}
        {numberOfPage === 3 && !loading && (
          <DetailOfCalling
            newCall={Calling}
            OnClickMapButton={OnClickMapButton}
            onClickCancelCalling={onClickCancelCalling}
          />
        )}
      </PaperStyled>
      <ImageBackground src={"./assets/background/background_betweenSite.jpg"} />
    </DivCoverReturn>
  );
};

export default BetweenSiteCar;
