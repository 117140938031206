import { httpClient } from "./httpClient";

const getAllCrossAreaCarPoolBookingPassengers = () => {
    return httpClient.get("cross_area_car_pool_booking_passengers");
};

const getCrossAreaCarPoolBookingPassengerByIdBooking = (id) => {
    return httpClient.get("cross_area_car_pool_booking_passengerbyIdbooking/"+id);
};
const deleteCarCrossAreaCarPoolBookingPassenger = (delCar) => {
    return httpClient.post("delete_car_cross_area_car_pool_booking_passenger", delCar);
};

export default {
    getAllCrossAreaCarPoolBookingPassengers,
    getCrossAreaCarPoolBookingPassengerByIdBooking,
    deleteCarCrossAreaCarPoolBookingPassenger
};