import React, { useState } from "react";
import { styled } from "@mui/material";
import "dayjs/locale/th";
import Form from "./Form";
import Result from "./Result";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1600,
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const Request = () => {
  const [isSuccess, setIsSuccess] = useState(null);
  const [resultData, setResultDate] = useState(null);

  const onSuccessHandler = (data) => {
    setIsSuccess(data.success);
    setResultDate(data.data);
  };

  return (
    <div className="page">
      <Root>
        {isSuccess === null && <Form onSuccessHandler={onSuccessHandler} />}
        {isSuccess === true && <Result data={resultData} />}
      </Root>
    </div>
  );
};

export default Request;
