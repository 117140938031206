import { httpClient } from "./httpClient";

const getAllPassengerFriendToFriends = () => {
    return httpClient.get("passengers_friendTofriend");
};

const getPassengerFriendToFriendById = (id) => {
    return httpClient.get("passenger_friendTofriend_byId/"+id);
};

const getPassengerFriendToFriendByIdDriver = (idDriver) => {
    return httpClient.get("passengers_friendTofriend_byidDriver/"+idDriver);
};

const getPassengerFriendToFriendByIdPassenger = (idPassenger) => {
    return httpClient.get("passengers_friendTofriend_byidPassenger/"+idPassenger);
};

const getWaitingResponsePassengerFriendToFriendByIdDriver = (idDriver) => {
    return httpClient.get("waitingResponse_passengers_friendTofriend_byidDriver/"+idDriver);
};

const getWaitingResponsePassengerFriendToFriendByIdPassenger = (idPassenger) => {
    return httpClient.get("waitingResponse_passengers_friendTofriend_byidPassenger/"+idPassenger);
};

const getPassengerFriendToFriendByIdRegisterAndDate = (data) => {
    return httpClient.post("passengers_friendTofriend_byidRegisterAndDate",data);
};

const postReponseFromDriver = (data) => {
    return httpClient.post("response_driver",data);
};

export default {
    getAllPassengerFriendToFriends,
    getPassengerFriendToFriendById,
    getPassengerFriendToFriendByIdDriver,
    getPassengerFriendToFriendByIdPassenger,
    getPassengerFriendToFriendByIdRegisterAndDate,
    getWaitingResponsePassengerFriendToFriendByIdDriver,
    getWaitingResponsePassengerFriendToFriendByIdPassenger,
    postReponseFromDriver
};