import React from "react";
import { Stack, Typography } from "@mui/material";
import Card from "../Card";
import dayjs from "dayjs";
import "dayjs/locale/th";

const groupDate = (data) => {
	let array = [];
	let groupDate = [];
	let currentGroupDate = new Date(data[0].createdAt).getDate();
	let count = 0;
	data.map((n, i)=>{
		if(new Date(n.createdAt).getDate() === currentGroupDate){
			groupDate.push(n);
		}
		else{
			array.push(<Item key={`card_notification_${count++}`} data={groupDate}/>)
			groupDate = []
			currentGroupDate = new Date(n.createdAt).getDate()
			groupDate.push(n);
		}
		if(i === data.length-1){
			array.push(<Item key={`card_notification_${count++}`} data={groupDate}/>)
		}
	})
	return array;
}

const Item = ({data}) => {
	console.log(data)
	return(
		<Card title={dayjs(new Date(data[0].createdAt)).locale('th').format("DD MMM YYYY")}>
			<Stack spacing={2}>
				{data.map((m, i)=>(
					<Typography key={`notification_${i}`}><span style={{color: "#9e9e9e"}}>{`${dayjs(new Date(m.createdAt)).locale('th').format("HH.mm น.")} | `}</span>{m.text}</Typography>
				))}
			</Stack>
		</Card>
	)
}

const Activity = ({data}) => {
	return(
		<Stack spacing={2}>
			{/* {data.map((n, i)=>( */}
			{/* {console.log(data)} */}
			{data?.length > 0 && groupDate(data)}
			{/* ))} */}
		</Stack>
	)
}

export default Activity;