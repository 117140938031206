import React, { useState } from 'react';
import { Controller, useForm } from "react-hook-form";

import { styled } from '@mui/styles';
import { 
    Modal, Paper, IconButton, Button, TextField, FormControl, InputLabel, Select, MenuItem,
    Grid,
} from '@mui/material';

import CardPackage from "../../../lender/formAddLentCar/conditionForm/CardPackage";
import { listPackages } from "../../../choise";

const EditButton = styled(IconButton)(({theme})=>({
    position:"absolute",
    right:"0px",
    top:"0px",
    fontSize:"16px",
    color:theme.palette.primary.main,
    "&:hover":{
        backgroundColor:"transparent",
    },
}));

const ModalStyled = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
});

const PaperStyled = styled(Paper)({
    width:"600px",
    height:"fit-content",
    padding:"30px 30px 20px",
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-end",
});

const GridContainer = styled(Grid)({

});

const PartOfPackage = styled("div")({
    width:"100%",
    height:"fit-content",
    display:"flex",
    padding:"30px 0 10px",
    overflowX:"auto",
    overflow:"hidden",
});

const ConfirmButton = styled(Button)(({theme})=>({
    marginTop:"20px",
    padding:"7px 10px",
    backgroundColor:theme.palette.success.main,
    color:theme.palette.success.contrastText,
    fontSize:"16px",
    "&:hover":{
        backgroundColor:theme.palette.success.dark,
    },
}));

const ModalPlaceAndPackage = props => {
    const { register, setValue, control } = useForm({
        defaultValues:{
            province:props.province?props.province:"",
            district:props.district?props.district:"",
        }
    });
    const [openModal, setOpenModal] = useState(false);
    const [packageSelected, setPackageSelected] = useState(props.packageId);

    const OnCloseFunction = () => {
        setValue("province",props.province);
        setValue("district",props.district);
        setPackageSelected(props.packageId);
        setOpenModal(false);
    };

    const OnClickConfirmButton = () => {
        setOpenModal(false);
    };

    return(
        <>
        {props.edit &&
            <EditButton onClick={()=>setOpenModal(true)} disableRipple><i className="fas fa-pen"></i></EditButton>
        }
            <ModalStyled
                open={openModal}
                onClose={OnCloseFunction}
            >
                <PaperStyled>
                    <GridContainer container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth style={{marginTop:"10px"}}>
                                <InputLabel id="Province">จังหวัด</InputLabel>
                                <Controller 
                                    control={control}
                                    name="province"
                                    render={({field})=>(
                                        <Select
                                            {...field}
                                            labelId="Province-label"
                                            id="Province"
                                            label="จังหวัด"
                                        >
                                            <MenuItem value="กรุงเทพฯ">กรุงเทพฯ</MenuItem>
                                            <MenuItem value="ระยอง">ระยอง</MenuItem>
                                            <MenuItem value="สระบุรี">สระบุรี</MenuItem>
                                            <MenuItem value="ตรัง">ตรัง</MenuItem>
                                            <MenuItem value="ขอนแก่น">ขอนแก่น</MenuItem>
                                            <MenuItem value="กระบี่">กระบี่</MenuItem>
                                            <MenuItem value="นครนายก">นครนายก</MenuItem>
                                            <MenuItem value="นครปฐม">นครปฐม</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth style={{marginTop:"10px"}}>
                                <InputLabel id="District">เขต/อำเภอ</InputLabel>
                                <Controller 
                                    control={control}
                                    name="district"
                                    render={({field})=>(
                                        <Select
                                            {...field}
                                            labelId="District-label"
                                            id="District"
                                            label="เขต/อำเภอ"
                                        >
                                            <MenuItem value="กรุงเทพฯ">กรุงเทพฯ</MenuItem>
                                            <MenuItem value="ระยอง">ระยอง</MenuItem>
                                            <MenuItem value="สระบุรี">สระบุรี</MenuItem>
                                            <MenuItem value="ตรัง">ตรัง</MenuItem>
                                            <MenuItem value="ขอนแก่น">ขอนแก่น</MenuItem>
                                            <MenuItem value="กระบี่">กระบี่</MenuItem>
                                            <MenuItem value="นครนายก">นครนายก</MenuItem>
                                            <MenuItem value="นครปฐม">นครปฐม</MenuItem>
                                            <MenuItem value="ห้วยใหญ่">ห้วยใหญ่</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth style={{marginTop:"10px"}}>
                                <InputLabel id="Subdistrict">แขวง/ตำบล</InputLabel>
                                <Controller 
                                    control={control}
                                    name="subdistrict"
                                    defaultValue=""
                                    render={({field})=>(
                                        <Select
                                            {...field}
                                            labelId="Subdistrict-label"
                                            id="Subdistrict"
                                            label="แขวง/ตำบล"
                                        >
                                            <MenuItem value="กรุงเทพฯ">กรุงเทพฯ</MenuItem>
                                            <MenuItem value="ระยอง">ระยอง</MenuItem>
                                            <MenuItem value="สระบุรี">สระบุรี</MenuItem>
                                            <MenuItem value="ตรัง">ตรัง</MenuItem>
                                            <MenuItem value="ขอนแก่น">ขอนแก่น</MenuItem>
                                            <MenuItem value="กระบี่">กระบี่</MenuItem>
                                            <MenuItem value="นครนายก">นครนายก</MenuItem>
                                            <MenuItem value="นครปฐม">นครปฐม</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth style={{marginTop:"10px"}}>
                                <InputLabel id="zipcode">ZIP code</InputLabel>
                                <Controller 
                                    control={control}
                                    name="zipcode"
                                    defaultValue=""
                                    render={({field})=>(
                                        <Select
                                            {...field}
                                            labelId="zipcode-label"
                                            id="zipcode"
                                            label="ZIP code"
                                        >
                                            <MenuItem value="10150">10150</MenuItem>
                                            <MenuItem value="10180">10180</MenuItem>
                                            <MenuItem value="21120">21120</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </GridContainer>
                    <PartOfPackage>
                    { listPackages.map((Package)=>(
                        <CardPackage 
                            key={Package.package}
                            Package={Package} 
                            index={Package.id}
                            packageSelected={packageSelected}
                            onClickCard={(indexpackage)=>setPackageSelected(indexpackage)}
                        />
                    ))}
                    </PartOfPackage>
                    <ConfirmButton onClick={OnClickConfirmButton}>Confirm</ConfirmButton>
                </PaperStyled>
            </ModalStyled>
        </>
    );
};

export default ModalPlaceAndPackage;