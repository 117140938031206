import React, { useEffect } from 'react';
import clsx from "clsx";

import makeStyles from '@mui/styles/makeStyles';
import {
    LinearProgress, Avatar, Grid, Typography
} from "@mui/material";

const useStyles = makeStyles((theme)=>({
    DivCoverReturn:{
        width:"100%",
        height:"100%",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        border:"1px solid",
        borderColor:theme.palette.grey[400],
        borderTop:"0",
        borderLeft:"0",
        borderBottom:"0",
    },
    ProgressBar:{
        width:"90%",
        height:"30px",
        marginTop:"20px",
        marginBottom:"20px",
    },
    Avatar:{
        width:"90%",
        height:"200px",
        [theme.breakpoints.down('lg')]:{
            height:"150px"
        },
        [theme.breakpoints.down('md')]:{
            height:"100px"
        },
    },
    GridContainer:{
        width:"90%",
        marginTop:"20px",
    },
    Text:{
        fontSize:"24px",
        marginLeft:"20px",
    },
    Icon:{
        fontSize:"26px",
    },
    FinishIcon:{
        color:"green",
    },
    NotFinishIcon:{
        color:"red",
    },
}));

const ListForm = props => {
    const classes = useStyles();

    useEffect(() => {
        
    }, [props.processValue])

    return (
        <div className={`${classes.DivCoverReturn}`}>
            <LinearProgress className={`${classes.ProgressBar}`} variant="determinate" value={props.processValue} />
            <Avatar src="../../assets/Image/friend2friend/lender/car_beforeUpload.jpeg" alt="car" className={`${classes.Avatar}`} variant="square" />
            <Grid container spacing={1} className={`${classes.GridContainer}`}>
                <Grid item xs={10}>
                    <Typography className={`${classes.Text}`}>
                        Location
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <span className={clsx(classes.Icon, { [classes.FinishIcon] : props.indexProcess > 1, [classes.NotFinishIcon] : props.indexProcess <= 1 })}>
                        { props.indexProcess > 1 ?
                            <i className="far fa-check-circle"></i>
                            :
                            <i className="far fa-times-circle"></i>
                        }
                    </span>
                </Grid>
                <Grid item xs={10}>
                    <Typography className={`${classes.Text}`}>
                        Car Detail
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <span className={clsx(classes.Icon, { [classes.FinishIcon] : props.indexProcess > 2, [classes.NotFinishIcon] : props.indexProcess <= 2 })}>
                        { props.indexProcess > 2 ?
                            <i className="far fa-check-circle"></i>
                            :
                            <i className="far fa-times-circle"></i>
                        }
                    </span>
                </Grid>
                <Grid item xs={10}>
                    <Typography className={`${classes.Text}`}>
                        Profile
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <span className={clsx(classes.Icon, { [classes.FinishIcon] : props.indexProcess > 3, [classes.NotFinishIcon] : props.indexProcess <= 3 })}>
                        { props.indexProcess > 3 ?
                            <i className="far fa-check-circle"></i>
                            :
                            <i className="far fa-times-circle"></i>
                        }
                    </span>
                </Grid>
                <Grid item xs={10}>
                    <Typography className={`${classes.Text}`}>
                        Price
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <span className={clsx(classes.Icon, { [classes.FinishIcon] : props.indexProcess > 4, [classes.NotFinishIcon] : props.indexProcess <= 4 })}>
                        { props.indexProcess > 4 ?
                            <i className="far fa-check-circle"></i>
                            :
                            <i className="far fa-times-circle"></i>
                        }
                    </span>
                </Grid>
                <Grid item xs={10}>
                    <Typography className={`${classes.Text}`}>
                        Condition
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <span className={clsx(classes.Icon, { [classes.FinishIcon] : props.indexProcess > 5, [classes.NotFinishIcon] : props.indexProcess <= 5 })}>
                        { props.indexProcess > 5 ?
                            <i className="far fa-check-circle"></i>
                            :
                            <i className="far fa-times-circle"></i>
                        }
                    </span>
                </Grid>
                <Grid item xs={10}>
                    <Typography className={`${classes.Text}`}>
                        Payment
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <span className={clsx(classes.Icon, { [classes.FinishIcon] : props.indexProcess > 6, [classes.NotFinishIcon] : props.indexProcess <= 6 })}>
                        { props.indexProcess > 6 ?
                            <i className="far fa-check-circle"></i>
                            :
                            <i className="far fa-times-circle"></i>
                        }
                    </span>
                </Grid>
                <Grid item xs={10}>
                    <Typography className={`${classes.Text}`}>
                        Privacy
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <span className={clsx(classes.Icon, { [classes.FinishIcon] : props.indexProcess > 7, [classes.NotFinishIcon] : props.indexProcess <= 7 })}>
                        { props.indexProcess > 7 ?
                            <i className="far fa-check-circle"></i>
                            :
                            <i className="far fa-times-circle"></i>
                        }
                    </span>
                </Grid>
            </Grid>
        </div>
    );
};

export default ListForm;