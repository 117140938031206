import React, { useState } from "react";
import clsx from "clsx";

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

const DialogTitleStyled = styled(DialogTitle)({
  display: "flex",
  justifyContent: "center",
  borderBottom: "2px  solid black",
  padding: "5px 0px",
  backgroundColor: "rgb(233,215,0)",
  display: "flex",
  alignItems: "center",
});

const IconEmergency = styled("img")({
  width: "50px",
  height: "50px",
});

const Topic = styled(Typography)(({ theme }) => ({
  fontSize: "50px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
}));

const TextStyled = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  marginTop: "20px",
  color: "white",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
  },
}));

const DialogContentStyled = styled(DialogContent)({
  backgroundColor: "black",
});

const DialogActionsStyled = styled(DialogActions)({
  backgroundColor: "black",
  paddingBottom: "15px",
});

const ListItemButtonStyled = styled(ListItemButton)({
  border: "0.5px solid black",
  backgroundColor: "white",
});

const Text = styled(Typography)({
  fontSize: "20px",
});

const ButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: "20px",
  marginRight: "20px",
  borderRadius: "20px",
  width: "130px",
  height: "70px",
  color: "white",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const CancelButtonStyled = styled(Button)(({ theme }) => ({
  color: "white",
  fontSize: "20px",
  marginRight: "20px",
  borderRadius: "20px",
  width: "130px",
  height: "70px",
  backgroundColor: theme.palette.error.main,
  "&:hover": {
    backgroundColor: theme.palette.error.main,
  },
}));

const DialogUpdateEmergency = (props) => {
  return (
    <Dialog
      fullWidth={true}
      open={props.openDialog}
      onClose={() => props.OpenDialogHandler(!props.openDialog)}
    >
      <DialogTitleStyled>
        <IconEmergency src="./assets/Image/Icon/siren.png" alt="siren" />
        <Topic>แจ้งเหตุฉุกเฉินสิ้นสุด</Topic>
        <IconEmergency src="./assets/Image/Icon/siren.png" alt="siren" />
      </DialogTitleStyled>
      <DialogContentStyled>
        {/* <List>
          {props.ListDialog.map((value, index) => {
            return (
              <ListItemButtonStyled
                key={value}
                className={clsx({ SelectList: selectList === index })}
                onClick={() => setSelectList(index)}
              >
                <ListItemText disableTypography={true}>
                  <Text>{value}</Text>
                </ListItemText>
              </ListItemButtonStyled>
            );
          })}
        </List> */}
        <TextStyled>เหตุฉุกเฉินสิ้นสุดแล้วใช่หรือไม่</TextStyled>
      </DialogContentStyled>
      <DialogActionsStyled>
        <CancelButtonStyled
          onClick={() => props.OpenDialogHandler(!props.openDialog)}
        >
          ยกเลิก
        </CancelButtonStyled>
        <ButtonStyled onClick={() => props.onClickEmergencyHandler()}>
          ยืนยัน
        </ButtonStyled>
      </DialogActionsStyled>
    </Dialog>
  );
};

export default DialogUpdateEmergency;
