import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import {
  Modal,
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { SnackbarProvider, useSnackbar } from "notistack";

import DriveEtaIcon from "@mui/icons-material/DriveEta";
import ButtonBlue from "../../../../shared/buttonBlue";
import VehicleBrandAndModelService from "../../../../../../services/vehicleBrandAndModel.service";
import InAreaCarBookingService from "../../../../../../services/inAreaCarBooking.service";
import UserService from "../../../../../../services/user.service";
import vehicleService from "../../../../../../services/vehicle.service";
import vehicleBrandAndModelService from "../../../../../../services/vehicleBrandAndModel.service";
import { getAllDriver } from "../../../../../../actions/user";
import { getAllVehicles } from "../../../../../../actions/vehicle";
import { getAllVehicleBrandsAndModels } from "../../../../../../actions/vehicleBrandAndModel";

const useStyles = makeStyles((theme) => ({
  Modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Paper: {
    width: "600px",
    padding: "20px",
  },
  partOfTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  IconCar: {
    fontSize: "40px",
    marginRight: "10px",
    color: theme.palette.primary.main,
  },
  Title: {
    fontSize: "30px",
    color: theme.palette.primary.main,
  },
  partOfTitleName: {
    display: "flex",
    alignItems: "center",
  },
  partOfNavSwitch: {
    display: "flex",
    alignItems: "center",
  },
  partOfNavButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  Button: {
    width: "100px",
    marginTop: "20px",
    backgroundColor: theme.palette.primary.main,
    fontSize: "18px",
    color: "white",
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const ModalManageCar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const { result: allDriver } = useSelector((state) => state.drivers);
  const { result: listVehicle } = useSelector((state) => state.vehicles);
  const { result: listVehicleBrandAndModel } = useSelector(
    (state) => state.vehicleBrandsAndModels
  );
  const [isIdDiver, setIdDriver] = useState();
  const [selectTypeCar, setselectTypeCar] = useState(
    props.inforManageCar?.idTypeCar
  );
  const [selectBrandModel, setselectBrandModel] = useState(
    props.inforManageCar?.idVehicleBrandAndModel
  );
  const [isDriverFromCompany, setIsDriverFromCompany] = useState(
    props.inforManageCar.isDriverFromCompany !== null
      ? props.inforManageCar.isDriverFromCompany
      : true
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!allDriver) {
      dispatch(getAllDriver());
    }
    if (!listVehicle) {
      dispatch(getAllVehicles());
    }
    if (!listVehicleBrandAndModel) {
      dispatch(getAllVehicleBrandsAndModels());
    }
    setValue("model", props.inforManageCar.model);
    setValue("idVehicle", props.inforManageCar.idVehicle);
  }, []);

  const onClickSubmit = (AnswerFromInput) => {
    AnswerFromInput.id = props.inforManageCar.idinAreaCarBooking;
    AnswerFromInput.isDriverFromCompany = isDriverFromCompany;
    if (!AnswerFromInput.isDriverFromCompany) {
      AnswerFromInput.idDriver = null;
      AnswerFromInput.idVehicle = null;
      AnswerFromInput.idVehicleBrandAndModel = null;
      AnswerFromInput.idTypeCar = null;
      AnswerFromInput.model = null;
    }
    console.log(AnswerFromInput);
    InAreaCarBookingService.postManageCarInAreaCarBooking(
      AnswerFromInput,
      isIdDiver
    ).then((res) => {
      if (res.data) {
        props.handleActionInPage();
        props.onCloseHandle();
        enqueueSnackbar("Data saved successfully", { variant: "success" });
      } else {
        enqueueSnackbar("Data saved failed", { variant: "error" });
      }
    });
  };

  return (
    <Modal
      className={`${classes.Modal}`}
      open={props.open}
      onClose={() => {
        props.onCloseHandle();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper className={`${classes.Paper}`}>
        <div className={`${classes.partOfTitle}`}>
          <div className={`${classes.partOfTitleName}`}>
            <DriveEtaIcon className={`${classes.IconCar}`} />
            <Typography className={`${classes.Title}`}>จัดรถ</Typography>
            {isDriverFromCompany ? (
              <Typography className={`${classes.Title}`}>ในบริษัท</Typography>
            ) : (
              <Typography className={`${classes.Title}`}>
                จากบริษัทอื่น
              </Typography>
            )}
          </div>
          <div className={`${classes.partOfNavSwitch}`}>
            <ButtonBlue
              variant="contained"
              onClick={() => {
                setValue("isDriverFromCompany", !isDriverFromCompany);
                setIsDriverFromCompany(!isDriverFromCompany);
              }}
            >
              {isDriverFromCompany
                ? "เลือกคนขับจากบริษัทอื่น"
                : "เลือกคนขับในบริษัท"}
            </ButtonBlue>
          </div>
        </div>
        <form onSubmit={handleSubmit(onClickSubmit)}>
          <Grid container spacing={3}>
            {isDriverFromCompany ? (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="TypeCar">ประเภทรถ</InputLabel>
                    <Controller
                      render={(propsInController) => (
                        <Select
                          labelId="TypeCar"
                          id="TypeCar"
                          error={errors.idTypeCar ? true : false}
                          value={propsInController.field.value}
                          onChange={(e) => {
                            setValue("model", "");
                            setValue("plate_No", "");
                            setValue("idVehicleBrandAndModel", "");
                            setselectTypeCar(e.target.value);
                            propsInController.field.onChange(e);
                          }}
                          label="ประเภทรถ"
                        >
                          <MenuItem value={2}>รถเก๋ง</MenuItem>
                          <MenuItem value={3}>รถกระบะ</MenuItem>
                          <MenuItem value={1}>รถตู้</MenuItem>
                          <MenuItem value={4}>รถบัส</MenuItem>
                        </Select>
                      )}
                      name="idTypeCar"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={props.inforManageCar?.idTypeCar}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  {listVehicleBrandAndModel && (
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="BrandAndModel">
                        แบรนด์และรุ่นรถ
                      </InputLabel>
                      <Controller
                        render={(propsInController) => (
                          <Select
                            labelId="BrandAndModel"
                            id="BrandAndModel"
                            error={errors.idVehicleBrandAndModel ? true : false}
                            value={propsInController.field.value}
                            onChange={(e) => {
                              console.log(e.target.value);
                              const vehicleBrandAndModel =
                                listVehicleBrandAndModel.find((item) => {
                                  if (
                                    item.idVehicleBrandsAndModels ===
                                    e.target.value
                                  )
                                    return item;
                                });

                              setValue("model", vehicleBrandAndModel.model);
                              setValue("plate_No", "");
                              setselectBrandModel(e.target.value);
                              propsInController.field.onChange(e);
                            }}
                            label="แบรนด์และรุ่นรถ"
                          >
                            {listVehicleBrandAndModel
                              .filter((item) => item.idType === selectTypeCar)
                              .map((item) => (
                                <MenuItem
                                  key={item.idVehicleBrandAndModel}
                                  value={item.idVehicleBrandsAndModels}
                                >
                                  {item.model}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                        name="idVehicleBrandAndModel"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={
                          props.inforManageCar?.idVehicleBrandAndModel
                        }
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {listVehicle && (
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="plate_No">ทะเบียนรถ</InputLabel>
                      <Controller
                        render={(propsInController) => (
                          <Select
                            labelId="plate_No"
                            id="plate_No"
                            value={propsInController.field.value}
                            onChange={(e) => {
                              const vehicle = listVehicle.find((value) => {
                                if (value.Plate_No === e.target.value) {
                                  return value;
                                }
                              });

                              setValue("idVehicle", vehicle.idVehicle);
                              propsInController.field.onChange(e);
                            }}
                            label="ทะเบียนรถ"
                            helperText={
                              errors &&
                              errors.plate_No &&
                              errors.plate_No.message
                            }
                            error={errors && errors.plate_No ? true : false}
                          >
                            {listVehicle &&
                              listVehicle
                                .filter((value) => {
                                  if (
                                    value.UseFor === "รถในพื้นที่" &&
                                    value.idVehicleBrandAndModel ===
                                      selectBrandModel
                                  ) {
                                    return value;
                                  }
                                })
                                .map((vehicle) => (
                                  <MenuItem
                                    key={vehicle.idVehicle}
                                    value={vehicle.Plate_No}
                                  >
                                    {`${vehicle.model} (${vehicle.Plate_No})`}
                                  </MenuItem>
                                ))}
                          </Select>
                        )}
                        name="plate_No"
                        rules={{
                          required: {
                            value: true,
                            message: "กรุณาเลือกทะเบียนรถยนต์",
                          },
                        }}
                        defaultValue={props.inforManageCar?.plate_No}
                        control={control}
                      />
                    </FormControl>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="NameDriver">ชื่อคนขับรถ</InputLabel>

                    <Controller
                      render={(propsInController) => (
                        <Select
                          labelId="NameDriver"
                          id="NameDriver"
                          error={errors && errors.nameDriver ? true : false}
                          helperText={
                            errors && errors.nameDriver
                              ? "กรุณาเลือกชื่อคนขับรถ"
                              : null
                          }
                          value={propsInController.field.value}
                          onChange={propsInController.field.onChange}
                          label="ชื่อคนขับรถ"
                        >
                          {allDriver &&
                            allDriver.map((driver) => (
                              <MenuItem
                                key={driver.idUser}
                                value={driver.idUser}
                              >
                                {driver.FullName}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                      name="nameDriver"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={props.inforManageCar?.idDriver}
                    />
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        {...propsInController.field}
                        label="ทะเบียนรถ"
                        error={errors && errors.plate_No ? true : false}
                        helperText={
                          errors && errors.plate_No && errors.plate_No.message
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    name="plate_No"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "กรุณากรอกทะเบียนรถยนต์",
                      },
                    }}
                    defaultValue={props.inforManageCar?.plate_No}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        {...propsInController.field}
                        label="ชื่อคนขับรถ"
                        error={errors && errors.nameDriver ? true : false}
                        helperText={
                          errors &&
                          errors.nameDriver &&
                          errors.nameDriver.message
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    name="nameDriver"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "กรุณากรอกชื่อคนขับ",
                      },
                    }}
                    defaultValue={props.inforManageCar?.nameDriver}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        {...propsInController.field}
                        label="เบอร์ติดต่อคนขับรถ"
                        error={errors && errors.phoneDriver ? true : false}
                        helperText={
                          errors &&
                          errors.phoneDriver &&
                          errors.phoneDriver.message
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    name="phoneDriver"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "กรุณากรอกเบอร์ติดต่อคนขับ",
                      },
                    }}
                    defaultValue={props.inforManageCar?.phoneDriver}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>{"GA site"}</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      label="GA Site"
                      error={errors.gaSite ? true : false}
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                    >
                      <MenuItem value={"site 1"}>site 1</MenuItem>
                      <MenuItem value={"site 2"}>site 2</MenuItem>
                      <MenuItem value={"site 3"}>site 3</MenuItem>
                      <MenuItem value={"site 7"}>site 7</MenuItem>
                      <MenuItem value={"GACenter"}>GA Center</MenuItem>
                    </Select>
                  )}
                  name="gaSite"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={props.inforManageCar?.gaSite}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="Note"
                label="หมายเหตุ"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                inputProps={{
                  ...register("note", { value: props.inforManageCar?.note }),
                }}
              />
            </Grid>
          </Grid>
          <div className={`${classes.partOfNavButton}`}>
            <ButtonBlue className={`${classes.Button}`} type="submit">
              จัดรถ
            </ButtonBlue>
          </div>
        </form>
      </Paper>
    </Modal>
  );
};

export default ModalManageCar;
