import React, { useState } from 'react';
import clsx from "clsx";

import makeStyles from '@mui/styles/makeStyles';
import {
    Button, Typography,
} from "@mui/material";

import GetAppIcon from '@mui/icons-material/GetApp';

const useStyles = makeStyles((theme)=>({
    Content:{
        width:"95%",
        height:"calc(100% - 40px)",
        padding:"20px 0",
    },
    TextExample:{
        fontSize:"26px",
    },
    partOfExImage:{
        width:"250px",
        height:"150px",
        minWidth:"250px",
        border:"1px solid black",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        marginRight:"10px",
    },
    partOfImage:{
        width:"250px",
        height:"150px",
        minWidth:"250px",
    },
    Image:{
        objectFit:"contain",
        width:"100%",
        height:"100%",
    },
    partOfDragAndDrop:{
        width:"100%",
        height:"60%",
        border:"2px dashed",
        borderColor:theme.palette.grey[500],
        marginTop:"10px",
    },
    DragAndDrop:{
        width:"100%",
        height:"100%",
        overflowY:"auto",
        "& span":{
            flexDirection:"column",
        }
    },
    DragAndDropHavingPicture:{
        justifyContent:"flex-start",
    },
    partOfDragAndDropArea:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
    },
    partOfDragAndDropAreaHavingPicture:{
        flexDirection:"row",
    },
    IconDragAndDrop:{
        fontSize:"80px",
        color:theme.palette.grey[500],
    },
    TextDragAndDrop:{
        fontSize:"26px",
        fontWeight:"bold",
        color:theme.palette.grey[500],
        [theme.breakpoints.down("sm")]:{
            fontSize:"22px"
        }
    },
    NextButton:{
        width:"fit-content",
        color:"green",
        fontSize:"18px",
        "&:hover":{
            backgroundColor:"white",
        }
    },
}));

const ImageCarForm = props => {
    const classes = useStyles();

    const previewImage = () => {
        let previewImageSrc = [];
        if(props.imageFiles.length > 0){
            props.imageFiles.map((image)=> previewImageSrc.push(URL.createObjectURL(image)))
        }
        return previewImageSrc;
    };

    const validationFile = (file) => {
        const types = ["image/jpg","image/jpeg","image/png"];
        if(types.includes(file.type)){
            return true;
        }
        return false;
    }

    const onDragHandler = event => {
        event.preventDefault();
    };

    const onDropHandler = event => {
        event.preventDefault();
        var Images = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        let TempFiles = [...props.imageFiles];
        [...new Array(Images.length)].map((value,index)=>{
            if(validationFile(Images[index])){
                TempFiles.push(Images[index]);
            }
        })
        console.log("TempFiles : ",TempFiles);
        if(TempFiles.length > 0){
            props.setImageFiles(TempFiles);
        }
    };

    return(
        <div className={`${classes.Content}`}>
            <Typography className={`${classes.TextExample}`}>ตัวอย่าง</Typography>
            <div style={{width:"100%",display:"flex",overflowX:"auto",}}>
                {[...new Array(5)].map((value,index)=>(
                    <div className={`${classes.partOfExImage}`} key={index}>
                        รูปภาพ
                    </div>
                ))}
            </div>
            <div 
                className={`${classes.partOfDragAndDrop}`} 
                onDragOver={onDragHandler}
			    onDragEnter={onDragHandler}
			    onDragLeave={onDragHandler}
                onDrop={(event)=>onDropHandler(event)}
            >
                <Button className={clsx(classes.DragAndDrop,{[classes.DragAndDropHavingPicture] : previewImage().length > 0})} component="label" disableRipple disableFocusRipple>
                    <input type="file" onChange={(event)=>onDropHandler(event)} hidden/>
                    <div className={clsx(classes.partOfDragAndDropArea,{[classes.partOfDragAndDropAreaHavingPicture] : previewImage().length > 0})}>
                        {previewImage().length > 0 ? previewImage().map((value,index)=>(
                            <div className={`${classes.partOfImage}`} key={index}>
                                <img className={`${classes.Image}`} src={value} />
                            </div>
                            ))
                            :
                            <>
                                <span className={`${classes.IconDragAndDrop}`}><i className="fas fa-cloud-upload-alt"></i></span>
                                <Typography className={`${classes.TextDragAndDrop}`}>{"Drag & Drop to Upload File"}</Typography>
                                <Typography className={`${classes.TextDragAndDrop}`} style={{fontSize:"16px",fontWeight:"normal"}}>Or</Typography>
                                <Typography className={`${classes.TextDragAndDrop}`} style={{fontSize:"20px",}}>Click this Area</Typography>
                            </>
                        }
                    </div>
                </Button>
            </div>
            <div style={{width:"100%",display:"flex",justifyContent:"flex-end",padding:"10px 0"}}>
                <Button className={`${classes.NextButton}`} onClick={props.onClickBackProcess}>Back</Button>
                <Button className={`${classes.NextButton}`} onClick={props.onClickNextProcess}>Next</Button>
            </div>
        </div>
    );
};

export default ImageCarForm;