import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { useMediaQuery } from "@mui/material";

import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PeopleIcon from "@mui/icons-material/People";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import RoomIcon from "@mui/icons-material/Room";
import PersonIcon from "@mui/icons-material/Person";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PhoneIcon from "@mui/icons-material/Phone";

import VehicleBrandAndModelService from "../../../../../services/vehicleBrandAndModel.service";

const useStyles = makeStyles((theme) => ({
  Title: {
    fontSize: "30px",
  },
  typeAndModelDetail: {
    textAlign: "center",
  },
  Dialog: {
    paddingBottom: "30px",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("sm")]: {
        margin: "0",
      },
    },
  },
  PaddingBottom: {
    paddingBottom: "0px",
  },
  GridTypeAndModel: {
    marginBottom: "10px",
  },
  Fixed: {
    backgroundColor: "orange",
    color: "white",
    "&:hover": {
      backgroundColor: "#DCA700",
    },
  },
  Confirm: {
    backgroundColor: "green",
    color: "white",
    "&:hover": {
      backgroundColor: "#72812e",
    },
  },
  GridDetail: {
    width: "calc(100% - 8%)",
    marginLeft: "8%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      width: "100%",
    },
  },
  BoldText: {
    fontWeight: "bold",
  },
  TextSize: {
    fontSize: "18px",
  },
  Icon: {
    fontSize: "20px",
    marginRight: "5px",
  },
  GridTextIcon: {
    display: "flex",
  },
  PaddingRight: {
    paddingRight: "0px !important",
  },
  DialogContent: {
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      height: "550px",
      overflowY: "auto",
    },
  },
}));

const GridImage = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  "& .Mobile": {
    width: "80%",
  },
});

const Image = styled("img")(({ theme }) => ({
  width: "45%",
  [theme.breakpoints.down("sm")]: {
    width: "250px !important",
    height: "150px",
  },
}));

const GridContainText = styled(Grid)(({ theme }) => ({
  display: "flex",
  wordWrap: "break-word",
  paddingRight: "16px",
}));

const DialogShowDetailBooking = (props) => {
  const classes = useStyles();
  const [vehicle, setVehicle] = useState(null);

  const MobileResponsive = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    VehicleBrandAndModelService.getVehicleBrandAndModelById(
      props.getValues("idVehicleBrandAndModel")
    )
      .then((res) => {
        if (res.data) {
          setVehicle(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getDayFromInput = (date) => {
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  };

  const getTotalPrice = () => {
    const timeStart = new Date("2000-01-01T" + props.getValues("startTime"));
    const timeEnd = new Date("2000-01-01T" + props.getValues("endTime"));
    const costBooking = props.getValues("costBooking");
    const extraCostBooking = props.getValues("extraCostBooking");
    const flight = props.getValues("flight");
    let totalCost = 0;
    console.log(timeStart, timeEnd, costBooking, extraCostBooking, flight);
    console.log(findExtraTime(timeStart, timeEnd));
    totalCost +=
      costBooking + extraCostBooking * findExtraTime(timeStart, timeEnd);
    if (flight === "twoWay") {
      const timeStartReturn = new Date(
        "2000-01-01T" + props.getValues("startTimeReturn")
      );
      const timeEndReturn = new Date(
        "2000-01-01T" + props.getValues("endTimeReturn")
      );
      console.log(timeStartReturn, timeEndReturn);
      console.log(findExtraTime(timeStartReturn, timeEndReturn));
      totalCost +=
        costBooking +
        extraCostBooking * findExtraTime(timeStartReturn, timeEndReturn);
    }
    props.setValue("totalPrice", totalCost);
    return totalCost;
  };

  const findExtraTime = (startTime, endTime) => {
    const startWorkTime = new Date("2000-01-01T08:00:00");
    const finishWorkTime = new Date("2000-01-01T17:00:00");
    let extraTime = 0;
    if (startTime < startWorkTime) {
      extraTime += (startWorkTime - startTime) / (1000 * 60 * 60);
    }
    if (endTime > finishWorkTime) {
      extraTime += (endTime - finishWorkTime) / (1000 * 60 * 60);
    }
    return extraTime;
  };

  return (
    <Dialog
      open={props.open.detailBooking}
      className={classes.Dialog}
      maxWidth="md"
    >
      <DialogTitle className={classes.PaddingBottom}>
        <Typography
          className={`${classes.Title} ${classes.typeAndModelDetail}`}
        >
          จองรถข้ามพื้นที่เฉพาะ
        </Typography>
      </DialogTitle>
      {props.watch("nameApproved") && vehicle && (
        <DialogContent className={`${classes.DialogContent}`}>
          <Grid container>
            <GridImage item xs={12}>
              <Image
                className={clsx({ Mobile: MobileResponsive })}
                src={`${process.env.REACT_APP_URL}image/car/${vehicle.imagepath}`}
                alt={vehicle.model}
              />
            </GridImage>
            <Grid item xs={12} className={classes.GridTypeAndModel}>
              <Typography
                className={`${classes.typeAndModelDetail} ${classes.TextSize} ${classes.BoldText}`}
              >{`${vehicle.vehicleTypeNameTH} ${vehicle.model}`}</Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              spacing={2}
              className={classes.GridDetail}
            >
              <GridContainText item xs={12} sm={6}>
                <CalendarTodayIcon className={classes.Icon} />
                <Typography className={`${classes.TextSize}`}>
                  วันที่เริ่มต้น :{" "}
                  <span className={classes.BoldText}>
                    {getDayFromInput(props.getValues("departureDate"))}
                  </span>
                </Typography>
              </GridContainText>
              <GridContainText item xs={12} sm={6}>
                <CalendarTodayIcon className={classes.Icon} />
                <Typography className={`${classes.TextSize}`}>
                  วันที่สุดท้าย :{" "}
                  <span className={classes.BoldText}>
                    {props.getValues("flight") === "twoWay"
                      ? getDayFromInput(props.getValues("returnDate"))
                      : getDayFromInput(props.getValues("departureDate"))}
                  </span>
                </Typography>
              </GridContainText>
              <GridContainText item xs={12} sm={6}>
                <DriveEtaIcon className={classes.Icon} />
                <Typography className={`${classes.TextSize}`}>
                  ประเภท :{" "}
                  <span className={classes.BoldText}>
                    {props.getValues("flight") === "oneWay"
                      ? "เที่ยวเดียว"
                      : "ไป-กลับ"}
                  </span>
                </Typography>
              </GridContainText>
              <GridContainText item xs={12} sm={6}>
                <PeopleIcon className={classes.Icon} />
                <Typography className={`${classes.TextSize}`} noWrap>
                  จำนวนที่นั่ง :{" "}
                  <span className={classes.BoldText}>{vehicle.capacity}</span>{" "}
                  ที่นั่ง
                </Typography>
              </GridContainText>
              <GridContainText item xs={12} sm={6}>
                <RoomIcon className={classes.Icon} />
                <Typography className={`${classes.TextSize}`}>
                  จุดรับ :{" "}
                  <span className={classes.BoldText}>
                    {props.getValues("fromPlace")}
                  </span>
                </Typography>
              </GridContainText>
              <GridContainText item xs={12} sm={6}>
                <RoomIcon className={classes.Icon} />
                <Typography className={`${classes.TextSize}`}>
                  จุดส่ง :{" "}
                  <span className={classes.BoldText}>
                    {props.getValues("toPlace")}
                  </span>
                </Typography>
              </GridContainText>
              <GridContainText item xs={12} sm={6}>
                <PersonIcon className={classes.Icon} />
                <Typography
                  className={`${classes.PaddingLeft} ${classes.TextSize}`}
                >
                  ผู้แจ้งขอ :{" "}
                  <span className={classes.BoldText}>
                    {props.getValues("name")}
                  </span>
                </Typography>
              </GridContainText>
              <GridContainText item xs={12} sm={6}>
                <PhoneIcon className={classes.Icon} />
                <Typography className={`${classes.TextSize}`}>
                  Tel. :{" "}
                  <span className={classes.BoldText}>
                    {props.getValues("telephoneMobile")}
                  </span>
                </Typography>
              </GridContainText>
              <GridContainText item xs={12} sm={6}>
                <MonetizationOnIcon className={classes.Icon} />
                <Typography className={`${classes.TextSize}`}>
                  ราคา :{" "}
                  <span
                    className={classes.BoldText}
                  >{`${getTotalPrice()} บาท`}</span>
                </Typography>
              </GridContainText>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          className={`${classes.Button} ${classes.Fixed}`}
          onClick={props.handleClose}
        >
          แก้ไข
        </Button>
        <Button
          className={`${classes.Button} ${classes.Confirm}`}
          onClick={props.OnClickSubmitBooking}
        >
          จองรถ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogShowDetailBooking;
