import React, { Fragment } from "react";
import { styled } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
/* import TrackPage from "../trackGPS"; */
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
  useJsApiLoader,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { GoogleAPIkey, locationSite1 } from "../../../../utils/googleMapAssets";
import GoogleMapServices from "../../../../services/google-map.service";

const placesLibrary = ["places"];

const DialogContentStyled = styled(DialogContent)({
  height: 450,
  padding: 0,
  "& .GPSIcon": {
    color: "red",
    fontSize: "30px",
    transform: "translate(-40%,-75%)",
  },
});

const MarkerCustom = () => {
  return (
    <Fragment>
      <i className="fas fa-map-marker-alt GPSIcon"></i>
    </Fragment>
  );
};

const DialogEditLocation = ({
  props,
  selectemployee,
  openLocation,
  handleClose,
  handleResponseLocation,
}) => {
  const [searchResult, setSearchResult] = React.useState("Result: none");
  const [locationLatLong, setLocationLatLong] = React.useState(locationSite1);
  const [mapInstance, setMapInstance] = React.useState(null);
  const scroll = "paper";
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleAPIkey,
    libraries: placesLibrary,
    language: "th",
    region: "TH",
  });

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: parseFloat(locationLatLong.lat),
    lng: parseFloat(locationLatLong.lng),
  };

  const onLoad = (autocomplete) => {
    console.log(autocomplete);
    console.log("onload");
    setSearchResult(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      console.log(searchResult);
      console.log(place);
      setLocationLatLong({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      //   setData(place.name);
    } else {
      alert("Please enter text");
    }
  };

  console.log(locationLatLong);
  //   const setData = (data) => {
  //     handleGetDataFromGoogleMap(data);
  //   };

  // const OnChangingAuto = async ({ value }) => {
  //   GoogleMapServices.getLatLngPlace(value.description).then((res) => {
  //     console.log(res);
  //     let latlng = res.data.candidates[0].geometry.location;
  //     setLocationLatLong(latlng);
  //   });
  // };

  const handleMapLoad = (map) => {
    setMapInstance(map);
  };
  const handleDragEnd = () => {
    if (mapInstance) {
      const newCenter = mapInstance.getCenter();
      setLocationLatLong({
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      });
    }
  };

  return (
    <Dialog
      style={{ overflow: "hidden !important" }}
      open={openLocation}
      onClose={handleClose}
      fullWidth
      scroll={scroll}
      maxWidth="xs"
      sx={{
        zIndex: 100,
      }}
    >
      {isLoaded && (
        <>
          <DialogTitle id="alert-dialog-title">{"ปักหมุดใหม่"}</DialogTitle>
          <DialogContentStyled dividers>
            {/* <GooglePlacesAutocomplete
          apiKey={GoogleAPIkey}
          apiOptions={{ language: "th", region: "th" }}
          selectProps={{
            onChange: OnChangingAuto,
          }}
        /> */}
            <div id="searchColumn">
              <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                <TextField
                  type="text"
                  placeholder="Search"
                  fullWidth
                  InputProps={{
                    style: {
                      width: "100%",
                      marginTop: "5px",
                      marginBottom: "5px",
                    },
                  }}
                />
              </Autocomplete>
            </div>

            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={15}
              options={{ fullscreenControlOptions: false }}
              //   onDragEnd={(map) =>
              //     setLocationLatLong({
              //       lat: map.getCenter().lat(),
              //       lng: map.getCenter().lng(),
              //     })
              //   }
              onLoad={handleMapLoad}
              onDragEnd={handleDragEnd}
            >
              <Marker
                position={{
                  lat: parseFloat(locationLatLong.lat),
                  lng: parseFloat(locationLatLong.lng),
                }}
              />
              {/* <MarkerCustom lat={locationLatLong.lat} lng={locationLatLong.lng} /> */}
            </GoogleMap>
          </DialogContentStyled>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              {" "}
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleResponseLocation(locationLatLong);
              }}
              color="primary"
              autoFocus
            >
              บันทึก
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DialogEditLocation;
