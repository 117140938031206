import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import dayjs from 'dayjs';

import { styled } from '@mui/styles';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import CardShowStartStopTime from "./cardShowStartStopTime";

require('dayjs/locale/th');
dayjs.locale('th');

const DivCoverContent = styled("div")(({theme})=>({
  width: "70%",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  backgroundColor: "rgba(25,25,112,0.7)",
  borderRadius: "20px",
  border: "5px solid white",
  paddingBottom:"80px",
  "& .ButtonStart": {
    width: "400px",
    height: "400px",
    fontSize: "90px",
    backgroundColor: "rgba(0,150,0,1)",
    boxShadow: "0 0 10px 10px rgba(0,200,0,1)",
    "&:hover":{
      backgroundColor: "rgba(0,100,0,1)",
    },
    [theme.breakpoints.down('sm')]:{
      width: "280px",
      height: "280px",
      fontSize: "70px",
    },
  },
  "& .ButtonStop": {
    width: "250px",
    height: "250px",
    fontSize: "60px",
    backgroundColor: "rgba(150,0,0,1)",
    boxShadow: "0 0 10px 10px rgba(200,0,0,1)",
    "&:hover":{
      backgroundColor: "rgba(100,0,0,1)",
    },
    [theme.breakpoints.down('sm')]:{
      width: "200px",
      height: "200px",
      fontSize: "70px",
    },
  },
  [theme.breakpoints.down('sm')]:{
    width:"calc(100% - 10px)",
  },
}));

const Topic = styled(Typography)({
  fontSize: "80px",
  color: "white",
});

const ButtonStyled = styled(Button)({
  color: "white",
  borderRadius: "50%",
  margin: "20px",
});

const CardStartStop = (props) => {
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  useEffect(() => {
    if(props.startTime){
      let date = dayjs(props.startTime).format('HH : mm');
      setStartTime(date);
    }
    if(props.endTime){
      let date = dayjs(props.endTime).format('HH : mm');
      setEndTime(date);
    }
  }, [props.startTime,props.endTime])

  const getHoursMinutes = (dateString) => {
    let date = new Date(dateString);
    let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return hours+" : "+minutes;
  }

  return (
    <DivCoverContent>
      <Topic>
        {props.TextHeader}
      </Topic>
      <ButtonStyled
        className={clsx({"ButtonStart":props.startTime === null, "ButtonStop":props.startTime})}
        disableFocusRipple
        onClick={ props.startTime ? props.onClickHandlerStop : props.onClickHandler}
      >
        {props.TextButton}
      </ButtonStyled>
      {props.startTime &&
        (<CardShowStartStopTime 
          startTime = {startTime}
          endTime = {endTime}
          duration = {props.duration}
          // clickStop = {props.clickStop}
        />)
      }
    </DivCoverContent>
  );
};

export default CardStartStop;

