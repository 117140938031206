import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/styles";
import Rating from "@mui/material/Rating";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";

import NavButtonCardGivingPoints from "./navButtonCardGivingPoints";
import TextFieldCustom from "./TextFieldCustom";

const DivCoverReturn = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "80%",
  backgroundColor: "#03256C",
  height: "fit-content",
  borderRadius: "20px",
  border: "5px solid white",
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 10px)",
  },
  "& .TextName": {
    fontSize: "30px",
  },
}));

const DivCoverContent = styled("div")({
  display: "flex",
  width: "80%",
  alignItems: "center",
  flexDirection: "column",
  paddingTop: "20px",
});

const Text = styled(Typography)({
  fontSize: "40px",
  color: "white",
});

const AvatarStyled = styled(Avatar)({
  width: "150px",
  height: "150px",
  marginBottom: "10px",
  border: "5px solid white",
});

const RatingStyled = styled(Rating)(({ theme }) => ({
  fontSize: "80px",
  height: "80px",
  marginBottom: "20px",
  "& .iconActive": {
    transform: "none",
  },
  "& .iconEmpty": {
    color: "#bebebe",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "60px",
  },
}));

const CardGivingPoints = (props) => {
  const { result: EmployeeProfile } = useSelector((state) => state.userProfile);
  const [rating, setRating] = useState(
    props.arrayObjectPassenger[props.currentOrder].point
  );
  const [comment, setComment] = useState(
    props.arrayObjectPassenger[props.currentOrder].comment
  );
  const [warningComment, setWarningComment] = useState(false);
  const [countPeopleRated, setCountPeopleRated] = useState(0);

  useEffect(() => {
    setRating(props.arrayObjectPassenger[props.currentOrder].point);
    setComment(
      props.arrayObjectPassenger[props.currentOrder].comment
        ? props.arrayObjectPassenger[props.currentOrder].comment
        : ""
    );
  }, [props.currentOrder]);

  useEffect(() => {
    props.arrayObjectPassenger[props.currentOrder].point = rating;
    props.arrayObjectPassenger[props.currentOrder].comment = comment;
    CountingEmployeeRated();
    ChackPoint(props.arrayObjectPassenger[props.currentOrder]);
  }, [rating, comment]);

  const CountingEmployeeRated = () => {
    let count = 0;
    props.arrayObjectPassenger.map((employee) => {
      if (employee.point !== 0) {
        let checkPoint = ChackPoint(employee);
        if (checkPoint) {
          count++;
        }
      }
    });
    setCountPeopleRated(count);
  };

  const ChackPoint = (employee) => {
    if (employee.point <= 2) {
      if (!employee.comment) {
        setWarningComment(true);
        return false;
      }
    }
    setWarningComment(false);
    return true;
  };

  const onClickNextHandler = () => {
    props.setArrayObjectPassenger([...props.arrayObjectPassenger]);
    props.onClickHandler(props.currentOrder + 1);
  };

  const onClickPreviousHandler = () => {
    props.setArrayObjectPassenger([...props.arrayObjectPassenger]);
    props.onClickHandler(props.currentOrder - 1);
  };

  return (
    <DivCoverReturn>
      <DivCoverContent>
        {EmployeeProfile && (
          <>
            <Text>{`ลำดับที่ ${props.currentOrder + 1}`}</Text>
            <AvatarStyled
              src={`${process.env.REACT_APP_URL}image/profile/${EmployeeProfile.imageName}`}
              alt={`${EmployeeProfile.firstname_TH}_${EmployeeProfile.lastname_TH}`}
            />
            <Text className="TextName">
              {`${EmployeeProfile.firstname_TH} ${EmployeeProfile.lastname_TH}`}
            </Text>
            <RatingStyled
              name="Points"
              value={rating}
              precision={0.5}
              emptyIcon={
                <StarIcon
                  style={{ opacity: 1, color: "gray" }}
                  fontSize="inherit"
                />
              }
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
            <TextFieldCustom
              comment={comment ? comment : ""}
              warningComment={warningComment}
              onSetComment={setComment}
            />
            <NavButtonCardGivingPoints
              onClickPreviousHandler={onClickPreviousHandler}
              onClickNextHandler={onClickNextHandler}
              currentOrder={props.currentOrder}
              maxOrder={props.maxOrder}
              countPeopleRated={countPeopleRated}
              OnClickSubmitRatingButton={props.OnClickSubmitRatingButton}
            />
          </>
        )}
      </DivCoverContent>
    </DivCoverReturn>
  );
};

export default CardGivingPoints;
