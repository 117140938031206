import React, { useState } from "react";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import {
  Grid,
  Card,
  Avatar,
  Typography,
  Button,
  IconButton,
} from "@mui/material";

import { SnackbarProvider, useSnackbar } from "notistack";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import ModalManageCar from "./modalManageCar";
import DeleteModal from "../../../../shared/deleteModal";
import CarInAreaCarBookingService from "../../../../../../services/inAreaCarBooking.service";

require("dayjs/locale/th");
dayjs.locale("th");

const GridItem = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  position: "relative",
});

const CardStyled = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "120px",
  boxShadow: theme.shadows[3],
  display: "flex",
  padding: "0 10px",
  borderRadius: "10px",
  cursor: "pointer",
  transition:
    "transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 0 10px 0 rgba(73, 73, 73, 0.8)",
  },
  [theme.breakpoints.down("lg")]: {
    height: "fit-content",
    flexDirection: "column",
    padding: "15px 0",
  },
}));

const PartOfContent = styled("div")(({ theme }) => ({
  width: "100%",
  height: "120px",
  display: "flex",
  // padding: "0 10px",
  [theme.breakpoints.down("lg")]: {
    height: "fit-content",
    flexDirection: "column",
    padding: "15px 0",
  },
}));

const PartOfAvatar = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexBasis: "10%",
  [theme.breakpoints.down("lg")]: {
    height: "fit-content",
  },
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "90px",
  height: "90px",
  [theme.breakpoints.down("lg")]: {
    width: "120px",
    height: "120px",
  },
}));

const PartOfName = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  flexBasis: "25%",
  marginLeft: "20px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0",
    display: "block",
  },
  "& .TextContentPartName": {
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
  },
}));

const Text = styled(Typography)({
  fontSize: "20px",
});

const HighlightText = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
});

const PartOfDate = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexBasis: "15%",
  "& .TextDateAndTime": {
    fontSize: "20px",
    [theme.breakpoints.down("lg")]: {
      margin: "-5px 0",
    },
  },
  "& .Date": {
    fontSize: "20px",
  },
  "& .Time": {
    fontSize: "15px",
  },
}));

const PartOfNumPassenger = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexBasis: "15%",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "row",
    margin: "-5px 0 -10px",
  },
  "& .TextNumPassenger": {
    fontSize: "40px",
    [theme.breakpoints.down("lg")]: {
      marginRight: "10px",
    },
  },
}));

const PartOfStatus = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexBasis: "15%",
  [theme.breakpoints.down("lg")]: {
    margin: "5px 0",
  },
  "& .TextSuccess": {
    color: "green",
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
    },
  },
  "& .TextWaiting": {
    color: "darkorange",
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
    },
  },
}));

const PartOfNavButton = styled("div")(({ theme }) => ({
  width: "150px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  // position: "absolute",
  right: "20px",
  top: "calc(50% + 15px)",
  // transform: "translateY(-50%)",
  zIndex: "2",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "60px",
    flexBasis: "auto",
  },
  "& .Icon": {
    fontSize: "35px",
    color: "white",
  },
}));

const ManageButton = styled(Button)(({ theme }) => ({
  width: "150px",
  fontSize: "20px",
  backgroundColor: theme.palette.primary.main,
  color: "white",
  ["&:hover"]: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const EditButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  ["&:hover"]: {
    backgroundColor: theme.palette.grey[700],
  },
}));

const DeleteButton = styled(IconButton)({
  backgroundColor: "red",
  ["&:hover"]: {
    backgroundColor: "darkred",
  },
});

const CardBooking = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const onCloseModalHandle = () => {
    setOpenModal(false);
  };

  const onClickOpenModal = () => {
    setOpenModal(true);
  };
  const onClickDelete = () => {
    CarInAreaCarBookingService.deleteCarInAreaCarBooking(props.booking).then(
      (res) => {
        if (res.status === 200) {
          props.handleActionInPage();
          setOpenDeleteModal(false);
          enqueueSnackbar("Delete Success", { variant: "success" });
        } else {
          enqueueSnackbar("Delete Failed", { variant: "error" });
        }
      }
    );
  };

  return (
    <GridItem item xs={12} sm={6} md={4} lg={12}>
      <CardStyled>
        <PartOfContent onClick={() => props.onClickCard(props.booking)}>
          <PartOfAvatar>
            <AvatarStyled
              src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
              alt="1"
            />
          </PartOfAvatar>
          <PartOfName>
            <HighlightText className="TextContentPartName">
              {props.booking.name}
            </HighlightText>
            <Text className="TextContentPartName">{props.booking.email}</Text>
            <Text className="TextContentPartName">
              {`Tel. ${props.booking.telephoneMobile}`}
            </Text>
          </PartOfName>
          <PartOfDate>
            <Text className="Date">
              {dayjs(props.booking.departureDate).format("D MMM YYYY")}
            </Text>
            <Text className="Time">
              {`${dayjs(props.booking.startTime).format("HH:mm")} น.- ${dayjs(
                props.booking.endTime
              ).format("HH:mm")} น.`}
            </Text>
          </PartOfDate>
          <PartOfNumPassenger>
            <HighlightText className="TextNumPassenger">
              {props.booking.numberOfPassenger}
            </HighlightText>
            <Text>คน</Text>
          </PartOfNumPassenger>
          <PartOfStatus>
            <Text>สถานะ</Text>
            {props.booking.statusManageCar === "Success" ? (
              <HighlightText className="TextSuccess">สำเร็จ</HighlightText>
            ) : (
              <HighlightText className="TextWaiting">รอการจัดรถ</HighlightText>
            )}
          </PartOfStatus>
        </PartOfContent>
        <PartOfNavButton>
          {props.booking.statusManageCar !== "Success" ? (
            <ManageButton onClick={onClickOpenModal}>จัดรถ</ManageButton>
          ) : (
            <>
              <EditButton onClick={onClickOpenModal} size="large">
                <EditIcon className="Icon" />
              </EditButton>
              <DeleteButton
                onClick={() => setOpenDeleteModal(true)}
                size="large"
              >
                <DeleteForeverIcon className="Icon" />
              </DeleteButton>
            </>
          )}
        </PartOfNavButton>
      </CardStyled>
      <ModalManageCar
        open={openModal}
        onCloseHandle={onCloseModalHandle}
        inforManageCar={props.booking}
        handleActionInPage={props.handleActionInPage}
      />
      <DeleteModal
        open={openDeleteModal}
        onCloseDeleteModalHandle={() => setOpenDeleteModal(false)}
        onClickDelete={() => onClickDelete()}
        label={"ลบรายการจองรถในพื้นที่"}
      />
    </GridItem>
  );
};

export default CardBooking;
