import {
  IN_AREA_CAR_BOOKINGS_FETCHING,
  IN_AREA_CAR_BOOKINGS_FAILED,
  IN_AREA_CAR_BOOKINGS_SUCCESS,
} from "./types";

import InAreaCarBookingService from "../services/inAreaCarBooking.service";

export const getInAreaCarBookingByIdUser = (idUser) => async (dispatch) => {
  try {
    dispatch({
      type: IN_AREA_CAR_BOOKINGS_FETCHING,
    });
    const res = await InAreaCarBookingService.getInAreaCarBookingByIdUser(
      idUser
    );
    if (res) {
      dispatch({
        type: IN_AREA_CAR_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: IN_AREA_CAR_BOOKINGS_FAILED,
    });
  }
};

export const getInAreaCarBookingByIdDriver = (idDriver) => async (dispatch) => {
  try {
    dispatch({
      type: IN_AREA_CAR_BOOKINGS_FETCHING,
    });
    const res = await InAreaCarBookingService.getInAreaCarBookingByIdDriver(
      idDriver
    );
    if (res) {
      dispatch({
        type: IN_AREA_CAR_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: IN_AREA_CAR_BOOKINGS_FAILED,
    });
  }
};

export const getInAreaCarBookingByFilterByIdDriver =
  (filter) => async (dispatch) => {
    try {
      dispatch({
        type: IN_AREA_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await InAreaCarBookingService.getInAreaCarBookingByFilterByIdDriver(
          filter
        );
      if (res) {
        dispatch({
          type: IN_AREA_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: IN_AREA_CAR_BOOKINGS_FAILED,
      });
    }
  };

export const getInAreaCarBookingByIdApprovedUserForManager =
  (idApprovedUser) => async (dispatch) => {
    try {
      dispatch({
        type: IN_AREA_CAR_BOOKINGS_FETCHING,
      });
      const res =
        await InAreaCarBookingService.getInAreaCarBookingByIdApprovedUserForManager(
          idApprovedUser
        );
      if (res) {
        dispatch({
          type: IN_AREA_CAR_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: IN_AREA_CAR_BOOKINGS_FAILED,
      });
    }
  };
export const postNewInAreaCarBooking = (newInArea) => async (dispatch) => {
  try {
    console.log("action");
    console.log(newInArea);
    dispatch({
      type: IN_AREA_CAR_BOOKINGS_FETCHING,
    });
    const res = await InAreaCarBookingService.postNewInAreaCarBooking(
      newInArea
    );
    if (res) {
      dispatch({
        type: IN_AREA_CAR_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: IN_AREA_CAR_BOOKINGS_FAILED,
    });
  }
};
export const getInAreaCarBookingByFilter = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: IN_AREA_CAR_BOOKINGS_FETCHING,
    });
    const res = await InAreaCarBookingService.getInAreaCarBookingByFilter(
      filter
    );
    if (res) {
      dispatch({
        type: IN_AREA_CAR_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: IN_AREA_CAR_BOOKINGS_FAILED,
    });
  }
};
