import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  styled,
  Grid,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Button,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import DateAdapter from "@mui/lab/AdapterDayjs";
import Item from "./components/Item";
import Container from "@mui/material/Container";
import UnicornLoading from "../../assets/loading.gif";
import maintenanceService from "../../../../services/maintenance.service";

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const History = (props) => {
  const { register, getValues, control } = useForm();
  const [requestedMaintenances, setRequestedMaintenances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    maintenanceService.getAllMaintenanceRequest().then((res) => {
      setRequestedMaintenances(res.data.data);
      setLoading(false);
      setError(res.data.error);
    });
  }, []);

  const onClickSearch = () => {
    setLoading(true);
    setRequestedMaintenances([]);
    maintenanceService
      .getAllMaintenanceRequest({
        maintenanceType: getValues("maintenanceType") || null,
        status: getValues("status") || null,
        date: getValues("date") || null,
      })
      .then((res) => {
        console.log(res.data.data);
        setRequestedMaintenances(res.data.data);
        setLoading(false);
        setError(res.data.error);
      });
  };

  const onChangeStatus = (index, status, technicianId) => {
    const newRequestedMaintenances = [...requestedMaintenances];
    const changeStatus = {
      maintenanceId: requestedMaintenances[index].idMaintenances,
      status: status,
      technicianId: technicianId == null ? [] : technicianId,
    };
    console.log(changeStatus);
    maintenanceService.putChangeStatus(changeStatus).then((res) => {
      newRequestedMaintenances[index].status = +res.data.data.status;
      setRequestedMaintenances(newRequestedMaintenances);
    });
  };

  const onClickLinkToDetail = (index) => {
    props.history.push(`/maintenances/detail/${index}`);
  };

  return (
    <Container maxWidth="xl">
      <div className="page">
        <Root>
          <Typography fontSize={34} marginBottom={1} marginTop={3}>
            รายการแจ้งซ่อมทั้งหมด
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              container
              xs={12}
              spacing={2}
              sx={(theme) => ({
                display: "flex",
                justifyContent: "center",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                padding: "0 30px 25px 0",
                marginLeft: "5px",
                marginTop: "10px",
                borderRadius: "15px",
              })}
            >
              <Grid item xs={12} sm={4}>
                <TextField
                  inputProps={{ ...register("maintenanceType") }}
                  label="ประเภทงานซ่อม"
                  select
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "5px",
                    },
                  }}
                  defaultValue={0}
                >
                  <MenuItem value={0}>ทั้งหมด</MenuItem>
                  <MenuItem value={1}>ซ่อมแซมทั่วไป</MenuItem>
                  <MenuItem value={2}>งานไฟฟ้า</MenuItem>
                  <MenuItem value={3}>งานประปา</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                  <Controller
                    name="date"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <MobileDatePicker
                        {...field}
                        label="วันที่"
                        inputFormat="DD MMMM YYYY"
                        // clearable
                        componentsProps={{
                          actionBar: {
                            actions: ["clear", "cancel", "accept"],
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputProps={{
                              style: {
                                borderRadius: "5px",
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  inputProps={{ ...register("status") }}
                  label="สถานะ"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "5px",
                    },
                  }}
                  select
                  defaultValue={0}
                >
                  <MenuItem value={0}>ทั้งหมด</MenuItem>
                  <MenuItem value={1}>กำลังรออนุมัติ</MenuItem>
                  <MenuItem value={2}>กำลังดำเนินการ</MenuItem>
                  <MenuItem value={3}>ดำเนินการเสร็จสิ้น</MenuItem>
                  <MenuItem value={4}>ไม่อนุมัติ</MenuItem>
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={onClickSearch}
                  style={{
                    maxWidth: "100px",
                    maxHeight: "40px",
                    // margin: "10px",
                    borderRadius: "15px",
                  }}
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>

            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Paper
                  sx={{
                    overflow: "hidden",
                    border: "1px solid #EEEEEE",
                    backgroundColor: "#FFFFFF",
                    marginBottom: "20px",
                    padding: "25px 30px 25px 30px",
                    borderRadius: "15px",
                    textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                  }}
                >
                  <img
                    src={UnicornLoading}
                    alt="Loading..."
                    style={{ width: "300px" }}
                  />
                  {/* ปรับขนาดของภาพตามที่ต้องการ */}
                </Paper>
              </div>
            )}
            {!loading && requestedMaintenances.length === 0 && (
              <Grid item xs={12}>
                <Typography fontSize={20} textAlign="center">
                  ไม่พบประวัติการแจ้งซ่อม
                </Typography>
              </Grid>
            )}
            {requestedMaintenances.map((data, index) => (
              <Grid
                key={`maintenance_${index}`}
                item
                xs={12}
                md={6}
                lg={12}
                style={{ paddingRight: "0 !important", marginTop: "5px" }}
              >
                <Item
                  data={data}
                  onChangeStatus={onChangeStatus}
                  index={index}
                  onClickLinkToDetail={onClickLinkToDetail}
                />
              </Grid>
            ))}
          </Grid>
        </Root>
      </div>
    </Container>
  );
};

export default History;
