import React, { Fragment, useState } from "react";

import { styled } from "@mui/styles";
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import ScheduleIcon from "@mui/icons-material/Schedule";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import dayjs from "dayjs";

const DivTitle = styled("div")({
  display: "flex",
  marginTop: "30px",
});

const Title = styled(Typography)({
  fontSize: "40px",
});

const IconAndText = styled("div")({
  display: "flex",
  width: "80%",
  marginTop: "15px",
});

const SubText = styled(Typography)({
  fontSize: "25px",
});

const Time = styled(Typography)({
  fontSize: "120px",
  color: "#04009A",
  margin: "-10px 0",
});

const GridContainer = styled(Grid)({
  width: "100%",
  marginLeft: "10px",
});

const Text = styled(Typography)({
  fontSize: "30px",
});

const CarIcon = styled(DriveEtaIcon)({
  fontSize: "40px",
  marginTop: "12px",
  marginRight: "5px",
});

const ClockIcon = styled(ScheduleIcon)({
  fontSize: "30px",
  marginTop: "5px",
  marginRight: "5px",
});

const MapButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.info.dark,
  color: "white",
  fontSize: "20px",
  marginTop: "20px",
  padding: "7px 20px",
  fontWeight: "bold",
  boxShadow: theme.shadows[3],
  "&:hover": {
    backgroundColor: theme.palette.info.dark,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.dark,
  color: "white",
  fontSize: "20px",
  marginTop: "20px",
  padding: "7px 20px",
  fontWeight: "bold",
  boxShadow: theme.shadows[3],
  "&:hover": {
    backgroundColor: theme.palette.error.dark,
  },
}));

const NavDiv = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "bold",
  color: "#FFAA33",
}));

const TextInDialog = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
  fontSize: "18px",
  backgroundColor: theme.palette.error.main,
  color: "white",
  "&:hover": {
    backgroundColor: theme.palette.error.main,
  },
}));

const DetailOfCalling = (props) => {
  const [openDialog, setOpenDialog] = useState(false);

  const onClickCancelButton = () => {
    props.onClickCancelCalling();
    setOpenDialog(false);
  };

  return (
    <Fragment>
      <DivTitle>
        <CarIcon />
        <Title>รถกำลังมารับแล้ว</Title>
      </DivTitle>
      <IconAndText>
        <ClockIcon />
        <SubText>เวลาที่คาดว่าจะถึง</SubText>
      </IconAndText>
      <Time className="TimeCalling">
        {dayjs(props.newCall.data.eta).format("HH:mm")}
      </Time>
      <GridContainer container spacing={1}>
        <Grid container item xs={12} justifyContent="center" spacing={3}>
          <Grid item xs={6} sm={4}>
            <Text>ประเภทรถ</Text>
          </Grid>
          <Grid item xs={1}>
            <Text>:</Text>
          </Grid>
          <Grid item xs={5} sm={4} lg={4}>
            <Text>{props.newCall.data.driver.vehicleTypeNameTH}</Text>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center" spacing={3}>
          <Grid item xs={6} sm={4}>
            <Text>ทะเบียน</Text>
          </Grid>
          <Grid item xs={1}>
            <Text>:</Text>
          </Grid>
          <Grid item xs={5} sm={4} lg={4}>
            <Text>{props.newCall.data.driver.Plate_No}</Text>
          </Grid>
        </Grid>
      </GridContainer>
      <NavDiv>
        <MapButton onClick={props.OnClickMapButton}>ดู Map</MapButton>
        <CancelButton onClick={() => setOpenDialog(true)}>ยกเลิก</CancelButton>
      </NavDiv>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-cancel-calling"
      >
        <DialogTitleStyled>ยืนยันการยกเลิกการเรียก</DialogTitleStyled>
        <DialogContent>
          <TextInDialog>ต้องการยกเลิกการเรียกรถรับส่งระหว่าง Site</TextInDialog>
        </DialogContent>
        <DialogActions>
          <ConfirmButton onClick={onClickCancelButton}>ยืนยัน</ConfirmButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DetailOfCalling;
