import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { styled } from "@mui/styles";
import {
  Modal,
  Paper,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Typography,
  Grid,
} from "@mui/material";

const EditButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "0px",
  top: "0px",
  fontSize: "16px",
  color: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const ModalStyled = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const PaperStyled = styled(Paper)({
  width: "600px",
  height: "fit-content",
  padding: "30px 30px 20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
});

const GridText = styled(Grid)({
    display:"grid",
    alignItems:"center",
});

const ConfirmButton = styled(Button)(({ theme }) => ({
  marginTop: "20px",
  padding: "7px 10px",
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
  fontSize: "16px",
  "&:hover": {
    backgroundColor: theme.palette.success.dark,
  },
}));

const ModalPrice = (props) => {
  const { register, handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: {
      price: props.price ? props.price : "",
      deposit: props.deposit ? props.deposit : "",
      price3Dplus: props.price3Dplus ? props.price3Dplus : "",
      price1Mplus: props.price1Mplus ? props.price1Mplus : "",
      earlybird: props.earlybird ? props.earlybird : "",
      earlybirdDay: props.earlybirdDay ? props.earlybirdDay : "",
      durationcancellation: props.durationcancellation ? props.durationcancellation : "",
      distanceInclude: props.distanceInclude ? props.distanceInclude : "",
      priceClean: props.priceClean ? props.priceClean : "",
    },
  });
  const [openModal, setOpenModal] = useState(false);

  const OnCloseFunction = () => {
    setOpenModal(false);
  };

  const OnClickConfirmButton = () => {
    setOpenModal(false);
  };

  return (
    <>
      {props.edit && 
        <EditButton onClick={() => setOpenModal(true)} disableRipple>
          <i className="fas fa-pen"></i>
        </EditButton>
      }
      <ModalStyled open={openModal} onClose={OnCloseFunction}>
        <PaperStyled>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                id="price"
                label="Price/Day"
                variant="outlined"
                fullWidth
                inputProps={{ ...register("price") }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="deposit"
                label="มัดจำ/Trip"
                variant="outlined"
                fullWidth
                inputProps={{ ...register("deposit") }}
              />
            </Grid>
            <GridText item xs={6}>
              <Typography>3 Days Or More Discount</Typography>
            </GridText>
            <Grid item xs={6}>
              <TextField
                id="price3Dplus"
                variant="outlined"
                fullWidth
                inputProps={{ ...register("price3Dplus") }}
              />
            </Grid>
            <GridText item xs={6}>
              <Typography>1 Months Or Move Discount</Typography>
            </GridText>
            <Grid item xs={6}>
              <TextField
                id="price1Mplus"
                variant="outlined"
                fullWidth
                inputProps={{ ...register("price1Mplus") }}
              />
            </Grid>
            <GridText item xs={6}>
              <Typography>Early Bird Discount</Typography>
            </GridText>
            <Grid item xs={3}>
              <TextField
                id="earlybird"
                variant="outlined"
                fullWidth
                inputProps={{ ...register("earlybird") }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="Before">Before</InputLabel>
                <Controller
                  control={control}
                  name="earlybirdDay"
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="Before-label"
                      id="Before"
                      label="Before"
                    >
                      <MenuItem value="1">1 Day</MenuItem>
                      <MenuItem value="2">2 Day</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <GridText item xs={6}>
              <Typography>Cancel Free Before</Typography>
            </GridText>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Controller
                  control={control}
                  name="durationcancellation"
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="durationcancellation-label"
                      id="durationcancellation"
                    >
                      <MenuItem value="1">1 Day</MenuItem>
                      <MenuItem value="2">2 Day</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="distanceInclude"
                variant="outlined"
                label="Distance Include"
                fullWidth
                inputProps={{ ...register("distanceInclude") }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="priceClean"
                variant="outlined"
                label="ราคาทำความสะอาด"
                fullWidth
                inputProps={{ ...register("priceClean") }}
              />
            </Grid>
          </Grid>
          <ConfirmButton onClick={OnClickConfirmButton}>Confirm</ConfirmButton>
        </PaperStyled>
      </ModalStyled>
    </>
  );
};

export default ModalPrice;
