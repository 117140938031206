import React, { useState } from 'react';

import { styled } from '@mui/styles';
import {
    Typography,
} from '@mui/material';

import CardEdit from "../../../../../shared/cardEdit";
import ModalExtra from "./modalExtra";
import utils from "../../../../../../../utils";
import { listExtras } from "../../../choise";

const Topic = styled(Typography)({
    fontSize:"22px",
    fontWeight:"bold",
    margin:"10px 0px",
});

const IconAndText = styled("div")(({theme})=>({
    display:"flex",
    alignItems:"center",
    "& .IconDot":{
        fontSize:"10px",
        marginRight:"5px",
    },
}));

const PartOfExtra = ({ listExExtras, setListExExtras, edit }) => {
    const [openModal, setOpenModal] = useState(false);

    const onClickDeleteExtraButton = (extratarget) => {
        setListExExtras([...listExExtras.filter((extra) => extra.extra !== extratarget )]);
    };

    const onClickAddButton = (extraAdd) => {
        setListExExtras([...listExExtras,extraAdd]);
    };

    const OnClickOpenModal = () => setOpenModal(true);

    const OnClickCloseModal = () => setOpenModal(false);

    return(
        <CardEdit openModal={OnClickOpenModal} edit={edit}>
            <Topic>Extras</Topic>
            {listExExtras.map((extra)=>(
                <IconAndText key={extra.extra} style={{marginBottom:"5px"}}>
                    <i className="fas fa-circle IconDot"></i>
                    <Typography>{`${listExtras.find( extralist => extralist.id === extra.extra).name} ${utils.numberWithCommas(extra.price)}/${extra.duration}`}</Typography>
                </IconAndText>
            ))}
            {openModal && 
            <ModalExtra 
                openModal={openModal}
                listExExtras={listExExtras}
                onClickAddButton={onClickAddButton}
                onClickDeleteExtraButton={onClickDeleteExtraButton}
                OnClickCloseModal={OnClickCloseModal}
            />}
        </CardEdit>
    );
};

export default PartOfExtra;