import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import clsx from "clsx";
import GoogleMap from "google-map-react";

import makeStyles from '@mui/styles/makeStyles';
import {
    Typography, Button, TextField, Grid, Card, CardContent, Divider, Chip, Backdrop,
    CircularProgress, Snackbar, FormControl, InputLabel, Select, MenuItem, Alert,Avatar,
    Autocomplete, Box
} from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from "@mui/icons-material/Save";
import RoomIcon from "@mui/icons-material/Room";

import { GoogleAPIkey, locationSite1 } from "../../../../../utils/googleMapAssets";
import DialogEditLocation from "../../../shared/dialog/DialogEditLocation";
import { getAllUsers } from "../../../../../actions/user";

// const useStyles = makeStyles((theme)=>({
//     DivCoverReturn:{
//         margin:"20px 50px",
//         height:"calc(100% - 20px)",
//         position:"relative",
//     },
//     BackButton:{
//         position:"absolute",
//         top:"15px",
//         left:"125px",
//         ["&:hover"]:{
//             backgroundColor:"white",
//         },
//         ["&:active"]:{
//             backgroundColor:"white",
//         },
//     },
//     Title:{
//         fontSize:"40px",
//         textAlign:"center",
//     },
//     form:{
//         width:"100%",
//         display:"flex",
//         alignItems:"center",
//         marginTop:"30px",
//         flexDirection:"column",
//     },
//     GridContainer:{
//         width:"80%",
//     },
//     GridItem:{
//         marginBottom:"20px",
//     },
//     NavButton:{
//         width:"80%",
//         display:"flex",
//         justifyContent:"flex-end",
//         marginTop:"40px",
//     },
//     confirmButton:{
//         width:"120px",
//         height:"60px",
//         backgroundColor:"green",
//         color:"white",
//         fontSize:"20px",
//         borderRadius:"10px",
//         ["&:hover"]:{
//             backgroundColor:"darkgreen",
//         }
//     },
//     TextDarkBlue:{
//         color:theme.palette.primary.dark,
//     },
//     TextField:{
//         ["& .MuiInputBase-fullWidth"]:{
//             color:theme.palette.primary.dark,
//         },
//     },
// }));
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 50,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height:"fit-content",
        background: "#eee",
        minWidth: 400,
    },
    Title:{
        fontSize:"36px",
        marginBottom:"10px"
    },
    trackView: {
        marginTop: 10,
        textalign: "center",
        width: "100%"
    },
    addressProfile: {
        marginTop: 10,
        textalign: "left",
        padding: 0
    },
    mapProfile: {
        height: "40vh",
        width: "100%",
        marginTop: 10,
        "& .GPSIcon":{
            color:"red",
            fontSize:"30px",
            transform:"translate(-40%,-75%)",
        },
    },
    infoProfile: {
        marginBottom: 25
    },
    infoTxtProfile: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    imageProfile: {
        borderRadius: "50%",
        height:"150px",
        width:"150px",
        marginBottom:"20px"
    },
    tagShift: {
        marginTop: 5
    },
    rowField: {
        display: "flex",
        marginBottom: 10
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    ImgExampleMap:{
        height:"100%",
        width:"100%",
    },
    BackButton:{
        position:"absolute",
        left:"20px",
        top:"10px",
        fontSize:"20px",
    },
    partOfTargetLocation:{
        width:"100%",
    },
}));

const FormAddUser = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { result: AllUsers }  = useSelector(state => state.users);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [locationLatLong, setLocationLatLong] = useState({Lat: locationSite1.lat, Long: locationSite1.lng});
    const [previewImage, setPreviewImage] = useState(null);
    const [openPinMap, setOpenPinMap] = useState(false);
    const { register, handleSubmit, control, watch, getValues, setValue } = useForm();

    useEffect(() => {
        dispatch(getAllUsers());
    }, [])

    const onClickBackButton = () => {
        props.history.goBack();
    };

    const onSubmitForm = (data) => {
        console.log(data)
    };

    const handleOpenPinMap = () => {

        setOpenPinMap(true);
    };

    const handleClosePinMap = () => {
        setOpenPinMap(false);
    };

    const handleResponseLocation = locationLatLong => {
        // if (formRef.current) {
        //     formRef.current.values.lat = locationLatLong.Lat
        //     formRef.current.values.long = locationLatLong.Long
        // }
        setLocationLatLong({ Lat: locationLatLong.Lat, Long: locationLatLong.Long });
        setOpenPinMap(false);
    };

    const MarkerCustom = () => {
        return (
            <Fragment>
                <i className="fas fa-map-marker-alt GPSIcon"></i>
            </Fragment>
        );
    };

    return(
        <div className={classes.root}>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => { setShowSuccess(false) }}>
                <Alert onClose={() => { setShowSuccess(false) }} severity="success">
                    บันทึกข้อมูลเรียบร้อย
            </Alert>
            </Snackbar>
            <Snackbar open={showError} autoHideDuration={3000} onClose={() => { setShowError(false) }}>
                <Alert onClose={() => { setShowError(false) }} severity="error">
                    เกิดข้อผิดพลาด ลองอีกครั้ง
            </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={showLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
                <div style={{ margin: "20px" }}>
                    <div className={classes.trackView}>
                        <div className={classes.infoProfile}>
                            <Card>
                                <CardContent style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", position: "relative" }}>
                                <Button 
                                    className={`${classes.BackButton}`}
                                    onClick={()=>props.history.goBack()}
                                >
                                <ArrowBackIosIcon /> Back</Button>
                                <Typography className={classes.Title}>Add Employee</Typography>
                                    <div>
                                        <Avatar 
                                            className={classes.imageProfile} 
                                            src={previewImage? `${process.env.REACT_APP_URL}image/profile/${previewImage}` : previewImage }
                                        />
                                    </div>
                                    {AllUsers && 
                                        <div style={{width:"80%"}}>
                                            <Controller
                                                render={(propsInController)=>(
                                                    <Autocomplete
                                                        fullWidth
                                                        options={AllUsers}
                                                        getOptionLabel={(option) => option.fNameThai}
                                                        isOptionEqualToValue={(option,value) => option === value }
                                                        renderOption={(props, option) => (
                                                            <Box component="li" {...props}>
                                                                {option.fNameThai}
                                                            </Box>
                                                        )}
                                                        value={propsInController.field.value}
                                                        onChange={(e,value)=>{
                                                            if(value){
                                                                setValue("department",value.department);
                                                                console.log("value : ",value);
                                                                setPreviewImage(value.image);
                                                                propsInController.field.onChange(value);
                                                            }
                                                            else{
                                                                setValue("department","");
                                                                setPreviewImage(null);
                                                                propsInController.field.onChange(null);
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                placeholder="ระบุชื่อ-นามสกุล"
                                                                label="ชื่อ-นามสกุล"
                                                            />
                                                        )}
                                                    />
                                                )}
                                                name="fNameThai"
                                                control={control}
                                                defaultValue={null}
                                            />
                                            <TextField
                                                fullWidth
                                                required
                                                id="fNameThai"
                                                label="แผนก"
                                                style={{marginTop:"20px"}}
                                                inputProps={{ ...register("department") }}
                                                InputLabelProps={{ shrink: watch("department")? true : false }}
                                            />
                                        </div>
                                    }
                                    {/* <Grid container spacing={3}>
                                        <Grid item md={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="preName">คำนำหน้า</InputLabel>
                                            <Controller
                                                render={(propsInController) => (
                                                    <Select
                                                        label="คำนำหน้า"
                                                        value={propsInController.field.value}
                                                        onChange={propsInController.field.onChange}
                                                    >
                                                        <MenuItem value="นาย">นาย</MenuItem>
                                                        <MenuItem value="นางสาว">นางสาว</MenuItem>
                                                        <MenuItem value="นาง">นาง</MenuItem>
                                                    </Select>
                                                )}
                                                name="preName"
                                                control={control}
                                                defaultValue=""
                                            />
                                        </FormControl>
                                        </Grid>
                                        <Grid item md={9}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="fNameThai"
                                                label="ชื่อ-นามสกุล"
                                                placeholder="ระบุชื่อ-นามสกุล"
                                                inputProps={{ ...register("fNameThai") }}
                                            />
                                        </Grid>
                                        <Grid item md={12}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="department">แผนก</InputLabel>
                                                <Controller
                                                    render={(propsInController) => (
                                                        <Select
                                                            label="แผนก"
                                                            value={propsInController.field.value}
                                                            onChange={propsInController.field.onChange}
                                                        >
                                                            <MenuItem value="นาย">นาย</MenuItem>
                                                            <MenuItem value="นางสาว">นางสาว</MenuItem>
                                                            <MenuItem value="นาง">นาง</MenuItem>
                                                        </Select>
                                                    )}
                                                    name="department"
                                                    control={control}
                                                    defaultValue=""
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}
                                    {/* <Typography variant="h4" style={{ fontSize: 30 }}>
                                        {props.location.state && props.location.state.employee.fNameThai}
                                    </Typography>
                                    <Typography color="textSecondary" variant="h6">{props.location.state && props.location.state.employee.departmentName}</Typography> */}
                                </CardContent>
                            </Card>
                        </div>
                        <div className={classes.infoProfile}>
                            <Card style={{ width: "100%" }}>
                                <CardContent style={{ padding: "25px" }}>
                                    <Typography className={classes.title}>
                                         ที่อยู่
                                    </Typography>
                                    <Divider style={{ margin: "10px 0" }}></Divider>
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={3} className={classes.rowField}>
                                            <Grid item xs={12} sm={2}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="addressNumber"
                                                    label="บ้านเลขที่"
                                                    inputProps={{ ...register("addressNumber") }}
                                                    // onChange={handleChange}
                                                    // value={values.addressNumber}
                                                    // onBlur={handleBlur}
                                                    // helperText={touched.addressNumber ? errors.addressNumber : ""}
                                                    // error={touched.addressNumber && Boolean(errors.addressNumber)}
                                                ></TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={10}>
                                            <TextField
                                                    fullWidth
                                                    required
                                                    id="villageSoi"
                                                    label="หมู่ที่/หมู่บ้าน/ซอย"
                                                    inputProps={{ ...register("villageSoi") }}
                                                    // onChange={handleChange}
                                                    // value={values.villageSoi}
                                                    // onBlur={handleBlur}
                                                    // helperText={touched.villageSoi ? errors.villageSoi : ""}
                                                    // error={touched.villageSoi && Boolean(errors.villageSoi)}
                                                ></TextField>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} className={classes.rowField}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    style={{ marginRight: 5 }}
                                                    id="streetRoad"
                                                    label="ถนน"
                                                    inputProps={{ ...register("streetRoad") }}
                                                    // onChange={handleChange}
                                                    // value={values.streetRoad}
                                                    fullWidth
                                                ></TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="subdistrictTambol"
                                                    label="ตำบล"
                                                    inputProps={{ ...register("subdistrictTambol") }}
                                                    // onChange={handleChange}
                                                    // value={values.subdistrictTambol}
                                                    // onBlur={handleBlur}
                                                    // helperText={
                                                    //     touched.subdistrictTambol ? errors.subdistrictTambol : ""
                                                    // }
                                                    // error={
                                                    //     touched.subdistrictTambol && Boolean(errors.subdistrictTambol)
                                                    // }
                                                ></TextField>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} className={classes.rowField}>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    required
                                                    id="district"
                                                    label="อำเภอ"
                                                    inputProps={{ ...register("district") }}
                                                    // onChange={handleChange}
                                                    // value={values.district}
                                                    // onBlur={handleBlur}
                                                    // helperText={touched.district ? errors.district : ""}
                                                    // error={touched.district && Boolean(errors.district)}
                                                    fullWidth
                                                ></TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    required
                                                    id="province"
                                                    label="จังหวัด"
                                                    inputProps={{ ...register("province") }}
                                                    // onChange={handleChange}
                                                    // value={values.province}
                                                    // onBlur={handleBlur}
                                                    // helperText={touched.province ? errors.province : ""}
                                                    // error={touched.province && Boolean(errors.province)}
                                                    fullWidth
                                                ></TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    required
                                                    id="postalCode"
                                                    label="รหัสไปรษณีย์"
                                                    inputProps={{ ...register("postalCode") }}
                                                    // onChange={handleChange}
                                                    // value={values.postalCode}
                                                    // onBlur={handleBlur}
                                                    // helperText={touched.postalCode ? errors.postalCode : ""}
                                                    // error={touched.postalCode && Boolean(errors.postalCode)}
                                                    fullWidth
                                                ></TextField>
                                            </Grid>
                                        </Grid>
                                        <div style={{ marginTop: "30px" }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between"
                                                }}
                                            >
                                                <Typography className={classes.title}>
                                                    ปักหมุดที่อยู่
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    onClick={handleOpenPinMap}
                                                    endIcon={<RoomIcon>pin</RoomIcon>}
                                                >
                                                    ปักหมุด
                                                </Button>
                                            </div>
                                            <Divider style={{ margin: "5px 0" }}></Divider>
                                            <Grid container spacing={3} className={classes.rowField}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        style={{ 
                                                            marginRight: 5,
                                                            ["&:hover"]:{
                                                                backgroundColor:"red",
                                                            }
                                                        }}
                                                        id="lat"
                                                        label="ตำแหน่ง Lat"
                                                        // value={values.lat}
                                                        inputProps={{ ...register("lat"), readOnly: true }}
                                                        fullWidth
                                                        // InputProps={{
                                                        //     readOnly: true
                                                        // }}
                                                    ></TextField>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField

                                                        style={{ marginRight: 5 }}
                                                        id="long"
                                                        label="ตำแหน่ง Long"
                                                        // value={values.long}
                                                        inputProps={{ ...register("long"), readOnly: true }}
                                                        fullWidth
                                                    ></TextField>
                                                </Grid>
                                            </Grid>
                                            
                                            <div className={classes.mapProfile}>
                                                <GoogleMap
                                                    defaultZoom={15}
                                                    defaultCenter={{lat : locationSite1.lat,lng : locationSite1.lng}}
                                                    center={{lat : locationLatLong.Lat, lng : locationLatLong.Long }}
                                                    bootstrapURLKeys={{ key: GoogleAPIkey }}
                                                >
                                                    <MarkerCustom lat={locationLatLong.Lat} lng={locationLatLong.Long} />
                                                </GoogleMap>
                                                {/* <GoogleMap
                                                    defaultZoom={11}
                                                    defaultCenter={{ lat: 12.7051742, lng: 101.1922503 }}
                                                    bootstrapURLKeys={{ key: googleApiKey }}
                                                >
                                                    <MarkerCustom lat={values.lat} lng={values.long} />
                                                </GoogleMap> */}
                                            </div>
                                            <Typography className={classes.title} style={{marginTop:"20px"}}>
                                                สถานที่ปลายทาง
                                            </Typography>
                                            <Divider style={{ margin: "10px 0" }}></Divider>
                                            <div className={`${classes.partOfTargetLocation}`}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="targetLocation">ปลายทาง</InputLabel>
                                                    <Controller
                                                        render={(propsInController) => (
                                                            <Select
                                                                label="ปลายทาง"
                                                                value={propsInController.field.value}
                                                                onChange={propsInController.field.onChange}
                                                            >
                                                                <MenuItem value={"TPE Site 1"}>TPE Site 1</MenuItem>
                                                                <MenuItem value={"TPE Site 2"}>TPE Site 2</MenuItem>
                                                                <MenuItem value={"TPE Site 3"}>TPE Site 3</MenuItem>
                                                                <MenuItem value={"TPE Site 7"}>TPE Site 7</MenuItem>
                                                            </Select>
                                                        )}
                                                        name="targetLocation"
                                                        control={control}
                                                        defaultValue=""
                                                    />
                                                </FormControl>
                                            </div>

                                            {/* Dialog Edit Pin Map */}
                                            {openPinMap && (
                                                <DialogEditLocation
                                                    handleClose={handleClosePinMap}
                                                    selectemployee={{ lat: locationLatLong.Lat, long: locationLatLong.Long }}
                                                    handleResponseLocation={handleResponseLocation}
                                                    openLocation={openPinMap}
                                                />
                                            )}
                                        </div>
                                    </form>
                                </CardContent>
                            </Card>
                        </div>
                        <div>
                            <Card style={{ width: "100%" }}>
                                <CardContent style={{ padding: 16 }}>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: "auto" }}
                                            className={classes.button}
                                            startIcon={<SaveIcon />}
                                            onClick={handleSubmit}
                                        >
                                            บันทึกข้อมูล
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>

                        </div>
                    </div>
                </div>
        </div>
    );
};

export default FormAddUser;