import React from 'react';

import { styled } from "@mui/styles";
import {
    Typography, Button, Grid, Divider,
} from "@mui/material";

import CircleIcon from '@mui/icons-material/Circle';

import { listOptions, listPackages, listCondition, } from "../../choise";
import utils from "../../../../../../utils";

const Content = styled("div")(({theme})=>({
    width:"97%",
}));

const Title = styled(Typography)(({theme})=>({
    textAlign:"center",
    marginTop:"10px",
    fontSize:"32px",
}));

const GridStyled = styled(Grid)(({theme})=>({

}));

const Topic = styled(Typography)(({theme})=>({
    fontSize:"20px",
    fontWeight:"bold",
    marginTop:'10px',
    marginLeft:"10px",
}));

const SubTopic = styled(Typography)(({theme})=>({
    fontSize:"20px",
    "& .TextAnswer":{
        color: theme.palette.primary.main,
    },
}));

const TextDetail = styled(Typography)(({theme})=>({
    fontSize:"20px",
    textIndent:"50px",
    "& .TextAnswer":{
        color: theme.palette.primary.main,
    },
}));

const ContentImage = styled("div")(({theme})=>({
    width:"100%",
    display:"flex",
    overflowX:"auto",
    "& .Content":{
        width:"300px",
        height:"250px",
        minWidth:"300px",
        marginRight:"10px",
    },
    "& .Image":{
        width:"100%",
        height:"100%",
        objectFit:"contain",
    },
}));

const List = styled("div")(({theme})=>({
    display:"flex",
    alignItems:"center",
    "& .dot":{
        fontSize:"10px",
        marginRight:"10px",
    },
    "& .Text":{
        fontSize:"18px",
    },
}));

const BackButton = styled(Button)({
    width:"fit-content",
    color:"green",
    fontSize:"18px",
    "&:hover":{
        backgroundColor:"white",
    },
});

const SubmitButton = styled(Button)(({theme})=>({
    marginLeft:"10px",
    backgroundColor:theme.palette.success.main,
    color:theme.palette.success.contrastText,
    fontSize:"18px",
    "&:hover":{
        backgroundColor:theme.palette.success.dark,
    },
}));

const SummarizePage = props => {

    const previewImage = () => {
        let previewImageSrc = [];
        if(props.imageFiles.length > 0){
            props.imageFiles.map((image)=> previewImageSrc.push(URL.createObjectURL(image)))
        }
        return previewImageSrc;
    };

    return(
        <Content>
            <Title>ตรวจสอบข้อมูล</Title>
            <GridStyled container spacing={1}>
                <Grid item xs={12}>
                    <Topic>ข้อมูลรถ</Topic>
                    <Divider className="Divider" />
                </Grid>
                <Grid item xs={4}>
                    <SubTopic>ยี่ห้อ : <span className="TextAnswer">{props.getValues("brand")}</span></SubTopic>
                </Grid>
                <Grid item xs={4}>
                    <SubTopic>Model : <span className="TextAnswer">{props.getValues("model")}</span></SubTopic>
                </Grid>
                <Grid item xs={4}>
                    <SubTopic>Year : <span className="TextAnswer">{props.getValues("year")}</span></SubTopic>
                </Grid>
                <Grid item xs={4}>
                    <SubTopic>เชื้อเพลิง : <span className="TextAnswer">{props.getValues("gas")}</span></SubTopic>
                </Grid>
                <Grid item xs={4}>
                    <SubTopic>เลขถัง : <span className="TextAnswer">{props.getValues("noGas")}</span></SubTopic>
                </Grid>
                <Grid item xs={4}>
                    <SubTopic>MPG : <span className="TextAnswer">{utils.numberWithCommas(props.getValues("mpg"))}</span></SubTopic>
                </Grid>
                <Grid item xs={4}>
                    <SubTopic>ไมล์ : <span className="TextAnswer">{props.getValues("mile")}</span></SubTopic>
                </Grid>
                <Grid item xs={4}>
                    <SubTopic>ประตู : <span className="TextAnswer">{props.getValues("doors")}</span></SubTopic>
                </Grid>
                <Grid item xs={4}>
                    <SubTopic>ที่นั่ง : <span className="TextAnswer">{props.getValues("seats")}</span></SubTopic>
                </Grid>
                <Grid item xs={12}>
                    <Topic>รูปภาพ</Topic>
                    <Divider className="Divider" />
                </Grid>
                <ContentImage>
                    {previewImage().length > 0 && previewImage().map((value,index)=>(
                        <div className="Content" key={index}>
                            <img className="Image" src={value} />
                        </div>
                    ))}
                </ContentImage>
                <Grid item xs={12}>
                    <Topic>สถานที่</Topic>
                    <Divider className="Divider" />
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>จังหวัด : <span className="TextAnswer">{props.getValues("province")}</span></SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>อำเภอ : <span className="TextAnswer">{props.getValues("district")}</span></SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>เขต/ตำบล : <span className="TextAnswer">{props.getValues("subdistrict")}</span></SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>ZIP code : <span className="TextAnswer">{props.getValues("zipcode")}</span></SubTopic>
                </Grid>
                <Grid item xs={12}>
                    <Topic>Package</Topic>
                    <Divider className="Divider" />
                </Grid>
                <Grid item xs={12}>
                    <SubTopic><span className="TextAnswer">{props.packageSelected > 0 && listPackages[props.packageSelected-1].package}</span></SubTopic>
                </Grid>
                <Grid item xs={12}>
                    <Topic>Options</Topic>
                    <Divider className="Divider" />
                </Grid>
                <Grid container item xs={12}>
                    {props.options.length > 0 && props.options.map((option)=>(
                        <Grid item xs={4} key={option}>
                            <List>
                                <CircleIcon className="dot"/>
                                <SubTopic><span className="TextAnswer">{listOptions.find(optionlist => optionlist.id === option).name}</span></SubTopic>
                            </List>
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <Topic>Condition</Topic>
                    <Divider className="Divider" />
                </Grid>
                <Grid container item xs={12}>
                    {props.conditions.length > 0 && props.conditions.map((condition)=>(
                        <Grid item xs={4} key={condition}>
                            <List>
                                <CircleIcon className="dot"/>
                                <SubTopic><span className="TextAnswer">{listCondition.find(conditionlist => conditionlist.id === condition)}</span></SubTopic>
                            </List>
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <Topic>Extras</Topic>
                    <Divider className="Divider" />
                </Grid>
                <Grid item xs={12}>
                    {props.listExtrasSetPrice.length > 0 && props.listExtrasSetPrice.map((extra)=>(
                        <List key={extra.extra}>
                            <CircleIcon className="dot" />
                            <SubTopic><span className="TextAnswer">{`${extra.extra} ${extra.price} บาท/${extra.duration}`}</span></SubTopic>
                        </List>
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <Topic>Profile</Topic>
                    <Divider className="Divider" />
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>Phone Number : <span className="TextAnswer">{props.getValues("phonenumber")}</span></SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>Email : <span className="TextAnswer">{props.getValues("email")}</span></SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>Line ID : <span className="TextAnswer">{props.getValues("lineID")}</span></SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>วุฒิการศึกษา : <span className="TextAnswer">{props.getValues("education")}</span></SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>อาชีพ : <span className="TextAnswer">{props.getValues("occupation")}</span></SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>รายได้ : <span className="TextAnswer">{utils.numberWithCommas(props.getValues("salary"))}</span> บาท</SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>คุณใช้รถบ่อยแค่ไหน : <span className="TextAnswer">{props.getValues("oftenUsedCar")}</span></SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>คุณแชร์รถบ่อยแค่ไหน : <span className="TextAnswer">{props.getValues("oftenSharedCar")}</span></SubTopic>
                </Grid>
                <Grid item xs={12}>
                    <SubTopic>รายละเอียดส่วนตัว :</SubTopic>
                </Grid>
                <Grid item xs={12}>
                    <TextDetail><span className="TextAnswer TextDetail">{props.getValues("detailProfile")}</span></TextDetail>
                </Grid>
                <Grid item xs={12}>
                    <Topic>Price</Topic>
                    <Divider className="Divider" />
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>Price/Day : <span className="TextAnswer">{utils.numberWithCommas(props.getValues("priceperday"))}</span> บาท</SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>มัดจำ/Trip : <span className="TextAnswer">{utils.numberWithCommas(props.getValues("deposit"))}</span> บาท</SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>3 Days Or More Discount : <span className="TextAnswer">{utils.numberWithCommas(props.getValues("threedaydiscount"))}</span> บาท</SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>1 Months Or Move Discount : <span className="TextAnswer">{utils.numberWithCommas(props.getValues("monthdiscount"))}</span> บาท</SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>Early Bird Discount : <span className="TextAnswer">{utils.numberWithCommas(props.getValues("earlybirddiscount"))}</span> บาท before <span className="TextAnswer">{props.getValues("before")}</span></SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>Cancel Free Before : <span className="TextAnswer">{props.getValues("cancelfreebefore")}</span></SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>Distance Include : <span className="TextAnswer">{utils.numberWithCommas(props.getValues("distanceinclude"))}</span> ไมล์</SubTopic>
                </Grid>
                <Grid item xs={6}>
                    <SubTopic>ราคาทำความสะอาด : <span className="TextAnswer">{utils.numberWithCommas(props.getValues("pricecleaning"))}</span> บาท</SubTopic>
                </Grid>
                <Grid item xs={12}>
                    <SubTopic>Delivery : <span className="TextAnswer">{props.getValues("delivery")}</span></SubTopic>
                </Grid>
                <Grid item xs={12}>
                    <SubTopic>Detail :</SubTopic>
                </Grid>
                <Grid item xs={12}>
                    <TextDetail><span className="TextAnswer">{props.getValues("detailDelivery")}</span></TextDetail>
                </Grid>
                <Grid item xs={12}>
                    <Topic>Approval Need</Topic>
                    <Divider className="Divider" />
                </Grid>
                <Grid item xs={12}>
                    <SubTopic><span className="TextAnswer">{props.getValues("approvalneed")}</span></SubTopic>
                </Grid>
            </GridStyled>
            <div style={{width:"100%",display:"flex",justifyContent:"flex-end",padding:"10px 0"}}>
                <BackButton onClick={props.onClickBackProcess}>Back</BackButton>
                <SubmitButton onClick={props.onClickSubmit}>Submit</SubmitButton>
            </div>
        </Content>
    );
};

export default SummarizePage;