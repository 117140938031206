import React, { useState, Fragment, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { styled } from "@mui/styles";
import {
  Typography,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import BetweenSiteCarService from "../../../../../services/betweenSiteCar.service";

const DivPlace = styled("div")({
  width: "80%",
  height: "50%",
  position: "relative",
  "& .ButtonConfirm": {
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
  "& .ButtonReject": {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "darkred",
    },
  },
  "& .CalledButton": {
    position: "absolute",
    right: "-10px",
    bottom: "0",
  },
});

const DivInputPlace = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
});

const IconAndText = styled("div")({
  display: "flex",
  marginTop: "20px",
  marginBottom: "20px",
});

const Icon = styled(LocationOnIcon)({
  marginTop: "5px",
  fontSize: "35px",
});

const Text = styled(Typography)({
  fontSize: "30px",
});

const TextGettingPlace = styled(Typography)({
  fontSize: "30px",
  textAlign: "center",
});

const FormControlStyled = styled(FormControl)({
  width: "100%",
  backgroundColor: "white",
  borderRadius: "4px",
});

const DividerStyled = styled(Divider)({
  width: "95%",
  height: "2px",
  backgroundColor: "white",
});

const ButtonStyled = styled(Button)({
  width: "100px",
  fontSize: "20px",
  color: "white",
});

const NavButtonOfGettingPlace = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-around",
  marginTop: "20px",
});

const DivPlaceConfirm = styled("div")({
  height: "calc(100% - 85px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const PlaceText = styled(Typography)({
  fontSize: "25px",
  marginTop: "-40px",
});

const EditButton = styled(Button)({
  width: "150px",
  height: "50px",
  backgroundColor: "#F4BB44",
  color: "black",
  fontSize: "20px",
  marginTop: "10px",
  "&:hover": {
    backgroundColor: "#F4BB44",
  },
});

const ButtonConfirmAll = styled(Button)({
  width: "150px",
  height: "50px",
  fontSize: "20px",
  color: "white",
  marginTop: "10px",
  marginLeft: "20px",
});

const AssignPlace = (props) => {
  const { getValues, setValue, control, handleSubmit, watch } = useForm();
  const [changeGettingPlace, setChangeGettingPlace] = useState(false);
  const [confirmGettingPlace, setConfirmGettingPlace] = useState(false);
  const [confirmToPlace, setConfirmToPlace] = useState(false);
  const [siteScg, setSiteScg] = useState([]);

  const onClickSubmit = () => {};

  useEffect(() => {
    BetweenSiteCarService.getSite().then((res) => {
      if (res.data) {
        setSiteScg(res.data);
      }
    });
  }, []);

  return (
    <Fragment>
      <DivPlace>
        <IconAndText>
          <Icon />
          <Text>สถานที่รับ</Text>
        </IconAndText>
        {!changeGettingPlace && !confirmGettingPlace && (
          <Fragment>
            <TextGettingPlace>จุดรอรถ Site 3</TextGettingPlace>
            <NavButtonOfGettingPlace>
              <ButtonStyled
                className="ButtonConfirm"
                onClick={() => {
                  setValue("gettingPlace", "Site 3");
                  setConfirmGettingPlace(true);
                }}
              >
                ใช่
              </ButtonStyled>
              <ButtonStyled
                className="ButtonReject"
                onClick={() => setChangeGettingPlace(true)}
              >
                ไม่ใช่
              </ButtonStyled>
            </NavButtonOfGettingPlace>
          </Fragment>
        )}
        {changeGettingPlace && !confirmGettingPlace && (
          <DivInputPlace>
            <Controller
              render={(propsInController) => (
                <FormControlStyled variant="outlined">
                  <InputLabel>จุดรับ</InputLabel>
                  <Select
                    label="จุดรับ"
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                  >
                    {/* <MenuItem value={"Site 1"}>Site 1</MenuItem>
                    <MenuItem value={"Site 2"}>Site 2</MenuItem>
                    <MenuItem value={"Site 3"}>Site 3</MenuItem>
                    <MenuItem value={"Site 7"}>Site 7</MenuItem> */}
                    {siteScg &&
                      siteScg.map((value, index) => (
                        <MenuItem key={index} value={value.idScgSite}>
                          {value.noSite !== null &&
                            `Site${value.noSite}:${value.nameSite}`}
                          {value.noSite === null && `${value.nameSite}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControlStyled>
              )}
              name="gettingPlace"
              control={control}
              defaultValue="Site 1"
            />
            <ButtonStyled
              className="ButtonConfirm"
              style={{ marginTop: "20px" }}
              onClick={() => setConfirmGettingPlace(true)}
            >
              ยืนยัน
            </ButtonStyled>
          </DivInputPlace>
        )}
        {watch("gettingPlace") !== undefined &&
          confirmGettingPlace &&
          siteScg && (
            <DivPlaceConfirm>
              <PlaceText>
                {siteScg.map((value) => {
                  if (value.idScgSite === watch("gettingPlace")) {
                    if (value.noSite !== null) {
                      return `Site${value.noSite}:${value.nameSite}`;
                    } else {
                      return value.nameSite;
                    }
                  }
                })}
              </PlaceText>
              <EditButton
                onClick={() => {
                  setConfirmGettingPlace(false);
                  setChangeGettingPlace(true);
                }}
              >
                เปลี่ยน
              </EditButton>
            </DivPlaceConfirm>
          )}
      </DivPlace>
      <DividerStyled />
      <DivPlace>
        <IconAndText>
          <Icon />
          <Text>จุดส่ง</Text>
        </IconAndText>
        {!confirmToPlace && (
          <DivInputPlace>
            <Controller
              render={(propsInController) => (
                <FormControlStyled variant="outlined">
                  <InputLabel>จุดส่ง</InputLabel>
                  <Select
                    label="จุดส่ง"
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                  >
                    {/* <MenuItem value={"Site 1"}>Site 1</MenuItem>
                    <MenuItem value={"Site 2"}>Site 2</MenuItem>
                    <MenuItem value={"Site 3"}>Site 3</MenuItem>
                    <MenuItem value={"Site 7"}>Site 7</MenuItem> */}
                    {siteScg &&
                      siteScg.map((value, index) => (
                        <MenuItem key={index} value={value.idScgSite}>
                          {value.noSite !== null &&
                            `Site${value.noSite}:${value.nameSite}`}
                          {value.noSite === null && `${value.nameSite}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControlStyled>
              )}
              name="toPlace"
              control={control}
              defaultValue="Site 1"
            />
            <ButtonStyled
              className="ButtonConfirm"
              style={{ marginTop: "20px" }}
              onClick={() => setConfirmToPlace(true)}
            >
              ยืนยัน
            </ButtonStyled>
          </DivInputPlace>
        )}
        {watch("toPlace") !== undefined && confirmToPlace && siteScg && (
          <DivPlaceConfirm>
            <PlaceText>
              {siteScg.map((value) => {
                if (value.idScgSite === watch("toPlace")) {
                  if (value.noSite !== null) {
                    return `Site${value.noSite}:${value.nameSite}`;
                  } else {
                    return value.nameSite;
                  }
                }
              })}
            </PlaceText>
            <div style={{ display: "flex" }}>
              <EditButton onClick={() => setConfirmToPlace(false)}>
                เปลี่ยน
              </EditButton>
              {confirmGettingPlace && confirmToPlace && (
                <ButtonConfirmAll
                  className="ButtonConfirm"
                  onClick={handleSubmit(props.onClickNextPage)}
                >
                  ยืนยัน
                </ButtonConfirmAll>
              )}
            </div>
          </DivPlaceConfirm>
        )}
      </DivPlace>
    </Fragment>
  );
};

export default AssignPlace;
