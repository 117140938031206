import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import NearMeIcon from "@mui/icons-material/NearMe";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";

const AdminMenu = ({ onClickList }) => {
  const dataListMenu = [
    // {
    //   listItemIcon: <DashboardIcon />,
    //   listItemText: "Dashboard LentCar",
    //   listLink: "/admin/dasboard_lentcar",
    //   listKey:"dashboardlentcar",
    // },

    // {
    //   listItemIcon: <i className="fas fa-steering-wheel" style={{fontSize:"20px", marginLeft:"3px"}}></i>,
    //   listItemText: "Driver",
    //   listLink: "/admin/driver",
    //   listKey:"driver",
    // },
    // {
    //   listItemIcon: <PeopleIcon />,
    //   listItemText: "Employee",
    //   listLink: "/admin/employee",
    //   listKey:"employee",
    // },
    // {
    //   listItemIcon: <DriveEtaIcon />,
    //   listItemText: "Car management",
    //   listLink: "/admin/car_management",
    //   listKey:"carManagement",
    // },
    // {
    //   listItemIcon: <AirportShuttleIcon />,
    //   listItemText: "Vehicle Page",
    //   listLink: "/admin/vehicle_page",
    //   listKey:"vehiclePage",
    // },
    {
      listItemIcon: <DriveEtaIcon />,
      listItemText: "ระบบจัดการรถ",
      listKey: "allCarManage",
      collapse: [
        {
          listItemIcon: <DriveEtaIcon />,
          listItemText: "จัดการรถ",
          listLink: "/admin/car_management",
          listKey: "carManagement",
        },
        {
          listItemIcon: (
            <i
              className="fas fa-steering-wheel"
              style={{ fontSize: "20px", marginLeft: "3px" }}
            ></i>
          ),
          listItemText: "คนขับรถ",
          listLink: "/admin/driver",
          listKey: "driver",
        },
        {
          listItemIcon: <AirportShuttleIcon />,
          listItemText: "รถยนต์",
          listLink: "/admin/vehicle_page",
          listKey: "vehiclePage",
        },
      ],
    },
    {
      listItemIcon: (
        <i className="fas fa-car-side" style={{ fontSize: "20px" }}></i>
      ),
      listItemText: "Shift Car",
      listKey: "shiftcar",
      collapse: [
        {
          listItemIcon: <DashboardIcon />,
          listItemText: "Dashboard",
          listLink: "/admin/dashboard",
          listKey: "dashboard",
        },
        {
          listItemIcon: <NearMeIcon />,
          listItemText: "Routes",
          listLink: "/admin/routes_page",
          listKey: "routespage",
        },
        {
          listItemIcon: <PersonPinIcon />,
          listItemText: "Employee Page",
          listLink: "/admin/employee_page",
          listKey: "employeePage",
        },
      ],
    },
    {
      listItemIcon: (
        <i className="fas fa-hat-chef" style={{ fontSize: "20px" }}></i>
      ),
      listItemText: "จัดเลี้ยง",
      listKey: "catering",
      collapse: [
        {
          listItemIcon: <FormatListBulletedIcon />,
          listItemText: "รายการขอจัดเลี้ยง",
          listLink: "/admin/catering/requested_list",
          listKey: "adminCateringRequestedList",
        },
        {
          listItemIcon: <FormatListBulletedIcon />,
          listItemText: "รายการร้านอาหาร",
          listLink: "/admin/catering/restaurant_list",
          listKey: "adminCateringRestaurantList",
        },
      ],
    },
    {
      listItemIcon: (
        <i
          className="fas fa-tools"
          style={{ width: 24, textAlign: "center", fontSize: "1.25rem" }}
        ></i>
      ),
      listItemText: "ระบบแจ้งซ่อม",
      listLink: "",
      listKey: "maintenanceAdmin",
      collapse: [
        {
          listItemIcon: <DescriptionRoundedIcon />,
          listItemText: "รายการแจ้งซ่อม",
          listLink: "/admin/maintenances",
          listKey: "requestMaintenance",
        },
        {
          listItemIcon: <DescriptionRoundedIcon />,
          listItemText: "รายชื่อช่าง",
          listLink: "/admin/technicians",
          listKey: "technician",
        },
      ],
    },
    {
      listItemIcon: <AirportShuttleIcon />,
      listItemText: "จองสถานที่",
      listKey: "meetingRoom",
      collapse: [
        {
          listItemIcon: <FormatListBulletedIcon />,
          listItemText: "รายการขอสถานที่",
          listLink: "/admin/Newmeetingroom/detailMeetingRoomBooking/",
          listKey: "meetingRoomRequestedList",
        },
        {
          listItemIcon: <FormatListBulletedIcon />,
          listItemText: "รายการสถานที่",
          listLink: "/admin/meetingRoom_page",
          listKey: "meetingRoomList",
        },
      ],
    },
    {
      listItemIcon: <AirportShuttleIcon />,
      listItemText: "จองคนขับรถ",
      listLink: "/admin/driverBooking_page",
      listKey: "driverBooking",
    },
  ];

  return (
    <ListMenu
      dataListMenu={dataListMenu}
      menuRole={"Admin"}
      onClickList={onClickList}
    />
  );
};

export default AdminMenu;
