import React, { useState } from 'react';
import { useForm } from "react-hook-form";

import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/styles';
import {
    Modal, Paper, Grid, Typography, Divider, useMediaQuery
} from '@mui/material';

import ListForm from "./listForm";
import Location from "./locationForm";
import DetailCarForm from "./detailCarForm";
import ImageCarForm from "./imageCarForm";
import ProfileForm from "./profileForm";
import PriceForm from "./priceForm";
import ConditionForm from "./conditionForm";
import ExtrasForm from "./extrasForm";
import SummarizePage from "./summarizePage";

const useStyles = makeStyles((theme)=>({
    DivCoverReturn:{
        display:"flex",
        height:"calc(100% - 64px)",
    },
    ListForm:{
        width:"30%",
        height:"100%",
    },
    ContentPage:{
        width:"70%",
        height:"100%",
        display:"flex",
        justifyContent:"center",
        overflowY:"auto",
        [theme.breakpoints.down('sm')]:{
            width:"100%"
        }
    },
}));

const ModalDetail = styled(Modal)(({theme})=>({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    "& .Paper":{
        width:"calc(50% - 100px)",
        height:"calc(90% - 40px)",
        padding:"20px 50px",
        overflowY:"auto",
    },
    "& .TitleCard":{
        fontSize:"30px",
        textAlign:"center",
        color:theme.palette.primary.dark,
        marginBottom:"10px",
    },
    "& .Divider":{
        height:"3px",
    },
    "& .TextTopic":{
        fontWeight:"bold",
    },
    "& .Text":{
        fontSize:"20px",
    },
    "& .TextInput":{
        fontSize:"18px",
    },
    "& .TextAnswer":{
        fontWeight:"bold",
        color:theme.palette.primary.main,
    },
}));

const FormAddLentCar = props => {
    const classes = useStyles();
    const [processValue, setProcessValue] = useState(0);
    const [indexProcess, setIndexProcess] = useState(1);
    /* const [openModal, setOpenModal] = useState(false); */
    const [options, setOptions] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [packageSelected, setPackageSelected] = useState(0);
    const [listExtrasSetPrice, setListExtrasSetPrice] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const { register, control, getValues, setValue } = useForm({
        defaultValues:{
            location:"",
            option:[],
        }
    });

    const MobileResponse = useMediaQuery('(max-width:600px)');

    const onClickNextProcess = () => {
        setIndexProcess(indexProcess+1);
        setProcessValue(indexProcess*14.28);
    };

    const onClickBackProcess = () => {
        setIndexProcess(indexProcess-1);
        setProcessValue((indexProcess-2)*14.28);
    };

    const onClickSubmit = () => {
        /* setOpenModal(true); */
        onClickNextProcess();
        console.log(getValues());
        props.history.push("/friend2friend_Car/lender");
    };

    return(
        <div className={`page ${classes.DivCoverReturn}`}>
            {!MobileResponse && 
                <div className={`${classes.ListForm}`}>
                    <ListForm processValue={processValue} indexProcess={indexProcess}/>
                </div>
            }
            <div className={`${classes.ContentPage}`}>
                {indexProcess === 1 && 
                    <Location 
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        control={control}
                        options={options}
                        setOptions={setOptions}
                        onClickNextProcess={onClickNextProcess}
                    />
                }
                {indexProcess === 2 &&
                    <DetailCarForm 
                        register={register} 
                        control={control} 
                        onClickNextProcess={onClickNextProcess} 
                        onClickBackProcess={onClickBackProcess}
                    />
                }
                {indexProcess === 3 &&
                    <ImageCarForm 
                        register={register} 
                        imageFiles={imageFiles}
                        setImageFiles={setImageFiles}
                        onClickNextProcess={onClickNextProcess} 
                        onClickBackProcess={onClickBackProcess}
                    />
                }
                {indexProcess === 4 &&
                    <ProfileForm 
                        register={register} 
                        control={control} 
                        onClickNextProcess={onClickNextProcess} 
                        onClickBackProcess={onClickBackProcess}
                    />
                }
                {indexProcess === 5 &&
                    <PriceForm 
                        register={register} control={control} 
                        onClickNextProcess={onClickNextProcess} 
                        onClickBackProcess={onClickBackProcess}
                    />
                }
                {indexProcess === 6 &&
                    <ConditionForm 
                        register={register} 
                        conditions={conditions}
                        setConditions={setConditions}
                        packageSelected={packageSelected}
                        setPackageSelected={setPackageSelected}
                        onClickNextProcess={onClickNextProcess} 
                        onClickBackProcess={onClickBackProcess}
                    />
                }
                {indexProcess === 7 &&
                    <ExtrasForm 
                        register={register} 
                        control={control} 
                        listExtrasSetPrice={listExtrasSetPrice}
                        setListExtrasSetPrice={setListExtrasSetPrice}
                        onClickBackProcess={onClickBackProcess} 
                        onClickSubmit={onClickSubmit}
                    />
                }
                {/* {indexProcess === 8 && 
                    <SummarizePage 
                        getValues={getValues}
                        options={options}
                        conditions={conditions}
                        imageFiles={imageFiles}
                        packageSelected={packageSelected}
                        listExtrasSetPrice={listExtrasSetPrice}
                        onClickBackProcess={onClickBackProcess} 
                        onClickSubmit={onClickSubmit}
                    />
                } */}
            </div>
        </div>
    );
};

export default FormAddLentCar;