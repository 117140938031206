import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';

import { styled } from "@mui/styles";
import { Backdrop, CircularProgress } from "@mui/material";

import CardStartStop from "./cardStartStop";

import { getDriverRouteDayByIdDriver, postStartTime, postEndTime } from "../../../../actions/driverRouteDay";
import DriverRouteDayService from "../../../../services/driverRouteDay.service";

require('dayjs/locale/th');
dayjs.locale('th');

const DivCoverReturn = styled("div")({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ImgBackground = styled("img")({
  width: "100%",
  height: "100%",
});

const StartStopDrivingPage = (props) => {
  const dispatch = useDispatch();
  const { result: Driver } = useSelector(state => state.driverRouteDay);
  // const [clickStop, setClickStop] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);

  const onClickHandler = () => {
    dispatch(postStartTime(Driver.idDriver,Driver.routeDate));
    setOpenBackDrop(true);
    let delay = setInterval(() => {
      setOpenBackDrop(false);
      props.history.push("/map");
      clearInterval(delay);
    }, 1000);
  };

  const onClickHandlerStop = () => {
    let endTime = new Date();
    let duration = TimeToString(endTime);
    dispatch(postEndTime(Driver.idDriver,Driver.routeDate,endTime,duration));
    // setClickStop(!clickStop);
    let delay = setInterval(() => {
      props.history.push("/reviews");
      clearInterval(delay);
    }, 5000);
  };

  const TimeToString = (endTime) => {
    let startTime = Driver.startTime;
    let stopTime = dayjs(endTime);
    let hour = stopTime.diff(startTime, 'hour');
    let minute = stopTime.diff(startTime, 'minute') - (hour * 60);
    let second = stopTime.diff(startTime, 'second') - (hour * 60) - (minute * 60);
    let duration = `${hour} ชั่วโมง ${minute} นาที ${second} วินาที`;
    return duration;
  }

  return (
    <DivCoverReturn className="page">
      {Driver &&
        (Driver.startTime ? (
          <CardStartStop
            TextHeader="หยุดรถ"
            TextButton="STOP"
            startTime={Driver.startTime}
            endTime={Driver.endTime}
            duration={Driver.duration}
            // clickStop={clickStop}
            onClickHandlerStop={onClickHandlerStop}
          />
        ) : (
          <CardStartStop
            TextHeader="ออกรถ"
            TextButton="START"
            startTime={null}
            onClickHandler={onClickHandler}
          />
        ))}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ImgBackground
        src="./assets/background/background_start.jpg"
        alt="background_start"
      />
    </DivCoverReturn>
  );
};

export default StartStopDrivingPage;
