import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { SnackbarProvider, useSnackbar } from "notistack";
import dayjs from "dayjs";

import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routers from "./Routers";
import { getEmergency } from "./actions/emergency";
import NavBar from "./components/layouts/NavBar";
import "moment/locale/th";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "4px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
}));

const PartOfRouter = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  // width: "calc(100% - 56px)",
  width: "100%",
  backgroundColor: "#F1F4F9",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0",
    width: "100%",
  },
}));

const PartOfRouterDriver = styled("div")({
  display: "flex",
  height: "100%",
  width: "100%",
  marginLeft: "0",
});

function App() {
  dayjs.locale("th");
  useStyles();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isDriver, setIsDriver] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setIsDriver(currentUser.roles.includes("ROLE_DRIVER"));
    }
  }, [currentUser]);

  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        <SnackbarProvider
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          maxSnack={3}
        >
          {isLoggedIn && <NavBar isDriver={isDriver} />}
          {!isLoggedIn && isDriver ? (
            <PartOfRouterDriver>
              <Routers />
            </PartOfRouterDriver>
          ) : (
            <PartOfRouter>
              <Routers />
            </PartOfRouter>
          )}
        </SnackbarProvider>
      </Box>
      {/* <NavBar isDriver={isDriver} isLoggedIn={isLoggedIn} /> */}
    </Router>
  );
}

export default App;
