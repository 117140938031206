import React from 'react';
import { Controller } from "react-hook-form";

import makeStyles from '@mui/styles/makeStyles';
import {
     Button, Typography, TextField, Avatar, FormControl, InputLabel, Select, MenuItem, Grid,
     Divider,
} from "@mui/material";

const useStyles = makeStyles((theme)=>({
    Content:{
        width:"95%",
        height:"calc(100% - 40px)",
        padding:"20px 0",
    },
    Title:{
        fontSize:"26px",
    },
    partOfAvatar:{
        width:"30%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
    },
    Avatar:{
        width:"100px",
        height:"100px",
        marginBottom:"10px",
    },
    Divider:{
        height:"2px",
        backgroundColor:theme.palette.grey[500],
        margin:"20px 0"
    },
    NextButton:{
        width:"fit-content",
        color:"green",
        fontSize:"18px",
        "&:hover":{
            backgroundColor:"white",
        }
    },
}));

const ProfileForm = props => {
    const classes = useStyles();

    return(
        <div className={`${classes.Content}`}>
            <Typography className={`${classes.Title}`}>Profile</Typography>
            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <Avatar className={`${classes.Avatar}`} src="" alt="avatar"/>
                <Button>Upload Avatar</Button>
            </div>
            <Divider className={`${classes.Divider}`}/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField 
                        id="phonenumber" 
                        label="Phone Number" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("phonenumber")}} 
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField 
                        id="email" 
                        label="Email" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("email")}} 
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField 
                        id="lineID" 
                        label="Line ID" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("lineID")}} 
                    />
                </Grid>
            </Grid>
            <Divider className={`${classes.Divider}`}/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="Occupation">อาชีพ</InputLabel>
                        <Controller 
                            control={props.control}
                            name="occupation"
                            defaultValue=""
                            render={({field})=>(
                                <Select
                                    {...field}
                                    labelId="Occupation-label"
                                    id="Occupation"
                                    label="อาชีพ"
                                >
                                    <MenuItem value="เจ้าของกิจการ">เจ้าของกิจการ</MenuItem>
                                    <MenuItem value="พนักงานบริษัทเอกชน">พนักงานบริษัทเอกชน</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField 
                        id="salary" 
                        label="รายได้" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("salary")}} 
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="Education">วุฒิการศึกษา</InputLabel>
                        <Controller 
                            control={props.control}
                            name="education"
                            defaultValue=""
                            render={({field})=>(
                                <Select
                                    {...field}
                                    labelId="Education-label"
                                    id="Education"
                                    label="วุฒิการศึกษา"
                                >
                                    <MenuItem value="มัธยมศึกษาตอนต้น(ม.1-3)">มัธยมศึกษาตอนต้น(ม.1-3)</MenuItem>
                                    <MenuItem value="มัธยมศึกษาตอนปลาย(ม.4-6)">มัธยมศึกษาตอนปลาย(ม.4-6)</MenuItem>
                                    <MenuItem value="ประกาศนียบัตรวิชาชีพ(ปวช.)">ประกาศนียบัตรวิชาชีพ(ปวช.)</MenuItem>
                                    <MenuItem value="อนุปริญญา">อนุปริญญา</MenuItem>
                                    <MenuItem value="ปริญญาตรี">ปริญญาตรี</MenuItem>
                                    <MenuItem value="สูงกว่าปริญญาตรี">สูงกว่าปริญญาตรี</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        id="detailProfile" 
                        label="รายละเอียด" 
                        variant="outlined" 
                        fullWidth 
                        multiline
                        rows={3}
                        inputProps={{...props.register("detailProfile")}} 
                    />
                </Grid>
            </Grid>
            <Divider className={`${classes.Divider}`}/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="OftenUsedCar">คุณใช้รถบ่อยแค่ไหน</InputLabel>
                        <Controller 
                            control={props.control}
                            name="oftenUsedCar"
                            defaultValue=""
                            render={({field})=>(
                                <Select
                                    {...field}
                                    labelId="OftenUsedCar-label"
                                    id="OftenUsedCar"
                                    label="คุณใช้รถบ่อยแค่ไหน"
                                >
                                    <MenuItem value="1-3 ครั้งต่อสัปดาห์">1-3 ครั้งต่อสัปดาห์</MenuItem>
                                    <MenuItem value="4-6 ครั้งต่อสัปดาห์">4-6 ครั้งต่อสัปดาห์</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="OftenSharedCar">คุณแชร์รถบ่อยแค่ไหน</InputLabel>
                        <Controller 
                            control={props.control}
                            name="oftenSharedCar"
                            defaultValue=""
                            render={({field})=>(
                                <Select
                                    {...field}
                                    labelId="OftenSharedCar-label"
                                    id="OftenSharedCar"
                                    label="คุณใช้รถบ่อยแค่ไหน"
                                >
                                    <MenuItem value="1-3 ครั้งต่อสัปดาห์">1-3 ครั้งต่อสัปดาห์</MenuItem>
                                    <MenuItem value="4-6 ครั้งต่อสัปดาห์">4-6 ครั้งต่อสัปดาห์</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <div style={{width:"100%",display:"flex",justifyContent:"flex-end",padding:"10px 0"}}>
                <Button className={`${classes.NextButton}`} onClick={props.onClickBackProcess}>Back</Button>
                <Button className={`${classes.NextButton}`} onClick={props.onClickNextProcess}>Next</Button>
            </div>
        </div>
    );
};

export default ProfileForm;