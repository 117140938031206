import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { styled } from "@mui/styles";
import {
  Typography,
  TextField,
  IconButton,
  Autocomplete,
  Grid,
  Card,
  Select,
  FormControl,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slider,
  useMediaQuery,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import SearchIcon from "@mui/icons-material/Search";
import FavoriteIcon from "@mui/icons-material/Favorite";
import StarIcon from "@mui/icons-material/Star";

import LentCarsService from "../../../../../services/lentCar.service";
import utils from "../../../../../utils";
import FilterButton from "./filterButton";

import GoogleMap from "google-map-react";
import {
  GoogleAPIkey,
  locationSite1,
} from "../../../../../utils/googleMapAssets";

const HeadFilter = styled("div")(({ theme }) => ({
  width: "calc(100% - 60px)",
  display: "flex",
  margin: "30px",
  alignItems: "center",
  // position:"relative",
  justifyContent: "space-between",
  "& .InputBox": {
    width: "300px",
    marginRight: "20px",
    [theme.breakpoints.down("lg")]: {
      width: "235px",
      marginRight: "10px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  "& .InputDate": {
    width: "150px",
    [theme.breakpoints.down("md")]: {
      width: "165px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  [theme.breakpoints.down("xl")]: {
    width: "calc(100% - 40px)",
    margin: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    display: "block",
  },
}));

const DivHeaderLeft = styled("div")(({ theme }) => ({
  width: "fit-content",
  display: "flex",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    display: "block",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const DivHeaderFilterDateAndTime = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    marginTop: "20px",
    alignItems: "flex-end",
  },
}));

const SelectTime = styled(FormControl)(({ theme }) => ({
  width: "150px",
  marginRight: "20px",
  [theme.breakpoints.down("lg")]: {
    marginRight: "10px",
  },
  [theme.breakpoints.down("md")]: {
    width: "160px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginRight: "0",
    marginBottom: "20px",
  },
}));

const DivSearchButton = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginTop: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
  },
}));

const SearchButton = styled(IconButton)(({ theme }) => ({
  width: "50px",
  height: "50px",
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListsRequestButton = styled(Button)(({ theme }) => ({
  width: "160px",
  height: "50px",
  // top:"50%",
  // position:"absolute",
  // right:"0",
  // transform:"translate(0 ,-50%)",
  border: "2px solid",
  fontSize: "20px",
  borderColor: theme.palette.primary.main,
  boxShadow: theme.shadows[3],
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  "& .Icon": {
    marginRight: "5px",
    fontSize: "24px",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "10px",
  },
}));

const DivHeaderRight = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  [theme.breakpoints.down("lg")]: {
    marginTop: "20px",
  },
}));

// const HeadFilter = styled("div")(({theme})=>({
//     display:"flex",
//     margin:"30px",
//     "& .InputBox":{
//         width:"300px",
//         marginRight:"20px",
//     },
//     "& .InputDate":{
//         width:"150px",
//     },
// }));

// const SearchButton = styled(IconButton)(({theme})=>({
//     width:"50px",
//     height:"50px",
//     color:theme.palette.primary.contrastText,
//     backgroundColor:theme.palette.primary.main,
//     "&:hover":{
//         backgroundColor:theme.palette.primary.main,
//     },
// }));

// const SelectTime = styled(FormControl)(({theme})=>({
//     width:"150px",
//     marginRight:"20px",
// }));

const NavFilter = styled("div")(({ theme }) => ({
  width: "calc(100% -10px)",
  paddingLeft: "10px",
}));

// const IconAndText = styled("div")(({theme})=>({
//     display:"flex",
//     alignItems:"center",
//     "& .Icon":{
//         fontSize:"16px",
//         marginRight:"10px",
//     },
//     "& .Text":{
//         fontSize:"16px",
//         fontWeight:"bold",
//     },
// }));

const CardStyled = styled(Card)(({ theme }) => ({
  width: "100%",
  boxShadow: theme.shadows[4],
  position: "relative",
}));

const ImageOfCard = styled("div")(({ theme }) => ({
  height: "150px",
  backgroundColor: "transparent",
  "& .Image": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

const DetailOfCard = styled("div")(({ theme }) => ({
  height: "80px",
  padding: "10px 20px 0",
  "& .Model": {
    fontSize: "20px",
    fontWeight: "bold",
  },
  "& .partOfRating": {
    display: "flex",
    alignItems: "center",
  },
  "& .IconRating": {
    color: theme.palette.warning.light,
    marginRight: "5px",
  },
  "& .Point": {
    marginRight: "5px",
  },
}));

const PriceOfCard = styled("div")(({ theme }) => ({
  height: "50px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: "0 20px 0 10px",
  "& .Price": {
    fontSize: "20px",
    fontWeight: "bold",
  },
}));

const FavoriteButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  zIndex: "1",
  bottom: "5px",
  left: "10px",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .favorited": {
    color: theme.palette.error.light,
  },
}));

const Map = styled("div")(({ theme }) => ({
  width: "40%",
  height: "100%",
  "& .Image": {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    height: "300px",
    minHeight: "300px",
  },
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  width: "120px",
  "& input": {
    padding: "10px 14px",
  },
}));

const DivContentGrid = styled("div")(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 62px)",
  overflowY: "auto",
  marginTop: "20px",
  marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    height: "500px",
  },
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  width: "calc(100% - 10px)",
  padding: "5px 10px 20px",
  marginLeft: "0",
}));

const Content = styled("div")(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 96px)",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("lg")]: {
    height: "calc(100% - 166px)",
  },
  [theme.breakpoints.down("md")]: {
    height: "fit-content",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: "20px",
  },
}));

const DivGridCar = styled("div")(({ theme }) => ({
  width: "60%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const FilterPageBorrower = (props) => {
  const [place, setPlace] = useState(props.location.state.place);
  const [fromDate, setFromDate] = useState(new Date());
  const [untilDate, setUntilDate] = useState(new Date());
  const [listLentCars, setListLentCars] = useState([]);
  const [favorite, setFavorite] = useState([1, 4]);
  const [rangePrice, setRangePrice] = useState([0, 10000]);
  const [rangeDelivery, setRangeDelivery] = useState(3000);

  const IpadVerticalResponse = useMediaQuery("(max-width:900px)");

  const listProvince = [
    { label: "กรุงเทพฯ" },
    { label: "ระยอง" },
    { label: "สระบุรี" },
    { label: "ตรัง" },
    { label: "ขอนแก่น" },
    { label: "กระบี่" },
    { label: "นครนายก" },
    { label: "นครปฐม" },
  ];

  useEffect(() => {
    LentCarsService.getLentCarsByProvince(place).then((res) => {
      setListLentCars(res.data);
    });
  }, [place]);

  const onClickFavoriteButton = (id) => {
    if (favorite.includes(id)) {
      setFavorite(favorite.filter((idFavorite) => idFavorite !== id));
    } else {
      setFavorite([...favorite, id]);
    }
  };

  const onClickCard = (idCar) => {
    props.history.push("/friend2friend_Car/borrower/car", {
      idCar: idCar,
      purpose: "lending",
    });
  };

  const onChangeRangePriceHandler = (event) => {
    setRangePrice(event.target.value);
  };

  const onChangeRangeDeliveryHandler = (event) => {
    setRangeDelivery(event.target.value);
  };

  const onClickListsRequestButton = () => {
    props.history.push("/friend2friend_Car/borrower/requestbooking");
  };

  return (
    <div className="page">
      <HeadFilter>
        <DivHeaderLeft>
          <Autocomplete
            className="InputBox"
            disablePortal
            freeSolo
            options={listProvince.map((province) => province.label)}
            onChange={(event, value) => {
              setPlace(value);
              console.log(value);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Where" value={place} />
            )}
          />
          <DivHeaderFilterDateAndTime>
            <div style={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="From Date"
                  value={fromDate}
                  minDate={new Date()}
                  onChange={(date) => {
                    setFromDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField className="InputDate" {...params} />
                  )}
                />
                <SelectTime>
                  <Select native defaultValue="">
                    {[...new Array(48)].map((value, index) => {
                      if (index === 0) {
                        return (
                          <option value="Midnight" key="Midnight">
                            Midnight
                          </option>
                        );
                      } else if (index === 24) {
                        return (
                          <option value="Noon" key="Noon">
                            Noon
                          </option>
                        );
                      } else {
                        let hour = Math.floor((0 + index * 30) / 60);
                        let minute = Math.floor((0 + index * 30) % 60);
                        if (minute < 10) {
                          minute = "0" + minute;
                        }
                        return (
                          <option
                            value={hour + ":" + minute}
                            key={hour + ":" + minute}
                          >
                            {hour + ":" + minute}
                          </option>
                        );
                      }
                    })}
                  </Select>
                </SelectTime>
                <MobileDatePicker
                  label="Until Date"
                  value={untilDate}
                  minDate={new Date()}
                  onChange={(date) => {
                    setUntilDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField className="InputDate" {...params} />
                  )}
                />
                <SelectTime>
                  <Select native defaultValue="">
                    {[...new Array(48)].map((value, index) => {
                      if (index === 0) {
                        return (
                          <option value="Midnight" key="Midnight">
                            Midnight
                          </option>
                        );
                      } else if (index === 24) {
                        return (
                          <option value="Noon" key="Noon">
                            Noon
                          </option>
                        );
                      } else {
                        let hour = Math.floor((0 + index * 30) / 60);
                        let minute = Math.floor((0 + index * 30) % 60);
                        if (minute < 10) {
                          minute = "0" + minute;
                        }
                        return (
                          <option
                            value={hour + ":" + minute}
                            key={hour + ":" + minute}
                          >
                            {hour + ":" + minute}
                          </option>
                        );
                      }
                    })}
                  </Select>
                </SelectTime>
              </LocalizationProvider>
            </div>
            <DivSearchButton>
              <SearchButton aria-label="search">
                <SearchIcon />
              </SearchButton>
              {/* <SearchButton aria-label="search" onClick={onClickSearchButton}><SearchIcon /></SearchButton> */}
              {IpadVerticalResponse && (
                <ListsRequestButton onClick={onClickListsRequestButton}>
                  <i className="fas fa-clipboard-list Icon"></i>รายการคำขอ
                </ListsRequestButton>
              )}
            </DivSearchButton>
          </DivHeaderFilterDateAndTime>
        </DivHeaderLeft>
        {!IpadVerticalResponse && (
          <DivHeaderRight>
            <ListsRequestButton onClick={onClickListsRequestButton}>
              <i className="fas fa-clipboard-list Icon"></i>รายการคำขอ
            </ListsRequestButton>
          </DivHeaderRight>
        )}
      </HeadFilter>
      <Content>
        <DivGridCar>
          <NavFilter>
            <FilterButton label="Sort by">
              <FormControl component="fieldset" style={{ margin: "15px" }}>
                <RadioGroup
                  aria-label="sort"
                  defaultValue="relevance"
                  name="sort"
                >
                  <FormControlLabel
                    value="relevance"
                    control={<Radio />}
                    label="Relevance"
                  />
                  <FormControlLabel
                    value="lowToHight"
                    control={<Radio />}
                    label="Price Low To Hight"
                  />
                  <FormControlLabel
                    value="hightToLow"
                    control={<Radio />}
                    label="Price Hight To Low"
                  />
                </RadioGroup>
              </FormControl>
            </FilterButton>
            <FilterButton label="Price">
              <div style={{ margin: "20px 40px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextFieldStyled value={rangePrice[0]} />
                  <Typography
                    style={{
                      margin: "0 10px",
                      fontSize: "26px",
                      fontWeight: "bold",
                    }}
                  >
                    -
                  </Typography>
                  <TextFieldStyled value={rangePrice[1]} />
                  <Typography style={{ margin: "0 5px", fontSize: "20px" }}>
                    บาท
                  </Typography>
                </div>
                <Slider
                  style={{ width: "100%", marginTop: "10px" }}
                  value={rangePrice}
                  onChange={onChangeRangePriceHandler}
                />
              </div>
            </FilterButton>
            <FilterButton label="Delivery">
              <div style={{ margin: "20px 40px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ margin: "0 5px", fontSize: "20px" }}>
                    Free
                  </Typography>
                  <Typography
                    style={{
                      margin: "0 10px",
                      fontSize: "26px",
                      fontWeight: "bold",
                    }}
                  >
                    -
                  </Typography>
                  <TextFieldStyled value={rangeDelivery} />
                  <Typography style={{ margin: "0 5px", fontSize: "20px" }}>
                    บาท
                  </Typography>
                </div>
                <Slider
                  style={{ width: "100%", marginTop: "10px" }}
                  value={rangeDelivery}
                  onChange={onChangeRangeDeliveryHandler}
                  max={3000}
                />
              </div>
            </FilterButton>
            <FilterButton
              label="More filter"
              icon={<i className="far fa-filter Icon"></i>}
            >
              {/* <h1>LL</h1> */}
            </FilterButton>
          </NavFilter>
          <DivContentGrid>
            <GridContainer container spacing={2}>
              {listLentCars &&
                listLentCars.map((lentCar) => (
                  <Grid item xs={12} sm={6} key={lentCar.id}>
                    <CardStyled>
                      <div onClick={() => onClickCard(lentCar.carId)}>
                        <ImageOfCard>
                          <img
                            className="Image"
                            src="../../assets/Image/example/HondaJazz.jpeg"
                            alt="HondaJazz"
                          />
                        </ImageOfCard>
                        <DetailOfCard>
                          <Typography className="Model" noWrap>
                            {lentCar.model}
                          </Typography>
                          <Typography>address</Typography>
                          <div className="partOfRating">
                            <StarIcon className="IconRating" />
                            <Typography className="Point">
                              {(lentCar.point * 10) % 1 === 0
                                ? lentCar.point.toFixed(1)
                                : lentCar.point.toFixed(2)}
                            </Typography>
                            <Typography>({lentCar.numberBorrowed})</Typography>
                          </div>
                        </DetailOfCard>
                        <PriceOfCard>
                          <Typography className="Price">
                            {utils.numberWithCommas(lentCar.price)} Bath/Day
                          </Typography>
                        </PriceOfCard>
                      </div>
                      <FavoriteButton
                        onClick={() => onClickFavoriteButton(lentCar.carId)}
                      >
                        <FavoriteIcon
                          className={clsx({
                            ["favorited"]: favorite.includes(lentCar.carId),
                          })}
                        />
                      </FavoriteButton>
                    </CardStyled>
                  </Grid>
                ))}
            </GridContainer>
          </DivContentGrid>
        </DivGridCar>
        <Map>
          {/* <img className="Image" src="../../assets/background/exampleMap.png"/> */}
          <GoogleMap
            defaultZoom={11}
            defaultCenter={{ lat: locationSite1.lat, lng: locationSite1.lng }}
            bootstrapURLKeys={{ key: GoogleAPIkey }}
          ></GoogleMap>
        </Map>
      </Content>
    </div>
  );
};

export default FilterPageBorrower;
