import React from "react";

import { styled } from '@mui/styles';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from '@mui/material/Typography';

const DialogStyled = styled(Dialog)({
  borderRadius: "10px",
  margin: "0 auto",
});

const DialogTitleStyled = styled(DialogTitle)({
  borderBottom: "2px  solid black",
  paddingBottom: "0px",
  backgroundColor: "rgb(233,215,0)",
  display: "flex",
  alignItems: "center",
});

const TextHead = styled(Typography)({
  fontSize: "50px",
});

const Text = styled(Typography)(({theme})=>({
  fontSize: "30px",
  color:theme.palette.grey[500],
}));

const ButtonStyled = styled(Button)({
  fontSize: "20px",
  width: "60px",
  height: "50px",
  color: "black",
});

const IconStyled = styled("img")({
  width: "50px",
  height: "50px",
});

const NavButton = styled("div")({
  display:"flex",
  alignItems:"center",
  "& .BackButton": {
    border: "1px solid rgb(100,100,100)",
  },
  "& .ConfirmButton": {
    backgroundColor: "rgb(233,215,0)",
  },
});

const DialogWarning = (props) => {

  return (
    <DialogStyled
      open={props.statusOpen}
      onClose={()=>props.onOpenHandler(false)}
      aria-describedby="Detail of the warning"
      aria-labelledby="Warning of the information"
    >
      <DialogTitleStyled>
        <IconStyled src="./assets/Image/Icon/siren.png" alt="siren" />
        <TextHead>
          คำเตือน
        </TextHead>
      </DialogTitleStyled>
      <DialogContent>
        <Text style={{ marginTop:"10px" }}>
          ข้อมูลที่ให้ต้องเป็นความจริง
        </Text>
      </DialogContent>
      <DialogActions>
        <NavButton>
          <ButtonStyled
            onClick={() => {
              props.onOpenHandler(false);
            }}
            className="BackButton"
          >
            กลับ
          </ButtonStyled>
          <Text style={{ margin: "0 7px" }}>|</Text>
          <ButtonStyled
            className="ConfirmButton"
            onClick={props.OnClickConfirmButton}
          >
            ยืนยัน
          </ButtonStyled>
        </NavButton>
      </DialogActions>
    </DialogStyled>
  );
};

export default DialogWarning;
