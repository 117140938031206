import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Avatar, Typography, Box, Grid, Divider } from "@mui/material";
import Rating from "@mui/material/Rating";
import utils from "../../../../utils";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import NoDrinksIcon from "@mui/icons-material/NoDrinks";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    // border: "1px solid #E0E0E0",
    // borderRadius: "8px",
    // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#fff",
    // margin: "auto",
  },
  avatar: {
    width: "150px",
    height: "150px",
    border: "2px solid #00bb77", // Matches your theme color
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  ratingContainer: {
    display: "flex",
    alignItems: "center",
  },
  ratingText: {
    fontSize: "40px",
    fontWeight: "bold",
    color: "#ffb400",
    marginLeft: "10px",
  },
  driverName: {
    fontSize: "28px",
    fontWeight: "600",
    marginBottom: "10px",
    textAlign: "center",
  },
  companyText: {
    fontSize: "18px",
    fontWeight: "400",
    color: "#6B6B6B",
  },
  infoContainer: {
    width: "50%",
    marginTop: "5px",
  },
  infoText: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#333",
    marginBottom: "10px",
  },
  highlight: {
    fontWeight: "600",
    color: "#00bb77", // Matches the theme color
  },
  divider: {
    margin: "10px 0",
  },
  detailSection: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  extraInfoSection: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  iconSection: {
    display: "flex",
    alignItems: "center",
  },
  iconPositive: {
    color: "green",
    marginRight: "10px",
  },
  iconNegative: {
    color: "red",
    marginRight: "10px",
  },
  iconLabel: {
    fontSize: "18px",
  },
}));

const DetailDriverComponent = (props) => {
  const classes = useStyles();
  const AllDrivers = useSelector((state) => state.drivers);

  return (
    <>
      {props.idUserClicked !== 0 && (
        <div className={classes.container}>
          <Avatar
            className={classes.avatar}
            src={`${process.env.REACT_APP_URL}image/profile/${
              AllDrivers.result[props.idUserClicked - 1].image
            }`}
            alt={AllDrivers.result[props.idUserClicked - 1].image}
          />
          <div className={classes.ratingContainer}>
            <Rating
              value={AllDrivers.result[props.idUserClicked - 1].Rating}
              readOnly
              precision={0.5}
              size="large"
            />
            <Typography className={classes.ratingText}>
              {AllDrivers.result[props.idUserClicked - 1].Rating % 1 === 0
                ? AllDrivers.result[props.idUserClicked - 1].Rating.toFixed(1)
                : AllDrivers.result[props.idUserClicked - 1].Rating.toFixed(2)}
            </Typography>
          </div>
          <Typography className={classes.driverName}>
            {AllDrivers.result[props.idUserClicked - 1].FullName}
          </Typography>
          <Typography className={classes.companyText}>
            {`Tel. ${AllDrivers.result[props.idUserClicked - 1].Telephone}`}
          </Typography>

          <Divider className={classes.divider} />
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Box className={classes.infoContainer}>
              <Typography className={classes.infoText}>
                {`บริษัท ${AllDrivers.result[props.idUserClicked - 1].Company}`}
              </Typography>

              <Typography className={classes.infoText}>
                {`อายุ ${
                  AllDrivers.result[props.idUserClicked - 1].AgeYear
                } ปี ${
                  AllDrivers.result[props.idUserClicked - 1].AgeMonth
                } เดือน`}
              </Typography>

              <Typography className={classes.infoText}>
                {`CostCenter : ${
                  AllDrivers.result[props.idUserClicked - 1].CostCenter
                }`}
              </Typography>
              <Typography className={classes.infoText}>
                รถหลัก{" "}
                <span className={classes.highlight}>
                  {AllDrivers.result[props.idUserClicked - 1].PlateNumCar}
                </span>
              </Typography>
              <div className={classes.iconSection}>
                {/* Smoking Icon */}
                {AllDrivers.result[props.idUserClicked - 1].isSmoking ? (
                  <SmokingRoomsIcon className={classes.iconPositive} />
                ) : (
                  <SmokeFreeIcon className={classes.iconNegative} />
                )}
                <Typography className={classes.iconLabel}>สูบบุหรี่</Typography>
              </div>
            </Box>
            <Box className={classes.infoContainer}>
              <Typography className={classes.infoText}>
                {`ทำงานที่ ${
                  AllDrivers.result[props.idUserClicked - 1].WorkingCompany
                }`}
              </Typography>

              <Typography className={classes.infoText}>
                {`อายุงาน ${
                  AllDrivers.result[props.idUserClicked - 1].AgeWorkingYear
                } ปี ${
                  AllDrivers.result[props.idUserClicked - 1].AgeWorkingMonth
                } เดือน`}
              </Typography>
              <Typography className={classes.infoText}>
                {`CostElement : ${
                  AllDrivers.result[props.idUserClicked - 1].CostElement
                }`}
              </Typography>
              <Typography className={classes.infoText}>
                {`ค่าจ้าง : ${utils.numberWithCommas(
                  AllDrivers.result[props.idUserClicked - 1].Salary
                )} ฿`}
              </Typography>
              <div className={classes.iconSection}>
                {/* Drinking Icon */}
                {AllDrivers.result[props.idUserClicked - 1].isDrinking ? (
                  <LocalBarIcon className={classes.iconPositive} />
                ) : (
                  <NoDrinksIcon className={classes.iconNegative} />
                )}
                <Typography className={classes.iconLabel}>ดื่มสุรา</Typography>
              </div>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailDriverComponent;
