import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import clsx from "clsx";
import driverService from "../../../../../services/driver.service";
import { SnackbarProvider, useSnackbar } from "notistack";

import makeStyles from "@mui/styles/makeStyles";
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    margin: "20px 50px",
    height: "calc(100% - 20px)",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
  },
  BackButton: {
    position: "absolute",
    top: "15px",
    left: "125px",
    ["&:hover"]: {
      backgroundColor: "white",
    },
    ["&:active"]: {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      top: "-30px",
      left: "-40px",
    },
  },
  Title: {
    fontSize: "40px",
    textAlign: "center",
  },
  form: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    flexDirection: "column",
  },
  GridContainer: {
    display: "flex",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    border: "1px solid #EEEEEE",
    backgroundColor: "#FBFCFC",
    marginTop: "10px",
    marginBottom: "20px",
    marginLeft: "0px",
    padding: "0 16px 16px 0",

    borderRadius: "15px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
  GridItem: {
    marginBottom: "5px",
  },
  NavButton: {
    width: "80%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "40px",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "20px 0",
    },
  },
  confirmButton: {
    width: "120px",
    height: "60px",
    backgroundColor: "green",
    color: "white",
    fontSize: "20px",
    borderRadius: "10px",
    ["&:hover"]: {
      backgroundColor: "darkgreen",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  TextDarkBlue: {
    color: theme.palette.primary.dark,
  },
  TextField: {
    ["& .MuiInputBase-fullWidth"]: {
      color: theme.palette.primary.dark,
    },
  },
}));

const FormAddDriver = (props) => {
  const classes = useStyles();
  const AllVehicles = useSelector((state) => state.vehicles);
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      PreName: props.location.state.driver?.PreName,
      FullName: props.location.state.driver?.FullName,
      Telephone: props.location.state.driver?.Telephone,
      AgeYear: props.location.state.driver?.AgeYear,
      AgeMonth: props.location.state.driver?.AgeMonth,
      AgeWorkingYear: props.location.state.driver?.AgeWorkingYear,
      AgeWorkingMonth: props.location.state.driver?.AgeWorkingMonth,
      idVehicle: props.location.state.driver?.idVehicle,
      PlateNumCar: props.location.state.driver?.PlateNumCar,
      Company: props.location.state.driver?.Company,
      WorkingCompany: props.location.state.driver?.WorkingCompany,
      Salary: props.location.state.driver?.Salary,
      CostCenter: props.location.state.driver?.CostCenter,
      CostElement: props.location.state.driver?.CostElement,
      isSmoking: props.location.state.driver?.isSmoking,
      isDrinking: props.location.state.driver?.isDrinking,
    },
  });
  console.log(props.location.state.driver);
  const { enqueueSnackbar } = useSnackbar();

  const onClickBackButton = () => {
    props.history.goBack();
  };
  const onSubmitForm = async (data) => {
    if (props.location.state.isEdit) {
      const response = await driverService.postEditDriver(
        data,
        props.location.state.driver.idDriver
      );
      if (response.request.status === 200) {
        enqueueSnackbar("Driver edited successfully", { variant: "success" });
        props.history.push("/admin/driver");
      } else {
        enqueueSnackbar("Driver edited failed", { variant: "error" });
      }
    } else {
      const response = await driverService.postNewDriver(data);

      if (response.request.status === 200) {
        enqueueSnackbar(
          "Driver added successfully\nUsername: " +
            response.data.username +
            "\nPassword: " +
            response.data.password +
            "",
          { variant: "success" }
        );

        props.history.push("/admin/driver");
      } else {
        enqueueSnackbar("Driver added failed", { variant: "error" });
      }
    }
  };

  return (
    <div className={`page`}>
      <div className={`${classes.DivCoverReturn}`}>
        <Button className={`${classes.BackButton}`} onClick={onClickBackButton}>
          <ArrowBackIosIcon className={`${classes.BackIcon}`} />
          <Typography className={`${classes.TextBackButton}`}>Back</Typography>
        </Button>
        <Typography className={`${classes.Title}`}>Add Driver</Typography>
        <form
          onSubmit={handleSubmit(onSubmitForm)}
          className={`${classes.form}`}
        >
          <Grid container spacing={2} className={`${classes.GridContainer}`}>
            <Grid item xs={12} sm={3} md={2} className={`${classes.GridItem}`}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="prename">คำนำหน้า</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      className={clsx({
                        [classes.TextDarkBlue]:
                          props.location.state.driver?.PreName !==
                          getValues("PreName"),
                      })}
                      label="คำนำหน้า"
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                      helperText={
                        errors && errors.PreName && errors.PreName.message
                      }
                      error={errors && errors.PreName ? true : false}
                    >
                      <MenuItem value={"นาย"}>นาย</MenuItem>
                      <MenuItem value={"นาง"}>นาง</MenuItem>
                      <MenuItem value={"นางสาว"}>นางสาว</MenuItem>
                    </Select>
                  )}
                  name="PreName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณาเลือกคำนำหน้า",
                    },
                  }}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} className={`${classes.GridItem}`}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    className={clsx({
                      [classes.TextField]:
                        props.location.state.driver?.FullName !==
                        getValues("FullName"),
                    })}
                    placeholder="ระบุชื่อ-สกุล"
                    label="ชื่อ-สกุล"
                    variant="outlined"
                    fullWidth
                    value={propsInController.field.value}
                    inputProps={{ pattern: `[\\u0E00-\\u0E7FA-Za-z\\s]{1,30}` }}
                    onChange={propsInController.field.onChange}
                    helperText={
                      errors && errors.FullName && errors.FullName.message
                    }
                    error={errors && errors.FullName ? true : false}
                  />
                )}
                name="FullName"
                rules={{
                  required: {
                    value: true,
                    message: "กรุณากรอกชื่อ-สกุล",
                  },
                }}
                control={control}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={3} md={4} className={`${classes.GridItem}`}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    className={clsx({
                      [classes.TextField]:
                        props.location.state.driver?.Telephone !==
                        getValues("Telephone"),
                    })}
                    placeholder="ระบุเบอร์โทรศัพท์"
                    label="เบอร์โทรศัพท์"
                    variant="outlined"
                    fullWidth
                    value={propsInController.field.value}
                    inputProps={{ pattern: `[0]\\d{2}(?:[\\-]?\\d{3}){2}\\d$` }}
                    onChange={propsInController.field.onChange}
                    helperText={
                      errors && errors.Telephone && errors.Telephone.message
                    }
                    error={errors && errors.Telephone ? true : false}
                  />
                )}
                name="Telephone"
                rules={{
                  required: {
                    value: true,
                    message: "กรุณากรอกเบอร์โทรศัพท์",
                  },
                }}
                control={control}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={3} className={`${classes.GridItem}`}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="AgeYear">อายุ(ปี)</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      className={clsx({
                        [classes.TextDarkBlue]:
                          props.location.state.driver?.AgeYear !==
                          getValues("AgeYear"),
                      })}
                      label="อายุ(ปี)"
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                      helperText={
                        errors && errors.AgeYear && errors.AgeYear.message
                      }
                      error={errors && errors.AgeYear ? true : false}
                    >
                      {[...new Array(48)].map((value, index) => {
                        return (
                          <MenuItem key={value + "" + index} value={index + 18}>
                            {index + 18}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  name="AgeYear"
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณาเลือกอายุ(ปี)",
                    },
                  }}
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} className={`${classes.GridItem}`}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="AgeMonth">อายุ(เดือน)</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      className={clsx({
                        [classes.TextDarkBlue]:
                          props.location.state.driver?.AgeMonth !==
                          getValues("AgeMonth"),
                      })}
                      label="AgeMonth"
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                      helperText={
                        errors && errors.AgeMonth && errors.AgeMonth.message
                      }
                      error={errors && errors.AgeMonth ? true : false}
                    >
                      {[...new Array(12)].map((value, index) => {
                        return (
                          <MenuItem key={value + "" + index} value={index}>
                            {index}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  name="AgeMonth"
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณาเลือกอายุ(เดือน)",
                    },
                  }}
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} className={`${classes.GridItem}`}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="AgeWorkingYear">อายุงาน(ปี)</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      className={clsx({
                        [classes.TextDarkBlue]:
                          props.location.state.driver?.AgeWorkingYear !==
                          getValues("AgeWorkingYear"),
                      })}
                      label="อายุงาน(ปี)"
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                      helperText={
                        errors &&
                        errors.AgeWorkingYear &&
                        errors.AgeWorkingYear.message
                      }
                      error={errors && errors.AgeWorkingYear ? true : false}
                    >
                      {[...new Array(48)].map((value, index) => {
                        return (
                          <MenuItem key={value + "" + index} value={index}>
                            {index}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  name="AgeWorkingYear"
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณาเลือกอายุงาน(ปี)",
                    },
                  }}
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} className={`${classes.GridItem}`}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="AgeWorkingMonth">อายุงาน(เดือน)</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      className={clsx({
                        [classes.TextDarkBlue]:
                          props.location.state.driver?.AgeWorkingMonth !==
                          getValues("AgeWorkingMonth"),
                      })}
                      label="อายุงาน(เดือน)"
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                      helperText={
                        errors &&
                        errors.AgeWorkingMonth &&
                        errors.AgeWorkingMonth.message
                      }
                      error={errors && errors.AgeWorkingMonth ? true : false}
                    >
                      {[...new Array(12)].map((value, index) => {
                        return (
                          <MenuItem key={value + "" + index} value={index}>
                            {index}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  name="AgeWorkingMonth"
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณาเลือกอายุงาน(เดือน)",
                    },
                  }}
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} className={`${classes.GridItem}`}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="Company">สังกัด</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      className={clsx({
                        [classes.TextDarkBlue]:
                          props.location.state.driver?.Company !==
                          getValues("Company"),
                      })}
                      label="Company"
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                      helperText={
                        errors && errors.Company && errors.Company.message
                      }
                      error={errors && errors.Company ? true : false}
                    >
                      <MenuItem value={"ORIX"}>ORIX</MenuItem>
                    </Select>
                  )}
                  name="Company"
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณาเลือกสังกัด",
                    },
                  }}
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} className={`${classes.GridItem}`}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="WorkingCompany">ทำงานบริษัท</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      className={clsx({
                        [classes.TextDarkBlue]:
                          props.location.state.driver?.WorkingCompany !==
                          getValues("WorkingCompany"),
                      })}
                      label="ทำงานบริษัท"
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                      helperText={
                        errors &&
                        errors.WorkingCompany &&
                        errors.WorkingCompany.message
                      }
                      error={errors && errors.WorkingCompany ? true : false}
                    >
                      <MenuItem value="TPE Site 1">TPE Site 1</MenuItem>
                      <MenuItem value="TPE Site 2">TPE Site 2</MenuItem>
                      <MenuItem value="TPE Site 3">TPE Site 3</MenuItem>
                      <MenuItem value="TPE Site 7">TPE Site 7</MenuItem>
                    </Select>
                  )}
                  name="WorkingCompany"
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณาเลือกบริษัทที่ทำงาน",
                    },
                  }}
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} className={`${classes.GridItem}`}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    className={clsx({
                      [classes.TextField]:
                        props.location.state.driver?.Salary !==
                        getValues("Salary"),
                    })}
                    placeholder="ระบุค่าจ้าง"
                    label="ค่าจ้าง"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                    helperText={
                      errors && errors.Salary && errors.Salary.message
                    }
                    error={errors && errors.Salary ? true : false}
                  />
                )}
                name="Salary"
                rules={{
                  required: {
                    value: true,
                    message: "กรุณากรอกค่าจ้าง",
                  },
                }}
                control={control}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={4} className={`${classes.GridItem}`}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="PlateNumCar">รถยนต์หลัก</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      className={clsx({
                        [classes.TextDarkBlue]:
                          props.location.state.driver?.PlateNumCar !==
                          getValues("PlateNumCar"),
                      })}
                      label="รถยนต์หลัก"
                      value={propsInController.field.value}
                      onChange={(e) => {
                        setValue(
                          "idVehicle",
                          AllVehicles.result.find((value) => {
                            return value.Plate_No === e.target.value;
                          }).idVehicle
                        );
                        propsInController.field.onChange(e);
                      }}
                      helperText={
                        errors &&
                        errors.PlateNumCar &&
                        errors.PlateNumCar.message
                      }
                      error={errors && errors.PlateNumCar ? true : false}
                    >
                      {AllVehicles.result.map((value, index) => (
                        <MenuItem key={index} value={value.Plate_No}>
                          {`${value.model} (${value.Plate_No})`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  name="PlateNumCar"
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณาเลือกรถยนต์หลัก",
                    },
                  }}
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} className={`${classes.GridItem}`}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    className={clsx({
                      [classes.TextField]:
                        props.location.state.driver?.CostCenter !==
                        getValues("CostCenter"),
                    })}
                    placeholder="ระบุ cost center"
                    label="Cost Center"
                    variant="outlined"
                    fullWidth
                    inputProps={{ pattern: "\\d{4}[-]\\d{5}$" }}
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                  />
                )}
                name="CostCenter"
                control={control}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={4} className={`${classes.GridItem}`}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    className={clsx({
                      [classes.TextField]:
                        props.location.state.driver?.CostElement !==
                        getValues("CostElement"),
                    })}
                    placeholder="ระบุ cost element"
                    label="Cost Element"
                    variant="outlined"
                    fullWidth
                    inputProps={{ pattern: "\\d{4}[-]\\d{5}$" }}
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                  />
                )}
                name="CostElement"
                control={control}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={6} className={`${classes.GridItem}`}>
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    value="isSmoking"
                    control={<Checkbox />}
                    label="คุณเคยสูบบุหรี่ไหม?"
                    labelPlacement="start"
                  />
                )}
                name="isSmoking"
                control={control}
                defaultValue={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={`${classes.GridItem}`}>
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    value="isDrinking"
                    control={<Checkbox />}
                    label="คุณเคยดื่มเครื่องดื่มแอลกอฮอล์ไหม?"
                    labelPlacement="start"
                  />
                )}
                name="isDrinking"
                control={control}
                defaultValue={false}
              />
            </Grid>
          </Grid>
          <div className={`${classes.NavButton}`}>
            <Button className={`${classes.confirmButton}`} type="submit">
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormAddDriver;
