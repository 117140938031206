import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { styled } from '@mui/styles';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Popover,
    Avatar, Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import LentCarsServices from "../../../../../services/lentCar.service";
import utils from "../../../../../utils";
import ButtonMore from "./buttonMore";

require('dayjs/locale/th');
dayjs.locale('th');

const TableContainerStyled = styled(TableContainer)(({theme})=>({
    overflowX:"auto",
    boxShadow:theme.shadows[3],
    height:"100%",
}));

const TableCellStyled = styled(TableCell)(({theme})=>({
    backgroundColor:theme.palette.primary.main,
    color:theme.palette.primary.contrastText,
    fontSize:"18px",
}));

const TextAndIcon = styled("div")(({theme})=>({
    display:"flex",
    justifyContent:"center",
}));

const AvatarStyled = styled(Avatar)(({theme})=>({
    width:"60px",
    height:"60px",
}));

const Text = styled(Typography)(({theme})=>({
    fontSize:"18px",
}));

const StarIconStyled = styled(StarIcon)(({theme})=>({
    color:theme.palette.warning.light,
    marginRight:"5px",
}));

const ViewButton = styled(Button)(({theme})=>({
    border:"1px solid black",
}));

const TableCars = props => {
    const [listLentCars, setListLentCars] = useState([]);

    useEffect(() => {
        LentCarsServices.getAllLentCars().then((res)=>{
            try {
                if(res.data){
                    setListLentCars(res.data);
                }
            } catch (error) {
                console.log(error)
            }
        })
    }, []);

    const onClickViewButton = () => {
        props.history.push("/admin/detail_lentcar",{ idCar:1, purpose: "view" });
    };

    return(
        <TableContainerStyled component={Paper}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCellStyled style={{ minWidth: "40px" }}></TableCellStyled>
                        <TableCellStyled style={{ minWidth: "130px", width:"230px" }}></TableCellStyled>
                        <TableCellStyled align="center">จังหวัด</TableCellStyled>
                        <TableCellStyled align="center">เจ้าของรถ</TableCellStyled>
                        <TableCellStyled align="center">วันที่ลงทะเบียน</TableCellStyled>
                        <TableCellStyled align="center">การยืม</TableCellStyled>
                        <TableCellStyled align="center">Rating</TableCellStyled>
                        <TableCellStyled></TableCellStyled>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listLentCars.length > 0 && listLentCars.map((lentcar)=>(
                        <TableRow key={lentcar.id}>
                            <TableCell><AvatarStyled src={`${process.env.REACT_APP_URL}image/car/car1.png`}/></TableCell>
                            <TableCell><Text>{lentcar.model}</Text></TableCell>
                            <TableCell align="center"><Text>{lentcar.province}</Text></TableCell>
                            <TableCell align="center"><Text>{`${lentcar.firstnamelender} ${lentcar.lastnamelender}`}</Text></TableCell>
                            <TableCell align="center"><Text>{dayjs(lentcar.registerDate).format("D MMM YYYY")}</Text></TableCell>
                            <TableCell align="center"><Text>{lentcar.numberBorrowed}</Text></TableCell>
                            <TableCell align="center"><TextAndIcon><StarIconStyled /><Text>{utils.pointFixedDecimal(lentcar.point)}</Text></TextAndIcon></TableCell>
                            <TableCell><ButtonMore onClickViewButton={onClickViewButton}/></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainerStyled>
    );
};

export default TableCars;