import {
    PASSENGERS_FETCHING,
    PASSENGERS_FAILED,
    PASSENGERS_SUCCESS
} from "./types";

import CrossAreaCarBookingPassengerService from "../services/crossAreaCarBookingPassenger.service";

export const getCrossAreaCarBookingPassengerByIdBooking = (idBooking) => async (dispatch) => {
    try {
        dispatch({
            type: PASSENGERS_FETCHING,
        });
        const res = await CrossAreaCarBookingPassengerService.getCrossAreaCarBookingPassengerByIdBooking(idBooking);
        if(res){
            dispatch({
                type: PASSENGERS_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: PASSENGERS_FAILED,
        });
    }
}