import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import Container from "@mui/material/Container";

import { styled } from "@mui/styles";

import {
  Grid,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Button,
  Tabs,
  Tab,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import UnicornLoading from "../../assets/loading.gif";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DateAdapter from "@mui/lab/AdapterDayjs";
import { useForm, Controller } from "react-hook-form";
import ButtonBlue from "../../shared/buttonBlue";
import { getCrossAreaCarBookingByIdUser } from "../../../../actions/crossAreaCarBooking";
import { getCrossAreaCarPoolBookingByIdUser } from "../../../../actions/crossAreaCarPoolBooking";
import { getInAreaCarBookingByIdUser } from "../../../../actions/inAreaCarBooking";
import { getDeliveryCarBookingByIdUser } from "../../../../actions/deliveryCarBooking";
import { getMeetingRoomBookingByIdUser } from "../../../../actions/meetingRoomBooking";

import { getAllCrossAreaCarBookingsByFilter } from "../../../../actions/crossAreaCarBooking";
import { getCrossAreaCarPoolBookingByFilter } from "../../../../actions/crossAreaCarPoolBooking";
import { getInAreaCarBookingByFilter } from "../../../../actions/inAreaCarBooking";
import { getMeetingRoomBookingByFilter } from "../../../../actions/meetingRoomBooking";
import { getDeliveryCarBookingByFilter } from "../../../../actions/deliveryCarBooking";

import DriverBookingService from "../../../../services/driverBooking.service";

import CrossArea from "./crossAreaCar";

import CrossAreaPoolCar from "./crossAreaPoolCar";
import InAreaCar from "./inAreaCar";
import MeetingRoomBooking from "./meetingRoom";
import DeliveryCar from "./deliveryCar";
import DriverBooking from "./driver";

import CardRequest from "./cardRequest";
import CardRequestHistory from "./cardRequestHistory";
import {
  getAllDriverBookingByIdUser,
  getDriverBookingByFilterByIdUser,
} from "../../../../actions/driverBooking";

// const StyledRoot = styled("div")(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   marginTop: "20px",
//   paddingLeft: "20px",
//   paddingRight: "20px",
//   [theme.breakpoints.down("md")]: {
//     paddingLeft: "0px",
//     paddingRight: "0px",
//   },
//   "& .ButtonTypeNotClicked": {
//     backgroundColor: "#B2BABB ",
//     color: "white",
//     fontWeight: "bold",
//     ["&:hover"]: {
//       borderBottom: "0px",
//       backgroundColor: "#D5DBDB",
//     },
//   },
//   "& Title": {
//     textAlign: "center",
//     fontSize: "30px",
//     marginBottom: "20px",
//   },
//   "& ButtonType": {
//     width: "180px",
//     minWidth: "180px",
//     height: "50px",
//     fontSize: "16px",
//     borderRadius: "4px 4px 0 0",
//     zIndex: "10",
//     backgroundColor: "#FBFCFC",
//     border: "1px solid #EEEEEE",
//     borderBottom: "0",
//     marginBottom: "-2px",
//     fontWeight: "bold",
//     "&:hover": {
//       borderBottom: "0px",
//       backgroundColor: "#FBFCFC",
//     },
//   },
//   "& NavDiv": {
//     width: "100%",
//     display: "flex",
//     overflowX: "auto",
//     "&::-webkit-scrollbar": {
//       display: "none",
//     },
//     [theme.breakpoints.down("md")]: {
//       width: "80%",
//     },
//   },
//   "& AllCards": {
//     height: "calc(100% - 153px)",
//     padding: "20px",
//     marginBottom: "10px",
//     paddingBottom: "0",
//     paddingTop: "20px",
//     border: "1px solid #EEEEEE",
//     zIndex: "1",
//     backgroundColor: "#FBFCFC",
//   },
//   "& SpaceOfAllCards": {
//     height: "95%",
//     padding: "5px 4px 0px 4px",
//     overflowY: "auto",
//   },
//   "& IconImage": {
//     weight: "26px",
//     height: "26px",
//     marginRight: "3px",
//   },
// }));

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));
const DivCoverReturn = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "20px",

  [theme.breakpoints.down("md")]: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  "& .ButtonTypeNotClicked": {
    backgroundColor: "#B2BABB ",
    color: "white",
    fontWeight: "bold",
    ["&:hover"]: {
      borderBottom: "0px",
      backgroundColor: "#D5DBDB",
    },
  },
}));
const HeaderOfPage = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginBottom: "20px",
  // alignItems: "center",
});
const PartOfContent = styled("div")({
  width: "100%",
  height: "calc(100% - 80px)",
  marginTop: "20px",
  // display: "flex",

  // alignItems: "center",
});

const Title = styled(Typography)({
  // textAlign: "center",
  fontSize: "34px",
  // marginBottom: "20px",
});

const ButtonType = styled(Button)({
  width: "180px",
  minWidth: "180px",
  height: "50px",
  fontSize: "16px",
  borderRadius: "4px 4px 0 0",
  zIndex: "10",
  backgroundColor: "#FBFCFC",
  border: "1px solid #EEEEEE",
  borderBottom: "0",
  marginBottom: "-2px",
  fontWeight: "bold",
  "&:hover": {
    borderBottom: "0px",
    backgroundColor: "#FBFCFC",
  },
});
const ViewButton = styled(Button)(({ theme }) => ({
  width: "180px",
  minWidth: "180px",
  height: "50px",
  fontSize: "15px",
  borderRadius: "10px 10px 10px 10px",
  zIndex: "10",
  backgroundColor: theme.palette.primary.main,
  border: "1px solid #436adc",
  borderBottom: "0",
  marginBottom: "-2px",
  color: "white",
  fontWeight: "bold",
  "&:hover": {
    borderBottom: "0px",
    backgroundColor: theme.palette.primary.dark,
  },
}));

const NavDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginTop: "10px",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const AllCards = styled("div")({
  height: "calc(100% - 153px)",
  padding: "20px",
  marginBottom: "10px",
  paddingBottom: "0",
  paddingTop: "20px",
  border: "1px solid #EEEEEE",
  zIndex: "1",
  backgroundColor: "#FBFCFC",
});
const TabsStyled = styled(Tabs)({
  "& .MuiTab-root": {
    fontSize: "18px",
    fontWeight: "500",
    minWidth: "150px",
    minHeight: "60px",
  },

  // "& .MuiTabs-flexContainer": {
  //   justifyContent: "center",
  // },

  "& .MuiTabs-scroller .PrivateTabIndicator-colorSecondary-34": {
    height: "3px",
  },
});

const TabStyled = styled(Tab)({
  // width: "150px",
  // border: "1px solid black",
  // borderLeft: "0",
  fontSize: "20px",
  height: "60px",
  // "&.Mui-selected": {
  //   borderBottom: "2px solid #000", // สีและความหนาของเส้นใต้
  // },
});
const Image = styled("img")({
  width: "100%",
  height: "80%",
});

const SpaceOfAllCards = styled("div")({
  height: "95%",
  padding: "5px 4px 0px 4px",
  overflowY: "auto",
});
const NavBar = styled("div")({
  display: "flex",
  width: "100%",
});

const MyLists = (props) => {
  const dispatch = useDispatch();
  const { isFetching: isFetchingCrossAreaBookings, result: crossAreaBookings } =
    useSelector((state) => state.crossAreaCar);
  const {
    isFetching: isFetchingCrossAreaPoolBookings,
    result: crossAreaPoolBookings,
  } = useSelector((state) => state.crossAreaCarPool);
  const { isFetching: isFetchingInAreaBookings, result: inAreaBookings } =
    useSelector((state) => state.inAreaCar);
  const { isFetching: isFetchingDeliveryBookings, result: deliveryBookings } =
    useSelector((state) => state.deliveryCar);
  const {
    isFetching: isFetchingMeetingRoomBookings,
    result: meetingRoomBookings,
  } = useSelector((state) => state.meetingRoom);
  const { isFetching: isFetchingDriverBookings, result: driverBookings } =
    useSelector((state) => state.driverBooking);

  const [typeOfBooking, setTypeOfBooking] = useState("CrossArea");
  const [typeOfBookingPath, setTypeOfBookingPath] = useState(
    "cross_area_car_booking"
  );
  const { user: User } = useSelector((state) => state.auth);
  const [showHistory, setHistory] = useState(0);
  const [booking, setBooking] = useState([]);
  const { register, getValues, control } = useForm();

  const [isStartDate, setStartDate] = useState(null);
  const [isEndDate, setEndDate] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let path = SplitPathName();
    setTypeOfBookingPath(path);
    dispatch(getCrossAreaCarBookingByIdUser(User.idUser));
    dispatch(getCrossAreaCarPoolBookingByIdUser(User.idUser));
    dispatch(getInAreaCarBookingByIdUser(User.idUser));
    dispatch(getDeliveryCarBookingByIdUser(User.idUser));
    dispatch(getMeetingRoomBookingByIdUser(User.idUser));
    dispatch(getAllDriverBookingByIdUser(User.idUser));
    if (path === "cross_area_car_booking") {
      setTypeOfBooking("CrossArea");
    } else if (path === "cross_area_car_pool_booking") {
      setTypeOfBooking("CrossAreaPool");
    } else if (path === "in_area_car_booking") {
      setTypeOfBooking("InArea");
    } else if (path === "delivery_car_booking") {
      setTypeOfBooking("Delivery");
    } else if (path === "meeting_room_booking") {
      setTypeOfBooking("MeetingRoom");
    } else if (path === "driver_booking") {
      setTypeOfBooking("DriverBooking");
    }
  }, []);

  useEffect(() => {
    if (typeOfBooking === "CrossArea") {
      if (!crossAreaBookings) {
        dispatch(getCrossAreaCarBookingByIdUser(User.idUser));
      }
    } else if (typeOfBooking === "CrossAreaPool") {
      if (!crossAreaPoolBookings) {
        dispatch(getCrossAreaCarPoolBookingByIdUser(User.idUser));
      }
    } else if (typeOfBooking === "InArea") {
      if (!inAreaBookings) {
        dispatch(getInAreaCarBookingByIdUser(User.idUser));
      }
    } else if (typeOfBooking === "MeetingRoom") {
      if (!meetingRoomBookings) {
        dispatch(getMeetingRoomBookingByIdUser(User.idUser));
      }
    } else if (typeOfBooking === "DriverBooking") {
      if (!driverBookings) {
        dispatch(getAllDriverBookingByIdUser(User.idUser));
      }
    } else {
      if (!deliveryBookings) {
        dispatch(getDeliveryCarBookingByIdUser(User.idUser));
      }
    }
  }, [typeOfBooking, showHistory]);

  const onClickSearch = () => {
    const startDate =
      isStartDate === null
        ? null
        : isStartDate.$d.toLocaleString("af-ZA").slice(0, 10);

    if (typeOfBooking === "CrossArea") {
      dispatch(
        getAllCrossAreaCarBookingsByFilter({
          name: getValues("name"),
          status: getValues("status"),
          startdate: startDate,
          idUser: User.idUser,
        })
      );
    } else if (typeOfBooking === "CrossAreaPool") {
      dispatch(
        getCrossAreaCarPoolBookingByFilter({
          name: getValues("name"),
          status: getValues("status"),
          startdate: startDate,
          idUser: User.idUser,
        })
      );
    } else if (typeOfBooking === "InArea") {
      dispatch(
        getInAreaCarBookingByFilter({
          name: getValues("name"),
          status: getValues("status"),
          startdate: startDate,
          idUser: User.idUser,
        })
      );
    } else if (typeOfBooking === "MeetingRoom") {
      dispatch(
        getMeetingRoomBookingByFilter({
          name: getValues("name"),
          status: getValues("status"),
          startdate: startDate,
          idUser: User.idUser,
        })
      );
    } else if (typeOfBooking === "DriverBooking") {
      dispatch(
        getDriverBookingByFilterByIdUser({
          name: getValues("name"),
          status: getValues("status"),
          startdate: startDate,
          idUser: User.idUser,
        })
      );
    } else {
      dispatch(
        getDeliveryCarBookingByFilter({
          name: getValues("name"),
          status: getValues("status"),
          startdate: startDate,
          idUser: User.idUser,
        })
      );
    }
  };
  const SplitPathName = () => {
    return props.location.pathname.split("/")[2];
  };

  const onClickButtonType = (event, path) => {
    setTypeOfBookingPath(path);
    // console.log(path);
    props.history.push(`/myListOfRequest/${path}`);
    let type;
    if (path === "cross_area_car_booking") {
      type = "CrossArea";
    } else if (path === "cross_area_car_pool_booking") {
      type = "CrossAreaPool";
    } else if (path === "in_area_car_booking") {
      type = "InArea";
    } else if (path === "delivery_car_booking") {
      type = "Delivery";
    } else if (path === "meeting_room_booking") {
      type = "MeetingRoom";
    } else if (path === "driver_booking") {
      type = "DriverBooking";
    }
    // console.log(type);
    setTypeOfBooking(type);
  };

  const onClickCardHandler = (type, booking, idImageBooking) => {
    props.history.push("/myListOfRequest/detail_booking", {
      typeBooking: type,
      idBooking: booking,
      idImageBooking: idImageBooking,
    });
  };
  const onClickHistory = () => {
    setHistory(1);
  };
  const onClickHistoryClose = () => {
    setHistory(0);
  };

  return (
    <Container maxWidth="xl">
      {/* <Grid container spacing={0}> */}
      <Root>
        <DivCoverReturn className="page">
          {/* <HeaderOfPage> */}
          <Grid
            container
            sx={(theme) => ({
              // display: "flex",
              // justifyContent: "space-betceween",

              marginTop: "30px",
              marginBottom: "30px",
              // [theme.breakpoints.down("sm")]: {
              //   display: "grid",
              // },
              // [theme.breakpoints.down("md")]: {
              //   display: "grid",
              // },
            })}
          >
            <Grid item xs={12} sm={6}>
              {showHistory === 0 && <Title>สถานะรายการของฉัน</Title>}
              {showHistory === 1 && <Title>ประวัติการทำรายการของฉัน</Title>}
            </Grid>
            <Grid item xs={12} sm={6} sx={(theme) => ({ textAlign: "right" })}>
              {showHistory === 0 && (
                <ViewButton onClick={onClickHistory}>
                  <img
                    style={{
                      weight: "26px",
                      height: "26px",
                      marginRight: "3px",
                      borderRadius: "5px",
                    }}
                    src={"/assets/Image/Icon/clock_white.png"}
                    alt="Logo"
                  />
                  ประวัติการทำรายการ
                </ViewButton>
              )}
              {showHistory === 1 && (
                <ViewButton onClick={onClickHistoryClose}>
                  <img
                    style={{
                      weight: "26px",
                      height: "26px",
                      marginRight: "3px",
                    }}
                    src={"/assets/Image/Icon/list.png"}
                    alt="Logo"
                  />
                  สถานะรายการ
                </ViewButton>
              )}
            </Grid>
          </Grid>
          {/* <Title>รายการของฉัน</Title> */}

          <Grid
            container
            spacing={3}
            sx={(theme) => ({
              display: "flex",
              // justifyContent: "space-between",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FBFCFC",
              marginLeft: "0",
              marginBottom: "20px",
              padding: "0px 25px 25px 0px",
              // padding: "25px 30px 25px 30px",

              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {
                // padding: "25px 30px 25px 15px",
              },
              [theme.breakpoints.down("md")]: {
                // padding: "25px 30px 25px 15px",
              },
            })}
          >
            <Grid item xs={12} md={6}>
              <TextField
                id="search"
                type="search"
                label="ค้นหาชื่อ"
                inputProps={{ ...register("name") }}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "5px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                <Controller
                  name="date"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <MobileDatePicker
                      {...field}
                      label="วันที่"
                      // clearable
                      inputFormat="DD MMMM YYYY"
                      value={isStartDate}
                      componentsProps={{
                        actionBar: {
                          actions: ["clear"],
                        },
                      }}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{
                            style: {
                              borderRadius: "5px",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                inputProps={{ ...register("status") }}
                label="สถานะ"
                select
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "5px",
                  },
                }}
                defaultValue={"ทั้งหมด"}
              >
                <MenuItem value={"ทั้งหมด"}>ทั้งหมด</MenuItem>
                <MenuItem value={"Waiting"}>Waiting</MenuItem>
                <MenuItem value={"Approved"}>Approved</MenuItem>
              </TextField>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                style={{ marginTop: "10px" }}
              >
                <ButtonBlue
                  variant="contained"
                  onClick={onClickSearch}
                  style={{
                    maxWidth: "200px",
                    maxHeight: "35px",
                    borderRadius: "15px",
                  }}
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </ButtonBlue>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <TabsStyled
              value={typeOfBookingPath}
              onChange={onClickButtonType}
              aria-label="Tab-typeOfBooking"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <TabStyled
                className="classes.TabLeft"
                id="Tab-crossArea"
                value="cross_area_car_booking"
                label="รถข้ามพื้นที่เฉพาะ"
              />
              <TabStyled
                id="Tab-crossAreaPool"
                value="cross_area_car_pool_booking"
                label="รถข้ามพื้นที่Pool"
              />
              <TabStyled
                id="Tab-inArea"
                value="in_area_car_booking"
                label="รถในพื้นที่"
              />
              <TabStyled
                id="Tab-deliveryItem"
                value="delivery_car_booking"
                label="รถรับส่งของ"
              />
              <TabStyled
                id="Tab-meetingRoom"
                value="meeting_room_booking"
                label="จองสถานที่"
              />
              <TabStyled
                id="Tab-driverBooking"
                value="driver_booking"
                label="จองคนขับ"
              />
            </TabsStyled>
          </Grid>
          {/* </HeaderOfPage> */}
          <PartOfContent>
            {/* <AllCards>
              <SpaceOfAllCards> */}

            {typeOfBooking === "CrossArea" && (
              <>
                {!isFetchingCrossAreaBookings ? (
                  <>
                    {crossAreaBookings && crossAreaBookings.length > 0 ? (
                      <CrossArea
                        booking={crossAreaBookings}
                        typeOfBooking={typeOfBooking}
                        onClickCardHandler={onClickCardHandler}
                        showHistory={showHistory}
                      />
                    ) : (
                      <Paper
                        sx={{
                          overflow: "hidden",
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <Typography
                          style={{ textAlign: "center", fontSize: "24px" }}
                        >
                          ไม่มีรายการ
                        </Typography>
                      </Paper>
                    )}
                  </>
                ) : (
                  <Paper
                    sx={{
                      overflow: "hidden",
                      border: "1px solid #EEEEEE",
                      backgroundColor: "#FFFFFF",
                      marginBottom: "20px",
                      padding: "25px 30px 25px 30px",
                      borderRadius: "15px",
                      textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                    }}
                  >
                    <img
                      src={UnicornLoading}
                      alt="Loading..."
                      style={{ width: "300px" }}
                    />
                    {/* ปรับขนาดของภาพตามที่ต้องการ */}
                  </Paper>
                )}
              </>
            )}
            {typeOfBooking === "CrossAreaPool" && (
              <>
                {!isFetchingCrossAreaPoolBookings ? (
                  <>
                    {crossAreaPoolBookings &&
                    crossAreaPoolBookings.length > 0 ? (
                      <CrossAreaPoolCar
                        booking={crossAreaPoolBookings}
                        typeOfBooking={typeOfBooking}
                        onClickCardHandler={onClickCardHandler}
                        showHistory={showHistory}
                      />
                    ) : (
                      <Paper
                        sx={{
                          overflow: "hidden",
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <Typography
                          style={{ textAlign: "center", fontSize: "24px" }}
                        >
                          ไม่มีรายการ
                        </Typography>
                      </Paper>
                    )}
                  </>
                ) : (
                  <Paper
                    sx={{
                      overflow: "hidden",
                      border: "1px solid #EEEEEE",
                      backgroundColor: "#FFFFFF",
                      marginBottom: "20px",
                      padding: "25px 30px 25px 30px",
                      borderRadius: "15px",
                      textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                    }}
                  >
                    <img
                      src={UnicornLoading}
                      alt="Loading..."
                      style={{ width: "300px" }}
                    />
                    {/* ปรับขนาดของภาพตามที่ต้องการ */}
                  </Paper>
                )}
              </>
            )}

            {typeOfBooking === "InArea" && (
              <>
                {!isFetchingInAreaBookings ? (
                  <>
                    {inAreaBookings && inAreaBookings.length > 0 ? (
                      <InAreaCar
                        booking={inAreaBookings}
                        typeOfBooking={typeOfBooking}
                        onClickCardHandler={onClickCardHandler}
                        showHistory={showHistory}
                      />
                    ) : (
                      <Paper
                        sx={{
                          overflow: "hidden",
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <Typography
                          style={{ textAlign: "center", fontSize: "24px" }}
                        >
                          ไม่มีรายการ
                        </Typography>
                      </Paper>
                    )}
                  </>
                ) : (
                  <Paper
                    sx={{
                      overflow: "hidden",
                      border: "1px solid #EEEEEE",
                      backgroundColor: "#FFFFFF",
                      marginBottom: "20px",
                      padding: "25px 30px 25px 30px",
                      borderRadius: "15px",
                      textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                    }}
                  >
                    <img
                      src={UnicornLoading}
                      alt="Loading..."
                      style={{ width: "300px" }}
                    />
                    {/* ปรับขนาดของภาพตามที่ต้องการ */}
                  </Paper>
                )}
              </>
            )}
            {typeOfBooking === "Delivery" && (
              <>
                {!isFetchingDeliveryBookings ? (
                  <>
                    {deliveryBookings && deliveryBookings.length > 0 ? (
                      <DeliveryCar
                        booking={deliveryBookings}
                        typeOfBooking={typeOfBooking}
                        onClickCardHandler={onClickCardHandler}
                        showHistory={showHistory}
                      />
                    ) : (
                      <Paper
                        sx={{
                          overflow: "hidden",
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <Typography
                          style={{ textAlign: "center", fontSize: "24px" }}
                        >
                          ไม่มีรายการ
                        </Typography>
                      </Paper>
                    )}
                  </>
                ) : (
                  <Paper
                    sx={{
                      overflow: "hidden",
                      border: "1px solid #EEEEEE",
                      backgroundColor: "#FFFFFF",
                      marginBottom: "20px",
                      padding: "25px 30px 25px 30px",
                      borderRadius: "15px",
                      textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                    }}
                  >
                    <img
                      src={UnicornLoading}
                      alt="Loading..."
                      style={{ width: "300px" }}
                    />
                    {/* ปรับขนาดของภาพตามที่ต้องการ */}
                  </Paper>
                )}
              </>
            )}
            {typeOfBooking === "MeetingRoom" && (
              <>
                {!isFetchingMeetingRoomBookings ? (
                  <>
                    {meetingRoomBookings && meetingRoomBookings.length > 0 ? (
                      <MeetingRoomBooking
                        booking={meetingRoomBookings}
                        typeOfBooking={typeOfBooking}
                        onClickCardHandler={onClickCardHandler}
                        showHistory={showHistory}
                      />
                    ) : (
                      <Paper
                        sx={{
                          overflow: "hidden",
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <Typography
                          style={{ textAlign: "center", fontSize: "24px" }}
                        >
                          ไม่มีรายการ
                        </Typography>
                      </Paper>
                    )}
                  </>
                ) : (
                  <Paper
                    sx={{
                      overflow: "hidden",
                      border: "1px solid #EEEEEE",
                      backgroundColor: "#FFFFFF",
                      marginBottom: "20px",
                      padding: "25px 30px 25px 30px",
                      borderRadius: "15px",
                      textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                    }}
                  >
                    <img
                      src={UnicornLoading}
                      alt="Loading..."
                      style={{ width: "300px" }}
                    />
                    {/* ปรับขนาดของภาพตามที่ต้องการ */}
                  </Paper>
                )}
              </>
            )}
            {typeOfBooking === "DriverBooking" && (
              <>
                {!isFetchingDriverBookings ? (
                  <>
                    {driverBookings && driverBookings.length > 0 ? (
                      <DriverBooking
                        booking={driverBookings}
                        typeOfBooking={typeOfBooking}
                        onClickCardHandler={onClickCardHandler}
                        showHistory={showHistory}
                      />
                    ) : (
                      <Paper
                        sx={{
                          overflow: "hidden",
                          border: "1px solid #EEEEEE",
                          backgroundColor: "#FFFFFF",
                          marginBottom: "20px",
                          padding: "25px 30px 25px 30px",
                          borderRadius: "15px",
                          textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                        }}
                      >
                        <Typography
                          style={{ textAlign: "center", fontSize: "24px" }}
                        >
                          ไม่มีรายการ
                        </Typography>
                      </Paper>
                    )}
                  </>
                ) : (
                  <Paper
                    sx={{
                      overflow: "hidden",
                      border: "1px solid #EEEEEE",
                      backgroundColor: "#FFFFFF",
                      marginBottom: "20px",
                      padding: "25px 30px 25px 30px",
                      borderRadius: "15px",
                      textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                    }}
                  >
                    <img
                      src={UnicornLoading}
                      alt="Loading..."
                      style={{ width: "300px" }}
                    />
                    {/* ปรับขนาดของภาพตามที่ต้องการ */}
                  </Paper>
                )}
              </>
            )}
          </PartOfContent>
        </DivCoverReturn>
      </Root>
      {/* </Grid> */}
    </Container>
  );
};

export default MyLists;
