import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "@mui/material/Button";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Typography, styled } from "@mui/material";
import { Grid, TextField } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import DeleteIcon from "@mui/icons-material/Delete";
import MeetingRoomService from "../../../../../../services/meetingRoom.service";

import Dropfile from "../../../../shared/Form/DropSingleFile/index";
import GoogleMap from "../../../../shared/googleWithAutoComplete/GoogleMap.js";

const StyledRoot = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",

    width: "100%",
  },
  "& .divCoverReturn": {
    width: "100%",
    display: "flex",
    justifyContent: "center",

    paddingTop: "70px",
    height: "calc(100% - 30px)",
  },
  "& .Title": {
    textAlign: "center",
    fontSize: "35px",
    fontWeight: "bold",
    // margin: "25px 0px",
  },
  "& .Grid": {
    width: "100%",
    minWidth: "1650px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
      minWidth: "150px",
      // flexDirection:"column",
    },
  },
  "& .form": {
    borderRadius: "15px",
  },
  "& .redIcon": {
    color: "inherit",
    "&:hover": {
      color: "red",
    },
  },
  "& .Header": {
    display: "flex",
    alignItems: "center",
  },
  "& .gridContainer": {
    padding: "10px",
  },
  "& .IconImage": {
    weight: "24px",
    height: "24px",
    marginRight: "5px",
  },
  "& .NavButton": {
    width: "80%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "20px 20px",
    },
  },
  "& .confirmButton": {
    width: "120px",
    height: "60px",
    backgroundColor: "green",
    color: "white",
    fontSize: "20px",
    borderRadius: "10px",
    ["&:hover"]: {
      backgroundColor: "darkgreen",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const AdminNewMeetingRoomBookingForm = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [fields, setFields] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [dataPlace, setDataPlace] = useState("");

  const onSubmitHandler = async (data) => {
    console.log(data);
    console.log(fields);
    const formData = new FormData();
    formData.append("fields", fields);
    formData.append("data", JSON.stringify(data));
    data.attachment?.map((file, index) => {
      formData.append(`attachment`, file);
    });

    console.log("attachment", formData.get("attachment"));

    MeetingRoomService.postNewMeetingRoom(formData).then((res) => {
      if (res.status === 200) {
        props.history.push(`/admin/meetingRoom_page`);
        enqueueSnackbar("Add new meeting room successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Add new meeting room failed", {
          variant: "error",
        });
      }
    });
  };

  const handleAddField = () => {
    setFields([...fields, ""]);
  };

  const handleRemoveField = (index) => {
    const filteredFields = fields.filter((_, i) => i !== index);
    setFields(filteredFields);
  };

  const getDataFromGoogleMapPlace = (data) => {
    setDataPlace(data);
    setValue("place", data);
  };
  const onClickGoBack = () => {
    props.history.push("/admin/meetingRoom_page");
  };
  return (
    <StyledRoot>
      <div className="divCoverReturn">
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="Header">
                <BackButton disableRipple onClick={onClickGoBack}>
                  <ArrowBackIcon
                    className="Icon"
                    style={{ color: "#99A8B2" }}
                  />
                </BackButton>
                <Typography fontSize={32} fontWeight={500} className="Title">
                  เพิ่มสถานที่
                </Typography>
              </div>
            </Grid>

            <Grid
              container
              item
              spacing={3}
              xs={12}
              sx={(theme) => ({
                display: "flex",
                boxShadow:
                  "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                marginTop: "10px",
                marginBottom: "20px",
                marginLeft: "0px",
                padding: "0 25px 25px 0",

                borderRadius: "15px",
                [theme.breakpoints.down("sm")]: {},
                [theme.breakpoints.down("md")]: {},
              })}
            >
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextField
                  label="ชื่อสถานที่"
                  variant="outlined"
                  fullWidth
                  inputProps={{ ...register("nameMeetingRoom") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        label="สถานที่"
                        error={errors.place}
                        helperText={errors.place && "กรุณากรอกสถานที่"}
                        fullWidth
                        disabled
                        value={propsInController.field.value}
                        onChange={propsInController.field.onChange}
                        InputProps={{
                          style: {
                            borderRadius: "15px",
                          },
                        }}
                      />
                    )}
                    name="place"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                  />
                </Grid>

                <Grid item xs={12} sm={12} style={{ height: "400px" }}>
                  <GoogleMap
                    name="place"
                    // inputProps={{
                    //   ...setValue("place", dataPlace),
                    // }}
                    value={dataPlace}
                    handleGetDataFromGoogleMap={getDataFromGoogleMapPlace}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextField
                  label="จำนวนคน"
                  variant="outlined"
                  fullWidth
                  type="number"
                  inputProps={{ ...register("numberOfPeople") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextField
                  label="ราคา ต่อ ชั่วโมง"
                  variant="outlined"
                  type="number"
                  fullWidth
                  inputProps={{ ...register("price") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextField
                  label="ราคา ต่อ วัน"
                  variant="outlined"
                  type="number"
                  fullWidth
                  inputProps={{ ...register("priceAllDay") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="รายละเอียด"
                  variant="outlined"
                  multiline
                  rows={3}
                  fullWidth
                  inputProps={{ ...register("detail") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label="ชื่อผู้จัดการ"
                  variant="outlined"
                  fullWidth
                  inputProps={{ ...register("nameApproved") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label="เบอร์ติดต่อ"
                  variant="outlined"
                  fullWidth
                  inputProps={{ ...register("phone") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  inputProps={{ ...register("email") }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography>เพิ่มสิ่งอำนวยความสะดวก</Typography>
                </Grid>
                <Grid container item spacing={1}>
                  <Grid item xs={12} spacing={2}>
                    {fields.map((field, index) => (
                      <Grid
                        style={{ display: "flex", marginBottom: "10px" }}
                        spacing={2}
                      >
                        <Grid item xs={12} md={12} lg={12}>
                          <TextField
                            key={index}
                            value={field}
                            fullWidth
                            InputProps={{
                              style: {
                                borderRadius: "15px",
                              },
                            }}
                            onChange={(e) => {
                              const updatedFields = [...fields];
                              updatedFields[index] = e.target.value;
                              setFields(updatedFields);
                            }}
                          />
                        </Grid>
                        <IconButton onClick={() => handleRemoveField(index)}>
                          <DeleteIcon className="redIcon" />
                        </IconButton>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    variant="contained"
                    onClick={handleAddField}
                    style={{ width: "200px" }}
                  >
                    <img
                      className="IconImage"
                      src={"/assets/Image/Icon/plus.png"}
                      alt="Logo"
                    />
                    เพิ่มรายการ
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Grid item xs={4} md={4} lg={7}>
                  <Typography>เพิ่มไฟล์</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Dropfile
                    title="แนบไฟล์ (ไม่เกิน 10 MB ต่อไฟล์ สูงสุด 5 ไฟล์)"
                    name="attachment"
                    setValue={setValue}
                  />
                </Grid>
              </Grid>
              {/*
              
              <Grid item xs={12}>
                <FromApprove
                  register={register}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                />
              </Grid>

              

              */}
            </Grid>
            {/* </Grid> */}

            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div className="NavButton">
                <Button className="confirmButton" type="submit">
                  ยืนยัน
                </Button>
              </div>
            </Grid>
            {/* </div> */}
          </Grid>
        </form>
      </div>
    </StyledRoot>
  );
};
export default AdminNewMeetingRoomBookingForm;
