import React, { useState } from 'react';
import { Controller } from "react-hook-form";

import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/styles';
import {
     Button, Typography, Modal, Paper, Card, TextField, FormControl, Select, 
     MenuItem, IconButton, InputLabel,
} from "@mui/material";

import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';

import { listExtras } from "../../../choise";

const useStyles = makeStyles((theme)=>({
    Content:{
        width:"95%",
        height:"calc(100% - 40px)",
        padding:"20px 0",
    },
    Box:{
        width:"100%",
        height:"70px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        border:"1px solid black",
    },
    NextButton:{
        width:"fit-content",
        color:"green",
        fontSize:"18px",
        "&:hover":{
            backgroundColor:"white",
        }
    },
}));

const Title = styled(Typography)(({theme})=>({
    fontSize:"20px",
}));

const ExtrasContent = styled("div")(({theme})=>({
    width:"100%",
    overflowX:"auto",
    "& .ContentCardExtras":{
        display:"flex",
        flexWrap:"wrap",
        "& .CardExtra":{
            width:"250px",
            minWidth:"250px",
            height:"70px",
            margin:"10px",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
        },
    }
}));

const ListExtrasContent = styled("div")(({theme})=>({
    marginTop:"20px",
    "& .List":{
        display:"flex",
        alignItems:"center",
        "& .dot":{
            fontSize:"10px",
            marginRight:"10px",
        },
        "& .Text":{
            fontSize:"18px",
        },
    },
}));

const ModalStyles = styled(Modal)(({theme})=>({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    "& .PaperModal":{
        width:"400px",
        padding:"20px",
        "& .TextField":{
            marginTop:"10px",
        },
        "& .NavButton":{
            display:"flex",
            justifyContent:"flex-end",
            marginTop:"10px",
            "& .ConfirmButton":{
                fontSize:"18px",
                backgroundColor:theme.palette.success.main,
                color:theme.palette.primary.contrastText,
            },
        },
    },
}));

const DurationSelect = styled("div")(({theme})=>({
    display:"flex",
    alignItems:"center",
    marginTop:"20px",
    "& .DurationText":{
        fontSize:"18px",
        width:"100px",
    },
}));

const WarningText = styled(Typography)(({theme})=>({
    fontSize:"18px",
    color:theme.palette.error.main,
}));

const DeleteIconButton = styled(IconButton)(({theme})=>({
    width:"20px",
    height:"20px",
    marginLeft:"10px",
    "&:hover":{
        backgroundColor:"white",
    },
    "& .Icon":{
        fontSize:"20px",
        fontWeight:"bold",
        color:theme.palette.error.main,
        "&:hover":{
            color:theme.palette.error.dark,
        },
    },
}));

const ApprovalNeedDiv = styled("div")(({theme})=>({
    marginTop:"20px",
}));

const SubmitButton = styled(Button)(({theme})=>({
    marginLeft:"10px",
    backgroundColor:theme.palette.success.main,
    color:theme.palette.success.contrastText,
    fontSize:"18px",
    "&:hover":{
        backgroundColor:theme.palette.success.dark,
    },
}));

const FormControlApprovalNeed = styled(FormControl)(({theme})=>({
    width:"300px",
    [theme.breakpoints.down('sm')]:{
        width:"100%"
    }
}));

const ExtrasForm = props => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [extraClicked, setExtraClicked] = useState(null);
    const [price, setPrice] = useState("");
    const [duration, setDuration] = useState("");

    const onClickExtraCard = (extra) => {
        setOpenModal(true);
        setExtraClicked(extra);
    };

    const onClickConfirmButton = () => {
        props.setListExtrasSetPrice([...props.listExtrasSetPrice,{ extra:extraClicked.name, price:price, duration:duration}]);
        setPrice("");
        setDuration("");
        setOpenModal(false);
    };

    const onClickDeleteButton = (extraTarget) => {
        props.setListExtrasSetPrice([...props.listExtrasSetPrice.filter((extra) => extra.extra !== extraTarget )]);
    }

    return(
        <div className={`${classes.Content}`}>
            <div className={`${classes.partOfBoxExtras}`}>
                <Title>Extras</Title>
                <ExtrasContent>
                    <div className="ContentCardExtras" style={{width:Math.ceil(listExtras.length/2)*270}}>
                        {listExtras.map((extra)=>(
                            <Card key={extra.id} className="CardExtra" onClick={()=>onClickExtraCard(extra)}>
                                {extra.name}
                            </Card>
                        ))}
                    </div>
                </ExtrasContent>
            </div>
            <ListExtrasContent>
                { props.listExtrasSetPrice.length > 0 && props.listExtrasSetPrice.map((listExtra)=>(
                    <div className="List" key={listExtra.extra}>
                        <CircleIcon className="dot"/>
                        <Typography className="Text">{`${listExtra.extra} ${listExtra.price} บาท/${listExtra.duration}`}</Typography>
                        <DeleteIconButton onClick={()=>onClickDeleteButton(listExtra.extra)}>
                            <CloseIcon className="Icon" />
                        </DeleteIconButton>
                    </div>
                ))}
            </ListExtrasContent>
            <ApprovalNeedDiv>
                <FormControlApprovalNeed variant="outlined">
                    <InputLabel id="ApprovalNeed">Approval Need</InputLabel>
                    <Controller 
                        control={props.control}
                        name="approvalneed"
                        defaultValue=""
                        render={({field})=>(
                            <Select
                                {...field}
                                labelId="ApprovalNeed-label"
                                id="ApprovalNeed"
                                label="Approval Need"
                            >
                                <MenuItem value="ไม่ต้องรอรับการอนุมัติ">ไม่ต้องรอรับการอนุมัติ</MenuItem>
                                <MenuItem value="รอรับการอนุมัติ">รอรับการอนุมัติ</MenuItem>
                            </Select>
                        )}
                    />
                </FormControlApprovalNeed>
            </ApprovalNeedDiv>
            <ModalStyles
                open={openModal}
                onClose={()=>setOpenModal(false)}
                aria-labelledby="setPrice-modal-title"
                aria-describedby="setPrice-modal-description"
            >
                <Paper className="PaperModal">
                    { extraClicked && (
                        <>
                            <Title>{extraClicked.name}</Title>
                            <TextField
                                className="TextField"
                                fullWidth
                                placeholder="ราคา(บาท)"
                                value={price}
                                onChange={(event)=>setPrice(event.target.value)}
                            />
                            <DurationSelect>
                                <Typography className="DurationText">ระยะเวลา</Typography>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="duration-select-label"
                                        id="duration-select"
                                        value={duration}
                                        onChange={(event)=>{setDuration(event.target.value)}}
                                    >
                                        <MenuItem value="วัน">วัน</MenuItem>
                                        <MenuItem value="การเดินทาง">การเดินทาง</MenuItem>
                                    </Select>
                                </FormControl>
                            </DurationSelect>
                        </>
                    )}
                    <div className="NavButton">
                        { props.listExtrasSetPrice.find((extra) => extra.extra === extraClicked ) ?
                            <WarningText>
                                มีอยู่ในรายการอยู่แล้ว
                            </WarningText>
                            :
                            <Button 
                                className="ConfirmButton"
                                onClick={onClickConfirmButton}
                            >
                                Confirm
                            </Button>
                        }
                    </div>
                </Paper>
            </ModalStyles>
            <div style={{width:"100%",display:"flex",justifyContent:"flex-end",padding:"20px 0"}}>
                <Button className={`${classes.NextButton}`} onClick={props.onClickBackProcess}>Back</Button>
                <SubmitButton onClick={props.onClickSubmit}>Submit</SubmitButton>
            </div>
        </div>
    );
};

export default ExtrasForm;