import { httpClient } from "./httpClient";

const getUserProfile = (id) => {
  return httpClient.get("users/profile/" + id);
};

const getDriverProfile = (id) => {
  return httpClient.get("driver/profile/" + id);
};

const getMyUserInfo = () => {
  return httpClient.get("users/me/info");
};

const getAllUsers = () => {
  return httpClient.get("users");
};

const getAllDriver = () => {
  return httpClient.get("users/drivers");
};

const getAllManager = () => {
  return httpClient.get("users/manager");
};

const updateLocationUser = (newdata) => {
  return httpClient.post("update_Location_User", newdata);
};

export default {
  getMyUserInfo,
  getUserProfile,
  getDriverProfile,
  getAllUsers,
  getAllDriver,
  updateLocationUser,
  getAllManager,
};
