import React, { useState } from "react";
import { Link } from "react-router-dom";
import { styled, Typography, Grid, Stack, Button, Avatar } from "@mui/material";
import GridCard from "../../../../../components/pages/shared/GridCard";
import dayjs from "dayjs";
import "dayjs/locale/th";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ConfirmApproveModal from "./ConfirmApproveModal";
import ConfirmDisApproveModal from "./ConfirmDisApproveModal";
import StatusRequest from "../../../shared/StatusRequest";

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "80px",
  height: "80px",
  [theme.breakpoints.down("lg")]: {
    width: "120px",
    height: "120px",
  },
}));

const GirdContent = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
    margin: "2.5px 0",
  },
}));
const Text = styled(Typography)({
  fontSize: "15px",
  fontWeight: 500,
});

const HighlightText = styled(Typography)({
  fontSize: "15px",
});
const HighlightTextName = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
});

const TextWithIcon = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  justifyContent: "center",
  "& .Icon": {
    marginRight: "5px",
    color: "#293A80",
  },
  "& .LocationIcon": {
    marginRight: "5px",
    color: theme.palette.error.main,
  },
  "& .CalendarIcon": {
    marginRight: "5px",
    color: theme.palette.primary.main,
  },
}));

const CateringRequestedCard = ({
  data,
  onChangeStatusHandler,
  index,
  onClickCard,
}) => {
  const [openConfirmApproveModal, setOpenConfirmApproveModal] = useState(false);
  const [openConfirmDisApproveModal, setOpenConfirmDisApproveModal] =
    useState(false);

  return (
    <Grid item xs={12} sm={6} md={4} lg={12}>
      <GridCard>
        <Grid
          container
          item
          xs={12}
          md={12}
          lg={10.2}
          onClick={() => {
            onClickCard(data.idCateringRequest);
          }}
        >
          <GirdContent item xs={12} md={12} lg={1.5}>
            <AvatarStyled
              src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
              alt="1"
            />
          </GirdContent>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={2.4}
            sx={(theme) => ({
              textAlign: "left",
            })}
          >
            <Grid item xs={12} md={12} lg={12}>
              <HighlightTextName>{data.name}</HighlightTextName>
              <Text>{`Tel. ${data.phoneNumber}`}</Text>
            </Grid>
          </GirdContent>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={2.5}
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextWithIcon>
                <LocationOnRoundedIcon className="LocationIcon" />
                <Text>สถานที่จัดเลี้ยง</Text>
              </TextWithIcon>
              <HighlightText>{data.sendTo}</HighlightText>
            </Grid>
          </GirdContent>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={2.2}
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextWithIcon>
                <CalendarMonthRoundedIcon className="CalendarIcon" />
                <Text>วันที่จัดเลี้ยง</Text>
              </TextWithIcon>
              <HighlightText>
                {dayjs(data.date).locale("th").format("DD MMM YYYY")}
              </HighlightText>
            </Grid>
          </GirdContent>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={2.2}
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextWithIcon>
                <PeopleAltRoundedIcon className="Icon" />
                <Text>จำนวนคน</Text>
              </TextWithIcon>
              <HighlightText>{data.numberOfPeople}</HighlightText>
            </Grid>
          </GirdContent>

          <GirdContent
            item
            xs={12}
            md={12}
            lg={1.2}
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <Text>สถานะ</Text>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {data.status === "approved" && (
                  <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={1}
                    active={1}
                  />
                )}
                {data.status === "pending" && (
                  <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={null}
                    step={null}
                    active={1}
                  />
                )}
                {data.status === "disapproved" && (
                  <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={0}
                    active={1}
                  />
                )}
              </Grid>
            </Grid>
          </GirdContent>
        </Grid>

        <Grid container item xs={12} md={12} lg={1.8}>
          <GirdContent
            item
            xs={12}
            md={12}
            lg={12}
            sx={{ textAlign: "center" }}
          >
            {data.status === "pending" && (
              <Grid item xs={12} md={12} lg={12}>
                <Button
                  className="cardButton"
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "#4BB543", // สีเขียว
                    "&:hover": {
                      backgroundColor: "#43A047", // สีเขียวเข้มขึ้นเมื่อ hover
                    },
                  }}
                  size="small"
                  style={{
                    minWidth: "40px", // Adjust the size as needed
                    height: "40px", // Make it a square to ensure it's circular
                    borderRadius: "50%", // Make the button circular
                    padding: "0",
                    margin: "0 5px",
                  }}
                  onClick={() => {
                    setOpenConfirmApproveModal(true);
                  }}
                >
                  <i className="far fa-check" style={{ fontSize: "20px" }}></i>
                </Button>
                <Button
                  className="cardButton"
                  variant="contained"
                  color="error"
                  size="small"
                  style={{
                    minWidth: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    padding: "0",
                    margin: "0 5px",
                  }}
                  onClick={() => {
                    setOpenConfirmDisApproveModal(true);
                  }}
                >
                  <i className="far fa-times" style={{ fontSize: "20px" }}></i>
                </Button>
              </Grid>
            )}
          </GirdContent>
        </Grid>

        {openConfirmApproveModal && (
          <ConfirmApproveModal
            open={openConfirmApproveModal}
            onClose={() => {
              setOpenConfirmApproveModal(false);
            }}
            onChangeStatusHandler={onChangeStatusHandler}
            index={data.idCateringRequest}
          />
        )}
        {openConfirmDisApproveModal && (
          <ConfirmDisApproveModal
            open={openConfirmDisApproveModal}
            onClose={() => {
              setOpenConfirmDisApproveModal(false);
            }}
            onChangeStatusHandler={onChangeStatusHandler}
            index={data.idCateringRequest}
          />
        )}
      </GridCard>
    </Grid>
  );
};

export default CateringRequestedCard;
