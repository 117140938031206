import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/styles";
import {
  Typography,
  Button,
  Divider,
  ButtonGroup,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  List,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  CircularProgress,
  Avatar,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Rating from "@mui/material/Rating";
import GoogleMap from "google-map-react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { ErrorOutline } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";

import { getRouteLine, getUserRoute } from "../../../../actions/routeDay";
import {
  getRouteLineByIdUserRouteDate,
  getRouteUserByIdUser,
} from "../../../../actions/routeCrossAreaCarPool";
import { getUserProfile } from "../../../../actions/user";
import { getAllEmployees } from "../../../../actions/employee";
import RouteTodayServices from "../../../../services/routeToday.service";
import RouteCrossAreaCarPoolService from "../../../../services/routeCrossAreaCarPool.service";
import { GoogleAPIkey } from "../../../../utils/googleMapAssets";
import utils from "../../../../utils/index";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles({
  root: {
    margin: "auto",
    marginTop: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "350px",
  },
  imageProfile: {
    borderRadius: "50%",
    margin: "0 10px",
  },
  infoProfile: {
    display: "flex",
    width: "100%",
  },
  infoTxtProfile: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  trackView: {
    marginTop: 10,
    marginBottom: 20,
    width: "100%",
  },
  tagShift: { marginTop: 5 },
  addressProfile: {
    marginTop: 10,
    textalign: "left",
    padding: "0 10px",
  },
  estimatedTime: {
    textAlign: "center",
    flexGrow: 1,
  },
  mapProfile: {
    height: 320,
    width: "100%",
    marginTop: 10,
  },
  btnViewFragment: {
    margin: "15px 0",
  },
  fragmentFriend: {
    overflow: "auto",
    height: 360,
  },
});

const RatingButton = styled(Button)(({ theme }) => ({
  width: "175px",
  backgroundColor: theme.palette.warning.light,
  color: theme.palette.warning.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.warning.light,
  },
}));

const DashboardUser = (props) => {
  const dispatch = useDispatch();

  const { user: AuthUser } = useSelector((state) => state.auth);
  const { result: routeLineReducer } = useSelector((state) => state.routeLine);
  const { result: userRouteReducer } = useSelector((state) => state.routeUser);
  /* const { result : userRouteReducer } = useSelector((state)=>state.userProfile); */
  /* const userRouteReducer = useSelector(({ userRouteReducer }) => userRouteReducer); */
  const userDriverReducer = useSelector(
    ({ userDriverReducer }) => userDriverReducer
  );
  const userVehicleReducer = useSelector(
    ({ userVehicleReducer }) => userVehicleReducer
  );
  /* const routeLineReducer = useSelector(({ routeLineReducer }) => routeLineReducer); */
  const employeeReducer = useSelector((state) => state.employees);

  // const [routeLineReducer, setRouteLineReducer] = React.useState(null);
  // const [userRouteReducer, setUserRouteReducer] = React.useState(null);
  const [viewArrive, setviewArrive] = React.useState("map");
  const [activeStep, setActiveStep] = React.useState(0);
  const [openEmergency, setOpenEmergency] = React.useState(false);
  const [searchEmployee, setSearchEmployee] = React.useState("");
  const [selectEmp, setSelectEmp] = React.useState(null);

  const classes = useStyles();

  //const handleNext = () => {
  //  setActiveStep(prevActiveStep => prevActiveStep + 1);
  //};

  useEffect(() => {
    //dispatch(userActions.getUserProfile(props.history))
    /* dispatch(routeActions.getUserRoute()); */
    /* console.log(AuthUser); */
    let pathname = props.location.pathname.split("/")[1];
    console.log(pathname);
    if (pathname === "transparent_ShiftCar") {
      dispatch(getUserProfile(AuthUser.id));
      dispatch(getAllEmployees());
      let data = {
        idUser: 2092,
        routeDate: "2022-08-27",
      };
      dispatch(getRouteLine(data));
      dispatch(getUserRoute(2092));
      // RouteTodayServices.getUserRoute(2092).then((res)=>{
      //     console.log("res.data : ",res.data);
      //     // setUserRouteReducer(res.data);
      // }).catch((error) => {
      //     console.log(error)
      // });
    } else if (pathname === "myCrossAreaCarPool") {
      if (props.location.state) {
        let data = {
          idUser: AuthUser.id,
          routeDate: props.location.state.routeDate,
        };
        dispatch(getRouteLineByIdUserRouteDate(data));
        dispatch(getRouteUserByIdUser(data));
        // RouteCrossAreaCarPoolService.getRouteUserByIdUser(data).then((res)=>{
        //     if(res.data){
        //         // setUserRouteReducer(res.data);
        //     }
        // }).catch((error) => {
        //     console.log(error);
        // });
      }
    } else {
      props.history.push("carbooking");
    }
  }, []);

  // useEffect(() => {
  //     let pathname = props.location.pathname.split("/")[1];
  //     if(userRouteReducer && pathname === "transparent_ShiftCar"){
  //         console.log(userRouteReducer)
  //         // RouteTodayServices.getRouteLine(userRouteReducer.routeLine,userRouteReducer.routeDayShift).then((res)=>{
  //         //     if(res.data.length > 0){
  //         //         setRouteLineReducer(res.data);
  //         //     }
  //         // }).catch((error)=>{
  //         //     console.log(error);
  //         // })
  //     }
  // }, [userRouteReducer])

  const handleCloseEmergency = () => {
    setOpenEmergency(false);
  };

  const handleSearch = (event) => {
    setSearchEmployee(event.target.value);
  };

  const handleApiLoaded = (map, maps) => {
    const markers = [];

    markers.push(
      new maps.Marker({
        position: {
          lat: parseFloat(userRouteReducer.latUser),
          lng: parseFloat(userRouteReducer.lngUser),
        },
        map,
      })
    );
  };

  const frgmentMap = () => {
    return (
      <div className={classes.mapProfile}>
        {userRouteReducer && (
          <GoogleMap
            defaultZoom={13}
            defaultCenter={{
              lat: parseFloat(userRouteReducer.latUser),
              lng: parseFloat(userRouteReducer.lngUser),
            }}
            bootstrapURLKeys={{ key: GoogleAPIkey }}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          />
        )}
      </div>
    );
  };

  const frgmentFriend = () => {
    return (
      <div className={classes.fragmentFriend}>
        {routeLineReducer && (
          <Stepper activeStep={activeStep} orientation="vertical">
            {routeLineReducer.map((item, index) => (
              <Step key={index} active={true}>
                <StepLabel>
                  <Typography variant="body2">{item.fNameThai}</Typography>
                </StepLabel>
                <StepContent>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {index == 1 && (
                      <Typography
                        variant="subtitle2"
                        style={{ color: "#9e9e9e" }}
                      >
                        {" "}
                        06:00{" "}
                      </Typography>
                    )}
                    {index != 1 && (
                      <Typography
                        variant="subtitle2"
                        style={{ color: "#9e9e9e" }}
                      >
                        {" "}
                        {dayjs(item.routeTimeOptimized).format("HH:mm")}{" "}
                      </Typography>
                    )}
                    <Typography
                      variant="subtitle2"
                      style={{ color: "#9e9e9e" }}
                    >
                      {item.departmentThai}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ color: "#9e9e9e" }}
                    >
                      {" "}
                      {utils.formatPhoneNumber(item.telephoneMobile)}{" "}
                    </Typography>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {userRouteReducer ? (
        <div style={{ width: "100%", marginTop: 25 }}>
          <div>
            <div>
              <Typography
                variant="h6"
                style={{ textAlign: "center", padding: 12 }}
              >
                {" "}
                พนักงานขับรถ{" "}
              </Typography>
            </div>
            <Grid container style={{ marginBottom: 10 }}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: 0,
                }}
              >
                {userRouteReducer ? (
                  <Avatar
                    className={classes.imageProfile}
                    src={"/assets/driver/" + userRouteReducer.pkDriver + ".png"}
                    style={{ width: 100, height: 100, marginBottom: 15 }}
                  />
                ) : (
                  <Avatar
                    className={classes.imageProfile}
                    src={"/assets/driver/0.png"}
                    style={{ width: 100, height: 100, marginBottom: 15 }}
                  />
                )}
              </Grid>
              <Grid item xs={12} className={classes.infoTxtProfile}>
                <Typography variant="h6">
                  {userRouteReducer
                    ? userRouteReducer.fullnameDriver
                    : "ยังไม่ระบุ"}
                </Typography>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ textAlign: "left", marginRight: 10 }}>
                    <Typography variant="body2">เบอร์โทรศัพท์:</Typography>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <Typography variant="body2">
                      {" "}
                      {userRouteReducer
                        ? utils.formatPhoneNumber(
                            userRouteReducer.mobileNumberDriver
                          )
                        : "ยังไม่ระบุ"}{" "}
                    </Typography>
                  </div>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    className={classes.tagShift}
                    disableRipple
                  >
                    {userRouteReducer ? userRouteReducer.plateNo : "ยังไม่ระบุ"}
                  </Button>
                </div>
                <div style={{ marginTop: 20, marginBottom: 10 }}>
                  <Rating
                    name="half-rating"
                    value={userRouteReducer ? userRouteReducer.rating : 0}
                    precision={0.5}
                    readOnly
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.trackView}>
            <Divider></Divider>
            <div
              style={{ display: "flex", justifyContent: "center", margin: 10 }}
            >
              <Button
                startIcon={<ErrorOutline />}
                color="secondary"
                variant="contained"
                style={{ backgroundColor: "#f44336" }}
                onClick={() => {
                  setOpenEmergency(true);
                  /* dispatch(employeeActions.getAllEmployee()) */
                }}
              >
                {" "}
                แจ้งเหตุฉุกเฉิน{" "}
              </Button>
            </div>
            <Dialog
              open={openEmergency}
              onClose={handleCloseEmergency}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"แจ้งเหตุฉุกเฉิน"}
              </DialogTitle>
              <DialogContent>
                <div style={{ display: "flex" }}>
                  <div>
                    <Typography variant="body1">
                      รายชื่อพนักงานทั้งหมด
                    </Typography>
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <TextField
                        label="ค้นหาพนักงาน"
                        id="outlined-size-small"
                        variant="outlined"
                        style={{ width: "100%" }}
                        onChange={handleSearch}
                        value={searchEmployee}
                      />
                    </div>
                    {employeeReducer.result ? (
                      <List style={{ height: 310, overflow: "auto" }}>
                        {employeeReducer.result
                          .filter((item) => {
                            return (
                              item.firstname_TH.includes(searchEmployee) &&
                              item.firstname_TH.indexOf("site") == -1
                            );
                          })
                          .map((route, index) => (
                            <ListItem key={index}>
                              {/* <ListItemAvatar>
                                                            <Avatar>
                                                                <FolderIcon />
                                                            </Avatar>
                                                          </ListItemAvatar> */}
                              <ListItemText
                                primary={route.firstname_TH}
                                secondary={route.departmentName}
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="addEmp"
                                  style={{ color: "#00c853" }}
                                  onClick={() => {
                                    setSelectEmp(route);
                                  }}
                                  size="large"
                                >
                                  <CheckCircleIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                      </List>
                    ) : (
                      <div
                        style={{
                          height: 310,
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </div>
                    )}
                  </div>
                  <div>
                    {selectEmp && (
                      <Fragment>
                        <div style={{ width: 200, padding: "0 20px" }}>
                          <Typography variant="body1">
                            พนักงานที่มาเข้างานแทน
                          </Typography>
                          <div style={{ marginBottom: 5, marginTop: 5 }}>
                            <Typography
                              variant="caption"
                              style={{ color: "#808080" }}
                            >
                              ชื่อ:{" "}
                            </Typography>
                            <Typography variant="body1">
                              {selectEmp.firstname_TH}
                            </Typography>
                          </div>
                          <div style={{ marginBottom: 5, marginTop: 5 }}>
                            <Typography
                              variant="caption"
                              style={{ color: "#808080" }}
                            >
                              หน่วยงาน:{" "}
                            </Typography>
                            <Typography variant="body1">
                              {selectEmp.departmentName}
                            </Typography>
                          </div>
                          <div style={{ marginBottom: 5, marginTop: 5 }}>
                            <Typography
                              variant="caption"
                              style={{ color: "#808080" }}
                            >
                              เบอร์โทรศัพท์:{" "}
                            </Typography>
                            <Typography variant="body1">
                              {selectEmp.telephoneMobile}
                            </Typography>
                          </div>
                          <div></div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseEmergency} color="primary">
                  {" "}
                  ยกเลิก{" "}
                </Button>
                <Button
                  onClick={handleCloseEmergency}
                  color="primary"
                  autoFocus
                >
                  {" "}
                  ส่งข้อมูล{" "}
                </Button>
              </DialogActions>
            </Dialog>
            <Divider />
            <div className={classes.addressProfile}>
              <div style={{ display: "flex" }}>
                <div className={classes.estimatedTime}>
                  <Typography
                    textalign="center"
                    variant="body2"
                    style={{ color: "#808080" }}
                  >
                    {" "}
                    สายที่{" "}
                  </Typography>
                  <Typography textalign="center" variant="h5">
                    {" "}
                    {userRouteReducer ? userRouteReducer.routeLine : "-"}{" "}
                  </Typography>
                </div>
                <div className={classes.estimatedTime}>
                  <Typography
                    textalign="center"
                    variant="body2"
                    style={{ color: "#808080" }}
                  >
                    {" "}
                    เวลารับโดยประมาณ{" "}
                  </Typography>
                  <Typography textalign="center" variant="h5">
                    {userRouteReducer
                      ? dayjs(userRouteReducer.routeTimeOptimized).format(
                          "HH:mm"
                        )
                      : "00:00"}
                  </Typography>
                </div>
              </div>
              <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="contained primary button group"
                fullWidth
                className={classes.btnViewFragment}
              >
                <Button
                  variant={viewArrive === "map" ? "contained" : "outlined"}
                  onClick={() => {
                    setviewArrive("map");
                  }}
                >
                  แผนที่
                </Button>
                <Button
                  variant={viewArrive === "friend" ? "contained" : "outlined"}
                  onClick={() => {
                    setviewArrive("friend");
                  }}
                >
                  เพื่อนร่วมรถ
                </Button>
              </ButtonGroup>
              {viewArrive === "friend" ? frgmentFriend() : frgmentMap()}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <RatingButton
                startIcon={<StarIcon />}
                onClick={() => props.history.push("rating_driver")}
              >
                ให้คะแนน
              </RatingButton>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", marginTop: 50, opacity: 0.5 }}>
          <img src={`${process.env.PUBLIC_URL}/assets/image/holiday.png`} />
        </div>
      )}
    </div>
  );
};

export default DashboardUser;
