import {
    VEHICLEBRANDSANDMODELS_FETCHING,
    VEHICLEBRANDSANDMODELS_FAILED,
    VEHICLEBRANDSANDMODELS_SUCCESS,
  } from "./types";
  
  import VehicleBrandAndModelService from "../services/vehicleBrandAndModel.service";
  
  export const getAllVehicleBrandsAndModels = () => async (dispatch) => {
    try {
      const res = await VehicleBrandAndModelService.getAllVehicleBrandsAndModels();
      if (res) {
        dispatch({
          type: VEHICLEBRANDSANDMODELS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: VEHICLEBRANDSANDMODELS_FAILED,
      });
    }
  };

  export const getAllVehicleBrandsAndModelsByTypeCar = (typeCar) => async (dispatch) => {
    try {
      const res = await VehicleBrandAndModelService.getAllVehicleBrandsAndModelsByTypeCar(typeCar);
      if (res) {
        dispatch({
          type: VEHICLEBRANDSANDMODELS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: VEHICLEBRANDSANDMODELS_FAILED,
      });
    }
  };
  