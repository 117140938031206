import React, { useState } from 'react';

import {
    Button, Typography, Popover
} from "@mui/material";
import { styled } from "@mui/styles";

const ButtonStyled = styled(Button)(({theme})=>({
    marginLeft:"20px",
    border:"1px solid",
    borderColor:theme.palette.grey[400],
    padding:"6px 15px",
    color:"black",
    "&:hover":{
        backgroundColor:"white",
    },
    [theme.breakpoints.down('sm')]:{
        marginTop:"10px"
    }
}));

const IconAndText = styled("div")(({theme})=>({
    display:"flex",
    alignItems:"center",
    "& .Icon":{
        fontSize:"16px",
        marginRight:"10px",
    },
    "& .Text":{
        fontSize:"16px",
        fontWeight:"bold",
    },
}));

const FilterButton = props => {
    const [anchorEl, setAnchorEl] = useState(null);

    const onClickButton = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return(
        <>
            <ButtonStyled onClick={onClickButton}>
                <IconAndText>
                    {props.icon && props.icon}
                    <Typography className="Text">{props.label}</Typography>
                </IconAndText>
            </ButtonStyled>
            <Popover
                open={Boolean(anchorEl)}
                onClose={()=>setAnchorEl(null)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {props.children}
            </Popover>
        </>
    );
};

export default FilterButton;