import React, { useState } from 'react';

import { styled } from '@mui/styles';
import {
    Grid, Typography
} from '@mui/material';

import EventSeatIcon from '@mui/icons-material/EventSeat';

import CardEdit from "../../../../../shared/cardEdit";
import ModalDetailCar from "./modalDetailCar";

const GridMainDetail = styled(Grid)({
    width:"100%",
    margin:"10px 0px 20px",
    position:"relative",
    padding:"10px 0",
});

const IconAndText = styled("div")(({theme})=>({
    display:"flex",
    alignItems:"center",
    "& .Text":{
        fontSize:"18px",
        marginLeft:"10px",
    },
    "& .Icon":{
        fontSize:"30px",
    },
    "& .ImgIcon":{
        width:"35px",
        height:"35px",
        marginTop:"-5px",
    },
}));

const PartOfDetailCar = ({ LentCar, edit }) => {
    const [openModal, setOpenModal] = useState(false);

    const OnClickOpenModal = () => setOpenModal(true);

    const OnClickCloseModal = () => setOpenModal(false);

    return(
        <CardEdit openModal={OnClickOpenModal} edit={edit}>
            <GridMainDetail container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                    <IconAndText>
                        <i className="fas fa-burn Icon"></i>
                        <Typography className="Text">{`${LentCar.mpg} MPG`}</Typography>
                    </IconAndText>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <IconAndText>
                        <i className="fas fa-gas-pump Icon"></i>
                        <Typography className="Text">{LentCar.gas}</Typography>
                    </IconAndText>
                </Grid>
                <Grid item xs={12} sm={6} lg={5}>
                    <IconAndText>
                        <i className="fas fa-car-side Icon"></i>
                        <Typography className="Text">{LentCar.noGas}</Typography>
                    </IconAndText>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <IconAndText>
                        <img className="ImgIcon" src="https://img.icons8.com/material-sharp/96/000000/car-door--v1.png"/>
                        <Typography className="Text">{`${LentCar.doors} doors`}</Typography>
                    </IconAndText>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <IconAndText>
                        <EventSeatIcon className="Icon" />
                        <Typography className="Text">{`${LentCar.seats} seats`}</Typography>
                    </IconAndText>
                </Grid>
                <Grid item xs={12} sm={6} lg={5}>
                    <IconAndText>
                        <i className="fas fa-tachometer-alt Icon"></i>
                        <Typography className="Text">{LentCar.mile}</Typography>
                    </IconAndText>
                </Grid>
                {openModal && <ModalDetailCar LentCar={LentCar} openModal={openModal} OnClickCloseModal={OnClickCloseModal}/>}
            </GridMainDetail>
        </CardEdit>
    );
};

export default PartOfDetailCar;