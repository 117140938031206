import {
  USERPROFILE_FETCHING,
  USERPROFILE_FAILED,
  USERPROFILE_SUCCESS,
  USERS_FETCHING,
  USERS_FAILED,
  USERS_SUCCESS,
  DRIVERS_FETCHING,
  DRIVERS_FAILED,
  DRIVERS_SUCCESS,
  MANAGERS_FETCHING,
  MANAGERS_FAILED,
  MANAGERS_SUCCESS,
} from "./types";

import UserService from "../services/user.service";

export const getUserProfile = (id) => async (dispatch) => {
  try {
    const res = await UserService.getUserProfile(id);
    if (res) {
      dispatch({
        type: USERPROFILE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: USERPROFILE_FAILED,
    });
  }
};

export const getAllUsers = () => async (dispatch) => {
  try {
    const res = await UserService.getAllUsers();
    if (res) {
      dispatch({
        type: USERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USERS_FAILED,
    });
  }
};

export const getAllDriver = () => async (dispatch) => {
  try {
    const res = await UserService.getAllDriver();
    if (res) {
      dispatch({
        type: DRIVERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DRIVERS_FAILED,
    });
  }
};

export const allManager = () => async (dispatch) => {
  try {
    const res = await UserService.getAllManager();
    if (res) {
      dispatch({
        type: USERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: USERS_FAILED,
    });
  }
};

export const updateLocationUser = (newData) => async () => {
  try {
    const res = await UserService.updateLocationUser(newData);
    if (res) {
      return res;
    }
  } catch (err) {
    return "error";
  }
};
