import React, { useState } from "react";
import { useSelector } from "react-redux"; 

import { styled } from '@mui/styles';
import { 
  Button, Typography
} from '@mui/material';

import DivInputBedTime from "./divInputBedTime";
import DialogWarning from "./dialogWarning";
import DriverRouteDayService from "../../../../services/driverRouteDay.service";

const DivCoverReturn = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
});

const BackgroundImg = styled("img")({
  position: "absolute",
  width: "100%",
  height: "calc(100% - 64px)",
  opacity: "0.3",
});

const Content = styled("div")(({theme})=>({
  width: "80%",
  backgroundColor: "#03256C",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: "0 0 5px",
  position: "absolute",
  borderRadius: "50px",
  [theme.breakpoints.down('sm')]:{
    width:"100%",
    borderRadius: "30px",
  },
}));

const ColonText = styled(Typography)(({theme})=>({
  fontSize: "120px",
  paddingTop: "100px",
  color: "white",
  [theme.breakpoints.down('sm')]:{
    fontSize: "100px",
    paddingTop: "70px",
  },
}));

const QuestionText = styled(Typography)(({theme})=>({
  fontSize: "60px",
  marginBottom: "30px",
  marginTop: "30px",
  color: "white",
  [theme.breakpoints.down('sm')]:{
    fontSize: "35px",
  }
}));

const DivInputTime = styled("div")({
  width: "100%",
  justifyContent: "center",
  display: "flex",
  marginBottom: "20px",
});

const ConfirmButton = styled(Button)(({theme})=>({
  backgroundColor: "rgb(58, 145, 38)",
  width: "25%",
  height: "15%",
  fontSize: "40px",
  color: "white",
  marginTop: "20px",
  marginBottom: "20px",
  borderRadius: "20px",
  "&:hover":{
    backgroundColor: "rgb(58, 145, 38)",
  },
  [theme.breakpoints.down('sm')]:{
    width: "90%",
  },
}));

const Bedtimepage = (props) => {
  const { result: Driver } = useSelector((state) => state.driverRouteDay);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const ChangeMinutesHandler = (minute) => {
    if (minute > 59) {
      setMinutes(0);
    } else if (minute < 0) {
      setMinutes(59);
    } else {
      setMinutes(parseInt(minute));
    }
  };

  const ChangeHoursHandler = (hour) => {
    if (hour > 23) {
      setHours(0);
    } else if (hour < 0) {
      setHours(23);
    } else {
      setHours(parseInt(hour));
    }
  };

  const onBlurHandler = (value, name) => {
    if (value === "" && name === "hours") {
      setHours(0);
    } else if (value === "" && name === "minutes") {
      setMinutes(0);
    }
  };

  const OnClickConfirmButton = () => {
    let Time = new Date().setHours(hours,minutes);
    console.log("Driver :",Driver);
    let data = {
      idDriver : Driver.idDriver,
      routeDate : Driver.routeDate,
      bedTime : new Date(Time)
    };
    DriverRouteDayService.postBedTime(data).then(
      ()=>{
        props.history.push("/driver");
      }
    ).catch(error => console.log(error));
  };

  return (
    <DivCoverReturn className="page">
      <BackgroundImg
        src="./assets/background/carpark_bedtime.jpg"
        alt="carpark_bedtime"
      />
      <Content>
        <QuestionText>
          ระบุเวลาที่เข้านอนเมื่อคืน
        </QuestionText>

        <DivInputTime>
          <DivInputBedTime
            onClickFunction={ChangeHoursHandler}
            onBlurHandler={onBlurHandler}
            onSetFuntion={setHours}
            name="hours"
            value={hours}
          />

          <ColonText>
            :
          </ColonText>

          <DivInputBedTime
            onClickFunction={ChangeMinutesHandler}
            onBlurHandler={onBlurHandler}
            onSetFuntion={setMinutes}
            name="minutes"
            value={minutes}
          />
        </DivInputTime>
        <ConfirmButton
          onClick={()=>{setOpenDialog(true)}}
        >
          ยืนยัน
        </ConfirmButton>
      </Content>
      <DialogWarning
        statusOpen={openDialog}
        onOpenHandler={setOpenDialog}
        OnClickConfirmButton={OnClickConfirmButton}
      />
    </DivCoverReturn>
  );
};

export default Bedtimepage;
