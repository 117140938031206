import React, { useState } from "react";
import clsx from "clsx";
import { styled } from "@mui/styles";
import {
    Typography, Card, IconButton,
} from "@mui/material";
import utils from "../../../../../../../utils";
import InfoIcon from '@mui/icons-material/Info';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';

const CardStyles = styled("div")(({ theme }) => ({
  width: "300px",
  height:"300px",
  minWidth: "300px",
  margin: "0 10px",
  position:"relative",
  "& .CardSelect":{
    boxShadow:"0 0 5px 5px red",
  },
  "& .Image":{
    height:"120px",
    backgroundColor:theme.palette.primary.main,
  },
  "& .partOfPriceText":{
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 10px",
  },
}));

const IconButtonStyles = styled(IconButton)(({theme})=>({
  position:"absolute",
  right:"5px",
  top:"5px",
  zIndex:1,
  "& .Icon":{
      color:theme.palette.primary.contrastText,
  },
}));

const TitleCard = styled(Typography)(({theme})=>({
  fontSize: "20px",
  fontWeight: "bold",
  textAlign: "center",
  padding: "5px",
  color:theme.palette.primary.main,
}));

const ContentList = styled("div")(({theme})=>({
  height: "100px",
  padding:"0 10px",
  "& .List":{
    display:"flex",
    alignItems:"center",
  },
  "& .dot":{
    fontSize:"10px",
    marginRight:"10px",
  },
  "& .Text":{
    fontSize:"18px",
  },
}))

const PriceText = styled(Typography)(({theme})=>({
  fontSize: "20px",
  marginTop:"10px",
  "& .Price": {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

const DetailContent = styled(Card)(({theme})=>({
  width:"calc(100% - 20px)",
  height:"calc(100% - 20px)",
  position:"absolute",
  top:0,
  backgroundColor:theme.palette.primary.main,
  padding:"10px",
  "& .Title":{
    fontSize:"20px",
    marginBottom:"5px",
  },
  "& .dot":{
    color:theme.palette.primary.contrastText,
  },
  "& .Text":{
    color:theme.palette.primary.contrastText,
    fontWeight:"bold",
  },
}));

const CardPackage = (props) => {

  const [openInfo, setOpenInfo] = useState(false);

  return (
    <CardStyles>
      <Card 
        className={clsx({["CardSelect"]:props.index === props.packageSelected})}
        onClick={()=>props.onClickCard(props.index)}
      >
        <div className="MainContent">
          <div className="Image"></div>
          <IconButtonStyles disableRipple onClick={()=>setOpenInfo(!openInfo)}>
            { openInfo ?
              <CloseIcon className="Icon" />
              :
              <InfoIcon className="Icon" />
            }
          </IconButtonStyles>
          <TitleCard>{props.Package.package}</TitleCard>
          <ContentList>
            {props.Package.Topic.map((topic)=>(
              <div className="List" key={topic}>
                <CircleIcon className="dot"/>
                <Typography className="Text">{topic}</Typography>
              </div>
            ))}
          </ContentList>
          <div className="partOfPriceText">
            { props.Package.price !== 0 ?
                <PriceText>
                    ราคา​: <span className="Price">{utils.numberWithCommas(props.Package.price)}</span> บาท
                </PriceText>
                :
                <PriceText className="PriceText">
                    <span className="Price">ฟรี</span>
                </PriceText>
            }
          </div>
        </div>
        <Fade in={openInfo}>
          <DetailContent>
            <Typography className="Title Text">รายละเอียด</Typography>
            <ContentList>
              {props.Package.Topic.map((topic)=>(
                <div className="List" key={topic+props.Package.package}>
                  <CircleIcon className="dot"/>
                  <Typography className="Text">{topic}</Typography>
                </div>
              ))}
            </ContentList>
          </DetailContent>
        </Fade>
      </Card>
    </CardStyles>
  );
};

export default CardPackage;
