import React from 'react';

import { styled } from '@mui/styles';

import makeStyles from '@mui/styles/makeStyles';
import { 
    Button, Typography, Card
} from '@mui/material';

const DivCoverReturn = styled("div")({
    height:"calc(100% - 64px)",
    display:"flex",
    position:"relative",
});

const PartOfBackground = styled("div")({
    height:"calc(100% - 64px)",
    display:"flex",
    position:"absolute",
    zIndex:"-1",
});

const Background = styled("img")(({theme})=>({
    width:"calc(100vw - 56px)",
    height:"calc(100vh - 64px)",
    opacity:"0.5",
    [theme.breakpoints.down('sm')]: {
        width:"100vw",
        height:"100%",
    },
}));

const NavButton = styled("div")(({theme})=>({
    width:"100%",
    height:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    minWidth:"0px",
    overflowX:"auto",
    [theme.breakpoints.down('xl')]: {
        justifyContent:"flex-start",
        padding:"0 20px",
    },

}));

const CardStyled = styled(Card)(({theme })=>({
    height:"350px",
    width:"300px",
    minWidth:"300px",
    backgroundColor:"#fff",
    borderRadius:"10px",
    display:"flex",
    justifyContent:"center",
    padding:"10px",
    "&:not(:last-child)": {
        marginRight:"24px",
    },
    [theme.breakpoints.down('sm')]: {
        width:"60%",
    },
}));

const ContentOnCard = styled("div")({
    width:"90%",
    height:"100%",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    position:"relative",
});

const ImageIcon = styled("img")({
    width:"150px",
    height:"150px",
});

const ImageDriverAndPassenger = styled("img")({
    width:"200px",
    height:"200px",
});

const ContentText = styled("div")({
    width:"100%",
    position:"absolute",
    top:"180px",
});

const Text = styled(Typography)({
    fontSize:"36px",
    color:"black",
    textAlign:"start",
});

const SubText = styled(Typography)({
    fontSize:"20px",
    color:"black",
    textAlign:"start",
});

const GoButton = styled(Button)(({theme})=>({
    backgroundColor:theme.palette.warning.main,
    color:theme.palette.primary.contrastText,
    fontSize:"16px",
    position:"absolute",
    right:"0",
    bottom:"10px",
    padding:"5px 15px",
    "&:hover":{
        backgroundColor:theme.palette.warning.dark,
    },
}));

const Friend2FriendCar = (props) => {
    const onClickButtonHandler = (position) => {
        if(position === "driver"){
            props.history.push("/friend2friend_Car/driver/listRegisterPage");
        }else if(position === "lender"){
            props.history.push("/friend2friend_Car/lender");
        }else if(position === "borrower"){
            props.history.push("/friend2friend_Car/borrower");
        }else{
            props.history.push("/friend2friend_Car/passenger/my_plan");
        }
    }

    return(
        <DivCoverReturn className="page">
            <PartOfBackground>
                <Background
                    src={`./assets/background/friend2friend.jpg`}
                    alt="background"
                />
            </PartOfBackground>
            <NavButton>
                <CardStyled>
                    <ContentOnCard>
                        <ImageDriverAndPassenger
                            src={`./assets/Image/friend2friend/driver.png`} 
                            alt="Driver"
                        />
                        <ContentText>
                            <Text>คนขับรถ</Text>
                            <SubText>คนที่รับเพื่อนร่วมงานไปทำงานด้วยกัน</SubText>
                        </ContentText>
                        <GoButton onClick={()=>onClickButtonHandler("driver")}>{`ต่อไป >`}</GoButton>
                    </ContentOnCard>
                </CardStyled>
                <CardStyled>
                    <ContentOnCard>
                        <ImageDriverAndPassenger
                            src={`./assets/Image/friend2friend/passenger.png`} 
                            alt="Passenger"
                        />
                        <ContentText>
                            <Text>ผู้โดยสาร</Text>
                            <SubText>คนที่ร่วมนั่งรถยนต์ไปทำงานกับเพื่อนร่วมงาน</SubText>
                        </ContentText>
                        <GoButton onClick={()=>onClickButtonHandler("passenger")}>{`ต่อไป >`}</GoButton>
                    </ContentOnCard>
                </CardStyled>
                <CardStyled>
                    <ContentOnCard>
                        <ImageIcon
                            src={`./assets/Image/friend2friend/lender.png`} 
                            alt="Lender"
                        />
                        <ContentText>
                            <Text>คนให้ยืมรถ</Text>
                            <SubText>คนที่นำรถยนต์มาแชร์ให้คนอื่นนำไปใช้ได้</SubText>
                        </ContentText>
                        <GoButton onClick={()=>onClickButtonHandler("lender")}>{`ต่อไป >`}</GoButton>
                    </ContentOnCard>
                </CardStyled>
                <CardStyled>
                    <ContentOnCard>
                        <ImageIcon
                            src={`./assets/Image/friend2friend/borrower.png`} 
                            alt="Lender"
                        />
                        <ContentText>
                            <Text>คนยืมรถ</Text>
                            <SubText>คนที่ยืมรถยนต์จากคนให้ยืมรถไปใช้ชั่วคราว</SubText>
                        </ContentText>
                        <GoButton onClick={()=>onClickButtonHandler("borrower")}>{`ต่อไป >`}</GoButton>
                    </ContentOnCard>
                </CardStyled>
            </NavButton>
        </DivCoverReturn>
    );
};

export default Friend2FriendCar;