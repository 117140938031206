import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import UserService from "../../../../../services/user.service";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const FormInformant = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (currentUser) {
      UserService.getUserProfile(currentUser.idUser).then((res) => {
        if (res.data) {
          props.setValue(
            "name",
            `${res.data.firstname_TH} ${res.data.lastname_TH}`
          );
          props.setValue(
            "telephoneMobile",
            res.data.telephoneMobile.replace(/-/g, "")
          );
          props.setValue("email", res.data.email);
          props.setValue("section", res.data.sectionName);
          props.setValue("department", res.data.departmentName);
        }
      });
    }
  }, []);

  return (
    <Grid
      container
      spacing={3}
      sx={(theme) => ({
        display: "flex",
        border: "1px solid #EEEEEE",
        backgroundColor: "#FBFCFC",
        marginTop: "5px",
        marginBottom: "20px",
        padding: "0 25px 25px 0",
        boxShadow:
          "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

        borderRadius: "15px",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.down("md")]: {},
      })}
    >
      <Grid item xs={12} lg={4}>
        <TextField
          placeholder="ระบุชื่อ-นามสกุล"
          label="ชื่อผู้แจ้งขอ"
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputProps={{ ...props.register("name", { required: true }) }}
          helperText={props.errors.name ? "กรุณากรอกข้อมูล" : false}
          error={props.errors.name ? true : false}
          InputProps={{
            style: {
              borderRadius: "15px",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          placeholder="ระบุเบอร์โทรศัพท์"
          label="เบอร์โทรศัพท์"
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputProps={{
            ...props.register("telephoneMobile"),
            pattern: `[0]\\d{2}(?:[\\-]?\\d{3}){2}\\d$`,
          }}
          helperText={props.errors.telephoneMobile ? "กรุณากรอกข้อมูล" : false}
          error={props.errors.telephoneMobile ? true : false}
          InputProps={{
            style: {
              borderRadius: "15px",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          placeholder="ระบุEmail"
          label="Email"
          fullWidth
          type="email"
          InputLabelProps={{ shrink: true }}
          inputProps={{ ...props.register("email") }}
          helperText={props.errors.email ? "กรุณากรอกข้อมูล" : false}
          error={props.errors.email ? true : false}
          InputProps={{
            style: {
              borderRadius: "15px",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          placeholder="ระบุแผนก"
          label="แผนก"
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputProps={{ ...props.register("section") }}
          helperText={props.errors.section ? "กรุณากรอกข้อมูล" : false}
          error={props.errors.section ? true : false}
          InputProps={{
            style: {
              borderRadius: "15px",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <TextField
          placeholder="ระบุส่วน"
          label="ส่วน"
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputProps={{ ...props.register("department") }}
          helperText={props.errors.department ? "กรุณากรอกข้อมูล" : false}
          error={props.errors.department ? true : false}
          InputProps={{
            style: {
              borderRadius: "15px",
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FormInformant;
