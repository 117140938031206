import {
    LENTCARS_FETCHING,
    LENTCARS_FAILED,
    LENTCARS_SUCCESS,
    LENTCAR_FETCHING,
    LENTCAR_FAILED,
    LENTCAR_SUCCESS,
} from "./types";

import LentCarService from "../services/lentCar.service";

export const getAllLentCars = () => async (dispatch) => {
    try {
        dispatch({
            type: LENTCARS_FETCHING,
        });
        const res = await LentCarService.getAllLentCars();
        if(res){
            dispatch({
                type: LENTCARS_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: LENTCARS_FAILED,
        });
    }
};

export const getLentCarById = (idCar) => async (dispatch) => {
    try {
        dispatch({
        type: LENTCAR_FETCHING,
        });
        const res = await LentCarService.getLentCarById(idCar);
        if(res){
            dispatch({
                type: LENTCAR_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: LENTCAR_FAILED,
        });
    }
};

export const getLentCarsByLenderId = (idLender) => async (dispatch) => {
    try {
        dispatch({
        type: LENTCARS_FETCHING,
        });
        const res = await LentCarService.getLentCarsByLenderId(idLender);
        if(res){
            dispatch({
                type: LENTCARS_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: LENTCARS_FAILED,
        });
    }
};

