import React from "react";
import { styled, Paper } from "@mui/material";

const Root = styled(Paper)(({ theme }) => ({
  padding: "24px 48px",
  transition: "transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  boxShadow: "0 2px 4px 0 rgb(35 31 32 / 20%), 0 0 1px 0 rgb(35 31 32 / 10%)",
  position: "relative",
  border: "1px solid #EEEEEE",
  backgroundColor: "#FBFCFC",
  borderRadius: "15px",

  [theme.breakpoints.down("sm")]: {
    padding: "24px 16px",
  },
}));

const Card = ({ children, style }) => {
  return <Root style={style}>{children}</Root>;
};

export default Card;
