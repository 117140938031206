import { httpClient } from "./httpClient";

const getAllRegistersOfDriver = () => {
    return httpClient.get("/allRegistersOfDriver");
};

const getRegistersOfDriver = (idOrder) => {
    return httpClient.get("/RegisterOfDriver/"+idOrder);
};

const getRegisterOfDriverByIdUser = (idUser) => {
    return httpClient.get("/RegisterOfDriverByIdUser/"+idUser);
};

const getRegistersOfDriverByTypeAndDate = (data) => {
    return httpClient.post("/RegisterOfDriverByTypeAndDate",data);
};

const getRegistersOfDriverByType = (type) => {
    return httpClient.get("/RegisterOfDriverByType/"+type);
};

const getRegistersOfDriverByDuration = (dateStart,dateEnd) => {
    return httpClient.get("/RegisterOfDriverByDuration/"+dateStart+"/"+dateEnd);
};

export default {
    getAllRegistersOfDriver,
    getRegistersOfDriver,
    getRegistersOfDriverByType,
    getRegisterOfDriverByIdUser,
    getRegistersOfDriverByTypeAndDate,
    getRegistersOfDriverByDuration,
  };