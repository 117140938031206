import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";

import { Button } from "@mui/material";
import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

import { Card } from "@mui/material";
import {
  Box,
  Paper,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  Grid: {
    width: "80%",
    height: "100%",
    margin: "0 auto",
    marginTop: "20px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },
  Title: {
    textAlign: "center",
    fontSize: "25px",
    margin: "25px 0px",
  },
  TableRow: {
    ["&:hover"]: {
      cursor: "pointer",
      backgroundColor: "#f1f4f9",
    },
  },
}));
const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f1f4f9",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});
const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  backgroundColor: "#ffffff",

  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "40px",
  height: "40px",
  margin: "5px",
  // [theme.breakpoints.down("lg")]: {
  //   width: "80px",
  //   height: "80px",
  // },
  // [theme.breakpoints.down("sm")]: {
  //   width: "140px",
  //   height: "140px",
  //   marginBottom: "0px",
  // },
}));

const NameAndType = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    // alignItems: "center",
  },
}));

const Text = styled(Typography)({
  fontSize: "14px",
  color: "#878484",
});

const DivAvatarAndName = styled("div")(({ theme }) => ({
  display: "flex",
}));
const TextFirstnameAndLastName = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "block",
    paddingTop: "10px",
  },
  "& .FirstName": {
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
    },
  },
}));

const StatusName = styled("span")(({ status }) => {
  const getColor = () => {
    switch (status) {
      case "ได้รับสินค้าเรียบร้อย":
        return "#24944f";
      case "รอรับสินค้า":
        return "#d42121";
      case "รับสินค้าเรียบร้อย":
        return "#dfcf20";
      case "ส่งสินค้าเรียบร้อย":
        return "#d47721";
      default:
        return "inherit";
    }
  };

  return {
    color: getColor(),
    fontSize: "16px",
    padding: "4px 8px",
    borderRadius: "12px",
    backgroundColor: `${getColor()}10`, // 10% opacity of the text color
    width: "160px", // Fixed width for all statuses
    display: "inline-block",
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
});

const BookingStatus = ({ status }) => (
  <StatusName status={status}>{status}</StatusName>
);

const CardSampleRequest = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const [booking, setBooking] = useState({});
  const [listBooking, setListBooking] = useState([]);
  const [allSite, setAllSite] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const addNamePlace = async () => {
    let bookings = props.listBooking;
    for (let i = 0; i < props.listBooking.length; i++) {
      const fromPlace = await props.allSite.find((site) => {
        if (site.idScgSite == props.listBooking[i].fromPlace) return site;
      });
      const toPlace = await props.allSite.find((site) => {
        if (site.idScgSite == props.listBooking[i].toPlace) return site;
      });

      if (fromPlace.noSite != null) {
        bookings[
          i
        ].fromPlaceName = `Site${fromPlace.noSite}: ${fromPlace.nameSite}`;
      } else {
        bookings[i].fromPlaceName = `${fromPlace.nameSite}`;
      }
      if (toPlace.noSite != null) {
        bookings[i].toPlaceName = `Site${toPlace.noSite}: ${toPlace.nameSite}`;
      } else {
        bookings[i].toPlaceName = `${toPlace.nameSite}`;
      }
    }
    return bookings;
  };

  useEffect(async () => {
    if (props.listBooking) {
      setAllSite(props.allSite);
      const bookings = await addNamePlace();
      setListBooking(bookings);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/data");
      const data = await response.json();
      setData(data);
    };
    fetchData();
  }, []);

  return (
    // <GridContainer
    //   container
    //   spacing={0}
    //   sx={(theme) => ({
    //     display: "flex",
    //     // justifyContent: "space-between",
    //     border: "1px solid #EEEEEE",
    //     backgroundColor: "#FBFCFC",

    //     marginBottom: "20px",
    //     // padding: "0px 25px 25px 0px",
    //     padding: "25px 30px 25px 30px",

    //     borderRadius: "15px",
    //   })}
    // >
    <Paper
      sx={{
        // width: "100%",
        overflow: "hidden",
        border: "1px solid #EEEEEE",
        backgroundColor: "#FFFFFF",

        marginBottom: "20px",
        // padding: "0px 25px 25px 0px",
        padding: "25px 30px 25px 30px",

        borderRadius: "15px",
      }}
    >
      {listBooking.length > 0 && allSite && (
        <>
          <TableContainer style={{ maxHeight: 500 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledCellHeader style={{ minWidth: "200px" }}>
                    ชื่อผู้ส่ง
                  </StyledCellHeader>
                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "100px" }}
                  >
                    ชื่อผลทดลอง
                  </StyledCellHeader>
                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "150px" }}
                  >
                    วันที่
                  </StyledCellHeader>
                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "200px" }}
                  >
                    ชื่อผู้รับ
                  </StyledCellHeader>
                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "300px" }}
                  >
                    จุดรับ
                  </StyledCellHeader>
                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "300px" }}
                  >
                    จุดส่ง
                  </StyledCellHeader>
                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "200px" }}
                  >
                    สถานะ
                  </StyledCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {listBooking
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((booking) => (
                    <TableRow
                      hover
                      key={booking.idDeliverySampleShuttle}
                      className={`${classes.TableRow}`}
                      onClick={() => {
                        props.onClickCardHandler(
                          booking.idDeliverySampleShuttle
                        );
                      }}
                    >
                      <StyledCellContent>
                        <DivAvatarAndName>
                          <AvatarStyled
                            src={`${process.env.REACT_APP_URL}image/profile/${booking.imageUser}`}
                            // alt={booking.name}
                          />
                          <NameAndType>
                            <TextFirstnameAndLastName>
                              {booking.nameSender}
                            </TextFirstnameAndLastName>
                          </NameAndType>{" "}
                        </DivAvatarAndName>
                      </StyledCellContent>
                      <StyledCellContent align="center">
                        <Text>{booking.nameSample}</Text>
                      </StyledCellContent>
                      <StyledCellContent align="center">
                        <Text>
                          {new Date(booking.date).toLocaleDateString("th-TH", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </Text>
                      </StyledCellContent>
                      <StyledCellContent align="center">
                        <Text>{booking.nameRecipient}</Text>
                      </StyledCellContent>
                      <StyledCellContent align="center">
                        <Text>{booking.fromPlaceName}</Text>
                      </StyledCellContent>
                      <StyledCellContent align="center">
                        <Text>{booking.toPlaceName}</Text>
                      </StyledCellContent>

                      <StyledCellContent align="center">
                        <BookingStatus status={booking.status} />
                      </StyledCellContent>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={listBooking.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
    // </GridContainer>
  );
};

export default CardSampleRequest;
