import React from 'react';
import dayjs from 'dayjs';

import { styled } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PeopleIcon from '@mui/icons-material/People';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import RoomIcon from '@mui/icons-material/Room';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PhoneIcon from '@mui/icons-material/Phone';

require("dayjs/locale/th");
dayjs.locale('th');

const useStyles = makeStyles((theme)=>({
    Title:{
        fontSize: "30px",
    },
    typeAndModelDetail:{
        textAlign:"center",
    },
    Dialog:{
        paddingBottom: "30px",
        marginTop:"0",
        "& .MuiPaper-root":{
            [theme.breakpoints.down('sm')]:{
                margin:"0",
            },
        }
    },
    DialogContent:{
        display:"flex",
        justifyContent:"center",
        padding:"0",
        [theme.breakpoints.down('sm')]:{
            height:"550px",
        },
    },
    Grid:{
        width:"100%",
        marginLeft:"50px",
        [theme.breakpoints.down('sm')]:{
            marginLeft:"0px",
        },
    },
    GridPicture:{
        display:"flex",
        justifyContent:"center",
    },
    Avatar:{
        width:"400px",
        height:"250px",
        [theme.breakpoints.down('sm')]:{
            width:"230px",
            height:"150px",
        },
    },
    Text:{
        fontSize:"20px",
        color:"darkblue",
    },
    Icon:{
        marginTop:"3px",
        color:"darkblue",
        fontsize:"10px",
        marginRight:"3px",
    },
    IconYellow:{
        color:"#DCA700",
    },
    GridTextIcon:{
        display:"flex",
    },
    Fixed:{
        backgroundColor: "#DCA700",
        "&:hover":{
            backgroundColor: "#ba8e04",
        }
    },
    Confirm:{
        backgroundColor: "green",
        "&:hover":{
            backgroundColor: "darkgreen",
        }
    },
    GridTextAndIcon:{
        display:"flex",
    },
    DialogActions:{
        paddingRight:"30px",
    },
    Button:{
        width:"150px",
        height:"50px",
    },
    TextButton:{
        color:"white",
    },
}));

const GridContainText = styled(Grid)(({theme})=>({
    display:"flex",
    wordWrap:"break-word",
    paddingRight:"24px",
}));

const DialogShowDetailBooking = (props) => {
    const classes = useStyles();

    const convertDate = (lawDate) => {
        let date = new Date(lawDate).toDateString();
        let splitDate = date.split(" ");
        return `${splitDate[2]} ${splitDate[1]} ${splitDate[3]}`;
    };

    const verificationFlightIsOneWay = (typeOfFlight) => {
        if(typeOfFlight === "oneWay"){
            return true;
        }else{
            return false;
        }
    };

    return (
        <Dialog open={props.open} className={classes.Dialog} maxWidth="md">
            <DialogTitle>
                <Typography className={`${classes.Title} ${classes.typeAndModelDetail}`}>จองรถข้ามพื้นที่ Pool</Typography>
            </DialogTitle>
            <DialogContent className={classes.DialogContent}>
                <Grid container spacing={3} className={classes.Grid}>
                    <Grid item xs={11} className={`${classes.GridPicture}`}>
                        <Avatar className={`${classes.Avatar}`} src={`${process.env.REACT_APP_URL}image/car/car4.jpg`} alt="car4.jpg" variant="square"/>
                    </Grid>
                    <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                        <CalendarTodayIcon className={`${classes.Icon}`}/>
                        <Typography className={`${classes.Text}`}>{`วันที่เริ่มต้น : ${dayjs(props.getValues("departureDate")).format("DD MMMM YYYY")}`}</Typography>
                    </GridContainText>
                    <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                        <CalendarTodayIcon className={`${classes.Icon}`}/>
                        <Typography className={`${classes.Text}`}>{`วันที่สุดท้าย : ${verificationFlightIsOneWay(props.getValues("flight"))? dayjs(props.getValues("departureDate")).format("DD MMMM YYYY") : dayjs(props.getValues("returnDate")).format("DD MMMM YYYY") }`}</Typography>
                    </GridContainText>
                    <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                        <DriveEtaIcon className={classes.Icon}/>
                        <Typography className={`${classes.Text}`}>ประเภท : &nbsp;&nbsp;{verificationFlightIsOneWay(props.getValues("flight"))? "เที่ยวเดียว" : "ไป-กลับ"}</Typography>
                    </GridContainText>
                    <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                        <PeopleIcon className={classes.Icon}/>
                        <Typography className={`${classes.Text}`}>จำนวนผู้โดยสาร : &nbsp;&nbsp;{props.getValues("numberOfPassenger")}&nbsp;&nbsp; คน</Typography>
                    </GridContainText>
                    <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                        <RoomIcon className={`${classes.Icon}`}/>
                        <Typography className={`${classes.Text}`}>จุดรับ : &nbsp;&nbsp;{props.getValues("fromPlace")}</Typography>
                    </GridContainText>
                    <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                        <RoomIcon className={`${classes.Icon}`}/>
                        <Typography className={`${classes.Text}`}>จุดส่ง : &nbsp;&nbsp;{props.getValues("toPlace")}</Typography>
                    </GridContainText>
                    <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                        <PersonIcon className={classes.Icon}/>
                        <Typography className={`${classes.Text}`}>ผู้แจ้งขอ : &nbsp;&nbsp;{props.getValues("name")}</Typography>
                    </GridContainText>
                    <GridContainText item xs={12} sm={6} className={classes.GridTextAndIcon}>
                        <PhoneIcon className={classes.Icon}/>
                        <Typography className={`${classes.Text}`}>Tel. : &nbsp;&nbsp;{props.getValues("telephoneMobile")}</Typography>
                    </GridContainText>
                    <GridContainText item xs={12} sm={6} className={`${classes.GridTextAndIcon}`}>
                        <MonetizationOnIcon className={`${classes.Icon}  ${classes.IconYellow}`}/>
                        <Typography className={`${classes.Text}`}>ราคา : &nbsp;&nbsp;5,400&nbsp;&nbsp; THB</Typography>
                    </GridContainText>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.DialogActions}>
                <Button className={`${classes.Fixed} ${classes.Button}`} onClick={props.handleClose}>
                    <Typography className={`${classes.Text} ${classes.TextButton}`}>แก้ไข</Typography>
                </Button>
                <Button className={`${classes.Confirm} ${classes.Button}`} onClick={props.onClickSubmit}>
                    <Typography className={`${classes.Text} ${classes.TextButton}`}>จองรถ</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogShowDetailBooking;