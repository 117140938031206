import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import {
  Card,
  Typography,
  Avatar,
  Grid,
  Divider,
  Rating,
  TextField,
  Button,
  Select,
  FormControl,
  Modal,
  Paper,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import StarIcon from "@mui/icons-material/Star";

import { getLentCarById } from "../../../../../../actions/lentcar";
import utils from "../../../../../../utils/index";
import { listExtras } from "../../choise";
import reviewServices from "../../../../../../services/review.service";
import HistoryLentCarServices from "../../../../../../services/historyLentCar.service";

import ModalPlaceAndPackage from "./modalPlaceAndPackage";
import ModalPrice from "./modalPrice";

import PartOfImageCar from "./partOfImageCar";
import PartOfModelTextAndPoint from "./partOfModelTextAndPoint";
import PartOfDetailCar from "./partOfDetailCar";
import PartOfDescription from "./partOfDescription";
import PartOfFeature from "./partOfFeature";
import PartOfExtra from "./partOfExtra";
import PartOfCondition from "./partOfCondition";
import PartOfRatingAndComment from "./partOfRatingAndComment";

require("dayjs/locale/th");
dayjs.locale("th");

const PartOfContent = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  display: "flex",
  justifyContent: "center",
}));

const PartOfDetail = styled("div")(({ theme }) => ({
  width: "calc(100% - 400px)",
  height: "100%",
  paddingTop: "20px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const PartOfChat = styled("div")(({ theme }) => ({
  width: "400px",
  height: "fit-content",
  maxHeight: "500px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  top: "80px",
  position: "sticky",
}));

const CardChat = styled(Card)(({ theme }) => ({
  width: "calc(100% - 40px)",
  height: "100%",
  padding: "20px",
  marginTop: "50px",
  boxShadow: theme.shadows[3],
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.grey[200],
  margin: "10px 0",
}));

const IconAndText = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .Text": {
    fontSize: "18px",
    marginLeft: "10px",
  },
  "& .IconAndPoint": {
    color: theme.palette.warning.light,
    fontWeight: "bold",
    fontSize: "24px",
  },
  "& .Icon": {
    fontSize: "30px",
  },
  "& .IconDot": {
    fontSize: "10px",
    marginRight: "5px",
  },
}));

const Topic = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "bold",
  margin: "10px 0px",
}));

const PartOfHost = styled("div")(({ theme }) => ({
  height: "90px",
  display: "flex",
  "& .Avatar": {
    marginRight: "20px",
    width: "90px",
    height: "90px",
  },
  "& .partOfText": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  "& .Name": {
    fontSize: "24px",
    fontWeight: "bold",
  },
  "& .NumberLendered": {
    color: theme.palette.grey[600],
    fontSize: "20px",
  },
}));

const ReviewDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  margin: "20px 0",
  "& .Avatar": {
    width: "50px",
    height: "50px",
  },
}));

const RatingAndComment = styled("div")(({ theme }) => ({
  width: "calc(100% - 80px)",
  marginLeft: "20px",
  "& .Name": {
    fontSize: "18px",
    fontWeight: "bold",
  },
}));

const ContentInputChat = styled("div")(({ theme }) => ({
  marginBottom: "10px",
  "& .InputDateAndTime": {
    display: "flex",
  },
  "& .InputBox": {
    width: "200px",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  "& .MuiInputBase-root": {
    height: "50px",
  },
}));

const GreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
  fontSize: "16px",
  fontWeight: "bold",
  marginBottom: "10px",
  marginTop: "10px",
  "&:hover": {
    backgroundColor: theme.palette.success.dark,
  },
}));

const ChatButton = styled(Button)(({ theme }) => ({
  border: "2px solid",
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  fontSize: "16px",
  fontWeight: "bold",
}));

const ContentPrice = styled("div")(({ theme }) => ({
  "& .PriceText": {
    fontSize: "20px",
    color: theme.palette.success.main,
  },
}));

const SelectTime = styled(FormControl)(({ theme }) => ({
  width: "150px",
  [theme.breakpoints.down("md")]: {
    width: "50%",
    marginRight: "0",
  },
}));

const TopicCard = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "bold",
  marginBottom: "5px",
}));

const CancellationFree = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .Icon": {
    fontSize: "35px",
    margin: "0 20px",
    color: theme.palette.warning.light,
  },
  "& .DateText": {
    fontSize: "20px",
    color: theme.palette.success.main,
  },
}));

const ModalStyled = styled(Modal)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
  width: "90%",
  height: "80%",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    overflowY: "auto",
  },
}));

const DurationAndExtras = styled("div")(({ theme }) => ({
  width: "calc(60% - 20px)",
  height: "calc(100% - 40px)",
  padding: "20px",
  paddingRight: "0",
  [theme.breakpoints.down("md")]: {
    width: "calc(50% - 40px)",
  },
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 40px)",
  },
}));

const Summarization = styled("div")(({ theme }) => ({
  width: "calc(40% - 40px)",
  height: "calc(100% - 40px)",
  margin: "20px 20px",
  paddingLeft: "20px",
  borderLeft: "1px solid",
  borderColor: theme.palette.grey[500],
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    width: "calc(50% - 40px)",
  },
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 40px)",
    paddingLeft: "0px",
    borderLeft: "0px",
  },
}));

const Duration = styled("div")(({ theme }) => ({
  "& .FromdateUntildate": {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  "& .Text": {
    fontSize: "30px",
  },
  "& .Dash": {
    margin: "0 10px",
  },
  "& .TextDuration": {
    fontSize: "26px",
    color: theme.palette.grey[500],
  },
}));

const Extras = styled("div")(({ theme }) => ({
  height: "calc(100% - 106px)",
  "& .Title": {
    fontSize: "26px",
  },
  "& .IconExtra": {
    color: theme.palette.warning.main,
    marginRight: "10px",
  },
}));

const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  alignItems: "flex-start",
  "& .MuiCheckbox-root": {
    paddingTop: "5px",
  },
}));

const LabelCheckBox = styled("div")(({ theme }) => ({
  "& .label": {
    fontSize: "18px",
  },
  "& .Extra": {
    fontSize: "20px",
    fontWeight: "bold",
  },
}));

const TextAndPrice = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "10px",
  "& .Text": {
    fontSize: "18px",
  },
  "& .TotalText": {
    fontSize: "24px",
    color: "green",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  marginBottom: "10px",
  "& .answer": {
    color: theme.palette.success.main,
    fontSize: "20px",
    fontWeight: "bold",
  },
}));

const OutlineGreenButton = styled(Button)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  fontSize: "16px",
  fontWeight: "bold",
}));

const DetailCar = (props) => {
  const dispatch = useDispatch();
  const { result: LentCar } = useSelector((state) => state.lentCar);
  const [reviews, setReviews] = useState([]);
  const [listExOptions, setListExOptions] = useState([1, 2, 4, 7, 5]);
  const [listExConditions, setListExConditions] = useState([1, 2, 3, 4, 5]);
  const [listExExtras, setListExExtras] = useState([
    { extra: 1, price: 2000, duration: "การเดินทาง" },
    { extra: 2, price: 1000, duration: "การเดินทาง" },
    { extra: 5, price: 200, duration: "วัน" },
    { extra: 6, price: 200, duration: "วัน" },
  ]);

  const [fromDate, setFromDate] = useState(new Date());
  const [untilDate, setUntilDate] = useState(new Date());
  const [place, setPlace] = useState("");

  const [continuousClick, setContinuousClick] = useState(false);
  const [extrasChosen, setExtrasChosen] = useState([]);

  const IpadVertical = useMediaQuery("(max-width:780px)");
  const MobileVertical = useMediaQuery("(max-width:500px)");

  const listImage = [
    "HondaJazz.jpeg",
    "example2.jpeg",
    "example3.jpeg",
    "example4.jpeg",
  ];

  useEffect(() => {
    dispatch(getLentCarById(props.location.state.idCar));
    reviewServices.getReviewsByCarId(props.location.state.idCar).then((res) => {
      if (res.data.length > 0) {
        setReviews(res.data);
      }
    });
    if (props.location.state.pending) {
      HistoryLentCarServices.getHistoryLentCarById(
        props.location.state.lentId
      ).then((res) => {
        if (res.data) {
          setFromDate(new Date(res.data.datelent));
          setUntilDate(new Date(res.data.datereturn));
          setPlace(`${res.data.province}, ${res.data.district}`);
          console.log("res.data : ", res.data.extras);
          if (res.data.extras.length > 0) {
            let tempExtras = [];
            res.data.extras.map((extra) => {
              tempExtras.push(extra.idExtra);
            });
            setExtrasChosen(tempExtras);
          }
        }
      });
    }
  }, []);

  const CheckExtrasFromList = (idExtra) => {
    let result = false;
    extrasChosen.map((extra) => {
      if (parseInt(extra) === idExtra) {
        result = true;
      }
    });
    return result;
  };

  const PriceOfExtraFromList = (idExtra) => {
    let price = 0;
    listExExtras.map((extra) => {
      if (extra.extra === idExtra) {
        price = extra.price;
        if (extra.duration === "วัน") {
          price *= CalculateDurationDay();
        }
      }
    });
    return price;
  };

  const CalculateDurationDay = () => {
    let Duration = new Date(untilDate) - new Date(fromDate);
    if (Duration === 0) {
      return 1;
    } else {
      return Math.ceil(Duration / (1000 * 3600 * 24));
    }
  };

  const CalculateMoneyPerDay = () => {
    let Price = CalculateDurationDay() * LentCar.price;
    return Price;
  };

  const CalculateFreeCancellationDate = () => {
    let date = dayjs(fromDate).add(-1, "day");
    return date.format("DD/MM/YYYY");
  };

  const CalculateExtrasPrice = () => {
    let price = 0;
    extrasChosen.map((index) => {
      price += PriceOfExtraFromList(index);
    });
    return price;
  };

  const CalcualteTripPriceTotal = () => {
    return (
      LentCar.earlybird * CalculateDurationDay() +
      LentCar.pricepackage +
      1000 +
      CalculateExtrasPrice()
    );
  };

  const CalcualtePriceTotal = () => {
    let result = CalcualteTripPriceTotal();
    let duration = CalculateDurationDay();
    if (duration >= 3) {
      result -= (LentCar.price - LentCar.price3Dplus) * duration;
    }
    if (duration >= 30) {
      result -= (LentCar.price - LentCar.price1Mplus) * duration;
    }
    return result;
  };

  const onClickCheckBoxHandler = (event) => {
    if (extrasChosen.includes(event.target.name)) {
      setExtrasChosen([
        ...extrasChosen.filter((extraId) => extraId !== event.target.name),
      ]);
    } else {
      setExtrasChosen([...extrasChosen, event.target.name]);
    }
  };

  return (
    <div className="page">
      {LentCar && (
        <div style={{ height: "100%" }}>
          <PartOfImageCar listImage={listImage} />
          <PartOfContent>
            <div style={{ width: "90%", display: "flex", margin: "10px" }}>
              <PartOfDetail>
                <PartOfModelTextAndPoint
                  LentCar={(({
                    brand,
                    model,
                    year,
                    point,
                    numberBorrowed,
                  }) => ({ brand, model, year, point, numberBorrowed }))(
                    LentCar
                  )}
                  edit={props.location.state.edit}
                />
                <DividerStyled />
                {IpadVertical && (
                  <>
                    {props.location.state.purpose === "lending" && (
                      <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <ContentInputChat>
                            <TopicCard>วันที่เริ่ม</TopicCard>
                            <div className="InputDateAndTime">
                              <MobileDatePicker
                                value={fromDate}
                                minDate={new Date()}
                                onChange={(date) => {
                                  setFromDate(date);
                                }}
                                disabled={props.location.state.pending}
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => (
                                  <TextField className="InputBox" {...params} />
                                )}
                              />
                              <SelectTime
                                disabled={props.location.state.pending}
                              >
                                <Select native defaultValue="">
                                  {[...new Array(48)].map((value, index) => {
                                    if (index === 0) {
                                      return (
                                        <option value="Midnight" key="Midnight">
                                          Midnight
                                        </option>
                                      );
                                    } else if (index === 24) {
                                      return (
                                        <option value="Noon" key="Noon">
                                          Noon
                                        </option>
                                      );
                                    } else {
                                      let hour = Math.floor(
                                        (0 + index * 30) / 60
                                      );
                                      let minute = Math.floor(
                                        (0 + index * 30) % 60
                                      );
                                      if (minute < 10) {
                                        minute = "0" + minute;
                                      }
                                      return (
                                        <option
                                          value={hour + ":" + minute}
                                          key={hour + ":" + minute}
                                        >
                                          {hour + ":" + minute}
                                        </option>
                                      );
                                    }
                                  })}
                                </Select>
                              </SelectTime>
                            </div>
                          </ContentInputChat>
                          <ContentInputChat>
                            <TopicCard>วันสิ้นสุด</TopicCard>
                            <div className="InputDateAndTime">
                              <MobileDatePicker
                                value={untilDate}
                                minDate={new Date()}
                                onChange={(date) => {
                                  setUntilDate(date);
                                }}
                                disabled={props.location.state.pending}
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => (
                                  <TextField className="InputBox" {...params} />
                                )}
                              />
                              <SelectTime
                                disabled={props.location.state.pending}
                              >
                                <Select native defaultValue="">
                                  {[...new Array(48)].map((value, index) => {
                                    if (index === 0) {
                                      return (
                                        <option value="Midnight" key="Midnight">
                                          Midnight
                                        </option>
                                      );
                                    } else if (index === 24) {
                                      return (
                                        <option value="Noon" key="Noon">
                                          Noon
                                        </option>
                                      );
                                    } else {
                                      let hour = Math.floor(
                                        (0 + index * 30) / 60
                                      );
                                      let minute = Math.floor(
                                        (0 + index * 30) % 60
                                      );
                                      if (minute < 10) {
                                        minute = "0" + minute;
                                      }
                                      return (
                                        <option
                                          value={hour + ":" + minute}
                                          key={hour + ":" + minute}
                                        >
                                          {hour + ":" + minute}
                                        </option>
                                      );
                                    }
                                  })}
                                </Select>
                              </SelectTime>
                            </div>
                          </ContentInputChat>
                        </LocalizationProvider>
                        <ContentInputChat>
                          <TopicCard>สถานที่ที่รับรถและคืนรถ</TopicCard>
                          <TextField
                            fullWidth
                            disabled={props.location.state.pending}
                            value={place}
                            onChange={(event) => setPlace(event.target.value)}
                          />
                        </ContentInputChat>
                        <ContentPrice>
                          <TopicCard>Price</TopicCard>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography className="Topic">{`${CalculateDurationDay()} วัน`}</Typography>
                            <Typography className="Topic">
                              <span className="PriceText">
                                {utils.numberWithCommas(CalculateMoneyPerDay())}
                              </span>{" "}
                              บาท
                            </Typography>
                          </div>
                        </ContentPrice>
                        <DividerStyled />
                        <CancellationFree>
                          <i className="fas fa-coins Icon"></i>
                          <div>
                            <TopicCard>Free Cancellation</TopicCard>
                            <Typography>
                              Full refund before{" "}
                              <span className="DateText">
                                {CalculateFreeCancellationDate()}
                              </span>
                            </Typography>
                          </div>
                        </CancellationFree>
                        <DividerStyled />
                        <GreenButton
                          fullWidth
                          onClick={() => setContinuousClick(true)}
                        >
                          {props.location.state.pending
                            ? "See more Detail"
                            : "Continuous"}
                        </GreenButton>
                        <ChatButton fullWidth>Chat With Host</ChatButton>
                      </>
                    )}
                    {props.location.state.purpose === "view" && (
                      <>
                        <div
                          style={{ position: "relative", paddingTop: "20px" }}
                        >
                          <Text>
                            สถานที่รับ :{" "}
                            <span className="answer">{LentCar.province}</span>,
                            <span className="answer">{LentCar.district}</span>
                          </Text>
                          <Text>
                            Delivery :{" "}
                            <span className="answer">
                              {LentCar.delivery ? "มี" : "ไม่มี"}
                            </span>
                          </Text>
                          <Text>
                            Package :{" "}
                            <span className="answer">
                              {`package ${
                                LentCar.package
                              } ${utils.numberWithCommas(
                                LentCar.pricepackage
                              )}`}{" "}
                            </span>
                            บาท
                          </Text>
                          <ModalPlaceAndPackage
                            packageId={LentCar.packageId}
                            province={LentCar.province}
                            district={LentCar.district}
                          />
                        </div>
                        <DividerStyled />
                        <div
                          style={{ position: "relative", paddingTop: "20px" }}
                        >
                          <Text>
                            ค่าเช่า :{" "}
                            <span className="answer">
                              {utils.numberWithCommas(LentCar.price)}
                            </span>{" "}
                            บาท/วัน
                          </Text>
                          <Text>
                            ค่าเช่า 3 วันขึ้นไป :{" "}
                            <span className="answer">
                              {utils.numberWithCommas(LentCar.price3Dplus)}
                            </span>{" "}
                            บาท/วัน
                          </Text>
                          <Text>
                            ค่าเช่า 1 เดือนขึ้นไป :{" "}
                            <span className="answer">
                              {utils.numberWithCommas(LentCar.price1Mplus)}
                            </span>{" "}
                            บาท/วัน
                          </Text>
                          <Text>
                            ยกเลิกฟรี : ก่อนวันที่นัด{" "}
                            <span className="answer">
                              {LentCar.durationcancellation}
                            </span>{" "}
                            วัน
                          </Text>
                          <ModalPrice
                            price={LentCar.price}
                            deposit={LentCar.deposit}
                            price3Dplus={LentCar.price3Dplus}
                            price1Mplus={LentCar.price1Mplus}
                            earlybird={LentCar.earlybird}
                            earlybirdDay={LentCar.earlybirdDay}
                            durationcancellation={LentCar.durationcancellation}
                            distanceInclude={LentCar.distanceInclude}
                            priceClean={LentCar.priceClean}
                          />
                        </div>
                      </>
                    )}
                    <DividerStyled />
                  </>
                )}
                <PartOfDetailCar
                  LentCar={(({ mpg, gas, noGas, doors, seats, mile }) => ({
                    mpg,
                    gas,
                    noGas,
                    doors,
                    seats,
                    mile,
                  }))(LentCar)}
                  edit={props.location.state.edit}
                />
                <DividerStyled />
                <PartOfDescription
                  LentCar={(({ description }) => ({ description }))(LentCar)}
                  edit={props.location.state.edit}
                />
                <DividerStyled />
                <div>
                  <Topic>Host</Topic>
                  <PartOfHost>
                    <Avatar
                      src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                      className="Avatar"
                    />
                    <div className="partOfText">
                      <Typography className="Name">
                        {LentCar.lenderName}
                      </Typography>
                      <Typography className="Name NumberLendered">
                        {utils.numberWithCommas(LentCar.numberBorrowed)} trips
                      </Typography>
                    </div>
                  </PartOfHost>
                </div>
                <DividerStyled />
                <PartOfFeature
                  listExOptions={listExOptions}
                  setListExOptions={setListExOptions}
                  edit={props.location.state.edit}
                />
                <DividerStyled />
                <PartOfExtra
                  listExExtras={listExExtras}
                  setListExExtras={setListExExtras}
                  edit={props.location.state.edit}
                />
                <DividerStyled />
                <PartOfCondition
                  listExConditions={listExConditions}
                  setListExConditions={setListExConditions}
                  edit={props.location.state.edit}
                />
                <DividerStyled />
                <PartOfRatingAndComment
                  LentCar={(({ point }) => ({ point }))(LentCar)}
                  reviews={reviews}
                />
              </PartOfDetail>
              {!IpadVertical && (
                <PartOfChat>
                  <CardChat>
                    {props.location.state.purpose === "lending" && (
                      <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <ContentInputChat>
                            <TopicCard>วันที่เริ่ม</TopicCard>
                            <div className="InputDateAndTime">
                              <MobileDatePicker
                                value={fromDate}
                                minDate={new Date()}
                                onChange={(date) => {
                                  setFromDate(date);
                                }}
                                disabled={props.location.state.pending}
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => (
                                  <TextField className="InputBox" {...params} />
                                )}
                              />
                              <SelectTime
                                disabled={props.location.state.pending}
                              >
                                <Select native defaultValue="">
                                  {[...new Array(48)].map((value, index) => {
                                    if (index === 0) {
                                      return (
                                        <option value="Midnight" key="Midnight">
                                          Midnight
                                        </option>
                                      );
                                    } else if (index === 24) {
                                      return (
                                        <option value="Noon" key="Noon">
                                          Noon
                                        </option>
                                      );
                                    } else {
                                      let hour = Math.floor(
                                        (0 + index * 30) / 60
                                      );
                                      let minute = Math.floor(
                                        (0 + index * 30) % 60
                                      );
                                      if (minute < 10) {
                                        minute = "0" + minute;
                                      }
                                      return (
                                        <option
                                          value={hour + ":" + minute}
                                          key={hour + ":" + minute}
                                        >
                                          {hour + ":" + minute}
                                        </option>
                                      );
                                    }
                                  })}
                                </Select>
                              </SelectTime>
                            </div>
                          </ContentInputChat>
                          <ContentInputChat>
                            <TopicCard>วันสิ้นสุด</TopicCard>
                            <div className="InputDateAndTime">
                              <MobileDatePicker
                                value={untilDate}
                                minDate={new Date()}
                                onChange={(date) => {
                                  setUntilDate(date);
                                }}
                                disabled={props.location.state.pending}
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => (
                                  <TextField className="InputBox" {...params} />
                                )}
                              />
                              <SelectTime
                                disabled={props.location.state.pending}
                              >
                                <Select native defaultValue="">
                                  {[...new Array(48)].map((value, index) => {
                                    if (index === 0) {
                                      return (
                                        <option value="Midnight" key="Midnight">
                                          Midnight
                                        </option>
                                      );
                                    } else if (index === 24) {
                                      return (
                                        <option value="Noon" key="Noon">
                                          Noon
                                        </option>
                                      );
                                    } else {
                                      let hour = Math.floor(
                                        (0 + index * 30) / 60
                                      );
                                      let minute = Math.floor(
                                        (0 + index * 30) % 60
                                      );
                                      if (minute < 10) {
                                        minute = "0" + minute;
                                      }
                                      return (
                                        <option
                                          value={hour + ":" + minute}
                                          key={hour + ":" + minute}
                                        >
                                          {hour + ":" + minute}
                                        </option>
                                      );
                                    }
                                  })}
                                </Select>
                              </SelectTime>
                            </div>
                          </ContentInputChat>
                        </LocalizationProvider>
                        <ContentInputChat>
                          <TopicCard>สถานที่ที่รับรถและคืนรถ</TopicCard>
                          <TextField
                            fullWidth
                            disabled={props.location.state.pending}
                            value={place}
                            onChange={(event) => setPlace(event.target.value)}
                          />
                        </ContentInputChat>
                        <ContentPrice>
                          <TopicCard>Price</TopicCard>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography className="Topic">{`${CalculateDurationDay()} วัน`}</Typography>
                            <Typography className="Topic">
                              <span className="PriceText">
                                {utils.numberWithCommas(CalculateMoneyPerDay())}
                              </span>{" "}
                              บาท
                            </Typography>
                          </div>
                        </ContentPrice>
                        <DividerStyled />
                        <CancellationFree>
                          <i className="fas fa-coins Icon"></i>
                          <div>
                            <TopicCard>Free Cancellation</TopicCard>
                            <Typography>
                              Full refund before{" "}
                              <span className="DateText">
                                {CalculateFreeCancellationDate()}
                              </span>
                            </Typography>
                          </div>
                        </CancellationFree>
                        <DividerStyled />
                        <GreenButton
                          fullWidth
                          onClick={() => setContinuousClick(true)}
                        >
                          {props.location.state.pending
                            ? "See more Detail"
                            : "Continuous"}
                        </GreenButton>
                        <ChatButton fullWidth>Chat With Host</ChatButton>
                      </>
                    )}
                    {props.location.state.purpose === "view" && (
                      <>
                        <div
                          style={{ position: "relative", paddingTop: "20px" }}
                        >
                          <Text>
                            สถานที่รับ :{" "}
                            <span className="answer">{LentCar.province}</span>,
                            <span className="answer">{LentCar.district}</span>
                          </Text>
                          <Text>
                            Delivery :{" "}
                            <span className="answer">
                              {LentCar.delivery ? "มี" : "ไม่มี"}
                            </span>
                          </Text>
                          <Text>
                            Package :{" "}
                            <span className="answer">
                              {`package ${
                                LentCar.package
                              } ${utils.numberWithCommas(
                                LentCar.pricepackage
                              )}`}{" "}
                            </span>
                            บาท
                          </Text>
                          <ModalPlaceAndPackage
                            edit={props.location.state.edit}
                            packageId={LentCar.packageId}
                            province={LentCar.province}
                            district={LentCar.district}
                          />
                        </div>
                        <DividerStyled />
                        <div
                          style={{ position: "relative", paddingTop: "20px" }}
                        >
                          <Text>
                            ค่าเช่า :{" "}
                            <span className="answer">
                              {utils.numberWithCommas(LentCar.price)}
                            </span>{" "}
                            บาท/วัน
                          </Text>
                          <Text>
                            ค่าเช่า 3 วันขึ้นไป :{" "}
                            <span className="answer">
                              {utils.numberWithCommas(LentCar.price3Dplus)}
                            </span>{" "}
                            บาท/วัน
                          </Text>
                          <Text>
                            ค่าเช่า 1 เดือนขึ้นไป :{" "}
                            <span className="answer">
                              {utils.numberWithCommas(LentCar.price1Mplus)}
                            </span>{" "}
                            บาท/วัน
                          </Text>
                          <Text>
                            ยกเลิกฟรี : ก่อนวันที่นัด{" "}
                            <span className="answer">
                              {LentCar.durationcancellation}
                            </span>{" "}
                            วัน
                          </Text>
                          <ModalPrice
                            edit={props.location.state.edit}
                            price={LentCar.price}
                            deposit={LentCar.deposit}
                            price3Dplus={LentCar.price3Dplus}
                            price1Mplus={LentCar.price1Mplus}
                            earlybird={LentCar.earlybird}
                            earlybirdDay={LentCar.earlybirdDay}
                            durationcancellation={LentCar.durationcancellation}
                            distanceInclude={LentCar.distanceInclude}
                            priceClean={LentCar.priceClean}
                          />
                        </div>
                      </>
                    )}
                  </CardChat>
                </PartOfChat>
              )}
            </div>
          </PartOfContent>
          <ModalStyled
            open={continuousClick}
            onClose={() => setContinuousClick(false)}
          >
            <PaperStyled>
              <DurationAndExtras>
                <Duration>
                  <div className="FromdateUntildate">
                    <Typography className="Text">
                      {dayjs(fromDate).format("D MMMM YYYY")}
                    </Typography>
                    <Typography className="Text Dash"> - </Typography>
                    <Typography className="Text">
                      {dayjs(untilDate).format("D MMMM YYYY")}
                    </Typography>
                  </div>
                  <Typography className="TextDuration">
                    ระยะเวลา {CalculateDurationDay()} วัน
                  </Typography>
                </Duration>
                <DividerStyled />
                <Extras>
                  <Typography className="Title">
                    <i className="fas fa-cubes IconExtra"></i>Extras
                  </Typography>
                  <div
                    style={{
                      height: "calc(100% - 39px)",
                      overflowY: "auto",
                      marginTop: "5px",
                    }}
                  >
                    <Grid container spacing={1}>
                      {listExExtras.map((extra, index) => (
                        <Grid item xs={12} md={6} key={extra.extra}>
                          <FormControlLabelStyled
                            control={
                              <Checkbox
                                name={extra.extra + ""}
                                onClick={(event) =>
                                  onClickCheckBoxHandler(event)
                                }
                                disabled={
                                  props.location.state.pending
                                    ? props.location.state.pending
                                    : false
                                }
                                checked={CheckExtrasFromList(extra.extra)}
                              />
                            }
                            label={
                              <LabelCheckBox>
                                <Typography className="label Extra">
                                  {listExtras[extra.extra - 1].name}
                                </Typography>
                                <Typography className="label">{`${extra.price} บาท/${extra.duration}`}</Typography>
                              </LabelCheckBox>
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Extras>
              </DurationAndExtras>
              <Summarization>
                <div>
                  <TextAndPrice>
                    <Typography className="Text">Price/Day</Typography>
                    <Typography className="Text">
                      {LentCar.price} บาท/วัน
                    </Typography>
                  </TextAndPrice>
                  <TextAndPrice>
                    <Typography className="Text">
                      Early Bird Discount
                    </Typography>
                    <Typography className="Text">
                      {LentCar.earlybird} บาท/วัน
                    </Typography>
                  </TextAndPrice>
                  <TextAndPrice>
                    <Typography className="Text">Total Price/Day</Typography>
                    <Typography className="Text">1900 บาท/วัน</Typography>
                  </TextAndPrice>
                  <TextAndPrice>
                    <Typography className="Text">Package</Typography>
                    <Typography className="Text">
                      {LentCar.pricepackage} บาท
                    </Typography>
                  </TextAndPrice>
                  <TextAndPrice>
                    <Typography className="Text">Delivery</Typography>
                    <Typography className="Text">1000 บาท</Typography>
                  </TextAndPrice>
                  <TextAndPrice>
                    <Typography className="Text">Extras</Typography>
                    <Typography className="Text">
                      {CalculateExtrasPrice()} บาท
                    </Typography>
                  </TextAndPrice>
                  <DividerStyled />
                  <TextAndPrice>
                    <Typography className="Text">Trip Price Total</Typography>
                    <Typography className="Text">
                      {CalcualteTripPriceTotal()} บาท
                    </Typography>
                  </TextAndPrice>
                  {CalculateDurationDay() >= 3 &&
                    CalculateDurationDay() < 30 && (
                      <TextAndPrice>
                        <Typography className="Text">3 Days or More</Typography>
                        <Typography className="Text">
                          - {LentCar.price - LentCar.price3Dplus} บาท/วัน
                        </Typography>
                      </TextAndPrice>
                    )}
                  {CalculateDurationDay() >= 30 && (
                    <TextAndPrice>
                      <Typography className="Text">Month or More</Typography>
                      <Typography className="Text">
                        - {LentCar.price - LentCar.price1Mplus} บาท/วัน
                      </Typography>
                    </TextAndPrice>
                  )}
                  <DividerStyled />
                  <TextAndPrice>
                    <Typography className="Text">มัดจำ/Trip</Typography>
                    <Typography className="Text">
                      {LentCar.deposit} บาท
                    </Typography>
                  </TextAndPrice>
                  <TextAndPrice>
                    <Typography className="Text TotalText">Total</Typography>
                    <Typography className="Text TotalText">
                      {CalcualtePriceTotal()} บาท
                    </Typography>
                  </TextAndPrice>
                </div>
                {props.location.state.pending ? (
                  <GreenButton
                    fullWidth
                    onClick={() => setContinuousClick(false)}
                  >
                    Close
                  </GreenButton>
                ) : (
                  <GreenButton
                    fullWidth
                    // onClick={()=>props.history.push("/friend2friend_Car/borrower/dependability")}
                    onClick={() =>
                      props.history.push("/friend2friend_Car/borrower")
                    }
                  >
                    Confirm
                  </GreenButton>
                )}
                {MobileVertical && (
                  <OutlineGreenButton
                    fullWidth
                    onClick={() => setContinuousClick(false)}
                  >
                    Close
                  </OutlineGreenButton>
                )}
              </Summarization>
            </PaperStyled>
          </ModalStyled>
        </div>
      )}
    </div>
  );
};

export default DetailCar;
