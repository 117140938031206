import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "auth/";

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      // console.log(response.data)

      if(response.data.type === "not_found"){
        alert(response.data.msg)
        window.location = "/login"
        return
      }
      else if(response.data.type === "password_invalid"){
        alert(response.data.msg)
        window.location = "/login"
        return
      }
      

      if (response.data.returnData.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data.returnData));
        
      }

      return response.data.returnData;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  login,
  logout,
};
