import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Container from "@mui/material/Container";

import { useSelector } from "react-redux";
import {
  styled,
  Grid,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Button,
  Rating,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import DateAdapter from "@mui/lab/AdapterDayjs";
import Item from "./components/Item";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import CrossAreaCarBookingService from "../../../../services/crossAreaCarBooking.service";
import CrossAreaCarPoolBookingService from "../../../../services/crossAreaCarPoolBooking.service";
import DeliveryCarBookingService from "../../../../services/deliveryCarBooking.service";
import DriverBookingService from "../../../../services/driverBooking.service";
import InAreaCarBookingService from "../../../../services/inAreaCarBooking.service";
import MeetingRoomService from "../../../../services/meetingRoom.service";

import ReviewService from "../../../../services/review.service";

import utils from "../../../../utils/index";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  // padding: 16,
  // maxWidth: "1200px",
}));

const EvaluateSatisfaction = (props) => {
  const { result: userRouteReducer } = useSelector((state) => state.routeUser);
  const { register, handleSubmit, control, watch, getValues } = useForm();
  const [error, setError] = useState(null);

  const [CrossAreaCarBooking, setCrossAreaCarBooking] = useState([]);
  const [CrossAreaCarPoolBooking, setCrossAreaCarPoolBooking] = useState([]);
  const [DeliveryCarBooking, setDeliveryCarBooking] = useState([]);
  const [DriverBooking, setDriverBooking] = useState([]);
  const [InAreaCarBooking, setInAreaCarBooking] = useState([]);
  const [MeetingRoom, setMeetingRoomService] = useState([]);

  const [getAll, setgetAll] = useState(false);
  const [getRatingSucces, setgetRatingSucces] = useState("all");
  const { user: currentUser } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    CrossAreaCarBookingService.getCrossAreaCarBookingByIdUserForRating(
      currentUser.idUser
    ).then((res) => {
      setCrossAreaCarBooking(res.data);
      setLoading(false);
      // setError(res.data.error);
    });
    CrossAreaCarPoolBookingService.getCrossAreaCarPoolBookingByIdUserForRating(
      currentUser.idUser
    ).then((res) => {
      setCrossAreaCarPoolBooking(res.data);
      setLoading(false);
    });
    DeliveryCarBookingService.getDeliveryCarBookingByIdUserForRating(
      currentUser.idUser
    ).then((res) => {
      setDeliveryCarBooking(res.data);
      setLoading(false);
    });
    DriverBookingService.getDriverBookingByIdUserForRating(
      currentUser.idUser
    ).then((res) => {
      setDriverBooking(res.data);
      setLoading(false);
    });
    InAreaCarBookingService.getInAreaCarBookingByIdUserForRating(
      currentUser.idUser
    ).then((res) => {
      setInAreaCarBooking(res.data);
      setLoading(false);
    });
    MeetingRoomService.getMeetingRoomBookingByIdUserForRating(
      currentUser.idUser
    ).then((res) => {
      setMeetingRoomService(res.data);
      setLoading(false);
    });

    // maintenanceService.getAllMyHistoryMaintenanceRequest({idUser:currentUser.idUser}).then(res => {

    // 	setRequestedMaintenance(res.data.data);
    // 	setLoading(false);
    // 	setError(res.data.error);
    // })
  }, []);

  const OnClickHandleSubmit = (data) => {
    let point = parseFloat(data.point);
    if (point > 0) {
      if (point > 2) {
        console.log(data);
      } else if (point <= 2 && data.comment.length > 0) {
        console.log(data);
      }
    }
  };
  const onClickSearch = () => {
    setLoading(true);
    console.log(getValues("TypeOfBooking"));
    if (getValues("TypeOfBooking") === 0 && getValues("status") === 0) {
      setgetRatingSucces("all");
      CrossAreaCarBookingService.getCrossAreaCarBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setCrossAreaCarBooking(res.data);
        setLoading(false);
        // setError(res.data.error);
      });
      CrossAreaCarPoolBookingService.getCrossAreaCarPoolBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setCrossAreaCarPoolBooking(res.data);
        setLoading(false);
      });
      DeliveryCarBookingService.getDeliveryCarBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setDeliveryCarBooking(res.data);
        setLoading(false);
      });
      DriverBookingService.getDriverBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setDriverBooking(res.data);
        setLoading(false);
      });
      InAreaCarBookingService.getInAreaCarBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setInAreaCarBooking(res.data);
        setLoading(false);
      });
      MeetingRoomService.getMeetingRoomBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setMeetingRoomService(res.data);
        setLoading(false);
      });
      setLoading(false);
    } else if (getValues("TypeOfBooking") === 0 && getValues("status") === 1) {
      setgetRatingSucces("false");
      CrossAreaCarBookingService.getCrossAreaCarBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setCrossAreaCarBooking(res.data);
        setLoading(false);
        // setError(res.data.error);
      });
      CrossAreaCarPoolBookingService.getCrossAreaCarPoolBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setCrossAreaCarPoolBooking(res.data);
        setLoading(false);
      });
      DeliveryCarBookingService.getDeliveryCarBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setDeliveryCarBooking(res.data);
        setLoading(false);
      });
      DriverBookingService.getDriverBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setDriverBooking(res.data);
        setLoading(false);
      });
      InAreaCarBookingService.getInAreaCarBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setInAreaCarBooking(res.data);
        setLoading(false);
      });
      MeetingRoomService.getMeetingRoomBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setMeetingRoomService(res.data);
        setLoading(false);
      });
      setLoading(false);
    } else if (getValues("TypeOfBooking") === 0 && getValues("status") === 2) {
      setgetRatingSucces("true");
      CrossAreaCarBookingService.getCrossAreaCarBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setCrossAreaCarBooking(res.data);
        setLoading(false);
        // setError(res.data.error);
      });
      CrossAreaCarPoolBookingService.getCrossAreaCarPoolBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setCrossAreaCarPoolBooking(res.data);
        setLoading(false);
      });
      DeliveryCarBookingService.getDeliveryCarBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setDeliveryCarBooking(res.data);
        setLoading(false);
      });
      DriverBookingService.getDriverBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setDriverBooking(res.data);
        setLoading(false);
      });
      InAreaCarBookingService.getInAreaCarBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setInAreaCarBooking(res.data);
        setLoading(false);
      });
      MeetingRoomService.getMeetingRoomBookingByIdUserForRating(
        currentUser.idUser
      ).then((res) => {
        setMeetingRoomService(res.data);
        setLoading(false);
      });
      setLoading(false);
    } else {
      setgetRatingSucces("all");
      ReviewService.getAllReviewsBySearch({
        TypeOfBooking: getValues("TypeOfBooking") || null,
        status: getValues("status") || null,
        idUser: currentUser.idUser,
      }).then((res) => {
        if (res.data) {
          console.log(getValues("TypeOfBooking"));
          if (getValues("TypeOfBooking") === "CrossArea") {
            setCrossAreaCarBooking(res.data);
            setCrossAreaCarPoolBooking([]);
            setDeliveryCarBooking([]);
            setDriverBooking([]);
            setInAreaCarBooking([]);
            setMeetingRoomService([]);
          } else if (getValues("TypeOfBooking") === "CrossAreaPool") {
            setCrossAreaCarBooking([]);
            setCrossAreaCarPoolBooking(res.data);
            setDeliveryCarBooking([]);
            setDriverBooking([]);
            setInAreaCarBooking([]);
            setMeetingRoomService([]);
          } else if (getValues("TypeOfBooking") === "InArea") {
            setCrossAreaCarBooking([]);
            setCrossAreaCarPoolBooking([]);
            setDeliveryCarBooking([]);
            setDriverBooking([]);
            setInAreaCarBooking(res.data);
            setMeetingRoomService([]);
          } else if (getValues("TypeOfBooking") === "Delivery") {
            setCrossAreaCarBooking([]);
            setCrossAreaCarPoolBooking([]);
            setDeliveryCarBooking(res.data);
            setDriverBooking([]);
            setInAreaCarBooking([]);
            setMeetingRoomService([]);
          } else if (getValues("TypeOfBooking") === "MeetingRoom") {
            setCrossAreaCarBooking([]);
            setCrossAreaCarPoolBooking([]);
            setDeliveryCarBooking([]);
            setDriverBooking([]);
            setInAreaCarBooking([]);
            setMeetingRoomService(res.data);
          } else if (getValues("TypeOfBooking") === "DriverBooking") {
            setCrossAreaCarBooking([]);
            setCrossAreaCarPoolBooking([]);
            setDeliveryCarBooking([]);
            setDriverBooking(res.data);
            setInAreaCarBooking([]);
            setMeetingRoomService([]);
          }

          setLoading(false);
        }
      });
    }

    // maintenanceService.getAllMyHistoryMaintenanceRequest({maintenanceType: getValues("maintenanceType")||null, status: getValues("status")||null, date: getValues("date")||null, idUser:currentUser.idUser})
    // .then(res => {
    // 	setRequestedMaintenance(res.data.data);
    // 	setLoading(false);
    // 	setError(res.data.error);
    // })
  };

  return (
    <Container maxWidth="xl">
      <div className="page">
        <Root>
          <Typography fontSize={34} marginBottom={2} marginTop={3}>
            รายการทั้งหมด
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} container>
              <Stack
                direction="row"
                spacing={{ xs: 1, sm: 2 }}
                useFlexGap
                flexWrap="wrap"
              >
                <TextField
                  inputProps={{ ...register("TypeOfBooking") }}
                  label="ประเภทการจอง"
                  style={{ minWidth: 345 }}
                  InputProps={{
                    style: {
                      borderRadius: "5px",
                    },
                  }}
                  select
                  defaultValue={0}
                >
                  <MenuItem value={0}>ทั้งหมด</MenuItem>
                  <MenuItem value={"CrossArea"}>จองรถข้ามพื้นที่เฉพาะ</MenuItem>
                  <MenuItem value={"CrossAreaPool"}>
                    จองรถข้ามพื้นที่ Pool
                  </MenuItem>
                  <MenuItem value={"InArea"}>จองรถในพื้นที่</MenuItem>
                  <MenuItem value={"Delivery"}>จองรถรับส่งของ</MenuItem>
                  <MenuItem value={"MeetingRoom"}>จองสถานที่</MenuItem>
                  <MenuItem value={"DriverBooking"}>จองคนขับรถ</MenuItem>
                </TextField>
                <TextField
                  inputProps={{ ...register("status") }}
                  label="สถานะ"
                  style={{ minWidth: 345 }}
                  InputProps={{
                    style: {
                      borderRadius: "5px",
                    },
                  }}
                  select
                  defaultValue={0}
                >
                  <MenuItem value={0}>ทั้งหมด</MenuItem>
                  <MenuItem value={1}>ยังไม่ได้ประเมิน</MenuItem>
                  <MenuItem value={2}>ประเมินเสร็จสิ้นแล้ว</MenuItem>
                </TextField>
              </Stack>
              <Grid
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  },
                })}
              >
                <Button
                  variant="contained"
                  onClick={onClickSearch}
                  startIcon={<SearchIcon />}
                  sx={(theme) => ({
                    maxWidth: "100px",
                    maxHeight: "40px",
                    marginTop: "7px",
                    borderRadius: "15px",
                    marginLeft: "15px",
                  })}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
            {loading && (
              <Grid item xs={12}>
                <Typography fontSize={20} textAlign="center">
                  กำลังโหลดข้อมูล...
                </Typography>
              </Grid>
            )}
            {!loading &&
              CrossAreaCarBooking.length === 0 &&
              CrossAreaCarPoolBooking.length === 0 &&
              DeliveryCarBooking.length === 0 &&
              DriverBooking.length === 0 &&
              InAreaCarBooking.length === 0 &&
              MeetingRoom.length === 0 && (
                <Grid item xs={12}>
                  <Typography fontSize={20} textAlign="center">
                    ไม่พบข้อมูล
                  </Typography>
                </Grid>
              )}
            {CrossAreaCarBooking.map((data, index) => (
              <>
                {getRatingSucces === "all" &&
                  data.statusApproved === "Success" &&
                  data.Approved === "Success" &&
                  data.statusManageCar === "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12} lg={12}>
                      <Item data={data} />
                    </Grid>
                  )}
                {getRatingSucces === "true" &&
                  data.statusApproved === "Success" &&
                  data.Approved === "Success" &&
                  data.statusManageCar === "Success" &&
                  data.statusRating === "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      <Item data={data} />
                    </Grid>
                  )}
                {getRatingSucces === "false" &&
                  data.statusApproved === "Success" &&
                  data.Approved === "Success" &&
                  data.statusManageCar === "Success" &&
                  data.statusRating != "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      <Item data={data} />
                    </Grid>
                  )}
              </>
            ))}
            {CrossAreaCarPoolBooking.map((data, index) => (
              <>
                {getRatingSucces === "all" &&
                  data.statusApproved === "Success" &&
                  data.Approved === "Success" &&
                  data.statusManageCar === "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      <Item data={data} />
                    </Grid>
                  )}
                {getRatingSucces === "true" &&
                  data.statusApproved === "Success" &&
                  data.Approved === "Success" &&
                  data.statusManageCar === "Success" &&
                  data.statusRating === "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      <Item data={data} />
                    </Grid>
                  )}
                {getRatingSucces === "false" &&
                  data.statusApproved === "Success" &&
                  data.Approved === "Success" &&
                  data.statusManageCar === "Success" &&
                  data.statusRating != "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      <Item data={data} />
                    </Grid>
                  )}
              </>
            ))}
            {DeliveryCarBooking.map((data, index) => (
              <>
                {getRatingSucces === "all" &&
                  data.statusApproved === "Success" &&
                  data.Approved === "Success" &&
                  data.statusManageCar === "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      <Item data={data} />
                    </Grid>
                  )}
                {getRatingSucces === "true" &&
                  data.statusApproved === "Success" &&
                  data.Approved === "Success" &&
                  data.statusManageCar === "Success" &&
                  data.statusRating === "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      <Item data={data} />
                    </Grid>
                  )}
                {getRatingSucces === "false" &&
                  data.statusApproved === "Success" &&
                  data.Approved === "Success" &&
                  data.statusManageCar === "Success" &&
                  data.statusRating != "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      <Item data={data} />
                    </Grid>
                  )}
              </>
            ))}
            {DriverBooking.map((data, index) => (
              <>
                {getRatingSucces === "all" &&
                  data.statusManageCar === "Success" &&
                  data.statusDelivery === "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      <Item data={data} />
                    </Grid>
                  )}
                {getRatingSucces === "true" &&
                  data.statusManageCar === "Success" &&
                  data.statusDelivery === "Success" &&
                  data.statusRating === "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      <Item data={data} />
                    </Grid>
                  )}
                {getRatingSucces === "false" &&
                  data.statusManageCar === "Success" &&
                  data.statusDelivery === "Success" &&
                  data.statusRating != "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      <Item data={data} />
                    </Grid>
                  )}
              </>
            ))}
            {InAreaCarBooking.map((data, index) => (
              <>
                {getRatingSucces === "all" &&
                  data.statusApproved === "Success" &&
                  data.Approved === "Success" &&
                  data.statusManageCar === "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      {" "}
                      <Item data={data} />{" "}
                    </Grid>
                  )}
                {getRatingSucces === "true" &&
                  data.statusApproved === "Success" &&
                  data.Approved === "Success" &&
                  data.statusManageCar === "Success" &&
                  data.statusRating === "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      {" "}
                      <Item data={data} />{" "}
                    </Grid>
                  )}
                {getRatingSucces === "false" &&
                  data.statusApproved === "Success" &&
                  data.Approved === "Success" &&
                  data.statusManageCar === "Success" &&
                  data.statusRating != "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      {" "}
                      <Item data={data} />{" "}
                    </Grid>
                  )}
              </>
            ))}
            {MeetingRoom.map((data, index) => (
              <>
                {getRatingSucces === "all" &&
                  data.statusApprove === "Success" &&
                  data.statusPayment === "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      {" "}
                      <Item data={data} />{" "}
                    </Grid>
                  )}
                {getRatingSucces === "true" &&
                  data.statusApprove === "Success" &&
                  data.statusPayment === "Success" &&
                  data.statusRating === "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      {" "}
                      <Item data={data} />{" "}
                    </Grid>
                  )}
                {getRatingSucces === "false" &&
                  data.statusApprove === "Success" &&
                  data.statusPayment === "Success" &&
                  data.statusRating != "Success" && (
                    <Grid key={`maintenance_${index}`} item xs={12}>
                      {" "}
                      <Item data={data} />{" "}
                    </Grid>
                  )}
              </>
            ))}
          </Grid>
        </Root>
      </div>
    </Container>
  );
};

export default EvaluateSatisfaction;
