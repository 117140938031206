import React from 'react';

import { styled } from '@mui/styles';
import {
    Button, Typography
} from '@mui/material';

const DivCoverReturn = styled("div")({
    width:"200px",
    height:"80px",
    position: "absolute",
    right: "60px",
    bottom: "25px",
    zIndex:"5",
});

const ButtonStyled = styled(Button)({
    width: "100%",
    height: "100%",
    backgroundColor: "#006400",
    "&:hover":{
        backgroundColor: "#006400",
    }
});

const IconStyled = styled("img")({
    width: "70px",
    height: "70px",
});

const Text = styled(Typography)({
    color: "white",
    fontSize: "30px",
    fontWeight: "bold",
    marginTop: "10px",
    marginLeft: "10px",
});

const ButtonGoToPlanPage = (props) => {

    return(
        <DivCoverReturn>
            <ButtonStyled onClick={props.OnClickPlanButton}>
                <IconStyled src="./assets/Image/Icon/team.png" alt="Icon_team"/>
                <Text>Plan</Text>
            </ButtonStyled>
        </DivCoverReturn>
    );
};

export default ButtonGoToPlanPage;