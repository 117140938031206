import React, { useEffect, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import {
    Typography,
    Button,
    TextField,
    Grid,
    Card,
    CardContent,
    Divider,
    Chip,
    Backdrop,
    CircularProgress,
    Snackbar,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import Alert from '@mui/material/Alert';
import SaveIcon from "@mui/icons-material/Save";
import RoomIcon from "@mui/icons-material/Room";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import GoogleMap from "google-map-react";
import * as Yup from "yup";
import { Formik } from "formik";
/* import * as userActions from "./../../../actions/user.action";
import * as loginActions from "./../../../actions/login.action";
import ReactGA from 'react-ga'; */
import DialogEditLocation from "../dialog/DialogEditLocation";

import { getUserProfile } from "../../../../actions/user";
import { GoogleAPIkey, locationSite1 } from "../../../../utils/googleMapAssets";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 50,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height:"fit-content",
        background: "#eee",
        minWidth: 400,
    },
    trackView: {
        marginTop: 10,
        textalign: "center",
        width: "100%"
    },
    addressProfile: {
        marginTop: 10,
        textalign: "left",
        padding: 0
    },
    mapProfile: {
        height: "40vh",
        width: "100%",
        marginTop: 10,
        "& .GPSIcon":{
            color:"red",
            fontSize:"30px",
            transform:"translate(-40%,-75%)",
        },
    },
    infoProfile: {
        marginBottom: 25
    },
    infoTxtProfile: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    imageProfile: {
        borderRadius: "50%"
    },
    tagShift: {
        marginTop: 5
    },
    rowField: {
        display: "flex",
        marginBottom: 10
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    ImgExampleMap:{
        height:"100%",
        width:"100%",
    },
    BackButton:{
        position:"absolute",
        left:"20px",
        top:"10px",
        fontSize:"20px",
    },
    partOfTargetLocation:{
        width:"100%",
    },
}));

const EditProfilePage = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user : AuthUser } = useSelector((state) => state.auth);
    const userReducer = useSelector((state) => state.userProfile );
    const [locationLatLong, setLocationLatLong] = React.useState({Lat: locationSite1.lat, Long: locationSite1.lng});
    /* const [locationLatLong, setLocationLatLong] = React.useState({ Lat: location.site1_lat, Long: location.site1_lng }); */
    const [openPinMap, setOpenPinMap] = React.useState(false);
    const [showInfoWindow, setShowInfoWindow] = React.useState(false);
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(false);
    const formRef = useRef();

    useEffect(() => {
        if(!props.location.state){
            dispatch(getUserProfile(AuthUser.id));
        }else{
            let employee = props.location.state.employee;
            setLocationLatLong({Lat: employee.lat, Long: employee.long});
        }
        /* ReactGA.initialize('UA-164134822-1');
        ReactGA.pageview(window.location.pathname + window.location.search); */
        
       //dispatch(userActions.getUserProfile())
        /* if (!localStorage.getItem("username")) {
            dispatch(loginActions.logout(props.history));
            props.history.push("/");
        } */
    }, []);

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.values.lat = locationLatLong.Lat
            formRef.current.values.long = locationLatLong.Long
            formRef.current.handleSubmit()
        }
    }
/* 
    const handleLocation = location => {
        setLocationLatLong({ Lat: location.lat, Long: location.lng });
        setOpenPinMap(false);
    }; */

    const handleOpenPinMap = () => {

        setOpenPinMap(true);
    };

    const handleClosePinMap = () => {
        setOpenPinMap(false);
    };

    const handleResponseLocation = locationLatLong => {
        if (formRef.current) {
            formRef.current.values.lat = locationLatLong.Lat
            formRef.current.values.long = locationLatLong.Long
        }
        setLocationLatLong({ Lat: locationLatLong.Lat, Long: locationLatLong.Long });
        setOpenPinMap(false);
    };

    const SignupSchema = Yup.object().shape({
        addressNumber: Yup.string().required("Required"),
        villageSoi: Yup.string().required("Required"),
        subdistrictTambol: Yup.string().required("Required"),
        district: Yup.string().required("Required"),
        province: Yup.string().required("Required"),
        postalCode: Yup.string().required("Required"),
    });

    const MarkerCustom = () => {
        return (
            <Fragment>
                <i className="fas fa-map-marker-alt GPSIcon"></i>
            </Fragment>
        );
    };

    const showForm = ({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        errors,
        handleBlur,
        handleReset,
    }) => {
        return (
            <form onSubmit={handleSubmit}>
                {console.log("values : ",values)}
                <Grid container spacing={3} className={classes.rowField}>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            fullWidth
                            required
                            id="addressNumber"
                            name="addressNumber"
                            label="บ้านเลขที่"
                            onChange={handleChange}
                            value={values.addressNumber}
                            onBlur={handleBlur}
                            helperText={touched.addressNumber ? errors.addressNumber : ""}
                            error={touched.addressNumber && Boolean(errors.addressNumber)}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                    <TextField
                            fullWidth
                            required
                            id="villageSoi"
                            name="villageSoi"
                            label="หมู่ที่/หมู่บ้าน/ซอย"
                            onChange={handleChange}
                            value={values.villageSoi}
                            onBlur={handleBlur}
                            helperText={touched.villageSoi ? errors.villageSoi : ""}
                            error={touched.villageSoi && Boolean(errors.villageSoi)}
                        ></TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.rowField}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            style={{ marginRight: 5 }}
                            id="streetRoad"
                            name="streetRoad"
                            label="ถนน"
                            onChange={handleChange}
                            value={values.streetRoad}
                            fullWidth
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            required
                            id="subdistrictTambol"
                            name="subdistrictTambol"
                            label="ตำบล"
                            onChange={handleChange}
                            value={values.subdistrictTambol}
                            onBlur={handleBlur}
                            helperText={
                                touched.subdistrictTambol ? errors.subdistrictTambol : ""
                            }
                            error={
                                touched.subdistrictTambol && Boolean(errors.subdistrictTambol)
                            }
                        ></TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.rowField}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            id="district"
                            name="district"
                            label="อำเภอ"
                            onChange={handleChange}
                            value={values.district}
                            onBlur={handleBlur}
                            helperText={touched.district ? errors.district : ""}
                            error={touched.district && Boolean(errors.district)}
                            fullWidth
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            id="province"
                            name="province"
                            label="จังหวัด"
                            onChange={handleChange}
                            value={values.province}
                            onBlur={handleBlur}
                            helperText={touched.province ? errors.province : ""}
                            error={touched.province && Boolean(errors.province)}
                            fullWidth
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            id="postalCode"
                            name="postalCode"
                            label="รหัสไปรษณีย์"
                            onChange={handleChange}
                            value={values.postalCode}
                            onBlur={handleBlur}
                            helperText={touched.postalCode ? errors.postalCode : ""}
                            error={touched.postalCode && Boolean(errors.postalCode)}
                            fullWidth
                        ></TextField>
                    </Grid>
                </Grid>
                <div style={{ marginTop: "30px" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                    >
                        <Typography className={classes.title}>
                             ปักหมุดที่อยู่
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={handleOpenPinMap}
                            endIcon={<RoomIcon>pin</RoomIcon>}
                        >
                            ปักหมุด
                        </Button>
                    </div>
                    <Divider style={{ margin: "5px 0" }}></Divider>
                    <Grid container spacing={3} className={classes.rowField}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                style={{ 
                                    marginRight: 5,
                                    ["&:hover"]:{
                                        backgroundColor:"red",
                                    }
                                }}
                                id="lat"
                                name="lat"
                                label="ตำแหน่ง Lat"
                                value={values.lat}
                                fullWidth
                                InputProps={{
                                    readOnly: true
                                }}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField

                                style={{ marginRight: 5 }}
                                id="long"
                                name="long"
                                label="ตำแหน่ง Long"
                                value={values.long}
                                fullWidth
                                InputProps={{
                                    readOnly: true
                                }}
                            ></TextField>
                        </Grid>
                    </Grid>
                    
                    <div className={classes.mapProfile}>
                        <GoogleMap
                            defaultZoom={15}
                            defaultCenter={{lat : locationSite1.lat,lng : locationSite1.lng}}
                            center={{lat : locationLatLong.Lat, lng : locationLatLong.Long }}
                            bootstrapURLKeys={{ key: GoogleAPIkey }}
                        >
                            <MarkerCustom lat={locationLatLong.Lat} lng={locationLatLong.Long} />
                        </GoogleMap>
                        {/* <GoogleMap
                            defaultZoom={11}
                            defaultCenter={{ lat: 12.7051742, lng: 101.1922503 }}
                            bootstrapURLKeys={{ key: googleApiKey }}
                        >
                            <MarkerCustom lat={values.lat} lng={values.long} />
                        </GoogleMap> */}
                    </div>
                    <Typography className={classes.title} style={{marginTop:"20px"}}>
                        สถานที่ปลายทาง
                    </Typography>
                    <Divider style={{ margin: "10px 0" }}></Divider>
                    <div className={`${classes.partOfTargetLocation}`}>
                        <FormControl style={{width:"100%"}}>
                            <InputLabel id="TargetLocation">ปลายทาง</InputLabel>
                            <Select
                                labelId="TargetLocation"
                                id="TargetLocation"
                                value={values.targetLocation}
                                onChange={handleChange}
                                style={{ width:"100%" }}
                            >
                                <MenuItem value={"TPE Site 1"}>TPE Site 1</MenuItem>
                                <MenuItem value={"TPE Site 2"}>TPE Site 2</MenuItem>
                                <MenuItem value={"TPE Site 3"}>TPE Site 3</MenuItem>
                                <MenuItem value={"TPE Site 7"}>TPE Site 7</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    {/* Dialog Edit Pin Map */}
                    {openPinMap && (
                        <DialogEditLocation
                            handleClose={handleClosePinMap}
                            selectemployee={{ lat: formRef.current.values.lat, long: formRef.current.values.long }}
                            handleResponseLocation={handleResponseLocation}
                            openLocation={openPinMap}
                        />
                    )}
                </div>
            </form>
        );
    };

    return (
        <div className={classes.root}>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => { setShowSuccess(false) }}>
                <Alert onClose={() => { setShowSuccess(false) }} severity="success">
                    บันทึกข้อมูลเรียบร้อย
            </Alert>
            </Snackbar>
            <Snackbar open={showError} autoHideDuration={3000} onClose={() => { setShowError(false) }}>
                <Alert onClose={() => { setShowError(false) }} severity="error">
                    เกิดข้อผิดพลาด ลองอีกครั้ง
            </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={showLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {!userReducer.result && !props.location.state ? 
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop> :
                <div style={{ margin: "20px" }}>
                    <div className={classes.trackView}>
                        <div className={classes.infoProfile}>
                            <Card>
                                <CardContent style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", position: "relative" }}>
                                <Button 
                                    className={`${classes.BackButton}`}
                                    onClick={()=>props.history.goBack()}
                                >
                                <ArrowBackIosIcon /> Back</Button>
                                    <div>
                                        <img 
                                            className={classes.imageProfile} 
                                            height="150" 
                                            width="150" 
                                            src={`${process.env.REACT_APP_URL}image/profile/1.jpg`} 
                                            alt="1"></img>
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <Typography variant="h4" style={{ fontSize: 30 }}>
                                            {props.location.state && props.location.state.employee.fNameThai}
                                        </Typography>
                                        <Typography color="textSecondary" variant="h6">{props.location.state && props.location.state.employee.departmentName}</Typography>
                                        {/* {userReducer.result.shift && <Chip
                                            color="primary"
                                            label={`SHIFT ${userReducer.result.shift}`}
                                            style={{
                                                fontSize: 16,
                                                width: "50%",
                                                display: "flex",
                                                margin: "0 auto",
                                                marginTop: "10px"
                                            }}
                                        />
                                        } */}
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                        <div className={classes.infoProfile}>
                            <Card style={{ width: "100%" }}>
                                <CardContent style={{ padding: "25px" }}>
                                    <Typography className={classes.title}>
                                         ที่อยู่
                                    </Typography>
                                    <Divider style={{ margin: "10px 0" }}></Divider>
                                    {console.log("props.location.state : ",props.location.state)}
                                    <Formik
                                        innerRef={formRef}
                                        onSubmit={async (values, { setSubmitting }) => {
                                            console.log("formRef: ",formRef)
                                            console.log("values: ",values)
                                            /* setShowLoading(true);
                                            let status = await dispatch(userActions.updateProfile(values))
                                            if (status) { */
                                                setShowSuccess(true);
                                            /*     setShowLoading(false);
                                            } else {
                                                setShowError(true)
                                                setShowLoading(false);
                                            } */
                                        }}
                                        validationSchema={SignupSchema}
                                        initialValues={props.location.state? props.location.state.employee : userReducer.result}
                                    >
                                        {props => showForm(props)}
                                    </Formik>
                                </CardContent>
                            </Card>
                        </div>
                        <div>
                            <Card style={{ width: "100%" }}>
                                <CardContent style={{ padding: 16 }}>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: "auto" }}
                                            className={classes.button}
                                            startIcon={<SaveIcon />}
                                            onClick={handleSubmit}
                                        >
                                            บันทึกข้อมูล
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>

                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default EditProfilePage;
