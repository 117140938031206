import {
  MEETING_ROOM_BOOKINGS_FETCHING,
  MEETING_ROOM_BOOKINGS_FAILED,
  MEETING_ROOM_BOOKINGS_SUCCESS,
} from "./types";

import MeetingRoomService from "../services/meetingRoom.service";

export const getMeetingRoomBookingByIdUser = (idUser) => async (dispatch) => {
  try {
    dispatch({
      type: MEETING_ROOM_BOOKINGS_FETCHING,
    });
    const res = await MeetingRoomService.getMeetingRoomBookingByIdUser(idUser);
    if (res) {
      dispatch({
        type: MEETING_ROOM_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: MEETING_ROOM_BOOKINGS_FAILED,
    });
  }
};

export const getAllMeetingRoomById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: MEETING_ROOM_BOOKINGS_FETCHING,
    });
    const res = await MeetingRoomService.getAllMeetingRoomById(id);
    if (res) {
      dispatch({
        type: MEETING_ROOM_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: MEETING_ROOM_BOOKINGS_FAILED,
    });
  }
};
export const getAllImageNewMeetingRoomById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: MEETING_ROOM_BOOKINGS_FETCHING,
    });
    const res = await MeetingRoomService.getIAllImageNewMeetingRoomById(id);
    if (res) {
      dispatch({
        type: MEETING_ROOM_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: MEETING_ROOM_BOOKINGS_FAILED,
    });
  }
};
export const getMeetingRoomBookingByFilter = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: MEETING_ROOM_BOOKINGS_FETCHING,
    });
    const res = await MeetingRoomService.getMeetingRoomBookingByFilter(filter);
    if (res) {
      dispatch({
        type: MEETING_ROOM_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: MEETING_ROOM_BOOKINGS_FAILED,
    });
  }
};
export const getAllMeetingRoomBookingByIdMeetingRoom =
  (id) => async (dispatch) => {
    try {
      dispatch({
        type: MEETING_ROOM_BOOKINGS_FETCHING,
      });
      const res = await MeetingRoomService.getMeetingRoomBookingByIdUser(id);
      if (res) {
        dispatch({
          type: MEETING_ROOM_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: MEETING_ROOM_BOOKINGS_FAILED,
      });
    }
  };
