import { httpClient } from "./httpClient";

const addNewMaintenanceRequest = (formData) => {
  return httpClient.post(`maintenances/add`, formData, {
    "content-type": "multipart/form-data",
  });
};

const getAllMyHistoryMaintenanceRequest = (query) => {
  return httpClient.get(`maintenances/getAllHistory`, { params: query });
};

const getAllMaintenanceRequest = (query) => {
  return httpClient.get(`maintenances/getAllMaintenanceRequest`, {
    params: query,
  });
};

const getMaintenanceDesc = (query) => {
  return httpClient.get(`maintenances/getMaintenanceDesc`, { params: query });
};

const putChangeStatus = (body) => {
  return httpClient.put(`maintenances/putChangeStatus`, body);
};

const newProgressReport = (body) => {
  return httpClient.post(`maintenances/newProgressReport`, body);
};

const getAllTechnicians = (body) => {
  return httpClient.post(`maintenances/getAllTechnicians`, body);
};

const getTechniciansById = (index) => {
  return httpClient.get(`maintenances/getTechniciansById/${index}`);
};

const addTechnician = (formData) => {
  return httpClient.post(`maintenances/addTechinician`, formData, {
    "content-type": "multipart/form-data",
  });
};

const editTechnician = (formData) => {
  return httpClient.post(`maintenances/editTechinician`, formData, {
    "content-type": "multipart/form-data",
  });
};

const deleteTechnicians = (body) => {
  return httpClient.post(`maintenances/deleteTechinician`, body);
};

export default {
  addNewMaintenanceRequest,
  getAllMyHistoryMaintenanceRequest,
  getAllMaintenanceRequest,
  getMaintenanceDesc,
  putChangeStatus,
  newProgressReport,
  getAllTechnicians,
  getTechniciansById,
  addTechnician,
  editTechnician,
  deleteTechnicians,
};
