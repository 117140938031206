import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme)=>({
    DialogTitle:{
        fontSize:"30px",
        ["@media only screen and (max-width: 420px)"]: {
            fontSize:"20px",
          },
    },
    DialogContent:{
        padding:"30px 20px",
    },
    TextDialogContent:{
        fontSize:"20px",
    },
    Button:{
        width:"100px",
        fontSize:"20px",
        color:"white",
    },
    ButtonConfirm:{
        backgroundColor:"green",
        ["&:hover"]:{
            backgroundColor:"darkgreen",
        }
    },
    ButtonCancel:{
        backgroundColor:"red",
        ["&:hover"]:{
            backgroundColor:"darkred",
        }
    },
}));

const DialogOfConfirmationOfApprovalOfAllBooking = (props) => {
    const classes = useStyles();
    return (
        <Dialog
            open={props.openDialog}
        >
            <DialogTitle>
                <Typography className={`${classes.DialogTitle}`}>
                    การยืนยันการอนุมัติทั้งหมด
                </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent className={`${classes.DialogContent}`}>
                <Typography className={`${classes.TextDialogContent}`}>
                    คุณยืนยันที่จะทำการอนุมัติรายการทั้งหมดใช่ไหม
                </Typography>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button className={`${classes.Button} ${classes.ButtonConfirm}`}>ยืนยัน</Button>
                <Button className={`${classes.Button} ${classes.ButtonCancel}`} onClick={()=>props.onCancellation()}>ยกเลิก</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogOfConfirmationOfApprovalOfAllBooking;