import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { SnackbarProvider, useSnackbar } from "notistack";

import { useMediaQuery } from "@mui/material";

import { getAllUsers } from "../../../../actions/user";
import FormInformant from "../../shared/formBooking/formInformant";
import FormFightCarPool from "./formFightCarPool";
import FormDetailFightCarPool from "./formDetailFightCarPool";
import DialogShowDetailBooking from "./dialogShowDetailBooking";
import NavButton from "../../shared/NavButton/NavButtonForm";
import userService from "../../../../services/user.service";
import { getEmployeesByCompany } from "../../../../actions/employee";

import CrossAreaCarPoolBookingServices from "../../../../services/crossAreaCarPoolBooking.service";

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingInline: "5vw",
    [theme.breakpoints.down("sm")]: {
      paddingInline: "0",
      marginLeft: "0",
      width: "100%",
    },
  },
  Grid: {
    width: "80%",
    margin: "0 auto",
    marginTop: "20px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },
  Form: {
    width: "80%",
  },
  Title: {
    textAlign: "center",
    fontSize: "25px",
    margin: "25px 0",
  },
  MobilePaddingLeft: {
    paddingLeft: 0,
  },
  DivSpace: {
    height: "100px",
  },
}));

const CrossAreaCarPoolBooking = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user: CurrentUser } = useSelector((state) => state.auth);
  const [countPage, setCountPage] = useState(1);
  const [openDetailBooking, setOpenDetailBooking] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [stateInputRef, setStateInputRef] = useState({
    listAllPassengers: [{ order: "" }],
    statusUsingAllPassenger: false,
  });

  useEffect(() => {
    if (CurrentUser) {
      userService.getUserProfile(CurrentUser.idUser).then((res) => {
        if (res.data) {
          console.log(res.data.idCompany);
          dispatch(getEmployeesByCompany(res.data.idCompany));
        }
      });
      setValue("idUser", CurrentUser.idUser);
    }
  }, []);

  const MobileResponsive = useMediaQuery("(max-width:600px)");

  const showRegister = (date) => {
    CheckPage(date);
    console.log(date);
  };

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const CheckPage = (date) => {
    if (countPage === 1) {
      setCountPage(countPage + 1);
    } else {
      console.log("1234");
      console.log(
        getValues(
          `listPassenger.person${getValues("numberOfPassenger")}.option`
        )
      );
      let isNotSpecified = [];
      for (let index = 1; index <= getValues("numberOfPassenger"); index++) {
        if (getValues(`listPassenger.person${index}.option`) === undefined) {
          isNotSpecified.push(index);
        }
      }
      if (isNotSpecified.length > 0) {
        handleClick();
      } else {
        setOpenDetailBooking(true);
      }
    }
    window.scroll(0, 0);
  };

  const onClickBackPageHandler = () => {
    setCountPage(countPage - 1);
  };

  const onCloseDialog = () => {
    setOpenDetailBooking(false);
  };

  const onClickSubmit = (date) => {
    const data = CrossAreaCarPoolBookingServices.postNewCrossAreaCarPoolBooking(
      date
    ).then((res) => {
      if (res.data) {
        enqueueSnackbar("Create Booking Success", { variant: "success" });
        props.history.push("/carbooking");
      } else {
        enqueueSnackbar("Create Booking Failed", { variant: "error" });
      }
    });
  };

  return (
    <div
      className={clsx("page", classes.DivCoverReturn, {
        [classes.MobilePaddingLeft]: MobileResponsive,
      })}
    >
      <Typography className={classes.Title}>จองรถข้ามพื้นที่ Pool</Typography>
      {countPage === 1 && (
        <form className={classes.Form} onSubmit={handleSubmit(showRegister)}>
          <FormInformant
            register={register}
            setValue={setValue}
            errors={errors}
          />
          <FormFightCarPool
            watch={watch}
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
          />
          <NavButton
            type="button"
            numberOfPage={countPage}
            onClickBackPageHandler={onClickBackPageHandler}
          />
        </form>
      )}
      {countPage === 2 && (
        <form className={classes.Form} onSubmit={handleSubmit(showRegister)}>
          <Grid container justifyContent="center">
            {watch("numberOfPassenger") && (
              <FormDetailFightCarPool
                register={register}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
                control={control}
                errors={errors}
                stateInputRef={stateInputRef}
                setStateInputRef={setStateInputRef}
              />
            )}
            <NavButton
              type="submit"
              numberOfPage={countPage}
              onClickBackPageHandler={onClickBackPageHandler}
            />
          </Grid>
          <div className={classes.DivSpace}></div>
        </form>
      )}
      <DialogShowDetailBooking
        open={openDetailBooking}
        handleClose={onCloseDialog}
        getValues={getValues}
        onClickSubmit={handleSubmit(onClickSubmit)}
      />

      <Snackbar
        open={openAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} variant="filled" severity="error">
          กรุณากรอกข้อมูลให้ครบ
        </Alert>
      </Snackbar>

      {/* </Grid> */}
    </div>
  );
};

export default CrossAreaCarPoolBooking;
