import {
    LENTCARS_FETCHING,
    LENTCARS_FAILED,
    LENTCARS_SUCCESS,
} from "../actions/types";

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LENTCARS_FETCHING:
            return { ...state, result: null, isFetching: true, isError: false };    
        case LENTCARS_FAILED:
            return { ...state, result: null, isFetching: false, isError: true };
        case LENTCARS_SUCCESS:
            return { ...state, result: payload, isFetching: false, isError: false};
        default:
            return state;
    }
}