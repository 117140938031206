import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";

import { styled } from '@mui/styles';
import { 
    Modal, Paper, IconButton, FormControl, InputLabel, Select, MenuItem, Button, TextField
} from '@mui/material';

const ModalStyled = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
});

const PaperStyled = styled(Paper)({
    width:"300px",
    height:"fit-content",
    padding:"30px 30px 20px",
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-end",
});

const ConfirmButton = styled(Button)(({theme})=>({
    marginTop:"20px",
    padding:"7px 10px",
    backgroundColor:theme.palette.success.main,
    color:theme.palette.success.contrastText,
    fontSize:"16px",
    "&:hover":{
        backgroundColor:theme.palette.success.dark,
    },
}));

const ModalDetail = ({ openModal, LentCar, OnClickCloseModal }) => {
    const { register, handleSubmit, control, getValues, setValue } = useForm({
        defaultValues:{
            mpg:LentCar?LentCar.mpg:"",
            gas:LentCar?LentCar.gas:"",
            noGas:LentCar?LentCar.noGas:"",
            doors:LentCar?LentCar.doors:"",
            seats:LentCar?LentCar.seats:"",
            mile:LentCar?LentCar.mile:"",
        }
    });

    const OnClickConfirmButton = () => {
        OnClickCloseModal();
    };

    return(
        <ModalStyled
            open={openModal}
            onClose={OnClickCloseModal}
        >
            <PaperStyled>
                <TextField 
                    id="mpg" 
                    label="MPG" 
                    variant="outlined" 
                    fullWidth 
                    inputProps={{...register("mpg")}} 
                />
                <FormControl variant="outlined" fullWidth style={{marginTop:"20px"}}>
                    <InputLabel id="Gas">เชื้อเพลิง</InputLabel>
                    <Controller 
                        control={control}
                        name="gas"
                        render={({field})=>(
                            <Select
                                {...field}
                                labelId="gas-label"
                                id="gas"
                                label="เชื้อเพลิง"
                            >
                                <MenuItem value="Gasoline 95">Gasoline 95</MenuItem>
                                <MenuItem value="Gasoline 91">Gasoline 91</MenuItem>
                                <MenuItem value="Gasohol 95(E10)">Gasohol 95(E10)</MenuItem>
                                <MenuItem value="Gasohol 91(E10)">Gasohol 91(E10)</MenuItem>
                                <MenuItem value="NGV">NGV</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <TextField 
                    id="noGas" 
                    label="เลขถัง" 
                    variant="outlined" 
                    fullWidth 
                    inputProps={{...register("noGas")}}
                    style={{marginTop:"20px"}}
                />
                <TextField 
                    id="doors" 
                    label="ประตู" 
                    variant="outlined" 
                    fullWidth 
                    inputProps={{...register("doors")}} 
                    style={{marginTop:"20px"}}
                />
                <TextField 
                    id="seats" 
                    label="ที่นั่ง" 
                    variant="outlined" 
                    fullWidth 
                    inputProps={{...register("seats")}} 
                    style={{marginTop:"20px"}}
                />
                <FormControl variant="outlined" fullWidth style={{marginTop:"20px"}}>
                    <InputLabel id="Mile">ไมล์</InputLabel>
                    <Controller 
                        control={control}
                        name="mile"
                        render={({field})=>(
                            <Select
                                {...field}
                                labelId="mile-label"
                                id="mile"
                                label="ไมล์"
                            >
                                <MenuItem value="0-10,000 Miles">0-10,000 Miles</MenuItem>
                                <MenuItem value="10,001-30,000 Miles">10,001-30,000 Miles</MenuItem>
                                <MenuItem value="30,001-50,000 Miles">30,001-50,000 Miles</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <ConfirmButton onClick={OnClickConfirmButton}>Confirm</ConfirmButton>
            </PaperStyled>
        </ModalStyled>
    );
};

export default ModalDetail;