import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  styled,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Divider,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Container from "@mui/material/Container";

import { LoadingButton } from "@mui/lab";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DateAdapter from "@mui/lab/AdapterDayjs";
import "dayjs/locale/th";

import {
  getOptionHours,
  getOptionMaintenanceTypes,
  getOptionMinutes,
} from "../../../../../../utils/constant";

import maintenanceService from "../../../../../../services/maintenance.service";
import UserService from "../../../../../../services/user.service";
import Dropfile from "../../../../shared/Form/DropFile";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1600,
}));

const Form = ({ onSuccessHandler }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    reset,
  } = useForm();
  const [myProfile, setMyProfile] = useState(null);
  const [myUploadFile, setMyUploadFile] = useState([]);
  const [isuserProfile, setUserProfile] = useState([]);

  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("idUser", currentUser.idUser);
    formData.append("maintenanceType", data.maintenanceType);
    formData.append("requestorName", data.requestorName);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("email", data.email);
    formData.append("company", data.company);
    formData.append("agency", data.agency);
    formData.append("costCenter", data.costCenter);
    formData.append("costElement", data.costElement);
    formData.append("location", data.location);
    formData.append("locationDetail", data.locationDetail);
    formData.append(
      "startDate",
      new Date(
        new Date(data.date).toDateString() +
          " " +
          data.startTimeHour.toString().padStart(2, "0") +
          ":" +
          data.startTimeMinute.toString().padStart(2, "0")
      )
    );
    // formData.append("startTime", new Date(new Date().toDateString() + ' ' + data.startTimeHour.toString().padStart(2, '0') + ':' + data.startTimeMinute.toString().padStart(2, '0')));
    formData.append("timeNote", data.timeNote);

    data.attachment?.map((file, index) => {
      formData.append("attachment", file);
    });

    formData.append("description", data.description);
    formData.append("uploadfile", data.uploadfile);

    console.log(formData.get("startDate"));
    console.log("attachment", formData.get("attachment"));

    maintenanceService.addNewMaintenanceRequest(formData).then((res) => {
      console.log(res.data);
      if (res.data.success) {
        onSuccessHandler(res.data);
      }
    });

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  useEffect(() => {
    // userService.getMyUserInfo().then((res) => {
    //   if (res.data.success) {
    //     setValue(
    //       "requestorName",
    //       `${res.data.data.firstname} ${res.data.data.lastname}`
    //     );
    //     setValue("phoneNumber", res.data.data.mobileNumber.replace(/-/g, ""));
    //     setValue("email", res.data.data.email);
    //     setValue("company", res.data.data.company);
    //     setValue("agency", res.data.data.department);
    //   }
    // });
    if (currentUser) {
      UserService.getUserProfile(currentUser.idUser).then((res) => {
        if (res.data) {
          setValue(
            "requestorName",
            `${res.data.firstname_TH} ${res.data.lastname_TH}`
          );
          setValue("phoneNumber", res.data.telephoneMobile.replace(/-/g, ""));
          setValue("email", res.data.email);
          setValue("company", res.data.companyName);
          setValue("agency", res.data.departmentName);
        }
      });
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography fontSize={24} fontWeight={500}>
              แจ้งงานซ่อมแซม
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={20} fontWeight={300}>
              รายละเอียดผู้แจ้ง
            </Typography>
            <Divider />
          </Grid>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={(theme) => ({
              display: "flex",
              boxShadow:
                "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FBFCFC",
              marginTop: "10px",
              marginBottom: "20px",
              marginLeft: "0px",
              padding: "0 25px 25px 0",

              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {},
              [theme.breakpoints.down("md")]: {},
            })}
          >
            <Grid item xs={12} lg={4} sm={4}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ชื่อผู้แจ้ง"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    fullWidth
                    helperText={
                      errors.requestorName ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    error={errors.requestorName ? true : false}
                  />
                )}
                control={control}
                name="requestorName"
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
              <TextField
                label="เบอร์ติดต่อ"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("phoneNumber", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.phoneNumber ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.phoneNumber ? true : false}
              />
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
              <TextField
                label="Email"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("email", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={errors.email ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false}
                error={errors.email ? true : false}
              />
            </Grid>
            <Grid item xs={6} lg={3} sm={3}>
              <TextField
                label="บริษัท"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("company", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.company ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.company ? true : false}
              />
            </Grid>
            <Grid item xs={6} lg={3} sm={3}>
              <TextField
                label="หน่วยงาน"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...register("agency", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={errors.agency ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false}
                error={errors.agency ? true : false}
              />
            </Grid>
            <Grid item xs={6} lg={3} sm={3}>
              <TextField
                label="Cost Center"
                inputProps={{ ...register("costCenter", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.costCenter ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.costCenter ? true : false}
              />
            </Grid>
            <Grid item xs={6} lg={3} sm={3}>
              <TextField
                label="Cost Element"
                inputProps={{ ...register("costElement", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.costElement ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.costElement ? true : false}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={20} fontWeight={300}>
              รายละเอียดงานซ่อมแซม
            </Typography>
            <Divider />
          </Grid>
          <Grid
            container
            item
            spacing={3}
            xs={12}
            sx={(theme) => ({
              display: "flex",
              boxShadow:
                "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FBFCFC",
              marginTop: "10px",
              marginBottom: "20px",
              marginLeft: "0px",
              padding: "0 25px 25px 0",

              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {},
              [theme.breakpoints.down("md")]: {},
            })}
          >
            <Grid item xs={12} lg={4} sm={4}>
              <TextField
                label="ประเภทการซ่อมแซม"
                inputProps={{
                  ...register("maintenanceType", { required: true }),
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                helperText={
                  errors.maintenanceType ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.maintenanceType ? true : false}
                select
                fullWidth
              >
                {getOptionMaintenanceTypes().map((mt) => (
                  <MenuItem value={mt.value}>{mt.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
              <TextField
                label="สถานที่"
                inputProps={{ ...register("location", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.location ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.location ? true : false}
              />
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
              <TextField
                label="รายละเอียด"
                inputProps={{
                  ...register("locationDetail", { required: true }),
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.locationDetail ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.locationDetail ? true : false}
              />
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                <Controller
                  name="date"
                  control={control}
                  defaultValue={null}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MobileDatePicker
                      {...field}
                      label="วันที่"
                      minDate={new Date()}
                      inputFormat="DD MMMM YYYY"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            errors.date ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                          }
                          error={errors.date ? true : false}
                          sx={{
                            ".MuiInputBase-root": {
                              borderRadius: "10px",
                            },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} lg={4} sm={4}>
              <Controller
                name="startTimeHour"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="เวลาเริ่ม (ชั่วโมง)"
                    fullWidth
                    helperText={
                      errors.startTimeHour ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "10px",
                      },
                    }}
                    error={errors.startTimeHour ? true : false}
                    select
                  >
                    {getOptionHours().map((mt) => (
                      <MenuItem key={`startHour_${mt.value}`} value={mt.value}>
                        {mt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6} lg={4} sm={4}>
              <TextField
                label="เวลาเริ่ม (นาที)"
                inputProps={{
                  ...register("startTimeMinute", { required: true }),
                }}
                fullWidth
                helperText={
                  errors.startTimeMinute ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                error={errors.startTimeMinute ? true : false}
                select
                defaultValue={""}
              >
                {getOptionMinutes().map((mt) => (
                  <MenuItem key={`startMinute_${mt.value}`} value={mt.value}>
                    {mt.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              {/* <TextField label="Note" inputProps={{...register("timeNote", { required: true })}} fullWidth helperText={errors.timeNote? "กรุณากรอกข้อมูลให้ถูกต้อง": false} error={errors.timeNote? true: false}/> */}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="รายละเอียดเพิ่มเติม"
                inputProps={{ ...register("description", { required: true }) }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                fullWidth
                helperText={
                  errors.description ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                }
                error={errors.description ? true : false}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropfile
                title="แนบไฟล์ (ไม่เกิน 10 MB ต่อไฟล์ สูงสุด 4 ไฟล์)"
                name="attachment"
                setValue={setValue}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              style={{
                maxWidth: "200px",
                maxHeight: "70px",
                marginTop: "5px",
                borderRadius: "15px",
                fontSize: "18px",
                backgroundColor: "green",
              }}
              variant="contained"
            >
              ยืนยัน
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Form;
