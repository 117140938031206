import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ScheduleIcon from "@mui/icons-material/Schedule";
import FlagIcon from "@mui/icons-material/Flag";
import RoomIcon from "@mui/icons-material/Room";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EventSeatIcon from "@mui/icons-material/EventSeat";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import RegisterOfDriverService from "../../../../../services/registerOfDriver.service";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  Title: {
    textAlign: "center",
    fontSize: "30px",
    marginTop: "25px",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
    },
  },
  Paper: {
    width: "80%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "20px",
    marginBottom: "20px",
    boxShadow: theme.shadows[3],
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  partOfTopOfPaper: {
    width: "95%",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  IconAndText: {
    display: "flex",
    alignItems: "center",
  },
  Icon: {
    fontSize: "30px",
    marginRight: "10px",
  },
  partOfDetailDriver: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  Text: {
    fontSize: "20px",
    textOverflow: "ellipsis",
  },
  TextName: {
    fontSize: "40px",
    marginBottom: "10px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },
  partOfDetailOfFlight: {
    width: "90%",
  },
  TextDetailOfFlight: {
    fontSize: "24px",
    color: "#0F19B8",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  Divider: {
    height: "1px",
    width: "95%",
    marginTop: "30px",
    marginBottom: "30px",
    backgroundColor: "#DDDDDD",
  },
  Avatar: {
    height: "160px",
    width: "160px",
    marginRight: "40px",
    marginLeft: "30px",
  },
  Button: {
    fontSize: "20px",
  },
  BackButton: {
    fontSize: "25px",
    position: "absolute",
    top: "20px",
    left: "20px",
    padding: "5px 20px",
    color: "black",
    ["& .MuiButton-label .MuiButton-startIcon"]: {
      marginRight: "0",
    },
    ["&:hover"]: {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      top: "10px",
      left: "10px",
    },
  },
  partOfRating: {
    display: "flex",
  },
  Rating: {
    marginRight: "20px",
  },
  TextPoint: {
    fontSize: "25px",
  },
  partOfDetail: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  TextDetail: {
    textIndent: "40px",
  },
  ImageIcon: {
    width: "35px",
    height: "35px",
    marginRight: "10px",
  },
  NumberSeats: {
    fontSize: "40px",
    color: "#28B463",
  },
  partOfNumberSeats: {
    width: "90%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
    marginBottom: "10px",
    alignItems: "center",
  },
  JoinButton: {
    width: "100px",
    height: "60px",
    color: "white",
    backgroundColor: "#1C7EE0",
    marginLeft: "30px",
    fontSize: "24px",
    ["&:hover"]: {
      backgroundColor: "#186ABC",
    },
  },
  SeatIcon: {
    fontSize: "50px",
    marginRight: "10px",
    color: "#28B463",
  },
}));

const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  fontSize: "20px",
  color: theme.palette.primary.main,
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
  fontSize: "18px",
  padding: "7px 20px",
  "&:hover": {
    backgroundColor: theme.palette.success.main,
  },
}));

const ShowDetailDriver = (props) => {
  const classes = useStyles();
  const { user: User } = useSelector((state) => state.auth);
  const [openDialog, setOpenDialog] = useState(false);
  const [detailOfRegisterOfDriver, setDetailOfRegisterOfDriver] =
    useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    RegisterOfDriverService.getRegistersOfDriver(
      props.location.state.orderId
    ).then((res) => {
      if (res.data) {
        setDetailOfRegisterOfDriver(res.data);
        setStartDate(res.data.startDate);
        setEndDate(res.data.endDate);
      }
    });
  }, []);

  const convertDate = (lawStartDate, lawEndDate) => {
    let startDateSetTime = new Date(lawStartDate).setHours(0, 0, 0, 0);
    let endDateSetTime = new Date(lawEndDate).setHours(0, 0, 0, 0);
    if (startDateSetTime === endDateSetTime) {
      return dayjs(startDateSetTime).format("DD MMM YYYY");
    } else {
      return `${dayjs(startDateSetTime).format("DD MMM YYYY")} - ${dayjs(
        endDateSetTime
      ).format("DD MMM YYYY")}`;
    }
  };

  const onClickConfirm = () => {
    let data = {
      idRegister: props.location.state.orderId,
      idPassenger: User.id,
      startDate: startDate,
      endDate: endDate,
    };
    console.log(data);
    props.history.push("/friend2friend_Car/passenger/my_plan");
  };

  const onClickBackButton = () => {
    props.history.goBack();
  };

  return (
    <div className={`page ${classes.DivCoverReturn}`}>
      {console.log(props)}
      <Paper className={classes.Paper}>
        {detailOfRegisterOfDriver && (
          <>
            <Button
              className={`${classes.Button} ${classes.BackButton}`}
              startIcon={<ArrowBackIosIcon className={`${classes.BackIcon}`} />}
              onClick={onClickBackButton}
            >
              กลับ
            </Button>
            <Typography className={classes.Title}>
              รายละเอียดของคนขับรถ
            </Typography>
            <div className={`${classes.partOfTopOfPaper}`}>
              <Avatar
                className={`${classes.Avatar}`}
                src={`${process.env.REACT_APP_URL}image/profile/${detailOfRegisterOfDriver.user.image}`}
                alt={detailOfRegisterOfDriver.user.firstname}
              />
              <div className={`${classes.partOfDetailDriver}`}>
                <Typography className={`${classes.TextName} ${classes.Text}`}>
                  {`${detailOfRegisterOfDriver.user.firstname} ${detailOfRegisterOfDriver.user.lastname}`}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6}>
                    <div className={`${classes.IconAndText}`}>
                      <PersonIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`} noWrap>
                        {`Position : ${detailOfRegisterOfDriver.user.position}`}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div className={`${classes.IconAndText}`}>
                      <WorkIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`} noWrap>
                        {`Department : ${detailOfRegisterOfDriver.user.department}`}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={`${classes.IconAndText}`}>
                      <PhoneIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`} noWrap>
                        {`Tel : ${detailOfRegisterOfDriver.user.mobileNumber}`}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={`${classes.IconAndText}`}>
                      <DriveEtaIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`} noWrap>
                        {`จำนวนรับ : 23`}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className={`${classes.partOfRating}`}
                  >
                    <Rating
                      className={`${classes.Rating}`}
                      name="read-only"
                      value={detailOfRegisterOfDriver.user.pointFriendToFriend}
                      precision={0.5}
                      size="large"
                      readOnly
                    />
                    <Typography
                      className={`${classes.Text} ${classes.TextPoint}`}
                      noWrap
                    >
                      {detailOfRegisterOfDriver.user.pointFriendToFriend}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Divider className={classes.Divider} />
            <Grid container className={`${classes.partOfDetailOfFlight}`}>
              <Grid item sm={12} lg={6}>
                <div className={`${classes.IconAndText}`}>
                  <CalendarTodayIcon className={`${classes.Icon}`} />
                  <Typography className={`${classes.TextDetailOfFlight}`}>
                    {convertDate(
                      detailOfRegisterOfDriver.startDate,
                      detailOfRegisterOfDriver.endDate
                    )}
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={12} lg={6}>
                <div className={`${classes.IconAndText}`}>
                  <ScheduleIcon className={`${classes.Icon}`} />
                  <Typography className={`${classes.TextDetailOfFlight}`}>
                    {dayjs(detailOfRegisterOfDriver.time).format(
                      "เวลา : HH.mm น."
                    )}
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={12} lg={6}>
                <div className={`${classes.IconAndText}`}>
                  <RoomIcon className={`${classes.Icon}`} />
                  <Typography className={`${classes.TextDetailOfFlight}`}>
                    {`จุดรับ : ${detailOfRegisterOfDriver.fromPlace}`}
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={12} lg={6}>
                <div className={`${classes.IconAndText}`}>
                  <FlagIcon className={`${classes.Icon}`} />
                  <Typography className={`${classes.TextDetailOfFlight}`}>
                    {`จุดส่ง : ${detailOfRegisterOfDriver.toPlace}`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Divider className={classes.Divider} />
            <div className={`${classes.partOfDetail}`}>
              <div
                className={`${classes.IconAndText} ${classes.partOfTopicDetail}`}
              >
                <img
                  className={`${classes.ImageIcon}`}
                  src={`../../assets/Image/Icon/detail.png`}
                  alt="detail.png"
                />
                <Typography className={`${classes.Text}`}>
                  {`รายละเอียด : `}
                </Typography>
              </div>
              <Typography className={`${classes.Text} ${classes.TextDetail}`}>
                {`${detailOfRegisterOfDriver.detail}`}
              </Typography>
            </div>
            <div className={`${classes.partOfNumberSeats}`}>
              {console.log(
                "detailOfRegisterOfDriver.availableSeats !== 0 : ",
                detailOfRegisterOfDriver.availableSeats !== 0
              )}
              {console.log(
                "!props.location.state.joined : ",
                !props.location.state.joined
              )}
              {detailOfRegisterOfDriver.availableSeats !== 0 &&
                !props.location.state.joined && (
                  <>
                    <div className={`${classes.IconAndText}`}>
                      <EventSeatIcon className={`${classes.SeatIcon}`} />
                      <Typography className={`${classes.NumberSeats}`}>
                        {`${detailOfRegisterOfDriver.availableSeats} ที่นั่ง`}
                      </Typography>
                    </div>
                    <Button
                      className={`${classes.JoinButton}`}
                      onClick={() => setOpenDialog(true)}
                    >
                      Join
                    </Button>
                    <Dialog
                      open={openDialog}
                      onClose={() => setOpenDialog(false)}
                    >
                      <DialogTitleStyled>
                        กำหนดวันที่ต้องการเข้าร่วม
                      </DialogTitleStyled>
                      <DialogContent
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "20px 24px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="วันที่เริ่ม"
                            inputFormat="dd/MM/yyyy"
                            minDate={
                              new Date(detailOfRegisterOfDriver.startDate)
                            }
                            maxDate={new Date(detailOfRegisterOfDriver.endDate)}
                            value={startDate}
                            onChange={(e) => setStartDate(e)}
                            renderInput={(params) => (
                              <TextField fullWidth {...params} />
                            )}
                          />
                          <DatePicker
                            label="วันที่สิ้นสุด"
                            inputFormat="dd/MM/yyyy"
                            minDate={new Date(startDate)}
                            maxDate={new Date(detailOfRegisterOfDriver.endDate)}
                            value={endDate}
                            onChange={(e) => setEndDate(e)}
                            renderInput={(params) => (
                              <TextField
                                style={{ marginTop: "20px" }}
                                fullWidth
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </DialogContent>
                      <DialogActions>
                        <ConfirmButton onClick={onClickConfirm}>
                          ยืนยัน
                        </ConfirmButton>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
            </div>
          </>
        )}
      </Paper>
    </div>
  );
};

export default ShowDetailDriver;
