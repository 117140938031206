import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Container from "@mui/material/Container";

import {
  styled,
  Typography,
  Button,
  Grid,
  Stack,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // Checkbox,
  // ListItemText,
  OutlinedInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import Detail from "./components/Detail";
import Item from "./components/Item";
import Table from "./components/Table";
import UnicornLoading from "../../assets/loading.gif";
import maintenanceService from "../../../../services/maintenance.service";
import { SnackbarProvider, useSnackbar } from "notistack";

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));
const TechniciansPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { enqueueSnackbar } = useSnackbar();
  const [technicians, setTechnicians] = useState([]);
  const [company, setCompany] = useState(userProfile?.companyName);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, register, getValues, setValue } = useForm({
    defaultValues: {
      fullName: "",
      type: 0,
    },
  });

  const types = ["ช่างซ่อมแซมทั่วไป", "ช่างไฟฟ้า", "ช่างประปา"];

  // const handleChangeFilterType = (event) => {
  // 	const {target: { value },} = event;
  // 		console.log(value)
  // 	setValue("types", value);
  // };

  const onClickSearch = () => {
    setLoading(true);
    maintenanceService
      .getAllTechnicians({
        fullName: getValues("fullName") || null,
        type: getValues("type") || null,
      })
      .then((res) => {
        setLoading(false);
        setSelectedTechnician(null);
        setTechnicians(res.data.data);
        // setLoading(false);
        // setError(res.data.error);
      });
  };

  const onClickDelete = (index) => {
    console.log(index);
    console.log(technicians[index]);

    maintenanceService.deleteTechnicians(technicians[index]).then((res) => {
      if (res.status === 200) {
        setTechnicians(technicians.filter((_, i) => i !== index));
        console.log(res.data);
        enqueueSnackbar("Delete Success", { variant: "success" });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    maintenanceService.getAllTechnicians().then((res) => {
      setTechnicians(res.data.data);
      setLoading(false);
      console.log(res.data);
    });
  }, []);

  return (
    <Container maxWidth="xl">
      <div className="page">
        <Root>
          <Grid container spacing={2} marginBottom={1} marginTop={3}>
            <Grid item xs={12} sm={6}>
              <Typography fontSize={34}>รายชื่อช่างทั้งหมด</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                component={Link}
                to="/admin/newtechnician"
                style={{
                  maxWidth: "140px",
                  maxHeight: "40px",

                  borderRadius: "15px",
                }}
              >
                ลงทะเบียนช่างใหม่
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              container
              spacing={2}
              sx={(theme) => ({
                display: "flex",
                justifyContent: "flex-start",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FFFFFF",
                boxShadow:
                  "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

                padding: "0 24px 24px 0",
                marginLeft: "5px",
                marginTop: "10px",
                borderRadius: "15px",
              })}
            >
              <Grid item xs={12} sm={6} md={4}>
                {userProfile && (
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      placeholder="เลือกบริษัท"
                      id="company"
                      defaultValue={company}
                      onChange={(e) => setCompany(e.target.value)}
                      value={company}
                    >
                      <MenuItem
                        key={userProfile.idCompany}
                        value={userProfile.companyName}
                      >
                        {userProfile.companyName}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  inputProps={{ ...register("fullName") }}
                  // size="small"
                  label="ชื่อช่าง"
                  placeholder="ชื่อ-นามสกุล"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "5px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={{ width: "100%", borderRadius: "5px" }}>
                      <InputLabel>ประเภทช่าง</InputLabel>
                      <Select
                        {...field}
                        input={<OutlinedInput label="ประเภทช่าง" />}
                      >
                        <MenuItem value={0}>ทั้งหมด</MenuItem>
                        {types.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                            {/* <ListItemText primary={type} /> */}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  onClick={onClickSearch}
                  style={{
                    maxWidth: "100px",
                    maxHeight: "40px",
                    marginTop: "10px",
                    borderRadius: "15px",
                  }}
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              spacing={2}
              // style={{ minWidth: 1240 }}
              alignItems="flex-start"
            >
              <Grid item xs={12} md={12} lg={8} container>
                <Table>
                  <Stack divider={<Divider flexItem />}>
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <img
                          src={UnicornLoading}
                          alt="Loading..."
                          style={{ width: "300px" }}
                        />
                        {/* ปรับขนาดของภาพตามที่ต้องการ */}
                      </div>
                    ) : (
                      <>
                        {technicians.length === 0 && (
                          <Typography
                            textAlign="center"
                            fontSize={24}
                            style={{ marginTop: 16 }}
                          >
                            ไม่พบรายชื่อ
                          </Typography>
                        )}
                        {technicians.map((tech, index) => (
                          <Item
                            key={`techinician_${index}`}
                            data={tech}
                            onClick={() => {
                              setSelectedTechnician(tech);
                            }}
                            onClickDelete={() => {
                              onClickDelete(index);
                            }}
                          />
                        ))}
                      </>
                    )}
                  </Stack>
                </Table>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={4}
                style={{ position: "sticky", top: 78 }}
              >
                <Detail data={selectedTechnician} />
              </Grid>
            </Grid>
          </Grid>
        </Root>
      </div>
    </Container>
  );
};

export default TechniciansPage;
