import {
    DRIVERROUTEDAY_FETCHING,
    DRIVERROUTEDAY_FAILED,
    DRIVERROUTEDAY_SUCCESS
} from "./types";

import DriverRouteDay from '../services/driverRouteDay.service';

export const getDriverRouteDayByIdDriver = (idDriver,routeDate) => async (dispatch) => {
    try {
        dispatch({
            type: DRIVERROUTEDAY_FETCHING,
        });
        const res = await DriverRouteDay.getDriverRouteDayByIdDriver(idDriver,routeDate);
        if(res){
            dispatch({
                type: DRIVERROUTEDAY_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: DRIVERROUTEDAY_FAILED,
        });
    }
};

export const postStartTime = (idDriver,routeDate) => async (dispatch) => {
    try {
        dispatch({
            type: DRIVERROUTEDAY_FETCHING,
        });
        const res = await DriverRouteDay.postStartTime(idDriver,routeDate);
        if(res){
            dispatch({
                type: DRIVERROUTEDAY_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: DRIVERROUTEDAY_FAILED,
        });
    }
};

export const postEndTime = (idDriver,routeDate,endTime,duration) => async (dispatch) => {
    try {
        dispatch({
            type: DRIVERROUTEDAY_FETCHING,
        });
        const res = await DriverRouteDay.postEndTime(idDriver,routeDate,endTime,duration);
        if(res){
            dispatch({
                type: DRIVERROUTEDAY_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: DRIVERROUTEDAY_FAILED,
        });
    }
};