import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";

import { styled } from '@mui/styles';
import { 
    Modal, Paper, IconButton, FormControl, InputLabel, Select, MenuItem, Button,
} from '@mui/material';

const ModalStyled = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
});

const PaperStyled = styled(Paper)({
    width:"300px",
    height:"fit-content",
    padding:"30px 30px 20px",
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-end",
});

const ConfirmButton = styled(Button)(({theme})=>({
    marginTop:"20px",
    padding:"7px 10px",
    backgroundColor:theme.palette.success.main,
    color:theme.palette.success.contrastText,
    fontSize:"16px",
    "&:hover":{
        backgroundColor:theme.palette.success.dark,
    },
}));

const ModalModelBrandYear = ({ openModal, LentCar, OnClickCloseModal }) => {
    const { register, handleSubmit, control, getValues, setValue } = useForm({
        defaultValues:{
            brand:LentCar?LentCar.brand:"",
            model:LentCar?LentCar.model:"",
            year:LentCar?LentCar.year:"",
        }
    });

    const OnClickConfirmButton = () => {
        OnClickCloseModal();
    };

    return(
        <ModalStyled
            open={openModal}
            onClose={OnClickCloseModal}
        >
            <PaperStyled>
                <FormControl variant="outlined" fullWidth >
                    <InputLabel id="Brand">ยี่ห้อ</InputLabel>
                    <Controller 
                        control={control}
                        name="brand"
                        defaultValue=""
                        render={({field})=>(
                            <Select
                                {...field}
                                labelId="Brand-label"
                                id="Brand"
                                label="ยี่ห้อ"
                            >
                                <MenuItem value="Toyota">Toyota</MenuItem>
                                <MenuItem value="Honda">Honda</MenuItem>
                                <MenuItem value="Tesla">Tesla</MenuItem>
                                <MenuItem value="Suzuki">Suzuki</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <FormControl variant="outlined" fullWidth style={{marginTop:"20px"}}>
                    <InputLabel id="Model">Model</InputLabel>
                    <Controller 
                        control={control}
                        name="model"
                        defaultValue=""
                        render={({field})=>(
                            <Select
                                {...field}
                                labelId="Model-label"
                                id="Model"
                                label="Model"
                            >
                                <MenuItem value={"Jazz"}>Jazz</MenuItem>
                                <MenuItem value={"Pajero Sport"}>Pajero Sport</MenuItem>
                                <MenuItem value={"Swift"}>Swift</MenuItem>
                                <MenuItem value={"City"}>City</MenuItem>
                                <MenuItem value={"Civic"}>Civic</MenuItem>
                                <MenuItem value={"Corolla Altis"}>Corolla Altis</MenuItem>
                                <MenuItem value={"Vios"}>Vios</MenuItem>

                            </Select>
                        )}
                    />
                </FormControl>
                <FormControl variant="outlined" fullWidth style={{marginTop:"20px"}}>
                    <InputLabel id="Year">Year</InputLabel>
                    <Controller 
                        control={control}
                        name="year"
                        defaultValue=""
                        render={({field})=>(
                            <Select
                                {...field}
                                labelId="Year-label"
                                id="Year"
                                label="Year"
                            >
                                {[...new Array(10)].map((value,index)=>(
                                    <MenuItem key={value+""+index} value={new Date().getFullYear()-index}>{new Date().getFullYear()-index}</MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
                <ConfirmButton onClick={OnClickConfirmButton}>Confirm</ConfirmButton>
            </PaperStyled>
        </ModalStyled>
    );
};

export default ModalModelBrandYear;