import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { styled, Grid } from "@mui/material";
import Item from "./components/Item";
import { getUserProfile } from "../../../../actions/user";

const Root = styled("div")(({ theme }) => ({
  position: "relative", // Ensure positioning context for the pseudo-element
  margin: "0 auto",
  padding: "16px 16px 16px 16px",
  minHeight: "calc(100vh - 64px)",
  boxSizing: "border-box",
  paddingInline: "16vw",
  maxWidth: 1600,
  overflow: "hidden", // Ensure the pseudo-element does not overflow the container
  zIndex: 2,
  // Pseudo-element for the blurred background
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: "url(./assets/background/homeUser.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    opacity: "0.9",
    filter: "blur(5px)",
    zIndex: 0, // Ensure it stays behind the content
  },

  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));
const BackgroundImg = styled("img")({
  position: "absolute",
  width: "100%",
  height: "calc(100% - 64px)",
  opacity: "0.8",
  filter: "blur(5px)",
  zIndex: "0",
});

const HomePage = () => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (!userProfile) {
      dispatch(getUserProfile(currentUser?.idUser));
    }
  }, []);
  return (
    <div className="page">
      <Root>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Item
              link="/catering/request_catering"
              title="จัดงานเลี้ยง"
              image="/assets/Image/menuPicture/menu_catering.png"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item
              link="/maintenances/request"
              title="บริการซ่อมแซม"
              image="/assets/Image/menuPicture/menu_maintenance.png"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item
              link="/carbooking"
              title="บริการรถ"
              image="/assets/Image/menuPicture/menu_logistics.png"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item
              link="/meetingroom"
              title="บริการสถานที่"
              image="/assets/Image/menuPicture/menu_meetingroom.png"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item
              link="/driverbooking"
              title="บริการขอคนขับรถ"
              image="/assets/Image/menuPicture/menu_driver.png"
            />
          </Grid>
        </Grid>
      </Root>
    </div>
  );
};

export default HomePage;
