import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, styled } from "@mui/material";
import { Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Box,
} from "@mui/material";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";

import { Button } from "@mui/material";
import ButtonBlue from "../../shared/buttonBlue/index.js";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Card from "./components/Card/index.js";

import MeetingRoomService from "../../../../services/meetingRoom.service.js";
import { useState } from "react";

const StyledRoot = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",

    width: "100%",
  },
  "& .divCoverReturn": {
    width: "100%",
    display: "flex",
    paddingTop: "50px",
    height: "calc(100% - 30px)",
  },
  "& .Grid": {
    width: "100%",

    // minWidth: "1650px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
      minWidth: "150px",
      // flexDirection:"column",
    },
  },
  "& .tabBar": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  "& .CreateButton": {
    height: "50px",
    width: "200px",
    display: "flex",
    color: "white",
    fontSize: "15px",
    borderRadius: "10px",
    margin: "3px",
    backgroundColor: theme.palette.primary.main,
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  "& .IconImage": {
    weight: "24px",
    height: "24px",
    marginRight: "5px",
  },
  "& .content": {
    marginTop: "15px",
  },
  "& .Title": {
    textAlign: "center",
    fontSize: "36px",
    // margin: "25px 0px",
  },
  "& .timePicker": {
    display: "flex",
    borderRadius: "10px",
    border: "1px solid RGB(196, 196, 196)",
    padding: "10px",

    "&:hover": {
      border: "1px solid #222222",
    },
  },

  "& .datePicker": {
    display: "flex",
    borderRadius: "10px",
    border: "1px solid RGB(196, 196, 196)",
    padding: "10px",
    // marginTop: "10px",

    "&:hover": {
      border: "1px solid #222222",
    },
  },
}));

const NewMeetingRoomBooking = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,

    getValues,
    formState: { errors },
  } = useForm();
  const [allMeetingRoom, setallMeetingRoom] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isBooikingforSearch, setBookingforSearch] = useState();
  const [value, setValue] = useState(new Date());
  const [isBooikingforSearchDateTime, setBookingforSearchDateTime] = useState();
  const [textError, setTextError] = useState("");
  const [timeStart, settimeStart] = useState("00:00");
  const [timeEnd, settimeEnd] = useState("00:00");
  //Search
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    MeetingRoomService.getAlltNewMeetingRoom().then((res) => {
      if (res.data) {
        console.log(res.data);
        setallMeetingRoom(res.data);
        setBookingforSearch(res.data);
      } else console.log("error");
    });
    MeetingRoomService.getAllMeetingRoomBooking().then((res) => {
      if (res.data) {
        setBookingforSearchDateTime(res.data);
      }
    });
  }, []);
  const handleChangeStart = (newValue) => {
    settimeStart(newValue);
  };
  const handleChangeEnd = (newValue) => {
    settimeEnd(newValue);
  };
  const handleChangeSearchFullnameAllDetail = (event) => {
    setSearchTerm(event.target.value);
    console.log(event.target.value);
    const data = isBooikingforSearch.filter((value) =>
      value.nameMeetingRoom
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );
    setallMeetingRoom(data);
  };

  const handleChangeSearchDateAllDetail = (
    startDate,
    endDate,
    timeStart,
    timeEnd
  ) => {
    console.log(startDate, endDate, timeStart, timeEnd);
    let checkTime = false;
    let checkList = [];
    let checkStartDate = new Date(
      startDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }) +
        ", " +
        timeStart
    );
    let checkEndDate = new Date(
      endDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }) +
        ", " +
        timeEnd
    );
    for (let e = 0; e < isBooikingforSearchDateTime.length; e++) {
      const rangeStart = new Date(
        isBooikingforSearchDateTime[e].startDate
      ).getTime();
      const rangeEnd = new Date(
        isBooikingforSearchDateTime[e].endDate
      ).getTime();
      const newStart = new Date(checkStartDate).getTime();
      const newEnd = new Date(checkEndDate).getTime();

      // );
      if (
        (newStart >= rangeStart && newStart < rangeEnd) ||
        (newEnd > rangeStart && newEnd <= rangeEnd) ||
        (newStart <= rangeStart && newEnd >= rangeEnd)
      ) {
        checkList.push(isBooikingforSearchDateTime[e]);

        checkTime = true;
      } else {
        checkTime = false;
        // console.log(false, "check")
      }
    }
    let dateStart = new Date(
      startDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    );
    let DateEnd = new Date(
      endDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    );
    if (
      timeEnd === timeStart &&
      dateStart.toISOString().slice(0, 10) ===
        DateEnd.toISOString().slice(0, 10)
    ) {
      setTextError("กรอกเวลาให้ถูกต้อง");
    } else if (
      timeEnd < timeStart &&
      dateStart.toISOString().slice(0, 10) ===
        DateEnd.toISOString().slice(0, 10)
    ) {
      setTextError("กรอกเวลาให้ถูกต้อง");
    } else if (checkTime === true) {
      setTextError("เวลาที่เลือกถูกจองไปแล้ว");
      const filteredData = isBooikingforSearch.filter(
        (item) =>
          !checkList.some(
            (otherItem) => otherItem.idMeetingRoom === item.idNewMeetingRoom
          )
      );

      setallMeetingRoom(filteredData);
    } else {
      const filteredData = isBooikingforSearch.filter(
        (item) =>
          !checkList.some(
            (otherItem) => otherItem.idMeetingRoom === item.idNewMeetingRoom
          )
      );

      setallMeetingRoom(filteredData);
    }
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const onClickCardHandler = (idMeeting) => {
    props.history.push(`/meetingroom/detail/`, { idMeeting: idMeeting });
    // console.log("onClickCardHandler");
  };
  const newForm = () => {
    props.history.push(`/admin/Newmeetingroom/form/`);
  };
  const detailEditBooking = () => {
    props.history.push(`/admin/Newmeetingroom/detailMeetingRoomBooking/`);
  };
  return (
    <Container maxWidth="xl">
      <StyledRoot>
        <div className="divCoverReturn">
          <div className="Grid">
            <p className="Title">สถานที่ทั้งหมด</p>
            {/* <div className="topDivCover"> */}
            <Grid
              item
              xs={12}
              container
              spacing={2}
              // style={{ height: "50%", width: "100%" }}
            >
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  label="ค้นหาถานที่"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleChangeSearchFullnameAllDetail}
                  multiline
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                      width: "100%",
                      height: "70px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Grid className="datePicker">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่เริ่ม"
                      value={startDate}
                      InputProps={{ disableUnderline: true }}
                      onChange={(date) => {
                        setStartDate(date);
                        handleChangeSearchDateAllDetail(
                          date,
                          endDate,
                          timeStart,
                          timeEnd
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="DataPicker"
                          variant="standard"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                    <DatePicker
                      label="วันที่สิ้นสุด"
                      minDate={startDate}
                      wrapperClassName="date"
                      value={endDate}
                      InputProps={{ disableUnderline: true }}
                      onChange={(date) => {
                        setEndDate(date);
                        handleChangeSearchDateAllDetail(
                          startDate,
                          date,
                          timeStart,
                          timeEnd
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="DataPicker"
                          variant="standard"
                          fullWidth
                          style={{ marginLeft: "10px" }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Grid className="timePicker">
                  <Controller
                    render={(propsInController) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel shrink>{"เวลาเริ่ม"}</InputLabel>
                        <Select
                          native
                          label="เวลาเริ่ม"
                          value={timeStart}
                          disableUnderline
                          onChange={(e) => {
                            propsInController.field.onChange(e.target.value);
                            handleChangeStart(e.target.value);
                            handleChangeSearchDateAllDetail(
                              startDate,
                              endDate,
                              e.target.value,
                              timeEnd
                            );
                          }}
                          style={{ borderRadius: "10px" }}
                        >
                          {[...new Array(48)].map((value, index) => {
                            let hour = Math.floor((0 + index * 30) / 60);
                            let minute = Math.floor((0 + index * 30) % 60);
                            if (hour < 10) {
                              hour = "0" + hour;
                            }
                            if (minute < 10) {
                              minute = "0" + minute;
                            }
                            return (
                              <option
                                value={hour + ":" + minute}
                                key={hour + ":" + minute}
                              >
                                {hour + ":" + minute}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                    name="timeStart"
                    control={control}
                    helperText={
                      timeStart === "00:00"
                        ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                        : false
                    }
                    error={timeStart === "00:00" ? true : false}
                    defaultValue="00:00"
                  />
                  <Controller
                    render={(propsInController) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel shrink>{"เวลาสิ้นสุด"}</InputLabel>
                        <Select
                          native
                          label="เวลาสิ้นสุด"
                          value={timeEnd}
                          disableUnderline
                          onChange={(e) => {
                            propsInController.field.onChange(e.target.value);
                            handleChangeEnd(e.target.value);
                            handleChangeSearchDateAllDetail(
                              startDate,
                              endDate,
                              timeStart,
                              e.target.value
                            );
                          }}
                          style={{ borderRadius: "10px" }}
                        >
                          {[...new Array(48)].map((value, index) => {
                            let hour = Math.floor((0 + index * 30) / 60);
                            let minute = Math.floor((0 + index * 30) % 60);
                            if (hour < 10) {
                              hour = "0" + hour;
                            }
                            if (minute < 10) {
                              minute = "0" + minute;
                            }
                            return (
                              <option
                                value={hour + ":" + minute}
                                key={hour + ":" + minute}
                              >
                                {hour + ":" + minute}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                    name="timeEnd"
                    control={control}
                    error={errors.timeEnd ? true : false}
                    defaultValue="00:00"
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className="tabBar">
              <ButtonBlue className="CreateButton" onClick={detailEditBooking}>
                <img
                  className="IconImage"
                  src={"/assets/Image/Icon/list.png"}
                  alt="Logo"
                />
                สถานะแก้ไขการจอง
              </ButtonBlue>
              <ButtonBlue className="CreateButton" onClick={newForm}>
                <img
                  className="IconImage"
                  src={"/assets/Image/Icon/plus.png"}
                  alt="Logo"
                />
                เพิ่มสถานที่
              </ButtonBlue>
            </div>
            <div className="content">
              <Grid
                container
                spacing={2}
                style={{ height: "50%", width: "100%" }}
              >
                {allMeetingRoom &&
                  allMeetingRoom.length > 0 &&
                  allMeetingRoom.map((meeting) => {
                    return (
                      <Grid item xs={12} sm={6} lg={4}>
                        <Card
                          key={meeting.idNewMeetingRoom}
                          meeting={meeting}
                          onClickCardHandler={onClickCardHandler}
                        ></Card>
                      </Grid>
                    );
                  })}
                {/* <Grid item xs={12} sm={6} lg={4}>
                <Card onClickCardHandler={onClickCardHandler}></Card>
              </Grid> */}
              </Grid>
            </div>
          </div>
        </div>
      </StyledRoot>
    </Container>
  );
};
export default NewMeetingRoomBooking;
