import React from "react";
import { styled } from "@mui/styles";
import { Card, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const DivCoverReturn = styled("div")(({ theme }) => ({
  width: "800px",
  height: "calc(100% - 30px)",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "30px 0 0",
  overflowY: "auto",
  overflowX: "auto",
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
  },
}));

const CardStyled = styled(Card)(({ theme }) => ({
  width: "560px",
  height: "150px",
  backgroundColor: "#03256C",
  border: "5px solid white",
  marginBottom: "30px",
  boxShadow: theme.shadows[3],
  borderRadius: "20px",
  padding: "0 20px",
  display: "flex",
  alignItems: "center",
  overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const DivIcon = styled("div")({
  width: "15%",
  height: "calc(100% - 60px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
});

const IconImg = styled("img")({
  width: "100%",
  heihgt: "100%",
  objectFit: "cover",
});

const Text = styled(Typography)({
  color: "white",
  fontSize: "clamp(1em,1.043em + 1.4706vw,2.5em)",
});

const CardButton = (props) => {
  const dataCard = props.dataCard;

  return (
    <DivCoverReturn>
      {dataCard.map((value, index) => {
        return (
          <CardStyled
            key={value.path}
            onClick={() => props.onClickCard(value.path)}
          >
            <DivIcon>
              {value.Icon ? value.Icon : <IconImg src={value.Image} />}
            </DivIcon>
            <Text>{value.Name}</Text>
          </CardStyled>
        );
      })}
    </DivCoverReturn>
  );
};

export default CardButton;
