import React from "react";
import { Link } from "react-router-dom";
import { Typography, styled, Stack, Paper } from "@mui/material";
// import Card from "../../../shared/Card";

import Utils from "../../../../../utils";

const Root = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    "& .restaurant-name": {
      color: "#00bb77",
    },
  },
  "& .card-content": {
    padding: "20px 30px",
  },
  "& .restaurant-name": {
    fontSize: 22,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    transition: "color 0.15s ease-in-out",
  },
  "& .restaurant-banner": {
    position: "relative",
    paddingBottom: "56.25%",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    cursor: "pointer",
  },
  "& .restaurant-location": {
    marginTop: 12,
    color: "#9e9e9e",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& i": {
      color: "#d50000",
    },
  },
  "& .restaurant-rating": {
    fontSize: 20,
    fontWeight: 500,
    color: "#ffb300",
    "& i": {
      fontSize: 12,
      verticalAlign: "middle",
    },
    "& span": {
      color: "#9e9e9e",
    },
  },
}));

const Card = styled(Paper)(({ theme }) => ({
  padding: 0,
  transition: "transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  boxShadow: "0 2px 4px 0 rgb(35 31 32 / 20%), 0 0 1px 0 rgb(35 31 32 / 10%)",
  position: "relative",
  border: "1px solid #EEEEEE",
  backgroundColor: "#FBFCFC",
  borderRadius: 8,
  overflow: "hidden",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow:
      "rgb(145 158 171 / 60%) 0px 0px 2px 0px, rgb(145 158 171 / 60%) 0px 20px 40px -4px",
  },
  transition: "transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  boxShadow: "0 2px 4px 0 rgb(35 31 32 / 20%), 0 0 1px 0 rgb(35 31 32 / 10%)",
}));

const RestaurantCard = (props) => {
  return (
    <Card>
      <Root to={`/catering/restaurant/${props.data.idCateringRestaurant}`}>
        <div
          className="restaurant-banner"
          style={{ backgroundImage: `url(${props.data.fileUrl})` }}
        />
        <div className="card-content">
          <Typography className="restaurant-name">
            {props.data.nameRestaurant}
          </Typography>
          <Typography className="restaurant-location">
            <i className="fas fa-map-marker-alt"></i>{" "}
            {props.data.location == ""
              ? props.data.locationfrommap
              : props.data.location}{" "}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Typography className="restaurant-rating">
              <i className="fas fa-star"></i> {props.data.rating.toFixed(1)}{" "}
              <span>
                ({Utils.numberWithCommas(props.data.numberOfRatings)})
              </span>
            </Typography>
          </Stack>
        </div>
      </Root>
    </Card>
  );
};

export default RestaurantCard;
