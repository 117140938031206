import {
    VEHICLES_FETCHING,
    VEHICLES_FAILED,
    VEHICLES_SUCCESS,
  } from "./types";
  
  import VehicleService from "../services/vehicle.service";
  
  export const getAllVehicles = () => async (dispatch) => {
    try {
      const res = await VehicleService.getAllVehicles();
      if (res) {
        dispatch({
          type: VEHICLES_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: VEHICLES_FAILED,
      });
    }
  };
  