import React, { useEffect } from 'react';
import { Controller } from "react-hook-form";

import makeStyles from '@mui/styles/makeStyles';
import {
    TextField, Grid, Button, FormControl, InputLabel, MenuItem, Select, 
} from "@mui/material";

const useStyles = makeStyles((theme)=>({
    Content:{
        width:"95%",
        height:"calc(100% - 40px)",
        padding:"20px 0",
    },
    NextButton:{
        width:"fit-content",
        color:"green",
        fontSize:"18px",
        "&:hover":{
            backgroundColor:"white",
        }
    },
}));

const DetailCarForm = props => {
    const classes = useStyles();
    
    return(
        <div className={`${classes.Content}`}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField 
                        id="description" 
                        label="รายละเอียด" 
                        variant="outlined" 
                        fullWidth 
                        multiline
                        rows={10}
                        inputProps={{...props.register("description")}} 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        id="mpg" 
                        label="MPG" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("mpg")}} 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="Gas">เชื้อเพลิง</InputLabel>
                        <Controller 
                            control={props.control}
                            name="gas"
                            defaultValue=""
                            render={({field})=>(
                                <Select
                                    {...field}
                                    labelId="gas-label"
                                    id="gas"
                                    label="เชื้อเพลิง"
                                >
                                    <MenuItem value="Gasoline 95">Gasoline 95</MenuItem>
                                    <MenuItem value="Gasoline 91">Gasoline 91</MenuItem>
                                    <MenuItem value="Gasohol 95(E10)">Gasohol 95(E10)</MenuItem>
                                    <MenuItem value="Gasohol 91(E10)">Gasohol 91(E10)</MenuItem>
                                    <MenuItem value="NGV">NGV</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        id="doors" 
                        label="ประตู" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("doors")}} 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        id="seats" 
                        label="ที่นั่ง" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("seats")}} 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        id="noGas" 
                        label="เลขถัง" 
                        variant="outlined" 
                        fullWidth 
                        inputProps={{...props.register("noGas")}} 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="Mile">ไมล์</InputLabel>
                        <Controller 
                            control={props.control}
                            name="mile"
                            defaultValue=""
                            render={({field})=>(
                                <Select
                                    {...field}
                                    labelId="mile-label"
                                    id="mile"
                                    label="ไมล์"
                                >
                                    <MenuItem value="0-10,000 Miles">0-10,000 Miles</MenuItem>
                                    <MenuItem value="10,001-30,000 Miles">10,001-30,000 Miles</MenuItem>
                                    <MenuItem value="30,001-50,000 Miles">30,001-50,000 Miles</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <div style={{width:"100%",display:"flex",justifyContent:"flex-end",padding:"10px 0"}}>
                <Button className={`${classes.NextButton}`} onClick={props.onClickBackProcess}>Back</Button>
                <Button className={`${classes.NextButton}`} onClick={props.onClickNextProcess}>Next</Button>
            </div>
        </div>
    );
};

export default DetailCarForm;