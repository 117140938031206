import { DRIVERS_FETCHING, DRIVERS_FAILED, DRIVERS_SUCCESS } from '../actions/types';

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action){
    const { type, payload } = action;
    switch (type) {
        case DRIVERS_FETCHING:
            return {...state, result: null, isFetching: true, isError: false};
        case DRIVERS_FAILED:
            return {...state, result: null, isFetching: false, isError: true };
        case DRIVERS_SUCCESS:
            return {...state, result: payload, isFetching: false, isError: false};
        default:
            return state;
    }
}