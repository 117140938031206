import React, { useEffect } from "react";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { Divider } from "@mui/material";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PeopleIcon from "@mui/icons-material/People";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import RoomIcon from "@mui/icons-material/Room";
import PersonIcon from "@mui/icons-material/Person";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import InventoryIcon from "@mui/icons-material/Inventory";
import SensorDoorIcon from "@mui/icons-material/SensorDoor";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ArticleIcon from "@mui/icons-material/Article";

import utils from "../../../../../../utils";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  Title: {
    fontSize: "30px",
  },
  typeAndModelDetail: {
    textAlign: "center",
  },
  GridPicture: {
    display: "flex",
    justifyContent: "center",
  },
  Avatar: {
    width: "350px",
    height: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "250px",
      height: "150px",
    },
  },
  Dialog: {
    paddingBottom: "30px",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("sm")]: {
        margin: "0",
      },
    },
  },
  DialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "blue",
    },
    [theme.breakpoints.down("sm")]: {
      height: "550px",
      overflowY: "auto",
      padding: "0",
    },
  },
  Grid: {
    width: "calc(100% - 50px)",
    marginLeft: "50px",
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 20px)",
      marginLeft: "0",
    },
  },
  Text: {
    fontSize: "23px",
    wordWrap: "break-word",
    color: "#525656",
    fontWeight: "500",
  },
  Icon: {
    marginTop: "3px",
    fontsize: "10px",
    marginRight: "3px",
  },
  Recipient: {
    color: "#525656",
    fontWeight: "500",
  },
  IconYellow: {
    color: "#DCA700",
  },
  GridTextIcon: {
    display: "flex",
  },
  Fixed: {
    backgroundColor: "#DCA700",
    "&:hover": {
      backgroundColor: "#ba8e04",
    },
  },
  Confirm: {
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
  GridTextAndIcon: {
    display: "flex",
  },
  DialogActions: {
    paddingRight: "30px",
  },
  Button: {
    width: "150px",
    height: "50px",
  },
  TextButton: {
    color: "white",
  },
  IconWeight: {
    weight: "26px",
    height: "26px",
    marginRight: "3px",
  },
  IconImage: {
    weight: "40px",
    height: "40px",
    marginRight: "3px",
  },
}));

const GridContainText = styled(Grid)(({ theme }) => ({
  paddingRight: "24px",
}));
const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "2px",

  margin: "10px 50px 10px 50px",
  borderStyle: "dashed",
  borderWidth: "0px 0px thin",
  borderColor: "rgba(145, 158, 171, 2)",
}));

const DialogShowDetailBooking = (props) => {
  const classes = useStyles();

  useEffect(() => {
    console.log(props.getValues("deleteOldFile"));
    console.log(props.getValues("attachment"));
  });
  return (
    <Dialog open={props.open} className={classes.Dialog} maxWidth="md">
      <DialogTitle>
        <Typography
          className={`${classes.Title} ${classes.typeAndModelDetail}`}
        >
          รายละเอียดตัวอย่างที่ต้องการส่ง
        </Typography>
      </DialogTitle>
      <DividerStyled />
      <DialogContent className={classes.DialogContent}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Grid container spacing={3} className={classes.Grid}>
            <GridContainText
              item
              xs={12}
              className={classes.GridTextAndIcon}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {/* <InventoryIcon className={`${classes.Icon} ${classes.Recipient}`}/> */}
              <img
                className={`${classes.IconImage}`}
                src={"/assets/Image/Icon/samplelab.png"}
                alt="Logo"
              />
              <div
                className={`${classes.IconWeight}`}
                src={`./`}
                alt="weightOfProducts"
              ></div>
              <Typography className={`${classes.Text} ${classes.Recipient}`}>
                ชื่อตัวอย่าง :
              </Typography>
              <Typography
                className={`${classes.Text} ${classes.Recipient}`}
                style={{ color: "black" }}
              >
                &nbsp;&nbsp;{props.getValues("nameSample")}
              </Typography>
            </GridContainText>
            <GridContainText
              item
              xs={12}
              sm={6}
              className={classes.GridTextAndIcon}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {/* <SensorDoorIcon className={`${classes.Icon} ${classes.Recipient}`}/> */}
              <img
                className={`${classes.IconImage}`}
                src={"/assets/Image/Icon/samplelab.png"}
                alt="Logo"
              />
              <Typography className={`${classes.Text} ${classes.Recipient}`}>
                Grade :
              </Typography>
              <Typography
                className={`${classes.Text} ${classes.Recipient}`}
                style={{ color: "black" }}
              >
                &nbsp;&nbsp;{props.getValues("nameGrade")}
              </Typography>
            </GridContainText>
            <GridContainText
              item
              xs={12}
              sm={6}
              className={classes.GridTextAndIcon}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {/* <FormatListNumberedIcon className={`${classes.Icon} ${classes.Recipient}`} /> */}
              <img
                className={`${classes.IconImage}`}
                src={"/assets/Image/Icon/samplelab.png"}
                alt="Logo"
              />
              <Typography className={`${classes.Text} ${classes.Recipient}`}>
                Lot Number :
              </Typography>
              <Typography
                className={`${classes.Text} ${classes.Recipient}`}
                style={{ color: "black" }}
              >
                &nbsp;&nbsp;{props.getValues("lotNumber")}
              </Typography>
            </GridContainText>

            <GridContainText
              item
              xs={12}
              sm={6}
              lg={6}
              className={classes.GridTextAndIcon}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {/* <PersonIcon className={`${classes.IconWeight}`}src={`./assets/Image/Icon/weightOfProducts.png`} alt="weightOfProducts"/> */}
              <img
                className={`${classes.IconImage}`}
                src={"/assets/Image/Icon/sender.png"}
                alt="Logo"
              />
              <Typography className={`${classes.Text}`}>ผู้ส่ง :</Typography>
              <Typography
                className={`${classes.Text}`}
                style={{ color: "black" }}
              >
                &nbsp;&nbsp;{props.getValues("nameSender")}
              </Typography>
            </GridContainText>
            <GridContainText
              item
              xs={12}
              sm={6}
              lg={6}
              className={classes.GridTextAndIcon}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {/* <PersonIcon className={`${classes.IconWeight}`} alt="weightOfProducts"/> */}
              <img
                className={`${classes.IconImage}`}
                src={"/assets/Image/Icon/sender.png"}
                alt="Logo"
              />
              <Typography className={`${classes.Text}`}>
                เบอร์ผู้ส่ง :
              </Typography>
              <Typography
                className={`${classes.Text}`}
                style={{ color: "black" }}
              >
                &nbsp;&nbsp;{props.getValues("phoneSender")}
              </Typography>
            </GridContainText>
            <GridContainText
              item
              xs={12}
              sm={6}
              lg={6}
              className={classes.GridTextAndIcon}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {/* <PersonIcon className={`${classes.IconWeight}`}src={`./assets/Image/Icon/weightOfProducts.png`} alt="weightOfProducts"/> */}
              <img
                className={`${classes.IconImage}`}
                src={"/assets/Image/Icon/recipient.png"}
                alt="Logo"
              />
              <Typography className={`${classes.Text}`}>ผู้รับ :</Typography>
              <Typography
                className={`${classes.Text}`}
                style={{ color: "black" }}
              >
                &nbsp;&nbsp;{props.getValues("nameRecipient")}
              </Typography>
            </GridContainText>
            <GridContainText
              item
              xs={12}
              sm={6}
              lg={6}
              className={classes.GridTextAndIcon}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {/* <PersonIcon className={`${classes.IconWeight}`} alt="weightOfProducts"/> */}
              <img
                className={`${classes.IconImage}`}
                src={"/assets/Image/Icon/recipient.png"}
                alt="Logo"
              />
              <Typography className={`${classes.Text}`}>
                เบอร์ผู้รับ :
              </Typography>
              <Typography
                className={`${classes.Text}`}
                style={{ color: "black" }}
              >
                &nbsp;&nbsp;{props.getValues("phoneRecipient")}
              </Typography>
            </GridContainText>
            <GridContainText
              item
              xs={12}
              sm={6}
              lg={6}
              className={classes.GridTextAndIcon}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {/* <CalendarTodayIcon className={`${classes.Icon}`}/> */}
              <img
                className={`${classes.IconImage}`}
                src={"/assets/Image/Icon/calendar.png"}
                alt="Logo"
              />
              <Typography className={`${classes.Text}`}>วันที่ : </Typography>
              <Typography
                className={`${classes.Text}`}
                style={{ color: "black" }}
              >{` ${dayjs(props.getValues("date")).format(
                "DD MMMM YY"
              )}`}</Typography>
            </GridContainText>
            <GridContainText
              item
              xs={12}
              sm={6}
              lg={6}
              className={classes.GridTextAndIcon}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {/* <QueryBuilderIcon className={`${classes.Icon}`}/> */}
              <img
                className={`${classes.IconImage}`}
                src={"/assets/Image/Icon/clock.png"}
                alt="Logo"
              />
              <Typography className={`${classes.Text}`}>
                เวลารับของ :
              </Typography>
              <Typography
                className={`${classes.Text}`}
                style={{ color: "black" }}
              >{` ${props.getValues("startTime")} น.`}</Typography>
              {/* <Typography className={`${classes.Text}`}>{`${props.getValues("startTime")} น.- ${props.getValues("endTime")} น.`}</Typography> */}
            </GridContainText>
            <GridContainText
              item
              xs={12}
              sm={6}
              className={classes.GridTextAndIcon}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {/* <RoomIcon className={`${classes.Icon}`}/> */}
              <img
                className={`${classes.IconImage}`}
                src={"/assets/Image/Icon/orderSender.png"}
                alt="Logo"
              />
              <Typography className={`${classes.Text}`}>จุดรับของ :</Typography>
              <Typography
                className={`${classes.Text}`}
                style={{ color: "black" }}
              >
                &nbsp;&nbsp;{props.getValues("fromPlaceName")}
              </Typography>
            </GridContainText>
            <GridContainText
              item
              xs={12}
              sm={6}
              className={classes.GridTextAndIcon}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {/* <RoomIcon className={`${classes.Icon}`}/> */}
              <img
                className={`${classes.IconImage}`}
                src={"/assets/Image/Icon/orderRe.png"}
                alt="Logo"
              />
              <Typography className={`${classes.Text}`}>จุดส่งของ :</Typography>
              <Typography
                className={`${classes.Text}`}
                style={{ color: "black" }}
              >
                &nbsp;&nbsp;{props.getValues("toPlaceName")}
              </Typography>
            </GridContainText>
            <GridContainText item xs={12} className={classes.GridTextAndIcon}>
              {/* <ArticleIcon className={`${classes.Icon} ${classes.Recipient}`}/> */}
              <img
                className={`${classes.IconImage}`}
                src={"/assets/Image/Icon/detailOther.png"}
                alt="Logo"
              />
              <Typography className={`${classes.Text} ${classes.Recipient}`}>
                รายละเอียดเพิ่มเติม :
              </Typography>
              <Typography
                className={`${classes.Text} ${classes.Recipient}`}
                style={{ color: "black" }}
              >
                &nbsp;&nbsp;{props.getValues("detail")}
              </Typography>
            </GridContainText>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions className={classes.DialogActions}>
        <Button
          className={`${classes.Fixed} ${classes.Button}`}
          onClick={props.handleClose}
        >
          <Typography className={`${classes.Text} ${classes.TextButton}`}>
            แก้ไข
          </Typography>
        </Button>
        <Button
          className={`${classes.Confirm} ${classes.Button}`}
          onClick={props.onClickSubmit}
        >
          <Typography className={`${classes.Text} ${classes.TextButton}`}>
            ยืนยัน
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogShowDetailBooking;
