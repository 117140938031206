import React from "react";
import { Button, Stack, styled, Typography, Avatar, Grid } from "@mui/material";

import utils from "../../../../../../../utils";
import Modal from "../../../../../shared/Modal";

const Root = styled("div")(({ theme }) => ({
  padding: 16,
  width: 540,
  maxWidth: "100%",
  boxSizing: "border-box",
  "& .MuiAvatar-root": {
    // marginRight: 4,
    width: 250,
    height: 250,
  },
  "& .foodContainer": {
    display: "grid",
    // gridTemplateColumns: "calc(100% - 80px) auto",
  },
  "& .price": {
    color: "#2e7d32",
  },
}));

const PictureModal = ({ open, onClose, data }) => {
  return (
    <Modal open={open} onClose={onClose} disableFullscreen>
      <Root>
        <Stack spacing={2}>
          <Typography>รูปภาพทั้งหมด</Typography>
          {data && (
            <div
              key={`DeliverySampleShuttle${data.idDeliverySampleShuttle}`}
              className="foodContainer"
            >
              <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                {/* <Avatar variant="square" src={`${data.fileUrl}`}/> */}
                {/* <Typography>{data.path}</Typography> */}
                {JSON.parse(data.path) === null && (
                  <>
                    <Typography>ไม่มีรูปภาพ</Typography>
                  </>
                )}
                {JSON.parse(data.path) != null &&
                  JSON.parse(data.path).map((value, index) => (
                    <Grid item xs={12} lg={6} sm={12}>
                      <Avatar
                        variant="square"
                        src={`${process.env.REACT_APP_URL}image/deliverySampleShuttle/${value.path}`}
                      />{" "}
                    </Grid>
                  ))}
              </Grid>
              {/* <Stack direction="row" justifyContent="flex-end">
                                <Typography className="price">{`฿${utils.numberWithCommas((parseFloat(food.price)).toFixed(2))}`}</Typography>
                            </Stack> */}
            </div>
          )}
        </Stack>
      </Root>
    </Modal>
  );
};

export default PictureModal;
