import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { styled } from "@mui/styles";
import MaterialTable from "material-table";
import {
  MenuItem,
  InputLabel,
  Avatar,
  Checkbox,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
  List,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  ListSubheader,
  DialogTitle,
  Typography,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ButtonGroup,
  Button,
  Divider,
  AccordionActions,
  FormHelperText,
  FormControl,
  Select,
  Radio,
  RadioGroup,
  Badge,
  CircularProgress,
  Backdrop,
  FormControlLabel,
  Paper,
  Tab,
  Tabs,
  FormLabel,
  TextField,
} from "@mui/material";
import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import GridOnIcon from "@mui/icons-material/GridOn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import GoogleMap from "google-map-react";
import Alert from "@mui/material/Alert";
import clsx from "clsx";

/* import * as utils from '../../../../src/utils/util';
import { googleApiKey, location } from "./../../../constants/index";
import * as routeActions from "./../../../actions/route.action";
import * as driverActions from "./../../../actions/driver.action";
import * as vehicleActions from "./../../../actions/vehicle.action";
import * as employeeActions from "./../../../actions/employee.action";
import * as userActions from "./../../../actions/user.action"; */

/* import routeTodayReducer from "../../../reducers/routeToday.reducer"; */
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
/* import siteProfileReducer from "../../../reducers/siteProfile.reducer"; */
/* import dayjs from "dayjs"; */
//import timeZonePlugin from 'dayjs-ext/plugin/timeZone'

//dayjs.extend(timeZonePlugin)

import RouteTodayService from "../../../../services/routeToday.service";
import RouteService from "../../../../services/route.service";
import utils from "../../../../utils/index";
import { GoogleAPIkey, locationSite1 } from "../../../../utils/googleMapAssets";

import DivAddButton from "./divAddButton";
import { getAllDriver } from "../../../../actions/driver";
import { getAllVehicles } from "../../../../actions/vehicle";
import { getAllEmployees } from "../../../../actions/employee";

const useStyles = makeStyles((theme) => ({
  rootInner: {
    width: "calc(100% - 40px)",
    height: "calc(100% - 40px)",
    margin: "auto",
    display: "flex",
    padding: 20,
  },
  headerDashboard: {
    width: "100%",
    textAlign: "center",
    fontSize: 28,
  },
  summaryTop: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  summaryTopCard: {},
  summaryTopCardContent: {
    padding: "16px !important",
    textAlign: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  oneIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/images/1.svg)`,
    backgroundSize: "25px 25px",
    backgroundColor: "white",
  },
  twoIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/images/2.svg)`,
    backgroundSize: "25px 25px",
    backgroundColor: "white",
  },
  threeIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/images/3.svg)`,
    backgroundSize: "25px 25px",
    backgroundColor: "white",
  },
  fourIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/images/4.svg)`,
    backgroundSize: "25px 25px",
    backgroundColor: "white",
  },
  fiveIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/images/5.svg)`,
    backgroundSize: "25px 25px",
    backgroundColor: "white",
  },
  sixIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/images/6.svg)`,
    backgroundSize: "25px 25px",
    backgroundColor: "white",
  },
  sevenIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/images/7.svg)`,
    backgroundSize: "25px 25px",
    backgroundColor: "white",
  },
  eightIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/images/8.svg)`,
    backgroundSize: "25px 25px",
    backgroundColor: "white",
  },
  nineIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/images/9.svg)`,
    backgroundSize: "25px 25px",
    backgroundColor: "white",
  },
  deportIcon: {
    background: `url(${process.env.PUBLIC_URL}/assets/images/deport1.svg)`,
    backgroundSize: "25px 25px",
  },
  mapProfile: {
    height: "calc(100% - 10px)",
    width: "100%",
    marginTop: 10,
    "& .oneIcon": {
      background: `url(${process.env.PUBLIC_URL}/assets/Image/1.svg)`,
      backgroundSize: "25px 25px",
      backgroundColor: "white",
    },
    "& .twoIcon": {
      background: `url(${process.env.PUBLIC_URL}/assets/Image/2.svg)`,
      backgroundSize: "25px 25px",
      backgroundColor: "white",
    },
    "& .threeIcon": {
      background: `url(${process.env.PUBLIC_URL}/assets/Image/3.svg)`,
      backgroundSize: "25px 25px",
      backgroundColor: "white",
    },
    "& .fourIcon": {
      background: `url(${process.env.PUBLIC_URL}/assets/Image/4.svg)`,
      backgroundSize: "25px 25px",
      backgroundColor: "white",
    },
    "& .fiveIcon": {
      background: `url(${process.env.PUBLIC_URL}/assets/Image/5.svg)`,
      backgroundSize: "25px 25px",
      backgroundColor: "white",
    },
    "& .sixIcon": {
      background: `url(${process.env.PUBLIC_URL}/assets/Image/6.svg)`,
      backgroundSize: "25px 25px",
      backgroundColor: "white",
    },
    "& .sevenIcon": {
      background: `url(${process.env.PUBLIC_URL}/assets/Image/7.svg)`,
      backgroundSize: "25px 25px",
      backgroundColor: "white",
    },
    "& .eightIcon": {
      background: `url(${process.env.PUBLIC_URL}/assets/Image/8.svg)`,
      backgroundSize: "25px 25px",
      backgroundColor: "white",
    },
    "& .nineIcon": {
      background: `url(${process.env.PUBLIC_URL}/assets/Image/9.svg)`,
      backgroundSize: "25px 25px",
      backgroundColor: "white",
    },
    "& .deportIcon": {
      background: `url(${process.env.PUBLIC_URL}/assets/Image/deport1.svg)`,
      backgroundSize: "25px 25px",
    },
  },
  formControl: {
    margin: 0,
    width: 140,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ImgExampleMap: {
    width: "100%",
    height: "100%",
  },
}));

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const RootStyled = styled("div")({
  width: "100%",
  height: "calc(100% - 64px)",
});

const RootInnerStyled = styled("div")(({ theme }) => ({
  width: "calc(100% - 40px)",
  height: "calc(100% - 40px)",
  margin: "auto",
  display: "flex",
  padding: 20,
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const InformationDiv = styled("div")(({ theme }) => ({
  width: "60%",
  marginTop: "10px",
  paddingLeft: "10px",
  paddingRight: "5px",
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "0",
  },
}));

const MapDiv = styled("div")(({ theme }) => ({
  width: "40%",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "400px",
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userReducer = useSelector(({ userReducer }) => userReducer);
  /* const routeTodayReducer = useSelector(({ routeTodayReducer }) => routeTodayReducer); */
  const driverReducer = useSelector((state) => state.drivers);
  const vehicleReducer = useSelector((state) => state.vehicles);
  const employeeReducer = useSelector((state) => state.employees);
  const employeeEmergencyReducer = useSelector(
    ({ employeeEmergencyReducer }) => employeeEmergencyReducer
  );
  const siteProfileReducer = useSelector(
    ({ siteProfileReducer }) => siteProfileReducer
  );

  const [modeTable, setModeTable] = React.useState(false);
  const [openEditCar, setOpenEditCar] = React.useState(false);
  const [selectDriver, setSelectDriver] = React.useState("");
  const [selectVehicle, setSelectVehicle] = React.useState("");
  const [selectShift, setSelectShift] = React.useState(false);
  const [selectRoute, setSelectRoute] = React.useState([]);
  const [openEmergency, setOpenEmergency] = React.useState(false);
  const [checked, setChecked] = React.useState([1]);
  const [dayNight, setDayNight] = React.useState("day");
  const [statusSave, setStatusSave] = React.useState("");
  const [shift, setShift] = React.useState("");
  const [indexHover, setIndexHover] = React.useState(0);
  const matches = useMediaQuery("(max-width: 768px)");
  const [tab, setTab] = React.useState(0);
  const [dateRoute, setDateRoute] = React.useState("0");
  const [searchEmployee, setSearchEmployee] = React.useState("");
  const [erList, SetErList] = React.useState([]);
  const [routeTodayReducer, setRouteTodayReducer] = React.useState(null);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    const data = {
      dateRoute: "0",
      site: tabToSite(),
      period: "day",
    };
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);

    RouteTodayService.getAllRoutesDay().then((res) => {
      let routeFilter = res.data.filter((route) => route.routeDayShift === "A");
      setRouteTodayReducer(routeFilter);
    });

    if (!driverReducer.result) {
      dispatch(getAllDriver());
    }

    if (!vehicleReducer.result) {
      dispatch(getAllVehicles());
    }

    if (!employeeReducer.result) {
      dispatch(getAllEmployees());
    }

    //console.log(dayjs(today).format({ format: 'D MMMM YYYY', timeZone: 'Asia/Bangkok' }))
    /* console.log(dayjs(today, { format: 'L LT', timeZone: 'Asia/Bangkok' })) */
    /* dispatch(routeActions.getRouteToday(data)) */
    /* dispatch(driverActions.getAllDriver(props.history)); */
    /* dispatch(vehicleActions.getAllVehicle(props.history)); */
  }, []);

  /* useEffect(() => {
        if(employeeReducer.result && routeTodayReducer){
            routeTodayReducer.map((route)=>{
                SetErList([...erList,{...route,...employeeReducer.result.find((employee)=> employee.idUser == route.idUser)}]);
            });
        }
    }, [employeeReducer,routeTodayReducer]) */

  const countEmp = () => {
    const routeLine = utils.groupBy(
      routeTodayReducer,
      (routeItem) => routeItem.route
    );
    const routes = [...routeLine.values()];

    return routeTodayReducer.length - (routes.length * 2).toString();
  };

  const countVehicle = () => {
    const routeLine = utils.groupBy(
      routeTodayReducer,
      (routeItem) => routeItem.route
    );
    const routes = [...routeLine.values()];

    return routes.length;
  };

  const countShift = () => {
    return routeTodayReducer[0].routeDayShift;
  };

  const columns = [
    {
      title: "เวลา",
      field: "routeTimeOptimized",
      render: (item) => (
        <Typography variant="subtitle1">
          {moment(item.timeOptimized).format("HH:mm")}
        </Typography>
      ),
    },
    {
      title: "พนักงาน",
      field: "fNameThai",
      //render: item => <Typography variant="subtitle1"></Typography>
    },
    {
      title: "เบอร์โทรศัพท์",
      field: "telephoneMobile",
      //render: item => <Typography variant="subtitle1">{item.FullName}</Typography>
    },
    {
      title: "สายที่",
      field: "route",
      //render: item => <Typography variant="subtitle1">{item.FullName}</Typography>
    },
    {
      title: "ป้ายทะเบียน",
      field: "plateNo",
      //render: item => <Typography variant="subtitle1">{item.FullName}</Typography>
    },
    {
      title: "พนักงานขับรถ",
      field: "fullName",
      //render: item => <Typography variant="subtitle1">{item.FullName}</Typography>
    },
  ];

  const handleClose = () => {
    setOpenEditCar(false);
  };

  const handleEditCar = (items) => {
    setOpenEditCar(true);
    setSelectRoute(items);
    /* const driver = driverReducer.result.filter((item) => { return item.PKDriver == items[0].PKDriver });
        const vehicle = vehicleReducer.result.filter((item) => { return item.PKVehicle == items[0].PKVehicle });
        setSelectDriver(driver[0]);
        setSelectVehicle(vehicle[0]); */
  };

  const handleChangeDriver = (event) => {
    setSelectDriver(event.target.value);
  };

  const handleChangeVehicle = (event) => {
    setSelectVehicle(event.target.value);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    const data = {
      dateRoute: dateRoute,
      site: newValue,
      period: dayNight,
    };
    /* dispatch(routeActions.getRouteToday(data)) */
  };

  const handleChangePeriodTime = (event) => {
    setDayNight(event.target.value);
    const data = {
      dateRoute: dateRoute,
      site: tabToSite(),
      period: event.target.value,
    };
    /* dispatch(routeActions.getRouteToday(data)) */
  };

  const handleChangeDateRoute = (event) => {
    setDateRoute(event.target.value);
    const data = {
      dateRoute: event.target.value,
      site: tabToSite(),
      period: dayNight,
    };
    /* dispatch(routeActions.getRouteToday(data)) */
  };

  const handleChangeShift = (event) => {
    setShift(event.target.value);
  };

  const handleCloseSelectShift = () => {
    setSelectShift(false);
  };

  const tabToSite = () => {
    if (tab == 0) {
      return 1;
    } else if (tab == 1) {
      return 3;
    } else if (tab == 2) {
      return 7;
    }
  };

  const handleAddRouteDay = async () => {
    //setStatusSave("loading");
    handleCloseSelectShift();
    const dataRoute = {
      site: tabToSite(tab),
      shift: shift,
    };

    let result = (await RouteService.getRoutesBySiteShift(dataRoute)).data;
    if (result) {
      let dataOptimized = Object.assign([], result);
      let today = new Date();
      dataOptimized.forEach((item) => {
        let d = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          moment.duration(item.routeTimeOptimized).hours(),
          moment.duration(item.routeTimeOptimized).minutes(),
          0,
          0
        );
        //item.TimeOptimized = d.toLocaleString("en-US", { timeZone: "Asia/Bangkok" });
        item.routeTimeOptimized = moment(d).zone("+07:00").format();

        item.routeDayShift = item.routeShift;
        item.dayNight = dayNight;
        item.createDate = moment(today).format("YYYY-MM-DD");
        item.routeDate = moment(today).format("YYYY-MM-DD");
        item.idDriver = "";
        item.idVehicle = "";

        ["CreateBy", "CreateDate", "idRouteOptimized", "routeShift"].forEach(
          (key) => delete item[key]
        );
      });
      //-------------------------------------------------------------
      setStatusSave("success");
      const data = {
        dateRoute: dateRoute,
        site: tabToSite(),
        period: dayNight,
      };
      setTimeout(function () {
        setStatusSave("");
      }, 3000);
      //-------------------------------------------------------------

      /* let resultFinish = (await RouteTodayService.addRoutesDay(dataOptimized)).data; */

      /* let resultAdd = await dispatch(routeActions.postRouteToday(dateRoute, dayNight, dataOptimized, shift)); */
      /* if (resultAdd) {
                setStatusSave("success");
                const data = {
                    dateRoute: dateRoute,
                    site: tabToSite(),
                    period: dayNight
                }
                await dispatch(routeActions.getRouteToday(data))
                setTimeout(function () { setStatusSave(""); }, 3000);
            } else {
                setStatusSave("error");
                setTimeout(function () { setStatusSave(""); }, 3000);
            } */
    }
  };

  const handleSearch = (event) => {
    setSearchEmployee(event.target.value);
  };

  const handleCloseEmergency = () => {
    setOpenEmergency(false);
  };

  const removeERList = (fNameThai) => {
    SetErList(erList.filter((item) => item.fNameThai !== fNameThai));
  };

  const handleSaveDriver = async () => {
    setStatusSave("loading");
    handleClose();
    selectRoute.forEach((element) => {
      element.idDriver = selectDriver.idDriver;
      element.driverName = selectDriver.fullName;
      element.driverMobilePhone = selectDriver.telephone;

      element.idVehicle = selectVehicle.idVehicle;
      element.plate_No = selectVehicle.plate_No;
      element.idVehicleType = selectVehicle.idVehicleType;
      element.vendor = selectVehicle.vendor;
    });

    //-------------------------------------------------------------
    setStatusSave("success");
    const data = {
      dateRoute: dateRoute,
      site: tabToSite(),
      period: dayNight,
    };
    setTimeout(function () {
      setStatusSave("");
    }, 3000);
    //-------------------------------------------------------------

    /* let result = await dispatch(routeActions.updateRouteLine(selectRoute))
        if (result) {
            setStatusSave("success");
            const data = {
                dateRoute: dateRoute,
                site: tabToSite(),
                period: dayNight
            }
            dispatch(routeActions.getRouteToday(data))
            setTimeout(function () { setStatusSave(""); }, 3000);
        } else {
            setStatusSave("error");
            setTimeout(function () { setStatusSave(""); }, 3000);
        } */
  };

  const printRouteDay = () => {
    const routeLine = utils.groupBy(
      routeTodayReducer,
      (routeItem) => routeItem.routeLine
    );
    const routes = [...routeLine.values()];
    console.log("routeLine : ", routeLine);
    return routes.map((route, index) => (
      <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <div>
              <Typography
                className={classes.heading}
                style={{ marginRight: "20px" }}
              >
                ประเภทรถ :{" "}
                <span style={{ fontWeight: 600 }}>
                  {route[0].idVehicleType == 1 ? "รถตู้" : "ยังไม่ระบุ"}
                </span>{" "}
                | {route[0].route} ทะเบียนรถ :{" "}
                <span style={{ fontWeight: 600 }}>
                  {route[0].plate_No ? route[0].plate_No : "ยังไม่ระบุ"}
                </span>
              </Typography>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src={
                  route[0].idDriver
                    ? `${process.env.PUBLIC_URL}/assets/driver/${route[0].driverImageProfile}.png`
                    : `${process.env.PUBLIC_URL}/assets/images/blank.png`
                }
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  marginRight: "20px",
                }}
              />
              <Typography className={classes.heading}>
                {" "}
                พนักงานขับรถ :{" "}
                <span style={{ fontWeight: 600 }}>
                  {route[0].driverName ? route[0].driverName : "ยังไม่ระบุ"}
                </span>{" "}
                | เบอร์โทร :{" "}
                <span style={{ fontWeight: 600 }}>
                  {route[0].driverMobilePhone
                    ? utils.formatPhoneNumber(route[0].driverMobilePhone)
                    : "ยังไม่ระบุ"}
                </span>
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ overflow: "auto" }}>
          <Stepper alternativeLabel style={{ width: "100%" }}>
            {route.map((item, index) => (
              <Step key={index} active={true}>
                <StepLabel>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {index == 1 && (
                      <Typography variant="subtitle2"> 06:00 </Typography>
                    )}
                    {index != 1 && (
                      <Typography variant="subtitle2">
                        {" "}
                        {moment(item.routeTimeOptimized).format("HH:mm")}{" "}
                      </Typography>
                    )}
                    <Typography variant="caption">
                      {console.log(item)}
                      {employeeReducer.result.find(
                        (employee) => employee.idEmployees == item.idUser
                      ) !== undefined
                        ? employeeReducer.result.find(
                            (employee) => employee.idEmployees == item.idUser
                          ).firstname_TH
                        : "ไม่พบข้อมูลพนักงาน"}
                    </Typography>
                    <Typography variant="caption" style={{ color: "#9e9e9e" }}>
                      {employeeReducer.result.find(
                        (employee) => employee.idEmployees == item.idUser
                      ) !== undefined
                        ? employeeReducer.result.find(
                            (employee) => employee.idEmployees == item.idUser
                          ).departmentName
                        : ""}
                    </Typography>
                    {/* <Typography variant="caption" style={{ color: "#9e9e9e" }}> {utils.formatPhoneNumber(item.telephoneMobile)} </Typography> */}
                  </div>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              handleEditCar(route, index);
            }}
          >
            แก้ไขข้อมูลพนักงานขับรถและรถรับส่ง
          </Button>
        </AccordionActions>
      </Accordion>
    ));
  };

  const Marker = (props) => {
    const MarkerStyle = styled("div")({
      borderRadius: "50%",
      height: 25,
      width: 25,
      cursor: "pointer",
      zIndex: 0,
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "50%",
      backgroundPositionY: "45%",
    });

    return (
      <Fragment>
        <MarkerStyle
          className={clsx({
            oneIcon:
              props.place.routeLine == 1 &&
              props.place.fNameThai.search("DEPORT") == -1,
            twoIcon:
              props.place.routeLine == 2 &&
              props.place.fNameThai.search("DEPORT") == -1,
            threeIcon:
              props.place.routeLine == 3 &&
              props.place.fNameThai.search("DEPORT") == -1,
            fourIcon:
              props.place.routeLine == 4 &&
              props.place.fNameThai.search("DEPORT") == -1,
            fiveIcon:
              props.place.routeLine == 5 &&
              props.place.fNameThai.search("DEPORT") == -1,
            sixIcon:
              props.place.routeLine == 6 &&
              props.place.fNameThai.search("DEPORT") == -1,
            sevenIcon:
              props.place.routeLine == 7 &&
              props.place.fNameThai.search("DEPORT") == -1,
            eightIcon:
              props.place.routeLine == 8 &&
              props.place.fNameThai.search("DEPORT") == -1,
            nineIcon:
              props.place.routeLine == 9 &&
              props.place.fNameThai.search("DEPORT") == -1,
            deportIcon: props.place.fNameThai.search("DEPORT") == 0,
          })}
        />
        {props.show && <InfoWindow place={props.place} />}
      </Fragment>
    );
  };

  const InfoWindow = (props) => {
    const { place } = props;

    const infoWindowStyle = {
      position: "relative",

      left: 0,
      width: 240,
      backgroundColor: "white",
      boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
      padding: 10,
      fontSize: 14,
      zIndex: 100,
      borderRadius: 3,
    };

    return (
      <div style={infoWindowStyle} className={classes.detailEmp}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            alt="profile"
            className={classes.large}
            src={
              place.imageProfile
                ? place.imageProfile
                : `${process.env.PUBLIC_URL}/assets/images/blank.png`
            }
          />
          <div style={{ marginLeft: 10, width: "100%", textAlign: "center" }}>
            <Typography variant="body1" style={{ marginRight: "auto" }}>
              {place.fNameThai}
            </Typography>
            <Typography
              variant="body2"
              style={{
                marginRight: "auto",
                color: "rgba(0, 0, 0, 0.54)",
                fontSize: 12,
              }}
            >
              {place.positionNameThai}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  return (
    <RootStyled className="page">
      <RootInnerStyled>
        {/* {false && <div className={classes.headerDashboard}><Typography variant="h5">รถตู้รับส่งพนักงานกะ </Typography></div>} */}
        <MapDiv>
          <div
            className={clsx({ [classes.wideScreen]: matches })}
            style={{ width: "100%", height: "100%", display: "flex" }}
          >
            <div className={classes.mapProfile}>
              <GoogleMap
                defaultZoom={12}
                defaultCenter={locationSite1}
                bootstrapURLKeys={{ key: GoogleAPIkey }}
              >
                {routeTodayReducer &&
                  routeTodayReducer.map((route, index) => (
                    <Marker
                      key={index}
                      lat={route.lat}
                      lng={route.long}
                      show={route.PKRouteDay == indexHover}
                      place={route}
                    />
                  ))}
              </GoogleMap>
            </div>
          </div>
        </MapDiv>
        <InformationDiv className="removeScrollbar">
          <div>
            <Typography
              variant="h4"
              style={{ margin: 20, textAlign: "center" }}
            >
              จัดการเส้นทางรถตู้รับ-ส่ง พนักงานกะ
            </Typography>
          </div>
          <div
            style={{
              margin: 20,
              display: "flex",
              justifyContent: "center",
              marginTop: 0,
            }}
          >
            <FormControl>
              <Select
                style={{ fontSize: "2rem" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dateRoute}
                onChange={handleChangeDateRoute}
              >
                <ListSubheader>5 วัน ถัดไป</ListSubheader>
                <MenuItem value="5">
                  {moment().add(5, "days").format("D MMMM YYYY")}
                </MenuItem>
                <MenuItem value="4">
                  {moment().add(4, "days").format("D MMMM YYYY")}
                </MenuItem>
                <MenuItem value="3">
                  {moment().add(3, "days").format("D MMMM YYYY")}
                </MenuItem>
                <MenuItem value="2">
                  {moment().add(2, "days").format("D MMMM YYYY")}
                </MenuItem>
                <MenuItem value="1">
                  {moment().add(1, "days").format("D MMMM YYYY")}
                </MenuItem>
                <ListSubheader>วันนี้</ListSubheader>
                <MenuItem value="0">{moment().format("D MMMM YYYY")}</MenuItem>
                <ListSubheader>ย้อนหลัง 5 วัน</ListSubheader>
                <MenuItem value="-1">
                  {moment().subtract(1, "days").format("D MMMM YYYY")}
                </MenuItem>
                <MenuItem value="-2">
                  {moment().subtract(2, "days").format("D MMMM YYYY")}
                </MenuItem>
                <MenuItem value="-3">
                  {moment().subtract(3, "days").format("D MMMM YYYY")}
                </MenuItem>
                <MenuItem value="-4">
                  {moment().subtract(4, "days").format("D MMMM YYYY")}
                </MenuItem>
                <MenuItem value="-5">
                  {moment().subtract(5, "days").format("D MMMM YYYY")}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <Paper style={{ flexGrow: 1, marginTop: 10, marginBottom: 10 }}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab style={{ fontSize: 16 }} label="SITE #1" />
                <Tab style={{ fontSize: 16 }} label="SITE #3" />
                <Tab style={{ fontSize: 16 }} label="SITE #7" />
              </Tabs>
            </Paper>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="period"
                name="period1"
                value={dayNight}
                onChange={handleChangePeriodTime}
              >
                <FormControlLabel value="day" control={<Radio />} label="DAY" />
                <FormControlLabel
                  value="night"
                  control={<Radio />}
                  label="NIGHT"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {routeTodayReducer ? (
            <Fragment>
              <div className={classes.summaryTop}>
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Card className={classes.summaryTopCard}>
                      <CardContent className={classes.summaryTopCardContent}>
                        <Typography variant="h3">
                          {routeTodayReducer ? countVehicle() : "0"}
                        </Typography>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                        >
                          รถตู้ (คัน)
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Card className={classes.summaryTopCard}>
                      <CardContent className={classes.summaryTopCardContent}>
                        <Typography variant="h3">
                          {routeTodayReducer ? countEmp() : "0"}
                        </Typography>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                        >
                          พนักงาน (คน)
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Card className={classes.summaryTopCard}>
                      <CardContent className={classes.summaryTopCardContent}>
                        <Typography variant="h3">
                          {routeTodayReducer ? countShift() : ""}
                        </Typography>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                        >
                          Shift
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <div>
                  <Button
                    startIcon={<ErrorOutlineIcon />}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setOpenEmergency(true);
                      SetErList(routeTodayReducer);
                      /* dispatch(employeeActions.getEmployeeEmergency(tabToSite()))
                                    dispatch(employeeActions.getSiteProfile(tabToSite())) */
                    }}
                    style={{ backgroundColor: "#f44336" }}
                  >
                    {" "}
                    เหตุฉุกเฉิน{" "}
                  </Button>
                </div>
                <div>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      onClick={() => {
                        setModeTable(false);
                      }}
                    >
                      <ViewAgendaIcon />
                    </Button>
                    <Button
                      onClick={() => {
                        setModeTable(true);
                      }}
                    >
                      <GridOnIcon />
                    </Button>
                  </ButtonGroup>
                </div>
                <Dialog
                  open={openEmergency}
                  onClose={handleCloseEmergency}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"ปรับเส้นทางรับ-ส่งพนักงานฉุกเฉิน"}
                  </DialogTitle>
                  <DialogContent>
                    <div style={{ display: "flex" }}>
                      <div>
                        <Typography variant="body1">
                          รายชื่อพนักงานทั้งหมด
                        </Typography>
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <TextField
                            label="ค้นหาพนักงาน"
                            id="outlined-size-small"
                            variant="outlined"
                            style={{ width: "100%" }}
                            onChange={handleSearch}
                            value={searchEmployee}
                          />
                        </div>
                        {employeeReducer.result ? (
                          <List style={{ height: 310, overflow: "auto" }}>
                            {employeeReducer.result
                              .filter((item) => {
                                return (
                                  item.firstname_TH.includes(searchEmployee) &&
                                  item.firstname_TH.indexOf("site") == -1
                                );
                              })
                              .map((route, index) => (
                                <ListItem key={index}>
                                  <ListItemAvatar>
                                    <Avatar
                                      alt={route.firstname_TH}
                                      src={`${process.env.PUBLIC_URL}/assets/profiles/${route.idUserb}.jpg`}
                                    ></Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={route.firstname_TH}
                                    /* secondary={route.departmentThai} */
                                    secondary={route.departmentName}
                                  />
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      edge="end"
                                      aria-label="addEmp"
                                      style={{ color: "#00c853" }}
                                      onClick={() => {
                                        SetErList([...erList, route]);
                                      }}
                                      size="large"
                                    >
                                      <CheckCircleIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              ))}
                          </List>
                        ) : (
                          <div
                            style={{
                              height: 310,
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                            }}
                          >
                            <CircularProgress color="inherit" />
                          </div>
                        )}
                      </div>
                      <div>
                        <Typography variant="body1">
                          รายชื่อพนักงานในเส้นทาง
                        </Typography>
                        <List
                          style={{
                            height: 375,
                            overflow: "auto",
                            marginTop: 10,
                          }}
                        >
                          {employeeReducer.result &&
                            employeeReducer.result
                              .filter(
                                (item) =>
                                  item.firstname_TH.indexOf("site") == -1
                              )
                              .map((route, index) => (
                                <ListItem key={index}>
                                  <ListItemAvatar>
                                    <Avatar
                                      alt={route.firstname_TH}
                                      src={`${process.env.PUBLIC_URL}/assets/profiles/${route.idUser}.jpg`}
                                    ></Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={route.firstname_TH}
                                    secondary={route.departmentName}
                                  />
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      edge="end"
                                      aria-label="deleteEmp"
                                      color="secondary"
                                      onClick={() => {
                                        removeERList(route.firstname_TH);
                                      }}
                                      size="large"
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              ))}
                        </List>
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseEmergency} color="primary">
                      {" "}
                      ยกเลิก{" "}
                    </Button>
                    <Button
                      color="primary"
                      autoFocus
                      onClick={async () => {
                        handleCloseEmergency();
                        setStatusSave("loading");
                        var empList = [];
                        var temp = null;
                        //-------------------------------------------------------------
                        setStatusSave("success");
                        const data = {
                          dateRoute: dateRoute,
                          site: tabToSite(),
                          period: dayNight,
                        };
                        setTimeout(function () {
                          setStatusSave("");
                        }, 3000);
                        //-------------------------------------------------------------
                        /* erList.forEach(itemEr => {
                                                if (itemEr.fNameThai.indexOf("DEPORT") == -1) {
                                                    temp = employeeEmergencyReducer.filter(item => itemEr.fNameThai == item.fNameThai)
                                                    empList.push(temp[0])
                                                }
                                            })

                                            empList.push(siteProfileReducer[0])

                                            routeTodayReducer.forEach(route => {
                                                route.routeStatus = false;
                                            }) */

                        /* dispatch(routeActions.updateRouteLine(routeTodayReducer.result))
                                            let result = await dispatch(routeActions.updateEmergency(dateRoute, dayNight, empList, countShift()));
                                            if (result) {
                                                setStatusSave("success");
                                                const data = {
                                                    dateRoute: dateRoute,
                                                    site: tabToSite(),
                                                    period: dayNight
                                                }
                                                dispatch(routeActions.getRouteToday(data))
                                                setTimeout(function () { setStatusSave(""); }, 3000);

                                            } else {
                                                setStatusSave("error");
                                                setTimeout(function () { setStatusSave(""); }, 3000);
                                            } */
                      }}
                    >
                      {" "}
                      คำนวณเส้นทางใหม่{" "}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              {!modeTable ? (
                <div>
                  {routeTodayReducer &&
                    employeeReducer.result &&
                    printRouteDay()}
                  <Dialog
                    open={openEditCar}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{ width: "400px" }}
                    >
                      {"แก้ไขข้อมูลพนักงานขับรถและรถรับส่ง"}
                    </DialogTitle>
                    <DialogContent>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormControl style={{ margin: "10px 0" }}>
                          <InputLabel id="demo-simple-select-label">
                            พนักงานขับรถ
                          </InputLabel>
                          <Select
                            fullwidth="true"
                            label="พนักงานขับรถ"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectDriver}
                            onChange={handleChangeDriver}
                          >
                            {driverReducer.result
                              ? driverReducer.result.map((items, index) => (
                                  <MenuItem value={items} key={index}>
                                    {items.FullName}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        </FormControl>
                        <FormControl style={{ margin: "10px 0" }}>
                          <InputLabel id="demo-simple-select-label">
                            รถตู้
                          </InputLabel>
                          <Select
                            label="รถตู้"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectVehicle}
                            onChange={handleChangeVehicle}
                          >
                            {vehicleReducer.result
                              ? vehicleReducer.result.map((items, index) => (
                                  <MenuItem value={items} key={index}>
                                    {items.Plate_No}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        </FormControl>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        {" "}
                        ยกเลิก{" "}
                      </Button>
                      <Button
                        onClick={handleSaveDriver}
                        color="primary"
                        autoFocus
                      >
                        {" "}
                        บันทึก{" "}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                <div>
                  {routeTodayReducer && (
                    <MaterialTable
                      title=""
                      columns={columns}
                      data={routeTodayReducer ? routeTodayReducer : []}
                      options={{
                        actionsColumnIndex: -1,
                        pageSize: 20,
                        exportButton: true,
                        exportAllData: true,
                      }}
                    />
                  )}
                </div>
              )}
            </Fragment>
          ) : (
            <DivAddButton
              setSelectShift={setSelectShift}
              selectShift={selectShift}
              handleCloseSelectShift={handleCloseSelectShift}
              shift={shift}
              handleChangeShift={handleChangeShift}
              handleAddRouteDay={handleAddRouteDay}
            />
          )}
        </InformationDiv>
      </RootInnerStyled>
      <Backdrop className={classes.backdrop} open={statusSave != ""}>
        {statusSave == "loading" && <CircularProgress color="inherit" />}
        {statusSave == "success" && (
          <Alert variant="filled" severity="success">
            บันทึกสำเร็จ
          </Alert>
        )}
        {statusSave == "error" && (
          <Alert variant="filled" severity="error">
            เกิดข้อผิดพลาด
          </Alert>
        )}
      </Backdrop>
    </RootStyled>
  );
};

export default Dashboard;
