import {
  DRIVER_BOOKINGS_FETCHING,
  DRIVER_BOOKINGS_FAILED,
  DRIVER_BOOKINGS_SUCCESS,
} from "../../actions/types";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case DRIVER_BOOKINGS_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    case DRIVER_BOOKINGS_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };
    case DRIVER_BOOKINGS_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
