import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { styled } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Routers from "../../../Routers";

import { Header, AdminMenu, ManagerMenu, UserMenu } from "../index";

const drawerWidth = 240;

const drawerUseStyles = makeStyles((theme) => ({
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "white",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drawerClose: {
    backgroundColor: "white",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bannerOpen: {
    backgroundImage:
      // "url(" +
      // process.env.PUBLIC_URL +
      // "/images/background_menu.jpg" +
      // ")"
      `url(${process.env.PUBLIC_URL}/images/background_menu.jpg)`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  isActive: {
    backgroundColor: "rgba(232, 245, 255,0.9)",
    color: "#0080ff",
  },
}));
const PartOfRouter = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: "calc(100% - 56px)",
  marginLeft: "56px",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0",
    width: "100%",
  },
}));

const PartOfRouterDriver = styled("div")({
  display: "flex",
  height: "100%",
  width: "100%",
  marginLeft: "0",
});

const DrawerContainer = ({ open, onClickList }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
      setIsManager(currentUser.roles.includes("ROLE_MANAGER"));
    }
  }, [currentUser]);

  const classes = drawerUseStyles();
  return (
    <Drawer
      variant="permanent"
      className={clsx({
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.bannerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}></div>
      {isManager && (
        <Fragment>
          <ManagerMenu onClickList={onClickList} />
          <Divider />
        </Fragment>
      )}
      {isAdmin && (
        <Fragment>
          <AdminMenu open={open} onClickList={onClickList} />
          <Divider />
        </Fragment>
      )}
      <UserMenu onClickList={onClickList} />
    </Drawer>
  );
};

const NavBar = (props) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Fragment>
        <Header handleDrawerOpen={handleDrawerOpen} isDriver={props.isDriver} />
        {!props.isDriver && (
          <DrawerContainer open={open} onClickList={() => setOpen(false)} />
        )}
        {/* {open && (
          <div
            style={{
              width: "100%",
              height: "100%",
              // backgroundColor: "rgba(0,0,0,0.1)",
              position: "fixed",
              top: "0",
              left: "0",
              // zIndex: "100",
            }}
          ></div>
        )} */}
      </Fragment>
    </Box>
  );
};

export default NavBar;
