import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "250px",
    display: "flex",
    position: "relative",
    scrollSnapAlign: "start",
  },
  Avatar: {
    width: "125px",
    height: "125px",
    border: "5px solid white",
    boxShadow: "0 0 10px 7px #36454f",
  },
  Text: {
    fontSize: "25px",
    color: "white",
    marginTop: "10px",
    marginBottom: "0px",
  },
  SubText: {
    fontSize: "16px",
    height: "50px",
    color: "white",
    marginBottom: "10px",
    textAlign: "center",
  },
  NavButton: {
    display: "flex",
    marginTop: "10px",
  },
  DivCompleted: {
    display: "block",
  },
  Line: {
    height: "15px",
    width: "200px",
    backgroundColor: "white",
    marginTop: "215px",
    position: "absolute",
    marginLeft: "-100px",
  },
  Content: {
    width: "250px",
    height :"480px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: "10",
    marginRight: "0",
  },
  Button: {
    width: "70px",
    height: "70px",
    color: "white",
    borderRadius: "50%",
  },
  ButtonView: {
    height: "45px",
    width: "100px",
    fontSize: "16px",
    color:"black",
    backgroundColor: "#EFC458",
    "&:hover": {
      backgroundColor: "#EFC458",
    },
  },
  ButtonDone: {
    backgroundColor: "#00A08F",
    "&:hover": {
      backgroundColor: "#00A08F",
    },
    marginRight: "20px",
  },
  ButtonDelete: {
    backgroundColor: "#F66747",
    "&:hover": {
      backgroundColor: "#F66747",
    },
  },
  IconCheckCircle:{
    color: "#32CD32",
  },
  TextCompleted:{
    color: "#32CD32",
    textAlign: "center",
  },
}));

const DivEmployee = (props) => {
  const classes = useStyles();
  const [onClickDone, setOnClickDone] = useState(false);

  const OnClickDeleteHandler = () => {
    props.onClickDeleteHandler(props.currentEmployee.idUser);
  };

  const OnClickDoneHandler = () => {
    props.onClickGettingComplete();
    setOnClickDone(true);
  };

  return (
    <div className={classes.DivCoverReturn}>
      {props.order !== 0 && <Divider className={`${classes.Line}`} />}
      <div className={classes.Content}>
        <Button
          className={`${classes.ButtonView}`}
          onClick={()=>props.onClickViewButton(props.currentEmployee.idUser)}
        >
          View
        </Button>
        <Typography className={`${classes.Text}`}>
          {props.currentEmployee.fNameThaiUser}
        </Typography>
        <Typography className={`${classes.SubText}`}>
          {`${props.currentEmployee.departmentUser}`}
        </Typography>
        <Avatar
          className={`${classes.Avatar}`}
          src={`${process.env.REACT_APP_URL}image/profile/${props.currentEmployee.imageUser}`}
          alt={`${props.currentEmployee.fNameThaiUser}`}
        />
        <Typography className={`${classes.Text}`}>
          {`ลำดับที่  ${props.order+1}`}
        </Typography>
        {onClickDone?
          <div className={`${classes.DivCompleted}`}>
            <CheckCircleOutlineIcon className={`${classes.IconCheckCircle} ${classes.Button}`}/>
            <Typography className={`${classes.TextCompleted}`}>สำเร็จ</Typography>
          </div>
          :
          <div className={`${classes.NavButton}`}>
            <Button className={`${classes.Button} ${classes.ButtonDone}`} onClick={OnClickDoneHandler}>
              <DoneIcon />
            </Button>
            <Button className={`${classes.Button} ${classes.ButtonDelete}`} onClick={OnClickDeleteHandler}>
              <CloseIcon />
            </Button>
          </div>
        }
      </div>
    </div>
  );
};

export default DivEmployee;
