import React from 'react';

import { styled } from '@mui/styles';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const DivCoverReturn = styled("div")({
    width:"190px",
    height:"70px",
    position: "absolute",
    right: "10px",
    top: "85px",
});

const ButtonStyled = styled(Button)({
    width: "100%",
    height: "100%",
    backgroundColor: "#006400",
    "&:hover":{
        backgroundColor: "#006400",
    }
});

const IconStyled = styled("img")({
    width: "50px",
    height: "50px",
});

const Text = styled(Typography)({
    color: "white",
    fontSize: "30px",
    fontWeight: "bold",
    marginTop: "10px",
    marginLeft: "10px",
});

const ButtonGoToMapPage = (props) => {

    return(
        <DivCoverReturn>
            <ButtonStyled onClick={props.OnClickMapButtonHandler}>
                <IconStyled src="./assets/Image/Icon/map.png" alt="Icon_map" />
                <Text>Map</Text>
            </ButtonStyled>
        </DivCoverReturn>
    );
};

export default ButtonGoToMapPage;