import React, { useState } from 'react'

import { styled } from '@mui/styles';
import {
    Button
} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const PartOfImage = styled("div")(({theme})=>({
    width:"100%",
    height:"400px",
    display:"flex",
    justifyContent:"center",
    "& .Image":{
        width:"100%",
        height:"100%",
        objectFit:"contain",
    },
    [theme.breakpoints.down("sm")]:{
        height:"200px",
    },
}));

const Content = styled("div")({
    width:"90%",
    height:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    position:"relative"
});

const ArrowButton = styled(Button)(({theme})=>({
    position:"absolute",
    padding:"10px",
    borderRadius:"50%",
    backgroundColor:"rgba(255,255,255,0.5)",
    "& .Icon":{
        fontSize:"50px",
        color:"black",
    },
    "&:hover":{
        backgroundColor:"rgba(255,255,255,0.5)",
    },
}));

const PartOfImageCar = ({ listImage }) => {
    const [indexImage, setIndexImage] = useState(0);

    const onClickArrowPrevious = () => {
        if(indexImage === 0){
            setIndexImage(listImage.length-1);
        }
        else{
            setIndexImage(indexImage-1);
        }
    };

    const onClickArrowNext = () => {
        if(indexImage === listImage.length-1){
            setIndexImage(0);
        }
        else{
            setIndexImage(indexImage+1);
        }
    };

    return(
        <PartOfImage>
            <Content>
                <ArrowButton style={{left:"0"}} disableRipple onClick={onClickArrowPrevious}><ArrowBackIosNewIcon className="Icon" /></ArrowButton>
                <img src={`../../assets/Image/example/${listImage[indexImage]}`} alt={listImage[indexImage]} className="Image"/>
                <ArrowButton style={{right:"0"}} disableRipple onClick={onClickArrowNext}><ArrowForwardIosIcon className="Icon" /></ArrowButton>
            </Content>
        </PartOfImage>
    );
};

export default PartOfImageCar;