import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { styled } from '@mui/styles';

import ListAllBuses from "./listAllBuses";
import DetailBus from "./DetailBus";

import VehicleService from "../../../../services/vehicle.service";
import DriverRouteDay from "../../../../services/driverRouteDay.service";

const DivCoverRetern = styled("div")(({theme})=>({
    width:"100%",
    paddingLeft: "0",
    display: "flex",
    [theme.breakpoints.down('sm')]:{
        flexDirection:"column",
    },
}));

const ChoosingCar = (props) => {
    const [vehicleChosen, setVehicleChosen] = useState(null);
    const [AllVehiclesShiftCar, setAllVehiclesShiftCar] = useState([]);
    const { result: Driver } = useSelector((state) => state.driverRouteDay);

    useEffect(() => {
        let CatchError = false;
        VehicleService.getVehiclesByUseFor("ShiftCar").then(
            (res) => {
                if(CatchError){ return; }
                if(res.data){
                    console.log("ShiftCar : ",res.data);
                    setAllVehiclesShiftCar(res.data);
                }
            }
        ).catch( error =>{
            console.log(error);
            setAllVehiclesShiftCar([]);
        });
        return () => {
            CatchError = true;
        }
    }, [])

    const onClickVehicleRow = (vehicle) => {
        setVehicleChosen(vehicle);
    }

    const OnClickConfirmButton = () => {
        let data = {
            idDriver : Driver.idDriver,
            idVehicle : vehicleChosen.idVehicle
        };
        console.log(data);
        DriverRouteDay.postIdVehicle(data).then(
            ()=>{
                props.history.push("/bedtimelastnight");
            }
        ).catch(error => console.log(error));
    };

    return <DivCoverRetern className="page">
        {AllVehiclesShiftCar &&
            <>
                <ListAllBuses AllVehiclesShiftCar={AllVehiclesShiftCar} onClickVehicleRow={onClickVehicleRow}/>
                <DetailBus vehicle={vehicleChosen} OnClickConfirmButton={OnClickConfirmButton}/>
            </>
        }
    </DivCoverRetern>;
};

export default ChoosingCar;
