import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { styled } from "@mui/styles";
import {
  Typography,
  Card,
  Grid,
  Avatar,
  Button,
  Modal,
  Paper,
  TextField,
  IconButton,
  useMediaQuery,
} from "@mui/material";
// import Masonry from "@mui/lab/Masonry";
// import MasonryItem from "@mui/lab/MasonryItem";

import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

import NumOfLendChart from "./numOfLendChart";
import ChartOfScore from "./chartOfScore";
import ChartOfReceipt from "./chartOfReceipt";
import utils from "../../../../../../utils";
import { getUserProfile } from "../../../../../../actions/user";
import { getLentCarsByLenderId } from "../../../../../../actions/lentcar";
import { getHistoryLentCarWaitingByLenderId } from "../../../../../../actions/historyLentCar";

const DivCoverReturn = styled("div")(({ theme }) => ({
  height: "calc(100% - 64px)",
  backgroundColor: theme.palette.grey[100],
}));

const ContentAllCard = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "30px",
  paddingBottom: "0",
  backgroundColor: theme.palette.grey[100],
}));

const CardStyled = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "100%",
  boxShadow: theme.shadows[0],
  "& .ProfileCard": {
    width: "calc(100% - 40px)",
    height: "calc(100% - 40px)",
    display: "flex",
    padding: "40px 20px 20px",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  "& .CarCard": {
    padding: "20px",
  },
  "& .PendingCard": {
    height: "150px",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .RevenueCard": {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px 20px",
      height: "350px",
      alignItems: "flex-start",
    },
  },
  "& .PointCard": {
    height: "150px",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "150px",
  height: "150px",
  [theme.breakpoints.down("lg")]: {
    width: "100px",
    height: "100px",
  },
}));

const ContentProfile = styled("div")(({ theme }) => ({
  width: "calc(100% - 180px)",
  marginLeft: "30px",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0",
    width: "100%",
  },
}));

const Name = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: "bold",
  [theme.breakpoints.down("lg")]: {
    fontSize: "24px",
  },
}));

const SubText = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  marginRight: "30px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
  },
}));

const IconAndText = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .Icon": {
    marginRight: "10px",
  },
}));

const CarImage = styled(Avatar)(({ theme }) => ({
  width: "300px",
  height: "150px",
  [theme.breakpoints.down("lg")]: {
    width: "150px",
    height: "100px",
  },
}));

const TextNumCar = styled(Typography)(({ theme }) => ({
  fontSize: "50px",
  fontWeight: "bold",
  textAlign: "center",
}));

const ContentCar = styled("div")(({ theme }) => ({
  width: "calc(100% - 300px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: "30px",
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    width: "calc(100% - 150px)",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginBottom: "20px",
    marginLeft: "0",
  },
}));

const AddCarButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  right: "0",
  bottom: "0",
  backgroundColor: "white",
  color: "black",
  border: "1px solid black",
  [theme.breakpoints.down("sm")]: {
    bottom: "-25px",
  },
}));

const PendingText = styled(Typography)(({ theme }) => ({
  fontSize: "70px",
  fontWeight: "bold",
  cursor: "pointer",
  textDecoration: "underline",
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.primary.dark,
  },
}));

const TextList = styled(Typography)(({ theme }) => ({
  position: "absolute",
  right: "30px",
  fontSize: "30px",
  fontWeight: "bold",
}));

const TopicPending = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  position: "absolute",
  left: "20px",
  top: "20px",
  color: theme.palette.grey[500],
}));

const EditButton = styled(Button)(({ theme }) => ({
  color: "black",
  position: "absolute",
  right: "10px",
  top: "10px",
  "&:hover": {
    backgroundColor: "white",
  },
}));

const AllReceipt = styled(Button)(({ theme }) => ({
  position: "absolute",
  right: "20px",
  bottom: "20px",
  color: "black",
  "&:hover": {
    backgroundColor: "white",
  },
}));

const ModalEditProfile = styled(Modal)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "flex-start",
  },
}));

const PaperEditProfile = styled(Paper)(({ theme }) => ({
  width: "45%",
  minWidth: "400px",
  padding: "50px 20px 20px",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 40px)",
    minWidth: "initial",
  },
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  top: "10px",
  right: "15px",
  position: "absolute",
  "&:hover": {
    backgroundColor: theme.palette.primary.contrastText,
  },
  "& .Icon": {
    fontSize: "18px",
    color: theme.palette.grey[400],
    "&:hover": {
      color: theme.palette.grey[600],
    },
  },
}));

const GridContainerStyled = styled(Grid)(({ theme }) => ({
  width: "100%",
  marginLeft: "-12px",
}));

const DivSubmitButton = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  paddingTop: "20px",
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: "90px",
  marginRight: "10px",
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
  fontSize: "16px",
  "&:hover": {
    backgroundColor: theme.palette.success.dark,
  },
}));

const DashBoardLender = (props) => {
  const { user: AuthUser } = useSelector((state) => state.auth);
  const { result: Profile } = useSelector((state) => state.userProfile);
  const { result: LentCars } = useSelector((state) => state.lentCars);
  const { result: Pending } = useSelector((state) => state.historyLentCars);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const { register, handleSubmit, control, getValues, setValue, watch } =
    useForm();

  const max1200width = useMediaQuery("(max-width:1200px)");
  const max600width = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    dispatch(getUserProfile(AuthUser.id));
    dispatch(getLentCarsByLenderId(AuthUser.id));
    dispatch(getHistoryLentCarWaitingByLenderId(AuthUser.id));
  }, []);

  const onClickAddCar = () => {
    props.history.push("/friend2friend_Car/lender/add_car");
  };

  const CalculateRecieptAll = () => {
    let reciepts = 0;
    if (LentCars) {
      LentCars.map((car) => {
        reciepts += car.receipts;
      });
    }
    return reciepts;
  };

  const onClickEditButton = () => {
    setOpenModal(true);
    setValue("firstname_TH", Profile.firstname_TH);
    setValue("lastname_TH", Profile.lastname_TH);
    setValue("province", Profile.province);
    setValue("district", Profile.district);
    setValue("telephoneMobile", Profile.telephoneMobile);
    setValue("email", Profile.email);
  };

  const onClickSubmitButton = (data) => {
    Profile.firstname_TH = data.firstname_TH;
    Profile.lastname_TH = data.lastname_TH;
    Profile.province = data.province;
    Profile.district = data.district;
    Profile.telephoneMobile = data.telephoneMobile;
    Profile.email = data.email;
    setOpenModal(false);
  };

  return (
    <DivCoverReturn className="page">
      <ContentAllCard>
        <Grid container spacing={3} style={{ paddingBottom: "24px" }}>
          <Grid item xs={12} sm={6} lg={3}>
            <CardStyled>
              <NumOfLendChart
                data={[0, 0, 1, 0, 1]}
                labelX={[12, 2, 10, 4, 5]}
                Title="ถูกยืม(ครั้ง)"
                SubTitle="2"
                color="#0083FF"
              />
            </CardStyled>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <CardStyled>
              <NumOfLendChart
                data={[0, 0, 1, 0, 1]}
                labelX={[12, 2, 10, 4, 5]}
                Title="สำเร็จ(ครั้ง)"
                SubTitle="2"
                color="#00C40C"
              />
            </CardStyled>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <CardStyled>
              <NumOfLendChart
                data={[0, 0, 500, 0, 1500]}
                labelX={[12, 2, 10, 4, 5]}
                Title="รายได้เดือนนี้(บาท)"
                SubTitle={utils.numberWithCommas(1500)}
                color="#FF8700"
              />
            </CardStyled>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <CardStyled>
              <NumOfLendChart
                data={[0, 0, 500, 500, 1500]}
                labelX={[12, 2, 10, 4, 5]}
                Title="รายได้รวม(บาท)"
                SubTitle={utils.numberWithCommas(2000)}
                color="#9C27B0"
              />
            </CardStyled>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardStyled>
              <div className="ProfileCard">
                {Profile && (
                  <>
                    <AvatarStyled
                      src={`${process.env.REACT_APP_URL}image/profile/${Profile.imageName}`}
                    />
                    <ContentProfile>
                      <Name
                        noWrap
                      >{`${Profile.firstname_TH} ${Profile.lastname_TH}`}</Name>
                      <div style={{ width: "100%", display: "flex" }}>
                        <IconAndText>
                          <PlaceIcon className="Icon" />
                          <SubText>{`${Profile.province},${Profile.district}`}</SubText>
                        </IconAndText>
                      </div>
                      <IconAndText>
                        <PhoneIcon className="Icon" />
                        <SubText>{`${Profile.telephoneMobile}`}</SubText>
                      </IconAndText>
                      <IconAndText>
                        <EmailIcon className="Icon" />
                        <SubText>{`${Profile.email}`}</SubText>
                      </IconAndText>
                    </ContentProfile>
                    <EditButton disableRipple onClick={onClickEditButton}>
                      Edit
                    </EditButton>
                  </>
                )}
              </div>
            </CardStyled>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardStyled style={{ height: "100%" }}>
              <div className="CarCard ProfileCard">
                <CarImage
                  variant="square"
                  src={`${process.env.REACT_APP_URL}image/car/car1.png`}
                />
                <ContentCar>
                  <TextNumCar>{LentCars ? LentCars.length : 0} คัน</TextNumCar>
                  <AddCarButton onClick={onClickAddCar}>Add Car</AddCarButton>
                </ContentCar>
              </div>
            </CardStyled>
          </Grid>
          {max1200width && (
            <>
              <Grid item xs={12} md={6}>
                <CardStyled>
                  <div className="ProfileCard PendingCard">
                    <TopicPending>Pending</TopicPending>
                    <PendingText
                      onClick={() =>
                        props.history.push("/friend2friend_Car/lender/pending")
                      }
                    >
                      {Pending ? Pending.length : 0}
                    </PendingText>
                    <TextList>รายการ</TextList>
                  </div>
                </CardStyled>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardStyled>
                  <div className="ProfileCard PointCard">
                    <TopicPending>Point</TopicPending>
                    <ChartOfScore
                      width={max600width ? "300px" : "400px"}
                      point={Profile ? Profile.pointLentCar : 0}
                    />
                  </div>
                </CardStyled>
              </Grid>
            </>
          )}
          {!max1200width && (
            <Grid item container lg={6}>
              <Grid item lg={12} style={{ marginBottom: "24px" }}>
                <CardStyled>
                  <div className="ProfileCard PendingCard">
                    <TopicPending>Pending</TopicPending>
                    <PendingText
                      onClick={() =>
                        props.history.push("/friend2friend_Car/lender/pending")
                      }
                    >
                      {Pending ? Pending.length : 0}
                    </PendingText>
                    <TextList>รายการ</TextList>
                  </div>
                </CardStyled>
              </Grid>
              <Grid item lg={12}>
                <CardStyled>
                  <div className="ProfileCard PointCard">
                    <TopicPending>Point</TopicPending>
                    <ChartOfScore
                      width="400px"
                      point={Profile ? Profile.pointLentCar : 0}
                    />
                  </div>
                </CardStyled>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <CardStyled>
              <div className="RevenueCard ProfileCard">
                <ChartOfReceipt
                  reciept={CalculateRecieptAll()}
                  extra={100}
                  delivery={200}
                  others={100}
                />
                <AllReceipt
                  disableRipple
                  onClick={() =>
                    props.history.push("/friend2friend_Car/lender/receipt")
                  }
                >
                  All Reciept
                </AllReceipt>
              </div>
            </CardStyled>
          </Grid>
        </Grid>
      </ContentAllCard>
      {Profile && (
        <ModalEditProfile open={openModal} onClose={() => setOpenModal(false)}>
          <PaperEditProfile>
            <CloseIconButton disableRipple onClick={() => setOpenModal(false)}>
              <i className="fas fa-times Icon"></i>
            </CloseIconButton>
            <GridContainerStyled container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="firstname_TH"
                  label="FirstName"
                  fullWidth
                  defaultValue={Profile?.firstname_TH}
                  inputProps={{ ...register("firstname_TH") }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastname_TH"
                  label="LastName"
                  fullWidth
                  defaultValue={Profile?.lastname_TH}
                  inputProps={{ ...register("lastname_TH") }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="province"
                  label="จังหวัด"
                  fullWidth
                  defaultValue={Profile?.province}
                  inputProps={{ ...register("province") }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="district"
                  label="เขต/อำเภอ"
                  fullWidth
                  defaultValue={Profile?.district}
                  inputProps={{ ...register("district") }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="telephoneMobile"
                  label="เบอร์มือถือ"
                  fullWidth
                  defaultValue={Profile?.telephoneMobile}
                  inputProps={{ ...register("telephoneMobile") }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="email"
                  label="อีเมล"
                  fullWidth
                  defaultValue={Profile?.email}
                  inputProps={{ ...register("email") }}
                />
              </Grid>
            </GridContainerStyled>
            <DivSubmitButton>
              <SubmitButton onClick={handleSubmit(onClickSubmitButton)}>
                ยืนยัน
              </SubmitButton>
            </DivSubmitButton>
          </PaperEditProfile>
        </ModalEditProfile>
      )}
    </DivCoverReturn>
  );
};

export default DashBoardLender;
