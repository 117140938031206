import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Route, Switch, Redirect } from "react-router-dom";
import clsx from 'clsx';

import { styled } from '@mui/styles';
import { 
    Card, Button, Grid
} from '@mui/material';

import NumOfLentCar from "./numOfLendChart";
import TableActions from "./TableActions";
import TableCars from "./TableCars";
import AdminLentCarService from "../../../../services/adminLentCar.service";

require('dayjs/locale/th');
dayjs.locale('th');

const DivCoverReturn = styled("div")(({
    padding:"70px 15px 10px",
}));

const NavButton = styled("div")(({theme})=>({
    height:"60px",
    marginTop:"20px",
    "& .CurrentTable":{
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
    },
}));

const ButtonStyled = styled(Button)(({theme})=>({
    backgroundColor:"white",
    color:theme.palette.primary.main,
    fontSize:"20px",
    marginRight:"10px",
    border:"2px solid",
    borderColor:theme.palette.primary.main,
    "&:hover":{
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
    },
}));

const DashboardLentCar = props => {
    const [currentTable, setCurrentTable] = useState("Action");
    const [adminTable, setAdminTable] = useState([]);

    useEffect(() => {
        let CatchError = false;
        const typeOfTable = props.history.location.pathname.split("/");
        if(typeOfTable[typeOfTable.length-1] === "actions"){
            setCurrentTable("Action");
        }else if(typeOfTable[typeOfTable.length-1] === "cars"){
            setCurrentTable("Car");
        }
        AdminLentCarService.getAllAdminLentCars().then(
            (res) => {
                if(CatchError){return;}
                if(res.data){
                    setAdminTable(res.data);
                }
            }
        ).catch( error => {
            console.log(error);
            setAdminTable([]);
        });
        return() => {
            CatchError = true;
        }
    }, []);

    const DataCalculate = (key) => {
        let array = [];
        if(adminTable){
            [...Array(adminTable.length)].map((value,index) => {
                array.splice(0,0,adminTable[index][key]);
            });
            return(array);
        }
        return([0,0,0,0,0])
    };

    const LabelMonth = () => {
        let array = [];
        if(adminTable){
            [...Array(adminTable.length)].map((value,index) => {
                let month = dayjs(adminTable[index].month).format("MMM");
                array.splice(0,0,month);
            });
            return(array);
        }
        return(["","","","",""])
    };

    const SubTitleForGraph = (key) => {
        if(adminTable){
            return adminTable[0][key];
        }
        return 0;
    };

    const onClickButton = (type,path) => {
        setCurrentTable(type);
        props.history.push(path);
    };

    return(
        <DivCoverReturn className="page">
            {adminTable.length > 0 && 
                <Grid container spacing={3}>
                    <Grid item md={3}>
                        <NumOfLentCar 
                            data={DataCalculate("item")} 
                            labelX={LabelMonth()} 
                            Title="Item" 
                            SubTitle={SubTitleForGraph("item")} 
                            color='#0083FF'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <NumOfLentCar 
                            data={DataCalculate("complete")} 
                            labelX={LabelMonth()} 
                            Title="Complete" 
                            SubTitle={SubTitleForGraph("complete")}
                            color='#00C40C'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <NumOfLentCar 
                            data={DataCalculate("newHost")} 
                            labelX={LabelMonth()} 
                            Title="New Host" 
                            SubTitle={SubTitleForGraph("newHost")} 
                            color='#FF8700'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <NumOfLentCar 
                            data={DataCalculate("newUser")} 
                            labelX={LabelMonth()} 
                            Title="New User" 
                            SubTitle={SubTitleForGraph("newUser")} 
                            color='#9C27B0'
                        />
                    </Grid>
                </Grid>
            }
            <NavButton>
                <ButtonStyled 
                    className={clsx({"CurrentTable":currentTable === "Action"})}
                    onClick={()=>onClickButton("Action","/admin/dasboard_lentcar/actions")}
                >การจัดการรายการ</ButtonStyled>
                <ButtonStyled 
                    className={clsx({"CurrentTable":currentTable === "Car"})}
                    onClick={()=>onClickButton("Car","/admin/dasboard_lentcar/cars")}
                >การจัดการรถ</ButtonStyled>
            </NavButton>
            <div style={{height:"500px",paddingBottom:"20px"}}>
                <Switch>
                    <Route exact path="/admin/dasboard_lentcar" component={()=> (<Redirect to="/admin/dasboard_lentcar/actions"/>)}/>
                    <Route exact path="/admin/dasboard_lentcar/actions" component={TableActions}/>
                    <Route exact path="/admin/dasboard_lentcar/cars" component={TableCars}/>
                </Switch>
            </div>
        </DivCoverReturn>
    );
};

export default DashboardLentCar;