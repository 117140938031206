import React from "react";

import { styled } from '@mui/styles';
import { Typography, Button } from '@mui/material';

const DivCoverReturn = styled("div")(({theme})=>({
  position: "fixed",
  marginLeft: "70%",
  height: "100%",
  width: "30%",
  padding: "0",
  [theme.breakpoints.down('sm')]:{
    width:"100%",
    height:"50%",
    position:"initial",
    marginLeft:"0",
    overflowY:"auto",
  },
}));

const Content = styled("div")(({theme})=>({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#03256C",
  width: "100%",
  height: "calc(100% - 64px)",
  "& .PlateText": {
    fontSize: "40px",
    marginTop: "20px",
  },
  "& .TypeText": {
    fontSize: "30px",
  },
  [theme.breakpoints.down('sm')]:{
    justifyContent: "flex-start",
    padding:"20px 0",
    height:"fit-content",
  },
}));

const ImageCar = styled("img")(({theme})=>({
  width: "95%",
  height: "180px",
  maxWidth: "240px",
  borderRadius: "40px",
  border: "5px solid white",
  objectFit:"fill",
  backgroundColor:"white",
  [theme.breakpoints.down("md")]:{
    width: "85%",
  },
}));

const Text = styled(Typography)({
  color: "white",
  fontSize: "20px",
});

const DetailText = styled("div")({
  marginTop: "20px",
  width:"85%",
});

const ConfirmButton = styled(Button)({
  width: "200px",
  height: "60px",
  backgroundColor: "rgb(58, 145, 38)",
  marginTop: "30px",
  color: "white",
  fontSize: "30px",
  "&:hover":{
    backgroundColor: "rgb(58, 145, 38)",
  },
});

const DetailBus = (props) => {

  return (
    <DivCoverReturn>
      <Content>
      {props.vehicle && (
        <>
          <ImageCar
            src={`${process.env.REACT_APP_URL}image/car/${props.vehicle.imagepath}`}
            alt={props.vehicle.model}
          />
          <Text className="PlateText">
            {props.vehicle.Plate_No}
          </Text>
          <Text className="TypeText">
            {props.vehicle.vehicleTypeNameTH}
          </Text>
          <DetailText>
            <Text>
              {props.vehicle.Vendor}
            </Text>
            <Text>
              {`วันที่เช่า : ${props.vehicle.RentDate}`}
            </Text>
            <Text>
              {`วันที่สิ้นสุด : ${props.vehicle.ExpireDate}`}
            </Text>
          </DetailText>
          <ConfirmButton 
            onClick={props.OnClickConfirmButton}
          >
            ยืนยันรถที่ใช้
          </ConfirmButton>
        </>
      )}
      </Content>
    </DivCoverReturn>
  );
};

export default DetailBus;
