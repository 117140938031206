import React from "react";

import { styled } from "@mui/styles";
import { 
  Avatar, Typography, useMediaQuery 
} from "@mui/material";

import StarIcon from "@mui/icons-material/Star";

const PartOfPassenger = styled("div")(({ theme }) => ({
  width: "calc(100% - 20px)",
  display: "flex",
  alignItems: "center",
  margin: "10px",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const PartOfAvatar = styled("div")(({ theme }) => ({
  flexBasis: "10%",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "80px",
  height: "80px",
  [theme.breakpoints.down("lg")]: {
    width: "60px",
    height: "60px",
  },
}));

const PartOfPoint = styled("div")({
  width: "100px",
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "20px",
  backgroundColor: "#D7F5F4",
  borderRadius: "50px",
});

const StarIconStyled = styled(StarIcon)({
  marginRight: "5px",
  color: "#E67E22",
  fontSize: "30px",
});

const TextPoint = styled(Typography)({
  color: "#E67E22",
  fontSize: "25px",
});

const PartOfDivTextAccordionDetails = styled("div")(({ theme }) => ({
  display: "flex",
  flexBasis: "80%",
  [theme.breakpoints.down("md")]: {
    display: "block",
    paddingLeft: "10px",
  },
  "& .TextSize": {
    fontSize: "18px",
  },
}));

const Name = styled("div")({
  display: "flex",
  flexBasis: "40%",
  alignItems: "center",
});

const TextFirstNameLastName = styled(Typography)(({ theme }) => ({
  flexBasis: "50%",
  [theme.breakpoints.down("md")]: {
    flexBasis: "25%",
  },
  [theme.breakpoints.down("sm")]: {
    flexBasis: "40%",
    fontWeight: "bold",
  },
}));

const Department = styled("div")(({ theme }) => ({
  display: "flex",
  flexBasis: "60%",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const TextPosition = styled(Typography)(({ theme }) => ({
  flexBasis: "50%",
  [theme.breakpoints.down("sm")]: {
    fontWeight: "initial",
  },
}));

const SubDepartment = styled("div")(({ theme }) => ({
  display: "flex",
  flexBasis: "50%",
}));

const TextDepartment = styled(Typography)(({ theme }) => ({
  flexBasis: "40%",
  [theme.breakpoints.down("sm")]: {
    fontWeight: "initial",
  },
}));

const TextTel = styled(Typography)(({ theme }) => ({
  flexBasis: "60%",
  [theme.breakpoints.down("sm")]: {
    fontWeight: "bold",
  },
}));

const DivPassenger = (props) => {
  const MobileResponse = useMediaQuery('(max-width:600px)');

  return (
    <PartOfPassenger>
      <PartOfAvatar>
        <AvatarStyled
          src={`${process.env.REACT_APP_URL}image/profile/${props.passenger.image}`}
          alt={props.passenger.image}
        />
        {MobileResponse && (
          <PartOfPoint>
            <StarIconStyled />
            <TextPoint>{props.passenger.pointFriendToFriend}</TextPoint>
          </PartOfPoint>
        )}
      </PartOfAvatar>
      <PartOfDivTextAccordionDetails>
        <Name>
          <TextFirstNameLastName className="TextSize">
            {props.passenger.firstname}
          </TextFirstNameLastName>
          <TextFirstNameLastName className="TextSize">
            {props.passenger.lastname}
          </TextFirstNameLastName>
        </Name>
        <Department>
          <TextPosition className="TextSize" noWrap>
            {props.passenger.position}
          </TextPosition>
          <SubDepartment>
            <TextDepartment className="TextSize">{props.passenger.department}</TextDepartment>
            <TextTel className="TextSize">{props.passenger.mobileNumber}</TextTel>
          </SubDepartment>
        </Department>
      </PartOfDivTextAccordionDetails>
      {!MobileResponse && (
        <PartOfPoint>
          <StarIconStyled />
          <TextPoint>{props.passenger.pointFriendToFriend}</TextPoint>
        </PartOfPoint>
      )}
    </PartOfPassenger>
  );
};

export default DivPassenger;
