import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/styles";
import { Grid, Card, Typography } from "@mui/material";
import { getUserProfile } from "../../../../actions/user";

const CardStyled = styled(Card)({
  borderRadius: 16,
  paddingBottom: "10px",
  boxShadow:
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
  margin: "10px",
  zIndex: "1",
  transition:
    "transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow:
      "rgb(145 158 171 / 60%) 0px 0px 2px 0px, rgb(145 158 171 / 60%) 0px 20px 40px -4px",
  },
});
const BackgroundImg = styled("img")({
  position: "absolute",
  width: "100%",
  height: "calc(100% - 64px)",
  opacity: "0.8",
  filter: "blur(5px)",
  zIndex: "0",
});
const Root = styled("div")(({ theme }) => ({
  position: "relative", // Ensure positioning context for the pseudo-element
  margin: "0 auto",
  padding: "16px 16px 16px 16px",
  minHeight: "calc(100vh - 64px)",
  boxSizing: "border-box",
  // paddingInline: "16vw",
  maxWidth: 1600,
  overflow: "hidden", // Ensure the pseudo-element does not overflow the container
  zIndex: 2,
  // Pseudo-element for the blurred background

  padding: "16px",
  paddingInline: "12vw",
  // marginTop: "40px",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: "url(./assets/background/homeUser.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    opacity: "0.9",
    filter: "blur(5px)",
    zIndex: 0, // Ensure it stays behind the content
  },

  // [theme.breakpoints.down("sm")]: {
  //   paddingInline: "0",
  //   marginLeft: "0",
  //   width: "100%",
  // },
}));

const Image = styled("img")({
  width: "100%",
  height: "80%",
});

const Text = styled(Typography)({
  fontSize: "20px",
  textAlign: "center",
});

const CarBooking = (props) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const listTypeOfCars = [
    "รถข้ามพื้นที่เฉพาะ",
    "รถข้ามพื้นที่ Pool",
    "รถในพื้นที่",
    "รถรับส่งของ",
    "รถรับส่งระหว่างจุด",
    "รถรับส่งกะ",
    "รถรับส่งเพื่อน",
    "รถรับส่งตัวอย่าง",
  ];
  const listNameOfPictures = [
    "CrossAreaCar",
    "CrossAreaCarPool",
    "InAreaCar",
    "DeliveryitemCar",
    "BetweenSiteCar",
    "transparent_ShiftCar(DoortoDoor)",
    "Friend2FriendCar",
    "sampleshuttle",
  ];
  const listPathName = [
    "crossAreaCar_Booking",
    "crossAreaCarPool_Booking",
    "inAreaCar_Booking",
    "deliveryItem_Car",
    "betweenSite_Car",
    "transparent_ShiftCar",
    "friend2friend_Car",
    "sampleshuttle",
  ];

  useEffect(() => {
    if (!userProfile) {
      dispatch(getUserProfile(currentUser?.idUser));
    }
  }, []);
  const OnClickCardHandler = (index) => {
    props.history.push({
      pathname: listPathName[index],
    });
  };

  return (
    <div className="page">
      <Root>
        <Grid container>
          {listTypeOfCars.map((nameOfType, index) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                zIndex={1}
                onClick={() => OnClickCardHandler(index)}
              >
                <CardStyled>
                  <Image
                    src={`./assets/Image/typeOfCars/${listNameOfPictures[index]}.png`}
                    alt={listNameOfPictures[index]}
                  />
                  <Text>{nameOfType}</Text>
                </CardStyled>
              </Grid>
            );
          })}
        </Grid>
      </Root>
    </div>
  );
};

export default CarBooking;
