import React, { useState, useEffect, Fragment } from 'react';
import dayjs from 'dayjs';

import { styled } from '@mui/styles';

import {
    Typography, Button, Grid
} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import CrossAreaCarPoolBookingPassengerService from "../../../../../services/crossAreaCarPoolBookingPassenger.service";

require('dayjs/locale/th');
dayjs.locale('th');

const DivCoverReturn = styled('div')(({theme})=>({
    width:"100%",
    marginTop:"20px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    paddingTop:"64px",
}));

const DivTitle = styled("div")(({theme})=>({
    width:"90%", 
    maxWidth:"1100px", 
    display:"flex", 
    justifyContent:"center", 
    position:"relative",
    [theme.breakpoints.down('sm')]:{
        marginTop:"50px"
    }
}));

const BackButton = styled(Button)(({theme})=>({
    position:"absolute",
    color:"black",
    fontSize:"20px",
    left:"-8px",
    [theme.breakpoints.down('sm')]:{
        top:"-50px",
    }
}));

const TimeButton = styled(Button)(({theme})=>({
    backgroundColor:theme.palette.warning.main,
    position:"absolute",
    color:theme.palette.warning.contrastText,
    fontSize:"20px",
    right:"0px",
    paddingLeft:"20px",
    paddingRight:"20px",
    "&:hover":{
        backgroundColor:theme.palette.warning.main,
    },
    [theme.breakpoints.down('sm')]:{
        top:"-50px",
    }
}));

const Title = styled(Typography)(({theme})=>({
    textAlign:"center",
    fontSize:"30px"
}));

const DivContent = styled("div")(({theme})=>({
    width:"90%",
    maxWidth:"1100px",
    marginBottom:"50px"
}));

const DivTopic = styled("div")(({theme})=>({
    display:"flex",
    borderBottom:"1px solid #1565c0",
    marginTop:"24px"
}));

const TextMainTopic = styled(Typography)(({theme})=>({
    fontSize:"20px",
    fontWeight:"bold",
    color:theme.palette.primary.dark
}));

const TextTopic = styled(Typography)(({theme})=>({
    fontSize:"18px",
    fontWeight:"bold",
}));

const TextData = styled(Typography)(({theme})=>({
    fontSize:"18px",
    marginLeft:"10px",
    color:theme.palette.primary.main
}));

const DivTopicAndData = styled("div")(({theme})=>({
    display:"flex"
}));

const GridContainer = styled(Grid)(({theme})=>({
    width:"100%",
    marginTop:"0px"
}));

const DivTypeOfFlight = styled("div")(({theme})=>({
    width:"calc(100% - 68px)",
    marginTop:"24px"
}));

const DivTextFlight = styled("div")(({theme})=>({
    width:"100%",
    borderBottom:"1px solid #DCDCDC",
    marginTop:"24px"
}));

const DivTextPassenger = styled("div")(({theme})=>({
    width:"100%",
    border:"1px solid",
    borderLeft:"0",
    borderRight:"0",
    borderTop:"0",
    borderColor:theme.palette.primary.light,
    marginTop:"24px"
}));

const TextPassenger = styled(Typography)(({theme})=>({
    fontSize:"18px",
    fontWeight:"bold",
    color:theme.palette.primary.light
}));

const DetailCrossAreaCarPool = props => {
    const [booking, setBooking] = useState(null);
    const [passenger, setPassenger] = useState(null);

    useEffect(() => {
        if(props.location.state === undefined){
            props.history.push("/myListOfRequest");
        }else{
            setBooking(props.location.state.booking);
            CrossAreaCarPoolBookingPassengerService.getCrossAreaCarPoolBookingPassengerByIdBooking(props.location.state.booking.id).then(
                (res) => {
                    if(res.data){
                        setPassenger(res.data);
                    }
                }
            ).catch( (error) => {
                console.log(error);
            });
        }
    }, [props.location.state])

    return(
        <DivCoverReturn>
            <DivTitle>
                <BackButton onClick={()=>props.history.goBack()}>
                    <ArrowBackIosNewIcon /> Back
                </BackButton>
                <Title>จองรถข้ามพื้นที่ Pool</Title>
                <TimeButton>
                    เวลารถมารับ
                </TimeButton>
            </DivTitle>
            {booking && passenger &&
                <DivContent>
                    <DivTopic>
                        <TextMainTopic>ผู้จอง</TextMainTopic>
                    </DivTopic>
                    <GridContainer container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <DivTopicAndData>
                                <TextTopic>ชื่อ :</TextTopic>
                                <TextData>{booking.name}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DivTopicAndData>
                                <TextTopic>เบอร์โทรศัพท์ :</TextTopic>
                                <TextData>{booking.telephoneMobile}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DivTopicAndData>
                                <TextTopic>Email :</TextTopic>
                                <TextData>{booking.email}</TextData>
                            </DivTopicAndData>
                        </Grid>
                    </GridContainer>
                    <DivTopic>
                        <TextMainTopic>รายละเอียดการจอง</TextMainTopic>
                    </DivTopic>
                    <DivTypeOfFlight>
                        <DivTopicAndData>
                            <TextTopic>ประเภทการเดินทาง :</TextTopic>
                            <TextData>{booking.flight === "oneWay" ? "เที่ยวเดียว" : "ไป-กลับ"}</TextData>
                        </DivTopicAndData>
                    </DivTypeOfFlight>
                    {booking.flight === "twoWay" &&
                        <DivTextFlight>
                            <TextTopic>ขาไป</TextTopic>
                        </DivTextFlight>
                    }
                    <GridContainer container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <DivTopicAndData>
                                <TextTopic>ต้นทาง :</TextTopic>
                                <TextData>{booking.fromPlace}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DivTopicAndData>
                                <TextTopic>ปลายทาง :</TextTopic>
                                <TextData>{booking.toPlace}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DivTopicAndData>
                                <TextTopic>จำนวนผู้โดยสาร :</TextTopic>
                                <TextData>{booking.numberOfPassenger}</TextData>
                            </DivTopicAndData>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DivTopicAndData>
                                <TextTopic>วันที่เดินทาง :</TextTopic>
                                <TextData>{dayjs(booking.departureDate).format('DD MMMM YYYY')}</TextData>
                            </DivTopicAndData>
                        </Grid>
                    </GridContainer>
                    {booking.flight === "twoWay" &&
                    <>
                        <DivTextFlight>
                            <TextTopic>ขากลับ</TextTopic>
                        </DivTextFlight>
                        <GridContainer container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <DivTopicAndData>
                                    <TextTopic>ต้นทาง :</TextTopic>
                                    <TextData>{booking.fromPlaceReturn}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DivTopicAndData>
                                    <TextTopic>ปลายทาง :</TextTopic>
                                    <TextData>{booking.toPlaceReturn}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DivTopicAndData>
                                    <TextTopic>จำนวนผู้โดยสาร :</TextTopic>
                                    <TextData>{booking.numberOfPassengerReturn}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DivTopicAndData>
                                    <TextTopic>วันที่เดินทาง :</TextTopic>
                                    <TextData>{dayjs(booking.returnDate).format('DD MMMM YYYY')}</TextData>
                                </DivTopicAndData>
                            </Grid>
                        </GridContainer>
                    </>
                    }
                    <DivTopic>
                        <TextMainTopic>รายละเอียดผู้โดยสาร</TextMainTopic>
                    </DivTopic>
                    {passenger.map( (passen, index) => (
                    <Fragment key={passen.id}>
                        <DivTextPassenger>
                            <TextPassenger>
                                ผู้โดยสารคนที่ {index+1}
                            </TextPassenger>
                        </DivTextPassenger>
                        <GridContainer container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <DivTopicAndData>
                                    <TextTopic>ชื่อ :</TextTopic>
                                    <TextData>{passen.name}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DivTopicAndData>
                                    <TextTopic>เบอร์โทรศัพท์ :</TextTopic>
                                    <TextData>{passen.telephoneMobile}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DivTopicAndData>
                                    <TextTopic>Email :</TextTopic>
                                    <TextData>{passen.email}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DivTopicAndData>
                                    <TextTopic>บริษัท :</TextTopic>
                                    <TextData>{passen.company}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DivTopicAndData>
                                    <TextTopic>Cost Center :</TextTopic>
                                    <TextData>{passen.costCenter}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DivTopicAndData>
                                    <TextTopic>Cost Element :</TextTopic>
                                    <TextData>{passen.costElement}</TextData>
                                </DivTopicAndData>
                            </Grid>
                        </GridContainer>
                        {booking.flight === "twoWay" && 
                            <DivTextFlight>
                                <TextTopic>ขาไป</TextTopic>
                            </DivTextFlight>
                        }
                        <GridContainer container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <DivTopicAndData>
                                    <TextTopic>จุดรับ :</TextTopic>
                                    <TextData>{passen.fromPlace}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DivTopicAndData>
                                    <TextTopic>จุดส่ง :</TextTopic>
                                    <TextData>{passen.toPlace}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DivTopicAndData>
                                    <TextTopic>รอบรถ :</TextTopic>
                                    <TextData>{dayjs(passen.roundTime).format("HH.mm")}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DivTopicAndData>
                                    <TextTopic>เวลาที่ต้องถึงจุดหมาย :</TextTopic>
                                    <TextData>{dayjs(passen.endTime).format("HH.mm")}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DivTopicAndData>
                                    <TextTopic>วัตถุประสงค์ :</TextTopic>
                                    <TextData>{passen.purpose}</TextData>
                                </DivTopicAndData>
                            </Grid>
                            <Grid item xs={12}>
                                <DivTopicAndData>
                                    <TextTopic>Note :</TextTopic>
                                    <TextData>{passen.noteDeparture?passen.noteDeparture : "-"}</TextData>
                                </DivTopicAndData>
                            </Grid>
                        </GridContainer>
                        {booking.flight === "twoWay" && 
                        <>
                            <DivTextFlight>
                                <TextTopic>ขากลับ</TextTopic>
                            </DivTextFlight>
                            <GridContainer container spacing={3} style={{marginBottom:"20px"}}>
                                <Grid item xs={12} sm={6}>
                                    <DivTopicAndData>
                                        <TextTopic>จุดรับ :</TextTopic>
                                        <TextData>{passen.fromPlace}</TextData>
                                    </DivTopicAndData>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DivTopicAndData>
                                        <TextTopic>จุดส่ง :</TextTopic>
                                        <TextData>{passen.toPlace}</TextData>
                                    </DivTopicAndData>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <DivTopicAndData>
                                        <TextTopic>รอบรถ :</TextTopic>
                                        <TextData>{dayjs(passen.roundTime).format("HH.mm")}</TextData>
                                    </DivTopicAndData>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <DivTopicAndData>
                                        <TextTopic>เวลาที่ต้องถึงจุดหมาย :</TextTopic>
                                        <TextData>{dayjs(passen.endTime).format("HH.mm")}</TextData>
                                    </DivTopicAndData>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <DivTopicAndData>
                                        <TextTopic>วัตถุประสงค์ :</TextTopic>
                                        <TextData>{passen.purpose}</TextData>
                                    </DivTopicAndData>
                                </Grid>
                                <Grid item xs={12}>
                                    <DivTopicAndData>
                                        <TextTopic>Note :</TextTopic>
                                        <TextData>{passen.noteDeparture?passen.noteDeparture : "-"}</TextData>
                                    </DivTopicAndData>
                                </Grid>
                            </GridContainer>
                        </>
                        }
                    </Fragment>
                    ))}
                </DivContent>
            }  
        </DivCoverReturn>
    );
};

export default DetailCrossAreaCarPool;