import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/styles";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import CardButton from "../shared/cardButton";

import DriverRouteDayService from "../../../services/driverRouteDay.service";
import { getDriverRouteDayByIdDriver } from "../../../actions/driverRouteDay";
import SiteService from "../../../services/site.service";

const DivCoverReturn = styled("div")({
  width: "100%",
  height: "calc(100% - 64px)",
  backgroundColor: "white",
  display: "flex",
  position: "relative",
  justifyContent: "center",
  marginLeft: "0px",
});

const BackgroundImg = styled("img")({
  position: "absolute",
  width: "100%",
  height: "calc(100% - 64px)",
  opacity: "0.3",
  zIndex: "0",
});

const CheckCircleIconStyled = styled(CheckCircleIcon)({
  fontSize: "100px",
  color: "white",
});

const Content = styled("div")({
  width: "100%",
  height: "100%",
  zIndex: "1",
  display: "flex",
  justifyContent: "center",
});

const UseGeoLocation = () => {
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
  });

  const onSuccess = (location) => {
    console.log("Success Map");
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
  };

  const onError = (error) => {
    console.log("Error Map");
    console.log(error);
    setLocation({
      loaded: true,
      coordinates: { lat: "", lng: "" },
      error: {
        code: error.code,
        message: error.message,
      },
    });
  };

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      });
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  return location;
};

const HomeDriver = (props) => {
  const dispatch = useDispatch();
  const state = UseGeoLocation();
  const [count, setCount] = useState(0);

  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: Driver } = useSelector((state) => state.driverRouteDay);

  useEffect(() => {
    DriverRouteDayService.getDriverRouteDayByIdDriver(
      currentUser.idUser,
      "2022-08-27"
    ).then((res) => {
      console.log("res.data : ", res.data);
      if (!res.data.bedTime) {
        props.history.push("authencar");
      } else {
        dispatch(getDriverRouteDayByIdDriver(currentUser.idUser, "2022-08-27"));
        // dispatch(getDriverRouteDayByIdDriver(currentUser.id,"2022-05-30"));
      }
    });
    const interval = setInterval(() => {
      setCount(count + 1);
    }, 180000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [count]);

  SiteService.addLocationDriver({
    idDriver: currentUser.idUser,
    Lat: state.coordinates.lat,
    Lng: state.coordinates.lng,
  });

  const dataCard = [
    {
      Icon: <CheckCircleIconStyled />,
      Name: "PLAN",
      path: "/plan",
    },
    {
      Image: "./assets/Image/Icon/map.png",
      Name: "MAP",
      path: "/map",
    },
    // {
    //   Image: "./assets/Image/Icon/site.png",
    //   Name: "รับส่งระหว่าง Site",
    //   path: "/map-between-site",
    // },
    {
      Image: "./assets/Image/Icon/alert.png",
      Name: "แจ้งเหตุฉุกเฉิน",
      path: "/emergency",
    },
    {
      Image: "./assets/Image/Icon/repeat.png",
      Name: "รายการจองของฉัน",
      path: "/myListForDriver/cross_area_car_booking",
    },
  ];

  const onClickCard = (path) => {
    props.history.push(path);
  };

  return (
    <DivCoverReturn className="page">
      <BackgroundImg
        src="./assets/background/carpark_home.jpg"
        alt="backgroundImage"
      />
      <Content>
        <CardButton dataCard={dataCard} onClickCard={onClickCard} />
      </Content>
    </DivCoverReturn>
  );
};

export default HomeDriver;
