import React, { Fragment, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  TextField,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  FormLabel,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Logo from "./assets/unihr_logo.png";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { red } from "@mui/material/colors";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ClearIcon from "@mui/icons-material/Clear";
import TranslateIcon from "@mui/icons-material/Translate";
import CircularProgress from "@mui/material/CircularProgress";

import { login } from "../../actions/auth";
import { Link, useLocation } from "react-router-dom";
import { useHistory, Redirect } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  rootCard: {
    width: 345,
    marginTop: 100,
  },
  media: {
    height: 155,
    padding: 20,
    paddingBottom: 0,
    margin: 10,
  },
  version: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  showPassword: {
    position: "absolute",
    justifyContent: "center",
  },
});

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Contain at least 8 characters";
  }

  return errors;
};

const LoginButton = withStyles((theme) => ({
  root: {
    marginTop: 16,
    borderColor: theme.palette.primary.main,
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    "&:hover": {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))(Button);

const PasswordField = ({ isSubmitting, values, handleChange, error }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Fragment>
      <TextField
        style={{ width: "100%" }}
        disabled={isSubmitting}
        name="password"
        id="password"
        type={showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              size="large"
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      ></TextField>
    </Fragment>
  );
};

const StyledFormLabel = styled(FormLabel)({
  fontWeight: 600,
  fontSize: 14,
  color: "#637381",
  marginTop: 8,
  marginBottom: 2,
});
const LoginPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState(false);
  const location = useLocation();
  const linkState = location.state;

  const allDataOnState = useSelector((state) => state);
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    //anyNameFunction();
    if (currentUser) {
      if (currentUser.roles.includes("ROLE_DRIVER")) {
        props.history.push("/driver");
      } else {
        props.history.push("/carbooking");
      }
      window.location.reload();
    }
  }, [allDataOnState]);

  const showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    handleClickShowPassword,
    handleMouseDownPassword,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <StyledFormLabel component="legend">Username</StyledFormLabel>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="username"
          name="username"
          label="Username"
          onChange={handleChange}
          value={values.username}
          autoFocus
          error={errors.username}
          disabled={isSubmitting}
        ></TextField>

        {errors.username && (
          <Typography style={{ color: "#f44336", fontSize: "12px" }}>
            {errors.username}
          </Typography>
        )}
        <StyledFormLabel component="legend">Password</StyledFormLabel>
        <PasswordField
          isSubmitting={isSubmitting}
          values={values}
          handleChange={handleChange}
          name="password"
          error={errors.password}
        ></PasswordField>
        {errors.password && (
          <Typography style={{ color: "#f44336", fontSize: "12px" }}>
            {errors.password}
          </Typography>
        )}
        <LoginButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={isSubmitting}
          className={classes.submit}
        >
          {isSubmitting ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CircularProgress
                color="inherit"
                size={20}
                sx={{ marginRight: 2 }}
              />
              {`Just a moment...`}
            </Box>
          ) : (
            `Login`
          )}
        </LoginButton>

        <Typography variant="body2" noWrap className={classes.version}>
          Version {process.env.REACT_APP_VERSION}
        </Typography>
      </form>
    );
  };

  return (
    <div className={classes.root}>
      <Card className={classes.rootCard}>
        <CardMedia
          style={{ backgroundSize: "contain" }}
          className={classes.media}
          image={Logo}
          title="Contemplative Reptile"
        />
        <CardContent>
          {/* HOC */}
          <Formik
            onSubmit={(values, { setSubmitting }) => {
              dispatch(login(values.username, values.password));
            }}
            initialValues={{ username: "", password: "", showPassword: false }}
            validate={validate}
          >
            {(props) => showForm(props)}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoginPage;
