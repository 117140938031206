import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  Typography,
  Avatar,
  IconButton,
  Fade,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { getAllEmployees } from "../../../../actions/employee";
import DetailEmployeeComponent from "./detailEmployeeComponent";

const useStyles = makeStyles((theme) => ({
  partOfTopNavButton: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  partOfTitle: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
  },
  ImageTopic: {
    width: "60px",
    height: "60px",
    marginRight: "20px",
  },
  Title: {
    fontSize: "50px",
  },
  TopNavButton: {
    height: "50px",
    display: "flex",
    justifyContent: "flex-end",
    padding: " 15px 50px",
  },
  Button: {
    borderRadius: "40px",
  },
  AddEmployeeButton: {
    width: "210px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    fontSize: "20px",
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  DeleteDriverButton: {
    width: "150px",
    fontSize: "20px",
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
    marginRight: "20px",
    ["&:hover"]: {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  Icon: {
    marginRight: "10px",
  },
  partOfDriver: {
    width: "100%",
    display: "flex",
    height: "calc(100% - 80px)",
  },
  partOfTableDriver: {
    width: "70%",
    height: "100%",
    border: "1px solid #CCD1D1",
  },
  TableContainer: {
    height: "100%",
  },
  TableHeadCell: {
    fontSize: "20px",
  },
  TableCell: {
    borderBottom: "1px solid #CCD1D1",
  },
  TableCellEditDelete: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  TableCellName: {
    width: "25%",
  },
  TableCellDepartment: {
    width: "20%",
  },
  TableCellWorkingLocation: {
    width: "20%",
  },
  TableRow: {
    ["&:hover"]: {
      cursor: "pointer",
    },
  },
  Avatar: {
    width: "50px",
    height: "50px",
  },
  Text: {
    fontSize: "18px",
  },
  editIcon: {
    color: theme.palette.primary.dark,
  },
  TextStatusSuccess: {
    fontWeight: "bold",
    color: "green",
  },
  TextStatusWaiting: {
    fontWeight: "bold",
    color: "#DC7201",
  },
  Button: {
    color: "white",
    width: "100px",
  },
  ButtonActivate: {
    backgroundColor: "green",
    ["&:hover"]: {
      backgroundColor: "darkgreen",
    },
  },
  ButtonInactivate: {
    backgroundColor: "red",
    ["&:hover"]: {
      backgroundColor: "darkred",
    },
  },
}));

const columnHead = [
  {
    id: "picture",
    label: "",
    padding: "checkbox",
    align: "left",
    minWidth: "50px",
  },
  {
    id: "name",
    label: "ชื่อ-สกุล",
    padding: "normal",
    align: "left",
    minWidth: "150px",
  },
  {
    id: "department",
    label: "แผนก",
    padding: "normal",
    align: "left",
    minWidth: "60px",
  },
  {
    id: "company",
    label: "บริษัท",
    padding: "normal",
    align: "left",
    minWidth: "200px",
  },
  {
    id: "status",
    label: "สถานะ",
    padding: "normal",
    align: "center",
    minWidth: "60px",
  },
  {
    id: "activate",
    label: "",
    padding: "normal",
    align: "center",
    minWidth: "80px",
  },
  { id: "edit", label: "", padding: "none", align: "left", minWidth: "50px" },
];

const EmployeePage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const AllEmployees = useSelector((state) => state.employees);
  const [idUserClicked, setIdUserClicked] = useState(0);
  const [selectDriver, setSelectDriver] = useState([]);
  const [actionInPage, setActionInPage] = useState(false);

  useEffect(() => {
    if (!AllEmployees.result) {
      dispatch(getAllEmployees());
    }
  }, [actionInPage]);

  const handleClick = (event, id) => {
    const selectedIndex = selectDriver.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectDriver, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectDriver.slice(1));
    } else if (selectedIndex === selectDriver.length - 1) {
      newSelected = newSelected.concat(selectDriver.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectDriver.slice(0, selectedIndex),
        selectDriver.slice(selectedIndex + 1)
      );
    }

    setSelectDriver(newSelected);
  };

  const handleClickAddButton = () => {
    props.history.push("/admin/employee/add_user");
  };

  const handleClickEditButton = (employee) => {
    console.log("employee : ", employee);
    props.history.push("/admin/edit_profile", { employee: employee });
  };

  const IsSelected = (id) => selectDriver.indexOf(id) !== -1;

  return (
    <div className={`page`}>
      <div className={`${classes.partOfTopNavButton}`}>
        <div className={`${classes.partOfTitle}`}>
          <img
            className={`${classes.ImageTopic}`}
            src="../../assets/Image/Icon/teamworkTopic.png"
            alt="employee"
          />
          <Typography className={`${classes.Title}`}>พนักงาน</Typography>
        </div>
        <div className={`${classes.TopNavButton}`}>
          <Fade in={selectDriver.length > 0}>
            <Button
              className={`${classes.DeleteDriverButton} ${classes.Button}`}
            >
              <DeleteIcon className={`${classes.Icon}`} />
              Delete
            </Button>
          </Fade>
          <Button
            className={`${classes.AddEmployeeButton} ${classes.Button}`}
            onClick={handleClickAddButton}
          >
            <AddIcon className={`${classes.Icon}`} />
            Add Employee
          </Button>
        </div>
      </div>
      <div className={`${classes.partOfDriver}`}>
        <div className={`${classes.partOfTableDriver}`}>
          <TableContainer className={`${classes.TableContainer}`}>
            <Table stickyHeader aria-label="List Driver">
              <TableHead>
                <TableRow>
                  <TableCell
                    key={"checkbox"}
                    align="center"
                    padding="checkbox"
                    className={`${classes.TableCell}`}
                  ></TableCell>
                  {columnHead.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.padding}
                      style={{ minWidth: headCell.minWidth }}
                      className={clsx(
                        classes.TableHeadCell,
                        classes.TableCell,
                        {
                          [classes.TableCellName]: headCell.id === "name",
                          [classes.TableCellCompany]: headCell.id === "company",
                        }
                      )}
                    >
                      <Typography className={`${classes.TableHeadCell}`}>
                        {headCell.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {AllEmployees.result &&
                  AllEmployees.result
                    .filter((employee) => employee.departmentName !== "")
                    .map((employee, index) => (
                      <TableRow
                        hover
                        key={employee.idEmployees}
                        className={`${classes.TableRow}`}
                        onClick={() => {
                          setIdUserClicked(index + 1);
                        }}
                      >
                        <TableCell
                          padding="checkbox"
                          className={`${classes.TableCell}`}
                        >
                          <Checkbox
                            checked={IsSelected(employee.idEmployees)}
                            onClick={(event) => {
                              handleClick(event, employee.idEmployees);
                            }}
                          />
                        </TableCell>
                        <TableCell
                          padding="normal"
                          className={`${classes.TableCell}`}
                        >
                          <Avatar
                            className={`${classes.Avatar}`}
                            src={`${process.env.REACT_APP_URL}image/profile/${employee.image}`}
                            alt={employee.image}
                          />
                        </TableCell>
                        <TableCell
                          className={`${classes.TableCell} ${classes.TableCellName}`}
                        >
                          <Typography className={`${classes.Text}`}>
                            {`${employee.firstname_TH} ${employee.lastname_TH}`}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={`${classes.TableCell} ${classes.TableCellDepartment}`}
                        >
                          <Typography className={`${classes.Text}`}>
                            {employee.departmentName}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={`${classes.TableCell} ${classes.TableCellWorkingLocation}`}
                        >
                          <Typography className={`${classes.Text}`}>
                            {employee.workingLocation}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.TableCell}`}
                        >
                          <Typography
                            className={clsx(classes.Text, {
                              [classes.TextStatusSuccess]:
                                employee.isActive === 1,
                              [classes.TextStatusWaiting]:
                                employee.isActive !== 1,
                            })}
                          >
                            {employee.isActive === 1 ? "success" : "waiting"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.TableCell}`}
                        >
                          <Button
                            className={clsx(classes.Button, {
                              [classes.ButtonActivate]: employee.isActive === 1,
                              [classes.ButtonInactivate]:
                                employee.isActive !== 1,
                            })}
                          >
                            {employee.isActive === 1
                              ? "Activate"
                              : "Inactivate"}
                          </Button>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.TableCell} ${classes.TableCellEditDelete}`}
                        >
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              handleClickEditButton(employee);
                            }}
                            size="large"
                          >
                            <EditIcon className={`${classes.editIcon}`} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <DetailEmployeeComponent idUserClicked={idUserClicked} />
      </div>
    </div>
  );
};

export default EmployeePage;
