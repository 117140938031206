import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import makeStyles from "@mui/styles/makeStyles";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Typography,
  Button,
  Modal,
  Paper,
  Grid,
  Divider,
  TextField,
} from "@mui/material";
import Rating from "@mui/material/Rating";

import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import PhoneIcon from "@mui/icons-material/Phone";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ScheduleIcon from "@mui/icons-material/Schedule";
import FlagIcon from "@mui/icons-material/Flag";
import RoomIcon from "@mui/icons-material/Room";

import registerOfDriverServices from "../../../../../services/registerOfDriver.service";

require("dayjs/locale/th");
dayjs.locale("th");

const columns = [
  { id: "img", label: "", align: "center", minWidth: 50 },
  { id: "name", label: "ชื่อ-สกุล", align: "left", minWidth: 260 },
  { id: "department", label: "หน่วยงาน", align: "left", minWidth: 140 },
  { id: "dateStart", label: "วันเริ่มต้น", align: "center", minWidth: 120 },
  { id: "dateEnd", label: "วันสิ้นสุด", align: "center", minWidth: 120 },
  { id: "numOfSeats", label: "รับได้", align: "center", minWidth: 50 },
  { id: "numOfPassenger", label: "คนขอ", align: "center", minWidth: 50 },
  { id: "detailButton", label: "", align: "center", minWidth: 100 },
];

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    height: "calc(100% - 30px)",
    paddingTop: "20px",
  },
  partOfFilter: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  DatePicker: {
    width: "200px",
    marginRight: "15px",
    [theme.breakpoints.down("md")]: {
      width: "170px",
      marginRight: "0px",
    },
  },
  Content: {
    height: "calc(100% - 80px)",
  },
  TableContainer: {
    borderTop: "1px solid rgb(224, 224, 224)",
    height: "100%",
  },
  Avatar: {
    width: "60px",
    height: "60px",
  },
  Text: {
    fontSize: "20px",
  },
  DetailButton: {
    width: "100px",
    height: "40px",
    color: "white",
    backgroundColor: theme.palette.grey[500],
    ["&:hover"]: {
      backgroundColor: theme.palette.grey[700],
    },
  },
  Modal: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Paper: {
    width: "80%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      overflow: "auto",
      height: "100vh",
    },
  },
  partOfTopOfPaper: {
    width: "95%",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  AvatarModal: {
    height: "160px",
    width: "160px",
    marginRight: "40px",
    marginLeft: "30px",
  },
  partOfDetailDriver: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  GridDetailDriver: {
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  TextName: {
    fontSize: "40px",
    marginBottom: "10px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  Icon: {
    fontSize: "30px",
    marginRight: "10px",
  },
  IconAndText: {
    display: "flex",
    alignItems: "center",
  },
  TextPoint: {
    fontSize: "25px",
  },
  Divider: {
    height: "1px",
    width: "95%",
    marginTop: "30px",
    marginBottom: "30px",
    backgroundColor: "#DDDDDD",
  },
  partOfDetailOfFlight: {
    width: "90%",
  },
  TextDetailOfFlight: {
    fontSize: "30px",
    color: "#0F19B8",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  partOfDetail: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  NoDetail: {
    color: theme.palette.grey[600],
  },
  NavButtonModal: {
    width: "100%",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "20px",
    },
  },
  CloseButton: {
    width: "70%",
    fontSize: "20px",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.grey[500],
    ["&:hover"]: {
      backgroundColor: theme.palette.grey[700],
    },
  },
}));

const DetailDriverPeer2Peer = (props) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [AllRegisterDriver, setAllRegisterDriver] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [InforRegister, setInforRegister] = useState(null);

  useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    registerOfDriverServices
      .getRegistersOfDriverByDuration(startDate, endDate)
      .then((res) => {
        console.log(res.data.result);
        setAllRegisterDriver(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [startDate, endDate]);

  const convertDate = (lawDateStart, lawDateEnd) => {
    let checkOneDay = () => {
      if (lawDateEnd !== "") {
        return false;
      } else {
        return true;
      }
    };

    return (
      <Typography
        className={`${classes.TextDetailOfFlight}`}
      >{`วันที่ : ${dayjs(lawDateStart).format("D MMM YYYY")}${
        !checkOneDay() && ` - ${dayjs(lawDateEnd).format("D MMM YYYY")}`
      }`}</Typography>
    );
  };

  return (
    <div className={`${classes.DivCoverReturn}`}>
      <div className={`${classes.Content}`}>
        <TableContainer className={`${classes.TableContainer}`}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      borderTop: "1px solid rgb(224, 224, 224)",
                    }}
                  >
                    <Typography className={`${classes.Text}`}>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {AllRegisterDriver.length > 0 &&
                AllRegisterDriver.map((register) => (
                  <TableRow hover key={register.id}>
                    <TableCell align="center" padding="normal">
                      <Avatar
                        className={`${classes.Avatar}`}
                        src={`${process.env.REACT_APP_URL}image/profile/${register.driver.image}`}
                        alt={register.driver.image}
                      />
                    </TableCell>
                    <TableCell padding="normal">
                      <Typography className={`${classes.Text}`}>
                        {`${register.driver.firstname} ${register.driver.lastname}`}
                      </Typography>
                    </TableCell>
                    <TableCell padding="normal">
                      <Typography className={`${classes.Text}`}>
                        {register.driver.department}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" padding="normal">
                      <Typography className={`${classes.Text}`}>
                        {dayjs(register.startDate).format("D MMM YYYY")}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" padding="normal">
                      <Typography className={`${classes.Text}`}>
                        {dayjs(register.endDate).format("D MMM YYYY")}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" padding="normal">
                      <Typography className={`${classes.Text}`}>
                        {`${register.numberOfPassenger} คน`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" padding="normal">
                      <Typography className={`${classes.Text}`}>
                        {`${register.requestPassenger} คน`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" padding="normal">
                      <Button
                        className={`${classes.DetailButton} ${classes.Text}`}
                        onClick={() => {
                          setInforRegister(register);
                          setOpenModal(true);
                        }}
                      >
                        Detail
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {InforRegister && (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="DetailRegister"
          aria-describedby="DetailOfRegister"
          className={`${classes.Modal}`}
        >
          <Paper className={`${classes.Paper}`}>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "30px",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              รายละเอียด
            </Typography>
            <div className={`${classes.partOfTopOfPaper}`}>
              <Avatar
                className={`${classes.AvatarModal}`}
                src={`${process.env.REACT_APP_URL}image/profile/${InforRegister.driver.image}`}
                alt={InforRegister.driver.image}
              />
              <div className={`${classes.partOfDetailDriver}`}>
                <Typography className={`${classes.TextName} ${classes.Text}`}>
                  {`${InforRegister.driver.firstname} ${InforRegister.driver.lastname}`}
                </Typography>
                <Grid
                  container
                  spacing={1}
                  className={`${classes.GridDetailDriver}`}
                >
                  <Grid item xs={12} lg={6}>
                    <div className={`${classes.IconAndText}`}>
                      <PersonIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`} noWrap>
                        {InforRegister.driver.position}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div className={`${classes.IconAndText}`}>
                      <WorkIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`} noWrap>
                        {InforRegister.driver.department}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={`${classes.IconAndText}`}>
                      <PhoneIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`} noWrap>
                        {`Tel : ${InforRegister.driver.mobileNumber}`}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={`${classes.IconAndText}`}>
                      <DriveEtaIcon className={`${classes.Icon}`} />
                      <Typography className={`${classes.Text}`} noWrap>
                        {`จำนวนรับ : 23`}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} className={`${classes.IconAndText}`}>
                    <Rating
                      className={`${classes.Rating}`}
                      name="read-only"
                      value={InforRegister.driver.pointFriendToFriend}
                      precision={0.5}
                      size="large"
                      readOnly
                    />
                    <Typography
                      className={`${classes.Text} ${classes.TextPoint}`}
                      noWrap
                    >
                      {InforRegister.driver.pointFriendToFriend}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Divider className={classes.Divider} />
            <Grid container className={`${classes.partOfDetailOfFlight}`}>
              <Grid
                item
                xs={12}
                md={8}
                lg={InforRegister.endDate !== "" ? 7 : 6}
              >
                <div className={`${classes.IconAndText}`}>
                  <CalendarTodayIcon className={`${classes.Icon}`} />
                  {convertDate(InforRegister.startDate, InforRegister.endDate)}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={InforRegister.endDate !== "" ? 5 : 6}
              >
                <div className={`${classes.IconAndText}`}>
                  <ScheduleIcon className={`${classes.Icon}`} />
                  <Typography className={`${classes.TextDetailOfFlight}`}>
                    {`เวลา : ${dayjs(InforRegister.time).format("H.mm")} น.`}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={`${classes.IconAndText}`}>
                  <RoomIcon className={`${classes.Icon}`} />
                  <Typography
                    className={`${classes.TextDetailOfFlight}`}
                    noWrap
                  >
                    {`จุดรับ : ${InforRegister.fromPlace}`}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={`${classes.IconAndText}`}>
                  <FlagIcon className={`${classes.Icon}`} />
                  <Typography
                    className={`${classes.TextDetailOfFlight}`}
                    noWrap
                  >
                    {`จุดส่ง : ${InforRegister.toPlace}`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Divider className={classes.Divider} />
            <div className={`${classes.partOfDetail}`}>
              <div
                className={`${classes.IconAndText} ${classes.partOfTopicDetail}`}
              >
                <span className={`${classes.Icon}`}>
                  <i className="far fa-clipboard-list"></i>
                </span>
                <Typography className={`${classes.Text}`}>
                  {`รายละเอียด : `}
                </Typography>
              </div>
              <Typography className={`${classes.Text} ${classes.TextDetail}`}>
                {InforRegister.detail === "" ? (
                  <span className={`${classes.NoDetail}`}>ไม่มีรายละเอียด</span>
                ) : (
                  InforRegister.detail
                )}
              </Typography>
            </div>
            <div className={`${classes.NavButtonModal}`}>
              <Button
                className={`${classes.CloseButton}`}
                onClick={() => setOpenModal(false)}
              >
                ปิด
              </Button>
            </div>
          </Paper>
        </Modal>
      )}
    </div>
  );
};

export default DetailDriverPeer2Peer;
