import React, { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";

import { styled } from "@mui/styles";

import { Typography, Button, Grid } from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import VehicleTypeService from "../../../../../services/vehicleType.service";
import ModalShowmap from "./showMap/index";
import siteService from "../../../../../services/site.service";

require("dayjs/locale/th");
dayjs.locale("th");

const DivCoverReturn = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: "20px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const BackButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  color: "black",
  fontSize: "20px",
  left: "40px",
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: "30px",
}));

const DivContent = styled("div")(({ theme }) => ({
  width: "90%",
  maxWidth: "1100px",
  boxShadow:
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
  border: "1px solid #EEEEEE",
  backgroundColor: "#FBFCFC",
  borderRadius: "15px",
  padding: "20px",
  marginBottom: "50px",
}));

const DivTopic = styled("div")(({ theme }) => ({
  display: "flex",
  borderBottom: "1px solid #1565c0",
  marginTop: "24px",
}));

const TextMainTopic = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  color: theme.palette.text.primary,
}));

const TextTopic = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "bold",
}));

const TextData = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  marginLeft: "10px",
  color: theme.palette.text.primary,
}));

const DivTopicAndData = styled("div")(({ theme }) => ({
  display: "flex",
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  marginTop: "0px",
}));

const DivTypeOfFlight = styled("div")(({ theme }) => ({
  width: "calc(100% - 68px)",
  marginTop: "24px",
}));

const DivTextFlight = styled("div")(({ theme }) => ({
  width: "100%",
  borderBottom: "1px solid #DCDCDC",
  marginTop: "24px",
}));

const DivPurpose = styled("div")(({ theme }) => ({
  width: "calc(100% - 68px)",
  marginTop: "24px",
}));

const DivGettingPlace = styled("div")(({ theme }) => ({
  width: "calc(100% - 68px)",
  marginTop: "24px",
}));
const ViewButton = styled(Button)({
  border: "1px solid #00bb77",
  width: "100%",
  fontSize: "20px",
  // backgroundColor: "#3970C2",
  color: "#00bb77",
});

const DetailDeliveryCar = (props) => {
  const [booking, setBooking] = useState(null);
  const [typeCar, setTypeCar] = useState(null);
  const [passenger, setPassenger] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (props.location.state === undefined) {
      props.history.push("/admin/car_management/delivery");
    } else {
      let booking_temp = props.location.state.booking;

      siteService
        .getLocationDriverById(booking_temp.idDriver)
        .then((res) => {
          if (res.data) {
            booking_temp.LatDriver = res.data[0].LatDriver;
            booking_temp.LngDriver = res.data[0].LngDriver;
            setBooking(booking_temp);
          }
        })
        .catch((error) => {
          booking_temp.LatDriver = 0;
          booking_temp.LngDriver = 0;
          setBooking(booking_temp);
          console.log(error);
        });
      setBooking(props.location.state.booking);

      VehicleTypeService.getVehicleTypeById(
        props.location.state.booking.idTypeCar
      )
        .then((res) => {
          if (res.data) {
            setTypeCar(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.location.state]);
  const onCloseModalHandle = () => {
    setOpenModal(false);
  };

  const onClickOpenModal = () => {
    setOpenModal(true);
  };
  return (
    <DivCoverReturn>
      <BackButton onClick={() => props.history.goBack()}>
        <ArrowBackIosNewIcon /> Back
      </BackButton>
      <Title>ขอรถรับส่งของ</Title>
      {booking && (
        <>
          <DivContent>
            <DivTopic>
              <TextMainTopic>ผู้ขอรถ</TextMainTopic>
            </DivTopic>
            <GridContainer container spacing={3}>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>ชื่อ :</TextTopic>
                  <TextData>{booking.name}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>เบอร์โทรศัพท์ :</TextTopic>
                  <TextData>{booking.telephoneMobile}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>Email :</TextTopic>
                  <TextData>{booking.email}</TextData>
                </DivTopicAndData>
              </Grid>
            </GridContainer>
            <DivTopic>
              <TextMainTopic>รายละเอียดสิ่งของ</TextMainTopic>
            </DivTopic>
            <GridContainer container spacing={3}>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>ประเภทของที่ส่ง :</TextTopic>
                  <TextData>{booking.typeProduct}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>น้ำหนักโดยประมาณ :</TextTopic>
                  <TextData>{`${booking.weightProduct} kg`}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item xs={4}>
                <DivTopicAndData>
                  <TextTopic>วัตถุประสงค์ :</TextTopic>
                  <TextData>{booking.purpose}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item xs={4}>
                <DivTopicAndData>
                  <TextTopic>รายละเอียด :</TextTopic>
                  <TextData>{booking.detail ? booking.detail : " - "}</TextData>
                </DivTopicAndData>
              </Grid>
            </GridContainer>
            <DivTopic>
              <TextMainTopic>รายละเอียดการขอรถ</TextMainTopic>
            </DivTopic>
            <GridContainer container spacing={3}>
              <Grid item md={6}>
                <DivTopicAndData>
                  <TextTopic>จุดรับ :</TextTopic>
                  <TextData>{booking.fromPlace}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={6}>
                <DivTopicAndData>
                  <TextTopic>จุดส่ง :</TextTopic>
                  <TextData>{booking.toPlace}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>วันที่เดินทาง :</TextTopic>
                  <TextData>
                    {dayjs(booking.departureDate).format("DD MMMM YYYY")}
                  </TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>เวลาเริ่มออกรถ :</TextTopic>
                  <TextData>
                    {dayjs(booking.startTime).format("HH.mm น.")}
                  </TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={4}>
                <DivTopicAndData>
                  <TextTopic>เวลาคืนรถ :</TextTopic>
                  <TextData>
                    {dayjs(booking.endTime).format("HH.mm น.")}
                  </TextData>
                </DivTopicAndData>
              </Grid>
            </GridContainer>
            <DivTopic>
              <TextMainTopic>รายละเอียดผู้รับ</TextMainTopic>
            </DivTopic>
            <GridContainer container spacing={3}>
              <Grid item md={6}>
                <DivTopicAndData>
                  <TextTopic>ชื่อผู้รับ :</TextTopic>
                  <TextData>{booking.nameRecipient}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item md={6}>
                <DivTopicAndData>
                  <TextTopic>เบอร์โทรศัพท์ผู้รับ :</TextTopic>
                  <TextData>{booking.telephoneMobileRecipient}</TextData>
                </DivTopicAndData>
              </Grid>
              <Grid item xs={12}>
                <ViewButton onClick={onClickOpenModal}>แสดงแผนที่</ViewButton>
              </Grid>
            </GridContainer>
          </DivContent>
          <ModalShowmap
            open={openModal}
            onCloseHandle={onCloseModalHandle}
            inforInCar={booking}
          />
        </>
      )}
    </DivCoverReturn>
  );
};

export default DetailDeliveryCar;
