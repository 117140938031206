import React, { useState, useEffect } from "react";

import { styled, withStyles } from "@mui/styles";

import {
  Typography,
  Stepper,
  StepConnector,
  Button,
  IconButton,
  Step,
  StepLabel,
  Paper,
  Modal,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { SnackbarProvider, useSnackbar } from "notistack";

import ModalManageCar from "../modalManageCar";

import routeCrossAreaCarPool from "../../../../../../services/routeCrossAreaCarPool.service.js";
import DeleteModal from "../../../../shared/deleteModal/index.js";
// import { deleteRouteCrossPools } from "../../../../actions/crossAreaCarPoolBooking";

const RouteStyled = styled("div")({
  display: "flex",
  alignItems: "center",
  margin: "0px 2% 30px",
});

const PartOfOrderRoute = styled("div")({
  minWidth: "60px",
  flexBasis: "5%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const OrderRoute = styled("div")(({ theme }) => ({
  width: "50px",
  height: "50px",
  border: "3px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const TextOrderRoute = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "30px",
}));

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 1,
  },
}))(StepConnector);

const StepIcon = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "25px",
  ["&:hover"]: {
    cursor: "pointer",
  },
}));

const PartOfManageCar = styled("div")({
  flexBasis: "15%",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  "& .Icon": {
    fontSize: "35px",
    color: "white",
  },
});

const ManageCarButton = styled(Button)(({ theme }) => ({
  width: "100px",
  height: "50px",
  fontSize: "20px",
  backgroundColor: theme.palette.primary.main,
  color: "white",
  ["&:hover"]: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const EditButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  ["&:hover"]: {
    backgroundColor: theme.palette.grey[700],
  },
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "red",
  ["&:hover"]: {
    backgroundColor: "darkred",
  },
}));

const ModalStyled = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const PaperStyled = styled(Paper)({
  width: "90%",
  maxWidth: "350px",
  padding: "20px",
});

const TextModalDetail = styled(Typography)({
  fontSize: "20px",
});

const RouteLineComponent = (props) => {
  const [InforManageCarRoute, setInforManageCarRoute] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModalDetailPassenger, setOpenModalDetailPassenger] =
    useState(false);
  const [InforModalDetailPassenger, setInforModalDetailPassenger] =
    useState(null);

  const ColorlibStepIcon = (propsInColorlibStepIcon) => {
    const { icon } = propsInColorlibStepIcon;

    const onClickPaseenger = () => {
      setOpenModalDetailPassenger(true);
      setInforModalDetailPassenger({
        name: propsInColorlibStepIcon.passenger.detail.name
          ? propsInColorlibStepIcon.passenger.detail.name
          : "ไม่ได้ระบุ",
        phone: propsInColorlibStepIcon.passenger.detail.telephoneMobile
          ? propsInColorlibStepIcon.passenger.detail.telephoneMobile
          : "ไม่ได้ระบุ",
        department: propsInColorlibStepIcon.passenger.detail.department
          ? propsInColorlibStepIcon.passenger.detail.department
          : "ไม่ได้ระบุ",
        departure: propsInColorlibStepIcon.passenger.detail.fromPlace
          ? propsInColorlibStepIcon.passenger.detail.fromPlace
          : "ไม่ได้ระบุ",
      });
    };

    return <StepIcon onClick={onClickPaseenger}>{icon}</StepIcon>;
  };

  const onClickManageCarButton = (route) => {
    setInforManageCarRoute({
      idVehicle: "",
      idDriver: "",
      plate_No: "",
      nameDriver: "",
      phoneDriver: "",
      DetailManageCar: "",
      isDriverFromCompany: true,
      routeLine: props.route[0].routeLine,
    });
    setOpenModal(true);
  };

  const onClickEditButton = (route) => {
    // console.log("route : ",route);
    setInforManageCarRoute({
      idVehicle: String(route[0].idVehicle),
      idDriver: route[0].idDriver,
      plate_No: route[0].plate_No,
      nameDriver: route[0].nameDriver,
      phoneDriver: route[0].phoneDriver,
      DetailManageCar: route[0].comment,
      isDriverFromCompany: props.route[0].isDriverFromCompany,
      routeLine: props.route[0].routeLine,
    });
    setOpenModal(true);
  };
  const onClickDeleteButton = (route) => {
    routeCrossAreaCarPool.deleteRouteCrossPools(route).then((res) => {
      if (res.status === 200) {
        props.handleActionInPage();
        setOpenDeleteModal(false);
        enqueueSnackbar("Delete Success", { variant: "success" });
      } else {
        enqueueSnackbar("Delete Failed", { variant: "error" });
      }
    });
  };

  return (
    <>
      <RouteStyled>
        <PartOfOrderRoute>
          <OrderRoute>
            <TextOrderRoute>{props.index}</TextOrderRoute>
          </OrderRoute>
        </PartOfOrderRoute>
        <Stepper
          nonLinear
          alternativeLabel
          connector={<ColorlibConnector />}
          style={{ flexBasis: "80%" }}
        >
          {props.route.map((passenger, index) => (
            <Step key={index + 1} active={true} index={index}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                StepIconProps={{ passenger: passenger }}
              >
                <div>
                  <Typography>{passenger.detail.name}</Typography>
                  <Typography>{passenger.detail.department}</Typography>
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <PartOfManageCar>
          {!props.route[0].statusManageCar ? (
            <ManageCarButton onClick={onClickManageCarButton}>
              จัดรถ
            </ManageCarButton>
          ) : (
            <>
              <EditButton
                // onClick={onClickEditButton}
                onClick={() => onClickEditButton(props.route)}
                size="large"
              >
                <EditIcon className={"Icon"} />
              </EditButton>
              <DeleteButton
                size="large"
                onClick={() => setOpenDeleteModal(true)}
              >
                <DeleteForeverIcon className={"Icon"} />
              </DeleteButton>
            </>
          )}
        </PartOfManageCar>
      </RouteStyled>
      {InforModalDetailPassenger && (
        <ModalStyled
          open={openModalDetailPassenger}
          onClose={() => setOpenModalDetailPassenger(false)}
        >
          <PaperStyled>
            <div>
              <TextModalDetail>{`ชื่อ-นามสกุล : ${InforModalDetailPassenger.name}`}</TextModalDetail>
              <TextModalDetail>{`เบอร์โทรศัพท์ : ${InforModalDetailPassenger.phone}`}</TextModalDetail>
              <TextModalDetail>{`หน่วยงาน : ${InforModalDetailPassenger.department}`}</TextModalDetail>
              <TextModalDetail>{`จุดรับ : ${InforModalDetailPassenger.departure}`}</TextModalDetail>
            </div>
          </PaperStyled>
        </ModalStyled>
      )}
      {InforManageCarRoute && (
        <ModalManageCar
          open={openModal}
          onCloseHandle={() => setOpenModal(false)}
          InforManageCarRoute={InforManageCarRoute}
          handleActionInPage={props.handleActionInPage}
        />
      )}
      <DeleteModal
        open={openDeleteModal}
        onCloseDeleteModalHandle={() => setOpenDeleteModal(false)}
        onClickDelete={() => onClickDeleteButton(props)}
        label={"ลบรายการจองรถข้ามพื้นที่แบบประจําทาง"}
      />
    </>
  );
};

export default RouteLineComponent;
