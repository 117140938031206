import React, { useEffect, useState } from "react";
import Card from "../Card";
import { Grid, Typography, Button, Rating, Box } from "@mui/material";

import ModalRating from "../Modal";

import DriveEtaIcon from "@mui/icons-material/DriveEta";

import dayjs from "dayjs";
import "dayjs/locale/th";

require("dayjs/locale/th");
dayjs.locale("th");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Item = ({ data }) => {
  const [value, setValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const onCloseModalHandle = () => {
    setOpenModal(false);
  };

  const onClickOpenModal = () => {
    setOpenModal(true);
  };
  return (
    <Card>
      <Grid container>
        <Grid item xs={12} md={5} lg={2} spacing={2} alignItems="center">
          {data.typeBooking === "CrossArea" && (
            <Typography fontSize={20}>
              <i
                className="fa-solid fa-car"
                style={{ marginRight: "10px" }}
              ></i>
              รถข้ามพื้นที่เฉพาะ
            </Typography>
          )}
          {data.typeBooking === "CrossAreaPool" && (
            <Typography fontSize={20} color="#f8c243 !important">
              <i className="fas fa-bolt"></i> รถข้ามพื้นที่ Pool
            </Typography>
          )}
          {data.typeBooking === "InArea" && (
            <Typography fontSize={20} color="cornflowerblue !important">
              <i className="fa-solid fa-car"></i> รถในพื้นที่
            </Typography>
          )}
          {data.typeBooking === "Delivery" && (
            <Typography fontSize={20} color="#2d4a7c !important">
              <i className="fa-solid fa-truck" style={{ color: "#2d4a7c" }}></i>{" "}
              รถรับส่งของ
            </Typography>
          )}
          {data.typeBooking === "MeetingRoom" && (
            <Typography fontSize={20} color="#2d7c64 !important">
              <i
                className="fa-solid fa-people-roof"
                style={{ color: "#2d7c64" }}
              ></i>{" "}
              จองสถานที่
            </Typography>
          )}
          {data.typeBooking === "DriverBooking" && (
            <Typography fontSize={20} color="#AF9675 !important">
              <i className="fa-solid fa-user"></i> จองคนขับรถ
            </Typography>
          )}
        </Grid>
        {data.typeBooking === "CrossArea" && (
          <>
            <Grid
              item
              xs={12}
              md={1.5}
              lg={1.5}
              spacing={2}
              alignItems="center"
            >
              
              <Typography className="title">วันที่แจ้ง</Typography>
              <Typography>
                {dayjs(data.departureDate).locale("th").format("DD MMMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1.5}
              lg={1.5}
              spacing={2}
              alignItems="center">
              <Typography className="title">เวลา</Typography>
              <Typography>{dayjs(data.startTime).format("HH.MM")}</Typography>
            </Grid>
            <Grid item xs={12}
              md={1.5}
              lg={1.5}
              spacing={2}
              alignItems="center">
              <Typography className="title">จุดประสงค์</Typography>
              <Typography>
                {data.purpose === "" ? "-" : data.purpose}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1.5}
              lg={1.5}
              spacing={2}
              alignItems="center">
              <Typography className="title">ชื่อคนขับรถ</Typography>
              <Typography>{data.nameDriver}</Typography>
            </Grid>
            <Grid item xs={12}
              md={2}
              lg={2}
              spacing={2}
              alignItems="center">
              <Typography className="title">สถานะ</Typography>
              {data.statusRating === "Success" && (
                <Typography color="#43a047 !important">
                  <i className="fas fa-check-circle"></i> ประเมินเสร็จสิ้นแล้ว
                </Typography>
              )}
              {data.statusRating != "Success" && (
                <Typography color="#e53935 !important">
                  <i className="far fa-clock"></i> กำลังรอการประเมิน...
                </Typography>
              )}
            </Grid>
          </>
        )}

        {data.typeBooking === "CrossAreaPool" && (
          <>
            <Grid item xs={12}
              md={1.5}
              lg={1.5}
              spacing={2}
              alignItems="center">
              <Typography className="title">วันที่แจ้ง</Typography>
              <Typography>
                {dayjs(data.departureDate).locale("th").format("DD MMMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1}
              lg={1}
              spacing={2}
              alignItems="center">
              <Typography className="title">เวลา</Typography>
              <Typography>{dayjs(data.startTime).format("HH.MM")}</Typography>
            </Grid>

            <Grid item xs={12}
              md={1.25}
              lg={1.25}
              spacing={2}
              alignItems="center">
              <Typography className="title">สถานที่(ขาไป)</Typography>
              <Typography>
                {data.fromPlace} - {data.toPlace}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1.25}
              lg={1.25}
              spacing={2}
              alignItems="center">
              <Typography className="title">สถานที่(ขากลับ)</Typography>
              <Typography>
                {data.fromPlaceReturn} - {data.toPlaceReturn}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1}
              lg={1}
              spacing={2}
              alignItems="center">
              <Typography className="title">ชื่อคนขับรถ</Typography>
              <Typography>{data.nameDriver}</Typography>
            </Grid>
            <Grid item xs={12}
              md={2}
              lg={2}
              spacing={2}
              alignItems="center">
              <Typography className="title">สถานะ</Typography>
              {data.statusRating === "Success" && (
                <Typography color="#43a047 !important">
                  <i className="fas fa-check-circle"></i> ประเมินเสร็จสิ้นแล้ว
                </Typography>
              )}
              {data.statusRating != "Success" && (
                <Typography color="#e53935 !important">
                  <i className="far fa-clock"></i> กำลังรอการประเมิน...
                </Typography>
              )}
            </Grid>
          </>
        )}
        {data.typeBooking === "InArea" && (
          <>
            <Grid item xs={12}
              md={1.5}
              lg={1.5}
              spacing={2}
              alignItems="center">
              <Typography className="title">วันที่แจ้ง</Typography>
              <Typography>
                {dayjs(data.departureDate).locale("th").format("DD MMMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1}
              lg={1}
              spacing={2}
              alignItems="center">
              <Typography className="title">เวลา</Typography>
              <Typography>{dayjs(data.startTime).format("HH.MM")}</Typography>
            </Grid>
            <Grid item xs={12}
              md={1.25}
              lg={1.25}
              spacing={2}
              alignItems="center">
              <Typography className="title">จุดประสงค์</Typography>
              <Typography>
                {data.purpose === "" ? "-" : data.purpose}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1.25}
              lg={1.25}
              spacing={2}
              alignItems="center">
              <Typography className="title">สถานที่</Typography>
              <Typography>
                {data.fromPlace} - {data.toPlace}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1}
              lg={1}
              spacing={2}
              alignItems="center">
              <Typography className="title">ชื่อคนขับรถ</Typography>
              <Typography>{data.nameDriver}</Typography>
            </Grid>
            <Grid item xs={12}
              md={2}
              lg={2}
              spacing={2}
              alignItems="center">
              <Typography className="title">สถานะ</Typography>
              {data.statusRating === "Success" && (
                <Typography color="#43a047 !important">
                  <i className="fas fa-check-circle"></i> ประเมินเสร็จสิ้นแล้ว
                </Typography>
              )}
              {data.statusRating != "Success" && (
                <Typography color="#e53935 !important">
                  <i className="far fa-clock"></i> กำลังรอการประเมิน...
                </Typography>
              )}
            </Grid>
          </>
        )}
        {data.typeBooking === "Delivery" && (
          <>
            <Grid item xs={12}
              md={1.5}
              lg={1.5}
              spacing={2}
              alignItems="center">
              <Typography className="title">วันที่แจ้ง</Typography>
              <Typography>
                {dayjs(data.departureDate).locale("th").format("DD MMMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1}
              lg={1}
              spacing={2}
              alignItems="center">
              <Typography className="title">เวลา</Typography>
              <Typography>
                {dayjs(data.startTime).format("HH.MM")} -{" "}
                {dayjs(data.endTime).format("HH.MM")}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1.25}
              lg={1.25}
              spacing={2}
              alignItems="center">
              <Typography className="title">จุดประสงค์</Typography>
              <Typography>
                {data.purpose === "" ? "-" : data.purpose}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1.25}
              lg={1.25}
              spacing={2}
              alignItems="center">
              <Typography className="title">สถานที่</Typography>
              <Typography>
                {data.fromPlace} - {data.toPlace}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1}
              lg={1}
              spacing={2}
              alignItems="center">
              <Typography className="title">ชื่อคนขับรถ</Typography>
              <Typography>{data.nameDriver}</Typography>
            </Grid>
            <Grid item xs={12}
              md={2}
              lg={2}
              spacing={2}
              alignItems="center">
              <Typography className="title">สถานะ</Typography>
              {data.statusRating === "Success" && (
                <Typography color="#43a047 !important">
                  <i className="fas fa-check-circle"></i> ประเมินเสร็จสิ้นแล้ว
                </Typography>
              )}
              {data.statusRating != "Success" && (
                <Typography color="#e53935 !important">
                  <i className="far fa-clock"></i> กำลังรอการประเมิน...
                </Typography>
              )}
            </Grid>
          </>
        )}
        {data.typeBooking === "MeetingRoom" && (
          <>
            <Grid item xs={12}
              md={1.5}
              lg={1.5}
              spacing={2}
              alignItems="center">
              <Typography className="title">วันที่แจ้ง</Typography>
              <Typography>
                {dayjs(data.departureDate).locale("th").format("DD MMMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1}
              lg={1}
              spacing={2}
              alignItems="center">
              <Typography className="title">เวลา</Typography>
              <Typography>
                {data.timeStart} - {data.timeEnd}
              </Typography>
            </Grid>

            <Grid item xs={12}
              md={1.25}
              lg={1.25}
              spacing={2}
              alignItems="center">
              <Typography className="title">สถานที่</Typography>
              <Typography>{data.nameMeetingRoom}</Typography>
            </Grid>
            <Grid item xs={12}
              md={1.25}
              lg={1.25}
              spacing={2}
              alignItems="center">
              <Typography className="title">จำนวนคน</Typography>
              <Typography>{data.numOfPeople}</Typography>
            </Grid>
            <Grid item xs={12}
              md={1}
              lg={1}
              spacing={2}
              alignItems="center">
              <Typography className="title">ราคา</Typography>
              <Typography>{data.totalPrice}</Typography>
            </Grid>
            <Grid item xs={12}
              md={2}
              lg={2}
              spacing={2}
              alignItems="center">
              <Typography className="title">สถานะ</Typography>
              {data.statusRating === "Success" && (
                <Typography color="#43a047 !important">
                  <i className="fas fa-check-circle"></i> ประเมินเสร็จสิ้นแล้ว
                </Typography>
              )}
              {data.statusRating != "Success" && (
                <Typography color="#e53935 !important">
                  <i className="far fa-clock"></i> กำลังรอการประเมิน...
                </Typography>
              )}
            </Grid>
          </>
        )}
        {data.typeBooking === "DriverBooking" && (
          <>
            <Grid item xs={12}
              md={1.5}
              lg={1.5}
              spacing={2}
              alignItems="center">
              <Typography className="title">วันที่แจ้ง</Typography>
              <Typography>
                {dayjs(data.departureDate).locale("th").format("DD MMMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1.5}
              lg={1.5}
              spacing={2}
              alignItems="center">
              <Typography className="title">เวลา</Typography>
              <Typography>
                {data.startTime} - {data.endTime}
              </Typography>
            </Grid>
            {/* <Grid item xs={1.5}>
              <Typography className="title">จุดประสงค์</Typography>
              <Typography>
                {data.purpose === "" ? "-" : data.purpose}
              </Typography>
            </Grid> */}
            <Grid item xs={12}
              md={1.5}
              lg={1.5}
              spacing={2}
              alignItems="center">
              <Typography className="title">สถานที่</Typography>
              <Typography>
                {data.namePlaceFrom} - {data.namePlaceTo}
              </Typography>
            </Grid>
            <Grid item xs={12}
              md={1.5}
              lg={1.5}
              spacing={2}
              alignItems="center">
              <Typography className="title">ชื่อคนขับรถ</Typography>
              <Typography>{data.nameDriver}</Typography>
            </Grid>
            <Grid itemxs={12}
              md={2}
              lg={2}
              spacing={2}
              alignItems="center">
              <Typography className="title">สถานะ</Typography>
              {data.statusRating === "Success" && (
                <Typography color="#43a047 !important">
                  <i className="fas fa-check-circle"></i> ประเมินเสร็จสิ้นแล้ว
                </Typography>
              )}
              {data.statusRating != "Success" && (
                <Typography color="#e53935 !important">
                  <i className="far fa-clock"></i> กำลังรอการประเมิน...
                </Typography>
              )}
            </Grid>
          </>
        )}

        {data.statusRating != "Success" && (
          <Grid item xs={12} md={5} lg={2} spacing={2} container alignItems="center" marginTop={2} >
            <Button
              variant="contained"
              onClick={onClickOpenModal}
              style={{ borderRadius: "10px" }}
            >
              ประเมินความพึงพอใจ
            </Button>
            <ModalRating
              open={openModal}
              onCloseHandle={onCloseModalHandle}
              infor={data}
            />
          </Grid>
        )}
        {data.statusRating === "Success" && (
          // <Grid item xs={2}>
          //   <Rating
          //     name="simple-controlled"
          //     value={parseInt(data.rating)}
          //     sx={{
          //       fontSize: "2rem",
          //     }}
          //     readOnly
          //   />
          //   <Typography className="title">
          //     {data.comment === "" ? "-" : data.comment}
          //   </Typography>
          // </Grid>
          <Grid item xs={12} md={5} lg={2} spacing={2}>
            <Typography className="title" style={{ marginTop: "15px" }}>
              ขอบคุณสำหรับความคิดเห็น
            </Typography>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Item;
