import React from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  DivCoverForm: {
    width: "90%",
    position: "absolute",
    zIndex: "1",
    paddingBottom: "30px",
  },
  Grid: {
    marginTop: "10px",
  },
  Title: {
    textAlign: "center",
    fontSize: "30px",
    marginTop: "25px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
    },
  },
  NavButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  Button: {
    width: "100px",
    height: "50px",
    fontSize: "18px",
    color: "white",
    backgroundColor: "green",
    ["&:hover"]: {
      backgroundColor: "darkgreen",
    },
  },
  NavButtonBack: {
    position: "absolute",
    width: "fit-content",
    top: "90px",
    left: "60px",
    zIndex: "10",
    [theme.breakpoints.down("sm")]: {
      left: "10px",
      top: "70px",
    },
  },
  BackButton: {
    backgroundColor: "white",
    ["&:hover"]: {
      backgroundColor: "white",
    },
  },
  BackIcon: {
    fontSize: "20px",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  TextBackButton: {
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
}));

const FormOfDriver = (props) => {
  const { register, handleSubmit, control, watch } = useForm();
  const classes = useStyles();
  // const AllInput = watch();

  const onSubmitHandler = (data) => {
    console.log(data);
    // props.history.push("/friend2friend_Car/driver/listRegisterPage");
  };

  const onClickBackButton = () => {
    props.history.push("/friend2friend_Car/driver/listRegisterPage");
  };

  return (
    <div className={`page ${classes.DivCoverReturn}`}>
      <div className={`${classes.NavButtonBack}`}>
        <Button className={`${classes.BackButton}`} onClick={onClickBackButton}>
          <ArrowBackIosIcon className={`${classes.BackIcon}`} />
          <Typography className={`${classes.TextBackButton}`}>Back</Typography>
        </Button>
      </div>
      <div className={`${classes.DivCoverForm}`}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Typography className={`${classes.Title}`}>
            Form ของคนขับรถ
          </Typography>
          <Grid container spacing={3} className={`${classes.Grid}`}>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  render={(propsInController) => (
                    <DatePicker
                      label="Date"
                      minDate={new Date()}
                      value={propsInController.field.value}
                      onChange={(date) => {
                        propsInController.field.onChange(date);
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  )}
                  name={"startDate"}
                  defaultValue={new Date()}
                  control={control}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  render={(propsInController) => (
                    <DatePicker
                      label="Date End"
                      minDate={new Date()}
                      value={propsInController.field.value}
                      onChange={(date) => {
                        propsInController.field.onChange(date);
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  )}
                  name={"endDate"}
                  defaultValue={new Date()}
                  control={control}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                render={(propsInController) => (
                  <FormControl fullWidth>
                    <InputLabel shrink>{"เวลา"}</InputLabel>
                    <Select
                      native
                      label="เวลา"
                      value={propsInController.field.value}
                      onChange={(e) => {
                        propsInController.field.onChange(e.target.value);
                      }}
                    >
                      {[...new Array(48)].map((value, index) => {
                        if (index === 0) {
                          return (
                            <option value="Midnight" key="Midnight">
                              Midnight
                            </option>
                          );
                        } else if (index === 24) {
                          return (
                            <option value="Noon" key="Noon">
                              Noon
                            </option>
                          );
                        } else {
                          let hour = Math.floor((0 + index * 30) / 60);
                          let minute = Math.floor((0 + index * 30) % 60);
                          if (minute < 10) {
                            minute = "0" + minute;
                          }
                          return (
                            <option
                              value={hour + ":" + minute}
                              key={hour + ":" + minute}
                            >
                              {hour + ":" + minute}
                            </option>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                )}
                name="time"
                control={control}
                defaultValue="Midnight"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>{"ประเภท"}</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      label="ประเภท"
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                    >
                      <MenuItem value={"GoToWork"}>ไปทำงาน</MenuItem>
                      <MenuItem value={"ComeBackHome"}>กลับบ้าน</MenuItem>
                    </Select>
                  )}
                  name="type"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                placeholder="ระบุจุดรับ"
                label="จุดรับ"
                fullWidth
                inputProps={{
                  ...register("fromPlace"),
                }}
              />
              {/* <FormControl fullWidth variant="outlined">
                <InputLabel>{"จุดรับ"}</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      label="จุดรับ"
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                    >
                      <MenuItem value={"site 1"}>Site 1</MenuItem>
                      <MenuItem value={"site 2"}>Site 2</MenuItem>
                      <MenuItem value={"site 3"}>Site 3</MenuItem>
                    </Select>
                  )}
                  name="fromPlace"
                  control={control}
                  defaultValue=""
                />
              </FormControl> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                placeholder="ระบุปลายทาง"
                label="ปลายทาง"
                fullWidth
                inputProps={{
                  ...register("toPlace"),
                }}
              />
              {/* <FormControl fullWidth variant="outlined">
                <InputLabel>{"ปลายทาง"}</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      label="ปลายทาง"
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                    >
                      <MenuItem value={"site 1"}>Site 1</MenuItem>
                      <MenuItem value={"site 2"}>Site 2</MenuItem>
                      <MenuItem value={"site 3"}>Site 3</MenuItem>
                    </Select>
                  )}
                  name="toPlace"
                  control={control}
                  defaultValue=""
                />
              </FormControl> */}
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="ระบุรายละเอียดการนัดหมาย"
                label="รายละเอียดการนัดหมาย"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                inputProps={{
                  ...register("detail"),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>{"จำนวนคนที่รับได้"}</InputLabel>
                <Controller
                  render={(propsInController) => (
                    <Select
                      label="จำนวนคนที่รับได้"
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                    >
                      {[...new Array(6)].map((value, index) => {
                        return (
                          <MenuItem key={value + "" + index} value={index + 1}>
                            {index + 1}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  name="seats"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="ระบุเบอร์โทรศัพท์"
                label="เบอร์โทรศัพท์"
                variant="outlined"
                fullWidth
                inputProps={{
                  ...register("telephoneMobile"),
                  // pattern: `[0]\\d{2}(?:[\\-]?\\d{3}){2}\\d$`,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={`${classes.NavButton}`}>
                <Button className={`${classes.Button}`} type="submit">
                  ยืนยัน
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default FormOfDriver;
