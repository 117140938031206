import React, { useState, useEffect } from "react";
import { Button, Typography, styled, Divider } from "@mui/material";
import {
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Box,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import Container from "@mui/material/Container";
import { SnackbarProvider, useSnackbar } from "notistack";

import { useForm, Controller } from "react-hook-form";

import { useSelector, useDispatch } from "react-redux";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Alert from "@mui/material/Alert";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getUserProfile } from "../../../../../actions/user";
import utils from "../../../../../utils";
import MeetingRoomService from "../../../../../services/meetingRoom.service";

const StyledRoot = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
  "& .divCoverReturn": {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "40px",
  },
  "& .Grid": {
    width: "100%",
    height: "100%",
    marginBottom: "30px",

    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },
  "& .Title": {
    // textAlign: "center",
    fontSize: "42px",
    // margin: "25px 0px",
  },
  "& .IconImage": {
    weight: "20%",
    height: "50%",
  },
  "& .content": {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
    },
    // marginTop: "15px",
  },
  "& .detail": {
    width: "60%",

    padding: "10px",
    marginRight: "90px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      padding: "10px",
    },
  },

  "& .booking": {
    borderRadius: "10px",
    backgroundColor: "white",
    border: "1px solid #DDDDDD",
    width: "550px",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 6px 16px",
    display: "grid",
    justifyContent: "center",
    padding: "20px",
    height: "450px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      padding: "10px",
    },
  },
  "& .datePicker": {
    // width: "100%",
    display: "flex",
    borderRadius: "10px",
    border: "1px solid RGB(196, 196, 196)",
    padding: "10px",
    marginTop: "10px",

    "&:hover": {
      border: "1px solid #222222",
    },
  },
  "& .timePicker": {
    display: "flex",
    borderRadius: "10px",
    border: "1px solid RGB(196, 196, 196)",
    padding: "10px",
    marginTop: "10px",
    "&:hover": {
      border: "1px solid #222222",
    },
  },
  "& .DataPicker": {
    width: "100%",
    marginRight: "0px",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      marginRight: "0px",
    },
  },
  "& .customDatePicker": {
    border: "none",
  },
  "& .seletNumber": {
    marginTop: "10px",
  },
  "& .price": {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .buttonBooking": {
    fontSize: "35px",
    border: "1px solid #00bb77",
    borderRadius: "10px",
    marginTop: "10px",
    marginBottom: "10px",

    "&:hover": {
      border: "1px solid white",
      backgroundColor: "#00bb77",
      color: "white",
    },
  },
  "& .textList": {
    fontSize: "20px",
  },
  "& .gridTextList": {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
  },
  "& .toggle": {
    fontSize: "20px",
  },
  "& .modal": {
    backgroundColor: "white",
    width: "30%",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: "10px",

    position: "absolute",
    left: "50%",
    top: "60%",
    transform: "translate(-50%, -50%)",
  },
  "& .modal-content": {
    padding: "10px",
  },
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "98%",
  marginTop: "25px",
  marginBottom: "25px",
  // backgroundColor: "#DDDDDD",
  borderStyle: "solid",
  borderWidth: "0px 0px thin",
  borderColor: "RGB(196, 196, 196)",
  // boxShadow: "1px 1px 1px 1px RGB(196, 196, 196)",
}));

const DividerStyledFull = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "25px",
  marginBottom: "25px",
  // backgroundColor: "#DDDDDD",
  borderStyle: "solid",
  borderWidth: "0px 0px thin",
  borderColor: "RGB(196, 196, 196)",
  // boxShadow: "1px 1px 1px 1px RGB(196, 196, 196)",
}));

const TabsStyled = styled(Tabs)(({ theme }) => ({
  width: "100%",
  borderRadius: "8px", // Rounded corners for the Tabs container
  overflow: "hidden",
  backgroundColor: "#e0e0e0", // Default background color for Tabs
  "& .MuiTabs-indicator": {
    height: "100%", // Make the indicator cover the entire Tab
    borderRadius: "8px", // Rounded corners for the indicator
    backgroundColor: "#00bb77", // Background color for the selected Tab
    transition: "all 0.3s ease-in-out", // Smooth transition effect
    "&:hover": {
      backgroundColor: "#1976d2", // Background color for the hovered Tab
    },
  },
}));

const TabStyled = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontSize: "15px",
  fontWeight: "bold",
  width: "50%",
  zIndex: 2,
  mixBlendMode: "normal",
  color: "#000", // Default text color for Tabs
  "&.Mui-selected": {
    color: "#fff", // White text when selected
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#1976d2",
  },
}));

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const DetailMeetingRoom = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const { user: AllUsers } = useSelector((state) => state.auth);
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const [startDate, setStartDate] = useState(
    new Date(new Date().setUTCHours(0, 0, 0, 0))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setUTCHours(0, 1, 0, 0))
  );
  const [timeStart, settimeStart] = useState("00:00");
  const [timeEnd, settimeEnd] = useState("00:00");
  const [isNumOfPeople, setIsNumofPeole] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);

  const [diffInDay, setDiffInDay] = useState(0);
  const [diffInHours, setDiffInHours] = useState(0);

  const [alignment, setAlignment] = useState("");
  const [textError, setTextError] = useState("");

  const [meetingRoom, setMeetingRoom] = useState([]);
  const [meetingRoomImage, setMeetingRoomImage] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [numOfPeople, setnumOfPeople] = useState([]);

  const [meetingRoomforsearch, setMeetingRoomforsearch] = useState([]);
  const [timeStartMeetingRoomforFree, setTimeStartMeetingRoomforFree] =
    useState([]);
  const [timeEndMeetingRoomforFree, setTimeEndMeetingRoomforFree] = useState(
    []
  );
  const [timeForCheck, setTimeForCheck] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isBookAllDay, setIsBookAllDay] = useState(0);

  useEffect(() => {
    if (props.history.location.state.idMeeting) {
      MeetingRoomService.getIAllImageNewMeetingRoomById(
        props.history.location.state.idMeeting
      ).then((res) => {
        if (res.data) {
          setMeetingRoomImage(res.data);
        } else {
          console.log("error");
        }
      });
    }
    MeetingRoomService.getAllMeetingRoomById(
      props.history.location.state.idMeeting
    ).then((res) => {
      if (res.data) {
        setMeetingRoom(res.data);
        setFacilities(res.data[0].facilities.split(","));
        const num = [];
        for (let i = 1; i <= parseInt(res.data[0].numberOfPeople); i++) {
          num.push(i);
        }
        setnumOfPeople(num);
      } else {
        console.log("error");
      }
    });

    MeetingRoomService.getAllMeetingRoomBookingByIdMeetingRoom(
      props.history.location.state.idMeeting
    ).then((res) => {
      if (res.data) {
        setMeetingRoomforsearch(res.data);

        const timeList = [];

        // สร้างรายการเวลาทุก 30 นาที
        for (let hours = 0; hours < 24; hours++) {
          for (let minutes = 0; minutes < 60; minutes += 30) {
            const startHours = String(hours).padStart(2, "0");
            const startMinutes = String(minutes).padStart(2, "0");
            let endMinutes = minutes + 30;
            let endHours = hours;

            if (endMinutes === 60) {
              endMinutes = "00";
              endHours = (endHours + 1) % 24;
            }

            endHours = String(endHours).padStart(2, "0");
            endMinutes = String(endMinutes).padStart(2, "0");

            var today = new Date();
            let newStartTime = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              startHours,
              startMinutes
            );
            let newEndTime = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              endHours,
              endMinutes
            );
            let check = false;

            for (let e = 0; e < res.data.length; e++) {
              const rangeStart = new Date(res.data[e].startDate).getTime();
              const rangeEnd = new Date(res.data[e].endDate).getTime();

              if (
                (newStartTime >= rangeStart && newStartTime < rangeEnd) ||
                (newEndTime > rangeStart && newEndTime <= rangeEnd) ||
                (newStartTime <= rangeStart && newEndTime >= rangeEnd)
              ) {
                check = true;
                break;
              } else {
                check = false;
              }
            }
            if (check === true) {
              continue;
            } else {
              const timeRange = `${startHours}:${startMinutes} - ${endHours}:${endMinutes}`;
              timeList.push(timeRange);
            }
          }
        }
        setTimeForCheck(timeList);
      } else {
        console.log("error");
      }
    });

    if (!AllUsers) {
      dispatch(getUserProfile());
    }
  }, []);

  useEffect(() => {
    if (meetingRoom.length > 0) {
      handleChangeDay(new Date(startDate), new Date(endDate));
    }
  }, [isBookAllDay]);

  const handleChangeTotalPrice = (diffInHours, diffInDay) => {
    console.log(diffInHours, diffInDay);
    setTotalPrice(diffInDay * diffInHours * parseFloat(meetingRoom[0].price));
  };

  const handleChangeDay = (dayStart, dayEnd) => {
    if (isBookAllDay === 1) {
      const differenceInMilliseconds = dayEnd.getTime() - dayStart.getTime();
      const millisecondsInDay = 1000 * 60 * 60 * 24;
      console.log(differenceInMilliseconds / millisecondsInDay);
      const differenceInDays =
        Math.floor(differenceInMilliseconds / millisecondsInDay) + 1;
      console.log(isBookAllDay, differenceInDays);
      setTotalPrice(differenceInDays * parseFloat(meetingRoom[0].priceAllDay));
    } else {
      if (dayStart <= dayEnd) {
        const differenceInMilliseconds = dayEnd.getTime() - dayStart.getTime();
        const millisecondsInDay = 1000 * 60 * 60 * 24;
        console.log(differenceInMilliseconds / millisecondsInDay);
        const differenceInDays =
          Math.floor(differenceInMilliseconds / millisecondsInDay) + 1;
        console.log(
          "The range between dayStart and dayEnd is " +
            differenceInDays +
            " days."
        );
        setDiffInDay(differenceInDays);
        handleChangeTotalPrice(diffInHours, differenceInDays);
      } else {
        console.log("The range between dayStart and dayEnd is 0 days.");
        setTotalPrice(0);
      }
    }
  };
  const handleChangeTime = (timeStart, timeEnd) => {
    console.log(timeStart, timeEnd);
    let [hourStart, minuteStart] = timeStart.split(":");
    let [hourEnd, minuteEnd] = timeEnd.split(":");
    hourStart = parseInt(hourStart);
    hourEnd = parseInt(hourEnd);
    minuteStart = parseInt(minuteStart);
    minuteEnd = parseInt(minuteEnd);
    console.log(hourStart, minuteStart, hourEnd, minuteEnd);
    if (
      hourStart < hourEnd ||
      (hourStart === hourEnd && minuteStart <= minuteEnd)
    ) {
      const differenceInMilliseconds = endDate.getTime() - startDate.getTime();

      const millisecondsInDay = 1000 * 60 * 60 * 24;
      console.log(differenceInMilliseconds / millisecondsInDay);
      const differenceInDays =
        Math.floor(differenceInMilliseconds / millisecondsInDay) + 1;
      console.log(
        "The range between dayStart and dayEnd is " +
          differenceInDays +
          " days."
      );
      setDiffInDay(differenceInDays);
      const differenceInHours =
        hourEnd - hourStart + (minuteEnd - minuteStart) / 60;
      setDiffInHours(differenceInHours);
      handleChangeTotalPrice(differenceInHours, differenceInDays);
    } else {
      setTotalPrice(0);
    }
  };

  const onClickSubmit = () => {
    let checkTime = false;
    let checkStartDate = new Date(
      startDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }) +
        ", " +
        timeStart
    );
    let checkEndDate = new Date(
      endDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }) +
        ", " +
        timeEnd
    );
    let numPeople = parseInt(isNumOfPeople);

    for (let e = 0; e < meetingRoomforsearch.length; e++) {
      const rangeStart = new Date(meetingRoomforsearch[e].startDate).getTime();
      const rangeEnd = new Date(meetingRoomforsearch[e].endDate).getTime();
      const newStart = new Date(checkStartDate).getTime();
      const newEnd = new Date(checkEndDate).getTime();

      // );
      if (
        (newStart >= rangeStart && newStart < rangeEnd) ||
        (newEnd > rangeStart && newEnd <= rangeEnd) ||
        (newStart <= rangeStart && newEnd >= rangeEnd)
      ) {
        // console.log(true, "check")
        checkTime = true;
        break;
      } else {
        checkTime = false;
        // console.log(false, "check")
      }
    }
    let dateStart = new Date(
      startDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    );
    let DateEnd = new Date(
      endDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    );
    // console.log(dateStart.toISOString().slice(0,10) ===  DateEnd.toISOString().slice(0,10))
    if (
      timeEnd === timeStart &&
      isBookAllDay === 0 &&
      dateStart.toISOString().slice(0, 10) ===
        DateEnd.toISOString().slice(0, 10)
    ) {
      setTextError("กรอกเวลาให้ถูกต้อง");
    } else if (
      timeEnd < timeStart &&
      isBookAllDay === 0 &&
      dateStart.toISOString().slice(0, 10) ===
        DateEnd.toISOString().slice(0, 10)
    ) {
      setTextError("กรอกเวลาให้ถูกต้อง");
    } else if (startDate > endDate) {
      setTextError("วันที่เริ่มต้นต้องน้อยกว่าวันที่สิ้นสุด");
    } else if (checkTime === true) {
      setTextError("เวลาที่เลือกถูกจองไปแล้ว");
    } else if (
      isNumOfPeople === null ||
      numPeople === 0 ||
      numPeople < 0 ||
      isNumOfPeople === ""
    ) {
      setTextError("กรุณากรอกจำนวนคนให้ถูกต้อง");
    } else if (numPeople > meetingRoom[0].numberOfPeople) {
      setTextError(`จำนวนคนต้องไม่เกิน ${meetingRoom[0].numberOfPeople} คน`);
    } else {
      setTextError("");
      const data = {
        idUser: AllUsers.idUser,
        startDate: startDate.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        }),
        endDate: endDate.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        }),
        timeStart: isBookAllDay ? "00:00" : timeStart,
        timeEnd: isBookAllDay ? "23:59" : timeEnd,
        numOfPeople: isNumOfPeople,
        totalPrice: totalPrice,
        isBookAllDay: isBookAllDay === 1 ? true : false,
        idMeetingRoom: props.history.location.state.idMeeting,
      };

      MeetingRoomService.postNewMeetingRoomBooking(data).then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("เพิ่มการจองเรียบร้อย", { variant: "success" });
          props.history.push(`/meetingroom`);
        } else {
          enqueueSnackbar("เพิ่มการจองไม่สําเร็จ", { variant: "error" });
        }
      });
    }
  };

  const onChangeTab = (event, newValue) => {
    setIsBookAllDay(newValue);
  };
  const handleChangeEvent = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleChangeStart = (newValue) => {
    settimeStart(newValue);
    handleChangeTime(newValue, timeEnd);
  };
  const handleChangeEnd = (newValue) => {
    settimeEnd(newValue);
    handleChangeTime(timeStart, newValue);
  };

  const handleChangeDayStart = (newValue) => {
    setStartDate(newValue);
    handleChangeDay(new Date(newValue), new Date(endDate));
  };

  const handleChangeDayEnd = (newValue) => {
    setEndDate(newValue);
    handleChangeDay(new Date(startDate), new Date(newValue));
  };

  const handleChangeNumOfPeolple = (newValue) => {
    setIsNumofPeole(newValue.target.value);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    props.history.push(`/meetingroom`);
  };

  return (
    <Container maxWidth="xl">
      <StyledRoot>
        <div className="divCoverReturn">
          {meetingRoom.length > 0 && (
            <div className="Grid">
              <p className="Title">{meetingRoom[0].nameMeetingRoom}</p>

              <Grid container style={{ width: "100%" }}>
                <ImageList
                  sx={{ width: "100%", height: "50%", borderRadius: "10px" }}
                  variant="quilted"
                  // cols={4}
                  cols={matchDownMd ? 2 : 4}
                  rowHeight={290}
                >
                  {meetingRoomImage &&
                    meetingRoomImage.map((item, index) =>
                      index === 0 ? (
                        <ImageListItem
                          key={item.imageBase64}
                          cols={item.cols || 2}
                          rows={item.rows || 2}
                        >
                          <img
                            src={item.imageBase64}
                            alt={item.title}
                            loading="lazy"
                          />
                        </ImageListItem>
                      ) : (
                        <ImageListItem
                          key={item.imageBase64}
                          cols={item.cols || 1}
                          rows={item.rows || 1}
                        >
                          <img
                            src={item.imageBase64}
                            alt={item.title}
                            loading="lazy"
                          />
                        </ImageListItem>
                      )
                    )}
                  {meetingRoom[0].fileUrl.length > 0 &&
                    meetingRoom[0].fileUrl.map((item, index) =>
                      index === 0 ? (
                        <ImageListItem
                          key={item}
                          cols={item.cols || 2}
                          rows={item.rows || 2}
                        >
                          <img src={item} alt={item.title} loading="lazy" />
                        </ImageListItem>
                      ) : (
                        <ImageListItem
                          key={item}
                          cols={item.cols || 1}
                          rows={item.rows || 1}
                        >
                          <img src={item} alt={item.title} loading="lazy" />
                        </ImageListItem>
                      )
                    )}
                </ImageList>
                <div className="content">
                  <div className="detail">
                    <Grid xs={12} md={8} lg={12}>
                      <Box sx={{ width: "100%", maxWidth: "100%" }}>
                        <Typography
                          style={{ fontSize: "24px", fontWeight: "bolder" }}
                        >
                          รายละเอียด
                        </Typography>
                        <Typography
                          style={{ fontSize: "20px", marginTop: "10px" }}
                        >
                          {meetingRoom[0].detail}
                        </Typography>
                      </Box>
                    </Grid>
                    <DividerStyled />

                    <Grid xs={12} md={8} lg={12}>
                      <Box
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          display: "grid",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "24px", fontWeight: "bolder" }}
                        >
                          บรรจุคน
                        </Typography>
                        <Typography
                          sx={{ letterSpacing: 2 }}
                          style={{ fontSize: "32px", textAlign: "center" }}
                        >
                          {utils.numberWithCommas(
                            meetingRoom[0].numberOfPeople
                          )}{" "}
                          คน
                        </Typography>
                      </Box>
                    </Grid>

                    <DividerStyled />

                    <Grid xs={12} md={8} lg={12}>
                      <Box
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          display: "grid",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "24px", fontWeight: "bolder" }}
                        >
                          ราคา / ชั่วโมง
                        </Typography>
                        <Typography
                          sx={{ letterSpacing: 2 }}
                          style={{ fontSize: "32px", textAlign: "center" }}
                        >
                          {utils.numberWithCommas(meetingRoom[0].price)} บาท
                        </Typography>
                      </Box>
                    </Grid>
                    <DividerStyled />

                    <Grid xs={12} md={8} lg={12}>
                      <Box
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          display: "grid",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "24px", fontWeight: "bolder" }}
                        >
                          ราคา / วัน
                        </Typography>
                        <Typography
                          sx={{ letterSpacing: 2 }}
                          style={{ fontSize: "32px", textAlign: "center" }}
                        >
                          {utils.numberWithCommas(meetingRoom[0].priceAllDay)}{" "}
                          บาท
                        </Typography>
                      </Box>
                    </Grid>

                    <DividerStyled />

                    <Grid xs={12} md={8} lg={12}>
                      <Box
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          display: "grid",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "24px", fontWeight: "bolder" }}
                        >
                          อุปกรณ์ / สิ่งอำนวยความสะดวก
                        </Typography>
                        {facilities.map((data, index) => (
                          <Grid item xs={12} md={6} className="gridTextList">
                            <img
                              style={{
                                weight: "40px",
                                height: "40px",
                                marginRight: "3px",
                              }}
                              src={"/assets/Image/Icon/orderRe.png"}
                              alt="Logo"
                            />
                            <Typography className="textList">{data}</Typography>
                          </Grid>
                        ))}
                      </Box>
                    </Grid>
                  </div>
                  <div className="booking">
                    <Grid style={{ width: "100%" }}>
                      <TabsStyled
                        value={isBookAllDay}
                        onChange={onChangeTab}
                        aria-label="Tabs Type Booking"
                      >
                        <TabStyled
                          value={0}
                          icon={<AccessTimeRoundedIcon />}
                          label="จองรายชั่วโมง"
                        />
                        <TabStyled
                          value={1}
                          icon={<CalendarMonthRoundedIcon />}
                          label="จองรายวัน"
                        />
                      </TabsStyled>
                    </Grid>

                    <Grid className="datePicker">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="วันที่เริ่ม"
                          value={startDate}
                          inputFormat="dd MMM yyyy"
                          InputProps={{ disableUnderline: true }}
                          onChange={(date) => handleChangeDayStart(date)}
                          renderInput={(params) => (
                            <TextField
                              className="DataPicker"
                              variant="standard"
                              {...params}
                            />
                          )}
                        />
                        <DatePicker
                          label="วันที่สิ้นสุด"
                          minDate={startDate}
                          wrapperClassName="date"
                          value={endDate}
                          inputFormat="dd MMM yyyy"
                          InputProps={{ disableUnderline: true }}
                          onChange={(date) => handleChangeDayEnd(date)}
                          renderInput={(params) => (
                            <TextField
                              className="DataPicker"
                              variant="standard"
                              style={{ marginLeft: "10px" }}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    {!isBookAllDay && (
                      <Grid className="timePicker">
                        <Controller
                          render={(propsInController) => (
                            <FormControl variant="standard" fullWidth>
                              <InputLabel shrink>{"เวลาเริ่ม"}</InputLabel>
                              <Select
                                native
                                label="เวลาเริ่ม"
                                value={timeStart}
                                disableUnderline
                                onChange={(e) => {
                                  propsInController.field.onChange(
                                    e.target.value
                                  );
                                  handleChangeStart(e.target.value);
                                }}
                                style={{ borderRadius: "10px" }}
                              >
                                {[...new Array(48)].map((value, index) => {
                                  let hour = Math.floor((0 + index * 30) / 60);
                                  let minute = Math.floor(
                                    (0 + index * 30) % 60
                                  );
                                  if (hour < 10) {
                                    hour = "0" + hour;
                                  }
                                  if (minute < 10) {
                                    minute = "0" + minute;
                                  }
                                  return (
                                    <option
                                      value={hour + ":" + minute}
                                      key={hour + ":" + minute}
                                    >
                                      {hour + ":" + minute}
                                    </option>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          )}
                          name="timeStart"
                          control={control}
                          helperText={
                            timeStart === "00:00"
                              ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                              : false
                          }
                          error={timeStart === "00:00" ? true : false}
                          defaultValue="00:00"
                        />
                        <Controller
                          render={(propsInController) => (
                            <FormControl variant="standard" fullWidth>
                              <InputLabel shrink>{"เวลาสิ้นสุด"}</InputLabel>
                              <Select
                                native
                                label="เวลาสิ้นสุด"
                                value={timeEnd}
                                disableUnderline
                                onChange={(e) => {
                                  propsInController.field.onChange(
                                    e.target.value
                                  );
                                  handleChangeEnd(e.target.value);
                                }}
                                style={{ borderRadius: "10px" }}
                              >
                                {[...new Array(48)].map((value, index) => {
                                  let hour = Math.floor((0 + index * 30) / 60);
                                  let minute = Math.floor(
                                    (0 + index * 30) % 60
                                  );
                                  if (hour < 10) {
                                    hour = "0" + hour;
                                  }
                                  if (minute < 10) {
                                    minute = "0" + minute;
                                  }
                                  return (
                                    <option
                                      value={hour + ":" + minute}
                                      key={hour + ":" + minute}
                                    >
                                      {hour + ":" + minute}
                                    </option>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          )}
                          name="timeEnd"
                          control={control}
                          error={errors.timeEnd ? true : false}
                          defaultValue="00:00"
                        />
                      </Grid>
                    )}
                    <Grid className="seletNumber">
                      <TextField
                        label="จำนวนคน"
                        value={isNumOfPeople}
                        type="number"
                        fullWidth
                        style={{ borderRadius: "10px", fontSize: "20px" }}
                        onChange={handleChangeNumOfPeolple}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid className="price">
                      <Typography style={{ fontSize: "30px" }}>
                        ค่าบริการ
                      </Typography>
                      <Typography style={{ fontSize: "30px" }}>
                        {utils.numberWithCommas(totalPrice)} บาท
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography style={{ color: "red" }}>
                        {textError}
                      </Typography>
                    </Grid>
                    <Button className="buttonBooking" onClick={onClickSubmit}>
                      จอง
                    </Button>
                    {/* {showModal && (
                      <Grid>
                        <Alert
                          onClose={() => {
                            toggleModal();
                          }}
                          severity="success"
                        >
                          บันทึกข้อมูลเรียบร้อย
                        </Alert>
                      </Grid>
                    )} */}
                  </div>
                </div>
                <DividerStyledFull />

                <Grid xs={12} md={12} lg={12}>
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontWeight: "bolder",
                      marginBottom: "10px",
                    }}
                  >
                    แนะนำช่วงเวลาว่าง
                  </Typography>

                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                      {timeForCheck.map((value, index) => (
                        <Grid item xs={2} sm={4} md={2} key={index}>
                          <ToggleButton
                            fullWidth={true}
                            value="bold"
                            style={{
                              backgroundColor: "#ffffff",
                            }}
                            aria-label="bold"
                            onChange={() => {
                              console.log(value);
                              handleChangeStart(value.split(" ")[0]);
                              handleChangeEnd(value.split(" ")[2]);
                            }}
                          >
                            <Typography style={{ color: "#00BB27" }}>
                              {value}
                            </Typography>
                          </ToggleButton>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </StyledRoot>
    </Container>
  );
};
export default DetailMeetingRoom;
const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Camera",
  },
];
