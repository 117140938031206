import { httpClient } from "./httpClient";

const getAllWaysOfDriver = () => {
    return httpClient.get("/wayOfDrivers");
}

const getWayOfDriver = (id) => {
    return httpClient.get("/wayOfDrivers/"+id);
}

const deleteEmployeeOfWay = (idDriver,idEmployee) => {
    return httpClient.post(`/deleteEmployeeOfWay/${idDriver}/${idEmployee}`);
}

export default {
    getAllWaysOfDriver,
    getWayOfDriver,
    deleteEmployeeOfWay,
};