import React from "react";
import clsx from "clsx";

import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/styles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  Grid: {
    display:"flex",
    width:"100%",
    marginTop: "10px",
    justifyContent:"flex-end",
  },
  Button:{
    width:"100px",
    height:"50px",
    color:"white",
    fontSize:"18px",
  },
  ButtonNext: {
    marginLeft:"20px",
    backgroundColor: "#DCA700",
    borderRadius:"15px",
    "&:hover": {
      backgroundColor: "#ba8e04",
    },
  },
  ButtonBack: {
    backgroundColor: "#BB2F20",
    borderRadius:"15px",
    "&:hover": {
      backgroundColor: "#9A271A",
    },
  },
  ButtonBooking: {
    marginLeft:"20px",
    backgroundColor: "green",
    borderRadius:"15px",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
}));

const NavButtonStyled = styled("div")({
  width:"100%",
  display:"flex",
  justifyContent:"flex-end",
  marginTop:"20px",
  paddingBottom:"20px",
});

const NavButton = (props) => {
  const classes = useStyles();

  return (
    // <Grid item xs={12} className={classes.Grid} >
      <NavButtonStyled>
        {props.numberOfPage !== 1 && (
            <Button className={`${classes.Button} ${classes.ButtonBack}`} onClick={()=>props.onClickBackPageHandler()}>กลับ</Button>
        )}
        <Button
          className={`${classes.Button} ${clsx(classes.ButtonNext, {
            [classes.ButtonBooking]: props.type === "submit",
          })}`}
          type="submit"
        >
          {props.type === "submit" ? "จองรถ" : "ถัดไป"}
        </Button>
      </NavButtonStyled>
    // </Grid>
  );
};

export default NavButton;
