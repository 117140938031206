import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Controller } from "react-hook-form";

import { Typography, styled } from "@mui/material";
import {
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Box,
} from "@mui/material";
import Container from "@mui/material/Container";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// import TimePicker from 'basic-react-timepicker';
import TimePicker from "@mui/lab/TimePicker";
import { useForm } from "react-hook-form";

import Card from "./Card/index.js";

import MeetingRoomService from "../../../../services/meetingRoom.service.js";

const StyledRoot = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
  "& .divCoverReturn": {
    width: "100%",
    display: "flex",
    paddingTop: "50px",
    height: "calc(100% - 30px)",
  },
  "& .Title": {
    textAlign: "center",
    fontSize: "36px",
    // margin: "25px 0px",
  },
  "& .Grid": {
    width: "100%",

    // minWidth: "1650px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
      minWidth: "150px",
      // flexDirection:"column",
    },
  },
  "& .topDivCover": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  "& .search": {
    width: "35%",
  },
  "& .DataPicker": {
    width: "auto",
    marginRight: "0px",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      marginRight: "0px",
    },
  },
  "& .datePicker": {
    display: "flex",
    borderRadius: "10px",
    border: "1px solid RGB(196, 196, 196)",
    padding: "10px",
    // marginTop: "10px",

    "&:hover": {
      border: "1px solid #222222",
    },
  },
  "& .timePicker": {
    width: "20%",
  },
  "& .date": {
    borderRadius: "15px",
  },
  "& .content": {
    marginTop: "15px",
  },
  "& .timePicker": {
    display: "flex",
    borderRadius: "10px",
    border: "1px solid RGB(196, 196, 196)",
    padding: "10px",

    "&:hover": {
      border: "1px solid #222222",
    },
  },
}));
const useStyles = makeStyles((theme) => ({
  Div: {
    justifyContent: "space-between",
    // borderRadius
    alignItems: "center",
  },
  // DatePicker:{
  //   width:"200px",
  //   marginRight:"15px",
  //   [theme.breakpoints.down('md')]: {
  //       width:"170px",
  //       marginRight:"0px",
  //   },
  // },
}));

const MeetingRoom = (props) => {
  // const classes = useStyles();
  const {
    register,
    handleSubmit,
    control,
    watch,

    getValues,
    formState: { errors },
  } = useForm();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [value, setValue] = useState(new Date());
  const [allMeetingRoom, setallMeetingRoom] = useState([]);
  const [isBooikingforSearch, setBookingforSearch] = useState();
  const [isBooikingforSearchDateTime, setBookingforSearchDateTime] = useState();
  const [textError, setTextError] = useState("");

  const [timeStart, settimeStart] = useState("00:00");
  const [timeEnd, settimeEnd] = useState("00:00");
  //Search
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    console.log("MeetingRoom");
    MeetingRoomService.getAlltNewMeetingRoom().then((res) => {
      if (res.data) {
        setallMeetingRoom(res.data);
        setBookingforSearch(res.data);
        console.log(res.data);
      } else console.log("error");
    });
    MeetingRoomService.getAllMeetingRoomBooking().then((res) => {
      if (res.data) {
        setBookingforSearchDateTime(res.data);
      }
    });
  }, []);

  const handleChangeStart = (newValue) => {
    settimeStart(newValue);
  };
  const handleChangeEnd = (newValue) => {
    settimeEnd(newValue);
  };

  const handleChangeSearchFullnameAllDetail = (event) => {
    setSearchTerm(event.target.value);
    console.log(event.target.value);
    const data = isBooikingforSearch.filter((value) =>
      value.nameMeetingRoom
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );
    setallMeetingRoom(data);
  };

  const handleChangeSearchDateAllDetail = (
    startDate,
    endDate,
    timeStart,
    timeEnd
  ) => {
    console.log(startDate, endDate, timeStart, timeEnd);
    let checkTime = false;
    let checkList = [];
    let checkStartDate = new Date(
      startDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }) +
        ", " +
        timeStart
    );
    let checkEndDate = new Date(
      endDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }) +
        ", " +
        timeEnd
    );
    for (let e = 0; e < isBooikingforSearchDateTime.length; e++) {
      const rangeStart = new Date(
        isBooikingforSearchDateTime[e].startDate
      ).getTime();
      const rangeEnd = new Date(
        isBooikingforSearchDateTime[e].endDate
      ).getTime();
      const newStart = new Date(checkStartDate).getTime();
      const newEnd = new Date(checkEndDate).getTime();

      // );
      if (
        (newStart >= rangeStart && newStart < rangeEnd) ||
        (newEnd > rangeStart && newEnd <= rangeEnd) ||
        (newStart <= rangeStart && newEnd >= rangeEnd)
      ) {
        checkList.push(isBooikingforSearchDateTime[e]);

        checkTime = true;
      } else {
        checkTime = false;
        // console.log(false, "check")
      }
    }
    let dateStart = new Date(
      startDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    );
    let DateEnd = new Date(
      endDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    );
    if (
      timeEnd === timeStart &&
      dateStart.toISOString().slice(0, 10) ===
        DateEnd.toISOString().slice(0, 10)
    ) {
      setTextError("กรอกเวลาให้ถูกต้อง");
    } else if (
      timeEnd < timeStart &&
      dateStart.toISOString().slice(0, 10) ===
        DateEnd.toISOString().slice(0, 10)
    ) {
      setTextError("กรอกเวลาให้ถูกต้อง");
    } else if (checkTime === true) {
      setTextError("เวลาที่เลือกถูกจองไปแล้ว");
      const filteredData = isBooikingforSearch.filter(
        (item) =>
          !checkList.some(
            (otherItem) => otherItem.idMeetingRoom === item.idNewMeetingRoom
          )
      );

      setallMeetingRoom(filteredData);
    } else {
      const filteredData = isBooikingforSearch.filter(
        (item) =>
          !checkList.some(
            (otherItem) => otherItem.idMeetingRoom === item.idNewMeetingRoom
          )
      );

      setallMeetingRoom(filteredData);
    }
  };

  const onClickCardHandler = (idMeeting) => {
    props.history.push(`/meetingroom/detail/`, { idMeeting: idMeeting });
    console.log("onClickCardHandler");
  };
  return (
    <Container maxWidth="xl">
      <StyledRoot>
        <div className="divCoverReturn">
          <div className="Grid">
            <p className="Title">สถานที่ทั้งหมด</p>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  label="ค้นหาถานที่"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleChangeSearchFullnameAllDetail}
                  // multiline
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                      width: "100%",
                      height: "70px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Grid className="datePicker">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่เริ่ม"
                      value={startDate}
                      InputProps={{ disableUnderline: true }}
                      onChange={(date) => {
                        setStartDate(date);
                        handleChangeSearchDateAllDetail(
                          date,
                          endDate,
                          timeStart,
                          timeEnd
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="DataPicker"
                          variant="standard"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                    <DatePicker
                      label="วันที่สิ้นสุด"
                      minDate={startDate}
                      wrapperClassName="date"
                      value={endDate}
                      InputProps={{ disableUnderline: true }}
                      onChange={(date) => {
                        setEndDate(date);
                        handleChangeSearchDateAllDetail(
                          startDate,
                          date,
                          timeStart,
                          timeEnd
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="DataPicker"
                          variant="standard"
                          fullWidth
                          style={{ marginLeft: "10px" }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Grid className="timePicker">
                  <Controller
                    render={(propsInController) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel shrink>{"เวลาเริ่ม"}</InputLabel>
                        <Select
                          native
                          label="เวลาเริ่ม"
                          value={timeStart}
                          disableUnderline
                          onChange={(e) => {
                            propsInController.field.onChange(e.target.value);
                            handleChangeStart(e.target.value);
                            handleChangeSearchDateAllDetail(
                              startDate,
                              endDate,
                              e.target.value,
                              timeEnd
                            );
                          }}
                          style={{ borderRadius: "10px" }}
                        >
                          {[...new Array(48)].map((value, index) => {
                            let hour = Math.floor((0 + index * 30) / 60);
                            let minute = Math.floor((0 + index * 30) % 60);
                            if (hour < 10) {
                              hour = "0" + hour;
                            }
                            if (minute < 10) {
                              minute = "0" + minute;
                            }
                            return (
                              <option
                                value={hour + ":" + minute}
                                key={hour + ":" + minute}
                              >
                                {hour + ":" + minute}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                    name="timeStart"
                    control={control}
                    helperText={
                      timeStart === "00:00"
                        ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                        : false
                    }
                    error={timeStart === "00:00" ? true : false}
                    defaultValue="00:00"
                  />
                  <Controller
                    render={(propsInController) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel shrink>{"เวลาสิ้นสุด"}</InputLabel>
                        <Select
                          native
                          label="เวลาสิ้นสุด"
                          value={timeEnd}
                          disableUnderline
                          onChange={(e) => {
                            propsInController.field.onChange(e.target.value);
                            handleChangeEnd(e.target.value);
                            handleChangeSearchDateAllDetail(
                              startDate,
                              endDate,
                              timeStart,
                              e.target.value
                            );
                          }}
                          style={{ borderRadius: "10px" }}
                        >
                          {[...new Array(48)].map((value, index) => {
                            let hour = Math.floor((0 + index * 30) / 60);
                            let minute = Math.floor((0 + index * 30) % 60);
                            if (hour < 10) {
                              hour = "0" + hour;
                            }
                            if (minute < 10) {
                              minute = "0" + minute;
                            }
                            return (
                              <option
                                value={hour + ":" + minute}
                                key={hour + ":" + minute}
                              >
                                {hour + ":" + minute}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                    name="timeEnd"
                    control={control}
                    error={errors.timeEnd ? true : false}
                    defaultValue="00:00"
                  />
                </Grid>
              </Grid>
            </Grid>

            <div className="content">
              <Grid
                container
                spacing={2}
                style={{ height: "50%", width: "100%" }}
              >
                {allMeetingRoom &&
                  allMeetingRoom.length > 0 &&
                  allMeetingRoom.map((meeting) => {
                    return (
                      <Grid item xs={12} sm={6} lg={4}>
                        <Card
                          key={meeting.idNewMeetingRoom}
                          meeting={meeting}
                          onClickCardHandler={onClickCardHandler}
                        ></Card>
                      </Grid>
                    );
                  })}
                {/* <Grid item xs={12} sm={6} lg={4}>
                <Card onClickCardHandler={onClickCardHandler}></Card>
              </Grid> */}
              </Grid>
            </div>
          </div>
        </div>
      </StyledRoot>
    </Container>
  );
};
export default MeetingRoom;
