function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function pointFixedDecimal(x) {
  if((x*10)%1 === 0){
    return x.toFixed(1);
  }else{
    return x.toFixed(2);
  }
}

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
      return match[1] + '-' + match[2] + '-' + match[3]
  }
  return null
}

const groupBy = (list, keyGetter) => {
  const map = new Map();
  if(list){
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
  }
}

export default { numberWithCommas, groupBy, formatPhoneNumber, pointFixedDecimal };
