import React, { useEffect, useState } from "react";
import { Grid, styled } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";

import RestaurantCard from "./RestaurantCard";

import cateringService from "../../../../../services/catering.service";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  // maxWidth: 1280,
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: 16,
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
  "@media (min-width: 680px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "24px 16px",
  },
  "@media (min-width: 1000px)": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));
const HeadRoot = styled("div")(({ theme }) => ({
  margin: "0 auto",

  padding: 5,
  maxWidth: 1280,
  display: "flex",
  justifyContent: "space-between",
  gridTemplateColumns: "1fr",

  "@media (min-width: 680px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "15px 10px",
  },
  "@media (min-width: 1000px)": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

const RestaurantListPage = (props) => {
  const [restaurants, setRestaurants] = useState([]);

  // const onClickToskip = () => {
  //   props.history.push(`/catering/request_catering`);
  // };
  useEffect(() => {
    cateringService.getAllRestaurants().then((res) => {
      setRestaurants(res.data.data);
    });
  }, []);

  return (
    <Container maxWidth="xl">
      <div className="page">
        <HeadRoot>
          <p style={{ fontSize: "24px" }}>ร้านอาหารทั้งหมด</p>
          {/* <Button
            onClick={onClickToskip}
            variant="text"
            style={{ fontSize: "18px" }}
          >
            ข้ามขั้นตอนนี้
          </Button> */}
        </HeadRoot>

        <Root>
          {restaurants.map((restaurant) => (
            <RestaurantCard
              key={`restaurant_${restaurant.idCateringRestaurant}`}
              data={restaurant}
            />
          ))}
        </Root>
      </div>
    </Container>
  );
};

export default RestaurantListPage;
