import React, { useState, useEffect } from "react";
import { styled } from "@mui/material";
import "dayjs/locale/th";
import Form from "./Form";
import Result from "./Result";
import AddMenu from "./Addmenu";
import EditMenu from "./Editmenu";
import Container from "@mui/material/Container";
import cateringService from "../../../../../services/catering.service";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1600,
  paddingInline: "8vw",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const FormAddRestaurant = (props) => {
  const [isSuccess, setIsSuccess] = useState(null);
  const [resultData, setResultDate] = useState(null);
  const [sendfile, setSendFile] = useState(null);
  const [isEdit, setIsEdit] = useState(props.location.state.isEdit);
  const [editRestaurant, setEditRestaurant] = useState(null);

  useEffect(() => {
    if (isEdit) {
      cateringService
        .getRestaurantById(props.location.state.idCateringRestaurant)
        .then((res) => {
          if (res.data.success) {
            setEditRestaurant(res.data.data);
            console.log(res.data.data);
          }
        });
    }
  }, [isEdit]);
  const onSuccessHandler = (data, file) => {
    console.log(data, file);
    setIsSuccess(1);
    setResultDate(data);
    setSendFile(file);
  };
  const onSuccessHandlerToResult = (data) => {
    setResultDate(data);
    console.log(data);
    setIsSuccess(2);
  };

  const onClickGoBack = () => {
    props.history.push("/admin/catering/restaurant_list");
  };

  return (
    <Container maxWidth="xl">
      <div className="page">
        <Root>
          {!isEdit && (
            <>
              {isSuccess === null && (
                <Form
                  onSuccessHandler={onSuccessHandler}
                  onClickGoBack={onClickGoBack}
                  isEdit={isEdit}
                  editRestaurant={null}
                />
              )}
              {isSuccess === 1 && (
                <AddMenu
                  onSuccessHandler={onSuccessHandlerToResult}
                  data={resultData}
                  sendfile={sendfile}
                />
              )}
              {isSuccess === 2 && (
                <Result
                  data={resultData}
                  onClickGoBack={onClickGoBack}
                  edit={false}
                />
              )}
            </>
          )}
          {isEdit && (
            <>
              {isSuccess === null && (
                <Form
                  onSuccessHandler={onSuccessHandler}
                  onClickGoBack={onClickGoBack}
                  isEdit={isEdit}
                  editRestaurant={editRestaurant}
                />
              )}
              {isSuccess === 1 && (
                <EditMenu
                  onSuccessHandler={onSuccessHandlerToResult}
                  data={resultData}
                  editRestaurant={editRestaurant}
                  sendfile={sendfile}
                />
              )}
              {isSuccess === 2 && (
                <Result
                  data={resultData}
                  onClickGoBack={onClickGoBack}
                  edit={true}
                />
              )}
            </>
          )}
        </Root>
      </div>
    </Container>
  );
};

export default FormAddRestaurant;
