import React from "react";
import { Link } from "react-router-dom";
import { Typography, styled, Stack, Paper } from "@mui/material";
// import Card from "../../../shared/Card";

import Utils from "../../../../../utils";

const Root = styled("div")(({ theme }) => ({
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    "& .restaurant-name": {
      color: "#00bb77",
    },
  },
  "& .card-content": {
    height: "100%",
    padding: "20px 30px",
  },
  "& .restaurant-name": {
    fontSize: 22,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    transition: "color 0.15s ease-in-out",
  },
  "& .restaurant-banner": {
    position: "relative",
    height: "50%",
    paddingBottom: "56.25%",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    cursor: "pointer",
  },
  "& .restaurant-location": {
    marginTop: 6,
    marginBottom: "30px",
    color: "#9e9e9e",
    // whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& i": {
      color: "#d50000",
    },
  },
  "& .restaurant-rating": {
    fontSize: 20,
    fontWeight: 400,
    color: "#d50000",
    "& i": {
      fontSize: 12,
      verticalAlign: "middle",
    },
    "& span": {
      color: "#9e9e9e",
    },
  },
}));

const Card = styled(Paper)(({ theme }) => ({
  padding: 0,
  transition: "transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  boxShadow: "0 2px 4px 0 rgb(35 31 32 / 20%), 0 0 1px 0 rgb(35 31 32 / 10%)",
  position: "relative",
  border: "1px solid #EEEEEE",
  backgroundColor: "#FBFCFC",
  borderRadius: 8,
  overflow: "hidden",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow:
      "rgb(145 158 171 / 60%) 0px 0px 2px 0px, rgb(145 158 171 / 60%) 0px 20px 40px -4px",
  },
  transition: "transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  boxShadow: "0 2px 4px 0 rgb(35 31 32 / 20%), 0 0 1px 0 rgb(35 31 32 / 10%)",
}));

const FoodCard = (props) => {
  return (
    <Card>
      <Root>
        <div
          className="restaurant-banner"
          style={{ backgroundImage: `url(${props.data.fileUrl})` }}
        />
        <div className="card-content">
          <Typography className="restaurant-name">
            {props.data.menuName}
          </Typography>
          <Typography className="restaurant-location">
            {props.data.detail == "" ? "No description" : props.data.detail}{" "}
          </Typography>
          <div
            style={{ position: "absolute", bottom: 0, right: 10, padding: 20 }}
          >
            <Typography className="restaurant-rating">
              {`฿${Utils.numberWithCommas(props.data.price)}`}
            </Typography>
          </div>
        </div>
      </Root>
    </Card>
  );
};

export default FoodCard;
