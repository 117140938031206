import React, { useState, useEffect } from "react";
import { styled, Stack, Typography, Button } from "@mui/material";
import Container from "@mui/material/Container";
import ButtonBlue from "../../../shared/buttonBlue";
import CateringRestaurantCard from "./CateringRestaurantCard";
import cateringService from "../../../../../services/catering.service";
import { useSnackbar } from "notistack";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: "16px 0 0 0",
  maxWidth: 1280,
  paddingInline: "8vw",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const RestaurantList = (props) => {
  const [restaurants, setRestaurants] = useState([]);
  const [onActionInPage, setOnActionInPage] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    cateringService.getAllRestaurants().then((res) => {
      if (res.data.success) {
        setRestaurants(res.data.data);
      }
    });
  }, [onActionInPage]);

  window.scroll(0, 0);
  const onClickAddRestaurant = () => {
    window.scroll(0, 0);
    props.history.push("/admin/catering/addRestaurant", {
      isEdit: false,
    });
  };

  const onClickEditRestaurant = (idCateringRestaurant) => {
    window.scroll(0, 0);
    props.history.push("/admin/catering/addRestaurant", {
      isEdit: true,
      idCateringRestaurant: idCateringRestaurant,
    });
  };
  const onClickDelete = (idCateringRestaurant) => {
    console.log("Delete", idCateringRestaurant);
    cateringService.deleteRestaurant(idCateringRestaurant).then((res) => {
      if (res.status === 200) {
        console.log("Delete Success");
        enqueueSnackbar("Delete Restaurant Success", { variant: "success" });
        setOnActionInPage(!onActionInPage);
      } else {
        enqueueSnackbar("Delete Restaurant Failed", { variant: "error" });
      }
    });
  };

  return (
    <Container maxWidth="xl">
      <div className="page">
        <Root>
          <Stack spacing={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography fontSize={32} marginBottom={2} marginTop={1}>
                รายการร้านอาหาร
              </Typography>
              <ButtonBlue
                variant="contained"
                style={{ borderRadius: "15px", height: "50px" }}
                onClick={onClickAddRestaurant}
              >
                เพิ่มร้านอาหาร
              </ButtonBlue>
            </div>

            {restaurants.map((restaurant) => (
              <CateringRestaurantCard
                key={`restaurant_${restaurant.idCateringRestaurant}`}
                data={restaurant}
                onClickEditRestaurant={onClickEditRestaurant}
                onClickDelete={onClickDelete}
              />
            ))}
          </Stack>
        </Root>
      </div>
    </Container>
  );
};

export default RestaurantList;
