import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { styled } from '@mui/styles';
import {
    Typography
} from '@mui/material';

import PassengerFriendToFriendService from '../../../../../../../../services/passengerFriendToFriend.service';
import DivPassenger from './divPassenger';

require("dayjs/locale/th");
dayjs.locale("th");

const PartOfAccordionDetails = styled("div")(({theme})=>({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom:"20px",
    "& .partOfDateInRegisterPink":{
        borderColor:"#F067D9",
    },
    "& .DateBoxPink":{
        backgroundColor:"#F067D9",
    },
}))

const PartOfDateInRegister = styled("div")(({theme})=>({
    height:"30px",
    display:"flex",
    justifyContent:"flex-end",
    borderTop:"1px solid purple",
    marginTop:"-9px",
    [theme.breakpoints.down('lg')]:{
      marginTop:"0px",
    },
}));

const DateBox = styled("div")(({theme})=>({
    height:"40px",
    width:"130px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    backgroundColor:"purple",
    borderBottomLeftRadius:"10px",
}));

const TextDateInRegister = styled(Typography)(({theme})=>({
    color:"white",
    fontWeight:"bold",
}));

const RegisterDate = props => {

    const [listPassengerOfDate, setListPassengerOfDate] = useState([]);

    useEffect(() => {
        let data = {
            idRegister:props.idRegister,
            date:dayjs(props.date).add(props.addDay, 'day').$d
        }
        console.log("data : ",data);
        PassengerFriendToFriendService.getPassengerFriendToFriendByIdRegisterAndDate(data).then(
            (res) => {
                if(res.data){
                    setListPassengerOfDate(res.data);
                }
            }
        ).catch(error => {
            console.log(data)
            setListPassengerOfDate([]);
        })
    }, [])

    return(
        <Fragment>
            {listPassengerOfDate.length > 0 &&
                <PartOfAccordionDetails>
                    <PartOfDateInRegister className={clsx({"partOfDateInRegisterPink":props.type !== "GoToWork"})}>
                        <DateBox className={clsx({"DateBoxPink":props.type !== "GoToWork"})}>
                            <TextDateInRegister>
                                {dayjs(props.date).add(props.addDay, 'day').format("DD MMM YYYY")}
                            </TextDateInRegister>
                        </DateBox>
                    </PartOfDateInRegister>
                    {listPassengerOfDate.map((passenger) => {
                            return (
                                <DivPassenger 
                                    key={passenger.id} 
                                    passenger={passenger.user} 
                                />
                            );
                        })
                    }
                </PartOfAccordionDetails>
            }
        </Fragment>
    );
};

export default RegisterDate;