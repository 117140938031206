import React from "react";
import { styled, Paper } from "@mui/material";

const Root = styled(Paper)(({theme})=>({
	padding: "24px 48px",
	// minWidth: 960,
	// borderRadius: 16,
	transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
	boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
	position: "relative",
	["& .title"]: {
		fontSize: 14,
		color: "#9e9e9e",
	}
}))

const Card = ({children}) => {
	return(
		<Root>
			{children}
		</Root>
	)
}

export default Card;