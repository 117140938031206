import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import { Grid, Typography, Divider, Chip, Stack } from "@mui/material";

require("dayjs/locale/th");
dayjs.locale("th");

const GridContainer = styled(Grid)({
  width: "100%",
});

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
}));

const MainText = styled(Typography)(({ theme }) => ({
  fontSize: "25px",
  fontWeight: "bold",
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: "#DDDDDD",
}));

const TextFrom = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  color: "#212b36",
  fontWeight: "600",
  marginLeft: "5px",
}));

const BetweenSiteCar = (props) => {
  const [booking, setBooking] = useState(null);

  useEffect(() => {
    if (props.booking) {
      setBooking(props.booking);
    }
  }, []);

  return (
    <Fragment>
      {booking && (
        <Fragment>
          <GridContainer container spacing={1}>
            <Grid item xs={12} md={12}>
              <MainText>รายละเอียดการจอง</MainText>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`ต้นทาง : ${booking.fromPlaceName}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`ปลายทาง : ${booking.toPlaceName}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`วันที่จอง : ${dayjs(booking.date).format(
                "DD MMMM YYYY"
              )}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`เวลาที่จอง : ${dayjs(booking.date).format(
                "HH:mm น."
              )}`}</Text>
            </Grid>
          </GridContainer>

          <DividerStyled />
          <GridContainer container spacing={1}>
            <Grid item xs={12} md={12}>
              <MainText>รายละเอียดคนขับรถ</MainText>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`คนขับรถ : ${booking.driver.firstname} ${booking.driver.lastname}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`เบอร์โทรศัพท์ผู้รับ : ${booking.driver.mobileNumber}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`email : ${booking.driver.email}`}</Text>
            </Grid>
          </GridContainer>
        </Fragment>
      )}
    </Fragment>
  );
};

export default BetweenSiteCar;
