import React from "react";
import { Link } from "react-router-dom";
import { styled, Typography } from "@mui/material";
import Card from "../Card";

const StyledItem = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  ["& .imgContainer"]: {
    position: "relative",
    paddingBottom: "75%",
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
    backgroundSize: "cover",
    backgroundPosition: "center",
    ["& img"]: {
      maxWidth: "100%",
      top: "50%",
      maxHeight: "100%",
      left: "50%",
      display: "block",
      position: "absolute",
      transform: "translate(-50%, -50%)",
    },
  },
}));

const Item = ({ link, title, image }) => {
  return (
    <Card>
      <StyledItem to={link}>
        <div
          className="imgContainer"
          style={{ backgroundImage: `url(${image})` }}
        />
        <Typography
          fontSize={24}
          textAlign="center"
          marginTop={1}
          marginBottom={1}
        >
          {title}
        </Typography>
      </StyledItem>
    </Card>
  );
};

export default Item;
