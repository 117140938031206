import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

import { styled } from "@mui/styles";
import {
  TextField,
  IconButton,
  Card,
  Typography,
  Grid,
  Autocomplete,
  Select,
  FormControl,
  Button,
  useMediaQuery,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import SearchIcon from "@mui/icons-material/Search";
import FavoriteIcon from "@mui/icons-material/Favorite";
import StarIcon from "@mui/icons-material/Star";

import { getAllLentCars } from "../../../../../actions/lentcar";
import utils from "../../../../../utils";

const HeadFilter = styled("div")(({ theme }) => ({
  width: "calc(100% - 60px)",
  display: "flex",
  margin: "30px",
  alignItems: "center",
  // position:"relative",
  justifyContent: "space-between",
  "& .InputBox": {
    width: "300px",
    marginRight: "20px",
    [theme.breakpoints.down("lg")]: {
      width: "235px",
      marginRight: "10px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  "& .InputDate": {
    width: "150px",
    [theme.breakpoints.down("md")]: {
      width: "165px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  [theme.breakpoints.down("xl")]: {
    width: "calc(100% - 40px)",
    margin: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    display: "block",
  },
}));

const SearchButton = styled(IconButton)(({ theme }) => ({
  width: "50px",
  height: "50px",
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const CardStyled = styled(Card)(({ theme }) => ({
  width: "100%",
  boxShadow: theme.shadows[4],
  position: "relative",
}));

const ImageOfCard = styled("div")(({ theme }) => ({
  height: "150px",
  backgroundColor: "transparent",
  "& .Image": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

const DetailOfCard = styled("div")(({ theme }) => ({
  height: "80px",
  padding: "10px 20px 0",
  "& .Model": {
    fontSize: "20px",
    fontWeight: "bold",
  },
  "& .partOfRating": {
    display: "flex",
    alignItems: "center",
  },
  "& .IconRating": {
    color: theme.palette.warning.light,
    marginRight: "5px",
  },
  "& .Point": {
    marginRight: "5px",
  },
}));

const PriceOfCard = styled("div")(({ theme }) => ({
  height: "50px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: "0 20px 0 10px",
  "& .Price": {
    fontSize: "20px",
    fontWeight: "bold",
  },
}));

const FavoriteButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  zIndex: "1",
  bottom: "5px",
  left: "10px",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .favorited": {
    color: theme.palette.error.light,
  },
}));

const SelectTime = styled(FormControl)(({ theme }) => ({
  width: "150px",
  marginRight: "20px",
  [theme.breakpoints.down("lg")]: {
    marginRight: "10px",
  },
  [theme.breakpoints.down("md")]: {
    width: "160px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginRight: "0",
    marginBottom: "20px",
  },
}));

const ListsRequestButton = styled(Button)(({ theme }) => ({
  width: "160px",
  height: "50px",
  // top:"50%",
  // position:"absolute",
  // right:"0",
  // transform:"translate(0 ,-50%)",
  border: "2px solid",
  fontSize: "20px",
  borderColor: theme.palette.primary.main,
  boxShadow: theme.shadows[3],
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  "& .Icon": {
    marginRight: "5px",
    fontSize: "24px",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "10px",
  },
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  width: "calc(95% - 40px)",
  height: "calc(100% - 20px)",
  paddingBottom: "20px",
  paddingRight: "5px",
  overflowY: "auto",
}));

// const GridCard = styled(Grid)(({theme})=>({
//     height:"fit-content"
// }));

// const Content = styled("div")(({theme})=>({
//     width:"100%",
//     display:"flex",
//     justifyContent:"center"
// }));

const DivHeaderLeft = styled("div")(({ theme }) => ({
  width: "fit-content",
  display: "flex",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    display: "block",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const DivHeaderFilterDateAndTime = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    marginTop: "20px",
    alignItems: "flex-end",
  },
}));

const DivHeaderRight = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  [theme.breakpoints.down("lg")]: {
    marginTop: "20px",
  },
}));

const DivSearchButton = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginTop: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
  },
}));

// const DivDateAndTime = styled("div")(({theme})=>({
//     width:"100%"
// }));

const HomePageBorrower = (props) => {
  const dispatch = useDispatch();
  const { result: listLentCars } = useSelector((state) => state.lentCars);
  const [place, setPlace] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [untilDate, setUntilDate] = useState(new Date());
  const [fromTime, setFromTime] = useState(new Date());
  const [untilTime, setUntilTime] = useState(new Date());
  const [favorite, setFavorite] = useState([1, 4]);

  const MobileResponse = useMediaQuery("(max-width:600px)");
  const IpadVerticalResponse = useMediaQuery("(max-width:900px)");

  const listProvince = [
    { label: "กรุงเทพฯ" },
    { label: "ระยอง" },
    { label: "สระบุรี" },
    { label: "ตรัง" },
    { label: "ขอนแก่น" },
    { label: "กระบี่" },
    { label: "นครนายก" },
    { label: "นครปฐม" },
  ];

  useEffect(() => {
    dispatch(getAllLentCars());
  }, []);

  const onClickFavoriteButton = (id) => {
    if (favorite.includes(id)) {
      setFavorite(favorite.filter((idFavorite) => idFavorite !== id));
    } else {
      setFavorite([...favorite, id]);
    }
  };

  const onClickCard = (idCar) => {
    props.history.push("/friend2friend_Car/borrower/car", {
      idCar: idCar,
      purpose: "lending",
    });
  };

  const onClickSearchButton = () => {
    props.history.push("/friend2friend_Car/borrower/filter", {
      place: place,
      fromDate: fromDate,
      untilDate: untilDate,
    });
  };

  const onClickListsRequestButton = () => {
    props.history.push("/friend2friend_Car/borrower/requestbooking");
  };

  return (
    <div className="page">
      <HeadFilter>
        <DivHeaderLeft>
          <Autocomplete
            className="InputBox"
            disablePortal
            freeSolo
            options={listProvince.map((province) => province.label)}
            onChange={(event, value) => {
              setPlace(value);
              console.log(value);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Where" value={place} />
            )}
          />
          <DivHeaderFilterDateAndTime>
            <div style={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="From Date"
                  value={fromDate}
                  minDate={new Date()}
                  onChange={(date) => {
                    setFromDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField className="InputDate" {...params} />
                  )}
                />
                <SelectTime>
                  <Select native defaultValue="">
                    {[...new Array(48)].map((value, index) => {
                      if (index === 0) {
                        return (
                          <option value="Midnight" key="Midnight">
                            Midnight
                          </option>
                        );
                      } else if (index === 24) {
                        return (
                          <option value="Noon" key="Noon">
                            Noon
                          </option>
                        );
                      } else {
                        let hour = Math.floor((0 + index * 30) / 60);
                        let minute = Math.floor((0 + index * 30) % 60);
                        if (minute < 10) {
                          minute = "0" + minute;
                        }
                        return (
                          <option
                            value={hour + ":" + minute}
                            key={hour + ":" + minute}
                          >
                            {hour + ":" + minute}
                          </option>
                        );
                      }
                    })}
                  </Select>
                </SelectTime>
                <MobileDatePicker
                  label="Until Date"
                  value={untilDate}
                  minDate={new Date()}
                  onChange={(date) => {
                    setUntilDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField className="InputDate" {...params} />
                  )}
                />
                <SelectTime>
                  <Select native defaultValue="">
                    {[...new Array(48)].map((value, index) => {
                      if (index === 0) {
                        return (
                          <option value="Midnight" key="Midnight">
                            Midnight
                          </option>
                        );
                      } else if (index === 24) {
                        return (
                          <option value="Noon" key="Noon">
                            Noon
                          </option>
                        );
                      } else {
                        let hour = Math.floor((0 + index * 30) / 60);
                        let minute = Math.floor((0 + index * 30) % 60);
                        if (minute < 10) {
                          minute = "0" + minute;
                        }
                        return (
                          <option
                            value={hour + ":" + minute}
                            key={hour + ":" + minute}
                          >
                            {hour + ":" + minute}
                          </option>
                        );
                      }
                    })}
                  </Select>
                </SelectTime>
              </LocalizationProvider>
            </div>
            <DivSearchButton>
              <SearchButton aria-label="search" onClick={onClickSearchButton}>
                <SearchIcon />
              </SearchButton>
              {IpadVerticalResponse && (
                <ListsRequestButton onClick={onClickListsRequestButton}>
                  <i className="fas fa-clipboard-list Icon"></i>รายการคำขอ
                </ListsRequestButton>
              )}
            </DivSearchButton>
          </DivHeaderFilterDateAndTime>
        </DivHeaderLeft>
        {!IpadVerticalResponse && (
          <DivHeaderRight>
            <ListsRequestButton onClick={onClickListsRequestButton}>
              <i className="fas fa-clipboard-list Icon"></i>รายการคำขอ
            </ListsRequestButton>
          </DivHeaderRight>
        )}
      </HeadFilter>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <GridContainer container spacing={3}>
          {listLentCars &&
            listLentCars.map((lentCar) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={lentCar.id}
                style={{ height: "fit-content" }}
              >
                <CardStyled>
                  <div onClick={() => onClickCard(lentCar.carId)}>
                    <ImageOfCard>
                      <img
                        className="Image"
                        src="../assets/Image/example/HondaJazz.jpeg"
                        alt="HondaJazz"
                      />
                    </ImageOfCard>
                    <DetailOfCard>
                      <Typography className="Model" noWrap>
                        {lentCar.model}
                      </Typography>
                      <Typography>บางซื่อ,กรุงเทพฯ</Typography>
                      <div className="partOfRating">
                        <StarIcon className="IconRating" />
                        <Typography className="Point">
                          {(lentCar.point * 10) % 1 === 0
                            ? lentCar.point.toFixed(1)
                            : lentCar.point.toFixed(2)}
                        </Typography>
                        <Typography>({lentCar.numberBorrowed})</Typography>
                      </div>
                    </DetailOfCard>
                    <PriceOfCard>
                      <Typography className="Price">
                        {utils.numberWithCommas(lentCar.price)} Bath/Day
                      </Typography>
                    </PriceOfCard>
                  </div>
                  <FavoriteButton
                    onClick={() => onClickFavoriteButton(lentCar.carId)}
                  >
                    <FavoriteIcon
                      className={clsx({
                        ["favorited"]: favorite.includes(lentCar.carId),
                      })}
                    />
                  </FavoriteButton>
                </CardStyled>
              </Grid>
            ))}
        </GridContainer>
      </div>
    </div>
  );
};

export default HomePageBorrower;
