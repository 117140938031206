import React,{ useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import { styled } from '@mui/styles';
import {
    Typography, Avatar, IconButton, Button
} from '@mui/material';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import BetweenSiteCarService from "../../../../../services/betweenSiteCar.service";

const DivCoverReturn = styled("div")({
    width:"400px",
    height:"calc(100% - 64px)",
    position:"absolute",
    zIndex:"5",
    backgroundColor:"white",
});

const ContentPlace = styled("div")(({theme})=>({
    width:"calc(100% - 20px)",
    height:"50px",
    display:"flex",
    justifyContent:"space-around",
    padding:"10px 10px", 
    border:"1px solid",
    borderTop:"0",
    borderLeft:"0",
    borderRight:"0",
    borderColor:theme.palette.grey[300],
}));

const TextSite = styled(Typography)({
    fontSize:"30px",
});

const DivListRequest = styled("div")({
    height:"40%",
    overflowY:"auto",
});

const DivPerson = styled("div")({
    width:"calc(100% - 20px)",
    heihgt:"fit-content",
    display:"flex",
    padding:"10px 10px",
    justifyContent:"space-between",
    alignItems:"center",
    position:"relative",
});

const AvatarPerson = styled(Avatar)({
    width:"50px",
    height:"50px",
    marginRight:"20px",
});

const Name = styled(Typography)({
    fontSize:"20px",
});

const Location = styled(Typography)(({theme})=>({
    fontSize:"16px",
    color:theme.palette.grey[400],
}));

const LocationOnIconStyled = styled(LocationOnIcon)({
    color:"red",
    marginRight:"5px",
});

const IconAndText = styled("div")({
    display:"flex",
});

const NavButton = styled("div")(({theme})=>({
    width:"85px",
    display:"flex",
    justifyContent:"space-between",
    "& .GetIcon":{
        backgroundColor:theme.palette.success.main,
    },
    "& .DontIcon":{
        backgroundColor:theme.palette.error.main,
    },
}));

const IconButtonStyled = styled(IconButton)(({theme})=>({
    boxShadow:theme.shadows[3],
    "& .Icon":{
        color:"white",
    }
}));

const AlertBackgroundEmployee = styled("div")({
    width:"calc(100% + 10px)",
    height:"100%",
    position:"absolute",
    zIndex:"-1",
    left:"-10px",
    animationName: "alertNewEmployee",
    animationDuration: "4s",
});

const DivPassengerAndHeader = styled("div")({
    height:"calc(100% - 40% - 70px)"
});

const DivPassengerHeader = styled("div")(({theme})=>({
    height:"50px",
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    padding:"10px 10px",
    border:"1px solid",
    borderLeft:"0",
    borderRight:"0",
    borderColor:theme.palette.grey[300],
}));

const Title = styled(Typography)({
    fontSize:"28px",
    marginLeft:"10px"
});

const GoingToTargetButton = styled(Button)(({theme})=>({
    width:"130px",
    backgroundColor:theme.palette.primary.main,
    color:theme.palette.primary.contrastText,
    fontSize:"20px",
    borderRadius:"50px",
    "&:hover":{
        backgroundColor:theme.palette.primary.main,
    }
}));

const ListPassenger = styled("div")({
    height:"calc(100% - 70px)"
});

const PaperRequest = props => {
    const { result : DriverRouteDay } = useSelector(state => state.driverRouteDay);
    const [calledEmployeeList, setCalledEmployeeList] = useState([]);
    const [driverRoute, setDriverRoute] = useState([])

    useEffect(() => {
        let CatchError = false;
        BetweenSiteCarService.getAllBetweenSiteCars().then(
            (res) => {
                if(CatchError){ return; }
                if(res.data){
                    setCalledEmployeeList(res.data);
                }
            }
        ).catch(error => {
            console.log(error);
            setCalledEmployeeList([]);
        });
        if(DriverRouteDay){
            setDriverRoute(DriverRouteDay)
        }
        return () => {
            CatchError = true;
        }
       
    }, [])
    console.log(driverRoute)

    const FindDestination = () => {
        let indexStart = (props.MockGPSPlace.map(place => place.id).indexOf(props.placeStart));
        if(indexStart === props.MockGPSPlace.length-1){
            return( props.MockGPSPlace[0].name );
        }
        else{
            return(props.MockGPSPlace[indexStart+1].name );
        }
    };

    const onClickupdateDriverRoute = (data) =>{
        console.log("updateDriverRoute",driverRoute.idDriverRouteDays,data)
        BetweenSiteCarService.updateDriverRoute({idDriverRouteDay:driverRoute.idDriverRouteDays, idBetweenSiteCar:data}).then((res)=>{
            if(res.data){
                BetweenSiteCarService.getAllBetweenSiteCars().then(
                    (res) => {
                       
                        if(res.data){
                            setCalledEmployeeList(res.data);
                        }
                    }
                ).catch(error => {
                    console.log(error);
                    setCalledEmployeeList([]);
                });
            }
        })
    }

    return(
        <DivCoverReturn>
            <ContentPlace>
                <TextSite>{props.MockGPSPlace.find(place => place.id === props.placeStart).name}</TextSite>
                <TextSite>
                    <i className="fad fa-chevron-double-right" style={{color:"orange"}}></i>
                </TextSite>
                <TextSite>{FindDestination()}</TextSite>
            </ContentPlace>
            {calledEmployeeList && 
            <>
                <DivListRequest>
                    {console.log("calledEmployeeList : ",calledEmployeeList)}
                    {calledEmployeeList.map(calling=>{
                        if(!calling.idDriverRouteDay){
                            return(
                                <DivPerson key={calling.id}>
                                    <div style={{display:"flex"}}>
                                        <AvatarPerson src={`${process.env.REACT_APP_URL}image/profile/1.jpg`} />
                                        <div>
                                            <Name>{`${calling.fNameThai}`}</Name>
                                            <IconAndText>
                                                <LocationOnIconStyled />
                                                <Location>{`${calling.gettingPlace} ${calling.spotGettingPlace == null ? '':','+ calling.spotGettingPlace }`}</Location>
                                            </IconAndText>
                                        </div>
                                    </div>
                                    <NavButton>
                                        <IconButtonStyled  size="medium" className="GetIcon">
                                            <DoneIcon onClick={()=>{onClickupdateDriverRoute(calling.idBetweenSiteCar)}} className="Icon" />
                                        </IconButtonStyled>
                                        <IconButtonStyled size="medium" className="DontIcon">
                                            <CloseIcon className="Icon" />
                                        </IconButtonStyled>
                                    </NavButton>
                                    <AlertBackgroundEmployee></AlertBackgroundEmployee>
                                </DivPerson>
                            )
                        }
                    })}  
                </DivListRequest>
                <DivPassengerAndHeader>
                    <DivPassengerHeader>
                        <Title>ผู้โดยสาร</Title>
                        <GoingToTargetButton>ไปจุดหมาย</GoingToTargetButton>
                    </DivPassengerHeader>
                    <ListPassenger>
                        {calledEmployeeList.map(calling=>{
                            if(parseInt(calling.idDriverRouteDay) === parseInt(DriverRouteDay.idDriverRouteDays)){
                                return(
                                    <DivPerson key={calling.idDriverRouteDay}>
                                        <div style={{display:"flex"}}>
                                            <AvatarPerson src={`${process.env.REACT_APP_URL}image/profile/1.jpg`} />
                                            <div>
                                                <Name>{`${calling.firstname} ${calling.lastname}`}</Name>
                                                <IconAndText>
                                                    <LocationOnIconStyled />
                                                    <Location>{`${calling.gettingPlace} ${calling.spotGettingPlace == null ? '':','+ calling.spotGettingPlace } `}</Location>
                                                </IconAndText>
                                            </div>
                                        </div>
                                        {/* <AlertBackgroundEmployee></AlertBackgroundEmployee> */}
                                    </DivPerson>
                                )
                            }
                        })}
                    </ListPassenger>
                </DivPassengerAndHeader>
            </>
            }
        </DivCoverReturn>
    );
};

export default PaperRequest;